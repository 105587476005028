import { MapViewConfigurationProvider } from "contexts/MapViewConfigurationContext/MapViewConfigurationContext";
import { MapViewDataProvider } from "contexts/MapViewDataContext/MapViewDataContext";
import {
    RoutingMetadataProvider,
    useRoutingMetadata
} from "contexts/RoutingMetadataContext";
import { ScreeningViewProvider } from "contexts/ScreeningViewContext/ScreeningViewContext";
import { SelectedBusesProvider } from "contexts/SelectedBusesContext/SelectedBusesContext";
import React from "react";
import { useLocation } from "react-router-dom";
import HomeView from "../components/HomeView";
import MapView from "../components/MapView";

/**
 * Represents the "Base Nira" application, which parses some configuration data
 * out of the URL, then renders an appropriate view based on that state.
 */
export const ProspectingApp: React.FC = () => {
    const location = useLocation();
    return (
        // We use this key to force a remount of all child components when the route changes.
        // This is because we want to reset the state when you go from MISO to PJM, etc.
        <RoutingMetadataProvider key={location.pathname}>
            <ScreeningViewProvider>
                <ProspectingAppInner />
            </ScreeningViewProvider>
        </RoutingMetadataProvider>
    );
};

/**
 * This is structured as a separate component so it can access the state provided
 * by the RoutingMetadataContext.
 */
const ProspectingAppInner: React.FC = () => {
    const { type: routingMetadataType } = useRoutingMetadata();
    return (
        <>
            {routingMetadataType !== "mapView" ? (
                <HomeView />
            ) : (
                <MapViewConfigurationProvider>
                    <MapViewDataProvider>
                        <SelectedBusesProvider>
                            <MapView />
                        </SelectedBusesProvider>
                    </MapViewDataProvider>
                </MapViewConfigurationProvider>
            )}
        </>
    );
};

import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { AllScenarioTooltips } from "in_queue/components/common/AllScenarioTooltips";
import { ScenarioDialog } from "in_queue/components/ScenarioDialog/ScenarioDialog";
import { useCurrentScenarioMetadata } from "in_queue/contexts/ScenarioPageContext";
import { useScenarioCreationDisabled } from "in_queue/hooks/useScenarioCreationDisabled";
import { useState } from "react";

const DuplicateScenarioAction: React.FC = () => {
    const scenario = useCurrentScenarioMetadata();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return (
        <>
            <AllScenarioTooltips stage={scenario.stage}>
                <Button
                    icon={IconNames.DUPLICATE}
                    text="Duplicate scenario"
                    onClick={() => setIsDialogOpen(true)}
                    disabled={useScenarioCreationDisabled(scenario.stage)}
                />
            </AllScenarioTooltips>
            <ScenarioDialog
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                scenario={scenario}
            />
        </>
    );
};

export default DuplicateScenarioAction;

import React from "react";
import "./ScreeningViewMetadataSection.scss";

import { getLabelForRegionAndGeneratorType } from "types/generatorType";
import { ScreeningView } from "types/screeningViewType";
import { STATES_TO_DISPLAY_NAME } from "types/stateType";

type ScreeningViewMetadataSectionProps = {
    readonly screeningView: ScreeningView;
    readonly showTitle: boolean;
};

const ScreeningViewMetadataSection: React.FC<
    ScreeningViewMetadataSectionProps
> = (props) => {
    const { screeningView, showTitle } = props;

    return (
        <div>
            {showTitle && <b>{screeningView.title}</b>}
            <div className="ScreeningViewMetadataSection-description">
                {screeningView.description}
            </div>
            <div className="ScreeningViewMetadataSection-items">
                <div className="ScreeningViewMetadataSection-item">
                    <strong className="ScreeningViewMetadataSection-label">
                        Region
                    </strong>
                    <span>{screeningView.region}</span>
                </div>
                <div className="ScreeningViewMetadataSection-item">
                    <strong className="ScreeningViewMetadataSection-label">
                        Fuel type
                    </strong>
                    <span>
                        {getLabelForRegionAndGeneratorType(
                            screeningView.region,
                            screeningView.generatorType
                        )}
                    </span>
                </div>
                <div className="ScreeningViewMetadataSection-item">
                    <strong className="ScreeningViewMetadataSection-label">
                        States
                    </strong>
                    <span>
                        {screeningView.states
                            .map((state) => STATES_TO_DISPLAY_NAME[state])
                            .join(", ")}
                    </span>
                </div>
                <div className="ScreeningViewMetadataSection-item">
                    <strong className="ScreeningViewMetadataSection-label">
                        Created by
                    </strong>
                    <span>{screeningView.authorEmail}</span>
                </div>
                <div className="ScreeningViewMetadataSection-item">
                    <strong className="ScreeningViewMetadataSection-label">
                        Created at
                    </strong>
                    <span>
                        {screeningView.createdAt.toFormat("MM/dd/yyyy HH:mm")}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ScreeningViewMetadataSection;

import { useQuery } from "@apollo/client";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import useSelectedBuses from "contexts/SelectedBusesContext/useSelectedBuses";
import {
    GetLineTapDataWithCapacityCostsQuery,
    GetLineTapDataWithCapacityCostsQueryResult,
    GetLineTapDataWithCapacityCostsQueryVariables
} from "generated/graphql";
import { loader } from "graphql.macro";
import {
    getCapacityCostsWhereClauseWithScopeView,
    getLineCapacityCostsWhereClauseWithScopeView
} from "graphql/helpers/capacityCostsWhereClause";
import {
    convertHasuraLineTapDetailsToNiraLineTapDetails,
    LineTapDetails
} from "types/modelLinesType";

const GET_LINE_TAP_DATA_WITH_CAPACITY_COSTS = loader(
    "src/graphql/getLineTapDataWithCapacityCosts.graphql"
);
export const EMPTY_LINE_TAP_DETAILS: LineTapDetails = {
    tapName: "",
    energySize: 0,
    capacitySize: 0,
    chargingSize: 0,
    allocatedCost: 0,
    totalCost: 0,
    fromBus: {
        busId: "",
        busName: "",
        energySize: 0,
        capacitySize: 0,
        chargingSize: 0,
        isLocked: false
    },
    toBus: {
        busId: "",
        busName: "",
        energySize: 0,
        capacitySize: 0,
        chargingSize: 0,
        isLocked: false
    },
    facilityId: "",
    rating: 0,
    lineLength: 0
};

interface LineTapDetailsContext {
    readonly lineTapDetails: LineTapDetails;
    readonly isLoading: boolean;
}

const useLineTapData = (): LineTapDetailsContext => {
    const { scopeView } = useMapViewRoutingMetadata();
    const {
        busFiltersConfiguration: { busFilters }
    } = useMapViewConfiguration();

    const { selectedFacility } = useSelectedBuses();

    if (!selectedFacility) {
        return {
            lineTapDetails: EMPTY_LINE_TAP_DETAILS,
            isLoading: true
        };
    }

    const capacityCostsWhereClause = getCapacityCostsWhereClauseWithScopeView({
        scope: busFilters.scope,
        scopeView: scopeView,
        maybeMaxAllocatedCosts: busFilters.maybeMaxAllocatedCosts.enabled
            ? busFilters.maybeMaxAllocatedCosts.maxCosts
            : undefined,
        maybeMaxTotalCosts: busFilters.maybeMaxTotalCosts.enabled
            ? busFilters.maybeMaxTotalCosts.maxCosts
            : undefined,
        includePreExistingConstraints: busFilters.includePreExistingConstraints
    });

    const lineCapacityCostsWhereClause =
        getLineCapacityCostsWhereClauseWithScopeView({
            scope: busFilters.scope,
            scopeView: scopeView,
            maybeMaxAllocatedCosts: busFilters.maybeMaxAllocatedCosts.enabled
                ? busFilters.maybeMaxAllocatedCosts.maxCosts
                : undefined,
            maybeMaxTotalCosts: busFilters.maybeMaxTotalCosts.enabled
                ? busFilters.maybeMaxTotalCosts.maxCosts
                : undefined,
            includePreExistingConstraints:
                busFilters.includePreExistingConstraints
        });

    const activeDataQuery = useQuery<
        GetLineTapDataWithCapacityCostsQuery,
        GetLineTapDataWithCapacityCostsQueryVariables
    >(GET_LINE_TAP_DATA_WITH_CAPACITY_COSTS, {
        variables: {
            scope: busFilters.scope,
            scopeView: scopeView,
            facilityId: selectedFacility.facilityId,
            lineCapacityCostsWhereClause: lineCapacityCostsWhereClause,
            capacityCostsWhereClause: capacityCostsWhereClause
        }
    });

    const lineTapDetails = parseLineTapDetailsResult(activeDataQuery);

    const isLoading =
        activeDataQuery.loading ||
        (activeDataQuery.previousData === undefined &&
            activeDataQuery.data === undefined);

    return {
        lineTapDetails,
        isLoading
    };
};

function parseLineTapDetailsResult(
    queryResult: GetLineTapDataWithCapacityCostsQueryResult
): LineTapDetails {
    const currentData: GetLineTapDataWithCapacityCostsQuery | undefined =
        queryResult.data;

    if (currentData === undefined) {
        if (queryResult.previousData === undefined) {
            // This means that this is the first time this is ever called
            return EMPTY_LINE_TAP_DETAILS;
        } else {
            return convertHasuraLineTapDetailsToNiraLineTapDetails(
                queryResult.previousData.model_lines[0]
            );
        }
    } else {
        if (currentData.model_lines.length === 0) {
            // This means that we couldn't fetch data for the selected line tap because of user selected filters
            return EMPTY_LINE_TAP_DETAILS;
        } else {
            return convertHasuraLineTapDetailsToNiraLineTapDetails(
                currentData.model_lines[0]
            );
        }
    }
}

export { useLineTapData };

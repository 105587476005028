import { GetScenarioMetricsQuery } from "generated/graphql";
import _ from "lodash";

export interface ScenarioMetrics {
    sumAllocatedCost?: number;
    costPerMw?: number;
}
export type ScenarioMetricsMapping = { [scenarioId: string]: ScenarioMetrics };

export const hasuraScenarioMetricsToScenarioMetricsMapping = (
    hasuraData: GetScenarioMetricsQuery
): ScenarioMetricsMapping => {
    return _.chain(hasuraData.scenarios)
        .keyBy("scenario_id")
        .mapValues((item) => {
            const agg = item.scenario_projects_aggregate.aggregate?.max;
            return {
                sumAllocatedCost: agg?.sum_allocated_cost ?? undefined,
                costPerMw: agg?.cost_per_mw ?? undefined
            };
        })
        .value();
};

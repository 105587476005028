import { BoundingBoxConfiguration } from "contexts/MapViewConfigurationContext/hooks/useBoundingBox";
import { BusFiltersConfiguration } from "contexts/MapViewConfigurationContext/hooks/useBusFilters";
import { useCreateMapViewConfiguration } from "contexts/MapViewConfigurationContext/hooks/useCreateMapViewConfiguration";
import { LayerStyleConfiguration } from "contexts/MapViewConfigurationContext/hooks/useLayerStyleId";
import { createContext, PropsWithChildren } from "react";

interface MapViewConfigurationContextProps {
    readonly busFiltersConfiguration: BusFiltersConfiguration;
    readonly layerStyleConfiguration: LayerStyleConfiguration;
    readonly boundingBoxConfiguration: BoundingBoxConfiguration;
}

const MapViewConfigurationContext = createContext<
    MapViewConfigurationContextProps | undefined
>(undefined);

const MapViewConfigurationProvider: React.FC<PropsWithChildren<unknown>> = ({
    children
}: PropsWithChildren<unknown>) => {
    const contextValue = useCreateMapViewConfiguration();

    return (
        <MapViewConfigurationContext.Provider value={contextValue}>
            {children}
        </MapViewConfigurationContext.Provider>
    );
};

export { MapViewConfigurationProvider, MapViewConfigurationContext };
export type { MapViewConfigurationContextProps };

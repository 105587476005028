import { useQuery } from "@apollo/client";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import useSelectedScopedBus from "contexts/SelectedScopedBusContext/useSelectedScopedBus";
import {
    GetConnectedLinesWithCapacityCostsQuery,
    GetConnectedLinesWithCapacityCostsQueryVariables
} from "generated/graphql";
import { loader } from "graphql.macro";
import { getLineCapacityCostsWhereClauseWithScopeView } from "graphql/helpers/capacityCostsWhereClause";
import {
    ConnectedLine,
    convertHasuraConnectedLinesToNiraConnectedLines,
    convertHasuraLinesWithMissingBusToNiraLinesWithMissingBus,
    LineWithMissingBus
} from "types/modelLinesType";

const GET_CONNECTED_LINES_WITH_CAPACITY_COST = loader(
    "src/graphql/getConnectedLinesWithCapacityCosts.graphql"
);

interface ConnectedLinesContext {
    readonly connectedLines: readonly ConnectedLine[];
    readonly linesWithMissingBuses: readonly LineWithMissingBus[];
    readonly isLoading: boolean;
}

const useConnectedLinesData = (args: {
    isUnlockBusLoading?: boolean;
}): ConnectedLinesContext => {
    const { isUnlockBusLoading } = args;
    const { scopeView } = useMapViewRoutingMetadata();
    const {
        busFiltersConfiguration: { busFilters }
    } = useMapViewConfiguration();
    const scopedBus = useSelectedScopedBus();

    const lineCapacityCostsWhereClause =
        getLineCapacityCostsWhereClauseWithScopeView({
            scope: busFilters.scope,
            scopeView: scopeView,
            maybeMaxAllocatedCosts: busFilters.maybeMaxAllocatedCosts.enabled
                ? busFilters.maybeMaxAllocatedCosts.maxCosts
                : undefined,
            maybeMaxTotalCosts: busFilters.maybeMaxTotalCosts.enabled
                ? busFilters.maybeMaxTotalCosts.maxCosts
                : undefined,
            includePreExistingConstraints:
                busFilters.includePreExistingConstraints
        });

    const activeDataQuery = useQuery<
        GetConnectedLinesWithCapacityCostsQuery,
        GetConnectedLinesWithCapacityCostsQueryVariables
    >(GET_CONNECTED_LINES_WITH_CAPACITY_COST, {
        variables: {
            scope: busFilters.scope,
            scopeView: scopeView,
            busId: scopedBus.bus.id,
            lineCapacityCostsWhereClause: lineCapacityCostsWhereClause
        },
        skip: isUnlockBusLoading
    });

    let connectedLines: ReadonlyArray<ConnectedLine> = [];
    let linesWithMissingBuses: ReadonlyArray<LineWithMissingBus> = [];
    let isLoading = true;
    if (!activeDataQuery.loading && activeDataQuery.data !== undefined) {
        connectedLines = convertHasuraConnectedLinesToNiraConnectedLines(
            scopedBus.bus.id,
            activeDataQuery.data.model_lines
        );
        linesWithMissingBuses =
            convertHasuraLinesWithMissingBusToNiraLinesWithMissingBus(
                activeDataQuery.data.model_lines_to_missing_buses
            );
        isLoading = false;
    }

    return {
        connectedLines,
        linesWithMissingBuses,
        isLoading
    };
};

export { useConnectedLinesData };

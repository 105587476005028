import { ScenarioMetricsMapping } from "in_queue/types/scenarioMetricsType";
import { formatCosts } from "in_queue/utils/formatting";
import { max, mean, min } from "lodash";
import { Loading, mapLoadingState } from "types/loadingType";
import { MetricCard, MetricCardRow } from "../common/MetricCard";

interface ProjectMetricsProps {
    scenarioMetrics: Loading<ScenarioMetricsMapping>;
}

interface ProjectMetrics {
    minCostPerMw: number;
    maxCostPerMw: number;
    avgCostPerMw: number;
}

export const ProjectMetricCards: React.FC<ProjectMetricsProps> = ({
    scenarioMetrics
}) => {
    const projectMetrics: Loading<ProjectMetrics> = mapLoadingState(
        scenarioMetrics,
        (metrics) => {
            const costsPerMw = Object.values(metrics).map((m) => m.costPerMw);
            return {
                minCostPerMw: min(costsPerMw) ?? 0,
                maxCostPerMw: max(costsPerMw) ?? 0,
                avgCostPerMw: mean(costsPerMw) ?? 0
            };
        }
    );

    return (
        <MetricCardRow>
            <MetricCard
                label="Minimum cost ($/MW)"
                value={mapLoadingState(projectMetrics, (m) =>
                    m.minCostPerMw !== undefined
                        ? formatCosts(m.minCostPerMw)
                        : "-"
                )}
            />
            <MetricCard
                label="Maximum cost ($/MW)"
                value={mapLoadingState(projectMetrics, (m) =>
                    m.maxCostPerMw !== undefined
                        ? formatCosts(m.maxCostPerMw)
                        : "-"
                )}
            />
            <MetricCard
                label="Average cost ($/MW)"
                value={mapLoadingState(projectMetrics, (m) =>
                    m.avgCostPerMw !== undefined
                        ? formatCosts(m.avgCostPerMw)
                        : "-"
                )}
            />
        </MetricCardRow>
    );
};

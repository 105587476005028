import {
    convertToRegionType,
    isKnownRegionType,
    RegionType
} from "./regionType";

export type Subscription = {
    readonly region: RegionType;
    readonly unlimited: boolean;
    readonly numBuses: number;
    readonly numUnlockedBuses: number;
};

type HasuraCountAggregate = {
    readonly count: number;
};

type HasuraUnlockedBusesAggregate = {
    readonly aggregate?: HasuraCountAggregate | null;
};

export type HasuraSubscription = {
    readonly region: string;
    readonly unlimited: boolean;
    readonly num_buses: number;
    readonly end_date: string;
    readonly unlocked_buses_aggregate: HasuraUnlockedBusesAggregate;
};

export const hasuraSubscriptionsToNiraSubscriptions = (
    hasuraSubscriptions: HasuraSubscription[]
): ReadonlyArray<Subscription> => {
    return hasuraSubscriptions
        .filter((hasuraSubscription) =>
            isKnownRegionType(hasuraSubscription.region)
        )
        .map(hasuraSubscriptionToNiraSubscription);
};

const hasuraSubscriptionToNiraSubscription = (
    hasuraSubscription: HasuraSubscription
): Subscription => {
    return {
        region: convertToRegionType(hasuraSubscription.region),
        unlimited: hasuraSubscription.unlimited,
        numBuses: hasuraSubscription.num_buses,
        numUnlockedBuses:
            hasuraSubscription.unlocked_buses_aggregate.aggregate?.count || 0
    };
};

import "./LoadingFullScreen.scss";

import { Spinner, SpinnerSize } from "@blueprintjs/core";
import React from "react";

const LoadingFullScreen: React.FC = () => {
    return (
        <div className="LoadingFullScreen-wrapper">
            <Spinner size={SpinnerSize.LARGE} />
        </div>
    );
};

export default LoadingFullScreen;

import { InQueueApp } from "in_queue/InQueueApp";
import { Route, Routes } from "react-router-dom";
import { DocumentationAuthenticationApp } from "./DocumentationAuthenticationApp";
import { ProspectingApp } from "./ProspectingApp";

export const SCREENING_VIEWS_PATH = "screening-views";
export const IN_QUEUE_PATH = "portfolio";

export const RouterApp: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<ProspectingApp />} />
            <Route path={`/${IN_QUEUE_PATH}/*`} element={<InQueueApp />} />
            <Route
                path="/docs-authentication"
                element={<DocumentationAuthenticationApp />}
            />
            <Route path="/:urlDirectory" element={<ProspectingApp />}>
                <Route
                    path={`${SCREENING_VIEWS_PATH}/:screeningViewId`}
                    element={<ProspectingApp />}
                />
            </Route>
        </Routes>
    );
};

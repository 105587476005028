import { Button, Intent, Position, Toaster, Tooltip } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useUserDataContext } from "contexts/UserDataContext/UserDataContext";
import { useRemoveTrackedProjectMutation } from "generated/graphql";
import { IN_QUEUE_PATH } from "infrastructure/RouterApp";
import { useSubscriptionForCluster } from "in_queue/contexts/ProjectDataContext";
import { clusterToClusterId } from "in_queue/types/clusterType";
import { TrackedProject } from "in_queue/types/projectType";
import { useNavigate } from "react-router-dom";
import { ADMIN_TYPE } from "types/roleType";
import { AdminOnlyTooltip } from "./common/AdminOnlyTooltip";

export const RemoveTrackedProjectButton: React.FC<{
    project: TrackedProject;
}> = ({ project }) => {
    const navigate = useNavigate();
    const { role } = useUserDataContext();
    const isNotAdmin = role !== ADMIN_TYPE;

    const subscription = useSubscriptionForCluster();
    const [removeTrackedProject, removeTrackedProjectResult] =
        useRemoveTrackedProjectMutation();

    const removeProject = async () => {
        if (subscription === "loading" || !subscription) {
            return;
        }
        try {
            await removeTrackedProject({
                variables: {
                    projectId: project.projectId,
                    subscriptionId: subscription.subscriptionId
                }
            });
            const clusterId = clusterToClusterId(project.cluster);
            navigate(`/${IN_QUEUE_PATH}/${clusterId}`);
        } catch (error) {
            RemoveTrackedProjectToaster.show({
                intent: Intent.DANGER,
                message: `Encountered an error while removing a project. Please try again later.`
            });
        }
    };

    return (
        <AdminOnlyTooltip>
            <Tooltip
                content={
                    <span>
                        Removing this project will not affect associated
                        scenarios.
                        <br />
                        You can always add this project back later.
                    </span>
                }
                disabled={isNotAdmin}
            >
                <Button
                    icon={IconNames.CROSS}
                    text="Remove project"
                    loading={removeTrackedProjectResult.loading}
                    disabled={isNotAdmin}
                    onClick={removeProject}
                />
            </Tooltip>
        </AdminOnlyTooltip>
    );
};

const RemoveTrackedProjectToaster = Toaster.create({
    position: Position.TOP
});

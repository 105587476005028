import { Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import InfoIcon from "components/common/InfoIcon";
import React from "react";
import "./FilterWrapper.scss";

type FilterWrapperProps = {
    readonly label?: string;
    readonly helperText?: React.ReactElement | string;
    readonly limitInputMaxWidth?: boolean;
    readonly limitLabelMaxWidth?: boolean;
    readonly alignLabelToTop?: boolean;
    readonly wideLabel?: boolean;
};

const FilterWrapper: React.FC<FilterWrapperProps> = (props) => {
    const {
        label,
        children,
        helperText,
        limitInputMaxWidth,
        limitLabelMaxWidth,
        alignLabelToTop,
        wideLabel
    } = props;

    const maybeInfoIcon = helperText && (
        <Tooltip
            content={
                <div className="FilterWrapper-popover-content-wrapper">
                    {helperText}
                </div>
            }
            placement="top"
        >
            <InfoIcon />
        </Tooltip>
    );

    return (
        <div className="FilterWrapper-wrapper">
            {label !== undefined && (
                <div
                    className={classNames("FilterWrapper-label", {
                        "FilterWrapper-label-max-width": limitLabelMaxWidth,
                        "FilterWrapper-label-align-top": alignLabelToTop,
                        "FilterWrapper-label-wide": wideLabel
                    })}
                >
                    {label}
                    {maybeInfoIcon}
                </div>
            )}
            <div
                className={classNames("FilterWrapper-input", {
                    "FilterWrapper-input-max-width": limitInputMaxWidth
                })}
            >
                {children}
            </div>
        </div>
    );
};

export default FilterWrapper;

import { useAuth0 } from "@auth0/auth0-react";
import { identify, page } from "analytics/analytics";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { NiraAuth0UserType } from "types/auth0UserType";

/**
 * Initializes Segment information
 */
export const SegmentProvider: React.FC = ({ children }) => {
    // Set up telemetry
    useSetupSegment();

    return <>{children}</>;
};

const useSetupSegment = (): void => {
    const location = useLocation();
    const { user } = useAuth0<NiraAuth0UserType>();
    useEffect(() => {
        if (user !== undefined) {
            identify(user, () => {
                page();
            });
        } else {
            // This happens in demo mode
            page();
        }
    }, [user, location]);
};

import { Button, Intent } from "@blueprintjs/core";
import { segmentTrackContactSalesUnauthorizedDetailPaneClicked } from "analytics/analyticTrackEvents";
import UnauthorizedTag from "components/common/UnauthorizedTag";
import React from "react";
import { BusId, ScopedBus } from "types/busType";
import {
    CAPACITY_RESOURCE_TYPE,
    ENERGY_RESOURCE_TYPE
} from "types/resourceType";
import BusDetailPane from "./BusDetailPane";
import ConnectedLinesSection from "./ConnectedLinesSection";
import "./UnauthorizedBusDetailPane.scss";

type UnauthorizedDetailPaneProps = {
    readonly scopedBus: ScopedBus;
};

const UnauthorizedDetailPane: React.FC<UnauthorizedDetailPaneProps> = (
    props: UnauthorizedDetailPaneProps
) => {
    const { scopedBus } = props;

    // Technically, there might be a different number of pills here than the real detail pane.
    // But that just got complicated to code so this error state will always have 2 pills.
    return (
        <div>
            <BusDetailPane
                energyTag={
                    <UnauthorizedTag resourceType={ENERGY_RESOURCE_TYPE} />
                }
                capacityTag={
                    <UnauthorizedTag resourceType={CAPACITY_RESOURCE_TYPE} />
                }
                constraintDetailsSections={[
                    getUnauthorizedConstraintDetailsSection(scopedBus.bus.id)
                ]}
                connectedLinesSection={<ConnectedLinesSection isLocked />}
            />
        </div>
    );
};

const getUnauthorizedConstraintDetailsSection = (busId: BusId): JSX.Element => {
    return (
        <div className="UnauthorizedDetailPane-upgrade-section-wrapper">
            <h5 className="UnauthorizedDetailPane-upgrade-section-heading bp5-heading">
                No buses left in subscription
            </h5>
            <Button intent={Intent.PRIMARY}>
                <a
                    href="mailto:sales@niraenergy.com"
                    target="_blank"
                    rel="noreferrer"
                    className="UnauthorizedDetailPane-contact-sales-link"
                    onClick={() => {
                        segmentTrackContactSalesUnauthorizedDetailPaneClicked(
                            busId
                        );
                    }}
                >
                    Contact sales
                </a>
            </Button>
        </div>
    );
};

export default UnauthorizedDetailPane;

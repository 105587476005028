import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    FormGroup,
    Intent,
    Position,
    Toaster
} from "@blueprintjs/core";
import { useUserDataContext } from "contexts/UserDataContext/UserDataContext";
import { useUpdateScenarioParentProjectMutation } from "generated/graphql";
import { ScenarioMetadata } from "in_queue/types/scenarioType";
import React, { useState } from "react";
import { VisibilityInput } from "./VisibilityInput";

type ScenarioDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    scenario: ScenarioMetadata;
};

export const ScenarioVisibilityDialog: React.FC<ScenarioDialogProps> = ({
    isOpen,
    onClose,
    scenario
}: ScenarioDialogProps) => {
    const { auth0Id } = useUserDataContext();
    const [updateParentProject] = useUpdateScenarioParentProjectMutation();
    const [parentProjectId, setParentProjectId] = useState<string | undefined>(
        scenario?.parentProjectId
    );
    const [isLoading, setIsLoading] = useState(false);

    const handleUpdate = async () => {
        setIsLoading(true);
        try {
            await updateParentProject({
                variables: {
                    authId: auth0Id,
                    scenarioId: scenario.scenarioId,
                    parentProjectId
                }
            });
            ScenarioVisibilityToaster.show({
                message: "Scenario visibility has been updated",
                intent: "success"
            });
            onClose();
        } catch (e) {
            ScenarioVisibilityToaster.show({
                message: "Failed to update scenario visibility",
                intent: "danger"
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog
            isOpen={isOpen}
            canOutsideClickClose={true}
            canEscapeKeyClose={true}
            onClose={onClose}
            title="Edit scenario visibility"
        >
            <DialogBody>
                <FormGroup
                    label="Visibility"
                    subLabel="Select where this scenario should be visible."
                >
                    <VisibilityInput
                        parentProjectId={parentProjectId}
                        setParentProjectId={setParentProjectId}
                    />
                </FormGroup>
            </DialogBody>
            <DialogFooter
                actions={
                    <>
                        <Button onClick={onClose} disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button
                            intent={Intent.PRIMARY}
                            onClick={handleUpdate}
                            loading={isLoading}
                        >
                            Update
                        </Button>
                    </>
                }
            />
        </Dialog>
    );
};

const ScenarioVisibilityToaster = Toaster.create({
    position: Position.TOP
});

import { Button, Checkbox, Tab, Tabs } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import LoadingFullScreen from "components/common/LoadingFullScreen";
import { IN_QUEUE_PATH } from "infrastructure/RouterApp";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { segmentTrackOpenProjectPage } from "./analytics";
import { AllScenarioTooltips } from "./components/common/AllScenarioTooltips";
import { BackButton } from "./components/common/BackButton";
import { SectionHeader } from "./components/common/SectionHeader";
import { ProjectMetricCards } from "./components/metrics/ProjectMetricCards";
import { RemoveTrackedProjectButton } from "./components/RemoveTrackedProjectButton";
import { ScenarioDialog } from "./components/ScenarioDialog/ScenarioDialog";
import { ScenariosTable } from "./components/tables/ScenariosTable";
import {
    useCluster,
    useClusterId,
    useStageConfig
} from "./contexts/ClusterContext";
import {
    useProjectScenarios,
    useTrackedProject
} from "./contexts/ProjectDataContext";
import { useScenarioCreationDisabled } from "./hooks/useScenarioCreationDisabled";
import { useScenarioMetricsMapping } from "./hooks/useScenarioMetricsMapping";
import "./ProjectPage.scss";
import { getPhaseLabel } from "./types/clusterType";
import { StageConfig } from "./types/configPerRegion";
import { TrackedProject } from "./types/projectType";
import {
    ScenarioMetadata,
    ScenarioStage,
    ScenarioStatus
} from "./types/scenarioType";

export const ProjectPage: React.FC = () => {
    const { projectId } = useParams();
    const cluster = useCluster();
    const navigate = useNavigate();
    const project = useTrackedProject({
        projectId,
        phase: cluster.studyPhase
    });

    useEffect(() => {
        if (project === undefined) {
            navigate(`/${IN_QUEUE_PATH}`);
        }
    }, [project]);

    const scenarios = useProjectScenarios({
        projectId,
        phase: cluster.studyPhase
    });
    const stageConfig = useStageConfig();

    if (
        !project ||
        project === "loading" ||
        !scenarios ||
        scenarios === "loading"
    ) {
        return <LoadingFullScreen />;
    }

    return (
        <LoadedProjectPage
            project={project}
            scenarios={scenarios}
            stageConfig={stageConfig}
        />
    );
};

const LoadedProjectPage: React.FC<{
    project: TrackedProject;
    scenarios: ScenarioMetadata[];
    stageConfig: StageConfig;
}> = ({ project, scenarios, stageConfig }) => {
    const clusterId = useClusterId();
    const [showArchived, setShowArchived] = useState(false);
    useEffect(() => {
        segmentTrackOpenProjectPage({ projectId: project.projectId });
    }, []);

    const validScenarioStages = useMemo(() => {
        const stages: Set<ScenarioStage> = new Set();
        if (stageConfig.PREVIEW.enabled) {
            stages.add("PREVIEW");
        }
        if (stageConfig.DECISION_POINT.enabled) {
            stages.add("DECISION_POINT");
        }
        return stages;
    }, [stageConfig]);
    // If any decision point scenarios exist, default to show those
    const [scenarioStage, setScenarioStage] = useState<ScenarioStage>(
        validScenarioStages.has("DECISION_POINT") ? "DECISION_POINT" : "PREVIEW"
    );

    const [isCreateScenarioDialogOpen, setIsCreateScenarioDialogOpen] =
        useState(false);
    const closeCreateScenarioDialog = () => {
        setIsCreateScenarioDialogOpen(false);
    };

    const filteredScenarios = scenarios.filter(
        (s) =>
            s.stage === scenarioStage &&
            (s.status !== ScenarioStatus.Archived || showArchived)
    );

    const numArchivedScenarios = scenarios.filter(
        (s) => s.status === ScenarioStatus.Archived
    ).length;

    const scenarioMetrics = useScenarioMetricsMapping(
        project,
        filteredScenarios
    );

    return (
        <>
            <BackButton
                text="Back to portfolio"
                to={`/${IN_QUEUE_PATH}/${clusterId}`}
            />
            <ScenarioDialog
                isOpen={isCreateScenarioDialogOpen}
                onClose={closeCreateScenarioDialog}
                scenarioStage={scenarioStage}
            />
            <div>
                <SectionHeader
                    level="h1"
                    title={`Project ${project.projectId}`}
                    actions={<RemoveTrackedProjectButton project={project} />}
                />
                <Tabs
                    selectedTabId={scenarioStage}
                    onChange={(newTabId) =>
                        setScenarioStage(newTabId as ScenarioStage)
                    }
                    large
                    fill
                >
                    <Tab
                        id="PREVIEW"
                        title={`${getPhaseLabel(
                            project.cluster.studyPhase
                        )} Preview`}
                        disabled={!validScenarioStages.has("PREVIEW")}
                    />
                    <Tab
                        id="DECISION_POINT"
                        title={`${getPhaseLabel(
                            project.cluster.studyPhase
                        )} Decision Point`}
                        disabled={!validScenarioStages.has("DECISION_POINT")}
                    />
                </Tabs>
            </div>
            <div>
                <ProjectMetricCards scenarioMetrics={scenarioMetrics} />
                <SectionHeader
                    level="h2"
                    title="Scenarios"
                    actions={
                        <div className="scenarios-section-header">
                            {numArchivedScenarios > 0 && (
                                <Checkbox
                                    checked={showArchived}
                                    label={`Show archived (${numArchivedScenarios})`}
                                    onChange={() =>
                                        setShowArchived(!showArchived)
                                    }
                                    className="show-archived-checkbox"
                                />
                            )}
                            <AllScenarioTooltips stage={scenarioStage}>
                                <Button
                                    icon={IconNames.ADD}
                                    text="Create scenario"
                                    onClick={() =>
                                        setIsCreateScenarioDialogOpen(true)
                                    }
                                    disabled={useScenarioCreationDisabled(
                                        scenarioStage
                                    )}
                                />
                            </AllScenarioTooltips>
                        </div>
                    }
                />
                <ScenariosTable
                    project={project}
                    scenarios={filteredScenarios}
                    scenarioMetrics={scenarioMetrics}
                />
            </div>
        </>
    );
};

import { useStageConfig } from "in_queue/contexts/ClusterContext";
import { useIsAtScenarioLimit } from "in_queue/hooks/useIsAtScenarioLimit";
import { ScenarioStage } from "in_queue/types/scenarioType";
import React from "react";
import { AdminOnlyTooltip } from "./AdminOnlyTooltip";
import { CreationDisabledForStageTooltip } from "./CreationDisabledForStageTooltip";
import { ScenarioLimitTooltip } from "./ScenarioLimitTooltip";

export const AllScenarioTooltips: React.FC<{ stage: ScenarioStage }> = ({
    children,
    stage
}) => {
    const isAtScenarioLimit = useIsAtScenarioLimit();
    const stageConfig = useStageConfig();
    return (
        <AdminOnlyTooltip>
            <ScenarioLimitTooltip isAtScenarioLimit={isAtScenarioLimit}>
                <CreationDisabledForStageTooltip
                    creationDisabled={!stageConfig[stage].createButtonEnabled}
                    stage={stage}
                >
                    {children}
                </CreationDisabledForStageTooltip>
            </ScenarioLimitTooltip>
        </AdminOnlyTooltip>
    );
};

import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { Loading } from "types/loadingType";
import css from "./MetricCard.module.scss";

export const MetricCardRow: React.FC = ({ children }) => {
    return <div className={css["row"]}>{children}</div>;
};
interface MetricCardProps {
    label: string;
    value: Loading<string>;
}
export const MetricCard: React.FC<MetricCardProps> = ({ label, value }) => {
    return (
        <div className={css["card"]}>
            <div className={css["label"]}>{label}</div>
            <div
                className={classNames(css["value"], {
                    [Classes.SKELETON]: value === "loading"
                })}
            >
                {value}
            </div>
        </div>
    );
};

import { Branches } from "generated/graphql";
import { convertToVoltage, Voltage } from "./voltageTypes";

export type BranchId = string;
export type VoltageToBranchesDict = { [key in Voltage]: readonly Branch[] };
export type Branch = {
    readonly id: BranchId;
    readonly voltage: Voltage;
    readonly branchPath: ReadonlyArray<ReadonlyArray<number>>;
};

type HasuraBranch = Readonly<Pick<Branches, "id" | "voltage" | "branch_path">>;

const hasuraBranchToNiraBranch = (hasuraBranch: HasuraBranch): Branch => {
    return {
        id: hasuraBranch.id,
        voltage: convertToVoltage(hasuraBranch.voltage),
        branchPath: convertBranchPath(hasuraBranch.branch_path)
    };
};

export const hasuraBranchesToNiraBranches = (
    hasuraBranches: ReadonlyArray<HasuraBranch>
): VoltageToBranchesDict => {
    const voltageToBranches: VoltageToBranchesDict = {
        44: [],
        66: [],
        69: [],
        100: [],
        115: [],
        120: [],
        138: [],
        161: [],
        230: [],
        345: [],
        500: [],
        765: []
    };
    hasuraBranches.forEach((hasuraBranch) => {
        const branchObject = hasuraBranchToNiraBranch(hasuraBranch);
        if (voltageToBranches[branchObject.voltage] === undefined) {
            voltageToBranches[branchObject.voltage] = [];
        }
        voltageToBranches[branchObject.voltage] = [
            ...voltageToBranches[branchObject.voltage],
            branchObject
        ];
    });
    return voltageToBranches;
};

const convertBranchPath = (
    path: string
): ReadonlyArray<ReadonlyArray<number>> => {
    let newPathString = path.replace(/\(/gi, "[");
    newPathString = newPathString.replace(/\)/gi, "]");
    const jsonPath: number[][] = JSON.parse(newPathString);
    return jsonPath.map((coordinate) => [coordinate[1], coordinate[0]]);
};

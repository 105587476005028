import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    Intent,
    NumericInput,
    Slider
} from "@blueprintjs/core";
import {
    segmentTrackCustomInjectionDialogClosed,
    segmentTrackCustomInjectionUpdated
} from "analytics/analyticTrackEvents";
import {
    useMapViewRoutingMetadata,
    usePricedConstraintConfig
} from "contexts/RoutingMetadataContext";
import React, { useEffect, useState } from "react";
import { BusId } from "types/busType";
import { STORAGE_TYPE } from "types/generatorType";
import { Loading } from "types/loadingType";
import { FacilityId } from "types/selectedFacility";
import { PowerAmounts } from "./BusDetailPane";
import "./CustomInjectionDialog.scss";

type CustomInjectionDialogProps = {
    readonly isOpen: boolean;
    readonly onClose: () => void;
    readonly defaultPowerAmounts: PowerAmounts;
    readonly setPowerAmounts: (powerAmounts: PowerAmounts) => void;
    readonly getFirstSummaryField: (
        powerAmounts: PowerAmounts
    ) => Loading<string> | undefined;
    readonly getSecondSummaryField: (
        powerAmounts: PowerAmounts
    ) => Loading<string> | undefined;
    readonly selectedId: BusId | FacilityId;
};

const MAX_MW = 1000;
const MIN_MW = 0;
const STEP_SIZE = 10;

// This component should only be used for regions with a PricedConstraintConfig. Don't use for regions like ERCOT and NYISO
const CustomInjectionDialog: React.FC<CustomInjectionDialogProps> = (props) => {
    const {
        isOpen,
        onClose,
        defaultPowerAmounts,
        getFirstSummaryField,
        getSecondSummaryField,
        setPowerAmounts,
        selectedId
    } = props;
    const { generator } = useMapViewRoutingMetadata();
    const { showCapacity } = usePricedConstraintConfig();

    const [stagedEnergySize, setStagedEnergySize] = useState(
        defaultPowerAmounts.energySize
    );
    const [stagedCapacitySize, setStagedCapacitySize] = useState(
        defaultPowerAmounts.capacitySize
    );
    const [stagedChargingSize, setStagedChargingSize] = useState(
        defaultPowerAmounts.chargingSize
    );
    const stagedPowerAmounts: PowerAmounts = {
        energySize: stagedEnergySize,
        capacitySize: stagedCapacitySize,
        chargingSize: stagedChargingSize
    };

    // We think this useEffect is no longer necessary, but keeping it around just to be safe
    // as it was originally added to handle an edge case
    useEffect(() => {
        setStagedEnergySize(defaultPowerAmounts.energySize);
        setStagedCapacitySize(defaultPowerAmounts.capacitySize);
        setStagedChargingSize(defaultPowerAmounts.chargingSize);
    }, [
        selectedId,
        defaultPowerAmounts.energySize,
        defaultPowerAmounts.capacitySize,
        defaultPowerAmounts.chargingSize
    ]);

    const maybeFirstSummaryField = getFirstSummaryField(stagedPowerAmounts);
    const maybeSecondSummaryField = getSecondSummaryField(stagedPowerAmounts);

    return (
        <Dialog
            isOpen={isOpen}
            onClose={() => {
                segmentTrackCustomInjectionDialogClosed(selectedId);
                onClose();
            }}
            title="Preview project size"
            icon="predictive-analysis"
            className="CustomInjectionDialog-dialog"
        >
            <DialogBody className="CustomInjectionDialog-body">
                <div className="CustomInjectionDialog-input-wrapper">
                    <h6 className={"bp5-heading"}>Energy size (MW)</h6>
                    <NumericInput
                        value={stagedEnergySize}
                        buttonPosition="right"
                        allowNumericCharactersOnly={true}
                        onValueChange={(valueAsNumber: number) => {
                            setStagedEnergySize(valueAsNumber);
                        }}
                        stepSize={STEP_SIZE}
                        max={MAX_MW}
                        min={MIN_MW}
                        className="CustomInjectionDialog-numeric-input"
                    />
                    <Slider
                        min={MIN_MW}
                        max={MAX_MW}
                        labelRenderer={false}
                        onChange={(value) => {
                            setStagedEnergySize(value);
                        }}
                        stepSize={STEP_SIZE}
                        value={stagedEnergySize}
                        className="CustomInjectionDialog-slider"
                    />
                </div>
                {generator === STORAGE_TYPE && (
                    <div className={"CustomInjectionDialog-input-wrapper"}>
                        <h6 className={"bp5-heading"}>Charging size (MW)</h6>
                        <NumericInput
                            value={stagedChargingSize}
                            buttonPosition="right"
                            allowNumericCharactersOnly={true}
                            onValueChange={(valueAsNumber: number) => {
                                setStagedChargingSize(valueAsNumber);
                            }}
                            stepSize={STEP_SIZE}
                            max={MAX_MW}
                            min={MIN_MW}
                            className="CustomInjectionDialog-numeric-input"
                        />
                        <Slider
                            min={MIN_MW}
                            max={MAX_MW}
                            labelRenderer={false}
                            onChange={(value) => {
                                setStagedChargingSize(value);
                            }}
                            stepSize={STEP_SIZE}
                            value={stagedChargingSize}
                            className="CustomInjectionDialog-slider"
                        />
                    </div>
                )}
                {showCapacity && (
                    <div className="CustomInjectionDialog-input-wrapper">
                        <h6 className={"bp5-heading"}>Capacity size (MW)</h6>
                        <NumericInput
                            value={stagedCapacitySize}
                            buttonPosition="right"
                            allowNumericCharactersOnly={true}
                            onValueChange={(valueAsNumber: number) => {
                                setStagedCapacitySize(valueAsNumber);
                            }}
                            stepSize={STEP_SIZE}
                            max={MAX_MW}
                            min={MIN_MW}
                            className="CustomInjectionDialog-numeric-input"
                        />
                        <Slider
                            min={MIN_MW}
                            max={MAX_MW}
                            labelRenderer={false}
                            onChange={(value) => {
                                setStagedCapacitySize(value);
                            }}
                            stepSize={STEP_SIZE}
                            value={stagedCapacitySize}
                            className="CustomInjectionDialog-slider"
                        />
                    </div>
                )}
            </DialogBody>
            <DialogFooter
                actions={
                    <div>
                        <Button
                            className="CustomInjectionDialog-cancel-button"
                            text="Cancel"
                            small={true}
                            onClick={onClose}
                            intent={Intent.NONE}
                        />
                        <Button
                            text="Preview"
                            small={true}
                            onClick={() => {
                                setPowerAmounts(stagedPowerAmounts);
                                segmentTrackCustomInjectionUpdated(
                                    stagedCapacitySize,
                                    stagedEnergySize,
                                    stagedChargingSize,
                                    defaultPowerAmounts.capacitySize,
                                    defaultPowerAmounts.energySize,
                                    defaultPowerAmounts.chargingSize,
                                    selectedId
                                );
                                onClose();
                            }}
                            intent={Intent.PRIMARY}
                        />
                    </div>
                }
            >
                <div>
                    {maybeFirstSummaryField === "loading" ||
                    maybeSecondSummaryField === "loading"
                        ? "Loading"
                        : maybeFirstSummaryField === undefined
                        ? `${maybeSecondSummaryField}`
                        : `${maybeFirstSummaryField} • ${maybeSecondSummaryField}`}
                </div>
            </DialogFooter>
        </Dialog>
    );
};

export default CustomInjectionDialog;

import { Button, Intent, MenuItem, Position, Toaster } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Select2 } from "@blueprintjs/select";
import { useUserDataContext } from "contexts/UserDataContext/UserDataContext";
import { useAddTrackedProjectMutation } from "generated/graphql";
import {
    useOtherProjectsInCluster,
    useSubscriptionForCluster
} from "in_queue/contexts/ProjectDataContext";
import { Project } from "in_queue/types/projectType";
import { ADMIN_TYPE } from "types/roleType";
import { AdminOnlyTooltip } from "./common/AdminOnlyTooltip";

export const AddTrackedProjectButton: React.FC = () => {
    const { role } = useUserDataContext();
    const isNotAdmin = role !== ADMIN_TYPE;

    const otherProjects = useOtherProjectsInCluster();
    const subscription = useSubscriptionForCluster();
    const [addTrackedProject, addTrackedProjectResult] =
        useAddTrackedProjectMutation();

    const onSelectProject = async (project: Project) => {
        if (subscription === "loading" || !subscription) {
            return;
        }
        try {
            await addTrackedProject({
                variables: {
                    projectId: project.projectId,
                    subscriptionId: subscription.subscriptionId
                }
            });
        } catch (error) {
            AddTrackedProjectToaster.show({
                intent: Intent.DANGER,
                message: `Encountered an error while adding tracked project. Please try again later.`
            });
        }
    };

    const items = otherProjects === "loading" ? [] : otherProjects;

    return (
        <AdminOnlyTooltip>
            <Select2
                disabled={otherProjects === "loading"}
                items={items}
                itemPredicate={(query, project) =>
                    project.projectId
                        .toLowerCase()
                        .includes(query.toLowerCase())
                }
                itemRenderer={(project, { handleClick }) => (
                    <MenuItem
                        text={project.projectId}
                        key={project.projectId}
                        disabled={addTrackedProjectResult.loading}
                        onClick={handleClick}
                    />
                )}
                onItemSelect={onSelectProject}
                resetOnClose
                popoverProps={{
                    minimal: true
                }}
            >
                <Button
                    icon={IconNames.SEARCH}
                    text="Add project"
                    loading={addTrackedProjectResult.loading}
                    disabled={isNotAdmin}
                />
            </Select2>
        </AdminOnlyTooltip>
    );
};

const AddTrackedProjectToaster = Toaster.create({
    position: Position.TOP
});

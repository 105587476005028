import useMapViewData from "contexts/MapViewDataContext/hooks/useMapViewData";
import useSelectedBuses from "contexts/SelectedBusesContext/useSelectedBuses";
import { createContext, PropsWithChildren } from "react";
import { ScopedBus } from "types/busType";
import { SelectedBusesAtLocation } from "types/selectedBusesAtLocation";

const SelectedScopedBusContext = createContext<ScopedBus | undefined>(
    undefined
);

const SelectedScopedBusProvider: React.FC<PropsWithChildren<unknown>> = ({
    children
}: PropsWithChildren<unknown>) => {
    const { selectedBusesAtLocation } = useSelectedBuses();
    const { scopedBuses } = useMapViewData();

    const maybeSelectedScopedBus = maybeGetSelectedScopedBus(
        selectedBusesAtLocation,
        scopedBuses
    );

    if (maybeSelectedScopedBus) {
        return (
            <SelectedScopedBusContext.Provider value={maybeSelectedScopedBus}>
                {children}
            </SelectedScopedBusContext.Provider>
        );
    } else {
        return null;
    }
};

const maybeGetSelectedScopedBus = (
    maybeSelectedBuses: SelectedBusesAtLocation | null,
    scopedBuses: readonly ScopedBus[]
): ScopedBus | null | undefined => {
    if (maybeSelectedBuses === null) {
        return null;
    } else {
        const busId = maybeSelectedBuses.selectedBusId;
        return scopedBuses.find((scopedBus) => scopedBus.bus.id === busId);
    }
};

export { SelectedScopedBusProvider, SelectedScopedBusContext };

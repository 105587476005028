import {
    Capacity_Costs_Bool_Exp,
    Line_Capacity_Costs_Bool_Exp
} from "generated/graphql";
import { ScopeType } from "types/scopeType";
import { ScopeViewType } from "types/scopeViewType";

export interface GetCapacityCostsWhereClauseWithScopeViewProps {
    scope: ScopeType;
    scopeView: ScopeViewType;
    maybeMaxAllocatedCosts: number | undefined;
    maybeMaxTotalCosts: number | undefined;
    includePreExistingConstraints: boolean;
}

export const getCapacityCostsWhereClauseWithScopeView = (
    props: GetCapacityCostsWhereClauseWithScopeViewProps
): Capacity_Costs_Bool_Exp => {
    const {
        scope,
        scopeView,
        maybeMaxAllocatedCosts,
        maybeMaxTotalCosts,
        includePreExistingConstraints
    } = props;
    return {
        upgrade_costs:
            maybeMaxAllocatedCosts !== undefined
                ? {
                      _lte: maybeMaxAllocatedCosts
                  }
                : {},
        total_upgrade_costs:
            maybeMaxTotalCosts !== undefined
                ? {
                      _lte: maybeMaxTotalCosts
                  }
                : {},
        scope: { _eq: scope },
        scope_view: { _eq: scopeView },
        includes_pre_existing: { _eq: includePreExistingConstraints }
    };
};

export const getLineCapacityCostsWhereClauseWithScopeView = (
    props: GetCapacityCostsWhereClauseWithScopeViewProps
): Line_Capacity_Costs_Bool_Exp => {
    const {
        scope,
        scopeView,
        maybeMaxAllocatedCosts,
        maybeMaxTotalCosts,
        includePreExistingConstraints
    } = props;
    return {
        upgrade_costs:
            maybeMaxAllocatedCosts !== undefined
                ? {
                      _lte: maybeMaxAllocatedCosts
                  }
                : {},
        total_upgrade_costs:
            maybeMaxTotalCosts !== undefined
                ? {
                      _lte: maybeMaxTotalCosts
                  }
                : {},
        scope: { _eq: scope },
        scope_view: { _eq: scopeView },
        includes_pre_existing: { _eq: includePreExistingConstraints }
    };
};

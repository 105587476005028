import { Icon, Intent, OverlayToaster, Position } from "@blueprintjs/core";
import {
    segmentTrackBusSelectedFromLineDetailPane,
    segmentTrackMoreDetailsConstraintsDialogOpened,
    segmentTrackSelectionCanceledFromLineDetailPaneCross
} from "analytics/analyticTrackEvents";
import AvailableTag from "components/common/AvailableTag";
import BusCapacityDot from "components/common/BusCapacityDot";
import { getAllSizeLabels, getLabelStyle } from "components/common/labels";
import { useIsUnlimitedSubscription } from "components/sidebar/utils/useIsUnlimitedSubscription";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import {
    EMPTY_LINE_TAP_DETAILS,
    useLineTapData
} from "contexts/MapViewDataContext/hooks/useLineTapData";
import useMapViewData from "contexts/MapViewDataContext/hooks/useMapViewData";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import useSelectedBuses from "contexts/SelectedBusesContext/useSelectedBuses";
import { Line_Constraint_Stacks_Bool_Exp } from "generated/graphql";
import { loader } from "graphql.macro";
import { getHasuraDataAndConvertToNiraType } from "graphql/helpers/queryHelpers";
import React, { useEffect, useState } from "react";
import { getCapacityColor } from "sharedStyles/capacityColorStyles";
import { isPricedConstraintConfig } from "types/componentConfigPerRegion";
import { STORAGE_TYPE } from "types/generatorType";
import { convertHasuraLineConstraintsToLineTapConstraints } from "types/lineConstraintType";
import { mapLoadingState } from "types/loadingType";
import { LineTapBusDetails } from "types/modelLinesType";
import {
    CAPACITY_RESOURCE_TYPE,
    CHARGING_RESOURCE_TYPE,
    ENERGY_RESOURCE_TYPE
} from "types/resourceType";
import { PowerAmounts } from "../BusDetailPane/BusDetailPane";
import ConstraintDetailsDialog from "../BusDetailPane/ConstraintDetailsDialog/ConstraintDetailsDialog";
import ConstraintDetailsDialogTable, {
    ConstraintTableConfig
} from "../BusDetailPane/ConstraintDetailsDialog/ConstraintsDetailsDialogTable";
import { getExportConstraintsToCSV } from "../BusDetailPane/ConstraintDetailsDialog/constraintsTableHelpers";
import { CurtailmentConstraintDetailsDialog } from "../BusDetailPane/ConstraintDetailsDialog/CurtailmentConstraintDetailsDialog";
import ConstraintsDetailsSection from "../BusDetailPane/ConstraintsDetailsSection";
import {
    getAllocatedCost,
    getConstraintDetailRows,
    getCurtailmentDetailRows,
    getFormattedAllocatedCost,
    getFormattedTotalCost,
    getTotalCost
} from "../BusDetailPane/constraintsHelpers";
import CustomInjectionDialog from "../BusDetailPane/CustomInjectionDialog";
import DetailPaneHeader from "../shared/DetailPaneHeader";
import "./LineTapPane.scss";

const GET_LINE_CONSTRAINTS = loader(
    "src/graphql/getLineTapConstraints.graphql"
);
const LineTapPaneToaster = OverlayToaster.create({
    position: Position.TOP
});

const LineTapPane: React.FC = () => {
    const isLimitedBusSubscription = !useIsUnlimitedSubscription();
    const { generator, scopeView, componentConfig } =
        useMapViewRoutingMetadata();
    const {
        busFiltersConfiguration: { busFilters }
    } = useMapViewConfiguration();
    const { capacityThresholds, scope, includePreExistingConstraints } =
        busFilters;
    const { selectedFacility, clearSelectedBuses, selectSingleBus } =
        useSelectedBuses();
    const shouldShowCharging = generator === STORAGE_TYPE;
    const labelStyle = getLabelStyle(componentConfig, generator);

    const selectedFacilityId = selectedFacility?.facilityId;
    if (!selectedFacilityId) {
        return null;
    }

    const [detailedConstraintsDialogOpen, setDetailedConstraintsDialogOpen] =
        useState(false);
    const [customInjectionDialogOpen, setCustomInjectionDialogOpen] =
        useState(false);
    const { lineTapDetails, isLoading } = useLineTapData();
    const { scopedBuses } = useMapViewData();

    const {
        tapName,
        rating,
        lineLength,
        energySize,
        capacitySize,
        chargingSize,
        fromBus,
        toBus
    } = lineTapDetails;

    const originalPowerAmounts = { energySize, capacitySize, chargingSize };
    const [editedPowerAmounts, setEditedPowerAmounts] =
        useState<PowerAmounts>(originalPowerAmounts);

    useEffect(() => {
        setEditedPowerAmounts(originalPowerAmounts);
    }, [
        selectedFacilityId,
        originalPowerAmounts.capacitySize,
        originalPowerAmounts.energySize,
        originalPowerAmounts.chargingSize,
        scope
    ]);

    const getConstraintsQueryVariables: Line_Constraint_Stacks_Bool_Exp = {
        tap_facility_id: { _eq: selectedFacilityId },
        scope: { _eq: scope }
    };

    if (
        !includePreExistingConstraints &&
        isPricedConstraintConfig(componentConfig)
    ) {
        getConstraintsQueryVariables.is_pre_existing = {
            _eq: false
        };
    }

    const maybeConstraints = getHasuraDataAndConvertToNiraType(
        GET_LINE_CONSTRAINTS,
        convertHasuraLineConstraintsToLineTapConstraints,
        { constraintsWhereClause: getConstraintsQueryVariables }
    );

    const scopeViewConstraints = mapLoadingState(
        maybeConstraints,
        (constraints) =>
            constraints.filter(
                (constraint) => constraint.scopeView === scopeView
            )
    );

    const capacityColor = getCapacityColor(
        { energySize, capacitySize, chargingSize },
        capacityThresholds,
        generator
    );

    const fromBusColor = getCapacityColor(
        {
            energySize: fromBus.energySize,
            capacitySize: fromBus.capacitySize,
            chargingSize: fromBus.chargingSize
        },
        capacityThresholds,
        generator
    );

    const toBusColor = getCapacityColor(
        {
            energySize: toBus.energySize,
            capacitySize: toBus.capacitySize,
            chargingSize: toBus.chargingSize
        },
        capacityThresholds,
        generator
    );

    if (isLoading) {
        return null;
    }

    // If we couldn't load the line tap details due to user selected filters,
    // clear the selected buses and don't show the line tap pane
    if (!isLoading && lineTapDetails === EMPTY_LINE_TAP_DETAILS) {
        clearSelectedBuses();
        return null;
    }

    const icon = (
        <Icon
            className={"LineTapPane-icon"}
            color={capacityColor}
            icon="layout-linear"
        />
    );

    const onCancel = () => {
        segmentTrackSelectionCanceledFromLineDetailPaneCross(
            selectedFacilityId
        );
        clearSelectedBuses();
    };

    const generateOnBusClick = (bus: LineTapBusDetails) => {
        return () => {
            const { busId, busName } = bus;
            const isHidden = !scopedBuses
                .map((scopedBus) => scopedBus.bus.id)
                .includes(busId);

            if (isHidden) {
                LineTapPaneToaster.show({
                    message: `${busName} is hidden because of your filters or current map view.`,
                    intent: Intent.WARNING
                });
            } else {
                selectSingleBus(busId);
            }

            segmentTrackBusSelectedFromLineDetailPane(
                busId,
                selectedFacilityId,
                isHidden
            );
        };
    };

    const lockIcon = (
        <Icon className="bp5-text-muted" icon={"lock"} size={10} />
    );
    const maybeFromBusLockIcon =
        fromBus.isLocked && isLimitedBusSubscription && lockIcon;
    const maybeToBusLockIcon =
        toBus.isLocked && isLimitedBusSubscription && lockIcon;

    const detailedConstraintsDialogOnClick = () => {
        segmentTrackMoreDetailsConstraintsDialogOpened(selectedFacilityId);
        setDetailedConstraintsDialogOpen(true);
    };

    let tagOnClick;
    let capacityTag;
    let constraintDetailsSection;
    let customInjectionDialog;
    let constraintDetailsDialog;
    if (isPricedConstraintConfig(componentConfig)) {
        tagOnClick = () => setCustomInjectionDialogOpen(true);
        const { showCapacity, hasAllocatedCost, showContingency } =
            componentConfig;
        capacityTag = showCapacity && (
            <AvailableTag
                size={originalPowerAmounts.capacitySize}
                editedSize={editedPowerAmounts.capacitySize}
                onClick={tagOnClick}
                resourceType={CAPACITY_RESOURCE_TYPE}
                selectedId={selectedFacilityId}
                labelStyle={labelStyle}
            />
        );
        constraintDetailsSection = (
            <ConstraintsDetailsSection
                sectionLabel="Approximated constraints"
                onHeaderDetailsClicked={detailedConstraintsDialogOnClick}
                constraintDetailsRows={getConstraintDetailRows(
                    scopeViewConstraints,
                    originalPowerAmounts,
                    editedPowerAmounts,
                    hasAllocatedCost
                )}
            />
        );
        customInjectionDialog = (
            <CustomInjectionDialog
                isOpen={customInjectionDialogOpen}
                onClose={() => {
                    setCustomInjectionDialogOpen(false);
                }}
                defaultPowerAmounts={editedPowerAmounts}
                setPowerAmounts={setEditedPowerAmounts}
                getFirstSummaryField={(powerAmounts) =>
                    hasAllocatedCost
                        ? getFormattedAllocatedCost(
                              getAllocatedCost(
                                  scopeViewConstraints,
                                  powerAmounts
                              )
                          )
                        : undefined
                }
                getSecondSummaryField={(powerAmounts) =>
                    getFormattedTotalCost(
                        getTotalCost(scopeViewConstraints, powerAmounts)
                    )
                }
                selectedId={selectedFacilityId}
            />
        );

        const constraintTableConfig: ConstraintTableConfig = {
            hideAllocatedCosts: !hasAllocatedCost,
            hideTotalCosts: false,
            showPostStudyLoad: true,
            showContingency: showContingency,
            generatorType: generator
        };
        const exportConstraints = getExportConstraintsToCSV(
            tapName,
            scopeViewConstraints,
            {
                constraintTableConfig,
                labelStyle,
                powerAmounts: editedPowerAmounts
            }
        );
        constraintDetailsDialog = (
            <ConstraintDetailsDialog
                isOpen={detailedConstraintsDialogOpen}
                closeDialog={() => {
                    setDetailedConstraintsDialogOpen(false);
                }}
                exportConstraints={exportConstraints}
                title={`Constraints approximated @ ${getAllSizeLabels(
                    editedPowerAmounts,
                    showCapacity,
                    shouldShowCharging,
                    labelStyle
                )}`}
                selectedId={selectedFacilityId}
            >
                <ConstraintDetailsDialogTable
                    constraints={scopeViewConstraints}
                    powerAmounts={editedPowerAmounts}
                    showAllConstraints={false}
                    labelStyle={labelStyle}
                    showCapacityLabel={showCapacity}
                    showChargingLabel={shouldShowCharging}
                    constraintTableConfig={constraintTableConfig}
                />
            </ConstraintDetailsDialog>
        );
    } else {
        constraintDetailsSection = (
            <ConstraintsDetailsSection
                sectionLabel="Approximated constraints"
                onHeaderDetailsClicked={detailedConstraintsDialogOnClick}
                constraintDetailsRows={getCurtailmentDetailRows(
                    scopeViewConstraints,
                    generator
                )}
            />
        );
        constraintDetailsDialog = (
            <CurtailmentConstraintDetailsDialog
                dialogTitle={"Constraints approximated"}
                detailsDialogOpen={detailedConstraintsDialogOpen}
                setDetailsDialogOpen={setDetailedConstraintsDialogOpen}
                maybeConstraints={maybeConstraints}
                powerAmounts={editedPowerAmounts}
                selectedId={selectedFacilityId}
                exportDisplayName={tapName}
            />
        );
    }

    const energyTag = (
        <AvailableTag
            size={originalPowerAmounts.energySize}
            editedSize={editedPowerAmounts.energySize}
            onClick={tagOnClick}
            resourceType={ENERGY_RESOURCE_TYPE}
            selectedId={selectedFacilityId}
            labelStyle={labelStyle}
        />
    );
    const chargingTag = shouldShowCharging && (
        <AvailableTag
            size={originalPowerAmounts.chargingSize}
            editedSize={editedPowerAmounts.chargingSize}
            onClick={tagOnClick}
            resourceType={CHARGING_RESOURCE_TYPE}
            selectedId={selectedFacilityId}
            labelStyle={labelStyle}
        />
    );

    return (
        <>
            <div className="LineTapPane-wrapper">
                <div className="LineTapPane-main-content-wrapper">
                    <DetailPaneHeader
                        title={tapName}
                        icon={icon}
                        onCancel={onCancel}
                        energyTag={energyTag}
                        chargingTag={chargingTag}
                        capacityTag={capacityTag}
                    />
                    {constraintDetailsSection}
                    <h6 className="bp5-heading LineTapPane-line-details">
                        Line Details
                    </h6>
                    <div className="LineTapPane-row">
                        <div className="LineTapPane-row-label  bp5-text-disabled">
                            From Bus
                        </div>
                        <div
                            className="LineTapPane-row-value LineTapPane-bus-row-value"
                            onClick={generateOnBusClick(fromBus)}
                        >
                            <div className="LineTapPane-bus-capacity-dot">
                                <BusCapacityDot color={fromBusColor} small />
                            </div>
                            {maybeFromBusLockIcon}
                            {fromBus.busName}
                        </div>
                    </div>
                    <div className="LineTapPane-row">
                        <div className="LineTapPane-row-label  bp5-text-disabled">
                            To Bus
                        </div>
                        <div
                            className="LineTapPane-row-value LineTapPane-bus-row-value"
                            onClick={generateOnBusClick(toBus)}
                        >
                            <div className="LineTapPane-bus-capacity-dot">
                                <BusCapacityDot color={toBusColor} small />
                            </div>
                            {maybeToBusLockIcon}
                            {toBus.busName}
                        </div>
                    </div>
                    <div className="LineTapPane-row">
                        <div className="LineTapPane-row-label  bp5-text-disabled">
                            Facility ID
                        </div>
                        <div className="LineTapPane-row-value">
                            {selectedFacilityId}
                        </div>
                    </div>
                    <div className="LineTapPane-row">
                        <div className="LineTapPane-row-label  bp5-text-disabled">
                            Rating
                        </div>
                        <div className="LineTapPane-row-value">
                            {rating.toFixed(0)} MVA
                        </div>
                    </div>
                    <div className="LineTapPane-row">
                        <div className="LineTapPane-row-label  bp5-text-disabled">
                            Line Length
                        </div>
                        <div className="LineTapPane-row-value">
                            {lineLength.toFixed(1)} miles
                        </div>
                    </div>
                </div>
            </div>
            {customInjectionDialog}
            {constraintDetailsDialog}
        </>
    );
};

export default LineTapPane;

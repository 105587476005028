import {
    Button,
    ButtonGroup,
    Menu,
    MenuItem,
    Popover,
    Position
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useUserDataContext } from "contexts/UserDataContext/UserDataContext";
import { useCurrentScenarioMetadata } from "in_queue/contexts/ScenarioPageContext";
import { useScenarioCreationDisabled } from "in_queue/hooks/useScenarioCreationDisabled";
import { ScenarioStatus } from "in_queue/types/scenarioType";
import { useState } from "react";
import { ADMIN_TYPE } from "types/roleType";
import { AdminOnlyTooltip } from "../common/AdminOnlyTooltip";
import { AllScenarioTooltips } from "../common/AllScenarioTooltips";
import { ScenarioDialog } from "../ScenarioDialog/ScenarioDialog";
import { ScenarioVisibilityDialog } from "../ScenarioVisibilityDialog";
import ArchiveScenarioAlert from "./ScenarioActions/ArchiveScenarioAlert";
import {
    DeleteScenarioAlert,
    DeleteScenarioMenuItem
} from "./ScenarioActions/DeleteScenarioAlert";
import DuplicateScenarioAction from "./ScenarioActions/DuplicateScenarioAction";
import SubmitScenarioRunButton from "./SubmitScenarioRunButton";

const ScenarioActions: React.FC = () => {
    const scenario = useCurrentScenarioMetadata();

    const { status } = scenario;
    if (status === ScenarioStatus.Draft) {
        return <DraftScenarioActions />;
    } else if (
        status === ScenarioStatus.RunRequested ||
        status === ScenarioStatus.RunSubmitted ||
        status === ScenarioStatus.Running ||
        status === ScenarioStatus.RunSucceeded
    ) {
        return <CompletedScenarioActions />;
    } else if (status === ScenarioStatus.RunFailed) {
        return <FailedScenarioActions />;
    } else if (status === ScenarioStatus.Archived) {
        return <ArchivedScenarioActions />;
    }

    return null;
};

const DraftScenarioActions = () => {
    const scenario = useCurrentScenarioMetadata();
    const { role } = useUserDataContext();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogAction, setDialogAction] = useState<"create" | "update">(
        "create"
    );
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

    const { status } = scenario;

    if (status !== ScenarioStatus.Draft) {
        return <></>;
    }

    const isNotAdmin = role !== ADMIN_TYPE;

    return (
        <>
            <ScenarioDialog
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                scenario={scenario}
                action={dialogAction}
            />
            <DeleteScenarioAlert
                isOpen={isDeleteAlertOpen}
                setIsOpen={setIsDeleteAlertOpen}
            />
            <AdminOnlyTooltip>
                <ButtonGroup>
                    <Button
                        icon={IconNames.EDIT}
                        text="Edit"
                        onClick={() => {
                            setDialogAction("update");
                            setIsDialogOpen(true);
                        }}
                        disabled={isNotAdmin}
                    />

                    <Popover
                        position={Position.BOTTOM_RIGHT}
                        content={
                            <Menu>
                                <AllScenarioTooltips stage={scenario.stage}>
                                    <MenuItem
                                        icon={IconNames.DUPLICATE}
                                        text="Duplicate"
                                        onClick={() => {
                                            setDialogAction("create");
                                            setIsDialogOpen(true);
                                        }}
                                        disabled={useScenarioCreationDisabled(
                                            scenario.stage
                                        )}
                                    />
                                </AllScenarioTooltips>
                                <DeleteScenarioMenuItem
                                    setIsOpen={setIsDeleteAlertOpen}
                                />
                            </Menu>
                        }
                    >
                        <Button
                            icon={IconNames.CARET_DOWN}
                            disabled={isNotAdmin}
                        />
                    </Popover>
                </ButtonGroup>
            </AdminOnlyTooltip>

            <SubmitScenarioRunButton scenarioId={scenario.scenarioId} />
        </>
    );
};

const CompletedScenarioActions = () => {
    const scenario = useCurrentScenarioMetadata();
    const [isVisibilityDialogOpen, setIsVisibilityDialogOpen] = useState(false);
    const [isArchiveAlertOpen, setIsArchiveAlertOpen] = useState(false);

    if (scenario.type === "SHARED") {
        return <></>;
    }

    return (
        <ButtonGroup>
            <DuplicateScenarioAction />
            <Popover
                position={Position.BOTTOM_RIGHT}
                content={
                    <Menu>
                        <MenuItem
                            icon={IconNames.EYE_OPEN}
                            text="Edit visibility"
                            onClick={() => {
                                setIsVisibilityDialogOpen(true);
                            }}
                        />
                        <MenuItem
                            icon={IconNames.ARCHIVE}
                            text="Archive scenario"
                            onClick={() => {
                                setIsArchiveAlertOpen(true);
                            }}
                        />
                    </Menu>
                }
            >
                <Button icon={IconNames.CARET_DOWN} />
            </Popover>
            <ScenarioVisibilityDialog
                isOpen={isVisibilityDialogOpen}
                onClose={() => setIsVisibilityDialogOpen(false)}
                scenario={scenario}
            />
            <ArchiveScenarioAlert
                isOpen={isArchiveAlertOpen}
                setIsOpen={setIsArchiveAlertOpen}
                action="archive"
            />
        </ButtonGroup>
    );
};

const FailedScenarioActions = () => {
    const { role } = useUserDataContext();
    const isNotAdmin = role !== ADMIN_TYPE;

    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

    return (
        <>
            <DeleteScenarioAlert
                isOpen={isDeleteAlertOpen}
                setIsOpen={setIsDeleteAlertOpen}
            />
            <AdminOnlyTooltip>
                <ButtonGroup>
                    <DuplicateScenarioAction />

                    <Popover
                        position={Position.BOTTOM_RIGHT}
                        content={
                            <Menu>
                                <DeleteScenarioMenuItem
                                    setIsOpen={setIsDeleteAlertOpen}
                                />
                            </Menu>
                        }
                    >
                        <Button
                            icon={IconNames.CARET_DOWN}
                            disabled={isNotAdmin}
                        />
                    </Popover>
                </ButtonGroup>
            </AdminOnlyTooltip>
        </>
    );
};

const ArchivedScenarioActions = () => {
    const { role } = useUserDataContext();
    const isNotAdmin = role !== ADMIN_TYPE;

    const [isUnarchiveAlertOpen, setIsUnarchiveAlertOpen] = useState(false);

    return (
        <>
            <ArchiveScenarioAlert
                isOpen={isUnarchiveAlertOpen}
                setIsOpen={setIsUnarchiveAlertOpen}
                action="unarchive"
            />
            <AdminOnlyTooltip>
                <ButtonGroup>
                    <Button
                        icon={IconNames.UNARCHIVE}
                        text="Unarchive scenario"
                        onClick={() => setIsUnarchiveAlertOpen(true)}
                        disabled={isNotAdmin}
                    />
                </ButtonGroup>
            </AdminOnlyTooltip>
        </>
    );
};

export default ScenarioActions;

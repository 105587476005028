import { TextArea } from "@blueprintjs/core";
import FilterWrapper from "../filters/FilterWrapper";

type ScreeningViewDescriptionProps = {
    readonly description: string;
    readonly setDescription: (newDescription: string) => void;
};

const ScreeningViewDescription: React.FC<ScreeningViewDescriptionProps> = (
    props
) => {
    const { description, setDescription } = props;

    return (
        <FilterWrapper label={"Description"} alignLabelToTop={true}>
            <TextArea
                value={description}
                fill={true}
                placeholder="Enter a description..."
                onChange={(value) => setDescription(value.currentTarget.value)}
            />
        </FilterWrapper>
    );
};

export default ScreeningViewDescription;

import { Colors } from "@blueprintjs/core";
import DEFAULT_LAYER_PREVIEW from "../components/layerStyleSelector/default_preview.png";
import SATELLITE_LAYER_PREVIEW from "../components/layerStyleSelector/satellite_preview.png";

// This color comes from blueprint3
const BLUE_BRANCH_COLOR = "#48AFF0";
const BLACK_BRANCH_COLOR = Colors.DARK_GRAY5;

export type STYLE_LAYER_THEME = "default" | "dark";

export const DEFAULT_LAYER_STYLE_ID = "default";
export const SATELLITE_LAYER_STYLE_ID = "satellite";

export const LAYER_STYLE_IDS = [
    DEFAULT_LAYER_STYLE_ID,
    SATELLITE_LAYER_STYLE_ID
] as const;

export type LayerStyleId = (typeof LAYER_STYLE_IDS)[number];

export type LayerStyle = {
    readonly id: LayerStyleId;
    readonly name: string;
    readonly previewSource: string;
    readonly mapboxSource: string;
    readonly branchColor: string;
    readonly branchWidth: number;
    readonly generatorTheme: STYLE_LAYER_THEME;
};

export const DEFAULT_LAYER_STYLE: LayerStyle = {
    id: DEFAULT_LAYER_STYLE_ID,
    name: "Default",
    previewSource: DEFAULT_LAYER_PREVIEW,
    mapboxSource: "mapbox://styles/mapbox/light-v10",
    branchColor: BLACK_BRANCH_COLOR,
    branchWidth: 2,
    generatorTheme: "default"
};

export const SATELLITE_LAYER_STYLE: LayerStyle = {
    id: SATELLITE_LAYER_STYLE_ID,
    name: "Satellite",
    previewSource: SATELLITE_LAYER_PREVIEW,
    mapboxSource: "mapbox://styles/mapbox/satellite-streets-v11",
    branchColor: BLUE_BRANCH_COLOR,
    branchWidth: 3,
    generatorTheme: "dark"
};

export const layerStyles: {
    readonly [key in LayerStyleId]: LayerStyle;
} = {
    default: DEFAULT_LAYER_STYLE,
    satellite: SATELLITE_LAYER_STYLE
};

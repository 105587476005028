import { Switch } from "@blueprintjs/core";
import { segmentTrackIncludePreExistingChanged } from "analytics/analyticTrackEvents";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import React from "react";
import FilterWrapper from "./FilterWrapper";

const PreexistingFilter: React.FC = () => {
    const {
        busFiltersConfiguration: {
            busFilters,
            toggleIncludePreExistingConstraints,
            filtersDisabled
        }
    } = useMapViewConfiguration();
    const { includePreExistingConstraints } = busFilters;

    return (
        <FilterWrapper
            label="Include 0 MW constraints"
            helperText={
                'Toggle to "No" to remove constraints that are triggered at 0 MW. ' +
                "This is a rough approximation for prior queues paying for these constraints."
            }
        >
            <Switch
                checked={includePreExistingConstraints}
                onChange={(evt) => {
                    toggleIncludePreExistingConstraints();
                    segmentTrackIncludePreExistingChanged(evt.target.checked);
                }}
                innerLabel="No"
                innerLabelChecked="Yes"
                style={{ marginBottom: 0 }}
                alignIndicator="right"
                large={true}
                disabled={filtersDisabled}
            />
        </FilterWrapper>
    );
};

export default PreexistingFilter;

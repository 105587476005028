import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import css from "./SectionHeader.module.scss";

interface SectionHeaderProps {
    level: "h1" | "h2";
    title: string | JSX.Element;
    isLoading?: boolean;
    actions?: JSX.Element;
}
export const SectionHeader: React.FC<SectionHeaderProps> = ({
    level,
    title,
    isLoading,
    actions
}) => {
    const loadingClassname = { [Classes.SKELETON]: isLoading };
    const headerElement =
        level === "h1" ? (
            <h1 className={classNames(css["h1"], loadingClassname)}>{title}</h1>
        ) : (
            <h2 className={classNames(css["h2"], loadingClassname)}>{title}</h2>
        );
    return (
        <div className={css["header"]}>
            {headerElement}
            <div className={classNames(css["actions"], loadingClassname)}>
                {actions}
            </div>
        </div>
    );
};

import { H6 } from "@blueprintjs/core";
import BusCapacityDot from "components/common/BusCapacityDot";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import {
    HIGH_AVAILABLE_CAPACITY_COLOR,
    LOW_AVAILABLE_CAPACITY_COLOR,
    MED_AVAILABLE_CAPACITY_COLOR
} from "sharedStyles/capacityColorStyles";
import { CapacityThresholds } from "types/busFilterTypes";
import { isCurtailmentConfig } from "types/componentConfigPerRegion";
import { STORAGE_TYPE } from "types/generatorType";
import "./Legend.scss";

type LegendProps = {
    readonly hideLegendTitle?: boolean;
};

const Legend: React.FC<LegendProps> = (props) => {
    const { generator, componentConfig } = useMapViewRoutingMetadata();
    const {
        busFiltersConfiguration: { busFilters }
    } = useMapViewConfiguration();
    const { capacityThresholds } = busFilters;

    const isCurtailment = isCurtailmentConfig(componentConfig);

    return (
        <div className="Legend-section-wrapper">
            {!props.hideLegendTitle && (
                <div className="Legend-header-wrapper">
                    <H6 className="Legend-h6">Legend</H6>
                </div>
            )}
            <div>
                {getLegendRow(
                    "highThreshold",
                    capacityThresholds,
                    !isCurtailment
                )}
                {getLegendRow(
                    "mediumThreshold",
                    capacityThresholds,
                    !isCurtailment
                )}
                {getLegendRow(
                    "lowThreshold",
                    capacityThresholds,
                    !isCurtailment
                )}
            </div>
            {isCurtailment && generator === STORAGE_TYPE && (
                <div className="Legend-storage-info-text bp5-text-small bp5-text-muted">
                    Color-coded by the minimum of MW(D) and MW(C).
                </div>
            )}
        </div>
    );
};

const getLegendRow = (
    threshold: keyof CapacityThresholds,
    thresholds: CapacityThresholds,
    useMWE: boolean
) => {
    const mwString = useMWE ? "MWE" : "MW";

    let color;
    let label;
    if (threshold === "highThreshold") {
        color = HIGH_AVAILABLE_CAPACITY_COLOR;
        label = `${thresholds.highThreshold}+ ${mwString}`;
    } else if (threshold === "mediumThreshold") {
        color = MED_AVAILABLE_CAPACITY_COLOR;
        label = `${thresholds.mediumThreshold}-${thresholds.highThreshold} ${mwString}`;
    } else {
        color = LOW_AVAILABLE_CAPACITY_COLOR;
        label = `${thresholds.lowThreshold}-${thresholds.mediumThreshold} ${mwString}`;
    }

    return (
        <div className="Legend-legend-row">
            <BusCapacityDot color={color} small={true} />
            <div className="Legend-legend-row-text">{label}</div>
        </div>
    );
};

export default Legend;

import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    Intent
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { useNavigate } from "react-router-dom";

type ErrorDialogProps = {
    readonly title: string;
    readonly friendlyDescription: string;
    readonly errorMessage?: string;
};

export const ErrorDialog: React.FC<ErrorDialogProps> = (props) => {
    const { title, friendlyDescription, errorMessage } = props;

    const navigate = useNavigate();

    return (
        <Dialog
            title={title}
            icon={IconNames.WarningSign}
            isOpen={true}
            isCloseButtonShown={false}
        >
            <DialogBody>
                <div>{friendlyDescription}</div>
                <br />
                {errorMessage && (
                    <div>
                        <span>Error message: </span>
                        <code className="bp5-code">{errorMessage}</code>
                    </div>
                )}
            </DialogBody>
            <DialogFooter
                actions={
                    <Button
                        text="Go home"
                        intent={Intent.PRIMARY}
                        onClick={() => navigate("/")}
                    />
                }
            />
        </Dialog>
    );
};

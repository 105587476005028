import { Tooltip } from "@blueprintjs/core";
import { useUserDataContext } from "contexts/UserDataContext/UserDataContext";
import { ADMIN_TYPE } from "types/roleType";
import "./Tooltip.scss";

export const AdminOnlyTooltip: React.FC = ({ children }) => {
    const { role } = useUserDataContext();
    return (
        <Tooltip
            content="Only admins can perform this action"
            position="bottom"
            disabled={role === ADMIN_TYPE}
            className="tooltip"
        >
            {children}
        </Tooltip>
    );
};

import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BusId, decryptBusId } from "types/busType";
import { QUERY_PARAMS_FOR_MAP_VIEW } from "types/queryParams";
import {
    getMaybeSingleSelectedBus,
    SelectedBusesAtLocation
} from "types/selectedBusesAtLocation";
import { SelectedFacility } from "types/selectedFacility";
import { SelectedBusesContextProps } from "./SelectedBusesContext";

const useCreateSelectedBuses = (): SelectedBusesContextProps => {
    const [queryParams] = useSearchParams();

    const [selectedBusesAtLocation, setSelectedBusesAtLocation] =
        useState<SelectedBusesAtLocation | null>(
            maybeGetSelectedBusIdFromQueryParams({ queryParams })
        );
    const [selectedFacility, setSelectedFacility] =
        useState<SelectedFacility | null>(null);

    const selectSingleBus = (busId: BusId) => {
        setSelectedBusesAtLocation(
            getMaybeSingleSelectedBus({ selectedBusId: busId })
        );
        setSelectedFacility(null);
    };

    const selectMultipleBuses = ({
        selectedBusId,
        busIds
    }: {
        selectedBusId: BusId;
        busIds: BusId[];
    }) => {
        setSelectedBusesAtLocation({
            selectedBusId,
            busesAtLocation: busIds
        });
        setSelectedFacility(null);
    };

    const updateSelectedBusId = (busId: BusId) => {
        if (selectedBusesAtLocation) {
            setSelectedBusesAtLocation({
                ...selectedBusesAtLocation,
                selectedBusId: busId
            });
        }
        setSelectedFacility(null);
    };

    const clearSelectedBuses = () => {
        setSelectedBusesAtLocation(null);
        setSelectedFacility(null);
    };

    const selectLineTap = (facility: SelectedFacility) => {
        const { facilityId, fromBusId, toBusId } = facility;
        setSelectedBusesAtLocation(null);
        setSelectedFacility({ facilityId, fromBusId, toBusId });
    };

    const value = useMemo(
        () => ({
            selectedBusesAtLocation,
            selectedFacility,
            selectSingleBus,
            selectMultipleBuses,
            updateSelectedBusId,
            clearSelectedBuses,
            selectLineTap
        }),
        [selectedBusesAtLocation, selectedFacility]
    );

    return value;
};

const maybeGetSelectedBusIdFromQueryParams = ({
    queryParams
}: {
    queryParams: URLSearchParams;
}): SelectedBusesAtLocation | null => {
    const maybeSelectedBusId = queryParams.get(
        QUERY_PARAMS_FOR_MAP_VIEW.selectedBusId
    );
    const maybeEncryptedSelectedBusId = queryParams.get(
        QUERY_PARAMS_FOR_MAP_VIEW.encryptedSelectedBusId
    );

    if (maybeSelectedBusId !== null) {
        return getMaybeSingleSelectedBus({
            selectedBusId: maybeSelectedBusId
        });
    } else if (maybeEncryptedSelectedBusId !== null) {
        const decryptedBusId = decryptBusId(maybeEncryptedSelectedBusId);
        return getMaybeSingleSelectedBus({
            selectedBusId: decryptedBusId
        });
    }
    return null;
};

export { useCreateSelectedBuses };

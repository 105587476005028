import {
    ControlGroup,
    FormGroup,
    NumericInput,
    Switch
} from "@blueprintjs/core";
import { useRegionConfig } from "in_queue/contexts/ClusterContext";
import { useState } from "react";

type ProjectSizeInputProps = {
    erisSizeReduction: number;
    nrisSizeReduction: number;
    onChangeSizeReduction: (params: {
        type: "eris" | "nris" | "both";
        newSize: number;
    }) => void;
};

export const ProjectSizeInput: React.FC<ProjectSizeInputProps> = ({
    erisSizeReduction,
    nrisSizeReduction,
    onChangeSizeReduction
}: ProjectSizeInputProps) => {
    const config = useRegionConfig();
    const [editSeparately, setEditSeparately] = useState(
        erisSizeReduction !== nrisSizeReduction
    );

    const toggleEditSeparately = () => {
        // When going from editing separately to editing both simultaneously, we want
        // to use the erisSizeReduction as the default value for both reductions.
        // On the contrary, updating the size reductions is not be needed when switching to
        // editing separately because both the erisSizeReduction and nrisSizeReduction will
        // already have the same value.
        const newEditSeparately = !editSeparately;
        if (!newEditSeparately) {
            onChangeSizeReduction({
                type: "both",
                newSize: erisSizeReduction
            });
        }
        setEditSeparately(newEditSeparately);
    };

    const editSeparatelySwitch = (
        <Switch
            label={`Adjust ${config.sizeLabel.energyLabel} + ${config.sizeLabel.capacityLabel} separately`}
            checked={editSeparately}
            onChange={toggleEditSeparately}
        />
    );

    return editSeparately ? (
        <ControlGroup>
            <FormGroup
                label={`${config.sizeLabel.energyLabel} size reduction (%)`}
            >
                <NumericInput
                    stepSize={1}
                    min={0}
                    max={100}
                    value={erisSizeReduction}
                    onValueChange={(newValue) => {
                        if (newValue >= 0 && newValue <= 100) {
                            onChangeSizeReduction({
                                type: "eris",
                                newSize: newValue
                            });
                        }
                    }}
                    required
                />
                {editSeparatelySwitch}
            </FormGroup>
            <FormGroup
                label={`${config.sizeLabel.capacityLabel} size reduction (%)`}
            >
                <NumericInput
                    stepSize={1}
                    min={0}
                    max={100}
                    value={nrisSizeReduction}
                    onValueChange={(newValue) => {
                        if (newValue >= 0 && newValue <= 100) {
                            onChangeSizeReduction({
                                type: "nris",
                                newSize: newValue
                            });
                        }
                    }}
                    required
                />
            </FormGroup>
        </ControlGroup>
    ) : (
        <FormGroup label="Project size reduction (%)">
            <NumericInput
                stepSize={1}
                min={0}
                max={100}
                value={erisSizeReduction}
                onValueChange={(newValue) => {
                    if (newValue >= 0 && newValue <= 100) {
                        onChangeSizeReduction({
                            type: "both",
                            newSize: newValue
                        });
                    }
                }}
                required
            />
            {editSeparatelySwitch}
        </FormGroup>
    );
};

import { Button, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Link } from "react-router-dom";
import css from "./NoSubscriptions.module.scss";

const NoSubscriptions: React.FC = () => {
    return (
        <NonIdealState
            className={css["non-ideal-state"]}
            title="Access not available"
            icon={IconNames.LOCK}
            description={
                <div>
                    You currently do not have access to Nira’s in-queue tools.
                    Please contact{" "}
                    <a href="mailto:support@niraenergy.com">
                        support@niraenergy.com
                    </a>{" "}
                    if you are interested in analyzing queued projects in MISO,
                    PJM, and SPP.
                </div>
            }
            action={
                <Link to="/">
                    <Button icon="path-search" text="Back to Prospecting app" />
                </Link>
            }
        />
    );
};

export default NoSubscriptions;

import { Intent, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, MultiSelect } from "@blueprintjs/select";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import {
    REGION_TO_STATES,
    STATES_TO_DISPLAY_NAME,
    StateType
} from "types/stateType";
import FilterWrapper from "../filters/FilterWrapper";
import "./StateSelector.scss";

type StateSelectorProps = {
    readonly selectedStates: readonly StateType[];
    readonly setSelectedStates: (
        newSelectedStates: readonly StateType[]
    ) => void;
};

const StateSelector: React.FC<StateSelectorProps> = (props) => {
    const { selectedStates, setSelectedStates } = props;
    const { region } = useMapViewRoutingMetadata();

    const stateRenderer: ItemRenderer<StateType> = (
        state: StateType,
        { handleClick, modifiers }
    ) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                key={state}
                text={STATES_TO_DISPLAY_NAME[state]}
                onClick={handleClick}
                role="listoption"
                icon={
                    selectedStates.includes(state)
                        ? IconNames.SMALL_TICK
                        : IconNames.BLANK
                }
            />
        );
    };

    return (
        <FilterWrapper label={"States"} alignLabelToTop={true}>
            <MultiSelect
                className="StateSelector-select"
                popoverProps={{ minimal: true }}
                items={[...Array.from(REGION_TO_STATES[region]).sort()]}
                selectedItems={[...selectedStates]}
                fill={true}
                onClear={() => setSelectedStates([])}
                tagRenderer={(state: StateType) => {
                    return STATES_TO_DISPLAY_NAME[state];
                }}
                itemRenderer={stateRenderer}
                onItemSelect={(state: StateType) => {
                    if (selectedStates.includes(state)) {
                        setSelectedStates(
                            selectedStates.filter((s) => s !== state)
                        );
                    } else {
                        const newStates = Array.from(
                            new Set([...selectedStates, state])
                        );
                        setSelectedStates(newStates);
                    }
                }}
                tagInputProps={{
                    tagProps: { minimal: true, intent: Intent.PRIMARY }
                }}
                onRemove={(state) =>
                    setSelectedStates(selectedStates.filter((s) => s !== state))
                }
            />
        </FilterWrapper>
    );
};

export default StateSelector;

import { Icon, IconSize } from "@blueprintjs/core";
import React from "react";
import "./InfoIcon.scss";

const InfoIcon: React.FC = () => {
    return (
        <Icon
            className={"InfoIcon-icon bp5-text-disabled"}
            icon="info-sign"
            iconSize={IconSize.STANDARD}
        />
    );
};

export default InfoIcon;

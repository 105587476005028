import { H6, Icon, IconSize } from "@blueprintjs/core";
import React, { useState } from "react";
import {
    DEFAULT_LAYER_STYLE,
    LayerStyleId,
    SATELLITE_LAYER_STYLE
} from "types/layerStyleTypes";
import LayerPreview from "./LayerPreview";
import "./LayerStyleSelector.scss";
import Legend from "./Legend";

const LayerStyleSelector: React.FC = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [layerStyleHovered, setLayerStyleHovered] = useState<
        LayerStyleId | undefined
    >(undefined);

    return (
        <div
            className="LayerStyleSelector-wrapper"
            onMouseOver={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {isHovered && (
                <div className="LayerStyleSelector-hover-selector">
                    <Legend />
                    <div className="LayerStyleSelector-layers-section-wrapper">
                        <H6 className="LayerStyleSelector-header-wrapper">
                            Layers
                        </H6>
                        <div className="LayerStyleSelector-layers-wrapper">
                            <LayerPreview
                                style={DEFAULT_LAYER_STYLE}
                                layerStyleHovered={layerStyleHovered}
                                setLayerStyleHovered={setLayerStyleHovered}
                            />
                            <LayerPreview
                                style={SATELLITE_LAYER_STYLE}
                                layerStyleHovered={layerStyleHovered}
                                setLayerStyleHovered={setLayerStyleHovered}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className="LayerStyleSelector-button-wrapper">
                <Icon
                    className="LayerStyleSelector-icon"
                    icon="layers"
                    size={IconSize.LARGE}
                />
            </div>
        </div>
    );
};

export default LayerStyleSelector;

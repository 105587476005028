import { useQuery } from "@apollo/client";
import { BoundingBox } from "contexts/MapViewConfigurationContext/hooks/useBoundingBox";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { useScreeningViewContext } from "contexts/ScreeningViewContext/ScreeningViewContext";
import {
    GetBusesWithCapacityCostQuery,
    GetBusesWithCapacityCostQueryResult,
    GetBusesWithCapacityCostQueryVariables
} from "generated/graphql";
import { loader } from "graphql.macro";
import {
    maybeHideLockedBusesFilter,
    maybeScreeningViewStatesFilter
} from "graphql/helpers/busHelpers";
import { getCapacityCostsWhereClauseWithScopeView } from "graphql/helpers/capacityCostsWhereClause";
import {
    ALL_LOCATION_CODES,
    convertHasuraBusesToNiraBusesAndSplitBasedOnAuth,
    HIGH_CONFIDENCE_LOCATION_CODES,
    ScopedBus,
    UnauthorizedBus
} from "types/busType";
import { Voltage } from "types/voltageTypes";

const GET_BUSES_WITH_CAPACITY_COST = loader(
    "src/graphql/getBusesWithCapacityCost.graphql"
);

interface BusesContext {
    readonly scopedBuses: readonly ScopedBus[];
    readonly isLoading: boolean;
}

const useBusesWithCapacityCostsVariables = (
    boundingBoxOverride?: BoundingBox,
    voltagesOverride?: readonly Voltage[]
): GetBusesWithCapacityCostQueryVariables => {
    const { region, scopeView } = useMapViewRoutingMetadata();
    const {
        busFiltersConfiguration: { busFilters },
        boundingBoxConfiguration: { boundingBox }
    } = useMapViewConfiguration();
    const maybeScreeningViewData = useScreeningViewContext();
    const queryBoundingBox = boundingBoxOverride ?? boundingBox;
    const queryVoltages = voltagesOverride ?? busFilters.voltages;

    const capacityCostsWhereclause = getCapacityCostsWhereClauseWithScopeView({
        scope: busFilters.scope,
        scopeView: scopeView,
        maybeMaxAllocatedCosts: busFilters.maybeMaxAllocatedCosts.enabled
            ? busFilters.maybeMaxAllocatedCosts.maxCosts
            : undefined,
        maybeMaxTotalCosts: busFilters.maybeMaxTotalCosts.enabled
            ? busFilters.maybeMaxTotalCosts.maxCosts
            : undefined,
        includePreExistingConstraints: busFilters.includePreExistingConstraints
    });

    return {
        ...queryBoundingBox,
        voltages: [...queryVoltages],
        scope: busFilters.scope,
        capacity_costs_where_clause: capacityCostsWhereclause,
        region,
        filter_locked_buses_where_clause: maybeHideLockedBusesFilter(
            busFilters.hideLockedBuses
        ),
        locationCodes: busFilters.hideLowConfidenceBuses
            ? HIGH_CONFIDENCE_LOCATION_CODES
            : ALL_LOCATION_CODES,
        filter_states_where_clause: maybeScreeningViewStatesFilter(
            maybeScreeningViewData
        )
    };
};

const useBusData = (): BusesContext => {
    const variables = useBusesWithCapacityCostsVariables();

    const activeDataQuery = useQuery<
        GetBusesWithCapacityCostQuery,
        GetBusesWithCapacityCostQueryVariables
    >(GET_BUSES_WITH_CAPACITY_COST, {
        variables: variables,
        fetchPolicy: "no-cache"
    });

    const [scopedBuses] =
        convertQueryResultAndSplitIntoScopedAndUnauthorizedBuses(
            activeDataQuery
        );

    const isLoading =
        activeDataQuery.loading ||
        (activeDataQuery.previousData === undefined &&
            activeDataQuery.data === undefined);

    return {
        scopedBuses,
        isLoading
    };
};

function convertQueryResultAndSplitIntoScopedAndUnauthorizedBuses(
    queryResult: GetBusesWithCapacityCostQueryResult
): readonly [ReadonlyArray<ScopedBus>, ReadonlyArray<UnauthorizedBus>] {
    const currentData: GetBusesWithCapacityCostQuery | undefined =
        queryResult.data;

    if (currentData === undefined) {
        if (queryResult.previousData === undefined) {
            // This means that this is the first time this is ever called
            return [[], []];
        } else {
            return convertHasuraBusesToNiraBusesAndSplitBasedOnAuth(
                queryResult.previousData.buses
            );
        }
    } else {
        return convertHasuraBusesToNiraBusesAndSplitBasedOnAuth(
            currentData.buses
        );
    }
}

export {
    useBusData,
    useBusesWithCapacityCostsVariables,
    convertQueryResultAndSplitIntoScopedAndUnauthorizedBuses
};

import AvailableTag from "components/common/AvailableTag";
import { LabelStyleType } from "components/common/labels";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { ScopedBus } from "types/busType";
import { isPricedConstraintConfig } from "types/componentConfigPerRegion";
import { STORAGE_TYPE } from "types/generatorType";
import {
    CAPACITY_RESOURCE_TYPE,
    CHARGING_RESOURCE_TYPE,
    ResourceType
} from "types/resourceType";

interface SecondTagProps {
    scopedBus: ScopedBus;
    isLoading: boolean;
    labelStyle: LabelStyleType;
}

const SecondTag: React.FC<SecondTagProps> = ({
    scopedBus,
    isLoading,
    labelStyle
}: SecondTagProps) => {
    const { generator, componentConfig } = useMapViewRoutingMetadata();

    let size: number;
    let resourceType: ResourceType;
    if (isPricedConstraintConfig(componentConfig)) {
        if (componentConfig.showCapacity) {
            size = scopedBus.scopedCapacityEnergyCost.capacitySize;
            resourceType = CAPACITY_RESOURCE_TYPE;
        } else if (generator === STORAGE_TYPE) {
            // Only ISONE is priced and has charging, but it has no capacity.
            size = scopedBus.scopedCapacityEnergyCost.chargingSize;
            resourceType = CHARGING_RESOURCE_TYPE;
        } else {
            return null;
        }
    } else {
        if (generator === STORAGE_TYPE) {
            size = scopedBus.scopedCapacityEnergyCost.chargingSize;
            resourceType = CHARGING_RESOURCE_TYPE;
        } else {
            return null;
        }
    }

    return (
        <div className={`BusItem-tag ${isLoading ? "bp5-skeleton" : ""}`}>
            <AvailableTag
                size={size}
                resourceType={resourceType}
                labelStyle={labelStyle}
            />
        </div>
    );
};

export default SecondTag;

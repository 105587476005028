import {
    Buses,
    Capacity_Costs,
    Line_Capacity_Costs,
    Model_Lines,
    Model_Lines_To_Missing_Buses,
    Substations,
    Unlocked_Buses
} from "generated/graphql";
import { convertMaybeToUndefined } from "graphql/helpers/queryHelpers";

export type ConnectedLine = {
    readonly facilityId: string;
    readonly lineLength: number;
    readonly toBus: ConnectedLineBusDetails;
    readonly energySize: number;
    readonly capacitySize: number;
    readonly chargingSize: number;
    readonly allocatedCosts: number;
    readonly totalCosts: number;
};

export type ConnectedLineBusDetails = {
    readonly busId: string;
    readonly busName: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly owner: string;
};

export type LineWithMissingBus = {
    readonly facilityId: string;
    readonly lineLength: number;
    readonly rating: number;
    readonly voltage: number;
    readonly missingBusId: string;
    readonly queuedProjectsAtMissingBus: string | undefined;
};

export type LineTapDetails = {
    readonly facilityId: string;
    readonly tapName: string;
    readonly rating: number;
    readonly lineLength: number;
    readonly energySize: number;
    readonly capacitySize: number;
    readonly chargingSize: number;
    readonly allocatedCost: number;
    readonly totalCost: number;
    readonly fromBus: LineTapBusDetails;
    readonly toBus: LineTapBusDetails;
};

export type LineTapBusDetails = {
    readonly busId: string;
    readonly busName: string;
    readonly energySize: number;
    readonly capacitySize: number;
    readonly chargingSize: number;
    readonly isLocked: boolean;
};

type HasuraConnectedLineWithCapacityCosts = Readonly<
    Pick<Model_Lines, "facility_id" | "line_length"> & {
        from_bus: HasuraFromOrToBus;
        to_bus: HasuraFromOrToBus;
        line_capacity_costs: Array<HasuraLineCapacityCost>;
    }
>;

type HasuraFromOrToBus = Readonly<
    { __typename?: "buses" } & Pick<
        Buses,
        "id" | "bus_display_name" | "latitude" | "longitude"
    > & { substation: HasuraSubstation }
>;

type HasuraSubstation = Readonly<
    { __typename?: "substations" } & Pick<Substations, "substation_owner">
>;

type HasuraLineCapacityCost = Readonly<
    { __typename?: "line_capacity_costs" } & Pick<
        Line_Capacity_Costs,
        | "energy_size"
        | "capacity_size"
        | "charging_size"
        | "upgrade_costs"
        | "total_upgrade_costs"
    >
>;

type HasuraLineWithMissingBus = Readonly<
    { __typename?: "model_lines_to_missing_buses" } & Pick<
        Model_Lines_To_Missing_Buses,
        | "facility_id"
        | "line_length"
        | "rating"
        | "voltage"
        | "missing_bus_id"
        | "queued_projects_at_missing_bus"
    >
>;

type HasuraBusCapacityCosts = Readonly<
    { __typename?: "capacity_costs" } & Pick<
        Capacity_Costs,
        "energy_size" | "capacity_size" | "charging_size"
    >
>;

type HasuraUnlockedBus = Readonly<
    { __typename?: "unlocked_buses" } & Pick<Unlocked_Buses, "unlocked">
>;

type HasuraBusWithCapacityCosts = Readonly<
    { __typename?: "buses" } & Pick<Buses, "id" | "bus_display_name"> & {
            capacity_costs: Array<HasuraBusCapacityCosts>;
        } & { unlocked: Array<HasuraUnlockedBus> }
>;

type HasuraLineTapDetailsWithCapacityCosts = Readonly<
    { __typename?: "model_lines" } & Pick<
        Model_Lines,
        "facility_id" | "rating" | "line_length"
    > & {
            from_bus: HasuraBusWithCapacityCosts;
            to_bus: HasuraBusWithCapacityCosts;
            line_capacity_costs: Array<HasuraLineCapacityCost>;
        }
>;

export const convertHasuraConnectedLinesToNiraConnectedLines = (
    busNumber: string,
    hasuraModelLines: ReadonlyArray<HasuraConnectedLineWithCapacityCosts>
): ReadonlyArray<ConnectedLine> => {
    return hasuraModelLines.map((hasuraModelLine) => {
        return {
            facilityId: hasuraModelLine.facility_id,
            lineLength: hasuraModelLine.line_length,
            toBus:
                busNumber == hasuraModelLine.from_bus.id
                    ? {
                          busId: hasuraModelLine.to_bus.id,
                          busName: hasuraModelLine.to_bus.bus_display_name,
                          latitude: hasuraModelLine.to_bus.latitude,
                          longitude: hasuraModelLine.to_bus.longitude,
                          owner: hasuraModelLine.to_bus.substation
                              .substation_owner
                      }
                    : {
                          busId: hasuraModelLine.from_bus.id,
                          busName: hasuraModelLine.from_bus.bus_display_name,
                          latitude: hasuraModelLine.from_bus.latitude,
                          longitude: hasuraModelLine.from_bus.longitude,
                          owner: hasuraModelLine.from_bus.substation
                              .substation_owner
                      },
            energySize: hasuraModelLine.line_capacity_costs[0].energy_size,
            capacitySize: hasuraModelLine.line_capacity_costs[0].capacity_size,
            chargingSize: hasuraModelLine.line_capacity_costs[0].charging_size,
            allocatedCosts:
                hasuraModelLine.line_capacity_costs[0].upgrade_costs,
            totalCosts:
                hasuraModelLine.line_capacity_costs[0].total_upgrade_costs
        };
    });
};

export const convertHasuraLinesWithMissingBusToNiraLinesWithMissingBus = (
    hasuraLinesWithMissingBuses: ReadonlyArray<HasuraLineWithMissingBus>
): ReadonlyArray<LineWithMissingBus> => {
    return hasuraLinesWithMissingBuses.map((hasuraLineWithMissingBus) => {
        return {
            facilityId: hasuraLineWithMissingBus.facility_id,
            lineLength: hasuraLineWithMissingBus.line_length,
            rating: hasuraLineWithMissingBus.rating,
            voltage: hasuraLineWithMissingBus.voltage,
            missingBusId: hasuraLineWithMissingBus.missing_bus_id,
            queuedProjectsAtMissingBus: convertMaybeToUndefined(
                hasuraLineWithMissingBus.queued_projects_at_missing_bus
            )
        };
    });
};

export const convertHasuraLineTapDetailsToNiraLineTapDetails = (
    hasuraLineTapDetails: HasuraLineTapDetailsWithCapacityCosts
): LineTapDetails => {
    return {
        facilityId: hasuraLineTapDetails.facility_id,
        tapName: `${hasuraLineTapDetails.from_bus.bus_display_name} --- ${hasuraLineTapDetails.to_bus.bus_display_name}`,
        rating: hasuraLineTapDetails.rating,
        lineLength: hasuraLineTapDetails.line_length,
        energySize: hasuraLineTapDetails.line_capacity_costs[0].energy_size,
        capacitySize: hasuraLineTapDetails.line_capacity_costs[0].capacity_size,
        chargingSize: hasuraLineTapDetails.line_capacity_costs[0].charging_size,
        allocatedCost:
            hasuraLineTapDetails.line_capacity_costs[0].upgrade_costs,
        totalCost:
            hasuraLineTapDetails.line_capacity_costs[0].total_upgrade_costs,
        fromBus: {
            busId: hasuraLineTapDetails.from_bus.id,
            busName: hasuraLineTapDetails.from_bus.bus_display_name,
            energySize:
                hasuraLineTapDetails.from_bus.capacity_costs[0].energy_size,
            capacitySize:
                hasuraLineTapDetails.from_bus.capacity_costs[0].capacity_size,
            chargingSize:
                hasuraLineTapDetails.from_bus.capacity_costs[0].charging_size,
            isLocked: hasuraLineTapDetails.from_bus.unlocked.length === 0
        },
        toBus: {
            busId: hasuraLineTapDetails.to_bus.id,
            busName: hasuraLineTapDetails.to_bus.bus_display_name,
            energySize:
                hasuraLineTapDetails.to_bus.capacity_costs[0].energy_size,
            capacitySize:
                hasuraLineTapDetails.to_bus.capacity_costs[0].capacity_size,
            chargingSize:
                hasuraLineTapDetails.to_bus.capacity_costs[0].charging_size,
            isLocked: hasuraLineTapDetails.to_bus.unlocked.length === 0
        }
    };
};

import { BusId } from "./busType";

export type SelectedBusesAtLocation = {
    readonly selectedBusId: BusId;
    busesAtLocation: readonly BusId[];
};

export const getMaybeSingleSelectedBus = ({
    selectedBusId
}: {
    selectedBusId: BusId | null;
}): SelectedBusesAtLocation | null => {
    if (!selectedBusId) {
        return null;
    }

    return {
        selectedBusId,
        busesAtLocation: [selectedBusId]
    };
};

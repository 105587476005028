import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { ScopedBus } from "types/busType";
import { isPricedConstraintConfig } from "types/componentConfigPerRegion";

interface SubheadingTextProps {
    scopedBus: ScopedBus;
    isLoading: boolean;
}

const SubheadingText: React.FC<SubheadingTextProps> = ({
    scopedBus,
    isLoading
}: SubheadingTextProps) => {
    const { componentConfig } = useMapViewRoutingMetadata();

    let subheadingText = "";
    if (isPricedConstraintConfig(componentConfig)) {
        const getCostString = (costNumber: number): string => {
            return `$${(costNumber / 1000000).toFixed(1)}M`;
        };

        const allocatedCostString = getCostString(
            scopedBus.scopedCapacityEnergyCost.allocatedCosts
        );
        const totalCostString = getCostString(
            scopedBus.scopedCapacityEnergyCost.totalCosts
        );

        if (!componentConfig.hasAllocatedCost)
            subheadingText = `${totalCostString} total`;
        else {
            subheadingText = `${allocatedCostString} allocated • ${totalCostString} total`;
        }
    } else {
        subheadingText = `${scopedBus.bus.substation.substationOwner}`;
    }

    return (
        <div
            className={`BusItem-owner-name bp5-text-muted ${
                isLoading ? "bp5-skeleton" : ""
            }`}
        >
            {subheadingText}
        </div>
    );
};

export default SubheadingText;

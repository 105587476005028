import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import useMapViewData from "contexts/MapViewDataContext/hooks/useMapViewData";
import React from "react";
import { Layer, Source } from "react-map-gl";
import { Branch, VoltageToBranchesDict } from "types/branchType";
import { layerStyles } from "types/layerStyleTypes";
import { Voltage } from "types/voltageTypes";
import { getBranchGeoJson } from "./geoJsonUtils/branchGeoJsonUtils";
import { EMPTY_GEOJSON } from "./geoJsonUtils/emptyGeoJson";

export const VISIBLE_BRANCH_LAYER_ID = "branches-visible";
export const BRANCH_LAYER_ID = "branches";

const BranchSource: React.FC = () => {
    const { branches } = useMapViewData();
    const {
        busFiltersConfiguration: {
            busFilters: { voltages }
        },
        layerStyleConfiguration: { layerStyleId }
    } = useMapViewConfiguration();

    // We memoize this calculation so that it's only rerun when there's
    // new branch data or the user-selected voltages have changed
    const sourceData = React.useMemo(
        () => getGeoJson(branches, voltages),
        [branches, voltages]
    );

    return (
        <Source id="branch-data" type="geojson" data={sourceData}>
            {/* This layer is invisible and has a high line-width to make it easier for users to interact with */}
            <Layer
                id={BRANCH_LAYER_ID}
                type="line"
                paint={{
                    "line-opacity": 0,
                    "line-width": 15
                }}
            />
            {/* This layer corresponds to branch lines actually visible on the map */}
            <Layer
                id={VISIBLE_BRANCH_LAYER_ID}
                type="line"
                layout={{
                    "line-join": "round",
                    "line-cap": "round"
                }}
                paint={{
                    "line-color": layerStyles[layerStyleId].branchColor,
                    "line-width": layerStyles[layerStyleId].branchWidth
                }}
            />
        </Source>
    );
};

const getGeoJson = (
    branches: VoltageToBranchesDict | undefined,
    voltages: readonly Voltage[]
): Readonly<GeoJSON.FeatureCollection> => {
    if (branches === undefined) {
        return EMPTY_GEOJSON;
    }

    let allBranches: Branch[] = [];
    voltages.forEach(
        (voltage) => (allBranches = allBranches.concat(branches[voltage]))
    );

    return getBranchGeoJson(allBranches);
};

export default BranchSource;

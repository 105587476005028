import { Tooltip } from "@blueprintjs/core";
import { ScenarioStage } from "in_queue/types/scenarioType";
import React from "react";
import "./Tooltip.scss";

export const CreationDisabledForStageTooltip: React.FC<{
    creationDisabled: boolean;
    stage: ScenarioStage;
}> = ({ creationDisabled, stage, children }) => {
    return (
        <Tooltip
            content={`Creating scenarios while in "${stage
                .toLowerCase()
                .replace("_", " ")}" is now disabled.`}
            position="bottom"
            disabled={!creationDisabled}
            className="tooltip"
        >
            {children}
        </Tooltip>
    );
};

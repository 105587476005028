import { createContext, PropsWithChildren, useContext } from "react";
import { useParams } from "react-router-dom";
import {
    ComponentConfig,
    getComponentConfig,
    isPricedConstraintConfig,
    PricedConstraintConfig
} from "types/componentConfigPerRegion";
import { GeneratorType } from "types/generatorType";
import { RegionType } from "types/regionType";
import {
    getScopeViewFromRegionAndGenerator,
    ScopeViewType
} from "types/scopeViewType";
import {
    convertToUrlDirectoryType,
    getGeneratorTypeFromUrlDirectory,
    getRegionFromUrlDirectory,
    isKnownUrlDirectoryType,
    UrlDirectoryType
} from "types/urlDirectoryType";

type RoutingMetadataType = "mapView" | "homeView";

interface RoutingMetadataContext {
    type: RoutingMetadataType;
}

type MapViewRoutingMetadataContext = RoutingMetadataContext & {
    type: "mapView";
    urlDirectory: UrlDirectoryType;
    region: RegionType;
    generator: GeneratorType;
    componentConfig: ComponentConfig;
    scopeView: ScopeViewType;
};

type HomeViewRoutingMetadataContext = RoutingMetadataContext & {
    type: "homeView";
};

const RoutingMetadataContext = createContext<
    RoutingMetadataContext | undefined
>(undefined);

const RoutingMetadataProvider: React.FC<PropsWithChildren<unknown>> = (
    props: PropsWithChildren<unknown>
) => {
    const { children } = props;
    const { urlDirectory: urlDirectoryParam } = useParams();

    if (urlDirectoryParam && isKnownUrlDirectoryType(urlDirectoryParam)) {
        const urlDirectory = convertToUrlDirectoryType(urlDirectoryParam);
        const region = getRegionFromUrlDirectory(urlDirectory);
        const generator = getGeneratorTypeFromUrlDirectory(urlDirectory);
        const scopeView = getScopeViewFromRegionAndGenerator(region, generator);

        const contextValue: MapViewRoutingMetadataContext = {
            type: "mapView",
            urlDirectory,
            region,
            generator,
            componentConfig: getComponentConfig(region, generator),
            scopeView
        };

        return (
            <RoutingMetadataContext.Provider value={contextValue}>
                {children}
            </RoutingMetadataContext.Provider>
        );
    }

    const contextValue: HomeViewRoutingMetadataContext = { type: "homeView" };
    return (
        <RoutingMetadataContext.Provider value={contextValue}>
            {children}
        </RoutingMetadataContext.Provider>
    );
};

const useRoutingMetadata = (): RoutingMetadataContext => {
    const context = useContext(RoutingMetadataContext);
    if (context === undefined) {
        throw new Error(
            "useMapMetadata must be used within a RoutingMetadataProvider"
        );
    }

    return context;
};

const useMapViewRoutingMetadata = (): MapViewRoutingMetadataContext => {
    const context = useRoutingMetadata();

    if (context.type !== "mapView") {
        throw new Error(
            "useMapViewRoutingMetadata can only be used when RoutingMetadataContext.type === 'mapView'"
        );
    }

    return context as MapViewRoutingMetadataContext;
};

const usePricedConstraintConfig = (): PricedConstraintConfig => {
    const { componentConfig, region } = useMapViewRoutingMetadata();

    if (isPricedConstraintConfig(componentConfig)) {
        return componentConfig;
    }

    throw new Error(
        `Current region (${region}) does not have priced constraints`
    );
};

export {
    RoutingMetadataContext,
    RoutingMetadataProvider,
    useRoutingMetadata,
    useMapViewRoutingMetadata,
    usePricedConstraintConfig
};

import {
    Constraint_Stacks,
    GetConstraintsQuery,
    Maybe
} from "generated/graphql";
import { BusId } from "./busType";
import { convertToResourceType, ResourceType } from "./resourceType";
import { convertToScopeType, ScopeType } from "./scopeType";
import { convertToScopeViewType, ScopeViewType } from "./scopeViewType";

export type SolutionId = string;

type HasuraConstraint = Readonly<
    Pick<
        Constraint_Stacks,
        | "bus_id"
        | "injection_threshold"
        | "monitored_facility_name"
        | "resource_type"
        | "rating"
        | "pre_study_load"
        | "element_type"
        | "line_length"
        | "dfax"
        | "monitored_facility_id"
        | "estimated_cost"
        | "scope"
        | "scope_view"
        | "contingency"
    >
>;

export type Constraint = {
    readonly busId: BusId;
    readonly injectionThreshold: number;
    readonly monitoredFacilityId: string;
    readonly monitoredFacilityName: string;
    readonly resourceType: ResourceType;
    readonly rating: number;
    readonly preStudyLoad: number;
    readonly elementType: string;
    readonly lineLength: number | undefined;
    readonly dfax: number;
    readonly estimatedCost: number | undefined;
    readonly scope: ScopeType;
    readonly scopeView: ScopeViewType;
    readonly contingency: string;
};

const convertHasuraConstraintToConstraint = (
    hasuraConstraint: HasuraConstraint
): Constraint => {
    return {
        busId: hasuraConstraint.bus_id,
        injectionThreshold: hasuraConstraint.injection_threshold,
        monitoredFacilityId: hasuraConstraint.monitored_facility_id,
        monitoredFacilityName: hasuraConstraint.monitored_facility_name,
        resourceType: convertToResourceType(hasuraConstraint.resource_type),
        rating: hasuraConstraint.rating,
        preStudyLoad: hasuraConstraint.pre_study_load,
        elementType: hasuraConstraint.element_type,
        lineLength: convertMaybeToUndefined(hasuraConstraint.line_length),
        dfax: hasuraConstraint.dfax,
        estimatedCost: convertMaybeToUndefined(hasuraConstraint.estimated_cost),
        scope: convertToScopeType(hasuraConstraint.scope),
        scopeView: convertToScopeViewType(hasuraConstraint.scope_view),
        contingency: hasuraConstraint.contingency
    };
};

export const convertHasuraConstraintsToConstraints = (
    hasuraData: GetConstraintsQuery
): ReadonlyArray<Constraint> => {
    return hasuraData.constraint_stacks.map(
        convertHasuraConstraintToConstraint
    );
};

const convertMaybeToUndefined = <T,>(
    maybeValue: Maybe<T> | undefined
): T | undefined => {
    if (maybeValue === null || maybeValue === undefined) {
        return undefined;
    } else {
        return maybeValue;
    }
};

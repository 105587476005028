import { useContext } from "react";
import { ScopedBus } from "types/busType";
import { SelectedScopedBusContext } from "./SelectedScopedBusContext";

const useSelectedScopedBus = (): ScopedBus => {
    const context = useContext(SelectedScopedBusContext);
    if (!context) {
        throw new Error(
            "useSelectedScopedBus must be used within a SelectedScopedBusProvider"
        );
    }

    return context;
};

export default useSelectedScopedBus;

import { Alignment, Button, FormGroup, MenuItem } from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";
import React from "react";
import "./ScreeningViewSelectorTab.scss";

import ScreeningViewMetadataSection from "components/sidebar/screeningView/ScreeningViewMetadataSection";
import { GetScreeningViewsQuery } from "generated/graphql";
import { loader } from "graphql.macro";
import { getHasuraDataAndConvertToNiraType } from "graphql/helpers/queryHelpers";
import { maybeScreeningViewIdFilter } from "graphql/helpers/screeningViewsWhereClause";
import {
    hasuraScreeningViewsToNiraScreeningViews,
    ScreeningView
} from "types/screeningViewType";

type ScreeningViewSelectorTabProps = {
    readonly screeningView: ScreeningView | undefined;
    readonly setScreeningView: (
        screeningView: ScreeningView | undefined
    ) => void;
};

const GET_SCREENING_VIEWS = loader("src/graphql/getScreeningViews.graphql");

const ScreeningViewSelectorTab: React.FC<ScreeningViewSelectorTabProps> = (
    props
) => {
    const { screeningView, setScreeningView } = props;

    let maybeScreeningViews = getHasuraDataAndConvertToNiraType(
        GET_SCREENING_VIEWS,
        (hasuraData: GetScreeningViewsQuery) =>
            hasuraScreeningViewsToNiraScreeningViews(
                hasuraData.screening_views
            ),
        { maybe_id_filter: maybeScreeningViewIdFilter() },
        false,
        "no-cache"
    );
    maybeScreeningViews =
        maybeScreeningViews === "loading" ? [] : maybeScreeningViews;

    return (
        <div>
            <FormGroup label="Select view">
                <Select
                    items={maybeScreeningViews}
                    itemRenderer={getItemRenderer(screeningView)}
                    onItemSelect={(screeningView: ScreeningView) => {
                        setScreeningView(screeningView);
                    }}
                    filterable={false}
                    popoverProps={{ minimal: true }}
                    noResults={
                        <MenuItem
                            disabled={true}
                            text="No screening views active currently"
                            roleStructure="listoption"
                        />
                    }
                >
                    <Button
                        className="ScreeningViewSelectorTab-select"
                        text={screeningView?.title ?? "Select screening view"}
                        rightIcon="caret-down"
                        ellipsizeText={true}
                        alignText={Alignment.LEFT}
                    />
                </Select>
            </FormGroup>
            {screeningView && (
                <div className="ScreeningViewSelectorTab-metadata">
                    <ScreeningViewMetadataSection
                        screeningView={screeningView}
                        showTitle={false}
                    />
                </div>
            )}
        </div>
    );
};

const getItemRenderer: (
    selectedScreeningView: ScreeningView | undefined
) => ItemRenderer<ScreeningView> =
    (selectedScreeningView: ScreeningView | undefined) =>
    (screeningView: ScreeningView, { handleClick }) => {
        return (
            <MenuItem
                active={selectedScreeningView?.id === screeningView.id}
                key={screeningView.id}
                text={screeningView.title}
                onClick={handleClick}
            />
        );
    };

export default ScreeningViewSelectorTab;

import { HTMLTable, Icon, IconSize, Tooltip } from "@blueprintjs/core";
import classnames from "classnames";
import { getAllSizeLabels, LabelStyleType } from "components/common/labels";
import React, { useState } from "react";
import { Constraint } from "types/constraintType";
import { GeneratorType } from "types/generatorType";
import { LineTapConstraint } from "types/lineConstraintType";
import { Loading } from "types/loadingType";
import { PowerAmounts } from "../BusDetailPane";
import {
    getConstraintsOutsideSize,
    getRelevantConstraintsForSize
} from "../constraintsHelpers";
import "./ConstraintsDetailsDialogTable.scss";
import {
    CONSTRAINT_TABLE_COLUMNS,
    getConfiguredColumns,
    getConstraintsTableHeader,
    RenderConstraintTableValueParams
} from "./constraintsTableHelpers";

export interface ConstraintTableConfig {
    hideAllocatedCosts: boolean;
    hideTotalCosts: boolean;
    showPostStudyLoad: boolean;
    // Used for ERCOT
    maybeGetModelName?: (constraint: Constraint | LineTapConstraint) => string;
    showContingency: boolean;
    generatorType: GeneratorType;
}
type ConstraintDetailsDialogTableProps = {
    readonly constraints: Loading<
        ReadonlyArray<Constraint | LineTapConstraint>
    >;
    readonly powerAmounts: PowerAmounts;
    readonly showAllConstraints: boolean;
    readonly showCapacityLabel: boolean;
    readonly showChargingLabel: boolean;
    readonly labelStyle: LabelStyleType;
    readonly constraintTableConfig: ConstraintTableConfig;
};

const ConstraintDetailsDialogTable: React.FC<
    ConstraintDetailsDialogTableProps
> = (props) => {
    const {
        constraints,
        powerAmounts,
        showAllConstraints,
        showCapacityLabel,
        showChargingLabel,
        labelStyle,
        constraintTableConfig
    } = props;
    const [furtherConstraintsOpen, setFurtherConstraintsOpen] = useState(false);

    const headerColumns = getHeaderColumns(constraintTableConfig);

    const numColumns = headerColumns.length;

    return (
        <HTMLTable
            compact={true}
            interactive={true}
            striped={true}
            className="ConstraintDetailsDialogTable-table"
        >
            <thead className="ConstraintDetailsDialogTable-header">
                <tr>{headerColumns}</tr>
            </thead>
            {constraints !== "loading" &&
                getTableBody(
                    constraints,
                    powerAmounts,
                    furtherConstraintsOpen,
                    setFurtherConstraintsOpen,
                    showAllConstraints,
                    showCapacityLabel,
                    showChargingLabel,
                    labelStyle,
                    numColumns,
                    constraintTableConfig
                )}
        </HTMLTable>
    );
};

const getTableBody = (
    constraints: ReadonlyArray<Constraint | LineTapConstraint>,
    powerAmounts: PowerAmounts,
    furtherConstraintsOpen: boolean,
    setFurtherConstraintsOpen: (isOpen: boolean) => void,
    showAllConstraints: boolean,
    showCapacityLabel: boolean,
    showChargingLabel: boolean,
    labelStyle: LabelStyleType,
    numColumns: number,
    constraintTableConfig: ConstraintTableConfig
): JSX.Element => {
    if (showAllConstraints) {
        return (
            <tbody>
                {getConstraintRows({
                    constraintRows: constraints,
                    powerAmounts,
                    withinSize: false,
                    labelStyle,
                    constraintTableConfig
                })}
            </tbody>
        );
    } else {
        return (
            <tbody>
                {getConstraintRowsWithinSize({
                    constraints,
                    powerAmounts,
                    showChargingLabel,
                    showCapacityLabel,
                    labelStyle,
                    numColumns,
                    constraintTableConfig
                })}
                {getFurtherConstraintsOpenRow(
                    furtherConstraintsOpen,
                    setFurtherConstraintsOpen,
                    powerAmounts,
                    showCapacityLabel,
                    showChargingLabel,
                    labelStyle,
                    numColumns
                )}
                {furtherConstraintsOpen &&
                    getConstraintsRowsOutsideSize({
                        constraints,
                        powerAmounts,
                        labelStyle,
                        constraintTableConfig
                    })}
            </tbody>
        );
    }
};

const getFurtherConstraintsOpenRow = (
    furtherConstraintsOpen: boolean,
    setFurtherConstraintsOpen: (isOpen: boolean) => void,
    powerAmounts: PowerAmounts,
    showCapacityLabel: boolean,
    showChargingLabel: boolean,
    labelStyle: LabelStyleType,
    numColumns: number
): JSX.Element => {
    const sizingLabel = getAllSizeLabels(
        powerAmounts,
        showCapacityLabel,
        showChargingLabel,
        labelStyle
    );
    return (
        <tr
            key="expansion-button"
            onClick={() => setFurtherConstraintsOpen(!furtherConstraintsOpen)}
        >
            <td
                colSpan={numColumns}
                className={`ConstraintDetailsDialogTable-further-constraints-td ${
                    furtherConstraintsOpen
                        ? "ConstraintDetailsDialogTable-further-constraints-td-open"
                        : ""
                }`}
            >
                <div
                    className={
                        "ConstraintDetailsDialogTable-further-constraints-row"
                    }
                >
                    <Icon
                        icon={
                            furtherConstraintsOpen
                                ? "caret-down"
                                : "caret-right"
                        }
                        iconSize={IconSize.LARGE}
                        className="ConstraintDetailsDialogTable-further-constraints-row-carat"
                    />
                    <h6 className="bp5-heading">{`${
                        furtherConstraintsOpen
                            ? `Hide constraints beyond ${sizingLabel}`
                            : `Show constraints beyond ${sizingLabel}`
                    }`}</h6>
                </div>
            </td>
        </tr>
    );
};

const getConstraintRowsWithinSize = (props: {
    constraints: ReadonlyArray<Constraint | LineTapConstraint>;
    powerAmounts: PowerAmounts;
    showChargingLabel: boolean;
    showCapacityLabel: boolean;
    labelStyle: LabelStyleType;
    numColumns: number;
    constraintTableConfig: ConstraintTableConfig;
}): ReadonlyArray<JSX.Element> | JSX.Element => {
    const {
        constraints,
        powerAmounts,
        showCapacityLabel,
        showChargingLabel,
        labelStyle,
        numColumns,
        constraintTableConfig
    } = props;
    const constraintsWithinSize = getRelevantConstraintsForSize(
        constraints,
        powerAmounts
    );
    const sizingLabel = getAllSizeLabels(
        powerAmounts,
        showCapacityLabel,
        showChargingLabel,
        labelStyle
    );

    if (constraintsWithinSize.length === 0) {
        return (
            <tr key="no-rows">
                <td colSpan={numColumns}>
                    <div className="ConstraintDetailsDialogTable-no-constraints-required-row">
                        No constraints triggered @ {sizingLabel}
                    </div>
                </td>
            </tr>
        );
    }

    return getConstraintRows({
        constraintRows: constraintsWithinSize,
        powerAmounts,
        withinSize: true,
        labelStyle,
        constraintTableConfig
    });
};

const getConstraintsRowsOutsideSize = (props: {
    constraints: ReadonlyArray<Constraint | LineTapConstraint>;
    powerAmounts: PowerAmounts;
    labelStyle: LabelStyleType;
    constraintTableConfig: ConstraintTableConfig;
}): ReadonlyArray<JSX.Element> => {
    const { constraints, powerAmounts, labelStyle, constraintTableConfig } =
        props;

    const constraintsOutsideSize = getConstraintsOutsideSize(
        constraints,
        powerAmounts
    );

    return getConstraintRows({
        constraintRows: constraintsOutsideSize,
        powerAmounts,
        withinSize: false,
        labelStyle,
        constraintTableConfig
    });
};

const getConstraintRows = (props: {
    constraintRows: ReadonlyArray<Constraint | LineTapConstraint>;
    powerAmounts: PowerAmounts;
    withinSize: boolean;
    labelStyle: LabelStyleType;
    constraintTableConfig: ConstraintTableConfig;
}): ReadonlyArray<JSX.Element> => {
    const {
        constraintRows,
        powerAmounts,
        withinSize,
        labelStyle,
        constraintTableConfig
    } = props;
    return [...constraintRows]
        .sort((a, b) => a.injectionThreshold - b.injectionThreshold)
        .map((constraint) => {
            const rowValues = constraintToRow({
                constraint,
                powerAmounts,
                labelStyle,
                withinSize,
                constraintTableConfig
            });
            return <tr>{rowValues}</tr>;
        });
};

/**
 * Converts a single constraint to a list of cell values representing a single row in the constraint table.
 */
const constraintToRow = (
    params: RenderConstraintTableValueParams
): JSX.Element[] => {
    const configuredColumns = getConfiguredColumns(
        CONSTRAINT_TABLE_COLUMNS,
        params.constraintTableConfig
    );

    return configuredColumns.map((column, index) => {
        const stringValue = column.renderValue(params);
        const ellipsized = column.ellipsizedColumn;
        const content = (
            <div
                className={classnames({
                    "ConstraintDetailsDialogTable-ellipsize": ellipsized
                })}
            >
                {stringValue}
            </div>
        );
        return (
            <td key={index}>
                {ellipsized ? (
                    <Tooltip content={stringValue} placement="left">
                        {content}
                    </Tooltip>
                ) : (
                    content
                )}
            </td>
        );
    });
};

const getHeaderColumns = (config: ConstraintTableConfig) => {
    const labels = getConstraintsTableHeader(config);

    return labels.map((label) => <th key={label}>{label}</th>);
};

export default ConstraintDetailsDialogTable;

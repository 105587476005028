import {
    Button,
    Menu,
    MenuItem,
    Popover,
    PopoverPosition
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useCurrentProjectId } from "in_queue/contexts/ScenarioPageContext";

export type ViewType = "related" | "current" | "all";

export interface ViewTypesMenuProps {
    currentViewType: ViewType;
    setCurrentViewType: (viewType: ViewType) => void;
    validViewTypes: ViewType[];
}
export const ViewTypesMenu: React.FC<ViewTypesMenuProps> = ({
    currentViewType,
    setCurrentViewType,
    validViewTypes
}) => {
    const currentProjectId = useCurrentProjectId();

    const viewTypeToLabel = getViewTypeToLabel(currentProjectId);

    const filterViewMenu = (
        <Menu>
            {validViewTypes.map((vt) => (
                <MenuItem
                    text={viewTypeToLabel[vt]}
                    onClick={() => setCurrentViewType(vt)}
                />
            ))}
        </Menu>
    );
    return (
        <Popover position={PopoverPosition.BOTTOM} content={filterViewMenu}>
            <Button
                text={viewTypeToLabel[currentViewType]}
                icon={IconNames.FILTER}
                rightIcon={IconNames.CARET_DOWN}
            />
        </Popover>
    );
};

const getViewTypeToLabel = (currentProjectId?: string) => ({
    related: "Related projects",
    current:
        currentProjectId !== undefined
            ? `Only ${currentProjectId}`
            : "Only my project",
    all: "All results"
});

import { Button, Classes, Intent, Position, Toaster } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useUserDataContext } from "contexts/UserDataContext/UserDataContext";
import { useUpdateScenarioTitleMutation } from "generated/graphql";
import { segmentTrackEditScenarioTitle } from "in_queue/analytics";
import { useCurrentScenarioMetadata } from "in_queue/contexts/ScenarioPageContext";
import { useEffect, useState } from "react";
import { AdminOnlyTooltip } from "../common/AdminOnlyTooltip";
import { SectionHeader } from "../common/SectionHeader";
import css from "./ScenarioTitle.module.scss";

interface ScenarioTitleProps {
    actions: JSX.Element;
}

const ScenarioTitle: React.FC<ScenarioTitleProps> = ({
    actions
}: ScenarioTitleProps) => {
    const userData = useUserDataContext();
    const scenario = useCurrentScenarioMetadata();
    const [useUpdateScenarioTitle] = useUpdateScenarioTitleMutation();
    const [isEditMode, setIsEditMode] = useState(false);
    const [isUpdatingTitle, setIsUpdatingTitle] = useState(false);
    const [title, setTitle] = useState(scenario.title);

    useEffect(() => {
        setTitle(scenario.title);
    }, [scenario.title]);

    const isTrackedProject = scenario && scenario.type === "CUSTOM";

    const updateTitle = async () => {
        setIsUpdatingTitle(true);
        const response = await useUpdateScenarioTitle({
            variables: {
                scenarioId: scenario.scenarioId,
                authId: userData.auth0Id,
                title
            }
        });
        segmentTrackEditScenarioTitle({
            scenarioId: scenario.scenarioId,
            scenarioTitle: title
        });
        setIsUpdatingTitle(false);

        if (
            response.data &&
            response.data.update_scenarios &&
            response.data.update_scenarios.affected_rows === 0
        ) {
            ScenarioTitleToaster.show({
                intent: Intent.DANGER,
                message:
                    "Encountered an error while updating scenario title. Please try again."
            });
        } else {
            ScenarioTitleToaster.show({
                intent: Intent.SUCCESS,
                message: "Successfully updated scenario title"
            });
        }

        setIsEditMode(false);
    };

    const onCancelEditing = () => {
        setTitle(scenario.title);
        setIsEditMode(false);
    };

    const onClickEditButton = () => {
        if (isEditMode) {
            updateTitle();
        } else {
            setIsEditMode(true);
        }
    };

    const editButton = (
        <AdminOnlyTooltip>
            <div className={css["buttons"]}>
                {isEditMode && (
                    <Button icon={IconNames.CROSS} onClick={onCancelEditing} />
                )}
                <Button
                    icon={isEditMode ? IconNames.TICK : IconNames.EDIT}
                    intent={isEditMode ? "primary" : "none"}
                    minimal={!isEditMode}
                    onClick={onClickEditButton}
                    loading={isUpdatingTitle}
                    disabled={userData.role !== "admin"}
                />
            </div>
        </AdminOnlyTooltip>
    );

    let titleElement = <>{title}</>;
    if (isEditMode) {
        const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
            event
        ) => {
            if (event.key == "Enter") {
                updateTitle();
            } else if (event.key == "Escape") {
                onCancelEditing();
            }
        };

        titleElement = (
            <div
                className={`${Classes.EDITABLE_TEXT} ${Classes.EDITABLE_TEXT_EDITING}`}
            >
                <input
                    className={Classes.EDITABLE_TEXT_INPUT}
                    value={title}
                    disabled={isUpdatingTitle}
                    autoFocus={true}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setTitle(event.target.value)
                    }
                    onKeyDown={onKeyDown}
                />
            </div>
        );
    }

    return (
        <SectionHeader
            level="h1"
            title={
                <div className={css["title-wrapper"]}>
                    {titleElement}
                    {isTrackedProject && editButton}
                </div>
            }
            actions={actions}
        />
    );
};

const ScenarioTitleToaster = Toaster.create({
    position: Position.TOP
});

export default ScenarioTitle;

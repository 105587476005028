import { segmentTrackBusSelectedFromSidebar } from "analytics/analyticTrackEvents";
import { getLabelStyle, LabelStyleType } from "components/common/labels";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import useSelectedBuses from "contexts/SelectedBusesContext/useSelectedBuses";
import React from "react";
import { ScopedBus } from "types/busType";
import FirstTag from "./FirstTag";
import "./index.scss";
import SecondTag from "./SecondTag";
import SubheadingText from "./SubheadingText";

type BusItemProps = {
    readonly isLoading: boolean;
    readonly scopedBus: ScopedBus;
};

const BusItem: React.FC<BusItemProps> = (props) => {
    const { isLoading, scopedBus } = props;
    const { bus } = scopedBus;

    const { selectedBusesAtLocation, selectedFacility, selectSingleBus } =
        useSelectedBuses();
    const { generator, componentConfig } = useMapViewRoutingMetadata();

    const labelStyle: LabelStyleType = getLabelStyle(
        componentConfig,
        generator,
        true
    );

    const possibleSelectedBusIds = [
        selectedBusesAtLocation?.selectedBusId,
        selectedFacility?.fromBusId,
        selectedFacility?.toBusId
    ];
    const isSelected = possibleSelectedBusIds.includes(bus.id);

    return (
        <div
            className={`BusItem-wrapper ${
                isSelected ? "BusItem-wrapper-selected" : ""
            }`}
            onClick={() => {
                selectSingleBus(bus.id);
                segmentTrackBusSelectedFromSidebar(scopedBus);
            }}
        >
            <div
                className={`BusItem-display-name bp5-text-large ${
                    isLoading ? "bp5-skeleton" : ""
                }`}
            >
                {`${bus.substation.substationDisplayName} - ${bus.voltage} kV`}
            </div>
            <FirstTag
                scopedBus={scopedBus}
                isLoading={isLoading}
                labelStyle={labelStyle}
            />
            <SubheadingText scopedBus={scopedBus} isLoading={isLoading} />
            <SecondTag
                scopedBus={scopedBus}
                isLoading={isLoading}
                labelStyle={labelStyle}
            />
        </div>
    );
};

export default BusItem;

import { useAuth0 } from "@auth0/auth0-react";
import LoadingFullScreen from "components/common/LoadingFullScreen";
import { GetUserDataQuery } from "generated/graphql";
import { loader } from "graphql.macro";
import { getHasuraDataAndConvertToNiraType } from "graphql/helpers/queryHelpers";
import React, { useContext } from "react";
import { NiraAuth0UserType } from "types/auth0UserType";
import { hasuraUserToNiraUser, User } from "types/userType";

export const GET_USER = loader("src/graphql/getUserData.graphql");
export const UserDataContext = React.createContext<User | undefined>(undefined);

export const UserDataProvider: React.FC = ({ children }) => {
    const { user } = useAuth0<NiraAuth0UserType>();

    // This should never happen since we should only get to this provider if you're logged in.
    if (user === undefined) {
        return <LoadingFullScreen />;
    } else {
        return (
            <UserDataProviderInner auth0User={user}>
                {children}
            </UserDataProviderInner>
        );
    }
};

type UserDataProviderInnerProps = {
    readonly auth0User: NiraAuth0UserType;
};

const UserDataProviderInner: React.FC<UserDataProviderInnerProps> = (props) => {
    const { auth0User, children } = props;
    const maybeUserData = getHasuraDataAndConvertToNiraType(
        GET_USER,
        (hasuraData: GetUserDataQuery) =>
            hasuraData.users.map((hasuraUser) =>
                hasuraUserToNiraUser(hasuraUser, auth0User)
            ),
        { userId: auth0User.sub }
    );

    const maybeUser: User | undefined =
        maybeUserData !== "loading" && maybeUserData.length > 0
            ? maybeUserData[0]
            : undefined;

    // This should never happen, since this means the user was created in Auth0
    // without an entity in Hasura
    if (maybeUser === undefined) {
        return <LoadingFullScreen />;
    } else {
        return (
            <UserDataContext.Provider value={maybeUser}>
                {children}
            </UserDataContext.Provider>
        );
    }
};

export const useUserDataContext = (): User => {
    const context = useContext(UserDataContext);
    if (!context) {
        throw new Error(
            "useUserDataContext must be used within a UserDataProvider"
        );
    }

    return context;
};

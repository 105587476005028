import { NumericInput, Switch } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { MaybeMaxCosts } from "types/busFilterTypes";
import "./CostsInput.scss";

type CostsInputProps = {
    readonly maxCost: MaybeMaxCosts;
    readonly setMaxCost: (value: MaybeMaxCosts) => void;
};

const CostsInput: React.FC<CostsInputProps> = (props) => {
    const {
        busFiltersConfiguration: { filtersDisabled }
    } = useMapViewConfiguration();
    const { maxCost, setMaxCost } = props;
    return (
        <div className="CostsInput-wrapper">
            <NumericInput
                className="CostsInput-input"
                leftIcon={IconNames.DOLLAR}
                value={maxCost.maxCosts / 1_000_000}
                buttonPosition="none"
                allowNumericCharactersOnly={true}
                onValueChange={(valueAsNumber: number) => {
                    setMaxCost({
                        ...maxCost,
                        maxCosts: valueAsNumber * 1_000_000
                    });
                }}
                disabled={!maxCost.enabled || filtersDisabled}
            />
            <Switch
                className="CostsInput-switch"
                innerLabel="off"
                innerLabelChecked="on"
                alignIndicator="right"
                checked={maxCost.enabled}
                onChange={(evt) =>
                    setMaxCost({ ...maxCost, enabled: evt.target.checked })
                }
                disabled={filtersDisabled}
            />
        </div>
    );
};

export default CostsInput;

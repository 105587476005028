type EnvVariables = {
    readonly hasuraUrl: string;
    readonly auth0Domain: string;
    readonly auth0ClientId: string;
    readonly auth0Audience: string;
    readonly mapboxToken: string;
};

// Make sure this matches the logic in index.html
const getEnvVariables = (): EnvVariables => {
    let hasuraUrl;
    let auth0Domain;
    let auth0ClientId;
    let auth0Audience;

    if (isInCreateReactAppProductionMode()) {
        if (onGitProductionBranch()) {
            hasuraUrl = getEnvVariable("REACT_APP_PRODUCTION_HASURA_URL");
            auth0Domain = getEnvVariable("REACT_APP_PRODUCTION_AUTH_0_DOMAIN");
            auth0ClientId = getEnvVariable(
                "REACT_APP_PRODUCTION_AUTH_0_CLIENT_ID"
            );
            auth0Audience = getEnvVariable(
                "REACT_APP_PRODUCTION_AUTH_0_AUDIENCE"
            );
        } else if (onGitDataPreviewBranch()) {
            hasuraUrl = getEnvVariable("REACT_APP_DATA_PREVIEW_HASURA_URL");
            auth0Domain = getEnvVariable(
                "REACT_APP_DATA_PREVIEW_AUTH_0_DOMAIN"
            );
            auth0ClientId = getEnvVariable(
                "REACT_APP_DATA_PREVIEW_AUTH_0_CLIENT_ID"
            );
            auth0Audience = getEnvVariable(
                "REACT_APP_DATA_PREVIEW_AUTH_0_AUDIENCE"
            );
        } else if (getGitBranch()?.startsWith("preview")) {
            hasuraUrl = getEnvVariable("REACT_APP_DATA_PREVIEW_HASURA_URL");
            auth0Domain = getEnvVariable(
                "REACT_APP_DATA_PREVIEW_AUTH_0_DOMAIN"
            );
            auth0ClientId = getEnvVariable(
                "REACT_APP_DATA_PREVIEW_AUTH_0_CLIENT_ID"
            );
            auth0Audience = getEnvVariable(
                "REACT_APP_DATA_PREVIEW_AUTH_0_AUDIENCE"
            );
        } else {
            hasuraUrl = getEnvVariable("REACT_APP_STAGING_HASURA_URL");
            auth0Domain = getEnvVariable("REACT_APP_STAGING_AUTH_0_DOMAIN");
            auth0ClientId = getEnvVariable(
                "REACT_APP_STAGING_AUTH_0_CLIENT_ID"
            );
            auth0Audience = getEnvVariable("REACT_APP_STAGING_AUTH_0_AUDIENCE");
        }
    } else if (isInCreateReactAppDevelopmentMode()) {
        hasuraUrl = getEnvVariable("REACT_APP_STAGING_HASURA_URL");
        auth0Domain = getEnvVariable("REACT_APP_STAGING_AUTH_0_DOMAIN");
        auth0ClientId = getEnvVariable("REACT_APP_STAGING_AUTH_0_CLIENT_ID");
        auth0Audience = getEnvVariable("REACT_APP_STAGING_AUTH_0_AUDIENCE");
    } else {
        throw new Error("unknown NODE_ENV");
    }

    const mapboxToken = getEnvVariable("REACT_APP_MAPBOX_ACCESS_TOKEN");

    return {
        hasuraUrl,
        auth0Domain,
        auth0ClientId,
        auth0Audience,
        mapboxToken
    };
};

export const isInCreateReactAppProductionMode = (): boolean => {
    return process.env.NODE_ENV === "production";
};

const isInCreateReactAppDevelopmentMode = (): boolean => {
    return process.env.NODE_ENV === "development";
};

const getGitBranch = (): string | undefined => {
    // This gets injected by Vercel during the build step. If we're running production locally, this will be undefined.
    return maybeGetEnvVariable("REACT_APP_VERCEL_GIT_COMMIT_REF");
};

export const onGitProductionBranch = (): boolean => {
    return getGitBranch() === "production";
};

export const onGitStagingBranch = (): boolean => {
    return getGitBranch() === "master";
};

const onGitDataPreviewBranch = (): boolean => {
    return getGitBranch() === "data-preview";
};

const maybeGetEnvVariable = (variableName: string): string | undefined => {
    const maybeEnvVariable = process.env[variableName];

    return maybeEnvVariable;
};

const getEnvVariable = (variableName: string): string => {
    const maybeEnvVariable = maybeGetEnvVariable(variableName);
    if (maybeEnvVariable === undefined) {
        throw new Error(
            `Can't find a required environment variable: ${variableName}`
        );
    } else {
        return maybeEnvVariable;
    }
};

export default getEnvVariables;

import { useMutation } from "@apollo/client";
import {
    AnchorButton,
    Dialog,
    DialogBody,
    DialogFooter,
    Intent,
    Position,
    Toaster,
    Tooltip
} from "@blueprintjs/core";
import { segmentTrackScreeningViewCreate } from "analytics/analyticTrackEvents";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { useUserDataContext } from "contexts/UserDataContext/UserDataContext";
import {
    CreateScreeningViewMutation,
    CreateScreeningViewMutationVariables
} from "generated/graphql";
import { loader } from "graphql.macro";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { BusFilters } from "types/busFilterTypes";
import { GeneratorType } from "types/generatorType";
import { RegionType } from "types/regionType";
import { getUrlForScreeningViewId } from "types/screeningViewType";
import { REGION_TO_STATES, StateType } from "types/stateType";
import FiltersDialogSection from "../filters/FiltersDialogSection";
import ISOAndGeneratorTypeLabel from "./ISOAndGenerator";
import ScreeningViewDescription from "./ScreeningViewDescription";
import ScreeningViewTitle from "./ScreeningViewTitle";
import StateSelector from "./StateSelector";

const ScreeningViewToaster = Toaster.create({
    position: Position.TOP
});

const CREATE_SCREENING_VIEW = loader("src/graphql/createScreeningView.graphql");

type CreateScreeningViewDialogProps = {
    readonly isOpen: boolean;
    readonly closeDialog: () => void;
};

const CreateScreeningViewDialog: React.FC<CreateScreeningViewDialogProps> = (
    props
) => {
    const navigate: NavigateFunction = useNavigate();

    const { isOpen, closeDialog } = props;
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const { busFiltersConfiguration } = useMapViewConfiguration();
    const { region, generator } = useMapViewRoutingMetadata();
    const { teamId } = useUserDataContext();

    const [selectedStates, setSelectedStates] = useState<readonly StateType[]>(
        REGION_TO_STATES[region].size === 1
            ? [Array.from(REGION_TO_STATES[region])[0]]
            : []
    );

    const [createScreeningView, { loading }] = useMutation<
        CreateScreeningViewMutation,
        CreateScreeningViewMutationVariables
    >(CREATE_SCREENING_VIEW);

    const canSubmit =
        selectedStates.length > 0 && title !== "" && description !== "";

    return (
        <Dialog
            isOpen={isOpen}
            title={"Create screening view"}
            onClose={closeDialog}
            icon="map-create"
        >
            <DialogBody>
                <FiltersDialogSection header="Region">
                    <ISOAndGeneratorTypeLabel />
                    <StateSelector
                        selectedStates={selectedStates}
                        setSelectedStates={setSelectedStates}
                    />
                </FiltersDialogSection>
                <FiltersDialogSection header="Title & Description">
                    <ScreeningViewTitle title={title} setTitle={setTitle} />
                    <ScreeningViewDescription
                        description={description}
                        setDescription={setDescription}
                    />
                </FiltersDialogSection>
            </DialogBody>
            <DialogFooter
                actions={
                    <Tooltip
                        content={"Fill out the state, title and description"}
                        position={Position.TOP_RIGHT}
                        disabled={canSubmit}
                    >
                        <AnchorButton
                            intent="primary"
                            onClick={async () => {
                                createScreeningView({
                                    variables: getCreateScreeningViewVariables(
                                        busFiltersConfiguration.busFilters,
                                        selectedStates,
                                        title,
                                        description,
                                        teamId,
                                        region,
                                        generator
                                    ),
                                    onCompleted: (
                                        data: CreateScreeningViewMutation
                                    ) => {
                                        onScreeningViewCreated(
                                            data,
                                            region,
                                            generator,
                                            navigate
                                        );
                                        closeDialog();
                                    }
                                });
                            }}
                            disabled={!canSubmit || loading === true}
                        >
                            Create
                        </AnchorButton>
                    </Tooltip>
                }
            >
                <Tooltip
                    content={
                        <div style={{ width: 400 }}>
                            Screening views are created to simplify Nira for
                            non-technical teammates. <br /> <br />
                            The filters you currently have set will be saved
                            into a custom view. Share this screening view with
                            non-technical teammates, who can then use it to find
                            favorable substations without needing to set any
                            filters.
                        </div>
                    }
                    position="top-left"
                >
                    <a>Learn more</a>
                </Tooltip>
            </DialogFooter>
        </Dialog>
    );
};

const onScreeningViewCreated = (
    data: CreateScreeningViewMutation,
    region: RegionType,
    generator: GeneratorType,
    navigate: NavigateFunction
) => {
    const maybeId = data?.insert_screening_views_one?.id;
    const maybeUrl =
        maybeId !== undefined
            ? getUrlForScreeningViewId(maybeId, region, generator)
            : undefined;
    if (maybeUrl && maybeId) {
        segmentTrackScreeningViewCreate(maybeId);
        ScreeningViewToaster.show({
            intent: Intent.SUCCESS,
            message: "Successfully created screening view.",
            action: {
                text: "Link",
                onClick: () => navigate(maybeUrl)
            }
        });
    } else {
        ScreeningViewToaster.show({
            intent: Intent.DANGER,
            message: `Failed to create screening view`
        });
    }
};

const getCreateScreeningViewVariables = (
    busFilters: BusFilters,
    states: readonly StateType[],
    title: string,
    description: string,
    teamId: number,
    region: RegionType,
    generatorType: GeneratorType
): CreateScreeningViewMutationVariables => {
    return {
        team_id: teamId,
        voltages: busFilters.voltages,
        max_allocated_costs: busFilters.maybeMaxAllocatedCosts.enabled
            ? busFilters.maybeMaxAllocatedCosts.maxCosts
            : undefined,
        max_total_costs: busFilters.maybeMaxTotalCosts.enabled
            ? busFilters.maybeMaxTotalCosts.maxCosts
            : undefined,
        scope: busFilters.scope,
        hide_locked_buses: busFilters.hideLockedBuses,
        hide_low_confidence_buses: busFilters.hideLowConfidenceBuses,
        includes_pre_existing: busFilters.includePreExistingConstraints,
        low_threshold: busFilters.capacityThresholds.lowThreshold,
        medium_threshold: busFilters.capacityThresholds.mediumThreshold,
        high_threshold: busFilters.capacityThresholds.highThreshold,
        region: region,
        generator_type: generatorType,
        title: title,
        description: description,
        states
    };
};

export default CreateScreeningViewDialog;

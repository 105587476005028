import { MultiSlider } from "@blueprintjs/core";
import { segmentTrackColorThresholdsChanged } from "analytics/analyticTrackEvents";
import Legend from "components/layerStyleSelector/Legend";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import {
    HIGH_AVAILABLE_CAPACITY_COLOR,
    LOW_AVAILABLE_CAPACITY_COLOR,
    MED_AVAILABLE_CAPACITY_COLOR
} from "sharedStyles/capacityColorStyles";
import { CapacityThresholds } from "types/busFilterTypes";
import "./ColorThresholdsFilter.scss";

const ColorThresholdsFilter: React.FC = () => {
    const {
        busFiltersConfiguration: {
            busFilters,
            updateCapacityThresholds,
            filtersDisabled
        }
    } = useMapViewConfiguration();
    const { capacityThresholds } = busFilters;

    const onChange = (thresholds: CapacityThresholds) => {
        updateCapacityThresholds(thresholds);
        segmentTrackColorThresholdsChanged(thresholds);
    };

    return (
        <div className="ColorThresholds-wrapper">
            <div className="ColorThresholds-multi-slider-wrapper">
                <MultiSlider
                    min={0}
                    max={500}
                    stepSize={10}
                    labelStepSize={100}
                    className="ColorThresholds-multi-slider"
                    disabled={filtersDisabled}
                >
                    <MultiSlider.Handle
                        value={capacityThresholds.mediumThreshold}
                        onChange={(evt) => {
                            onChange({
                                ...capacityThresholds,
                                mediumThreshold: evt
                            });
                        }}
                        trackStyleBefore={{
                            backgroundColor: LOW_AVAILABLE_CAPACITY_COLOR
                        }}
                        trackStyleAfter={{
                            backgroundColor: MED_AVAILABLE_CAPACITY_COLOR
                        }}
                    />
                    <MultiSlider.Handle
                        value={capacityThresholds.highThreshold}
                        onChange={(evt) => {
                            onChange({
                                ...capacityThresholds,
                                highThreshold: evt
                            });
                        }}
                        trackStyleAfter={{
                            backgroundColor: HIGH_AVAILABLE_CAPACITY_COLOR
                        }}
                    />
                </MultiSlider>
            </div>
            <div className="ColorThresholds-legend-wrapper">
                <Legend hideLegendTitle={true} />
            </div>
        </div>
    );
};

export default ColorThresholdsFilter;

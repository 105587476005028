import { useCreateMapViewData } from "contexts/MapViewDataContext/hooks/useCreateMapViewData";
import { createContext, PropsWithChildren } from "react";
import { VoltageToBranchesDict } from "types/branchType";
import { ScopedBus } from "types/busType";

interface MapViewDataContextProps {
    readonly scopedBuses: readonly ScopedBus[];
    readonly isLoadingBuses: boolean;

    readonly branches: VoltageToBranchesDict | undefined;
    readonly isLoadingBranches: boolean;
}

const MapViewDataContext = createContext<MapViewDataContextProps | undefined>(
    undefined
);

const MapViewDataProvider: React.FC<PropsWithChildren<unknown>> = ({
    children
}: PropsWithChildren<unknown>) => {
    const contextValue = useCreateMapViewData();

    return (
        <MapViewDataContext.Provider value={contextValue}>
            {children}
        </MapViewDataContext.Provider>
    );
};

export { MapViewDataProvider, MapViewDataContext };
export type { MapViewDataContextProps };

import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { getLabelForRegionAndGeneratorType } from "types/generatorType";
import FilterWrapper from "../filters/FilterWrapper";

const ISOAndGeneratorTypeLabel: React.FC = () => {
    const { region, generator } = useMapViewRoutingMetadata();

    return (
        <FilterWrapper label={"ISO & generator type"}>
            <div>{`${region} - ${getLabelForRegionAndGeneratorType(
                region,
                generator
            )}`}</div>
        </FilterWrapper>
    );
};

export default ISOAndGeneratorTypeLabel;

import { useAuth0 } from "@auth0/auth0-react";
import LoadingFullScreen from "components/common/LoadingFullScreen";
import LoggedOutApp from "components/login/LoggedOutApp";
import React from "react";

/**
 * Renders a loading state or redirects the user if they're unauthenticated in Auth0,
 * or if data about the user is still loading from Hasura.
 */
export const LoadingApp: React.FC = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <LoadingFullScreen />;
    }

    if (!isAuthenticated) {
        return <LoggedOutApp />;
    }

    return <>{children}</>;
};

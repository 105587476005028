// eslint-disable-next-line import/no-unresolved
import { Feature, FeatureCollection, LineString } from "geojson";
import { Branch } from "types/branchType";

export const BRANCH_FEATURE_PROPERTY_VOLTAGE: keyof BranchFeatureProperties =
    "voltage";

type BranchFeatureProperties = {
    readonly voltage: number;
};

export const getBranchGeoJson = (
    branches: ReadonlyArray<Branch>
): Readonly<FeatureCollection> => {
    const featureList: Feature<LineString, BranchFeatureProperties>[] =
        branches.map((branch) => {
            return {
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: convertReadonlyBranchPathToNativeArray(
                        branch.branchPath
                    )
                },
                properties: {
                    voltage: branch.voltage
                }
            };
        });

    return {
        type: "FeatureCollection",
        features: featureList
    };
};

const convertReadonlyBranchPathToNativeArray = (
    branch: ReadonlyArray<ReadonlyArray<number>>
): number[][] => {
    return branch.map((point) => point.map((value) => value));
};

import { createContext, PropsWithChildren } from "react";
import { BusId } from "types/busType";
import { SelectedBusesAtLocation } from "types/selectedBusesAtLocation";
import { SelectedFacility } from "types/selectedFacility";
import { useCreateSelectedBuses } from "./useCreateSelectedBuses";

interface SelectedBusesContextProps {
    selectedBusesAtLocation: SelectedBusesAtLocation | null;
    selectedFacility: SelectedFacility | null;
    selectSingleBus: (busId: BusId) => void;
    selectMultipleBuses: ({
        selectedBusId,
        busIds
    }: {
        selectedBusId: BusId;
        busIds: BusId[];
    }) => void;
    updateSelectedBusId: (busId: BusId) => void;
    clearSelectedBuses: () => void;
    selectLineTap: (facility: SelectedFacility) => void;
}

const SelectedBusesContext = createContext<
    SelectedBusesContextProps | undefined
>(undefined);

const SelectedBusesProvider: React.FC<PropsWithChildren<unknown>> = ({
    children
}: PropsWithChildren<unknown>) => {
    const contextValue = useCreateSelectedBuses();

    return (
        <SelectedBusesContext.Provider value={contextValue}>
            {children}
        </SelectedBusesContext.Provider>
    );
};

export { SelectedBusesProvider, SelectedBusesContext };
export type { SelectedBusesContextProps };

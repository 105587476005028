import { ReactNode } from "react";
import { Loading } from "types/loadingType";
import "./ConstraintsDetailsSection.scss";

type ConstraintsDetailsSectionProps<T> = {
    readonly sectionLabel: string;
    readonly constraintDetailsRows: ReadonlyArray<ConstraintDetailRowData<T>>;
    readonly onHeaderDetailsClicked?: () => void;
};

export type ConstraintDetailRowData<T> = {
    readonly label: string;
    readonly originalValue: Loading<T>;
    readonly editedValue?: Loading<T>;
    readonly formatter: (value: T) => string;
    readonly doNotFormatOriginalWhenCrossed?: boolean;
    readonly showConstraintsDetailRowButtonData?: ConstraintDetailRowShowConstraintButtonData;
};

type ConstraintDetailRowShowConstraintButtonData = {
    readonly showConstraintsOpen: boolean;
    readonly onShowConstraintsButtonClicked: () => void;

    readonly maybeCollapsableSection?: ReactNode;
};

const ConstraintsDetailsSection = <T,>(
    props: ConstraintsDetailsSectionProps<T>
): JSX.Element => {
    const { constraintDetailsRows, sectionLabel, onHeaderDetailsClicked } =
        props;

    return (
        <div>
            <h6 className="bp5-heading">
                {sectionLabel}{" "}
                {onHeaderDetailsClicked !== undefined && (
                    <span
                        className={
                            "bp5-text-small ConstraintsDetailsSection-view-details-button"
                        }
                        onClick={() => onHeaderDetailsClicked()}
                    >
                        (view details)
                    </span>
                )}
            </h6>
            {getAllConstraintDetailsRows(constraintDetailsRows)}
        </div>
    );
};

const getAllConstraintDetailsRows = <T,>(
    constraintDetailsRows: ReadonlyArray<ConstraintDetailRowData<T>>
): ReadonlyArray<JSX.Element> => {
    return constraintDetailsRows.map((constraintDetailRowContent, index) => {
        const {
            originalValue,
            editedValue,
            formatter,
            doNotFormatOriginalWhenCrossed,
            showConstraintsDetailRowButtonData
        } = constraintDetailRowContent;

        return (
            <div key={index}>
                <div className={`ConstraintsDetailsSection-row`}>
                    <div className="ConstraintsDetailsSection-row-label  bp5-text-disabled">
                        {constraintDetailRowContent.label}
                    </div>
                    <div className="ConstraintsDetailsSection-row-value">
                        <div className="ConstraintsDetailsSection-row-value-text">
                            {originalValue === "loading" ||
                            editedValue === "loading"
                                ? "Loading..."
                                : getFormattedEditedAndOriginalValues(
                                      originalValue,
                                      formatter,
                                      doNotFormatOriginalWhenCrossed ===
                                          undefined
                                          ? false
                                          : doNotFormatOriginalWhenCrossed,
                                      editedValue
                                  )}
                        </div>
                        {showConstraintsDetailRowButtonData !== undefined && (
                            <div
                                className={
                                    "bp5-text-small ConstraintsDetailsSection-view-upgrades-button"
                                }
                                onClick={() =>
                                    showConstraintsDetailRowButtonData.onShowConstraintsButtonClicked()
                                }
                            >
                                {showConstraintsDetailRowButtonData.showConstraintsOpen
                                    ? "(hide)"
                                    : "(show)"}
                            </div>
                        )}
                    </div>
                </div>
                {showConstraintsDetailRowButtonData !== undefined &&
                    showConstraintsDetailRowButtonData.showConstraintsOpen &&
                    showConstraintsDetailRowButtonData.maybeCollapsableSection}
            </div>
        );
    });
};

const getFormattedEditedAndOriginalValues = <T,>(
    originalValue: T,
    formatter: (value: T) => string,
    doNotFormatOriginalWhenCrossed: boolean,
    editedValue?: T
): JSX.Element => {
    if (editedValue === undefined || originalValue === editedValue) {
        return <span>{formatter(originalValue)}</span>;
    } else {
        return (
            <div>
                <span className="ConstraintsDetailsSection-original-value-crossed bp5-text-disabled">
                    {doNotFormatOriginalWhenCrossed
                        ? originalValue
                        : formatter(originalValue)}
                </span>
                <span>{` → ${formatter(editedValue)}`}</span>
            </div>
        );
    }
};

export default ConstraintsDetailsSection;

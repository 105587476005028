import { GetScenarioResultsQuery } from "generated/graphql";
import { loader } from "graphql.macro";
import { getHasuraDataAndConvertToNiraType } from "graphql/helpers/queryHelpers";
import {
    convertHasuraScenarioDataToScenarioResults,
    ScenarioResults
} from "in_queue/types/scenarioResultType";
import { createContext, useContext } from "react";
import { Loading } from "types/loadingType";
import {
    useCurrentScenarioMetadata,
    useCurrentTrackedProject
} from "./ScenarioPageContext";

interface ScenarioResultsContextValue {
    scenarioResults: Loading<ScenarioResults>;
}

export const ScenarioResultsContext =
    createContext<ScenarioResultsContextValue>({
        scenarioResults: "loading"
    });

const GET_SCENARIO_RESULTS = loader(
    "src/graphql/in_queue/getScenarioResults.graphql"
);

export const ScenarioResultsProvider: React.FC = ({ children }) => {
    const project = useCurrentTrackedProject();
    const { scenarioId } = useCurrentScenarioMetadata();

    const scenarioResults = getHasuraDataAndConvertToNiraType(
        GET_SCENARIO_RESULTS,
        (hasuraData: GetScenarioResultsQuery) => {
            return convertHasuraScenarioDataToScenarioResults(
                hasuraData,
                project
            );
        },
        { scenarioId }
    );

    return (
        <ScenarioResultsContext.Provider value={{ scenarioResults }}>
            {children}
        </ScenarioResultsContext.Provider>
    );
};

export const useScenarioResults = (): Loading<ScenarioResults> => {
    const context = useContext(ScenarioResultsContext);
    return context.scenarioResults;
};

import {
    BoundingBoxConfiguration,
    useCreateBoundingBoxConfiguration
} from "contexts/MapViewConfigurationContext/hooks/useBoundingBox";
import {
    BusFiltersConfiguration,
    useCreateBusFiltersConfiguration,
    useCreateBusFiltersFromScreeningView
} from "contexts/MapViewConfigurationContext/hooks/useBusFilters";
import {
    LayerStyleConfiguration,
    useCreateLayerStyleConfiguration
} from "contexts/MapViewConfigurationContext/hooks/useLayerStyleId";
import { useScreeningViewContext } from "contexts/ScreeningViewContext/ScreeningViewContext";

interface MapViewConfiguration {
    readonly busFiltersConfiguration: BusFiltersConfiguration;
    readonly layerStyleConfiguration: LayerStyleConfiguration;
    readonly boundingBoxConfiguration: BoundingBoxConfiguration;
}

const useCreateMapViewConfiguration = (): MapViewConfiguration => {
    const maybeScreeningViewData = useScreeningViewContext();
    const busFilters =
        maybeScreeningViewData === undefined
            ? useCreateBusFiltersConfiguration()
            : useCreateBusFiltersFromScreeningView(maybeScreeningViewData);
    const layerStyle = useCreateLayerStyleConfiguration();
    const boundingBox = useCreateBoundingBoxConfiguration();

    return {
        busFiltersConfiguration: busFilters,
        layerStyleConfiguration: layerStyle,
        boundingBoxConfiguration: boundingBox
    };
};

export { useCreateMapViewConfiguration };
export type { MapViewConfiguration };

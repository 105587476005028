import {
    ApolloClient,
    ApolloProvider,
    HttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    split
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingFullScreen from "components/common/LoadingFullScreen";
import getEnvVariables from "config/envVariables";
import { createClient } from "graphql-ws";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useMemo, useState } from "react";

/**
 * Initializes data loading via Apollo.
 */
export const ApolloClientProvider: React.FC = ({ children }) => {
    const maybeToken: string | undefined = useAuthToken();

    const apolloClient = useMemo(
        () => useCreateApolloClient(maybeToken),
        [maybeToken]
    );

    // This happens on the first render while we're pulling the token.
    if (maybeToken === undefined) {
        return <LoadingFullScreen />;
    }

    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

const useCreateApolloClient = (
    token: string | undefined
): ApolloClient<NormalizedCacheObject> => {
    const envVariables = getEnvVariables();

    const httpLink = new HttpLink({
        uri: `https://${envVariables.hasuraUrl}`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    const wsLink = new GraphQLWsLink(
        createClient({
            url: `wss://${envVariables.hasuraUrl}`,
            connectionParams: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        })
    );

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
            );
        },
        wsLink,
        httpLink
    );

    const apolloClient = new ApolloClient({
        link: splitLink,
        cache: new InMemoryCache()
    });

    return apolloClient;
};

const useAuthToken = (): string | undefined => {
    const { getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState<string | undefined>();

    useEffect(() => {
        const getToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                setToken(accessToken);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (e: any) {
                console.error(e.message);
            }
        };

        getToken();
    }, [getAccessTokenSilently]);

    return token;
};

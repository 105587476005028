import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { AgGridReact } from "ag-grid-react";
import { segmentTrackExportScenarioData } from "in_queue/analytics";
import {
    useCurrentProjectId,
    useCurrentScenarioMetadata
} from "in_queue/contexts/ScenarioPageContext";
import { RefObject } from "react";

export interface ExportButtonProps {
    gridRef: RefObject<AgGridReact>;
    exportName: string;
}

export const ExportButton: React.FC<ExportButtonProps> = ({
    gridRef,
    exportName
}) => {
    const projectId = useCurrentProjectId();
    const scenario = useCurrentScenarioMetadata();

    const exportToCsv = () => {
        gridRef.current?.api.exportDataAsCsv({
            exportedRows: "all",
            fileName: `Nira Export - ${projectId} - ${scenario.title} - ${exportName}`
        });

        segmentTrackExportScenarioData({
            scenarioId: scenario.scenarioId,
            exportName
        });
    };

    return (
        <Button text="Export" icon={IconNames.EXPORT} onClick={exportToCsv} />
    );
};

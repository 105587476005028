import {
    AnchorButton,
    Button,
    Dialog,
    DialogBody,
    DialogFooter
} from "@blueprintjs/core";
import React from "react";

import { IconNames } from "@blueprintjs/icons";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { isPricedConstraintConfig } from "types/componentConfigPerRegion";
import { hasMultipleScopeConfigurations } from "types/scopeConfigurationType";
import { useIsUnlimitedSubscription } from "../utils/useIsUnlimitedSubscription";
import VoltageSelectWrapper from "../VoltageSelectWrapper";
import AllocatedCostFilter from "./AllocatedCostFilter";
import ColorThresholdsFilter from "./ColorThresholdsFilter";
import FiltersDialogSection from "./FiltersDialogSection";
import FilterWrapper from "./FilterWrapper";
import LowConfidenceBusFilter from "./LowConfidenceBusFilter";
import PreexistingFilter from "./PreexistingFilter";
import ScopeSelectFilter from "./ScopeSelectFilter";
import TotalCostFilter from "./TotalCostFilter";
import UnlockedBusesFilter from "./UnlockedBusesFilter";

type ScopeSelectorDialogProps = {
    readonly isOpen: boolean;
    readonly closeDialog: () => void;
};

const ScopeSelectorDialog: React.FC<ScopeSelectorDialogProps> = (props) => {
    const { isOpen, closeDialog } = props;
    const { region, componentConfig, generator } = useMapViewRoutingMetadata();
    const {
        busFiltersConfiguration: { resetToDefaults, filtersDisabled }
    } = useMapViewConfiguration();

    const isPricedConfig = isPricedConstraintConfig(componentConfig);
    const isAllocatedCostsEnabled =
        isPricedConfig && componentConfig.hasAllocatedCost;

    const lowConfidenceBusFilterEnabled =
        componentConfig.allowUsersToViewLowConfidenceBuses;
    const unlockedBusesFilterEnabled = !useIsUnlimitedSubscription();
    const showOtherSection =
        lowConfidenceBusFilterEnabled || unlockedBusesFilterEnabled;

    const scopeFilterEnabled = hasMultipleScopeConfigurations(
        region,
        generator
    );
    const isPreExistingConstraintsEnabled =
        isPricedConfig && componentConfig.isPreExistingConstraintsEnabled;
    const showQueueAssumptionsSection =
        scopeFilterEnabled || isPreExistingConstraintsEnabled;

    return (
        <Dialog
            isOpen={isOpen}
            title={"All filters"}
            onClose={closeDialog}
            icon={IconNames.FILTER}
        >
            <DialogBody>
                <FiltersDialogSection header="Voltages">
                    <FilterWrapper>
                        <VoltageSelectWrapper />
                    </FilterWrapper>
                </FiltersDialogSection>
                {showQueueAssumptionsSection && (
                    <FiltersDialogSection header="Queue assumptions">
                        {scopeFilterEnabled && (
                            <ScopeSelectFilter inSidebar={false} />
                        )}
                        {isPreExistingConstraintsEnabled && (
                            <PreexistingFilter />
                        )}
                    </FiltersDialogSection>
                )}
                {isPricedConfig && (
                    <FiltersDialogSection header="Maximum costs">
                        {isAllocatedCostsEnabled && <AllocatedCostFilter />}
                        <TotalCostFilter />
                    </FiltersDialogSection>
                )}
                <FiltersDialogSection header="Color thresholds">
                    <ColorThresholdsFilter />
                </FiltersDialogSection>
                {showOtherSection && (
                    <FiltersDialogSection header="Other">
                        {lowConfidenceBusFilterEnabled && (
                            <LowConfidenceBusFilter />
                        )}
                        {unlockedBusesFilterEnabled && <UnlockedBusesFilter />}
                    </FiltersDialogSection>
                )}
            </DialogBody>
            <DialogFooter
                actions={
                    <AnchorButton intent="primary" onClick={closeDialog}>
                        Done
                    </AnchorButton>
                }
            >
                <Button
                    minimal={true}
                    onClick={resetToDefaults}
                    disabled={filtersDisabled}
                >
                    Reset all
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

export default ScopeSelectorDialog;

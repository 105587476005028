import { Intent, NonIdealState, ProgressBar } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import LoadingFullScreen from "components/common/LoadingFullScreen";
import { IN_QUEUE_PATH } from "infrastructure/RouterApp";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { segmentTrackOpenScenarioPage } from "./analytics";
import { BackButton } from "./components/common/BackButton";
import { SectionHeader } from "./components/common/SectionHeader";
import ScenarioActions from "./components/Scenario/ScenarioActions";
import ScenarioAssumptions from "./components/Scenario/ScenarioAssumptions";
import ScenarioTitle from "./components/Scenario/ScenarioTitle";
import SubmitScenarioRunButton from "./components/Scenario/SubmitScenarioRunButton";
import { ScenarioResults } from "./components/scenarios/ScenarioResults/ScenarioResults";
import { useCluster } from "./contexts/ClusterContext";
import {
    useScenarioMetadata,
    useTrackedProject
} from "./contexts/ProjectDataContext";
import {
    ScenarioPageProvider,
    useCurrentScenarioMetadata
} from "./contexts/ScenarioPageContext";
import css from "./ScenarioPage.module.scss";
import { ScenarioStatus } from "./types/scenarioType";
import { formatRelativeDate } from "./utils/formatting";

export const ScenarioPage: React.FC = () => {
    const { clusterId, projectId, scenarioId } = useParams();
    const cluster = useCluster();
    const navigate = useNavigate();
    const project = useTrackedProject({
        projectId,
        phase: cluster.studyPhase
    });
    const scenario = useScenarioMetadata(projectId, scenarioId);

    useEffect(() => {
        if (scenarioId) {
            segmentTrackOpenScenarioPage({
                scenarioId
            });
        }
    }, [scenarioId]);

    useEffect(() => {
        if (clusterId === undefined || projectId === undefined) {
            navigate(`/${IN_QUEUE_PATH}`);
        } else if (scenario === undefined) {
            navigate(`/${IN_QUEUE_PATH}/${clusterId}/${projectId}`);
        }
    }, [clusterId, projectId, scenario]);

    if (
        project === undefined ||
        project === "loading" ||
        scenario === undefined ||
        scenario === "loading"
    ) {
        return <LoadingFullScreen />;
    }

    return (
        <ScenarioPageProvider project={project} scenario={scenario}>
            <BackButton
                text={`Back to Project ${projectId}`}
                to={`/${IN_QUEUE_PATH}/${clusterId}/${projectId}`}
            />
            <div>
                <ScenarioTitle actions={<ScenarioActions />} />
                <SectionHeader level="h2" title="Assumptions" />
                <ScenarioAssumptions />
            </div>
            <ScenarioBody />
        </ScenarioPageProvider>
    );
};

const ScenarioBody: React.FC = () => {
    const scenario = useCurrentScenarioMetadata();

    const { status } = scenario;
    if (
        status === ScenarioStatus.RunSucceeded ||
        status === ScenarioStatus.Archived
    ) {
        return <ScenarioResults />;
    }

    let bodyElement = <></>;
    if (status === ScenarioStatus.Draft) {
        bodyElement = (
            <NonIdealState
                title="Draft scenario"
                icon={IconNames.EDIT}
                description={<div>Submit scenario to compute results.</div>}
                action={
                    <SubmitScenarioRunButton scenarioId={scenario.scenarioId} />
                }
            />
        );
    } else if (status === ScenarioStatus.RunFailed) {
        const description = (
            <div>
                Calculating results for this scenario failed unexpectedly. Nira
                has been notified of this issue and we're looking into it.
                <br />
                <br />
                To get in touch, please reach out to{" "}
                <a href="mailto:support@niraenergy.com">
                    support@niraenergy.com
                </a>
                .
            </div>
        );
        bodyElement = (
            <NonIdealState
                icon={IconNames.WARNING_SIGN}
                title="Scenario run failed"
                description={description}
            />
        );
    } else if (
        status == ScenarioStatus.RunRequested ||
        status === ScenarioStatus.RunSubmitted ||
        status === ScenarioStatus.Running
    ) {
        const runStartedAt = scenario.timeMetadata.runStartedAt;
        const description = (
            <div className={css["running-description"]}>
                <span>
                    Currently calculating results for this scenario.
                    {runStartedAt !== undefined && (
                        <>
                            <br />
                            <br />
                            Run started {formatRelativeDate(runStartedAt)} and
                            typically takes under 4 hours.
                        </>
                    )}
                </span>
                <ProgressBar value={0.4} intent={Intent.PRIMARY} />
            </div>
        );
        bodyElement = (
            <NonIdealState
                title="Scenario is running..."
                description={description}
            />
        );
    }

    return (
        <>
            <SectionHeader level="h2" title="Results" />
            {bodyElement}
        </>
    );
};

import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
    BusFilters,
    CapacityThresholds,
    getDefaultBusFilter,
    getDefaultBusFilterAndOverrideWithParams,
    MaybeMaxCosts
} from "types/busFilterTypes";
import { ScopeType } from "types/scopeType";
import {
    convertScreeningViewToBusFilters,
    ScreeningView
} from "types/screeningViewType";
import { Voltage } from "types/voltageTypes";

interface BusFiltersConfiguration {
    busFilters: BusFilters;
    updateScope: (newScope: ScopeType) => void;

    selectVoltage: (voltage: Voltage) => void;
    deselectVoltage: (voltage: Voltage) => void;
    clearVoltages: () => void;

    updateMaybeMaxAllocatedCosts: (
        maybeMaxAllocatedCosts: MaybeMaxCosts
    ) => void;
    updateMaybeMaxTotalCosts: (maybeMaxTotalCosts: MaybeMaxCosts) => void;
    toggleIncludePreExistingConstraints: () => void;
    toggleHideLockedBuses: () => void;
    toggleHideLowConfidenceBuses: () => void;

    updateCapacityThresholds: (capacityThresholds: CapacityThresholds) => void;

    resetToDefaults: () => void;

    filtersDisabled: boolean;
}

const useCreateBusFiltersConfiguration = (): BusFiltersConfiguration => {
    const { region, generator } = useMapViewRoutingMetadata();
    const [queryParams] = useSearchParams();

    const [busFilters, setBusFilters] = useState<BusFilters>(() =>
        getDefaultBusFilterAndOverrideWithParams(region, generator, queryParams)
    );

    const updateScope = (newScope: ScopeType) => {
        setBusFilters({
            ...busFilters,
            scope: newScope
        });
    };

    const updateMaybeMaxAllocatedCosts = (
        maybeMaxAllocatedCosts: MaybeMaxCosts
    ) => {
        setBusFilters({ ...busFilters, maybeMaxAllocatedCosts });
    };

    const updateMaybeMaxTotalCosts = (maybeMaxTotalCosts: MaybeMaxCosts) => {
        setBusFilters({
            ...busFilters,
            maybeMaxTotalCosts: maybeMaxTotalCosts
        });
    };

    const toggleIncludePreExistingConstraints = () => {
        setBusFilters({
            ...busFilters,
            includePreExistingConstraints:
                !busFilters.includePreExistingConstraints
        });
    };

    const toggleHideLockedBuses = () => {
        setBusFilters({
            ...busFilters,
            hideLockedBuses: !busFilters.hideLockedBuses
        });
    };

    const toggleHideLowConfidenceBuses = () => {
        setBusFilters({
            ...busFilters,
            hideLowConfidenceBuses: !busFilters.hideLowConfidenceBuses
        });
    };

    const updateCapacityThresholds = (
        capacityThresholds: CapacityThresholds
    ) => {
        setBusFilters({
            ...busFilters,
            capacityThresholds
        });
    };

    const resetToDefaults = () => {
        setBusFilters(getDefaultBusFilter(region, generator));
    };

    return {
        busFilters,
        updateScope,
        selectVoltage: getSelectVoltage(busFilters, setBusFilters),
        deselectVoltage: getDeselectVoltage(busFilters, setBusFilters),
        clearVoltages: getClearVoltages(busFilters, setBusFilters),
        updateMaybeMaxAllocatedCosts,
        updateMaybeMaxTotalCosts,
        toggleIncludePreExistingConstraints,
        toggleHideLockedBuses,
        toggleHideLowConfidenceBuses,
        updateCapacityThresholds,
        resetToDefaults,
        filtersDisabled: false
    };
};

// This is used when we want to load a screening view. We don't actually want to be able to edit any filters
// except for the voltage filters
const useCreateBusFiltersFromScreeningView = (
    maybeScreeningViewData: ScreeningView
): BusFiltersConfiguration => {
    const [busFilters, setBusFilters] = useState<BusFilters>(() =>
        convertScreeningViewToBusFilters(maybeScreeningViewData)
    );
    return {
        busFilters,
        updateScope: () => {
            return;
        },
        selectVoltage: getSelectVoltage(busFilters, setBusFilters),
        deselectVoltage: getDeselectVoltage(busFilters, setBusFilters),
        clearVoltages: getClearVoltages(busFilters, setBusFilters),
        updateMaybeMaxAllocatedCosts: () => {
            return;
        },
        updateMaybeMaxTotalCosts: () => {
            return;
        },
        toggleIncludePreExistingConstraints: () => {
            return;
        },
        toggleHideLockedBuses: () => {
            return;
        },
        toggleHideLowConfidenceBuses: () => {
            return;
        },
        updateCapacityThresholds: () => {
            return;
        },
        resetToDefaults: () => {
            return;
        },
        filtersDisabled: true
    };
};

const getSelectVoltage = (
    busFilters: BusFilters,
    setBusFilters: (busFilters: BusFilters) => void
): ((voltage: Voltage) => void) => {
    return (voltage: Voltage) => {
        const newVoltages = Array.from(
            new Set([...busFilters.voltages, voltage])
        );
        setBusFilters({
            ...busFilters,
            voltages: newVoltages
        });
    };
};

const getDeselectVoltage = (
    busFilters: BusFilters,
    setBusFilters: (busFilters: BusFilters) => void
): ((voltage: Voltage) => void) => {
    return (voltage: Voltage) => {
        setBusFilters({
            ...busFilters,
            voltages: busFilters.voltages.filter((v) => v !== voltage)
        });
    };
};

const getClearVoltages = (
    busFilters: BusFilters,
    setBusFilters: (busFilters: BusFilters) => void
): (() => void) => {
    return () => {
        setBusFilters({
            ...busFilters,
            voltages: []
        });
    };
};

export {
    useCreateBusFiltersConfiguration,
    useCreateBusFiltersFromScreeningView
};
export type { BusFiltersConfiguration };

import { DateTime } from "luxon";

const formatter = Intl.NumberFormat("en", {
    style: "currency",
    currency: "USD",
    notation: "compact",
    maximumSignificantDigits: 3
});
export const formatCosts = (dollarAmount: number | undefined): string => {
    if (dollarAmount === undefined || isNaN(dollarAmount)) {
        return "-";
    }
    return formatter.format(dollarAmount);
};

export const formatRelativeDate = (date: Date): string => {
    return DateTime.fromJSDate(date).toRelative() ?? "-";
};

export const formatPower = (mwPower: number): string => {
    const formatterMW = new Intl.NumberFormat("en", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "decimal"
    });

    const formatterGW = new Intl.NumberFormat("en", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
        style: "decimal"
    });

    if (mwPower >= 1000) {
        const powerInGW = mwPower / 1000;
        return `${formatterGW.format(powerInGW)}GW`;
    } else {
        return `${formatterMW.format(mwPower)}MW`;
    }
};

// eslint-disable-next-line import/no-unresolved
import { Feature, FeatureCollection, Point } from "geojson";
import { GENERATOR_TYPE_TO_AVAILABILITY_SIZE_TO_USE } from "sharedStyles/capacityColorStyles";
import { Bus, ScopedBus } from "types/busType";
import { GeneratorType } from "types/generatorType";

export const SIZE_KEY: keyof GeojsonScopedBus = "size";
export const BUS_ID_KEY: keyof GeojsonScopedBus = "id";

export type GeojsonScopedBus = {
    readonly id: string;
    readonly size: number;
};

export const getBusGeojson = (
    scopedBuses: ReadonlyArray<ScopedBus>,
    generatorType: GeneratorType
): Readonly<FeatureCollection> => {
    const sizeCalculatorFunction =
        GENERATOR_TYPE_TO_AVAILABILITY_SIZE_TO_USE[generatorType];

    const scopedBusFeatureList: Feature<Point, GeojsonScopedBus>[] =
        scopedBuses.map((scopedBus) => {
            return getFeature(
                scopedBus.bus,
                sizeCalculatorFunction(scopedBus.scopedCapacityEnergyCost)
            );
        });
    return {
        type: "FeatureCollection",
        features: scopedBusFeatureList
    };
};

const getFeature = (
    bus: Bus,
    size: number
): Readonly<Feature<Point, GeojsonScopedBus>> => {
    return {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [bus.longitude, bus.latitude]
        },
        properties: {
            size: size,
            id: bus.id
        }
    };
};

import { useRegionConfig } from "in_queue/contexts/ClusterContext";
import { ProjectSize } from "in_queue/types/projectType";
import { MisoPercentageReduction } from "in_queue/types/scenarioType";
import { reduceProjectSizeByPercentage } from "in_queue/types/scenarioUtils";
import css from "./ProjectSizePreview.module.scss";

interface ProjectSizePreviewProps {
    projectSize: ProjectSize;
    percentageReduction: MisoPercentageReduction;
    sizesToShow?: "eris" | "nris" | "both";
}

export const ProjectSizePreview: React.FC<ProjectSizePreviewProps> = ({
    projectSize,
    percentageReduction,
    sizesToShow = "both"
}: ProjectSizePreviewProps) => {
    const updatedProjectSize = reduceProjectSizeByPercentage(
        projectSize,
        percentageReduction
    );
    const config = useRegionConfig();

    if (sizesToShow == "both") {
        return (
            <span className={css["project-size-preview"]}>
                ({updatedProjectSize.erisMw}MW {config.sizeLabel.energyLabel},{" "}
                {updatedProjectSize.nrisMw}MW {config.sizeLabel.capacityLabel})
            </span>
        );
    } else if (sizesToShow == "eris") {
        return (
            <span className={css["project-size-preview"]}>
                ({updatedProjectSize.erisMw}MW {config.sizeLabel.energyLabel})
            </span>
        );
    } else {
        return (
            <span className={css["project-size-preview"]}>
                ({updatedProjectSize.nrisMw}MW {config.sizeLabel.capacityLabel})
            </span>
        );
    }
};

import { isStringArray } from "graphql/helpers/queryHelpers";
import { CoordinatesAndZoom } from "./coordinatesAndZoom";
import { RegionType } from "./regionType";

export const STATE_TYPES = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY"
] as const;
export type StateType = (typeof STATE_TYPES)[number];

export const STATES_TO_DISPLAY_NAME: Readonly<{ [key in StateType]: string }> =
    {
        AL: "Alabama",
        AK: "Alaska",
        AZ: "Arizona",
        AR: "Arkansas",
        CA: "California",
        CO: "Colorado",
        CT: "Connecticut",
        DC: "Washington DC",
        DE: "Delaware",
        FL: "Florida",
        GA: "Georgia",
        HI: "Hawaii",
        ID: "Idaho",
        IL: "Illinois",
        IN: "Indiana",
        IA: "Iowa",
        KS: "Kansas",
        KY: "Kentucky",
        LA: "Louisiana",
        ME: "Maine",
        MD: "Maryland",
        MA: "Massachusetts",
        MI: "Michigan",
        MN: "Minnesota",
        MS: "Mississippi",
        MO: "Missouri",
        MT: "Montana",
        NE: "Nebraska",
        NV: "Nevada",
        NH: "New Hampshire",
        NJ: "New Jersey",
        NM: "New Mexico",
        NY: "New York",
        NC: "North Carolina",
        ND: "North Dakota",
        OH: "Ohio",
        OK: "Oklahoma",
        OR: "Oregon",
        PA: "Pennsylvania",
        RI: "Rhode Island",
        SC: "South Carolina",
        SD: "South Dakota",
        TN: "Tennessee",
        TX: "Texas",
        UT: "Utah",
        VT: "Vermont",
        VA: "Virginia",
        WA: "Washington",
        WV: "West Virginia",
        WI: "Wisconsin",
        WY: "Wyoming"
    };

export const REGION_TO_STATES: Readonly<{
    [key in RegionType]: ReadonlySet<StateType>;
}> = {
    NYISO: new Set<StateType>(["NY"]),
    PJM: new Set<StateType>([
        "NJ",
        "PA",
        "MD",
        "DE",
        "DC",
        "VA",
        "WV",
        "OH",
        "KY",
        "TN",
        "IN",
        "MI",
        "IL",
        "NC"
    ]),
    MISO: new Set<StateType>([
        "AR",
        "IL",
        "IN",
        "IA",
        "KY",
        "LA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "ND",
        "SD",
        "TX",
        "WI"
    ]),
    CAISO: new Set<StateType>(["CA", "NV"]),
    SPP: new Set<StateType>([
        "ND",
        "SD",
        "MN",
        "NE",
        "IA",
        "KS",
        "MO",
        "LA",
        "TX",
        "OK",
        "NM",
        "MT",
        "AR"
    ]),
    ERCOT: new Set<StateType>(["TX"]),
    WECC: new Set<StateType>([
        "WA",
        "OR",
        "CA",
        "ID",
        "NV",
        "AZ",
        "UT",
        "WY",
        "MT",
        "CO",
        "NM",
        "TX",
        "NE",
        "SD"
    ]),
    TVA: new Set<StateType>(["TN", "MS", "AL", "KY", "GA", "NC", "VA"]),
    ISONE: new Set<StateType>(["CT", "VT", "RI", "MA", "NH", "ME"]),
    SOCO: new Set<StateType>(["GA", "AL", "MS", "FL"]),
    DUKE: new Set<StateType>(["NC", "SC"])
};

export const STATE_TO_DEFAULT_COORDINATES: {
    [key in StateType]: CoordinatesAndZoom;
} = {
    AL: { latitude: 32.806671, longitude: -86.79113, zoom: 8 },
    AK: { latitude: 61.370716, longitude: -152.404419, zoom: 8 },
    AZ: { latitude: 33.729759, longitude: -111.431221, zoom: 8 },
    AR: { latitude: 34.969704, longitude: -92.373123, zoom: 8 },
    CA: { latitude: 36.116203, longitude: -119.681564, zoom: 8 },
    CO: { latitude: 39.059811, longitude: -105.311104, zoom: 8 },
    CT: { latitude: 41.597782, longitude: -72.755371, zoom: 8 },
    DC: { latitude: 38.9072, longitude: -77.0369, zoom: 8 },
    DE: { latitude: 39.318523, longitude: -75.507141, zoom: 8 },
    FL: { latitude: 27.766279, longitude: -81.686783, zoom: 8 },
    GA: { latitude: 33.040619, longitude: -83.643074, zoom: 8 },
    HI: { latitude: 21.094318, longitude: -157.498337, zoom: 8 },
    ID: { latitude: 44.240459, longitude: -114.478828, zoom: 8 },
    IL: { latitude: 40.349457, longitude: -88.986137, zoom: 8 },
    IN: { latitude: 39.849426, longitude: -86.258278, zoom: 8 },
    IA: { latitude: 42.011539, longitude: -93.210526, zoom: 8 },
    KS: { latitude: 38.5266, longitude: -96.726486, zoom: 8 },
    KY: { latitude: 37.66814, longitude: -84.670067, zoom: 8 },
    LA: { latitude: 31.169546, longitude: -91.867805, zoom: 8 },
    ME: { latitude: 44.693947, longitude: -69.381927, zoom: 8 },
    MD: { latitude: 39.063946, longitude: -76.802101, zoom: 8 },
    MA: { latitude: 42.230171, longitude: -71.530106, zoom: 8 },
    MI: { latitude: 43.326618, longitude: -84.536095, zoom: 8 },
    MN: { latitude: 45.694454, longitude: -93.900192, zoom: 8 },
    MS: { latitude: 32.741646, longitude: -89.678696, zoom: 8 },
    MO: { latitude: 38.456085, longitude: -92.288368, zoom: 8 },
    MT: { latitude: 46.921925, longitude: -110.454353, zoom: 8 },
    NE: { latitude: 41.12537, longitude: -98.268082, zoom: 8 },
    NV: { latitude: 38.313515, longitude: -117.055374, zoom: 8 },
    NH: { latitude: 43.452492, longitude: -71.563896, zoom: 8 },
    NJ: { latitude: 40.298904, longitude: -74.521011, zoom: 8 },
    NM: { latitude: 34.840515, longitude: -106.248482, zoom: 8 },
    NY: { latitude: 42.165726, longitude: -74.948051, zoom: 8 },
    NC: { latitude: 35.630066, longitude: -79.806419, zoom: 8 },
    ND: { latitude: 47.528912, longitude: -99.784012, zoom: 8 },
    OH: { latitude: 40.388783, longitude: -82.764915, zoom: 8 },
    OK: { latitude: 35.565342, longitude: -96.928917, zoom: 8 },
    OR: { latitude: 44.572021, longitude: -122.070938, zoom: 8 },
    PA: { latitude: 40.590752, longitude: -77.209755, zoom: 8 },
    RI: { latitude: 41.680893, longitude: -71.51178, zoom: 8 },
    SC: { latitude: 33.856892, longitude: -80.945007, zoom: 8 },
    SD: { latitude: 44.299782, longitude: -99.438828, zoom: 8 },
    TN: { latitude: 35.747845, longitude: -86.692345, zoom: 8 },
    TX: { latitude: 31.054487, longitude: -97.563461, zoom: 8 },
    UT: { latitude: 40.150032, longitude: -111.862434, zoom: 8 },
    VT: { latitude: 44.045876, longitude: -72.710686, zoom: 8 },
    VA: { latitude: 37.769337, longitude: -78.169968, zoom: 8 },
    WA: { latitude: 47.400902, longitude: -121.490494, zoom: 8 },
    WV: { latitude: 38.491226, longitude: -80.95457, zoom: 8 },
    WI: { latitude: 44.268543, longitude: -89.616508, zoom: 8 },
    WY: { latitude: 42.755966, longitude: -107.30249, zoom: 8 }
};

export const isKnownStateType = (type: string): type is StateType => {
    return STATE_TYPES.includes(type as StateType);
};

export const convertToStateType = (string: string): StateType => {
    if (isKnownStateType(string)) {
        return string;
    }

    throw new Error("Unexpected state type");
};

export const convertHasuraAnyToStates = (
    hasuraObject: unknown
): StateType[] => {
    if (!isStringArray(hasuraObject)) {
        throw new Error("Expected array of strings");
    }

    const listOfStrings: string[] = hasuraObject;
    const validStates: StateType[] = listOfStrings
        .filter(isKnownStateType)
        .map(convertToStateType);

    return validStates;
};

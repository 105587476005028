import { useAuth0 } from "@auth0/auth0-react";
import {
    Button,
    ButtonGroup,
    Divider,
    Menu,
    MenuItem,
    Popover,
    Position,
    Tooltip
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import LoadingFullScreen from "components/common/LoadingFullScreen";
import TextLogo from "logo/textLogo";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import { ClusterPage } from "./ClusterPage";
import { InQueueBreadcrumbs } from "./components/common/Breadcrumbs";
import NoSubscriptions from "./components/NoSubscriptions";
import { ClusterProvider } from "./contexts/ClusterContext";
import {
    InQueueSubscriptionsProvider,
    useSubscriptions
} from "./contexts/InQueueSubscriptionsContext";
import { ProjectDataProvider } from "./contexts/ProjectDataContext";
import css from "./InQueueApp.module.scss";
import { ProjectPage } from "./ProjectPage";
import { RedirectToCluster } from "./RedirectToCluster";
import { ScenarioPage } from "./ScenarioPage";

export const InQueueApp: React.FC = () => {
    return (
        <InQueueSubscriptionsProvider>
            <InQueueAppBody />
        </InQueueSubscriptionsProvider>
    );
};

const InQueueAppBody: React.FC = () => {
    const subscriptions = useSubscriptions();

    if (subscriptions === "loading") {
        return <LoadingFullScreen />;
    } else if (subscriptions.length === 0) {
        return <NoSubscriptions />;
    } else {
        return (
            <Routes>
                <Route path="/" element={<RedirectToCluster />} />
                <Route element={<LayoutWithToolbar />}>
                    <Route path=":clusterId" element={<ClusterPage />} />
                    <Route
                        path=":clusterId/:projectId"
                        element={<ProjectPage />}
                    />
                    <Route
                        path=":clusterId/:projectId/scenario/:scenarioId"
                        element={<ScenarioPage />}
                    />
                </Route>
            </Routes>
        );
    }
};

const LayoutWithToolbar = () => (
    <ClusterProvider>
        <ProjectDataProvider>
            <div className={css["app-wrapper"]}>
                <Toolbar />
                <div className={css["app-layout"]}>
                    <Outlet />
                </div>
            </div>
        </ProjectDataProvider>
    </ClusterProvider>
);

const Toolbar: React.FC = () => {
    const { logout } = useAuth0();
    const userMenuContent = (
        <Menu>
            <MenuItem
                icon={IconNames.LOG_OUT}
                text="Logout"
                onClick={() => logout({ returnTo: window.location.origin })}
            />
        </Menu>
    );
    return (
        <div className={css["toolbar"]}>
            <div className={css["toolbar-left"]}>
                <TextLogo width={90} />
                <Divider />
                <InQueueBreadcrumbs />
            </div>
            <div>
                <Link to="/">
                    <ButtonGroup minimal large>
                        <Tooltip
                            position={Position.BOTTOM}
                            content="Go to Prospecting app"
                        >
                            <Button icon="path-search" />
                        </Tooltip>
                    </ButtonGroup>
                </Link>
                <ButtonGroup minimal large>
                    <Popover
                        minimal
                        position={Position.BOTTOM}
                        content={userMenuContent}
                    >
                        <Button icon="user" />
                    </Popover>
                </ButtonGroup>
            </div>
        </div>
    );
};

import * as CryptoJS from "crypto-js";
import { Buses, Capacity_Costs, Substations } from "generated/graphql";
import { convertToRegionType, RegionType } from "./regionType";
import { convertToVoltage, isKnownVoltageType, Voltage } from "./voltageTypes";

const SECRET_KEY_FOR_BUS_ID = "NIRA_BUS_ID_SECRET_KEY";

export type BusId = string;
export type EncryptedBusId = string;
export type SubstationId = string;

export const UnauthorizedCapacityCosts = "unauthorizedCapacityCosts";
export type UnauthorizedCapacityCostsType = typeof UnauthorizedCapacityCosts;

export type ScopedBus = {
    readonly bus: Bus;
    readonly scopedCapacityEnergyCost: CapacityEnergyCost;
};

export const isScopedBus = (
    bus: ScopedBus | UnauthorizedBus
): bus is ScopedBus => {
    return bus.scopedCapacityEnergyCost !== UnauthorizedCapacityCosts;
};

export type UnauthorizedBus = {
    readonly bus: Bus;
    readonly scopedCapacityEnergyCost: UnauthorizedCapacityCostsType;
};

export const isUnauthorizedBus = (
    bus: ScopedBus | UnauthorizedBus
): bus is UnauthorizedBus => {
    return bus.scopedCapacityEnergyCost === UnauthorizedCapacityCosts;
};

export const HIGH_CONFIDENCE_LOCATION_CODES = [1, 2];
export const LOW_CONFIDENCE_LOCATION_CODES = [3, 4];
export const ALL_LOCATION_CODES = [
    ...HIGH_CONFIDENCE_LOCATION_CODES,
    ...LOW_CONFIDENCE_LOCATION_CODES
];
export const isLowConfidenceLocationBus = (bus: ScopedBus): boolean => {
    return LOW_CONFIDENCE_LOCATION_CODES.includes(bus.bus.locationCode);
};

export type Bus = {
    readonly id: BusId;
    readonly voltage: Voltage;
    readonly busDisplayName: string;
    readonly longitude: number;
    readonly latitude: number;
    readonly region: RegionType;
    readonly substation: Substation;
    readonly locationCode: number;
};

export type Substation = {
    readonly id: SubstationId;
    readonly substationOwner: string;
    readonly substationDisplayName: string;
};

export type CapacityEnergyCost = {
    readonly capacitySize: number;
    readonly energySize: number;
    readonly chargingSize: number;
    readonly allocatedCosts: number;
    readonly totalCosts: number;
};

type HasuraSubstation = Readonly<
    { __typename?: "substations" } & Pick<
        Substations,
        "id" | "substation_display_name" | "substation_owner"
    >
>;

type HasuraBus = Readonly<
    Pick<
        Buses,
        | "id"
        | "voltage"
        | "latitude"
        | "longitude"
        | "bus_display_name"
        | "region"
        | "location_code"
    > & {
        capacity_costs: Array<HasuraCapacityCost>;
        substation: HasuraSubstation;
    }
>;

type HasuraCapacityCost = Readonly<
    { __typename?: "capacity_costs" } & Pick<
        Capacity_Costs,
        | "capacity_size"
        | "energy_size"
        | "charging_size"
        | "upgrade_costs"
        | "total_upgrade_costs"
    >
>;

const getCapacityEnergyCost = (
    hasuraCapacityCost: HasuraCapacityCost
): CapacityEnergyCost => {
    return {
        capacitySize: hasuraCapacityCost.capacity_size,
        energySize: hasuraCapacityCost.energy_size,
        chargingSize: hasuraCapacityCost.charging_size,
        allocatedCosts: hasuraCapacityCost.upgrade_costs,
        totalCosts: hasuraCapacityCost.total_upgrade_costs
    };
};

const hasuraSubstationToNiraSubstation = (
    hasuraSubstation: HasuraSubstation
): Substation => {
    return {
        id: hasuraSubstation.id,
        substationDisplayName: hasuraSubstation.substation_display_name,
        substationOwner: hasuraSubstation.substation_owner
    };
};

const hasuraBusToNiraBus = (hasuraBus: HasuraBus): Bus => {
    return {
        id: hasuraBus.id,
        voltage: convertToVoltage(hasuraBus.voltage),
        busDisplayName: hasuraBus.bus_display_name,
        longitude: hasuraBus.longitude,
        latitude: hasuraBus.latitude,
        region: convertToRegionType(hasuraBus.region),
        substation: hasuraSubstationToNiraSubstation(hasuraBus.substation),
        locationCode: hasuraBus.location_code
    };
};

export const convertHasuraBusesToNiraBusesAndSplitBasedOnAuth = (
    hasuraBuses: ReadonlyArray<HasuraBus>
): readonly [ReadonlyArray<ScopedBus>, ReadonlyArray<UnauthorizedBus>] => {
    let authorizedBuses: ReadonlyArray<ScopedBus> = [];
    let unauthorizedBuses: ReadonlyArray<UnauthorizedBus> = [];

    const filteredHasuraBusesKnownVoltages = hasuraBuses.filter((hasuraBus) =>
        isKnownVoltageType(hasuraBus.voltage)
    );

    filteredHasuraBusesKnownVoltages.forEach((hasuraBus) => {
        const convertedBusData = hasuraBusToNiraBus(hasuraBus);
        const isAuthorized = hasuraBus.capacity_costs.length > 0;

        if (!isAuthorized) {
            unauthorizedBuses = [
                ...unauthorizedBuses,
                {
                    scopedCapacityEnergyCost: UnauthorizedCapacityCosts,
                    bus: convertedBusData
                }
            ];
        } else {
            authorizedBuses = [
                ...authorizedBuses,
                {
                    scopedCapacityEnergyCost: getCapacityEnergyCost(
                        hasuraBus.capacity_costs[0]
                    ),
                    bus: convertedBusData
                }
            ];
        }
    });

    return [authorizedBuses, unauthorizedBuses] as const;
};

export const encryptBusId = (busId: BusId): string => {
    return CryptoJS.AES.encrypt(busId, SECRET_KEY_FOR_BUS_ID).toString();
};

export const decryptBusId = (busId: EncryptedBusId): string => {
    return CryptoJS.AES.decrypt(busId, SECRET_KEY_FOR_BUS_ID).toString(
        CryptoJS.enc.Utf8
    );
};

import { capitalize, invert } from "lodash";
import { RegionType } from "types/regionType";

export interface InQueueCluster {
    region: RegionType;
    studyCycle: StudyCycle;
    studyGroup: StudyGroup;
    studyPhase: StudyPhase;
}

export type StudyCycle =
    | "DPP-2020-Cycle"
    | "DPP-2021-Cycle"
    | "DPP-2022-Cycle"
    | "Transition Cycle 1"
    | "Transition Cycle 2"
    | "DISIS_2022";
export type StudyGroup =
    // MISO study groups
    | "South"
    | "Central"
    | "West"
    | "Atc"
    | "Itc"
    // PJM and SPP don't have study groups
    | "default_group";
export type StudyPhase =
    | "resubmission"
    | "pre_screen"
    | "one"
    | "two"
    | "three";

// String cluster ID, visible to users in the URL
export enum ClusterId {
    PJM_TC1_PH1 = "pjm-tc1-ph1",
    PJM_TC1_PH2 = "pjm-tc1-ph2",
    PJM_TC2_RESUBMISSION = "pjm-tc2-resubmission",
    MISO_2022_SOUTH_PRESCREEN = "miso-2022-south-prescreen",
    MISO_2022_SOUTH_PH1 = "miso-2022-south-ph1",
    MISO_2022_CENTRAL_PH1 = "miso-2022-central-ph1",
    MISO_2022_WEST_PH1 = "miso-2022-west-ph1",
    MISO_2022_ATC_PH1 = "miso-2022-atc-ph1",
    MISO_2022_ITC_PH1 = "miso-2022-itc-ph1",
    MISO_2021_SOUTH_PH1 = "miso-2021-south-ph1",
    MISO_2021_SOUTH_PH2 = "miso-2021-south-ph2",
    MISO_2021_CENTRAL_PH1 = "miso-2021-central-ph1",
    MISO_2021_ATC_PH2 = "miso-2021-atc-ph2",
    MISO_2020_CENTRAL_PH2 = "miso-2020-central-ph2",
    SPP_2022_PH1 = "spp-2022-ph1"
}
export const isValidClusterId = (
    maybeClusterId: string
): maybeClusterId is ClusterId => {
    return Object.values(ClusterId).includes(maybeClusterId as ClusterId);
};

export const clusterToClusterId = (cluster: InQueueCluster): ClusterId => {
    const clusterComponents = [
        cluster.region.toLowerCase(),
        getStudyCycleLabel(cluster.region, cluster.studyCycle).toLowerCase()
    ];
    if (cluster.region === "MISO") {
        clusterComponents.push(cluster.studyGroup.toLowerCase());
    }
    clusterComponents.push(getPhaseLabel(cluster.studyPhase, true));

    const clusterId = clusterComponents.join("-");
    if (isValidClusterId(clusterId)) {
        return clusterId;
    }
    throw new Error(`Unknown cluster: ${clusterId}`);
};

export const clusterIdToCluster = (
    clusterId: string | undefined
): InQueueCluster => {
    if (!clusterId) {
        throw new Error("Cluster ID is required");
    }

    const clusterIdParts = clusterId.split("-");
    const region = clusterIdParts[0].toUpperCase() as RegionType;
    const studyCycle = getStudyCycleFromUrlString(region, clusterIdParts[1]);
    let studyGroup: StudyGroup;
    let studyPhase: StudyPhase;
    if (region === "MISO") {
        studyGroup = capitalize(clusterIdParts[2]) as StudyGroup;
        studyPhase = CONCISE_PHASE_LABELS_TO_PHASE[clusterIdParts[3]];
    } else {
        studyGroup = "default_group";
        studyPhase = CONCISE_PHASE_LABELS_TO_PHASE[clusterIdParts[2]];
    }

    return { region, studyCycle, studyGroup, studyPhase };
};

const getStudyCycleFromUrlString = (
    region: RegionType,
    urlString: string
): StudyCycle => {
    if (urlString === "tc1") {
        return "Transition Cycle 1";
    } else if (urlString === "tc2") {
        return "Transition Cycle 2";
    } else if (region === "MISO") {
        return `DPP-${urlString}-Cycle` as StudyCycle;
    } else if (region === "SPP") {
        return `DISIS_${urlString}` as StudyCycle;
    } else {
        throw new Error("Unknown study cycle");
    }
};

export const getClusterLabel = (cluster: InQueueCluster): string => {
    return `${cluster.region} ${getCycleAndGroupLabel(
        cluster.region,
        cluster.studyCycle,
        cluster.studyGroup
    )} ${getPhaseLabel(cluster.studyPhase)}`;
};

export const getCycleAndGroupLabel = (
    region: RegionType,
    cycle: StudyCycle,
    group: StudyGroup
): string => {
    const cycleLabel = getStudyCycleLabel(region, cycle);
    const groupLabel = getStudyGroupLabel(region, group);
    return cycleLabel + (groupLabel ? ` ${groupLabel}` : "");
};

export const getStudyGroupLabel = (
    region: RegionType,
    group: StudyGroup
): string | undefined => {
    if (region === "MISO" && (group === "Atc" || group === "Itc")) {
        return `East (${group.toUpperCase()})`;
    } else if (region === "MISO") {
        return group;
    }
};

export const getStudyCycleLabel = (
    region: string,
    studyCycle: StudyCycle
): string => {
    if (region === "MISO") {
        return studyCycle.replace("DPP-", "").replace("-Cycle", "");
    } else if (region === "PJM") {
        return studyCycle.replace("Transition Cycle ", "TC");
    } else if (region === "SPP") {
        return studyCycle.replace("DISIS_", "");
    }
    return studyCycle;
};

const PHASE_LABELS: Record<StudyPhase, string> = {
    resubmission: "Resubmission",
    pre_screen: "Pre-screen",
    one: "Phase 1",
    two: "Phase 2",
    three: "Phase 3"
};
const CONCISE_PHASE_LABELS: Record<StudyPhase, string> = {
    resubmission: "resubmission",
    pre_screen: "prescreen",
    one: "ph1",
    two: "ph2",
    three: "ph3"
};
const CONCISE_PHASE_LABELS_TO_PHASE: Record<string, StudyPhase> = invert(
    CONCISE_PHASE_LABELS
) as Record<string, StudyPhase>;

export const getPhaseLabel = (
    studyPhase: StudyPhase,
    concise = false
): string => {
    if (concise) {
        return CONCISE_PHASE_LABELS[studyPhase];
    } else {
        return PHASE_LABELS[studyPhase];
    }
};

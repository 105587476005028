import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import useMapViewData from "contexts/MapViewDataContext/hooks/useMapViewData";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import useSelectedBuses from "contexts/SelectedBusesContext/useSelectedBuses";
import React from "react";
import { Layer, Source } from "react-map-gl";
import { getMapboxColorSteps } from "sharedStyles/capacityColorStyles";
import {
    BUS_ID_KEY,
    getBusGeojson,
    SIZE_KEY
} from "./geoJsonUtils/busGeoJsonUtils";

export const BUS_LAYER_ID = "buses";

const BusSource: React.FC = () => {
    const { generator: generatorType } = useMapViewRoutingMetadata();
    const { selectedBusesAtLocation, selectedFacility } = useSelectedBuses();
    const { scopedBuses, isLoadingBranches } = useMapViewData();
    const {
        busFiltersConfiguration: { busFilters }
    } = useMapViewConfiguration();
    const { capacityThresholds } = busFilters;

    const possibleSelectedBusIds = [
        selectedBusesAtLocation?.selectedBusId,
        selectedFacility?.fromBusId,
        selectedFacility?.toBusId
    ];

    const busIdEqualsCases = possibleSelectedBusIds.map((busId) => [
        "==",
        busId || null,
        ["get", BUS_ID_KEY]
    ]);
    const matchAnySelectedBusId = ["any", ...busIdEqualsCases];
    // We artificially slow down bus loading to give branches time to render.
    const busesToDisplay = isLoadingBranches ? [] : scopedBuses;

    return (
        <Source
            id="bus-data"
            type="geojson"
            data={getBusGeojson(busesToDisplay, generatorType)}
        >
            <Layer
                id={BUS_LAYER_ID}
                type="circle"
                paint={{
                    "circle-radius": ["case", matchAnySelectedBusId, 5, 4],
                    "circle-color": "#FFFFFF",
                    "circle-stroke-width": [
                        "case",
                        matchAnySelectedBusId,
                        7,
                        4
                    ],
                    "circle-stroke-color": [
                        "step",
                        ["get", SIZE_KEY],
                        ...getMapboxColorSteps(capacityThresholds)
                    ]
                }}
            />
        </Source>
    );
};

export default BusSource;

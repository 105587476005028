import LoadingFullScreen from "components/common/LoadingFullScreen";
import { GetScreeningViewsQuery } from "generated/graphql";
import { loader } from "graphql.macro";
import { getHasuraDataAndConvertToNiraType } from "graphql/helpers/queryHelpers";
import { maybeScreeningViewIdFilter } from "graphql/helpers/screeningViewsWhereClause";
import { ErrorDialog } from "infrastructure/ErrorDialog";
import { createContext, PropsWithChildren, useContext } from "react";
import { useParams } from "react-router-dom";
import {
    hasuraScreeningViewByPkToNiraScreeningView,
    ScreeningView,
    ScreeningViewId
} from "types/screeningViewType";

const GET_SCREENING_VIEW = loader("src/graphql/getScreeningViews.graphql");

// null is just the initial value. Undefined means it wasnt in the url param
export const ScreeningViewContext = createContext<
    ScreeningView | null | undefined
>(null);

export const ScreeningViewProvider: React.FC<PropsWithChildren<unknown>> = (
    props: PropsWithChildren<unknown>
) => {
    const { children } = props;
    const { screeningViewId } = useParams();

    if (screeningViewId) {
        return (
            <InnerScreeningViewProvider screeningViewId={screeningViewId}>
                {children}
            </InnerScreeningViewProvider>
        );
    } else {
        return (
            <ScreeningViewContext.Provider value={undefined}>
                {children}
            </ScreeningViewContext.Provider>
        );
    }
};

type InnerScreeningViewProviderProps = {
    screeningViewId: ScreeningViewId;
};

const InnerScreeningViewProvider: React.FC<
    PropsWithChildren<InnerScreeningViewProviderProps>
> = (props: PropsWithChildren<InnerScreeningViewProviderProps>) => {
    const { children, screeningViewId } = props;

    let maybeScreeningViewData = undefined;
    try {
        maybeScreeningViewData = getHasuraDataAndConvertToNiraType(
            GET_SCREENING_VIEW,
            (hasuraData: GetScreeningViewsQuery) =>
                hasuraScreeningViewByPkToNiraScreeningView(
                    hasuraData.screening_views
                ),
            {
                maybe_id_filter: maybeScreeningViewIdFilter(screeningViewId)
            }
        );
    } catch (error) {
        let maybeError;
        if (error instanceof Error) {
            maybeError = error.message;
        }
        return (
            <ErrorDialog
                title="Error loading screening view"
                friendlyDescription={
                    "There was an error while loading your screening view. " +
                    "It's likely that the view was deleted, or that it referenced a scope that was removed."
                }
                errorMessage={maybeError}
            />
        );
    }

    if (maybeScreeningViewData === "loading") {
        return <LoadingFullScreen />;
    }

    const finalScreeningViewData: ScreeningView = maybeScreeningViewData;

    return (
        <ScreeningViewContext.Provider value={finalScreeningViewData}>
            {children}
        </ScreeningViewContext.Provider>
    );
};

export const useScreeningViewContext = (): ScreeningView | undefined => {
    const context = useContext(ScreeningViewContext);
    if (context === null) {
        throw new Error(
            "useScreeningViewContext must be used within a ScreeningViewProvider"
        );
    }

    return context;
};

import "./BusItemList.scss";

import { Icon, Spinner, SpinnerSize } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import useMapViewData from "contexts/MapViewDataContext/hooks/useMapViewData";
import React from "react";
import { Virtuoso } from "react-virtuoso";
import { ScopedBus } from "types/busType";
import BusItem from "./BusItem";

const BusItemList: React.FC = () => {
    const { scopedBuses, isLoadingBuses, isLoadingBranches } = useMapViewData();

    // We show loading when the branches are loading in the beginning. This artificially
    // slows down the bus load, but makes it feel faster.
    if (isLoadingBranches) {
        return getLoadingComponent();
    }

    if (scopedBuses.length === 0) {
        return getNoBusesZeroStateOrLoading(isLoadingBuses);
    }

    const sortedScopedBuses: ScopedBus[] = [...scopedBuses];
    sortedScopedBuses.sort(
        (a, b) =>
            b.scopedCapacityEnergyCost.energySize -
            a.scopedCapacityEnergyCost.energySize
    );

    return (
        <Virtuoso
            className="BusItemList-wrapper"
            data={sortedScopedBuses}
            itemContent={(index, scopedBus) => (
                <BusItem isLoading={isLoadingBuses} scopedBus={scopedBus} />
            )}
        />
    );
};

const getNoBusesZeroStateOrLoading = (busesLoading: boolean): JSX.Element => {
    return busesLoading ? (
        getLoadingComponent()
    ) : (
        <div className="BusItemList-zero-state-wrapper">
            <Icon
                icon={IconNames.SEARCH}
                size={SpinnerSize.STANDARD}
                className="BusItemList-zero-state-icon"
            />
            <h3 className="bp5-heading BusItemList-zero-state-header">
                {"No search results"}
            </h3>
        </div>
    );
};

const getLoadingComponent = () => (
    <div className="BusItemList-zero-state-wrapper">
        <Spinner className="BusItemList-zero-state-icon" />
        <h3 className="bp5-heading BusItemList-zero-state-header">
            {"Loading..."}
        </h3>
    </div>
);

export default BusItemList;

import { track } from "analytics/analytics";

const IN_QUEUE_PREFIX = "in_queue_";
const PROJECT_ID_PROPERTY = "project_id";
const SCENARIO_ID_PROPERTY = "scenario_id";

const OPEN_HOME_PAGE_NAME = `${IN_QUEUE_PREFIX}open_home_page`;
export const segmentTrackOpenHomePage = (): void => {
    track(OPEN_HOME_PAGE_NAME, {});
};

const OPEN_PROJECT_PAGE_NAME = `${IN_QUEUE_PREFIX}open_project_page`;
export const segmentTrackOpenProjectPage = ({
    projectId
}: {
    projectId: string;
}): void => {
    track(OPEN_PROJECT_PAGE_NAME, {
        [PROJECT_ID_PROPERTY]: projectId
    });
};

const OPEN_SCENARIO_PAGE_NAME = `${IN_QUEUE_PREFIX}open_scenario_page`;
export const segmentTrackOpenScenarioPage = ({
    scenarioId
}: {
    scenarioId: string;
}): void => {
    track(OPEN_SCENARIO_PAGE_NAME, {
        [SCENARIO_ID_PROPERTY]: scenarioId
    });
};

const CREATE_SCENARIO_NAME = `${IN_QUEUE_PREFIX}create_scenario`;
const REGION_PROPERTY = "region";
const STUDY_GROUP_PROPERTY = "study_group";
const STUDY_CYCLE_PROPERTY = "study_cycle";
const STUDY_PHASE_PROPERTY = "study_phase";
const SCENARIO_STAGE_PROPERTY = "scenario_stage";
const SCENARIO_TITLE_PROPERTY = "scenario_title";
const NUM_PROJECT_SIZE_ASSUMPTIONS_PROPERTY = "num_project_size_assumptions";
export const segmentTrackCreateScenario = (props: {
    region: string;
    studyGroup: string;
    studyCycle: string;
    studyPhase: string;
    scenarioStage: string;
    scenarioTitle: string;
    numProjectSizeAssumptions: number;
}): void => {
    track(CREATE_SCENARIO_NAME, {
        [REGION_PROPERTY]: props.region,
        [STUDY_GROUP_PROPERTY]: props.studyGroup,
        [STUDY_CYCLE_PROPERTY]: props.studyCycle,
        [STUDY_PHASE_PROPERTY]: props.studyPhase,
        [SCENARIO_STAGE_PROPERTY]: props.scenarioStage,
        [SCENARIO_TITLE_PROPERTY]: props.scenarioTitle,
        [NUM_PROJECT_SIZE_ASSUMPTIONS_PROPERTY]: props.numProjectSizeAssumptions
    });
};

const EDIT_SCENARIO_NAME = `${IN_QUEUE_PREFIX}edit_scenario`;
export const segmentTrackEditScenario = (props: {
    scenarioId: string;
    scenarioTitle: string;
    numProjectSizeAssumptions: number;
}): void => {
    track(EDIT_SCENARIO_NAME, {
        [SCENARIO_ID_PROPERTY]: props.scenarioId,
        [SCENARIO_TITLE_PROPERTY]: props.scenarioTitle,
        [NUM_PROJECT_SIZE_ASSUMPTIONS_PROPERTY]: props.numProjectSizeAssumptions
    });
};

const EDIT_SCENARIO_TITLE_NAME = `${IN_QUEUE_PREFIX}edit_scenario_title`;
export const segmentTrackEditScenarioTitle = (props: {
    scenarioId: string;
    scenarioTitle: string;
}): void => {
    track(EDIT_SCENARIO_TITLE_NAME, {
        [SCENARIO_ID_PROPERTY]: props.scenarioId,
        [SCENARIO_TITLE_PROPERTY]: props.scenarioTitle
    });
};

const DELETE_SCENARIO_NAME = `${IN_QUEUE_PREFIX}delete_scenario`;
export const segmentTrackDeleteScenario = ({
    scenarioId
}: {
    scenarioId: string;
}): void => {
    track(DELETE_SCENARIO_NAME, {
        [SCENARIO_ID_PROPERTY]: scenarioId
    });
};

const ARCHIVE_SCENARIO_NAME = `${IN_QUEUE_PREFIX}archive_scenario`;
const SCENARIO_STATUS_PROPERTY = "status";
export const segmentTrackArchiveScenario = ({
    scenarioId,
    action
}: {
    scenarioId: string;
    action: "archive" | "unarchive";
}): void => {
    track(ARCHIVE_SCENARIO_NAME, {
        [SCENARIO_ID_PROPERTY]: scenarioId,
        [SCENARIO_STATUS_PROPERTY]:
            action === "archive" ? "ARCHIVED" : "UNARCHIVED"
    });
};

const SUBMIT_SCENARIO_RUN_NAME = `${IN_QUEUE_PREFIX}submit_scenario_run`;
export const segmentTrackSubmitScenarioRun = ({
    scenarioId
}: {
    scenarioId: string;
}): void => {
    track(SUBMIT_SCENARIO_RUN_NAME, {
        [SCENARIO_ID_PROPERTY]: scenarioId
    });
};

const EXPORT_SCENARIO_DATA_NAME = `${IN_QUEUE_PREFIX}export_scenario_data`;
const EXPORT_NAME_PROPERTY = "export_name";
export const segmentTrackExportScenarioData = ({
    scenarioId,
    exportName
}: {
    scenarioId: string;
    exportName: string;
}): void => {
    track(EXPORT_SCENARIO_DATA_NAME, {
        [SCENARIO_ID_PROPERTY]: scenarioId,
        [EXPORT_NAME_PROPERTY]: exportName
    });
};

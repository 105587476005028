import { useState } from "react";
import { DEFAULT_LAYER_STYLE_ID, LayerStyleId } from "types/layerStyleTypes";

interface LayerStyleConfiguration {
    readonly layerStyleId: LayerStyleId;
    readonly setLayerStyleId: (newLayerStyleId: LayerStyleId) => void;
}

const useCreateLayerStyleConfiguration = (): LayerStyleConfiguration => {
    const [layerStyleId, setLayerStyleId] = useState<LayerStyleId>(
        DEFAULT_LAYER_STYLE_ID
    );

    return {
        layerStyleId,
        setLayerStyleId
    };
};

export { useCreateLayerStyleConfiguration };
export type { LayerStyleConfiguration };

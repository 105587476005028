import { TrackedProject } from "in_queue/types/projectType";
import { ScenarioMetadata } from "in_queue/types/scenarioType";
import { createContext, useContext } from "react";

interface ScenarioPageContextValue {
    project: TrackedProject;
    scenario: ScenarioMetadata;
}

export const ScenarioPageContext = createContext<
    ScenarioPageContextValue | undefined
>(undefined);

export const ScenarioPageProvider: React.FC<{
    project: TrackedProject;
    scenario: ScenarioMetadata;
}> = ({ project, scenario, children }) => {
    return (
        <ScenarioPageContext.Provider value={{ project, scenario }}>
            {children}
        </ScenarioPageContext.Provider>
    );
};

const useScenarioPageContext = (): ScenarioPageContextValue => {
    const context = useContext(ScenarioPageContext);
    if (context === undefined) {
        throw new Error(
            "useScenarioPageContext must be used within a ScenarioPageProvider"
        );
    }

    return context;
};

export const useCurrentProjectId = (): string => {
    const context = useScenarioPageContext();
    return context.project.projectId;
};

export const useCurrentTrackedProject = (): TrackedProject => {
    const context = useScenarioPageContext();
    return context.project;
};

export const useCurrentScenarioMetadata = (): ScenarioMetadata => {
    const context = useScenarioPageContext();

    return context.scenario;
};

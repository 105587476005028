import { useUserDataContext } from "contexts/UserDataContext/UserDataContext";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { getRedirectUrl } from "types/gitBook";
import { ErrorDialog } from "./ErrorDialog";

// This is used to specify the Gitbook space. AKA the common-docs space, or a region space.
const GITBOOK_SPACE_SEARCH_PARAM = "space";
// This is used to specify the page that is being viewed inside the space.
const GITBOOK_LOCATION_PARAM = "location";

export const DocumentationAuthenticationApp: React.FC = () => {
    const [searchParams] = useSearchParams();
    const maybeSpaceParam = searchParams.get(GITBOOK_SPACE_SEARCH_PARAM);
    const maybeLocationParam = searchParams.get(GITBOOK_LOCATION_PARAM);

    const user = useUserDataContext();

    // This would happen if we misconfigured Gitbook to not pass in a space
    if (!maybeSpaceParam) {
        return getDenyComponent();
    }

    const maybeUrl = getRedirectUrl(maybeSpaceParam, maybeLocationParam, user);

    if (!maybeUrl) {
        return getDenyComponent();
    }

    window.location.href = maybeUrl;

    return null;
};

const getDenyComponent = () => {
    return (
        <ErrorDialog
            title={"Access denied"}
            friendlyDescription={
                "You do not have permission to view this page of documentation. " +
                "You're likely missing a subscription to the region."
            }
        />
    );
};

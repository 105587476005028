import { convertMaybeToUndefined } from "graphql/helpers/queryHelpers";
import { SCREENING_VIEWS_PATH } from "infrastructure/RouterApp";
import { DateTime } from "luxon";
import { BusFilters } from "./busFilterTypes";
import { convertToGeneratorType, GeneratorType } from "./generatorType";
import { QUERY_PARAMS_FOR_MAP_VIEW } from "./queryParams";
import { convertToRegionType, RegionType } from "./regionType";
import { convertToScopeType, ScopeType } from "./scopeType";
import { convertHasuraAnyToStates, StateType } from "./stateType";
import { REGION_TO_FUEL_TYPE_TO_URL_DICT } from "./urlDirectoryType";
import { convertHasuraAnyToVoltages, Voltage } from "./voltageTypes";

export type ScreeningViewId = string;

export type ScreeningView = {
    readonly id: ScreeningViewId;
    readonly createdAt: DateTime;
    readonly authorEmail: string;
    readonly description: string;
    readonly generatorType: GeneratorType;
    readonly hideLockedBuses: boolean;
    readonly hideLowConfidenceBuses: boolean;
    readonly highThreshold: number;
    readonly includesPreExisting: boolean;
    readonly lowThreshold: number;
    readonly maxAllocatedCosts?: number;
    readonly maxTotalCosts?: number;
    readonly mediumThreshold: number;
    readonly region: RegionType;
    readonly scope: ScopeType;
    readonly states: StateType[];
    readonly title: string;
    readonly voltages: Voltage[];
};

export type HasuraScreeningView = {
    readonly id: string;
    readonly created_at: string;
    readonly description: string;
    readonly generator_type: string;
    readonly hide_locked_buses: boolean;
    readonly hide_low_confidence_buses: boolean;
    readonly high_threshold: number;
    readonly includes_pre_existing: boolean;
    readonly low_threshold: number;
    readonly max_allocated_costs?: number | null;
    readonly max_total_costs?: number | null;
    readonly medium_threshold: number;
    readonly region: string;
    readonly scope: string;
    readonly states: unknown;
    readonly title: string;
    readonly voltages: unknown;
    readonly user: HasuraEmailUser;
};

type HasuraEmailUser = {
    readonly email: string;
};

export const hasuraScreeningViewByPkToNiraScreeningView = (
    hasuraViews: HasuraScreeningView[]
): ScreeningView => {
    if (hasuraViews.length === 0) {
        throw new Error("Could not find screening view in database.");
    }

    return convertHasuraScreeningViewToNiraScreeningView(hasuraViews[0]);
};

// TODO: Handle displaying a bad view, but not letting users load it.
// Right now, if a user has a view with an old scope, it'll just get filtered out. It'd be better to display it, but
// not let users open it.
export const hasuraScreeningViewsToNiraScreeningViews = (
    hasuraViews: HasuraScreeningView[]
): ScreeningView[] => {
    return hasuraViews
        .map((hasuraScreeningView) => {
            try {
                return convertHasuraScreeningViewToNiraScreeningView(
                    hasuraScreeningView
                );
            } catch (error) {
                return undefined;
            }
        })
        .filter(
            (screeningView): screeningView is ScreeningView =>
                screeningView !== undefined
        );
};

// TODO: Handle a user loading an invalid screening view directly in the URL more elegantly. Right now, the app will crash.
// You can imagine if we delete a scope name, but a user has saved a view with that scope, this will start failing.
const convertHasuraScreeningViewToNiraScreeningView = (
    hasuraScreeningView: HasuraScreeningView
): ScreeningView => {
    return {
        id: hasuraScreeningView.id,
        createdAt: DateTime.fromISO(hasuraScreeningView.created_at),
        authorEmail: hasuraScreeningView.user.email,
        description: hasuraScreeningView.description,
        generatorType: convertToGeneratorType(
            hasuraScreeningView.generator_type
        ),
        hideLockedBuses: hasuraScreeningView.hide_locked_buses,
        hideLowConfidenceBuses: hasuraScreeningView.hide_low_confidence_buses,
        highThreshold: hasuraScreeningView.high_threshold,
        includesPreExisting: hasuraScreeningView.includes_pre_existing,
        lowThreshold: hasuraScreeningView.low_threshold,
        maxAllocatedCosts: convertMaybeToUndefined(
            hasuraScreeningView.max_allocated_costs
        ),
        maxTotalCosts: convertMaybeToUndefined(
            hasuraScreeningView.max_total_costs
        ),
        mediumThreshold: hasuraScreeningView.medium_threshold,
        region: convertToRegionType(hasuraScreeningView.region),
        scope: convertToScopeType(hasuraScreeningView.scope),
        states: convertHasuraAnyToStates(hasuraScreeningView.states),
        title: hasuraScreeningView.title,
        voltages: convertHasuraAnyToVoltages(hasuraScreeningView.voltages)
    };
};

export const convertScreeningViewToBusFilters = (
    screeningView: ScreeningView
): BusFilters => {
    return {
        voltages: screeningView.voltages,
        maybeMaxAllocatedCosts: {
            enabled: screeningView.maxAllocatedCosts !== undefined,
            maxCosts:
                screeningView.maxAllocatedCosts === undefined
                    ? // Note: this will only be set when this filter isn't in use, so this could be anything
                      10_000_000
                    : screeningView.maxAllocatedCosts
        },
        maybeMaxTotalCosts: {
            enabled: screeningView.maxTotalCosts !== undefined,
            maxCosts:
                screeningView.maxTotalCosts === undefined
                    ? // Note: this will only be set when this filter isn't in use, so this could be anything
                      10_000_000
                    : screeningView.maxTotalCosts
        },
        scope: screeningView.scope,
        hideLockedBuses: screeningView.hideLockedBuses,
        includePreExistingConstraints: screeningView.includesPreExisting,
        hideLowConfidenceBuses: screeningView.hideLockedBuses,
        capacityThresholds: {
            lowThreshold: screeningView.lowThreshold,
            mediumThreshold: screeningView.mediumThreshold,
            highThreshold: screeningView.highThreshold
        }
    };
};

export const getUrlForScreeningView = (
    screeningView: ScreeningView
): string | undefined => {
    return getUrlForScreeningViewId(
        screeningView.id,
        screeningView.region,
        screeningView.generatorType
    );
};

export const getUrlForScreeningViewId = (
    screeningViewId: ScreeningViewId,
    region: RegionType,
    generatorType: GeneratorType
): string | undefined => {
    const maybeBaseUrl = REGION_TO_FUEL_TYPE_TO_URL_DICT[region][generatorType];
    if (maybeBaseUrl) {
        return `/${maybeBaseUrl}/${SCREENING_VIEWS_PATH}/${screeningViewId}`;
    }
};

export const getUrlForScreeningViewWithBus = (
    screeningView: ScreeningView,
    encryptedBusId: string,
    latitude: number,
    longitude: number,
    baseUrl: string
): string => {
    const maybeScreeningViewUrl = getUrlForScreeningView(screeningView);
    if (!maybeScreeningViewUrl) {
        return baseUrl;
    }

    const encodedEncryptedBusId = encodeURIComponent(encryptedBusId);
    const defaultZoom = 11;

    const queryParams = [
        `${QUERY_PARAMS_FOR_MAP_VIEW.encryptedSelectedBusId}=${encodedEncryptedBusId}`,
        `${QUERY_PARAMS_FOR_MAP_VIEW.latitude}=${latitude}`,
        `${QUERY_PARAMS_FOR_MAP_VIEW.longitude}=${longitude}`,
        `${QUERY_PARAMS_FOR_MAP_VIEW.zoom}=${defaultZoom}`
    ].join("&");
    return `${baseUrl}${maybeScreeningViewUrl}?${queryParams}`;
};

import "./PlanDetailsDialog.scss";

import { Dialog, DialogBody, Spinner, SpinnerSize } from "@blueprintjs/core";
import { GetSubscriptionsQuery } from "generated/graphql";
import { loader } from "graphql.macro";
import { getHasuraDataAndConvertToNiraType } from "graphql/helpers/queryHelpers";
import { Loading } from "types/loadingType";
import {
    hasuraSubscriptionsToNiraSubscriptions,
    Subscription
} from "types/subscriptionType";

type PlanDetailsDialogProps = {
    readonly isOpen: boolean;
    readonly closeDialog: () => void;
};

const GET_SUBSCRIPTIONS = loader("src/graphql/getSubscriptions.graphql");

const PlanDetailsDialog: React.FC<PlanDetailsDialogProps> = (props) => {
    const { isOpen, closeDialog } = props;

    const maybeSubscriptions = getHasuraDataAndConvertToNiraType(
        GET_SUBSCRIPTIONS,
        (hasuraData: GetSubscriptionsQuery) =>
            hasuraSubscriptionsToNiraSubscriptions(hasuraData.subscriptions),
        undefined,
        !isOpen,
        "no-cache"
    );

    return (
        <Dialog
            isOpen={isOpen}
            title={"View Plan"}
            onClose={closeDialog}
            icon="credit-card"
        >
            <DialogBody useOverflowScrollContainer={false}>
                {getDialogContents(maybeSubscriptions)}
            </DialogBody>
        </Dialog>
    );
};

const getDialogContents = (
    maybeSubscriptions: Loading<ReadonlyArray<Subscription>>
): JSX.Element => {
    if (maybeSubscriptions === "loading") {
        return <Spinner size={SpinnerSize.LARGE} />;
    }
    const subscriptionInfoStrings = maybeSubscriptions.map((subscription) =>
        getSubscriptionDetails(subscription)
    );
    return <div>{subscriptionInfoStrings}</div>;
};

const getSubscriptionDetails = (subscription: Subscription): JSX.Element => {
    const unlimitedDescription = "unlimited buses";
    const limitedDescription = `${subscription.numUnlockedBuses}/${subscription.numBuses} buses used`;
    const description = subscription.unlimited
        ? unlimitedDescription
        : limitedDescription;

    const subscriptionDetails = `${description}`;

    return (
        <div
            className="PlanDetailsDialog-subscription-details"
            key={subscription.region}
        >
            <strong>{subscription.region}: </strong>
            {subscriptionDetails}
        </div>
    );
};

export default PlanDetailsDialog;

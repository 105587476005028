export const ADMIN_TYPE = "admin";
export const SCREENER_TYPE = "screener";
export const READER_TYPE = "reader";

export const ROLE_TYPES = [ADMIN_TYPE, SCREENER_TYPE, READER_TYPE] as const;
export type RoleType = (typeof ROLE_TYPES)[number];

export const isKnownRoleType = (type: string): type is RoleType => {
    return ROLE_TYPES.includes(type as RoleType);
};

export const convertToRoleType = (string: string): RoleType => {
    if (isKnownRoleType(string)) {
        return string;
    }

    throw new Error("Unexpected role type");
};

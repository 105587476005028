import { useQuery } from "@apollo/client";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { useScreeningViewContext } from "contexts/ScreeningViewContext/ScreeningViewContext";
import {
    GetBranchesQuery,
    GetBranchesQueryResult,
    GetBranchesQueryVariables
} from "generated/graphql";
import { loader } from "graphql.macro";
import { useMemo } from "react";
import {
    hasuraBranchesToNiraBranches,
    VoltageToBranchesDict
} from "types/branchType";
import { Loading } from "types/loadingType";
import { REGIONS_TO_VOLTAGES } from "types/voltageTypes";

const GET_BRANCHES = loader("src/graphql/getBranches.graphql");

interface Branches {
    branches: VoltageToBranchesDict | undefined;
    isLoading: boolean;
}
const useBranchData = (): Branches => {
    const { region } = useMapViewRoutingMetadata();
    const maybeScreeningViewData = useScreeningViewContext();

    const activeBranchesQuery = useQuery<
        GetBranchesQuery,
        GetBranchesQueryVariables
    >(GET_BRANCHES, {
        variables: {
            region,
            voltages: [...REGIONS_TO_VOLTAGES[region]],
            filter_states_where_clause: maybeScreeningViewData
                ? { states: { _has_keys_any: maybeScreeningViewData.states } }
                : {}
        },
        // We remount the entire app when the region changes, after you click the Region Select Dialog.
        // If you switch from MISO -> WECC -> MISO, your second trip to MISO would have used the cache
        // by default. This then freezes the page while you're still in the Region Select Dialog
        // since the query returned so quickly from the cache, it'll start calling the expensive
        // getBranchesFromQuery below - before the dialog even closes. So for now, we'll just
        // force another query by ignoring the cache.
        fetchPolicy: "no-cache"
    });

    const voltageToBranches = useMemo(
        () => getBranchesFromQuery(activeBranchesQuery),
        [activeBranchesQuery]
    );

    return {
        branches:
            voltageToBranches === "loading" ? undefined : voltageToBranches,
        isLoading: voltageToBranches === "loading"
    };
};

const getBranchesFromQuery = (
    query: GetBranchesQueryResult
): Loading<VoltageToBranchesDict> => {
    if (query.loading || query.data === undefined) {
        return "loading";
    } else {
        return hasuraBranchesToNiraBranches(query.data.branches);
    }
};

export { useBranchData };

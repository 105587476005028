import { InputGroup, Tab, Tabs } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ScenarioAllocatedUpgradesTable } from "in_queue/components/tables/ScenarioAllocatedUpgradesTable";
import { ScenarioConstraintsTable } from "in_queue/components/tables/ScenarioConstraintsTable";
import { ScenarioProjectsTable } from "in_queue/components/tables/ScenarioProjectsTable";
import { useCurrentProjectId } from "in_queue/contexts/ScenarioPageContext";
import { ScenarioResultsProvider } from "in_queue/contexts/ScenarioResultsContext";
import { useState } from "react";
import { SectionHeader } from "../../common/SectionHeader";
import { ScenarioMetrics } from "../../metrics/ScenarioMetrics";
import css from "./ScenarioResults.module.scss";

export const ScenarioResults: React.FC = () => {
    const projectId = useCurrentProjectId();
    return (
        <ScenarioResultsProvider>
            <div className={css.results}>
                <SectionHeader level="h2" title={`Results for ${projectId}`} />
                <ScenarioMetrics />
            </div>
            <div className={css.tables}>
                <SectionHeader level="h2" title="Details" />
                <ScenarioTables />
            </div>
        </ScenarioResultsProvider>
    );
};

const ScenarioTables: React.FC = () => {
    const [filterText, setFilterText] = useState("");

    return (
        <div>
            <Tabs
                id="scenario-tables"
                defaultSelectedTabId="assigned-upgrades"
                large
            >
                <Tab
                    id="assigned-upgrades"
                    title="Assigned upgrades"
                    panel={
                        <ScenarioAllocatedUpgradesTable
                            filterText={filterText}
                        />
                    }
                />
                <Tab
                    id="constraints"
                    title="Constraints"
                    panel={<ScenarioConstraintsTable filterText={filterText} />}
                />
                <Tab
                    id="projects"
                    title="Projects"
                    panel={<ScenarioProjectsTable filterText={filterText} />}
                />
                <Tabs.Expander />
                <InputGroup
                    className={css["search-input"]}
                    type="search"
                    leftIcon={IconNames.SEARCH}
                    placeholder="Type to filter..."
                    onChange={(event) =>
                        setFilterText(event.currentTarget.value)
                    }
                />
            </Tabs>
        </div>
    );
};

import { Buses_Bool_Exp } from "generated/graphql";
import { ScreeningView } from "types/screeningViewType";

export const maybeScreeningViewStatesFilter = (
    screeningViewData?: ScreeningView
): Buses_Bool_Exp => {
    return screeningViewData
        ? { state: { _in: screeningViewData.states } }
        : {};
};

export const maybeHideLockedBusesFilter = (
    hideLockedBuses: boolean
): Buses_Bool_Exp => {
    return {
        // If the user wants to hide locked buses, then we filter to buses that have an "unlocked"
        // attribute, as those are the ones that were unlocked by this user's team.
        ...(hideLockedBuses && {
            unlocked: {}
        })
    };
};

import { Auth0Provider } from "@auth0/auth0-react";
import getEnvVariables from "config/envVariables";
import { UserDataProvider } from "contexts/UserDataContext/UserDataContext";
import { ApolloClientProvider } from "infrastructure/ApolloClientProvider";
import { DataDogProvider } from "infrastructure/DataDogProvider";
import { LoadingApp } from "infrastructure/LoadingApp";
import React from "react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { RouterApp } from "./RouterApp";
import { SegmentProvider } from "./SegmentProvider";

export const App: React.FC = () => {
    return (
        <DataDogProvider>
            <BrowserRouter>
                <InnerApp />
            </BrowserRouter>
        </DataDogProvider>
    );
};

// We need this inner because we useNavigate can only be called inside a router
const InnerApp: React.FC = () => {
    const envVariables = getEnvVariables();

    const navigate = useNavigate();

    return (
        <Auth0Provider
            domain={envVariables.auth0Domain}
            clientId={envVariables.auth0ClientId}
            redirectUri={window.location.origin}
            audience={envVariables.auth0Audience}
            onRedirectCallback={(appState) => {
                navigate(appState?.returnTo || "/");
            }}
        >
            <SegmentProvider>
                <LoadingApp>
                    <ApolloClientProvider>
                        <UserDataProvider>
                            <RouterApp />
                        </UserDataProvider>
                    </ApolloClientProvider>
                </LoadingApp>
            </SegmentProvider>
        </Auth0Provider>
    );
};

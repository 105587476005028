import { Button, Intent, Position, Toaster } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useUserDataContext } from "contexts/UserDataContext/UserDataContext";
import { useSubmitScenarioMutation } from "generated/graphql";
import { segmentTrackSubmitScenarioRun } from "in_queue/analytics";
import { useCurrentScenarioMetadata } from "in_queue/contexts/ScenarioPageContext";
import { useScenarioCreationDisabled } from "in_queue/hooks/useScenarioCreationDisabled";
import { DateTime } from "luxon";
import { useState } from "react";
import { AllScenarioTooltips } from "../common/AllScenarioTooltips";

const SubmitScenarioRunButton: React.FC<{ scenarioId: string }> = ({
    scenarioId
}) => {
    const { auth0Id } = useUserDataContext();
    const [submitScenario] = useSubmitScenarioMutation();
    const [isLoading, setIsLoading] = useState(false);
    const scenario = useCurrentScenarioMetadata();

    const onClickSubmitRun = async () => {
        setIsLoading(true);

        try {
            await submitScenario({
                variables: {
                    scenarioId,
                    authId: auth0Id,
                    currentTime: DateTime.utc().toString()
                }
            });

            segmentTrackSubmitScenarioRun({ scenarioId });
            SubmitScenarioToaster.show({
                intent: Intent.SUCCESS,
                message: "Successfully submitted scenario."
            });

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            SubmitScenarioToaster.show({
                intent: Intent.DANGER,
                message:
                    "Encountered an error while submitting scenario run. Please try again."
            });
        }
    };
    return (
        <AllScenarioTooltips stage={scenario.stage}>
            <Button
                intent={Intent.PRIMARY}
                icon={IconNames.CONFIRM}
                text="Submit run"
                onClick={onClickSubmitRun}
                loading={isLoading}
                disabled={useScenarioCreationDisabled(scenario.stage)}
            />
        </AllScenarioTooltips>
    );
};

const SubmitScenarioToaster = Toaster.create({
    position: Position.TOP
});

export default SubmitScenarioRunButton;

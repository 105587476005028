import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  date: { input: string; output: string; }
  jsonb: { input: any; output: any; }
  numeric: { input: number; output: number; }
  path: { input: string; output: string; }
  timestamptz: { input: any; output: any; }
  uuid: { input: any; output: any; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** All queued projects in all regions, used as basis for users running queue studies */
export type All_Projects = {
  __typename?: 'all_projects';
  bus_number?: Maybe<Scalars['String']['output']>;
  eris_capacity_mw: Scalars['numeric']['output'];
  facility_id?: Maybe<Scalars['String']['output']>;
  fuel_type: Scalars['String']['output'];
  nris_capacity_mw: Scalars['numeric']['output'];
  project_id: Scalars['String']['output'];
  region: Scalars['String']['output'];
  status: Scalars['String']['output'];
  study_cycle: Scalars['String']['output'];
  study_group: Scalars['String']['output'];
  study_phase: Scalars['String']['output'];
};

/** aggregated selection of "all_projects" */
export type All_Projects_Aggregate = {
  __typename?: 'all_projects_aggregate';
  aggregate?: Maybe<All_Projects_Aggregate_Fields>;
  nodes: Array<All_Projects>;
};

/** aggregate fields of "all_projects" */
export type All_Projects_Aggregate_Fields = {
  __typename?: 'all_projects_aggregate_fields';
  avg?: Maybe<All_Projects_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<All_Projects_Max_Fields>;
  min?: Maybe<All_Projects_Min_Fields>;
  stddev?: Maybe<All_Projects_Stddev_Fields>;
  stddev_pop?: Maybe<All_Projects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<All_Projects_Stddev_Samp_Fields>;
  sum?: Maybe<All_Projects_Sum_Fields>;
  var_pop?: Maybe<All_Projects_Var_Pop_Fields>;
  var_samp?: Maybe<All_Projects_Var_Samp_Fields>;
  variance?: Maybe<All_Projects_Variance_Fields>;
};


/** aggregate fields of "all_projects" */
export type All_Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<All_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type All_Projects_Avg_Fields = {
  __typename?: 'all_projects_avg_fields';
  eris_capacity_mw?: Maybe<Scalars['Float']['output']>;
  nris_capacity_mw?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "all_projects". All fields are combined with a logical 'AND'. */
export type All_Projects_Bool_Exp = {
  _and?: InputMaybe<Array<All_Projects_Bool_Exp>>;
  _not?: InputMaybe<All_Projects_Bool_Exp>;
  _or?: InputMaybe<Array<All_Projects_Bool_Exp>>;
  bus_number?: InputMaybe<String_Comparison_Exp>;
  eris_capacity_mw?: InputMaybe<Numeric_Comparison_Exp>;
  facility_id?: InputMaybe<String_Comparison_Exp>;
  fuel_type?: InputMaybe<String_Comparison_Exp>;
  nris_capacity_mw?: InputMaybe<Numeric_Comparison_Exp>;
  project_id?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  study_cycle?: InputMaybe<String_Comparison_Exp>;
  study_group?: InputMaybe<String_Comparison_Exp>;
  study_phase?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "all_projects" */
export enum All_Projects_Constraint {
  /** unique or primary key constraint on columns "study_phase", "project_id", "region" */
  AllProjectsPkey = 'all_projects_pkey'
}

/** input type for incrementing numeric columns in table "all_projects" */
export type All_Projects_Inc_Input = {
  eris_capacity_mw?: InputMaybe<Scalars['numeric']['input']>;
  nris_capacity_mw?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "all_projects" */
export type All_Projects_Insert_Input = {
  bus_number?: InputMaybe<Scalars['String']['input']>;
  eris_capacity_mw?: InputMaybe<Scalars['numeric']['input']>;
  facility_id?: InputMaybe<Scalars['String']['input']>;
  fuel_type?: InputMaybe<Scalars['String']['input']>;
  nris_capacity_mw?: InputMaybe<Scalars['numeric']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  study_cycle?: InputMaybe<Scalars['String']['input']>;
  study_group?: InputMaybe<Scalars['String']['input']>;
  study_phase?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type All_Projects_Max_Fields = {
  __typename?: 'all_projects_max_fields';
  bus_number?: Maybe<Scalars['String']['output']>;
  eris_capacity_mw?: Maybe<Scalars['numeric']['output']>;
  facility_id?: Maybe<Scalars['String']['output']>;
  fuel_type?: Maybe<Scalars['String']['output']>;
  nris_capacity_mw?: Maybe<Scalars['numeric']['output']>;
  project_id?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  study_cycle?: Maybe<Scalars['String']['output']>;
  study_group?: Maybe<Scalars['String']['output']>;
  study_phase?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type All_Projects_Min_Fields = {
  __typename?: 'all_projects_min_fields';
  bus_number?: Maybe<Scalars['String']['output']>;
  eris_capacity_mw?: Maybe<Scalars['numeric']['output']>;
  facility_id?: Maybe<Scalars['String']['output']>;
  fuel_type?: Maybe<Scalars['String']['output']>;
  nris_capacity_mw?: Maybe<Scalars['numeric']['output']>;
  project_id?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  study_cycle?: Maybe<Scalars['String']['output']>;
  study_group?: Maybe<Scalars['String']['output']>;
  study_phase?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "all_projects" */
export type All_Projects_Mutation_Response = {
  __typename?: 'all_projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<All_Projects>;
};

/** on_conflict condition type for table "all_projects" */
export type All_Projects_On_Conflict = {
  constraint: All_Projects_Constraint;
  update_columns?: Array<All_Projects_Update_Column>;
  where?: InputMaybe<All_Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "all_projects". */
export type All_Projects_Order_By = {
  bus_number?: InputMaybe<Order_By>;
  eris_capacity_mw?: InputMaybe<Order_By>;
  facility_id?: InputMaybe<Order_By>;
  fuel_type?: InputMaybe<Order_By>;
  nris_capacity_mw?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  study_cycle?: InputMaybe<Order_By>;
  study_group?: InputMaybe<Order_By>;
  study_phase?: InputMaybe<Order_By>;
};

/** primary key columns input for table: all_projects */
export type All_Projects_Pk_Columns_Input = {
  project_id: Scalars['String']['input'];
  region: Scalars['String']['input'];
  study_phase: Scalars['String']['input'];
};

/** select columns of table "all_projects" */
export enum All_Projects_Select_Column {
  /** column name */
  BusNumber = 'bus_number',
  /** column name */
  ErisCapacityMw = 'eris_capacity_mw',
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  FuelType = 'fuel_type',
  /** column name */
  NrisCapacityMw = 'nris_capacity_mw',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Region = 'region',
  /** column name */
  Status = 'status',
  /** column name */
  StudyCycle = 'study_cycle',
  /** column name */
  StudyGroup = 'study_group',
  /** column name */
  StudyPhase = 'study_phase'
}

/** input type for updating data in table "all_projects" */
export type All_Projects_Set_Input = {
  bus_number?: InputMaybe<Scalars['String']['input']>;
  eris_capacity_mw?: InputMaybe<Scalars['numeric']['input']>;
  facility_id?: InputMaybe<Scalars['String']['input']>;
  fuel_type?: InputMaybe<Scalars['String']['input']>;
  nris_capacity_mw?: InputMaybe<Scalars['numeric']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  study_cycle?: InputMaybe<Scalars['String']['input']>;
  study_group?: InputMaybe<Scalars['String']['input']>;
  study_phase?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type All_Projects_Stddev_Fields = {
  __typename?: 'all_projects_stddev_fields';
  eris_capacity_mw?: Maybe<Scalars['Float']['output']>;
  nris_capacity_mw?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type All_Projects_Stddev_Pop_Fields = {
  __typename?: 'all_projects_stddev_pop_fields';
  eris_capacity_mw?: Maybe<Scalars['Float']['output']>;
  nris_capacity_mw?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type All_Projects_Stddev_Samp_Fields = {
  __typename?: 'all_projects_stddev_samp_fields';
  eris_capacity_mw?: Maybe<Scalars['Float']['output']>;
  nris_capacity_mw?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "all_projects" */
export type All_Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: All_Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type All_Projects_Stream_Cursor_Value_Input = {
  bus_number?: InputMaybe<Scalars['String']['input']>;
  eris_capacity_mw?: InputMaybe<Scalars['numeric']['input']>;
  facility_id?: InputMaybe<Scalars['String']['input']>;
  fuel_type?: InputMaybe<Scalars['String']['input']>;
  nris_capacity_mw?: InputMaybe<Scalars['numeric']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  study_cycle?: InputMaybe<Scalars['String']['input']>;
  study_group?: InputMaybe<Scalars['String']['input']>;
  study_phase?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type All_Projects_Sum_Fields = {
  __typename?: 'all_projects_sum_fields';
  eris_capacity_mw?: Maybe<Scalars['numeric']['output']>;
  nris_capacity_mw?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "all_projects" */
export enum All_Projects_Update_Column {
  /** column name */
  BusNumber = 'bus_number',
  /** column name */
  ErisCapacityMw = 'eris_capacity_mw',
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  FuelType = 'fuel_type',
  /** column name */
  NrisCapacityMw = 'nris_capacity_mw',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Region = 'region',
  /** column name */
  Status = 'status',
  /** column name */
  StudyCycle = 'study_cycle',
  /** column name */
  StudyGroup = 'study_group',
  /** column name */
  StudyPhase = 'study_phase'
}

export type All_Projects_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<All_Projects_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<All_Projects_Set_Input>;
  /** filter the rows which have to be updated */
  where: All_Projects_Bool_Exp;
};

/** aggregate var_pop on columns */
export type All_Projects_Var_Pop_Fields = {
  __typename?: 'all_projects_var_pop_fields';
  eris_capacity_mw?: Maybe<Scalars['Float']['output']>;
  nris_capacity_mw?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type All_Projects_Var_Samp_Fields = {
  __typename?: 'all_projects_var_samp_fields';
  eris_capacity_mw?: Maybe<Scalars['Float']['output']>;
  nris_capacity_mw?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type All_Projects_Variance_Fields = {
  __typename?: 'all_projects_variance_fields';
  eris_capacity_mw?: Maybe<Scalars['Float']['output']>;
  nris_capacity_mw?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "branches" */
export type Branches = {
  __typename?: 'branches';
  branch_path: Scalars['path']['output'];
  id: Scalars['String']['output'];
  latitude_substation_one: Scalars['numeric']['output'];
  latitude_substation_two: Scalars['numeric']['output'];
  longitude_substation_one: Scalars['numeric']['output'];
  longitude_substation_two: Scalars['numeric']['output'];
  regions: Scalars['jsonb']['output'];
  states: Scalars['jsonb']['output'];
  voltage: Scalars['numeric']['output'];
};


/** columns and relationships of "branches" */
export type BranchesRegionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "branches" */
export type BranchesStatesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "branches" */
export type Branches_Aggregate = {
  __typename?: 'branches_aggregate';
  aggregate?: Maybe<Branches_Aggregate_Fields>;
  nodes: Array<Branches>;
};

/** aggregate fields of "branches" */
export type Branches_Aggregate_Fields = {
  __typename?: 'branches_aggregate_fields';
  avg?: Maybe<Branches_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Branches_Max_Fields>;
  min?: Maybe<Branches_Min_Fields>;
  stddev?: Maybe<Branches_Stddev_Fields>;
  stddev_pop?: Maybe<Branches_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Branches_Stddev_Samp_Fields>;
  sum?: Maybe<Branches_Sum_Fields>;
  var_pop?: Maybe<Branches_Var_Pop_Fields>;
  var_samp?: Maybe<Branches_Var_Samp_Fields>;
  variance?: Maybe<Branches_Variance_Fields>;
};


/** aggregate fields of "branches" */
export type Branches_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Branches_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Branches_Append_Input = {
  regions?: InputMaybe<Scalars['jsonb']['input']>;
  states?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Branches_Avg_Fields = {
  __typename?: 'branches_avg_fields';
  latitude_substation_one?: Maybe<Scalars['Float']['output']>;
  latitude_substation_two?: Maybe<Scalars['Float']['output']>;
  longitude_substation_one?: Maybe<Scalars['Float']['output']>;
  longitude_substation_two?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "branches". All fields are combined with a logical 'AND'. */
export type Branches_Bool_Exp = {
  _and?: InputMaybe<Array<Branches_Bool_Exp>>;
  _not?: InputMaybe<Branches_Bool_Exp>;
  _or?: InputMaybe<Array<Branches_Bool_Exp>>;
  branch_path?: InputMaybe<Path_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  latitude_substation_one?: InputMaybe<Numeric_Comparison_Exp>;
  latitude_substation_two?: InputMaybe<Numeric_Comparison_Exp>;
  longitude_substation_one?: InputMaybe<Numeric_Comparison_Exp>;
  longitude_substation_two?: InputMaybe<Numeric_Comparison_Exp>;
  regions?: InputMaybe<Jsonb_Comparison_Exp>;
  states?: InputMaybe<Jsonb_Comparison_Exp>;
  voltage?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "branches" */
export enum Branches_Constraint {
  /** unique or primary key constraint on columns "id" */
  BranchesPkey = 'branches_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Branches_Delete_At_Path_Input = {
  regions?: InputMaybe<Array<Scalars['String']['input']>>;
  states?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Branches_Delete_Elem_Input = {
  regions?: InputMaybe<Scalars['Int']['input']>;
  states?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Branches_Delete_Key_Input = {
  regions?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "branches" */
export type Branches_Inc_Input = {
  latitude_substation_one?: InputMaybe<Scalars['numeric']['input']>;
  latitude_substation_two?: InputMaybe<Scalars['numeric']['input']>;
  longitude_substation_one?: InputMaybe<Scalars['numeric']['input']>;
  longitude_substation_two?: InputMaybe<Scalars['numeric']['input']>;
  voltage?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "branches" */
export type Branches_Insert_Input = {
  branch_path?: InputMaybe<Scalars['path']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude_substation_one?: InputMaybe<Scalars['numeric']['input']>;
  latitude_substation_two?: InputMaybe<Scalars['numeric']['input']>;
  longitude_substation_one?: InputMaybe<Scalars['numeric']['input']>;
  longitude_substation_two?: InputMaybe<Scalars['numeric']['input']>;
  regions?: InputMaybe<Scalars['jsonb']['input']>;
  states?: InputMaybe<Scalars['jsonb']['input']>;
  voltage?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Branches_Max_Fields = {
  __typename?: 'branches_max_fields';
  id?: Maybe<Scalars['String']['output']>;
  latitude_substation_one?: Maybe<Scalars['numeric']['output']>;
  latitude_substation_two?: Maybe<Scalars['numeric']['output']>;
  longitude_substation_one?: Maybe<Scalars['numeric']['output']>;
  longitude_substation_two?: Maybe<Scalars['numeric']['output']>;
  voltage?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type Branches_Min_Fields = {
  __typename?: 'branches_min_fields';
  id?: Maybe<Scalars['String']['output']>;
  latitude_substation_one?: Maybe<Scalars['numeric']['output']>;
  latitude_substation_two?: Maybe<Scalars['numeric']['output']>;
  longitude_substation_one?: Maybe<Scalars['numeric']['output']>;
  longitude_substation_two?: Maybe<Scalars['numeric']['output']>;
  voltage?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "branches" */
export type Branches_Mutation_Response = {
  __typename?: 'branches_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Branches>;
};

/** on_conflict condition type for table "branches" */
export type Branches_On_Conflict = {
  constraint: Branches_Constraint;
  update_columns?: Array<Branches_Update_Column>;
  where?: InputMaybe<Branches_Bool_Exp>;
};

/** Ordering options when selecting data from "branches". */
export type Branches_Order_By = {
  branch_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude_substation_one?: InputMaybe<Order_By>;
  latitude_substation_two?: InputMaybe<Order_By>;
  longitude_substation_one?: InputMaybe<Order_By>;
  longitude_substation_two?: InputMaybe<Order_By>;
  regions?: InputMaybe<Order_By>;
  states?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
};

/** primary key columns input for table: branches */
export type Branches_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Branches_Prepend_Input = {
  regions?: InputMaybe<Scalars['jsonb']['input']>;
  states?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "branches" */
export enum Branches_Select_Column {
  /** column name */
  BranchPath = 'branch_path',
  /** column name */
  Id = 'id',
  /** column name */
  LatitudeSubstationOne = 'latitude_substation_one',
  /** column name */
  LatitudeSubstationTwo = 'latitude_substation_two',
  /** column name */
  LongitudeSubstationOne = 'longitude_substation_one',
  /** column name */
  LongitudeSubstationTwo = 'longitude_substation_two',
  /** column name */
  Regions = 'regions',
  /** column name */
  States = 'states',
  /** column name */
  Voltage = 'voltage'
}

/** input type for updating data in table "branches" */
export type Branches_Set_Input = {
  branch_path?: InputMaybe<Scalars['path']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude_substation_one?: InputMaybe<Scalars['numeric']['input']>;
  latitude_substation_two?: InputMaybe<Scalars['numeric']['input']>;
  longitude_substation_one?: InputMaybe<Scalars['numeric']['input']>;
  longitude_substation_two?: InputMaybe<Scalars['numeric']['input']>;
  regions?: InputMaybe<Scalars['jsonb']['input']>;
  states?: InputMaybe<Scalars['jsonb']['input']>;
  voltage?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Branches_Stddev_Fields = {
  __typename?: 'branches_stddev_fields';
  latitude_substation_one?: Maybe<Scalars['Float']['output']>;
  latitude_substation_two?: Maybe<Scalars['Float']['output']>;
  longitude_substation_one?: Maybe<Scalars['Float']['output']>;
  longitude_substation_two?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Branches_Stddev_Pop_Fields = {
  __typename?: 'branches_stddev_pop_fields';
  latitude_substation_one?: Maybe<Scalars['Float']['output']>;
  latitude_substation_two?: Maybe<Scalars['Float']['output']>;
  longitude_substation_one?: Maybe<Scalars['Float']['output']>;
  longitude_substation_two?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Branches_Stddev_Samp_Fields = {
  __typename?: 'branches_stddev_samp_fields';
  latitude_substation_one?: Maybe<Scalars['Float']['output']>;
  latitude_substation_two?: Maybe<Scalars['Float']['output']>;
  longitude_substation_one?: Maybe<Scalars['Float']['output']>;
  longitude_substation_two?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "branches" */
export type Branches_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Branches_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Branches_Stream_Cursor_Value_Input = {
  branch_path?: InputMaybe<Scalars['path']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude_substation_one?: InputMaybe<Scalars['numeric']['input']>;
  latitude_substation_two?: InputMaybe<Scalars['numeric']['input']>;
  longitude_substation_one?: InputMaybe<Scalars['numeric']['input']>;
  longitude_substation_two?: InputMaybe<Scalars['numeric']['input']>;
  regions?: InputMaybe<Scalars['jsonb']['input']>;
  states?: InputMaybe<Scalars['jsonb']['input']>;
  voltage?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Branches_Sum_Fields = {
  __typename?: 'branches_sum_fields';
  latitude_substation_one?: Maybe<Scalars['numeric']['output']>;
  latitude_substation_two?: Maybe<Scalars['numeric']['output']>;
  longitude_substation_one?: Maybe<Scalars['numeric']['output']>;
  longitude_substation_two?: Maybe<Scalars['numeric']['output']>;
  voltage?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "branches" */
export enum Branches_Update_Column {
  /** column name */
  BranchPath = 'branch_path',
  /** column name */
  Id = 'id',
  /** column name */
  LatitudeSubstationOne = 'latitude_substation_one',
  /** column name */
  LatitudeSubstationTwo = 'latitude_substation_two',
  /** column name */
  LongitudeSubstationOne = 'longitude_substation_one',
  /** column name */
  LongitudeSubstationTwo = 'longitude_substation_two',
  /** column name */
  Regions = 'regions',
  /** column name */
  States = 'states',
  /** column name */
  Voltage = 'voltage'
}

export type Branches_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Branches_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Branches_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Branches_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Branches_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Branches_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Branches_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Branches_Set_Input>;
  /** filter the rows which have to be updated */
  where: Branches_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Branches_Var_Pop_Fields = {
  __typename?: 'branches_var_pop_fields';
  latitude_substation_one?: Maybe<Scalars['Float']['output']>;
  latitude_substation_two?: Maybe<Scalars['Float']['output']>;
  longitude_substation_one?: Maybe<Scalars['Float']['output']>;
  longitude_substation_two?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Branches_Var_Samp_Fields = {
  __typename?: 'branches_var_samp_fields';
  latitude_substation_one?: Maybe<Scalars['Float']['output']>;
  latitude_substation_two?: Maybe<Scalars['Float']['output']>;
  longitude_substation_one?: Maybe<Scalars['Float']['output']>;
  longitude_substation_two?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Branches_Variance_Fields = {
  __typename?: 'branches_variance_fields';
  latitude_substation_one?: Maybe<Scalars['Float']['output']>;
  latitude_substation_two?: Maybe<Scalars['Float']['output']>;
  longitude_substation_one?: Maybe<Scalars['Float']['output']>;
  longitude_substation_two?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "buses" */
export type Buses = {
  __typename?: 'buses';
  bus_display_name: Scalars['String']['output'];
  /** An array relationship */
  capacity_costs: Array<Capacity_Costs>;
  /** An aggregate relationship */
  capacity_costs_aggregate: Capacity_Costs_Aggregate;
  /** An array relationship */
  constraint_stacks: Array<Constraint_Stacks>;
  /** An aggregate relationship */
  constraint_stacks_aggregate: Constraint_Stacks_Aggregate;
  id: Scalars['String']['output'];
  latitude: Scalars['numeric']['output'];
  location_code: Scalars['Int']['output'];
  longitude: Scalars['numeric']['output'];
  region: Scalars['String']['output'];
  /** An array relationship */
  region_subscriptions: Array<Subscriptions>;
  /** An aggregate relationship */
  region_subscriptions_aggregate: Subscriptions_Aggregate;
  scopes: Scalars['jsonb']['output'];
  state: Scalars['String']['output'];
  /** An object relationship */
  substation: Substations;
  substation_id: Scalars['String']['output'];
  /** An array relationship */
  unlocked: Array<Unlocked_Buses>;
  /** An aggregate relationship */
  unlocked_aggregate: Unlocked_Buses_Aggregate;
  voltage: Scalars['numeric']['output'];
};


/** columns and relationships of "buses" */
export type BusesCapacity_CostsArgs = {
  distinct_on?: InputMaybe<Array<Capacity_Costs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Capacity_Costs_Order_By>>;
  where?: InputMaybe<Capacity_Costs_Bool_Exp>;
};


/** columns and relationships of "buses" */
export type BusesCapacity_Costs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Capacity_Costs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Capacity_Costs_Order_By>>;
  where?: InputMaybe<Capacity_Costs_Bool_Exp>;
};


/** columns and relationships of "buses" */
export type BusesConstraint_StacksArgs = {
  distinct_on?: InputMaybe<Array<Constraint_Stacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Constraint_Stacks_Order_By>>;
  where?: InputMaybe<Constraint_Stacks_Bool_Exp>;
};


/** columns and relationships of "buses" */
export type BusesConstraint_Stacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Constraint_Stacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Constraint_Stacks_Order_By>>;
  where?: InputMaybe<Constraint_Stacks_Bool_Exp>;
};


/** columns and relationships of "buses" */
export type BusesRegion_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


/** columns and relationships of "buses" */
export type BusesRegion_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


/** columns and relationships of "buses" */
export type BusesScopesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "buses" */
export type BusesUnlockedArgs = {
  distinct_on?: InputMaybe<Array<Unlocked_Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Unlocked_Buses_Order_By>>;
  where?: InputMaybe<Unlocked_Buses_Bool_Exp>;
};


/** columns and relationships of "buses" */
export type BusesUnlocked_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unlocked_Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Unlocked_Buses_Order_By>>;
  where?: InputMaybe<Unlocked_Buses_Bool_Exp>;
};

/** aggregated selection of "buses" */
export type Buses_Aggregate = {
  __typename?: 'buses_aggregate';
  aggregate?: Maybe<Buses_Aggregate_Fields>;
  nodes: Array<Buses>;
};

export type Buses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Buses_Aggregate_Bool_Exp_Count>;
};

export type Buses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Buses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Buses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "buses" */
export type Buses_Aggregate_Fields = {
  __typename?: 'buses_aggregate_fields';
  avg?: Maybe<Buses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Buses_Max_Fields>;
  min?: Maybe<Buses_Min_Fields>;
  stddev?: Maybe<Buses_Stddev_Fields>;
  stddev_pop?: Maybe<Buses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Buses_Stddev_Samp_Fields>;
  sum?: Maybe<Buses_Sum_Fields>;
  var_pop?: Maybe<Buses_Var_Pop_Fields>;
  var_samp?: Maybe<Buses_Var_Samp_Fields>;
  variance?: Maybe<Buses_Variance_Fields>;
};


/** aggregate fields of "buses" */
export type Buses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Buses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "buses" */
export type Buses_Aggregate_Order_By = {
  avg?: InputMaybe<Buses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Buses_Max_Order_By>;
  min?: InputMaybe<Buses_Min_Order_By>;
  stddev?: InputMaybe<Buses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Buses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Buses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Buses_Sum_Order_By>;
  var_pop?: InputMaybe<Buses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Buses_Var_Samp_Order_By>;
  variance?: InputMaybe<Buses_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Buses_Append_Input = {
  scopes?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "buses" */
export type Buses_Arr_Rel_Insert_Input = {
  data: Array<Buses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Buses_On_Conflict>;
};

/** aggregate avg on columns */
export type Buses_Avg_Fields = {
  __typename?: 'buses_avg_fields';
  latitude?: Maybe<Scalars['Float']['output']>;
  location_code?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "buses" */
export type Buses_Avg_Order_By = {
  latitude?: InputMaybe<Order_By>;
  location_code?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "buses". All fields are combined with a logical 'AND'. */
export type Buses_Bool_Exp = {
  _and?: InputMaybe<Array<Buses_Bool_Exp>>;
  _not?: InputMaybe<Buses_Bool_Exp>;
  _or?: InputMaybe<Array<Buses_Bool_Exp>>;
  bus_display_name?: InputMaybe<String_Comparison_Exp>;
  capacity_costs?: InputMaybe<Capacity_Costs_Bool_Exp>;
  capacity_costs_aggregate?: InputMaybe<Capacity_Costs_Aggregate_Bool_Exp>;
  constraint_stacks?: InputMaybe<Constraint_Stacks_Bool_Exp>;
  constraint_stacks_aggregate?: InputMaybe<Constraint_Stacks_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  latitude?: InputMaybe<Numeric_Comparison_Exp>;
  location_code?: InputMaybe<Int_Comparison_Exp>;
  longitude?: InputMaybe<Numeric_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  region_subscriptions?: InputMaybe<Subscriptions_Bool_Exp>;
  region_subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Bool_Exp>;
  scopes?: InputMaybe<Jsonb_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  substation?: InputMaybe<Substations_Bool_Exp>;
  substation_id?: InputMaybe<String_Comparison_Exp>;
  unlocked?: InputMaybe<Unlocked_Buses_Bool_Exp>;
  unlocked_aggregate?: InputMaybe<Unlocked_Buses_Aggregate_Bool_Exp>;
  voltage?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "buses" */
export enum Buses_Constraint {
  /** unique or primary key constraint on columns "id" */
  BusesPkey = 'buses_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Buses_Delete_At_Path_Input = {
  scopes?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Buses_Delete_Elem_Input = {
  scopes?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Buses_Delete_Key_Input = {
  scopes?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "buses" */
export type Buses_Inc_Input = {
  latitude?: InputMaybe<Scalars['numeric']['input']>;
  location_code?: InputMaybe<Scalars['Int']['input']>;
  longitude?: InputMaybe<Scalars['numeric']['input']>;
  voltage?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "buses" */
export type Buses_Insert_Input = {
  bus_display_name?: InputMaybe<Scalars['String']['input']>;
  capacity_costs?: InputMaybe<Capacity_Costs_Arr_Rel_Insert_Input>;
  constraint_stacks?: InputMaybe<Constraint_Stacks_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['numeric']['input']>;
  location_code?: InputMaybe<Scalars['Int']['input']>;
  longitude?: InputMaybe<Scalars['numeric']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  region_subscriptions?: InputMaybe<Subscriptions_Arr_Rel_Insert_Input>;
  scopes?: InputMaybe<Scalars['jsonb']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  substation?: InputMaybe<Substations_Obj_Rel_Insert_Input>;
  substation_id?: InputMaybe<Scalars['String']['input']>;
  unlocked?: InputMaybe<Unlocked_Buses_Arr_Rel_Insert_Input>;
  voltage?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Buses_Max_Fields = {
  __typename?: 'buses_max_fields';
  bus_display_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['numeric']['output']>;
  location_code?: Maybe<Scalars['Int']['output']>;
  longitude?: Maybe<Scalars['numeric']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  substation_id?: Maybe<Scalars['String']['output']>;
  voltage?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "buses" */
export type Buses_Max_Order_By = {
  bus_display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  location_code?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  substation_id?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Buses_Min_Fields = {
  __typename?: 'buses_min_fields';
  bus_display_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['numeric']['output']>;
  location_code?: Maybe<Scalars['Int']['output']>;
  longitude?: Maybe<Scalars['numeric']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  substation_id?: Maybe<Scalars['String']['output']>;
  voltage?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "buses" */
export type Buses_Min_Order_By = {
  bus_display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  location_code?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  substation_id?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "buses" */
export type Buses_Mutation_Response = {
  __typename?: 'buses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Buses>;
};

/** input type for inserting object relation for remote table "buses" */
export type Buses_Obj_Rel_Insert_Input = {
  data: Buses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Buses_On_Conflict>;
};

/** on_conflict condition type for table "buses" */
export type Buses_On_Conflict = {
  constraint: Buses_Constraint;
  update_columns?: Array<Buses_Update_Column>;
  where?: InputMaybe<Buses_Bool_Exp>;
};

/** Ordering options when selecting data from "buses". */
export type Buses_Order_By = {
  bus_display_name?: InputMaybe<Order_By>;
  capacity_costs_aggregate?: InputMaybe<Capacity_Costs_Aggregate_Order_By>;
  constraint_stacks_aggregate?: InputMaybe<Constraint_Stacks_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  location_code?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  region_subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Order_By>;
  scopes?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  substation?: InputMaybe<Substations_Order_By>;
  substation_id?: InputMaybe<Order_By>;
  unlocked_aggregate?: InputMaybe<Unlocked_Buses_Aggregate_Order_By>;
  voltage?: InputMaybe<Order_By>;
};

/** primary key columns input for table: buses */
export type Buses_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Buses_Prepend_Input = {
  scopes?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "buses" */
export enum Buses_Select_Column {
  /** column name */
  BusDisplayName = 'bus_display_name',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  LocationCode = 'location_code',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Region = 'region',
  /** column name */
  Scopes = 'scopes',
  /** column name */
  State = 'state',
  /** column name */
  SubstationId = 'substation_id',
  /** column name */
  Voltage = 'voltage'
}

/** input type for updating data in table "buses" */
export type Buses_Set_Input = {
  bus_display_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['numeric']['input']>;
  location_code?: InputMaybe<Scalars['Int']['input']>;
  longitude?: InputMaybe<Scalars['numeric']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  scopes?: InputMaybe<Scalars['jsonb']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  substation_id?: InputMaybe<Scalars['String']['input']>;
  voltage?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Buses_Stddev_Fields = {
  __typename?: 'buses_stddev_fields';
  latitude?: Maybe<Scalars['Float']['output']>;
  location_code?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "buses" */
export type Buses_Stddev_Order_By = {
  latitude?: InputMaybe<Order_By>;
  location_code?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Buses_Stddev_Pop_Fields = {
  __typename?: 'buses_stddev_pop_fields';
  latitude?: Maybe<Scalars['Float']['output']>;
  location_code?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "buses" */
export type Buses_Stddev_Pop_Order_By = {
  latitude?: InputMaybe<Order_By>;
  location_code?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Buses_Stddev_Samp_Fields = {
  __typename?: 'buses_stddev_samp_fields';
  latitude?: Maybe<Scalars['Float']['output']>;
  location_code?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "buses" */
export type Buses_Stddev_Samp_Order_By = {
  latitude?: InputMaybe<Order_By>;
  location_code?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "buses" */
export type Buses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Buses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Buses_Stream_Cursor_Value_Input = {
  bus_display_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['numeric']['input']>;
  location_code?: InputMaybe<Scalars['Int']['input']>;
  longitude?: InputMaybe<Scalars['numeric']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  scopes?: InputMaybe<Scalars['jsonb']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  substation_id?: InputMaybe<Scalars['String']['input']>;
  voltage?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Buses_Sum_Fields = {
  __typename?: 'buses_sum_fields';
  latitude?: Maybe<Scalars['numeric']['output']>;
  location_code?: Maybe<Scalars['Int']['output']>;
  longitude?: Maybe<Scalars['numeric']['output']>;
  voltage?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "buses" */
export type Buses_Sum_Order_By = {
  latitude?: InputMaybe<Order_By>;
  location_code?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
};

/** update columns of table "buses" */
export enum Buses_Update_Column {
  /** column name */
  BusDisplayName = 'bus_display_name',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  LocationCode = 'location_code',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Region = 'region',
  /** column name */
  Scopes = 'scopes',
  /** column name */
  State = 'state',
  /** column name */
  SubstationId = 'substation_id',
  /** column name */
  Voltage = 'voltage'
}

export type Buses_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Buses_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Buses_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Buses_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Buses_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Buses_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Buses_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Buses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Buses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Buses_Var_Pop_Fields = {
  __typename?: 'buses_var_pop_fields';
  latitude?: Maybe<Scalars['Float']['output']>;
  location_code?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "buses" */
export type Buses_Var_Pop_Order_By = {
  latitude?: InputMaybe<Order_By>;
  location_code?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Buses_Var_Samp_Fields = {
  __typename?: 'buses_var_samp_fields';
  latitude?: Maybe<Scalars['Float']['output']>;
  location_code?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "buses" */
export type Buses_Var_Samp_Order_By = {
  latitude?: InputMaybe<Order_By>;
  location_code?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Buses_Variance_Fields = {
  __typename?: 'buses_variance_fields';
  latitude?: Maybe<Scalars['Float']['output']>;
  location_code?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "buses" */
export type Buses_Variance_Order_By = {
  latitude?: InputMaybe<Order_By>;
  location_code?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
};

/** columns and relationships of "capacity_costs" */
export type Capacity_Costs = {
  __typename?: 'capacity_costs';
  /** An object relationship */
  bus: Buses;
  bus_id: Scalars['String']['output'];
  capacity_size: Scalars['numeric']['output'];
  charging_size: Scalars['numeric']['output'];
  energy_size: Scalars['numeric']['output'];
  includes_pre_existing: Scalars['Boolean']['output'];
  scope: Scalars['String']['output'];
  scope_view: Scalars['String']['output'];
  total_upgrade_costs: Scalars['numeric']['output'];
  upgrade_costs: Scalars['numeric']['output'];
};

/** aggregated selection of "capacity_costs" */
export type Capacity_Costs_Aggregate = {
  __typename?: 'capacity_costs_aggregate';
  aggregate?: Maybe<Capacity_Costs_Aggregate_Fields>;
  nodes: Array<Capacity_Costs>;
};

export type Capacity_Costs_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Capacity_Costs_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Capacity_Costs_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Capacity_Costs_Aggregate_Bool_Exp_Count>;
};

export type Capacity_Costs_Aggregate_Bool_Exp_Bool_And = {
  arguments: Capacity_Costs_Select_Column_Capacity_Costs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Capacity_Costs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Capacity_Costs_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Capacity_Costs_Select_Column_Capacity_Costs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Capacity_Costs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Capacity_Costs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Capacity_Costs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Capacity_Costs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "capacity_costs" */
export type Capacity_Costs_Aggregate_Fields = {
  __typename?: 'capacity_costs_aggregate_fields';
  avg?: Maybe<Capacity_Costs_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Capacity_Costs_Max_Fields>;
  min?: Maybe<Capacity_Costs_Min_Fields>;
  stddev?: Maybe<Capacity_Costs_Stddev_Fields>;
  stddev_pop?: Maybe<Capacity_Costs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Capacity_Costs_Stddev_Samp_Fields>;
  sum?: Maybe<Capacity_Costs_Sum_Fields>;
  var_pop?: Maybe<Capacity_Costs_Var_Pop_Fields>;
  var_samp?: Maybe<Capacity_Costs_Var_Samp_Fields>;
  variance?: Maybe<Capacity_Costs_Variance_Fields>;
};


/** aggregate fields of "capacity_costs" */
export type Capacity_Costs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Capacity_Costs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "capacity_costs" */
export type Capacity_Costs_Aggregate_Order_By = {
  avg?: InputMaybe<Capacity_Costs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Capacity_Costs_Max_Order_By>;
  min?: InputMaybe<Capacity_Costs_Min_Order_By>;
  stddev?: InputMaybe<Capacity_Costs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Capacity_Costs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Capacity_Costs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Capacity_Costs_Sum_Order_By>;
  var_pop?: InputMaybe<Capacity_Costs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Capacity_Costs_Var_Samp_Order_By>;
  variance?: InputMaybe<Capacity_Costs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "capacity_costs" */
export type Capacity_Costs_Arr_Rel_Insert_Input = {
  data: Array<Capacity_Costs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Capacity_Costs_On_Conflict>;
};

/** aggregate avg on columns */
export type Capacity_Costs_Avg_Fields = {
  __typename?: 'capacity_costs_avg_fields';
  capacity_size?: Maybe<Scalars['Float']['output']>;
  charging_size?: Maybe<Scalars['Float']['output']>;
  energy_size?: Maybe<Scalars['Float']['output']>;
  total_upgrade_costs?: Maybe<Scalars['Float']['output']>;
  upgrade_costs?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "capacity_costs" */
export type Capacity_Costs_Avg_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "capacity_costs". All fields are combined with a logical 'AND'. */
export type Capacity_Costs_Bool_Exp = {
  _and?: InputMaybe<Array<Capacity_Costs_Bool_Exp>>;
  _not?: InputMaybe<Capacity_Costs_Bool_Exp>;
  _or?: InputMaybe<Array<Capacity_Costs_Bool_Exp>>;
  bus?: InputMaybe<Buses_Bool_Exp>;
  bus_id?: InputMaybe<String_Comparison_Exp>;
  capacity_size?: InputMaybe<Numeric_Comparison_Exp>;
  charging_size?: InputMaybe<Numeric_Comparison_Exp>;
  energy_size?: InputMaybe<Numeric_Comparison_Exp>;
  includes_pre_existing?: InputMaybe<Boolean_Comparison_Exp>;
  scope?: InputMaybe<String_Comparison_Exp>;
  scope_view?: InputMaybe<String_Comparison_Exp>;
  total_upgrade_costs?: InputMaybe<Numeric_Comparison_Exp>;
  upgrade_costs?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "capacity_costs" */
export enum Capacity_Costs_Constraint {
  /** unique or primary key constraint on columns "scope", "energy_size", "includes_pre_existing", "scope_view", "bus_id" */
  CapacityCostsPkey = 'capacity_costs_pkey'
}

/** input type for incrementing numeric columns in table "capacity_costs" */
export type Capacity_Costs_Inc_Input = {
  capacity_size?: InputMaybe<Scalars['numeric']['input']>;
  charging_size?: InputMaybe<Scalars['numeric']['input']>;
  energy_size?: InputMaybe<Scalars['numeric']['input']>;
  total_upgrade_costs?: InputMaybe<Scalars['numeric']['input']>;
  upgrade_costs?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "capacity_costs" */
export type Capacity_Costs_Insert_Input = {
  bus?: InputMaybe<Buses_Obj_Rel_Insert_Input>;
  bus_id?: InputMaybe<Scalars['String']['input']>;
  capacity_size?: InputMaybe<Scalars['numeric']['input']>;
  charging_size?: InputMaybe<Scalars['numeric']['input']>;
  energy_size?: InputMaybe<Scalars['numeric']['input']>;
  includes_pre_existing?: InputMaybe<Scalars['Boolean']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  total_upgrade_costs?: InputMaybe<Scalars['numeric']['input']>;
  upgrade_costs?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Capacity_Costs_Max_Fields = {
  __typename?: 'capacity_costs_max_fields';
  bus_id?: Maybe<Scalars['String']['output']>;
  capacity_size?: Maybe<Scalars['numeric']['output']>;
  charging_size?: Maybe<Scalars['numeric']['output']>;
  energy_size?: Maybe<Scalars['numeric']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  scope_view?: Maybe<Scalars['String']['output']>;
  total_upgrade_costs?: Maybe<Scalars['numeric']['output']>;
  upgrade_costs?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "capacity_costs" */
export type Capacity_Costs_Max_Order_By = {
  bus_id?: InputMaybe<Order_By>;
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  scope_view?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Capacity_Costs_Min_Fields = {
  __typename?: 'capacity_costs_min_fields';
  bus_id?: Maybe<Scalars['String']['output']>;
  capacity_size?: Maybe<Scalars['numeric']['output']>;
  charging_size?: Maybe<Scalars['numeric']['output']>;
  energy_size?: Maybe<Scalars['numeric']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  scope_view?: Maybe<Scalars['String']['output']>;
  total_upgrade_costs?: Maybe<Scalars['numeric']['output']>;
  upgrade_costs?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "capacity_costs" */
export type Capacity_Costs_Min_Order_By = {
  bus_id?: InputMaybe<Order_By>;
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  scope_view?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "capacity_costs" */
export type Capacity_Costs_Mutation_Response = {
  __typename?: 'capacity_costs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Capacity_Costs>;
};

/** on_conflict condition type for table "capacity_costs" */
export type Capacity_Costs_On_Conflict = {
  constraint: Capacity_Costs_Constraint;
  update_columns?: Array<Capacity_Costs_Update_Column>;
  where?: InputMaybe<Capacity_Costs_Bool_Exp>;
};

/** Ordering options when selecting data from "capacity_costs". */
export type Capacity_Costs_Order_By = {
  bus?: InputMaybe<Buses_Order_By>;
  bus_id?: InputMaybe<Order_By>;
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  includes_pre_existing?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  scope_view?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** primary key columns input for table: capacity_costs */
export type Capacity_Costs_Pk_Columns_Input = {
  bus_id: Scalars['String']['input'];
  energy_size: Scalars['numeric']['input'];
  includes_pre_existing: Scalars['Boolean']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
};

/** select columns of table "capacity_costs" */
export enum Capacity_Costs_Select_Column {
  /** column name */
  BusId = 'bus_id',
  /** column name */
  CapacitySize = 'capacity_size',
  /** column name */
  ChargingSize = 'charging_size',
  /** column name */
  EnergySize = 'energy_size',
  /** column name */
  IncludesPreExisting = 'includes_pre_existing',
  /** column name */
  Scope = 'scope',
  /** column name */
  ScopeView = 'scope_view',
  /** column name */
  TotalUpgradeCosts = 'total_upgrade_costs',
  /** column name */
  UpgradeCosts = 'upgrade_costs'
}

/** select "capacity_costs_aggregate_bool_exp_bool_and_arguments_columns" columns of table "capacity_costs" */
export enum Capacity_Costs_Select_Column_Capacity_Costs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IncludesPreExisting = 'includes_pre_existing'
}

/** select "capacity_costs_aggregate_bool_exp_bool_or_arguments_columns" columns of table "capacity_costs" */
export enum Capacity_Costs_Select_Column_Capacity_Costs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IncludesPreExisting = 'includes_pre_existing'
}

/** input type for updating data in table "capacity_costs" */
export type Capacity_Costs_Set_Input = {
  bus_id?: InputMaybe<Scalars['String']['input']>;
  capacity_size?: InputMaybe<Scalars['numeric']['input']>;
  charging_size?: InputMaybe<Scalars['numeric']['input']>;
  energy_size?: InputMaybe<Scalars['numeric']['input']>;
  includes_pre_existing?: InputMaybe<Scalars['Boolean']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  total_upgrade_costs?: InputMaybe<Scalars['numeric']['input']>;
  upgrade_costs?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Capacity_Costs_Stddev_Fields = {
  __typename?: 'capacity_costs_stddev_fields';
  capacity_size?: Maybe<Scalars['Float']['output']>;
  charging_size?: Maybe<Scalars['Float']['output']>;
  energy_size?: Maybe<Scalars['Float']['output']>;
  total_upgrade_costs?: Maybe<Scalars['Float']['output']>;
  upgrade_costs?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "capacity_costs" */
export type Capacity_Costs_Stddev_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Capacity_Costs_Stddev_Pop_Fields = {
  __typename?: 'capacity_costs_stddev_pop_fields';
  capacity_size?: Maybe<Scalars['Float']['output']>;
  charging_size?: Maybe<Scalars['Float']['output']>;
  energy_size?: Maybe<Scalars['Float']['output']>;
  total_upgrade_costs?: Maybe<Scalars['Float']['output']>;
  upgrade_costs?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "capacity_costs" */
export type Capacity_Costs_Stddev_Pop_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Capacity_Costs_Stddev_Samp_Fields = {
  __typename?: 'capacity_costs_stddev_samp_fields';
  capacity_size?: Maybe<Scalars['Float']['output']>;
  charging_size?: Maybe<Scalars['Float']['output']>;
  energy_size?: Maybe<Scalars['Float']['output']>;
  total_upgrade_costs?: Maybe<Scalars['Float']['output']>;
  upgrade_costs?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "capacity_costs" */
export type Capacity_Costs_Stddev_Samp_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "capacity_costs" */
export type Capacity_Costs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Capacity_Costs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Capacity_Costs_Stream_Cursor_Value_Input = {
  bus_id?: InputMaybe<Scalars['String']['input']>;
  capacity_size?: InputMaybe<Scalars['numeric']['input']>;
  charging_size?: InputMaybe<Scalars['numeric']['input']>;
  energy_size?: InputMaybe<Scalars['numeric']['input']>;
  includes_pre_existing?: InputMaybe<Scalars['Boolean']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  total_upgrade_costs?: InputMaybe<Scalars['numeric']['input']>;
  upgrade_costs?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Capacity_Costs_Sum_Fields = {
  __typename?: 'capacity_costs_sum_fields';
  capacity_size?: Maybe<Scalars['numeric']['output']>;
  charging_size?: Maybe<Scalars['numeric']['output']>;
  energy_size?: Maybe<Scalars['numeric']['output']>;
  total_upgrade_costs?: Maybe<Scalars['numeric']['output']>;
  upgrade_costs?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "capacity_costs" */
export type Capacity_Costs_Sum_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** update columns of table "capacity_costs" */
export enum Capacity_Costs_Update_Column {
  /** column name */
  BusId = 'bus_id',
  /** column name */
  CapacitySize = 'capacity_size',
  /** column name */
  ChargingSize = 'charging_size',
  /** column name */
  EnergySize = 'energy_size',
  /** column name */
  IncludesPreExisting = 'includes_pre_existing',
  /** column name */
  Scope = 'scope',
  /** column name */
  ScopeView = 'scope_view',
  /** column name */
  TotalUpgradeCosts = 'total_upgrade_costs',
  /** column name */
  UpgradeCosts = 'upgrade_costs'
}

export type Capacity_Costs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Capacity_Costs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Capacity_Costs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Capacity_Costs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Capacity_Costs_Var_Pop_Fields = {
  __typename?: 'capacity_costs_var_pop_fields';
  capacity_size?: Maybe<Scalars['Float']['output']>;
  charging_size?: Maybe<Scalars['Float']['output']>;
  energy_size?: Maybe<Scalars['Float']['output']>;
  total_upgrade_costs?: Maybe<Scalars['Float']['output']>;
  upgrade_costs?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "capacity_costs" */
export type Capacity_Costs_Var_Pop_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Capacity_Costs_Var_Samp_Fields = {
  __typename?: 'capacity_costs_var_samp_fields';
  capacity_size?: Maybe<Scalars['Float']['output']>;
  charging_size?: Maybe<Scalars['Float']['output']>;
  energy_size?: Maybe<Scalars['Float']['output']>;
  total_upgrade_costs?: Maybe<Scalars['Float']['output']>;
  upgrade_costs?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "capacity_costs" */
export type Capacity_Costs_Var_Samp_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Capacity_Costs_Variance_Fields = {
  __typename?: 'capacity_costs_variance_fields';
  capacity_size?: Maybe<Scalars['Float']['output']>;
  charging_size?: Maybe<Scalars['Float']['output']>;
  energy_size?: Maybe<Scalars['Float']['output']>;
  total_upgrade_costs?: Maybe<Scalars['Float']['output']>;
  upgrade_costs?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "capacity_costs" */
export type Capacity_Costs_Variance_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** columns and relationships of "constraint_stacks" */
export type Constraint_Stacks = {
  __typename?: 'constraint_stacks';
  /** An object relationship */
  bus: Buses;
  bus_id: Scalars['String']['output'];
  contingency: Scalars['String']['output'];
  dfax: Scalars['numeric']['output'];
  element_type: Scalars['String']['output'];
  estimated_cost?: Maybe<Scalars['numeric']['output']>;
  from_bus_kv_nominal?: Maybe<Scalars['numeric']['output']>;
  injection_threshold: Scalars['numeric']['output'];
  is_pre_existing: Scalars['Boolean']['output'];
  line_length?: Maybe<Scalars['numeric']['output']>;
  monitored_facility_id: Scalars['String']['output'];
  monitored_facility_name: Scalars['String']['output'];
  pre_study_load: Scalars['numeric']['output'];
  rating: Scalars['numeric']['output'];
  resource_type: Scalars['String']['output'];
  scope: Scalars['String']['output'];
  scope_view: Scalars['String']['output'];
  to_bus_kv_nominal?: Maybe<Scalars['numeric']['output']>;
};

/** aggregated selection of "constraint_stacks" */
export type Constraint_Stacks_Aggregate = {
  __typename?: 'constraint_stacks_aggregate';
  aggregate?: Maybe<Constraint_Stacks_Aggregate_Fields>;
  nodes: Array<Constraint_Stacks>;
};

export type Constraint_Stacks_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Constraint_Stacks_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Constraint_Stacks_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Constraint_Stacks_Aggregate_Bool_Exp_Count>;
};

export type Constraint_Stacks_Aggregate_Bool_Exp_Bool_And = {
  arguments: Constraint_Stacks_Select_Column_Constraint_Stacks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Constraint_Stacks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Constraint_Stacks_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Constraint_Stacks_Select_Column_Constraint_Stacks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Constraint_Stacks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Constraint_Stacks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Constraint_Stacks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Constraint_Stacks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "constraint_stacks" */
export type Constraint_Stacks_Aggregate_Fields = {
  __typename?: 'constraint_stacks_aggregate_fields';
  avg?: Maybe<Constraint_Stacks_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Constraint_Stacks_Max_Fields>;
  min?: Maybe<Constraint_Stacks_Min_Fields>;
  stddev?: Maybe<Constraint_Stacks_Stddev_Fields>;
  stddev_pop?: Maybe<Constraint_Stacks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Constraint_Stacks_Stddev_Samp_Fields>;
  sum?: Maybe<Constraint_Stacks_Sum_Fields>;
  var_pop?: Maybe<Constraint_Stacks_Var_Pop_Fields>;
  var_samp?: Maybe<Constraint_Stacks_Var_Samp_Fields>;
  variance?: Maybe<Constraint_Stacks_Variance_Fields>;
};


/** aggregate fields of "constraint_stacks" */
export type Constraint_Stacks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Constraint_Stacks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "constraint_stacks" */
export type Constraint_Stacks_Aggregate_Order_By = {
  avg?: InputMaybe<Constraint_Stacks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Constraint_Stacks_Max_Order_By>;
  min?: InputMaybe<Constraint_Stacks_Min_Order_By>;
  stddev?: InputMaybe<Constraint_Stacks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Constraint_Stacks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Constraint_Stacks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Constraint_Stacks_Sum_Order_By>;
  var_pop?: InputMaybe<Constraint_Stacks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Constraint_Stacks_Var_Samp_Order_By>;
  variance?: InputMaybe<Constraint_Stacks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "constraint_stacks" */
export type Constraint_Stacks_Arr_Rel_Insert_Input = {
  data: Array<Constraint_Stacks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Constraint_Stacks_On_Conflict>;
};

/** aggregate avg on columns */
export type Constraint_Stacks_Avg_Fields = {
  __typename?: 'constraint_stacks_avg_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  estimated_cost?: Maybe<Scalars['Float']['output']>;
  from_bus_kv_nominal?: Maybe<Scalars['Float']['output']>;
  injection_threshold?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  to_bus_kv_nominal?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "constraint_stacks" */
export type Constraint_Stacks_Avg_Order_By = {
  dfax?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  from_bus_kv_nominal?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  to_bus_kv_nominal?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "constraint_stacks". All fields are combined with a logical 'AND'. */
export type Constraint_Stacks_Bool_Exp = {
  _and?: InputMaybe<Array<Constraint_Stacks_Bool_Exp>>;
  _not?: InputMaybe<Constraint_Stacks_Bool_Exp>;
  _or?: InputMaybe<Array<Constraint_Stacks_Bool_Exp>>;
  bus?: InputMaybe<Buses_Bool_Exp>;
  bus_id?: InputMaybe<String_Comparison_Exp>;
  contingency?: InputMaybe<String_Comparison_Exp>;
  dfax?: InputMaybe<Numeric_Comparison_Exp>;
  element_type?: InputMaybe<String_Comparison_Exp>;
  estimated_cost?: InputMaybe<Numeric_Comparison_Exp>;
  from_bus_kv_nominal?: InputMaybe<Numeric_Comparison_Exp>;
  injection_threshold?: InputMaybe<Numeric_Comparison_Exp>;
  is_pre_existing?: InputMaybe<Boolean_Comparison_Exp>;
  line_length?: InputMaybe<Numeric_Comparison_Exp>;
  monitored_facility_id?: InputMaybe<String_Comparison_Exp>;
  monitored_facility_name?: InputMaybe<String_Comparison_Exp>;
  pre_study_load?: InputMaybe<Numeric_Comparison_Exp>;
  rating?: InputMaybe<Numeric_Comparison_Exp>;
  resource_type?: InputMaybe<String_Comparison_Exp>;
  scope?: InputMaybe<String_Comparison_Exp>;
  scope_view?: InputMaybe<String_Comparison_Exp>;
  to_bus_kv_nominal?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "constraint_stacks" */
export enum Constraint_Stacks_Constraint {
  /** unique or primary key constraint on columns "scope", "scope_view", "resource_type", "bus_id", "monitored_facility_id" */
  ConstraintStacksPkey = 'constraint_stacks_pkey'
}

/** input type for incrementing numeric columns in table "constraint_stacks" */
export type Constraint_Stacks_Inc_Input = {
  dfax?: InputMaybe<Scalars['numeric']['input']>;
  estimated_cost?: InputMaybe<Scalars['numeric']['input']>;
  from_bus_kv_nominal?: InputMaybe<Scalars['numeric']['input']>;
  injection_threshold?: InputMaybe<Scalars['numeric']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  pre_study_load?: InputMaybe<Scalars['numeric']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
  to_bus_kv_nominal?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "constraint_stacks" */
export type Constraint_Stacks_Insert_Input = {
  bus?: InputMaybe<Buses_Obj_Rel_Insert_Input>;
  bus_id?: InputMaybe<Scalars['String']['input']>;
  contingency?: InputMaybe<Scalars['String']['input']>;
  dfax?: InputMaybe<Scalars['numeric']['input']>;
  element_type?: InputMaybe<Scalars['String']['input']>;
  estimated_cost?: InputMaybe<Scalars['numeric']['input']>;
  from_bus_kv_nominal?: InputMaybe<Scalars['numeric']['input']>;
  injection_threshold?: InputMaybe<Scalars['numeric']['input']>;
  is_pre_existing?: InputMaybe<Scalars['Boolean']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  monitored_facility_id?: InputMaybe<Scalars['String']['input']>;
  monitored_facility_name?: InputMaybe<Scalars['String']['input']>;
  pre_study_load?: InputMaybe<Scalars['numeric']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
  resource_type?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  to_bus_kv_nominal?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Constraint_Stacks_Max_Fields = {
  __typename?: 'constraint_stacks_max_fields';
  bus_id?: Maybe<Scalars['String']['output']>;
  contingency?: Maybe<Scalars['String']['output']>;
  dfax?: Maybe<Scalars['numeric']['output']>;
  element_type?: Maybe<Scalars['String']['output']>;
  estimated_cost?: Maybe<Scalars['numeric']['output']>;
  from_bus_kv_nominal?: Maybe<Scalars['numeric']['output']>;
  injection_threshold?: Maybe<Scalars['numeric']['output']>;
  line_length?: Maybe<Scalars['numeric']['output']>;
  monitored_facility_id?: Maybe<Scalars['String']['output']>;
  monitored_facility_name?: Maybe<Scalars['String']['output']>;
  pre_study_load?: Maybe<Scalars['numeric']['output']>;
  rating?: Maybe<Scalars['numeric']['output']>;
  resource_type?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  scope_view?: Maybe<Scalars['String']['output']>;
  to_bus_kv_nominal?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "constraint_stacks" */
export type Constraint_Stacks_Max_Order_By = {
  bus_id?: InputMaybe<Order_By>;
  contingency?: InputMaybe<Order_By>;
  dfax?: InputMaybe<Order_By>;
  element_type?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  from_bus_kv_nominal?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  monitored_facility_id?: InputMaybe<Order_By>;
  monitored_facility_name?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  resource_type?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  scope_view?: InputMaybe<Order_By>;
  to_bus_kv_nominal?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Constraint_Stacks_Min_Fields = {
  __typename?: 'constraint_stacks_min_fields';
  bus_id?: Maybe<Scalars['String']['output']>;
  contingency?: Maybe<Scalars['String']['output']>;
  dfax?: Maybe<Scalars['numeric']['output']>;
  element_type?: Maybe<Scalars['String']['output']>;
  estimated_cost?: Maybe<Scalars['numeric']['output']>;
  from_bus_kv_nominal?: Maybe<Scalars['numeric']['output']>;
  injection_threshold?: Maybe<Scalars['numeric']['output']>;
  line_length?: Maybe<Scalars['numeric']['output']>;
  monitored_facility_id?: Maybe<Scalars['String']['output']>;
  monitored_facility_name?: Maybe<Scalars['String']['output']>;
  pre_study_load?: Maybe<Scalars['numeric']['output']>;
  rating?: Maybe<Scalars['numeric']['output']>;
  resource_type?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  scope_view?: Maybe<Scalars['String']['output']>;
  to_bus_kv_nominal?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "constraint_stacks" */
export type Constraint_Stacks_Min_Order_By = {
  bus_id?: InputMaybe<Order_By>;
  contingency?: InputMaybe<Order_By>;
  dfax?: InputMaybe<Order_By>;
  element_type?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  from_bus_kv_nominal?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  monitored_facility_id?: InputMaybe<Order_By>;
  monitored_facility_name?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  resource_type?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  scope_view?: InputMaybe<Order_By>;
  to_bus_kv_nominal?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "constraint_stacks" */
export type Constraint_Stacks_Mutation_Response = {
  __typename?: 'constraint_stacks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Constraint_Stacks>;
};

/** on_conflict condition type for table "constraint_stacks" */
export type Constraint_Stacks_On_Conflict = {
  constraint: Constraint_Stacks_Constraint;
  update_columns?: Array<Constraint_Stacks_Update_Column>;
  where?: InputMaybe<Constraint_Stacks_Bool_Exp>;
};

/** Ordering options when selecting data from "constraint_stacks". */
export type Constraint_Stacks_Order_By = {
  bus?: InputMaybe<Buses_Order_By>;
  bus_id?: InputMaybe<Order_By>;
  contingency?: InputMaybe<Order_By>;
  dfax?: InputMaybe<Order_By>;
  element_type?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  from_bus_kv_nominal?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  is_pre_existing?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  monitored_facility_id?: InputMaybe<Order_By>;
  monitored_facility_name?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  resource_type?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  scope_view?: InputMaybe<Order_By>;
  to_bus_kv_nominal?: InputMaybe<Order_By>;
};

/** primary key columns input for table: constraint_stacks */
export type Constraint_Stacks_Pk_Columns_Input = {
  bus_id: Scalars['String']['input'];
  monitored_facility_id: Scalars['String']['input'];
  resource_type: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
};

/** select columns of table "constraint_stacks" */
export enum Constraint_Stacks_Select_Column {
  /** column name */
  BusId = 'bus_id',
  /** column name */
  Contingency = 'contingency',
  /** column name */
  Dfax = 'dfax',
  /** column name */
  ElementType = 'element_type',
  /** column name */
  EstimatedCost = 'estimated_cost',
  /** column name */
  FromBusKvNominal = 'from_bus_kv_nominal',
  /** column name */
  InjectionThreshold = 'injection_threshold',
  /** column name */
  IsPreExisting = 'is_pre_existing',
  /** column name */
  LineLength = 'line_length',
  /** column name */
  MonitoredFacilityId = 'monitored_facility_id',
  /** column name */
  MonitoredFacilityName = 'monitored_facility_name',
  /** column name */
  PreStudyLoad = 'pre_study_load',
  /** column name */
  Rating = 'rating',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  Scope = 'scope',
  /** column name */
  ScopeView = 'scope_view',
  /** column name */
  ToBusKvNominal = 'to_bus_kv_nominal'
}

/** select "constraint_stacks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "constraint_stacks" */
export enum Constraint_Stacks_Select_Column_Constraint_Stacks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPreExisting = 'is_pre_existing'
}

/** select "constraint_stacks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "constraint_stacks" */
export enum Constraint_Stacks_Select_Column_Constraint_Stacks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPreExisting = 'is_pre_existing'
}

/** input type for updating data in table "constraint_stacks" */
export type Constraint_Stacks_Set_Input = {
  bus_id?: InputMaybe<Scalars['String']['input']>;
  contingency?: InputMaybe<Scalars['String']['input']>;
  dfax?: InputMaybe<Scalars['numeric']['input']>;
  element_type?: InputMaybe<Scalars['String']['input']>;
  estimated_cost?: InputMaybe<Scalars['numeric']['input']>;
  from_bus_kv_nominal?: InputMaybe<Scalars['numeric']['input']>;
  injection_threshold?: InputMaybe<Scalars['numeric']['input']>;
  is_pre_existing?: InputMaybe<Scalars['Boolean']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  monitored_facility_id?: InputMaybe<Scalars['String']['input']>;
  monitored_facility_name?: InputMaybe<Scalars['String']['input']>;
  pre_study_load?: InputMaybe<Scalars['numeric']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
  resource_type?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  to_bus_kv_nominal?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Constraint_Stacks_Stddev_Fields = {
  __typename?: 'constraint_stacks_stddev_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  estimated_cost?: Maybe<Scalars['Float']['output']>;
  from_bus_kv_nominal?: Maybe<Scalars['Float']['output']>;
  injection_threshold?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  to_bus_kv_nominal?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "constraint_stacks" */
export type Constraint_Stacks_Stddev_Order_By = {
  dfax?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  from_bus_kv_nominal?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  to_bus_kv_nominal?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Constraint_Stacks_Stddev_Pop_Fields = {
  __typename?: 'constraint_stacks_stddev_pop_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  estimated_cost?: Maybe<Scalars['Float']['output']>;
  from_bus_kv_nominal?: Maybe<Scalars['Float']['output']>;
  injection_threshold?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  to_bus_kv_nominal?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "constraint_stacks" */
export type Constraint_Stacks_Stddev_Pop_Order_By = {
  dfax?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  from_bus_kv_nominal?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  to_bus_kv_nominal?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Constraint_Stacks_Stddev_Samp_Fields = {
  __typename?: 'constraint_stacks_stddev_samp_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  estimated_cost?: Maybe<Scalars['Float']['output']>;
  from_bus_kv_nominal?: Maybe<Scalars['Float']['output']>;
  injection_threshold?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  to_bus_kv_nominal?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "constraint_stacks" */
export type Constraint_Stacks_Stddev_Samp_Order_By = {
  dfax?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  from_bus_kv_nominal?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  to_bus_kv_nominal?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "constraint_stacks" */
export type Constraint_Stacks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Constraint_Stacks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Constraint_Stacks_Stream_Cursor_Value_Input = {
  bus_id?: InputMaybe<Scalars['String']['input']>;
  contingency?: InputMaybe<Scalars['String']['input']>;
  dfax?: InputMaybe<Scalars['numeric']['input']>;
  element_type?: InputMaybe<Scalars['String']['input']>;
  estimated_cost?: InputMaybe<Scalars['numeric']['input']>;
  from_bus_kv_nominal?: InputMaybe<Scalars['numeric']['input']>;
  injection_threshold?: InputMaybe<Scalars['numeric']['input']>;
  is_pre_existing?: InputMaybe<Scalars['Boolean']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  monitored_facility_id?: InputMaybe<Scalars['String']['input']>;
  monitored_facility_name?: InputMaybe<Scalars['String']['input']>;
  pre_study_load?: InputMaybe<Scalars['numeric']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
  resource_type?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  to_bus_kv_nominal?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Constraint_Stacks_Sum_Fields = {
  __typename?: 'constraint_stacks_sum_fields';
  dfax?: Maybe<Scalars['numeric']['output']>;
  estimated_cost?: Maybe<Scalars['numeric']['output']>;
  from_bus_kv_nominal?: Maybe<Scalars['numeric']['output']>;
  injection_threshold?: Maybe<Scalars['numeric']['output']>;
  line_length?: Maybe<Scalars['numeric']['output']>;
  pre_study_load?: Maybe<Scalars['numeric']['output']>;
  rating?: Maybe<Scalars['numeric']['output']>;
  to_bus_kv_nominal?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "constraint_stacks" */
export type Constraint_Stacks_Sum_Order_By = {
  dfax?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  from_bus_kv_nominal?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  to_bus_kv_nominal?: InputMaybe<Order_By>;
};

/** update columns of table "constraint_stacks" */
export enum Constraint_Stacks_Update_Column {
  /** column name */
  BusId = 'bus_id',
  /** column name */
  Contingency = 'contingency',
  /** column name */
  Dfax = 'dfax',
  /** column name */
  ElementType = 'element_type',
  /** column name */
  EstimatedCost = 'estimated_cost',
  /** column name */
  FromBusKvNominal = 'from_bus_kv_nominal',
  /** column name */
  InjectionThreshold = 'injection_threshold',
  /** column name */
  IsPreExisting = 'is_pre_existing',
  /** column name */
  LineLength = 'line_length',
  /** column name */
  MonitoredFacilityId = 'monitored_facility_id',
  /** column name */
  MonitoredFacilityName = 'monitored_facility_name',
  /** column name */
  PreStudyLoad = 'pre_study_load',
  /** column name */
  Rating = 'rating',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  Scope = 'scope',
  /** column name */
  ScopeView = 'scope_view',
  /** column name */
  ToBusKvNominal = 'to_bus_kv_nominal'
}

export type Constraint_Stacks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Constraint_Stacks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Constraint_Stacks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Constraint_Stacks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Constraint_Stacks_Var_Pop_Fields = {
  __typename?: 'constraint_stacks_var_pop_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  estimated_cost?: Maybe<Scalars['Float']['output']>;
  from_bus_kv_nominal?: Maybe<Scalars['Float']['output']>;
  injection_threshold?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  to_bus_kv_nominal?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "constraint_stacks" */
export type Constraint_Stacks_Var_Pop_Order_By = {
  dfax?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  from_bus_kv_nominal?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  to_bus_kv_nominal?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Constraint_Stacks_Var_Samp_Fields = {
  __typename?: 'constraint_stacks_var_samp_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  estimated_cost?: Maybe<Scalars['Float']['output']>;
  from_bus_kv_nominal?: Maybe<Scalars['Float']['output']>;
  injection_threshold?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  to_bus_kv_nominal?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "constraint_stacks" */
export type Constraint_Stacks_Var_Samp_Order_By = {
  dfax?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  from_bus_kv_nominal?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  to_bus_kv_nominal?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Constraint_Stacks_Variance_Fields = {
  __typename?: 'constraint_stacks_variance_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  estimated_cost?: Maybe<Scalars['Float']['output']>;
  from_bus_kv_nominal?: Maybe<Scalars['Float']['output']>;
  injection_threshold?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  to_bus_kv_nominal?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "constraint_stacks" */
export type Constraint_Stacks_Variance_Order_By = {
  dfax?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  from_bus_kv_nominal?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  to_bus_kv_nominal?: InputMaybe<Order_By>;
};

/** columns and relationships of "constraint_types" */
export type Constraint_Types = {
  __typename?: 'constraint_types';
  constraint_type: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

/** aggregated selection of "constraint_types" */
export type Constraint_Types_Aggregate = {
  __typename?: 'constraint_types_aggregate';
  aggregate?: Maybe<Constraint_Types_Aggregate_Fields>;
  nodes: Array<Constraint_Types>;
};

/** aggregate fields of "constraint_types" */
export type Constraint_Types_Aggregate_Fields = {
  __typename?: 'constraint_types_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Constraint_Types_Max_Fields>;
  min?: Maybe<Constraint_Types_Min_Fields>;
};


/** aggregate fields of "constraint_types" */
export type Constraint_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Constraint_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "constraint_types". All fields are combined with a logical 'AND'. */
export type Constraint_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Constraint_Types_Bool_Exp>>;
  _not?: InputMaybe<Constraint_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Constraint_Types_Bool_Exp>>;
  constraint_type?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "constraint_types" */
export enum Constraint_Types_Constraint {
  /** unique or primary key constraint on columns "constraint_type" */
  ConstraintTypesPkey = 'constraint_types_pkey'
}

export enum Constraint_Types_Enum {
  /** An ERIS thermal constraint */
  Eris = 'ERIS',
  /** An ERIS charging constraint, used in MISO */
  ErisCharging = 'ERIS_CHARGING',
  /** A complex cost allocation for an ERIS constraint, used in MISO */
  ErisComplex = 'ERIS_COMPLEX',
  /** An ERIS Local Planning Criteria constraint in MISO */
  ErisLpc = 'ERIS_LPC',
  /** Light load contingency type for PJM */
  LightLoad = 'LIGHT_LOAD',
  /** An NRIS thermal constraint */
  Nris = 'NRIS',
  /** A complex cost allocation for an NRIS constraint, used in MISO */
  NrisComplex = 'NRIS_COMPLEX',
  /** A network upgrade shared with a prior DPP cycle, used in MISO */
  SharedNetworkUpgrade = 'SHARED_NETWORK_UPGRADE',
  /** A non-convergence constraint type for SPP */
  SppNonConvergence = 'SPP_NON_CONVERGENCE',
  /** A thermal constraint type for SPP */
  SppThermal = 'SPP_THERMAL',
  /** Summer peak contingency type for PJM */
  SummerPeak = 'SUMMER_PEAK',
  /** Winter peak contingency type for PJM */
  WinterPeak = 'WINTER_PEAK'
}

/** Boolean expression to compare columns of type "constraint_types_enum". All fields are combined with logical 'AND'. */
export type Constraint_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Constraint_Types_Enum>;
  _in?: InputMaybe<Array<Constraint_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Constraint_Types_Enum>;
  _nin?: InputMaybe<Array<Constraint_Types_Enum>>;
};

/** input type for inserting data into table "constraint_types" */
export type Constraint_Types_Insert_Input = {
  constraint_type?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Constraint_Types_Max_Fields = {
  __typename?: 'constraint_types_max_fields';
  constraint_type?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Constraint_Types_Min_Fields = {
  __typename?: 'constraint_types_min_fields';
  constraint_type?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "constraint_types" */
export type Constraint_Types_Mutation_Response = {
  __typename?: 'constraint_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Constraint_Types>;
};

/** on_conflict condition type for table "constraint_types" */
export type Constraint_Types_On_Conflict = {
  constraint: Constraint_Types_Constraint;
  update_columns?: Array<Constraint_Types_Update_Column>;
  where?: InputMaybe<Constraint_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "constraint_types". */
export type Constraint_Types_Order_By = {
  constraint_type?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
};

/** primary key columns input for table: constraint_types */
export type Constraint_Types_Pk_Columns_Input = {
  constraint_type: Scalars['String']['input'];
};

/** select columns of table "constraint_types" */
export enum Constraint_Types_Select_Column {
  /** column name */
  ConstraintType = 'constraint_type',
  /** column name */
  Description = 'description'
}

/** input type for updating data in table "constraint_types" */
export type Constraint_Types_Set_Input = {
  constraint_type?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "constraint_types" */
export type Constraint_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Constraint_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Constraint_Types_Stream_Cursor_Value_Input = {
  constraint_type?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "constraint_types" */
export enum Constraint_Types_Update_Column {
  /** column name */
  ConstraintType = 'constraint_type',
  /** column name */
  Description = 'description'
}

export type Constraint_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Constraint_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Constraint_Types_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** columns and relationships of "in_queue_subscriptions" */
export type In_Queue_Subscriptions = {
  __typename?: 'in_queue_subscriptions';
  phase: Scalars['String']['output'];
  region: Scalars['String']['output'];
  scenario_limit: Scalars['Int']['output'];
  /** An array relationship */
  scenarios: Array<Scenarios>;
  /** An aggregate relationship */
  scenarios_aggregate: Scenarios_Aggregate;
  status: Scalars['String']['output'];
  stripe_id?: Maybe<Scalars['String']['output']>;
  study_cycle: Scalars['String']['output'];
  study_group: Scalars['String']['output'];
  subscription_id: Scalars['uuid']['output'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['Int']['output'];
  /** An array relationship */
  tracked_projects: Array<Tracked_Projects>;
  /** An aggregate relationship */
  tracked_projects_aggregate: Tracked_Projects_Aggregate;
};


/** columns and relationships of "in_queue_subscriptions" */
export type In_Queue_SubscriptionsScenariosArgs = {
  distinct_on?: InputMaybe<Array<Scenarios_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenarios_Order_By>>;
  where?: InputMaybe<Scenarios_Bool_Exp>;
};


/** columns and relationships of "in_queue_subscriptions" */
export type In_Queue_SubscriptionsScenarios_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scenarios_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenarios_Order_By>>;
  where?: InputMaybe<Scenarios_Bool_Exp>;
};


/** columns and relationships of "in_queue_subscriptions" */
export type In_Queue_SubscriptionsTracked_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tracked_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tracked_Projects_Order_By>>;
  where?: InputMaybe<Tracked_Projects_Bool_Exp>;
};


/** columns and relationships of "in_queue_subscriptions" */
export type In_Queue_SubscriptionsTracked_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracked_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tracked_Projects_Order_By>>;
  where?: InputMaybe<Tracked_Projects_Bool_Exp>;
};

/** aggregated selection of "in_queue_subscriptions" */
export type In_Queue_Subscriptions_Aggregate = {
  __typename?: 'in_queue_subscriptions_aggregate';
  aggregate?: Maybe<In_Queue_Subscriptions_Aggregate_Fields>;
  nodes: Array<In_Queue_Subscriptions>;
};

/** aggregate fields of "in_queue_subscriptions" */
export type In_Queue_Subscriptions_Aggregate_Fields = {
  __typename?: 'in_queue_subscriptions_aggregate_fields';
  avg?: Maybe<In_Queue_Subscriptions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<In_Queue_Subscriptions_Max_Fields>;
  min?: Maybe<In_Queue_Subscriptions_Min_Fields>;
  stddev?: Maybe<In_Queue_Subscriptions_Stddev_Fields>;
  stddev_pop?: Maybe<In_Queue_Subscriptions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<In_Queue_Subscriptions_Stddev_Samp_Fields>;
  sum?: Maybe<In_Queue_Subscriptions_Sum_Fields>;
  var_pop?: Maybe<In_Queue_Subscriptions_Var_Pop_Fields>;
  var_samp?: Maybe<In_Queue_Subscriptions_Var_Samp_Fields>;
  variance?: Maybe<In_Queue_Subscriptions_Variance_Fields>;
};


/** aggregate fields of "in_queue_subscriptions" */
export type In_Queue_Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<In_Queue_Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type In_Queue_Subscriptions_Avg_Fields = {
  __typename?: 'in_queue_subscriptions_avg_fields';
  scenario_limit?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "in_queue_subscriptions". All fields are combined with a logical 'AND'. */
export type In_Queue_Subscriptions_Bool_Exp = {
  _and?: InputMaybe<Array<In_Queue_Subscriptions_Bool_Exp>>;
  _not?: InputMaybe<In_Queue_Subscriptions_Bool_Exp>;
  _or?: InputMaybe<Array<In_Queue_Subscriptions_Bool_Exp>>;
  phase?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  scenario_limit?: InputMaybe<Int_Comparison_Exp>;
  scenarios?: InputMaybe<Scenarios_Bool_Exp>;
  scenarios_aggregate?: InputMaybe<Scenarios_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  stripe_id?: InputMaybe<String_Comparison_Exp>;
  study_cycle?: InputMaybe<String_Comparison_Exp>;
  study_group?: InputMaybe<String_Comparison_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  tracked_projects?: InputMaybe<Tracked_Projects_Bool_Exp>;
  tracked_projects_aggregate?: InputMaybe<Tracked_Projects_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "in_queue_subscriptions" */
export enum In_Queue_Subscriptions_Constraint {
  /** unique or primary key constraint on columns "subscription_id" */
  InQueueSubscriptionsPkey = 'in_queue_subscriptions_pkey'
}

/** input type for incrementing numeric columns in table "in_queue_subscriptions" */
export type In_Queue_Subscriptions_Inc_Input = {
  scenario_limit?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "in_queue_subscriptions" */
export type In_Queue_Subscriptions_Insert_Input = {
  phase?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  scenario_limit?: InputMaybe<Scalars['Int']['input']>;
  scenarios?: InputMaybe<Scenarios_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']['input']>;
  stripe_id?: InputMaybe<Scalars['String']['input']>;
  study_cycle?: InputMaybe<Scalars['String']['input']>;
  study_group?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['uuid']['input']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  tracked_projects?: InputMaybe<Tracked_Projects_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type In_Queue_Subscriptions_Max_Fields = {
  __typename?: 'in_queue_subscriptions_max_fields';
  phase?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  scenario_limit?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stripe_id?: Maybe<Scalars['String']['output']>;
  study_cycle?: Maybe<Scalars['String']['output']>;
  study_group?: Maybe<Scalars['String']['output']>;
  subscription_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type In_Queue_Subscriptions_Min_Fields = {
  __typename?: 'in_queue_subscriptions_min_fields';
  phase?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  scenario_limit?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stripe_id?: Maybe<Scalars['String']['output']>;
  study_cycle?: Maybe<Scalars['String']['output']>;
  study_group?: Maybe<Scalars['String']['output']>;
  subscription_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "in_queue_subscriptions" */
export type In_Queue_Subscriptions_Mutation_Response = {
  __typename?: 'in_queue_subscriptions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<In_Queue_Subscriptions>;
};

/** input type for inserting object relation for remote table "in_queue_subscriptions" */
export type In_Queue_Subscriptions_Obj_Rel_Insert_Input = {
  data: In_Queue_Subscriptions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<In_Queue_Subscriptions_On_Conflict>;
};

/** on_conflict condition type for table "in_queue_subscriptions" */
export type In_Queue_Subscriptions_On_Conflict = {
  constraint: In_Queue_Subscriptions_Constraint;
  update_columns?: Array<In_Queue_Subscriptions_Update_Column>;
  where?: InputMaybe<In_Queue_Subscriptions_Bool_Exp>;
};

/** Ordering options when selecting data from "in_queue_subscriptions". */
export type In_Queue_Subscriptions_Order_By = {
  phase?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  scenario_limit?: InputMaybe<Order_By>;
  scenarios_aggregate?: InputMaybe<Scenarios_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  stripe_id?: InputMaybe<Order_By>;
  study_cycle?: InputMaybe<Order_By>;
  study_group?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  tracked_projects_aggregate?: InputMaybe<Tracked_Projects_Aggregate_Order_By>;
};

/** primary key columns input for table: in_queue_subscriptions */
export type In_Queue_Subscriptions_Pk_Columns_Input = {
  subscription_id: Scalars['uuid']['input'];
};

/** select columns of table "in_queue_subscriptions" */
export enum In_Queue_Subscriptions_Select_Column {
  /** column name */
  Phase = 'phase',
  /** column name */
  Region = 'region',
  /** column name */
  ScenarioLimit = 'scenario_limit',
  /** column name */
  Status = 'status',
  /** column name */
  StripeId = 'stripe_id',
  /** column name */
  StudyCycle = 'study_cycle',
  /** column name */
  StudyGroup = 'study_group',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "in_queue_subscriptions" */
export type In_Queue_Subscriptions_Set_Input = {
  phase?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  scenario_limit?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  stripe_id?: InputMaybe<Scalars['String']['input']>;
  study_cycle?: InputMaybe<Scalars['String']['input']>;
  study_group?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['uuid']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type In_Queue_Subscriptions_Stddev_Fields = {
  __typename?: 'in_queue_subscriptions_stddev_fields';
  scenario_limit?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type In_Queue_Subscriptions_Stddev_Pop_Fields = {
  __typename?: 'in_queue_subscriptions_stddev_pop_fields';
  scenario_limit?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type In_Queue_Subscriptions_Stddev_Samp_Fields = {
  __typename?: 'in_queue_subscriptions_stddev_samp_fields';
  scenario_limit?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "in_queue_subscriptions" */
export type In_Queue_Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: In_Queue_Subscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type In_Queue_Subscriptions_Stream_Cursor_Value_Input = {
  phase?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  scenario_limit?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  stripe_id?: InputMaybe<Scalars['String']['input']>;
  study_cycle?: InputMaybe<Scalars['String']['input']>;
  study_group?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['uuid']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type In_Queue_Subscriptions_Sum_Fields = {
  __typename?: 'in_queue_subscriptions_sum_fields';
  scenario_limit?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "in_queue_subscriptions" */
export enum In_Queue_Subscriptions_Update_Column {
  /** column name */
  Phase = 'phase',
  /** column name */
  Region = 'region',
  /** column name */
  ScenarioLimit = 'scenario_limit',
  /** column name */
  Status = 'status',
  /** column name */
  StripeId = 'stripe_id',
  /** column name */
  StudyCycle = 'study_cycle',
  /** column name */
  StudyGroup = 'study_group',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  TeamId = 'team_id'
}

export type In_Queue_Subscriptions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<In_Queue_Subscriptions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<In_Queue_Subscriptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: In_Queue_Subscriptions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type In_Queue_Subscriptions_Var_Pop_Fields = {
  __typename?: 'in_queue_subscriptions_var_pop_fields';
  scenario_limit?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type In_Queue_Subscriptions_Var_Samp_Fields = {
  __typename?: 'in_queue_subscriptions_var_samp_fields';
  scenario_limit?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type In_Queue_Subscriptions_Variance_Fields = {
  __typename?: 'in_queue_subscriptions_variance_fields';
  scenario_limit?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** Capacity costs for line taps */
export type Line_Capacity_Costs = {
  __typename?: 'line_capacity_costs';
  capacity_size: Scalars['numeric']['output'];
  charging_size: Scalars['numeric']['output'];
  energy_size: Scalars['numeric']['output'];
  includes_pre_existing: Scalars['Boolean']['output'];
  /** An object relationship */
  model_line?: Maybe<Model_Lines>;
  scope: Scalars['String']['output'];
  scope_view: Scalars['String']['output'];
  tap_facility_id: Scalars['String']['output'];
  total_upgrade_costs: Scalars['numeric']['output'];
  upgrade_costs: Scalars['numeric']['output'];
};

/** aggregated selection of "line_capacity_costs" */
export type Line_Capacity_Costs_Aggregate = {
  __typename?: 'line_capacity_costs_aggregate';
  aggregate?: Maybe<Line_Capacity_Costs_Aggregate_Fields>;
  nodes: Array<Line_Capacity_Costs>;
};

export type Line_Capacity_Costs_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Line_Capacity_Costs_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Line_Capacity_Costs_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Line_Capacity_Costs_Aggregate_Bool_Exp_Count>;
};

export type Line_Capacity_Costs_Aggregate_Bool_Exp_Bool_And = {
  arguments: Line_Capacity_Costs_Select_Column_Line_Capacity_Costs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Line_Capacity_Costs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Line_Capacity_Costs_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Line_Capacity_Costs_Select_Column_Line_Capacity_Costs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Line_Capacity_Costs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Line_Capacity_Costs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Line_Capacity_Costs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Line_Capacity_Costs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "line_capacity_costs" */
export type Line_Capacity_Costs_Aggregate_Fields = {
  __typename?: 'line_capacity_costs_aggregate_fields';
  avg?: Maybe<Line_Capacity_Costs_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Line_Capacity_Costs_Max_Fields>;
  min?: Maybe<Line_Capacity_Costs_Min_Fields>;
  stddev?: Maybe<Line_Capacity_Costs_Stddev_Fields>;
  stddev_pop?: Maybe<Line_Capacity_Costs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Line_Capacity_Costs_Stddev_Samp_Fields>;
  sum?: Maybe<Line_Capacity_Costs_Sum_Fields>;
  var_pop?: Maybe<Line_Capacity_Costs_Var_Pop_Fields>;
  var_samp?: Maybe<Line_Capacity_Costs_Var_Samp_Fields>;
  variance?: Maybe<Line_Capacity_Costs_Variance_Fields>;
};


/** aggregate fields of "line_capacity_costs" */
export type Line_Capacity_Costs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Line_Capacity_Costs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "line_capacity_costs" */
export type Line_Capacity_Costs_Aggregate_Order_By = {
  avg?: InputMaybe<Line_Capacity_Costs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Line_Capacity_Costs_Max_Order_By>;
  min?: InputMaybe<Line_Capacity_Costs_Min_Order_By>;
  stddev?: InputMaybe<Line_Capacity_Costs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Line_Capacity_Costs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Line_Capacity_Costs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Line_Capacity_Costs_Sum_Order_By>;
  var_pop?: InputMaybe<Line_Capacity_Costs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Line_Capacity_Costs_Var_Samp_Order_By>;
  variance?: InputMaybe<Line_Capacity_Costs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "line_capacity_costs" */
export type Line_Capacity_Costs_Arr_Rel_Insert_Input = {
  data: Array<Line_Capacity_Costs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Line_Capacity_Costs_On_Conflict>;
};

/** aggregate avg on columns */
export type Line_Capacity_Costs_Avg_Fields = {
  __typename?: 'line_capacity_costs_avg_fields';
  capacity_size?: Maybe<Scalars['Float']['output']>;
  charging_size?: Maybe<Scalars['Float']['output']>;
  energy_size?: Maybe<Scalars['Float']['output']>;
  total_upgrade_costs?: Maybe<Scalars['Float']['output']>;
  upgrade_costs?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "line_capacity_costs" */
export type Line_Capacity_Costs_Avg_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "line_capacity_costs". All fields are combined with a logical 'AND'. */
export type Line_Capacity_Costs_Bool_Exp = {
  _and?: InputMaybe<Array<Line_Capacity_Costs_Bool_Exp>>;
  _not?: InputMaybe<Line_Capacity_Costs_Bool_Exp>;
  _or?: InputMaybe<Array<Line_Capacity_Costs_Bool_Exp>>;
  capacity_size?: InputMaybe<Numeric_Comparison_Exp>;
  charging_size?: InputMaybe<Numeric_Comparison_Exp>;
  energy_size?: InputMaybe<Numeric_Comparison_Exp>;
  includes_pre_existing?: InputMaybe<Boolean_Comparison_Exp>;
  model_line?: InputMaybe<Model_Lines_Bool_Exp>;
  scope?: InputMaybe<String_Comparison_Exp>;
  scope_view?: InputMaybe<String_Comparison_Exp>;
  tap_facility_id?: InputMaybe<String_Comparison_Exp>;
  total_upgrade_costs?: InputMaybe<Numeric_Comparison_Exp>;
  upgrade_costs?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "line_capacity_costs" */
export enum Line_Capacity_Costs_Constraint {
  /** unique or primary key constraint on columns "scope", "energy_size", "includes_pre_existing", "tap_facility_id", "scope_view" */
  LineCapacityCostsPkey = 'line_capacity_costs_pkey'
}

/** input type for incrementing numeric columns in table "line_capacity_costs" */
export type Line_Capacity_Costs_Inc_Input = {
  capacity_size?: InputMaybe<Scalars['numeric']['input']>;
  charging_size?: InputMaybe<Scalars['numeric']['input']>;
  energy_size?: InputMaybe<Scalars['numeric']['input']>;
  total_upgrade_costs?: InputMaybe<Scalars['numeric']['input']>;
  upgrade_costs?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "line_capacity_costs" */
export type Line_Capacity_Costs_Insert_Input = {
  capacity_size?: InputMaybe<Scalars['numeric']['input']>;
  charging_size?: InputMaybe<Scalars['numeric']['input']>;
  energy_size?: InputMaybe<Scalars['numeric']['input']>;
  includes_pre_existing?: InputMaybe<Scalars['Boolean']['input']>;
  model_line?: InputMaybe<Model_Lines_Obj_Rel_Insert_Input>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  tap_facility_id?: InputMaybe<Scalars['String']['input']>;
  total_upgrade_costs?: InputMaybe<Scalars['numeric']['input']>;
  upgrade_costs?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Line_Capacity_Costs_Max_Fields = {
  __typename?: 'line_capacity_costs_max_fields';
  capacity_size?: Maybe<Scalars['numeric']['output']>;
  charging_size?: Maybe<Scalars['numeric']['output']>;
  energy_size?: Maybe<Scalars['numeric']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  scope_view?: Maybe<Scalars['String']['output']>;
  tap_facility_id?: Maybe<Scalars['String']['output']>;
  total_upgrade_costs?: Maybe<Scalars['numeric']['output']>;
  upgrade_costs?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "line_capacity_costs" */
export type Line_Capacity_Costs_Max_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  scope_view?: InputMaybe<Order_By>;
  tap_facility_id?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Line_Capacity_Costs_Min_Fields = {
  __typename?: 'line_capacity_costs_min_fields';
  capacity_size?: Maybe<Scalars['numeric']['output']>;
  charging_size?: Maybe<Scalars['numeric']['output']>;
  energy_size?: Maybe<Scalars['numeric']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  scope_view?: Maybe<Scalars['String']['output']>;
  tap_facility_id?: Maybe<Scalars['String']['output']>;
  total_upgrade_costs?: Maybe<Scalars['numeric']['output']>;
  upgrade_costs?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "line_capacity_costs" */
export type Line_Capacity_Costs_Min_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  scope_view?: InputMaybe<Order_By>;
  tap_facility_id?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "line_capacity_costs" */
export type Line_Capacity_Costs_Mutation_Response = {
  __typename?: 'line_capacity_costs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Line_Capacity_Costs>;
};

/** on_conflict condition type for table "line_capacity_costs" */
export type Line_Capacity_Costs_On_Conflict = {
  constraint: Line_Capacity_Costs_Constraint;
  update_columns?: Array<Line_Capacity_Costs_Update_Column>;
  where?: InputMaybe<Line_Capacity_Costs_Bool_Exp>;
};

/** Ordering options when selecting data from "line_capacity_costs". */
export type Line_Capacity_Costs_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  includes_pre_existing?: InputMaybe<Order_By>;
  model_line?: InputMaybe<Model_Lines_Order_By>;
  scope?: InputMaybe<Order_By>;
  scope_view?: InputMaybe<Order_By>;
  tap_facility_id?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** primary key columns input for table: line_capacity_costs */
export type Line_Capacity_Costs_Pk_Columns_Input = {
  energy_size: Scalars['numeric']['input'];
  includes_pre_existing: Scalars['Boolean']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
  tap_facility_id: Scalars['String']['input'];
};

/** select columns of table "line_capacity_costs" */
export enum Line_Capacity_Costs_Select_Column {
  /** column name */
  CapacitySize = 'capacity_size',
  /** column name */
  ChargingSize = 'charging_size',
  /** column name */
  EnergySize = 'energy_size',
  /** column name */
  IncludesPreExisting = 'includes_pre_existing',
  /** column name */
  Scope = 'scope',
  /** column name */
  ScopeView = 'scope_view',
  /** column name */
  TapFacilityId = 'tap_facility_id',
  /** column name */
  TotalUpgradeCosts = 'total_upgrade_costs',
  /** column name */
  UpgradeCosts = 'upgrade_costs'
}

/** select "line_capacity_costs_aggregate_bool_exp_bool_and_arguments_columns" columns of table "line_capacity_costs" */
export enum Line_Capacity_Costs_Select_Column_Line_Capacity_Costs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IncludesPreExisting = 'includes_pre_existing'
}

/** select "line_capacity_costs_aggregate_bool_exp_bool_or_arguments_columns" columns of table "line_capacity_costs" */
export enum Line_Capacity_Costs_Select_Column_Line_Capacity_Costs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IncludesPreExisting = 'includes_pre_existing'
}

/** input type for updating data in table "line_capacity_costs" */
export type Line_Capacity_Costs_Set_Input = {
  capacity_size?: InputMaybe<Scalars['numeric']['input']>;
  charging_size?: InputMaybe<Scalars['numeric']['input']>;
  energy_size?: InputMaybe<Scalars['numeric']['input']>;
  includes_pre_existing?: InputMaybe<Scalars['Boolean']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  tap_facility_id?: InputMaybe<Scalars['String']['input']>;
  total_upgrade_costs?: InputMaybe<Scalars['numeric']['input']>;
  upgrade_costs?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Line_Capacity_Costs_Stddev_Fields = {
  __typename?: 'line_capacity_costs_stddev_fields';
  capacity_size?: Maybe<Scalars['Float']['output']>;
  charging_size?: Maybe<Scalars['Float']['output']>;
  energy_size?: Maybe<Scalars['Float']['output']>;
  total_upgrade_costs?: Maybe<Scalars['Float']['output']>;
  upgrade_costs?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "line_capacity_costs" */
export type Line_Capacity_Costs_Stddev_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Line_Capacity_Costs_Stddev_Pop_Fields = {
  __typename?: 'line_capacity_costs_stddev_pop_fields';
  capacity_size?: Maybe<Scalars['Float']['output']>;
  charging_size?: Maybe<Scalars['Float']['output']>;
  energy_size?: Maybe<Scalars['Float']['output']>;
  total_upgrade_costs?: Maybe<Scalars['Float']['output']>;
  upgrade_costs?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "line_capacity_costs" */
export type Line_Capacity_Costs_Stddev_Pop_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Line_Capacity_Costs_Stddev_Samp_Fields = {
  __typename?: 'line_capacity_costs_stddev_samp_fields';
  capacity_size?: Maybe<Scalars['Float']['output']>;
  charging_size?: Maybe<Scalars['Float']['output']>;
  energy_size?: Maybe<Scalars['Float']['output']>;
  total_upgrade_costs?: Maybe<Scalars['Float']['output']>;
  upgrade_costs?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "line_capacity_costs" */
export type Line_Capacity_Costs_Stddev_Samp_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "line_capacity_costs" */
export type Line_Capacity_Costs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Line_Capacity_Costs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Line_Capacity_Costs_Stream_Cursor_Value_Input = {
  capacity_size?: InputMaybe<Scalars['numeric']['input']>;
  charging_size?: InputMaybe<Scalars['numeric']['input']>;
  energy_size?: InputMaybe<Scalars['numeric']['input']>;
  includes_pre_existing?: InputMaybe<Scalars['Boolean']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  tap_facility_id?: InputMaybe<Scalars['String']['input']>;
  total_upgrade_costs?: InputMaybe<Scalars['numeric']['input']>;
  upgrade_costs?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Line_Capacity_Costs_Sum_Fields = {
  __typename?: 'line_capacity_costs_sum_fields';
  capacity_size?: Maybe<Scalars['numeric']['output']>;
  charging_size?: Maybe<Scalars['numeric']['output']>;
  energy_size?: Maybe<Scalars['numeric']['output']>;
  total_upgrade_costs?: Maybe<Scalars['numeric']['output']>;
  upgrade_costs?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "line_capacity_costs" */
export type Line_Capacity_Costs_Sum_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** update columns of table "line_capacity_costs" */
export enum Line_Capacity_Costs_Update_Column {
  /** column name */
  CapacitySize = 'capacity_size',
  /** column name */
  ChargingSize = 'charging_size',
  /** column name */
  EnergySize = 'energy_size',
  /** column name */
  IncludesPreExisting = 'includes_pre_existing',
  /** column name */
  Scope = 'scope',
  /** column name */
  ScopeView = 'scope_view',
  /** column name */
  TapFacilityId = 'tap_facility_id',
  /** column name */
  TotalUpgradeCosts = 'total_upgrade_costs',
  /** column name */
  UpgradeCosts = 'upgrade_costs'
}

export type Line_Capacity_Costs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Line_Capacity_Costs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Line_Capacity_Costs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Line_Capacity_Costs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Line_Capacity_Costs_Var_Pop_Fields = {
  __typename?: 'line_capacity_costs_var_pop_fields';
  capacity_size?: Maybe<Scalars['Float']['output']>;
  charging_size?: Maybe<Scalars['Float']['output']>;
  energy_size?: Maybe<Scalars['Float']['output']>;
  total_upgrade_costs?: Maybe<Scalars['Float']['output']>;
  upgrade_costs?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "line_capacity_costs" */
export type Line_Capacity_Costs_Var_Pop_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Line_Capacity_Costs_Var_Samp_Fields = {
  __typename?: 'line_capacity_costs_var_samp_fields';
  capacity_size?: Maybe<Scalars['Float']['output']>;
  charging_size?: Maybe<Scalars['Float']['output']>;
  energy_size?: Maybe<Scalars['Float']['output']>;
  total_upgrade_costs?: Maybe<Scalars['Float']['output']>;
  upgrade_costs?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "line_capacity_costs" */
export type Line_Capacity_Costs_Var_Samp_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Line_Capacity_Costs_Variance_Fields = {
  __typename?: 'line_capacity_costs_variance_fields';
  capacity_size?: Maybe<Scalars['Float']['output']>;
  charging_size?: Maybe<Scalars['Float']['output']>;
  energy_size?: Maybe<Scalars['Float']['output']>;
  total_upgrade_costs?: Maybe<Scalars['Float']['output']>;
  upgrade_costs?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "line_capacity_costs" */
export type Line_Capacity_Costs_Variance_Order_By = {
  capacity_size?: InputMaybe<Order_By>;
  charging_size?: InputMaybe<Order_By>;
  energy_size?: InputMaybe<Order_By>;
  total_upgrade_costs?: InputMaybe<Order_By>;
  upgrade_costs?: InputMaybe<Order_By>;
};

/** Constraint stacks for tapping a line */
export type Line_Constraint_Stacks = {
  __typename?: 'line_constraint_stacks';
  contingency: Scalars['String']['output'];
  dfax: Scalars['numeric']['output'];
  element_type: Scalars['String']['output'];
  estimated_cost?: Maybe<Scalars['numeric']['output']>;
  injection_threshold: Scalars['numeric']['output'];
  is_pre_existing: Scalars['Boolean']['output'];
  line_length?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  model_line?: Maybe<Model_Lines>;
  monitored_facility_id: Scalars['String']['output'];
  monitored_facility_name: Scalars['String']['output'];
  pre_study_load: Scalars['numeric']['output'];
  rating: Scalars['numeric']['output'];
  resource_type: Scalars['String']['output'];
  scope: Scalars['String']['output'];
  scope_view: Scalars['String']['output'];
  tap_facility_id: Scalars['String']['output'];
};

/** aggregated selection of "line_constraint_stacks" */
export type Line_Constraint_Stacks_Aggregate = {
  __typename?: 'line_constraint_stacks_aggregate';
  aggregate?: Maybe<Line_Constraint_Stacks_Aggregate_Fields>;
  nodes: Array<Line_Constraint_Stacks>;
};

export type Line_Constraint_Stacks_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Line_Constraint_Stacks_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Line_Constraint_Stacks_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Line_Constraint_Stacks_Aggregate_Bool_Exp_Count>;
};

export type Line_Constraint_Stacks_Aggregate_Bool_Exp_Bool_And = {
  arguments: Line_Constraint_Stacks_Select_Column_Line_Constraint_Stacks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Line_Constraint_Stacks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Line_Constraint_Stacks_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Line_Constraint_Stacks_Select_Column_Line_Constraint_Stacks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Line_Constraint_Stacks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Line_Constraint_Stacks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Line_Constraint_Stacks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Line_Constraint_Stacks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "line_constraint_stacks" */
export type Line_Constraint_Stacks_Aggregate_Fields = {
  __typename?: 'line_constraint_stacks_aggregate_fields';
  avg?: Maybe<Line_Constraint_Stacks_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Line_Constraint_Stacks_Max_Fields>;
  min?: Maybe<Line_Constraint_Stacks_Min_Fields>;
  stddev?: Maybe<Line_Constraint_Stacks_Stddev_Fields>;
  stddev_pop?: Maybe<Line_Constraint_Stacks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Line_Constraint_Stacks_Stddev_Samp_Fields>;
  sum?: Maybe<Line_Constraint_Stacks_Sum_Fields>;
  var_pop?: Maybe<Line_Constraint_Stacks_Var_Pop_Fields>;
  var_samp?: Maybe<Line_Constraint_Stacks_Var_Samp_Fields>;
  variance?: Maybe<Line_Constraint_Stacks_Variance_Fields>;
};


/** aggregate fields of "line_constraint_stacks" */
export type Line_Constraint_Stacks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Line_Constraint_Stacks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Aggregate_Order_By = {
  avg?: InputMaybe<Line_Constraint_Stacks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Line_Constraint_Stacks_Max_Order_By>;
  min?: InputMaybe<Line_Constraint_Stacks_Min_Order_By>;
  stddev?: InputMaybe<Line_Constraint_Stacks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Line_Constraint_Stacks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Line_Constraint_Stacks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Line_Constraint_Stacks_Sum_Order_By>;
  var_pop?: InputMaybe<Line_Constraint_Stacks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Line_Constraint_Stacks_Var_Samp_Order_By>;
  variance?: InputMaybe<Line_Constraint_Stacks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Arr_Rel_Insert_Input = {
  data: Array<Line_Constraint_Stacks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Line_Constraint_Stacks_On_Conflict>;
};

/** aggregate avg on columns */
export type Line_Constraint_Stacks_Avg_Fields = {
  __typename?: 'line_constraint_stacks_avg_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  estimated_cost?: Maybe<Scalars['Float']['output']>;
  injection_threshold?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Avg_Order_By = {
  dfax?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "line_constraint_stacks". All fields are combined with a logical 'AND'. */
export type Line_Constraint_Stacks_Bool_Exp = {
  _and?: InputMaybe<Array<Line_Constraint_Stacks_Bool_Exp>>;
  _not?: InputMaybe<Line_Constraint_Stacks_Bool_Exp>;
  _or?: InputMaybe<Array<Line_Constraint_Stacks_Bool_Exp>>;
  contingency?: InputMaybe<String_Comparison_Exp>;
  dfax?: InputMaybe<Numeric_Comparison_Exp>;
  element_type?: InputMaybe<String_Comparison_Exp>;
  estimated_cost?: InputMaybe<Numeric_Comparison_Exp>;
  injection_threshold?: InputMaybe<Numeric_Comparison_Exp>;
  is_pre_existing?: InputMaybe<Boolean_Comparison_Exp>;
  line_length?: InputMaybe<Numeric_Comparison_Exp>;
  model_line?: InputMaybe<Model_Lines_Bool_Exp>;
  monitored_facility_id?: InputMaybe<String_Comparison_Exp>;
  monitored_facility_name?: InputMaybe<String_Comparison_Exp>;
  pre_study_load?: InputMaybe<Numeric_Comparison_Exp>;
  rating?: InputMaybe<Numeric_Comparison_Exp>;
  resource_type?: InputMaybe<String_Comparison_Exp>;
  scope?: InputMaybe<String_Comparison_Exp>;
  scope_view?: InputMaybe<String_Comparison_Exp>;
  tap_facility_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "line_constraint_stacks" */
export enum Line_Constraint_Stacks_Constraint {
  /** unique or primary key constraint on columns "scope", "tap_facility_id", "scope_view", "resource_type", "monitored_facility_id" */
  LineConstraintStacksPkey = 'line_constraint_stacks_pkey'
}

/** input type for incrementing numeric columns in table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Inc_Input = {
  dfax?: InputMaybe<Scalars['numeric']['input']>;
  estimated_cost?: InputMaybe<Scalars['numeric']['input']>;
  injection_threshold?: InputMaybe<Scalars['numeric']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  pre_study_load?: InputMaybe<Scalars['numeric']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Insert_Input = {
  contingency?: InputMaybe<Scalars['String']['input']>;
  dfax?: InputMaybe<Scalars['numeric']['input']>;
  element_type?: InputMaybe<Scalars['String']['input']>;
  estimated_cost?: InputMaybe<Scalars['numeric']['input']>;
  injection_threshold?: InputMaybe<Scalars['numeric']['input']>;
  is_pre_existing?: InputMaybe<Scalars['Boolean']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  model_line?: InputMaybe<Model_Lines_Obj_Rel_Insert_Input>;
  monitored_facility_id?: InputMaybe<Scalars['String']['input']>;
  monitored_facility_name?: InputMaybe<Scalars['String']['input']>;
  pre_study_load?: InputMaybe<Scalars['numeric']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
  resource_type?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  tap_facility_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Line_Constraint_Stacks_Max_Fields = {
  __typename?: 'line_constraint_stacks_max_fields';
  contingency?: Maybe<Scalars['String']['output']>;
  dfax?: Maybe<Scalars['numeric']['output']>;
  element_type?: Maybe<Scalars['String']['output']>;
  estimated_cost?: Maybe<Scalars['numeric']['output']>;
  injection_threshold?: Maybe<Scalars['numeric']['output']>;
  line_length?: Maybe<Scalars['numeric']['output']>;
  monitored_facility_id?: Maybe<Scalars['String']['output']>;
  monitored_facility_name?: Maybe<Scalars['String']['output']>;
  pre_study_load?: Maybe<Scalars['numeric']['output']>;
  rating?: Maybe<Scalars['numeric']['output']>;
  resource_type?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  scope_view?: Maybe<Scalars['String']['output']>;
  tap_facility_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Max_Order_By = {
  contingency?: InputMaybe<Order_By>;
  dfax?: InputMaybe<Order_By>;
  element_type?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  monitored_facility_id?: InputMaybe<Order_By>;
  monitored_facility_name?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  resource_type?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  scope_view?: InputMaybe<Order_By>;
  tap_facility_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Line_Constraint_Stacks_Min_Fields = {
  __typename?: 'line_constraint_stacks_min_fields';
  contingency?: Maybe<Scalars['String']['output']>;
  dfax?: Maybe<Scalars['numeric']['output']>;
  element_type?: Maybe<Scalars['String']['output']>;
  estimated_cost?: Maybe<Scalars['numeric']['output']>;
  injection_threshold?: Maybe<Scalars['numeric']['output']>;
  line_length?: Maybe<Scalars['numeric']['output']>;
  monitored_facility_id?: Maybe<Scalars['String']['output']>;
  monitored_facility_name?: Maybe<Scalars['String']['output']>;
  pre_study_load?: Maybe<Scalars['numeric']['output']>;
  rating?: Maybe<Scalars['numeric']['output']>;
  resource_type?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  scope_view?: Maybe<Scalars['String']['output']>;
  tap_facility_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Min_Order_By = {
  contingency?: InputMaybe<Order_By>;
  dfax?: InputMaybe<Order_By>;
  element_type?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  monitored_facility_id?: InputMaybe<Order_By>;
  monitored_facility_name?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  resource_type?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  scope_view?: InputMaybe<Order_By>;
  tap_facility_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Mutation_Response = {
  __typename?: 'line_constraint_stacks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Line_Constraint_Stacks>;
};

/** on_conflict condition type for table "line_constraint_stacks" */
export type Line_Constraint_Stacks_On_Conflict = {
  constraint: Line_Constraint_Stacks_Constraint;
  update_columns?: Array<Line_Constraint_Stacks_Update_Column>;
  where?: InputMaybe<Line_Constraint_Stacks_Bool_Exp>;
};

/** Ordering options when selecting data from "line_constraint_stacks". */
export type Line_Constraint_Stacks_Order_By = {
  contingency?: InputMaybe<Order_By>;
  dfax?: InputMaybe<Order_By>;
  element_type?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  is_pre_existing?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  model_line?: InputMaybe<Model_Lines_Order_By>;
  monitored_facility_id?: InputMaybe<Order_By>;
  monitored_facility_name?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  resource_type?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  scope_view?: InputMaybe<Order_By>;
  tap_facility_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: line_constraint_stacks */
export type Line_Constraint_Stacks_Pk_Columns_Input = {
  monitored_facility_id: Scalars['String']['input'];
  resource_type: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
  tap_facility_id: Scalars['String']['input'];
};

/** select columns of table "line_constraint_stacks" */
export enum Line_Constraint_Stacks_Select_Column {
  /** column name */
  Contingency = 'contingency',
  /** column name */
  Dfax = 'dfax',
  /** column name */
  ElementType = 'element_type',
  /** column name */
  EstimatedCost = 'estimated_cost',
  /** column name */
  InjectionThreshold = 'injection_threshold',
  /** column name */
  IsPreExisting = 'is_pre_existing',
  /** column name */
  LineLength = 'line_length',
  /** column name */
  MonitoredFacilityId = 'monitored_facility_id',
  /** column name */
  MonitoredFacilityName = 'monitored_facility_name',
  /** column name */
  PreStudyLoad = 'pre_study_load',
  /** column name */
  Rating = 'rating',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  Scope = 'scope',
  /** column name */
  ScopeView = 'scope_view',
  /** column name */
  TapFacilityId = 'tap_facility_id'
}

/** select "line_constraint_stacks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "line_constraint_stacks" */
export enum Line_Constraint_Stacks_Select_Column_Line_Constraint_Stacks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPreExisting = 'is_pre_existing'
}

/** select "line_constraint_stacks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "line_constraint_stacks" */
export enum Line_Constraint_Stacks_Select_Column_Line_Constraint_Stacks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPreExisting = 'is_pre_existing'
}

/** input type for updating data in table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Set_Input = {
  contingency?: InputMaybe<Scalars['String']['input']>;
  dfax?: InputMaybe<Scalars['numeric']['input']>;
  element_type?: InputMaybe<Scalars['String']['input']>;
  estimated_cost?: InputMaybe<Scalars['numeric']['input']>;
  injection_threshold?: InputMaybe<Scalars['numeric']['input']>;
  is_pre_existing?: InputMaybe<Scalars['Boolean']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  monitored_facility_id?: InputMaybe<Scalars['String']['input']>;
  monitored_facility_name?: InputMaybe<Scalars['String']['input']>;
  pre_study_load?: InputMaybe<Scalars['numeric']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
  resource_type?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  tap_facility_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Line_Constraint_Stacks_Stddev_Fields = {
  __typename?: 'line_constraint_stacks_stddev_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  estimated_cost?: Maybe<Scalars['Float']['output']>;
  injection_threshold?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Stddev_Order_By = {
  dfax?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Line_Constraint_Stacks_Stddev_Pop_Fields = {
  __typename?: 'line_constraint_stacks_stddev_pop_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  estimated_cost?: Maybe<Scalars['Float']['output']>;
  injection_threshold?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Stddev_Pop_Order_By = {
  dfax?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Line_Constraint_Stacks_Stddev_Samp_Fields = {
  __typename?: 'line_constraint_stacks_stddev_samp_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  estimated_cost?: Maybe<Scalars['Float']['output']>;
  injection_threshold?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Stddev_Samp_Order_By = {
  dfax?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Line_Constraint_Stacks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Line_Constraint_Stacks_Stream_Cursor_Value_Input = {
  contingency?: InputMaybe<Scalars['String']['input']>;
  dfax?: InputMaybe<Scalars['numeric']['input']>;
  element_type?: InputMaybe<Scalars['String']['input']>;
  estimated_cost?: InputMaybe<Scalars['numeric']['input']>;
  injection_threshold?: InputMaybe<Scalars['numeric']['input']>;
  is_pre_existing?: InputMaybe<Scalars['Boolean']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  monitored_facility_id?: InputMaybe<Scalars['String']['input']>;
  monitored_facility_name?: InputMaybe<Scalars['String']['input']>;
  pre_study_load?: InputMaybe<Scalars['numeric']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
  resource_type?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  tap_facility_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Line_Constraint_Stacks_Sum_Fields = {
  __typename?: 'line_constraint_stacks_sum_fields';
  dfax?: Maybe<Scalars['numeric']['output']>;
  estimated_cost?: Maybe<Scalars['numeric']['output']>;
  injection_threshold?: Maybe<Scalars['numeric']['output']>;
  line_length?: Maybe<Scalars['numeric']['output']>;
  pre_study_load?: Maybe<Scalars['numeric']['output']>;
  rating?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Sum_Order_By = {
  dfax?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** update columns of table "line_constraint_stacks" */
export enum Line_Constraint_Stacks_Update_Column {
  /** column name */
  Contingency = 'contingency',
  /** column name */
  Dfax = 'dfax',
  /** column name */
  ElementType = 'element_type',
  /** column name */
  EstimatedCost = 'estimated_cost',
  /** column name */
  InjectionThreshold = 'injection_threshold',
  /** column name */
  IsPreExisting = 'is_pre_existing',
  /** column name */
  LineLength = 'line_length',
  /** column name */
  MonitoredFacilityId = 'monitored_facility_id',
  /** column name */
  MonitoredFacilityName = 'monitored_facility_name',
  /** column name */
  PreStudyLoad = 'pre_study_load',
  /** column name */
  Rating = 'rating',
  /** column name */
  ResourceType = 'resource_type',
  /** column name */
  Scope = 'scope',
  /** column name */
  ScopeView = 'scope_view',
  /** column name */
  TapFacilityId = 'tap_facility_id'
}

export type Line_Constraint_Stacks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Line_Constraint_Stacks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Line_Constraint_Stacks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Line_Constraint_Stacks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Line_Constraint_Stacks_Var_Pop_Fields = {
  __typename?: 'line_constraint_stacks_var_pop_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  estimated_cost?: Maybe<Scalars['Float']['output']>;
  injection_threshold?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Var_Pop_Order_By = {
  dfax?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Line_Constraint_Stacks_Var_Samp_Fields = {
  __typename?: 'line_constraint_stacks_var_samp_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  estimated_cost?: Maybe<Scalars['Float']['output']>;
  injection_threshold?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Var_Samp_Order_By = {
  dfax?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Line_Constraint_Stacks_Variance_Fields = {
  __typename?: 'line_constraint_stacks_variance_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  estimated_cost?: Maybe<Scalars['Float']['output']>;
  injection_threshold?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "line_constraint_stacks" */
export type Line_Constraint_Stacks_Variance_Order_By = {
  dfax?: InputMaybe<Order_By>;
  estimated_cost?: InputMaybe<Order_By>;
  injection_threshold?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** Lines from model branch data for each scope and scope view */
export type Model_Lines = {
  __typename?: 'model_lines';
  circuit_id: Scalars['String']['output'];
  facility_id: Scalars['String']['output'];
  /** An object relationship */
  from_bus: Buses;
  from_bus_id: Scalars['String']['output'];
  /** An array relationship */
  line_capacity_costs: Array<Line_Capacity_Costs>;
  /** An aggregate relationship */
  line_capacity_costs_aggregate: Line_Capacity_Costs_Aggregate;
  /** An array relationship */
  line_constraint_stacks: Array<Line_Constraint_Stacks>;
  /** An aggregate relationship */
  line_constraint_stacks_aggregate: Line_Constraint_Stacks_Aggregate;
  line_length: Scalars['numeric']['output'];
  rating: Scalars['numeric']['output'];
  scope: Scalars['String']['output'];
  scope_view: Scalars['String']['output'];
  /** An object relationship */
  to_bus: Buses;
  to_bus_id: Scalars['String']['output'];
  voltage: Scalars['numeric']['output'];
};


/** Lines from model branch data for each scope and scope view */
export type Model_LinesLine_Capacity_CostsArgs = {
  distinct_on?: InputMaybe<Array<Line_Capacity_Costs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Line_Capacity_Costs_Order_By>>;
  where?: InputMaybe<Line_Capacity_Costs_Bool_Exp>;
};


/** Lines from model branch data for each scope and scope view */
export type Model_LinesLine_Capacity_Costs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Line_Capacity_Costs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Line_Capacity_Costs_Order_By>>;
  where?: InputMaybe<Line_Capacity_Costs_Bool_Exp>;
};


/** Lines from model branch data for each scope and scope view */
export type Model_LinesLine_Constraint_StacksArgs = {
  distinct_on?: InputMaybe<Array<Line_Constraint_Stacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Line_Constraint_Stacks_Order_By>>;
  where?: InputMaybe<Line_Constraint_Stacks_Bool_Exp>;
};


/** Lines from model branch data for each scope and scope view */
export type Model_LinesLine_Constraint_Stacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Line_Constraint_Stacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Line_Constraint_Stacks_Order_By>>;
  where?: InputMaybe<Line_Constraint_Stacks_Bool_Exp>;
};

/** aggregated selection of "model_lines" */
export type Model_Lines_Aggregate = {
  __typename?: 'model_lines_aggregate';
  aggregate?: Maybe<Model_Lines_Aggregate_Fields>;
  nodes: Array<Model_Lines>;
};

/** aggregate fields of "model_lines" */
export type Model_Lines_Aggregate_Fields = {
  __typename?: 'model_lines_aggregate_fields';
  avg?: Maybe<Model_Lines_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Model_Lines_Max_Fields>;
  min?: Maybe<Model_Lines_Min_Fields>;
  stddev?: Maybe<Model_Lines_Stddev_Fields>;
  stddev_pop?: Maybe<Model_Lines_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Model_Lines_Stddev_Samp_Fields>;
  sum?: Maybe<Model_Lines_Sum_Fields>;
  var_pop?: Maybe<Model_Lines_Var_Pop_Fields>;
  var_samp?: Maybe<Model_Lines_Var_Samp_Fields>;
  variance?: Maybe<Model_Lines_Variance_Fields>;
};


/** aggregate fields of "model_lines" */
export type Model_Lines_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Model_Lines_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Model_Lines_Avg_Fields = {
  __typename?: 'model_lines_avg_fields';
  line_length?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "model_lines". All fields are combined with a logical 'AND'. */
export type Model_Lines_Bool_Exp = {
  _and?: InputMaybe<Array<Model_Lines_Bool_Exp>>;
  _not?: InputMaybe<Model_Lines_Bool_Exp>;
  _or?: InputMaybe<Array<Model_Lines_Bool_Exp>>;
  circuit_id?: InputMaybe<String_Comparison_Exp>;
  facility_id?: InputMaybe<String_Comparison_Exp>;
  from_bus?: InputMaybe<Buses_Bool_Exp>;
  from_bus_id?: InputMaybe<String_Comparison_Exp>;
  line_capacity_costs?: InputMaybe<Line_Capacity_Costs_Bool_Exp>;
  line_capacity_costs_aggregate?: InputMaybe<Line_Capacity_Costs_Aggregate_Bool_Exp>;
  line_constraint_stacks?: InputMaybe<Line_Constraint_Stacks_Bool_Exp>;
  line_constraint_stacks_aggregate?: InputMaybe<Line_Constraint_Stacks_Aggregate_Bool_Exp>;
  line_length?: InputMaybe<Numeric_Comparison_Exp>;
  rating?: InputMaybe<Numeric_Comparison_Exp>;
  scope?: InputMaybe<String_Comparison_Exp>;
  scope_view?: InputMaybe<String_Comparison_Exp>;
  to_bus?: InputMaybe<Buses_Bool_Exp>;
  to_bus_id?: InputMaybe<String_Comparison_Exp>;
  voltage?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "model_lines" */
export enum Model_Lines_Constraint {
  /** unique or primary key constraint on columns "scope", "facility_id", "scope_view" */
  ModelLinesPkey = 'model_lines_pkey'
}

/** input type for incrementing numeric columns in table "model_lines" */
export type Model_Lines_Inc_Input = {
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
  voltage?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "model_lines" */
export type Model_Lines_Insert_Input = {
  circuit_id?: InputMaybe<Scalars['String']['input']>;
  facility_id?: InputMaybe<Scalars['String']['input']>;
  from_bus?: InputMaybe<Buses_Obj_Rel_Insert_Input>;
  from_bus_id?: InputMaybe<Scalars['String']['input']>;
  line_capacity_costs?: InputMaybe<Line_Capacity_Costs_Arr_Rel_Insert_Input>;
  line_constraint_stacks?: InputMaybe<Line_Constraint_Stacks_Arr_Rel_Insert_Input>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  to_bus?: InputMaybe<Buses_Obj_Rel_Insert_Input>;
  to_bus_id?: InputMaybe<Scalars['String']['input']>;
  voltage?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Model_Lines_Max_Fields = {
  __typename?: 'model_lines_max_fields';
  circuit_id?: Maybe<Scalars['String']['output']>;
  facility_id?: Maybe<Scalars['String']['output']>;
  from_bus_id?: Maybe<Scalars['String']['output']>;
  line_length?: Maybe<Scalars['numeric']['output']>;
  rating?: Maybe<Scalars['numeric']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  scope_view?: Maybe<Scalars['String']['output']>;
  to_bus_id?: Maybe<Scalars['String']['output']>;
  voltage?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type Model_Lines_Min_Fields = {
  __typename?: 'model_lines_min_fields';
  circuit_id?: Maybe<Scalars['String']['output']>;
  facility_id?: Maybe<Scalars['String']['output']>;
  from_bus_id?: Maybe<Scalars['String']['output']>;
  line_length?: Maybe<Scalars['numeric']['output']>;
  rating?: Maybe<Scalars['numeric']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  scope_view?: Maybe<Scalars['String']['output']>;
  to_bus_id?: Maybe<Scalars['String']['output']>;
  voltage?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "model_lines" */
export type Model_Lines_Mutation_Response = {
  __typename?: 'model_lines_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Model_Lines>;
};

/** input type for inserting object relation for remote table "model_lines" */
export type Model_Lines_Obj_Rel_Insert_Input = {
  data: Model_Lines_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Model_Lines_On_Conflict>;
};

/** on_conflict condition type for table "model_lines" */
export type Model_Lines_On_Conflict = {
  constraint: Model_Lines_Constraint;
  update_columns?: Array<Model_Lines_Update_Column>;
  where?: InputMaybe<Model_Lines_Bool_Exp>;
};

/** Ordering options when selecting data from "model_lines". */
export type Model_Lines_Order_By = {
  circuit_id?: InputMaybe<Order_By>;
  facility_id?: InputMaybe<Order_By>;
  from_bus?: InputMaybe<Buses_Order_By>;
  from_bus_id?: InputMaybe<Order_By>;
  line_capacity_costs_aggregate?: InputMaybe<Line_Capacity_Costs_Aggregate_Order_By>;
  line_constraint_stacks_aggregate?: InputMaybe<Line_Constraint_Stacks_Aggregate_Order_By>;
  line_length?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  scope_view?: InputMaybe<Order_By>;
  to_bus?: InputMaybe<Buses_Order_By>;
  to_bus_id?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
};

/** primary key columns input for table: model_lines */
export type Model_Lines_Pk_Columns_Input = {
  facility_id: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
};

/** select columns of table "model_lines" */
export enum Model_Lines_Select_Column {
  /** column name */
  CircuitId = 'circuit_id',
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  FromBusId = 'from_bus_id',
  /** column name */
  LineLength = 'line_length',
  /** column name */
  Rating = 'rating',
  /** column name */
  Scope = 'scope',
  /** column name */
  ScopeView = 'scope_view',
  /** column name */
  ToBusId = 'to_bus_id',
  /** column name */
  Voltage = 'voltage'
}

/** input type for updating data in table "model_lines" */
export type Model_Lines_Set_Input = {
  circuit_id?: InputMaybe<Scalars['String']['input']>;
  facility_id?: InputMaybe<Scalars['String']['input']>;
  from_bus_id?: InputMaybe<Scalars['String']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  to_bus_id?: InputMaybe<Scalars['String']['input']>;
  voltage?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Model_Lines_Stddev_Fields = {
  __typename?: 'model_lines_stddev_fields';
  line_length?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Model_Lines_Stddev_Pop_Fields = {
  __typename?: 'model_lines_stddev_pop_fields';
  line_length?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Model_Lines_Stddev_Samp_Fields = {
  __typename?: 'model_lines_stddev_samp_fields';
  line_length?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "model_lines" */
export type Model_Lines_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Model_Lines_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Model_Lines_Stream_Cursor_Value_Input = {
  circuit_id?: InputMaybe<Scalars['String']['input']>;
  facility_id?: InputMaybe<Scalars['String']['input']>;
  from_bus_id?: InputMaybe<Scalars['String']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  to_bus_id?: InputMaybe<Scalars['String']['input']>;
  voltage?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Model_Lines_Sum_Fields = {
  __typename?: 'model_lines_sum_fields';
  line_length?: Maybe<Scalars['numeric']['output']>;
  rating?: Maybe<Scalars['numeric']['output']>;
  voltage?: Maybe<Scalars['numeric']['output']>;
};

/** Same as model_lines but instead of to_bus we have missing_bus */
export type Model_Lines_To_Missing_Buses = {
  __typename?: 'model_lines_to_missing_buses';
  circuit_id: Scalars['String']['output'];
  facility_id: Scalars['String']['output'];
  /** An object relationship */
  from_bus: Buses;
  from_bus_id: Scalars['String']['output'];
  line_length: Scalars['numeric']['output'];
  missing_bus_id: Scalars['String']['output'];
  queued_projects_at_missing_bus?: Maybe<Scalars['String']['output']>;
  rating: Scalars['numeric']['output'];
  scope: Scalars['String']['output'];
  scope_view: Scalars['String']['output'];
  voltage: Scalars['numeric']['output'];
};

/** aggregated selection of "model_lines_to_missing_buses" */
export type Model_Lines_To_Missing_Buses_Aggregate = {
  __typename?: 'model_lines_to_missing_buses_aggregate';
  aggregate?: Maybe<Model_Lines_To_Missing_Buses_Aggregate_Fields>;
  nodes: Array<Model_Lines_To_Missing_Buses>;
};

/** aggregate fields of "model_lines_to_missing_buses" */
export type Model_Lines_To_Missing_Buses_Aggregate_Fields = {
  __typename?: 'model_lines_to_missing_buses_aggregate_fields';
  avg?: Maybe<Model_Lines_To_Missing_Buses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Model_Lines_To_Missing_Buses_Max_Fields>;
  min?: Maybe<Model_Lines_To_Missing_Buses_Min_Fields>;
  stddev?: Maybe<Model_Lines_To_Missing_Buses_Stddev_Fields>;
  stddev_pop?: Maybe<Model_Lines_To_Missing_Buses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Model_Lines_To_Missing_Buses_Stddev_Samp_Fields>;
  sum?: Maybe<Model_Lines_To_Missing_Buses_Sum_Fields>;
  var_pop?: Maybe<Model_Lines_To_Missing_Buses_Var_Pop_Fields>;
  var_samp?: Maybe<Model_Lines_To_Missing_Buses_Var_Samp_Fields>;
  variance?: Maybe<Model_Lines_To_Missing_Buses_Variance_Fields>;
};


/** aggregate fields of "model_lines_to_missing_buses" */
export type Model_Lines_To_Missing_Buses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Model_Lines_To_Missing_Buses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Model_Lines_To_Missing_Buses_Avg_Fields = {
  __typename?: 'model_lines_to_missing_buses_avg_fields';
  line_length?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "model_lines_to_missing_buses". All fields are combined with a logical 'AND'. */
export type Model_Lines_To_Missing_Buses_Bool_Exp = {
  _and?: InputMaybe<Array<Model_Lines_To_Missing_Buses_Bool_Exp>>;
  _not?: InputMaybe<Model_Lines_To_Missing_Buses_Bool_Exp>;
  _or?: InputMaybe<Array<Model_Lines_To_Missing_Buses_Bool_Exp>>;
  circuit_id?: InputMaybe<String_Comparison_Exp>;
  facility_id?: InputMaybe<String_Comparison_Exp>;
  from_bus?: InputMaybe<Buses_Bool_Exp>;
  from_bus_id?: InputMaybe<String_Comparison_Exp>;
  line_length?: InputMaybe<Numeric_Comparison_Exp>;
  missing_bus_id?: InputMaybe<String_Comparison_Exp>;
  queued_projects_at_missing_bus?: InputMaybe<String_Comparison_Exp>;
  rating?: InputMaybe<Numeric_Comparison_Exp>;
  scope?: InputMaybe<String_Comparison_Exp>;
  scope_view?: InputMaybe<String_Comparison_Exp>;
  voltage?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "model_lines_to_missing_buses" */
export enum Model_Lines_To_Missing_Buses_Constraint {
  /** unique or primary key constraint on columns "scope", "facility_id", "scope_view" */
  ModelLinesToMissingBusesPkey = 'model_lines_to_missing_buses_pkey'
}

/** input type for incrementing numeric columns in table "model_lines_to_missing_buses" */
export type Model_Lines_To_Missing_Buses_Inc_Input = {
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
  voltage?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "model_lines_to_missing_buses" */
export type Model_Lines_To_Missing_Buses_Insert_Input = {
  circuit_id?: InputMaybe<Scalars['String']['input']>;
  facility_id?: InputMaybe<Scalars['String']['input']>;
  from_bus?: InputMaybe<Buses_Obj_Rel_Insert_Input>;
  from_bus_id?: InputMaybe<Scalars['String']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  missing_bus_id?: InputMaybe<Scalars['String']['input']>;
  queued_projects_at_missing_bus?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  voltage?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Model_Lines_To_Missing_Buses_Max_Fields = {
  __typename?: 'model_lines_to_missing_buses_max_fields';
  circuit_id?: Maybe<Scalars['String']['output']>;
  facility_id?: Maybe<Scalars['String']['output']>;
  from_bus_id?: Maybe<Scalars['String']['output']>;
  line_length?: Maybe<Scalars['numeric']['output']>;
  missing_bus_id?: Maybe<Scalars['String']['output']>;
  queued_projects_at_missing_bus?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['numeric']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  scope_view?: Maybe<Scalars['String']['output']>;
  voltage?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type Model_Lines_To_Missing_Buses_Min_Fields = {
  __typename?: 'model_lines_to_missing_buses_min_fields';
  circuit_id?: Maybe<Scalars['String']['output']>;
  facility_id?: Maybe<Scalars['String']['output']>;
  from_bus_id?: Maybe<Scalars['String']['output']>;
  line_length?: Maybe<Scalars['numeric']['output']>;
  missing_bus_id?: Maybe<Scalars['String']['output']>;
  queued_projects_at_missing_bus?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['numeric']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  scope_view?: Maybe<Scalars['String']['output']>;
  voltage?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "model_lines_to_missing_buses" */
export type Model_Lines_To_Missing_Buses_Mutation_Response = {
  __typename?: 'model_lines_to_missing_buses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Model_Lines_To_Missing_Buses>;
};

/** on_conflict condition type for table "model_lines_to_missing_buses" */
export type Model_Lines_To_Missing_Buses_On_Conflict = {
  constraint: Model_Lines_To_Missing_Buses_Constraint;
  update_columns?: Array<Model_Lines_To_Missing_Buses_Update_Column>;
  where?: InputMaybe<Model_Lines_To_Missing_Buses_Bool_Exp>;
};

/** Ordering options when selecting data from "model_lines_to_missing_buses". */
export type Model_Lines_To_Missing_Buses_Order_By = {
  circuit_id?: InputMaybe<Order_By>;
  facility_id?: InputMaybe<Order_By>;
  from_bus?: InputMaybe<Buses_Order_By>;
  from_bus_id?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  missing_bus_id?: InputMaybe<Order_By>;
  queued_projects_at_missing_bus?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  scope_view?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
};

/** primary key columns input for table: model_lines_to_missing_buses */
export type Model_Lines_To_Missing_Buses_Pk_Columns_Input = {
  facility_id: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
};

/** select columns of table "model_lines_to_missing_buses" */
export enum Model_Lines_To_Missing_Buses_Select_Column {
  /** column name */
  CircuitId = 'circuit_id',
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  FromBusId = 'from_bus_id',
  /** column name */
  LineLength = 'line_length',
  /** column name */
  MissingBusId = 'missing_bus_id',
  /** column name */
  QueuedProjectsAtMissingBus = 'queued_projects_at_missing_bus',
  /** column name */
  Rating = 'rating',
  /** column name */
  Scope = 'scope',
  /** column name */
  ScopeView = 'scope_view',
  /** column name */
  Voltage = 'voltage'
}

/** input type for updating data in table "model_lines_to_missing_buses" */
export type Model_Lines_To_Missing_Buses_Set_Input = {
  circuit_id?: InputMaybe<Scalars['String']['input']>;
  facility_id?: InputMaybe<Scalars['String']['input']>;
  from_bus_id?: InputMaybe<Scalars['String']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  missing_bus_id?: InputMaybe<Scalars['String']['input']>;
  queued_projects_at_missing_bus?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  voltage?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Model_Lines_To_Missing_Buses_Stddev_Fields = {
  __typename?: 'model_lines_to_missing_buses_stddev_fields';
  line_length?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Model_Lines_To_Missing_Buses_Stddev_Pop_Fields = {
  __typename?: 'model_lines_to_missing_buses_stddev_pop_fields';
  line_length?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Model_Lines_To_Missing_Buses_Stddev_Samp_Fields = {
  __typename?: 'model_lines_to_missing_buses_stddev_samp_fields';
  line_length?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "model_lines_to_missing_buses" */
export type Model_Lines_To_Missing_Buses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Model_Lines_To_Missing_Buses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Model_Lines_To_Missing_Buses_Stream_Cursor_Value_Input = {
  circuit_id?: InputMaybe<Scalars['String']['input']>;
  facility_id?: InputMaybe<Scalars['String']['input']>;
  from_bus_id?: InputMaybe<Scalars['String']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  missing_bus_id?: InputMaybe<Scalars['String']['input']>;
  queued_projects_at_missing_bus?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  scope_view?: InputMaybe<Scalars['String']['input']>;
  voltage?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Model_Lines_To_Missing_Buses_Sum_Fields = {
  __typename?: 'model_lines_to_missing_buses_sum_fields';
  line_length?: Maybe<Scalars['numeric']['output']>;
  rating?: Maybe<Scalars['numeric']['output']>;
  voltage?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "model_lines_to_missing_buses" */
export enum Model_Lines_To_Missing_Buses_Update_Column {
  /** column name */
  CircuitId = 'circuit_id',
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  FromBusId = 'from_bus_id',
  /** column name */
  LineLength = 'line_length',
  /** column name */
  MissingBusId = 'missing_bus_id',
  /** column name */
  QueuedProjectsAtMissingBus = 'queued_projects_at_missing_bus',
  /** column name */
  Rating = 'rating',
  /** column name */
  Scope = 'scope',
  /** column name */
  ScopeView = 'scope_view',
  /** column name */
  Voltage = 'voltage'
}

export type Model_Lines_To_Missing_Buses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Model_Lines_To_Missing_Buses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Model_Lines_To_Missing_Buses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Model_Lines_To_Missing_Buses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Model_Lines_To_Missing_Buses_Var_Pop_Fields = {
  __typename?: 'model_lines_to_missing_buses_var_pop_fields';
  line_length?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Model_Lines_To_Missing_Buses_Var_Samp_Fields = {
  __typename?: 'model_lines_to_missing_buses_var_samp_fields';
  line_length?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Model_Lines_To_Missing_Buses_Variance_Fields = {
  __typename?: 'model_lines_to_missing_buses_variance_fields';
  line_length?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** update columns of table "model_lines" */
export enum Model_Lines_Update_Column {
  /** column name */
  CircuitId = 'circuit_id',
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  FromBusId = 'from_bus_id',
  /** column name */
  LineLength = 'line_length',
  /** column name */
  Rating = 'rating',
  /** column name */
  Scope = 'scope',
  /** column name */
  ScopeView = 'scope_view',
  /** column name */
  ToBusId = 'to_bus_id',
  /** column name */
  Voltage = 'voltage'
}

export type Model_Lines_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Model_Lines_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Model_Lines_Set_Input>;
  /** filter the rows which have to be updated */
  where: Model_Lines_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Model_Lines_Var_Pop_Fields = {
  __typename?: 'model_lines_var_pop_fields';
  line_length?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Model_Lines_Var_Samp_Fields = {
  __typename?: 'model_lines_var_samp_fields';
  line_length?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Model_Lines_Variance_Fields = {
  __typename?: 'model_lines_variance_fields';
  line_length?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  voltage?: Maybe<Scalars['Float']['output']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "all_projects" */
  delete_all_projects?: Maybe<All_Projects_Mutation_Response>;
  /** delete single row from the table: "all_projects" */
  delete_all_projects_by_pk?: Maybe<All_Projects>;
  /** delete data from the table: "branches" */
  delete_branches?: Maybe<Branches_Mutation_Response>;
  /** delete single row from the table: "branches" */
  delete_branches_by_pk?: Maybe<Branches>;
  /** delete data from the table: "buses" */
  delete_buses?: Maybe<Buses_Mutation_Response>;
  /** delete single row from the table: "buses" */
  delete_buses_by_pk?: Maybe<Buses>;
  /** delete data from the table: "capacity_costs" */
  delete_capacity_costs?: Maybe<Capacity_Costs_Mutation_Response>;
  /** delete single row from the table: "capacity_costs" */
  delete_capacity_costs_by_pk?: Maybe<Capacity_Costs>;
  /** delete data from the table: "constraint_stacks" */
  delete_constraint_stacks?: Maybe<Constraint_Stacks_Mutation_Response>;
  /** delete single row from the table: "constraint_stacks" */
  delete_constraint_stacks_by_pk?: Maybe<Constraint_Stacks>;
  /** delete data from the table: "constraint_types" */
  delete_constraint_types?: Maybe<Constraint_Types_Mutation_Response>;
  /** delete single row from the table: "constraint_types" */
  delete_constraint_types_by_pk?: Maybe<Constraint_Types>;
  /** delete data from the table: "in_queue_subscriptions" */
  delete_in_queue_subscriptions?: Maybe<In_Queue_Subscriptions_Mutation_Response>;
  /** delete single row from the table: "in_queue_subscriptions" */
  delete_in_queue_subscriptions_by_pk?: Maybe<In_Queue_Subscriptions>;
  /** delete data from the table: "line_capacity_costs" */
  delete_line_capacity_costs?: Maybe<Line_Capacity_Costs_Mutation_Response>;
  /** delete single row from the table: "line_capacity_costs" */
  delete_line_capacity_costs_by_pk?: Maybe<Line_Capacity_Costs>;
  /** delete data from the table: "line_constraint_stacks" */
  delete_line_constraint_stacks?: Maybe<Line_Constraint_Stacks_Mutation_Response>;
  /** delete single row from the table: "line_constraint_stacks" */
  delete_line_constraint_stacks_by_pk?: Maybe<Line_Constraint_Stacks>;
  /** delete data from the table: "model_lines" */
  delete_model_lines?: Maybe<Model_Lines_Mutation_Response>;
  /** delete single row from the table: "model_lines" */
  delete_model_lines_by_pk?: Maybe<Model_Lines>;
  /** delete data from the table: "model_lines_to_missing_buses" */
  delete_model_lines_to_missing_buses?: Maybe<Model_Lines_To_Missing_Buses_Mutation_Response>;
  /** delete single row from the table: "model_lines_to_missing_buses" */
  delete_model_lines_to_missing_buses_by_pk?: Maybe<Model_Lines_To_Missing_Buses>;
  /** delete data from the table: "projects" */
  delete_projects?: Maybe<Projects_Mutation_Response>;
  /** delete single row from the table: "projects" */
  delete_projects_by_pk?: Maybe<Projects>;
  /** delete data from the table: "scenario_allocated_upgrades" */
  delete_scenario_allocated_upgrades?: Maybe<Scenario_Allocated_Upgrades_Mutation_Response>;
  /** delete single row from the table: "scenario_allocated_upgrades" */
  delete_scenario_allocated_upgrades_by_pk?: Maybe<Scenario_Allocated_Upgrades>;
  /** delete data from the table: "scenario_constraints" */
  delete_scenario_constraints?: Maybe<Scenario_Constraints_Mutation_Response>;
  /** delete single row from the table: "scenario_constraints" */
  delete_scenario_constraints_by_pk?: Maybe<Scenario_Constraints>;
  /** delete data from the table: "scenario_projects" */
  delete_scenario_projects?: Maybe<Scenario_Projects_Mutation_Response>;
  /** delete single row from the table: "scenario_projects" */
  delete_scenario_projects_by_pk?: Maybe<Scenario_Projects>;
  /** delete data from the table: "scenario_statuses" */
  delete_scenario_statuses?: Maybe<Scenario_Statuses_Mutation_Response>;
  /** delete single row from the table: "scenario_statuses" */
  delete_scenario_statuses_by_pk?: Maybe<Scenario_Statuses>;
  /** delete data from the table: "scenarios" */
  delete_scenarios?: Maybe<Scenarios_Mutation_Response>;
  /** delete single row from the table: "scenarios" */
  delete_scenarios_by_pk?: Maybe<Scenarios>;
  /** delete data from the table: "screening_views" */
  delete_screening_views?: Maybe<Screening_Views_Mutation_Response>;
  /** delete single row from the table: "screening_views" */
  delete_screening_views_by_pk?: Maybe<Screening_Views>;
  /** delete data from the table: "subscriptions" */
  delete_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** delete single row from the table: "subscriptions" */
  delete_subscriptions_by_pk?: Maybe<Subscriptions>;
  /** delete data from the table: "substations" */
  delete_substations?: Maybe<Substations_Mutation_Response>;
  /** delete single row from the table: "substations" */
  delete_substations_by_pk?: Maybe<Substations>;
  /** delete data from the table: "teams" */
  delete_teams?: Maybe<Teams_Mutation_Response>;
  /** delete single row from the table: "teams" */
  delete_teams_by_pk?: Maybe<Teams>;
  /** delete data from the table: "tracked_projects" */
  delete_tracked_projects?: Maybe<Tracked_Projects_Mutation_Response>;
  /** delete single row from the table: "tracked_projects" */
  delete_tracked_projects_by_pk?: Maybe<Tracked_Projects>;
  /** delete data from the table: "unlocked_buses" */
  delete_unlocked_buses?: Maybe<Unlocked_Buses_Mutation_Response>;
  /** delete single row from the table: "unlocked_buses" */
  delete_unlocked_buses_by_pk?: Maybe<Unlocked_Buses>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "all_projects" */
  insert_all_projects?: Maybe<All_Projects_Mutation_Response>;
  /** insert a single row into the table: "all_projects" */
  insert_all_projects_one?: Maybe<All_Projects>;
  /** insert data into the table: "branches" */
  insert_branches?: Maybe<Branches_Mutation_Response>;
  /** insert a single row into the table: "branches" */
  insert_branches_one?: Maybe<Branches>;
  /** insert data into the table: "buses" */
  insert_buses?: Maybe<Buses_Mutation_Response>;
  /** insert a single row into the table: "buses" */
  insert_buses_one?: Maybe<Buses>;
  /** insert data into the table: "capacity_costs" */
  insert_capacity_costs?: Maybe<Capacity_Costs_Mutation_Response>;
  /** insert a single row into the table: "capacity_costs" */
  insert_capacity_costs_one?: Maybe<Capacity_Costs>;
  /** insert data into the table: "constraint_stacks" */
  insert_constraint_stacks?: Maybe<Constraint_Stacks_Mutation_Response>;
  /** insert a single row into the table: "constraint_stacks" */
  insert_constraint_stacks_one?: Maybe<Constraint_Stacks>;
  /** insert data into the table: "constraint_types" */
  insert_constraint_types?: Maybe<Constraint_Types_Mutation_Response>;
  /** insert a single row into the table: "constraint_types" */
  insert_constraint_types_one?: Maybe<Constraint_Types>;
  /** insert data into the table: "in_queue_subscriptions" */
  insert_in_queue_subscriptions?: Maybe<In_Queue_Subscriptions_Mutation_Response>;
  /** insert a single row into the table: "in_queue_subscriptions" */
  insert_in_queue_subscriptions_one?: Maybe<In_Queue_Subscriptions>;
  /** insert data into the table: "line_capacity_costs" */
  insert_line_capacity_costs?: Maybe<Line_Capacity_Costs_Mutation_Response>;
  /** insert a single row into the table: "line_capacity_costs" */
  insert_line_capacity_costs_one?: Maybe<Line_Capacity_Costs>;
  /** insert data into the table: "line_constraint_stacks" */
  insert_line_constraint_stacks?: Maybe<Line_Constraint_Stacks_Mutation_Response>;
  /** insert a single row into the table: "line_constraint_stacks" */
  insert_line_constraint_stacks_one?: Maybe<Line_Constraint_Stacks>;
  /** insert data into the table: "model_lines" */
  insert_model_lines?: Maybe<Model_Lines_Mutation_Response>;
  /** insert a single row into the table: "model_lines" */
  insert_model_lines_one?: Maybe<Model_Lines>;
  /** insert data into the table: "model_lines_to_missing_buses" */
  insert_model_lines_to_missing_buses?: Maybe<Model_Lines_To_Missing_Buses_Mutation_Response>;
  /** insert a single row into the table: "model_lines_to_missing_buses" */
  insert_model_lines_to_missing_buses_one?: Maybe<Model_Lines_To_Missing_Buses>;
  /** insert data into the table: "projects" */
  insert_projects?: Maybe<Projects_Mutation_Response>;
  /** insert a single row into the table: "projects" */
  insert_projects_one?: Maybe<Projects>;
  /** insert data into the table: "scenario_allocated_upgrades" */
  insert_scenario_allocated_upgrades?: Maybe<Scenario_Allocated_Upgrades_Mutation_Response>;
  /** insert a single row into the table: "scenario_allocated_upgrades" */
  insert_scenario_allocated_upgrades_one?: Maybe<Scenario_Allocated_Upgrades>;
  /** insert data into the table: "scenario_constraints" */
  insert_scenario_constraints?: Maybe<Scenario_Constraints_Mutation_Response>;
  /** insert a single row into the table: "scenario_constraints" */
  insert_scenario_constraints_one?: Maybe<Scenario_Constraints>;
  /** insert data into the table: "scenario_projects" */
  insert_scenario_projects?: Maybe<Scenario_Projects_Mutation_Response>;
  /** insert a single row into the table: "scenario_projects" */
  insert_scenario_projects_one?: Maybe<Scenario_Projects>;
  /** insert data into the table: "scenario_statuses" */
  insert_scenario_statuses?: Maybe<Scenario_Statuses_Mutation_Response>;
  /** insert a single row into the table: "scenario_statuses" */
  insert_scenario_statuses_one?: Maybe<Scenario_Statuses>;
  /** insert data into the table: "scenarios" */
  insert_scenarios?: Maybe<Scenarios_Mutation_Response>;
  /** insert a single row into the table: "scenarios" */
  insert_scenarios_one?: Maybe<Scenarios>;
  /** insert data into the table: "screening_views" */
  insert_screening_views?: Maybe<Screening_Views_Mutation_Response>;
  /** insert a single row into the table: "screening_views" */
  insert_screening_views_one?: Maybe<Screening_Views>;
  /** insert data into the table: "subscriptions" */
  insert_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** insert a single row into the table: "subscriptions" */
  insert_subscriptions_one?: Maybe<Subscriptions>;
  /** insert data into the table: "substations" */
  insert_substations?: Maybe<Substations_Mutation_Response>;
  /** insert a single row into the table: "substations" */
  insert_substations_one?: Maybe<Substations>;
  /** insert data into the table: "teams" */
  insert_teams?: Maybe<Teams_Mutation_Response>;
  /** insert a single row into the table: "teams" */
  insert_teams_one?: Maybe<Teams>;
  /** insert data into the table: "tracked_projects" */
  insert_tracked_projects?: Maybe<Tracked_Projects_Mutation_Response>;
  /** insert a single row into the table: "tracked_projects" */
  insert_tracked_projects_one?: Maybe<Tracked_Projects>;
  /** insert data into the table: "unlocked_buses" */
  insert_unlocked_buses?: Maybe<Unlocked_Buses_Mutation_Response>;
  /** insert a single row into the table: "unlocked_buses" */
  insert_unlocked_buses_one?: Maybe<Unlocked_Buses>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** execute VOLATILE function "unlock_bus" which returns "unlocked_buses" */
  unlock_bus?: Maybe<Unlocked_Buses>;
  /** update data of the table: "all_projects" */
  update_all_projects?: Maybe<All_Projects_Mutation_Response>;
  /** update single row of the table: "all_projects" */
  update_all_projects_by_pk?: Maybe<All_Projects>;
  /** update multiples rows of table: "all_projects" */
  update_all_projects_many?: Maybe<Array<Maybe<All_Projects_Mutation_Response>>>;
  /** update data of the table: "branches" */
  update_branches?: Maybe<Branches_Mutation_Response>;
  /** update single row of the table: "branches" */
  update_branches_by_pk?: Maybe<Branches>;
  /** update multiples rows of table: "branches" */
  update_branches_many?: Maybe<Array<Maybe<Branches_Mutation_Response>>>;
  /** update data of the table: "buses" */
  update_buses?: Maybe<Buses_Mutation_Response>;
  /** update single row of the table: "buses" */
  update_buses_by_pk?: Maybe<Buses>;
  /** update multiples rows of table: "buses" */
  update_buses_many?: Maybe<Array<Maybe<Buses_Mutation_Response>>>;
  /** update data of the table: "capacity_costs" */
  update_capacity_costs?: Maybe<Capacity_Costs_Mutation_Response>;
  /** update single row of the table: "capacity_costs" */
  update_capacity_costs_by_pk?: Maybe<Capacity_Costs>;
  /** update multiples rows of table: "capacity_costs" */
  update_capacity_costs_many?: Maybe<Array<Maybe<Capacity_Costs_Mutation_Response>>>;
  /** update data of the table: "constraint_stacks" */
  update_constraint_stacks?: Maybe<Constraint_Stacks_Mutation_Response>;
  /** update single row of the table: "constraint_stacks" */
  update_constraint_stacks_by_pk?: Maybe<Constraint_Stacks>;
  /** update multiples rows of table: "constraint_stacks" */
  update_constraint_stacks_many?: Maybe<Array<Maybe<Constraint_Stacks_Mutation_Response>>>;
  /** update data of the table: "constraint_types" */
  update_constraint_types?: Maybe<Constraint_Types_Mutation_Response>;
  /** update single row of the table: "constraint_types" */
  update_constraint_types_by_pk?: Maybe<Constraint_Types>;
  /** update multiples rows of table: "constraint_types" */
  update_constraint_types_many?: Maybe<Array<Maybe<Constraint_Types_Mutation_Response>>>;
  /** update data of the table: "in_queue_subscriptions" */
  update_in_queue_subscriptions?: Maybe<In_Queue_Subscriptions_Mutation_Response>;
  /** update single row of the table: "in_queue_subscriptions" */
  update_in_queue_subscriptions_by_pk?: Maybe<In_Queue_Subscriptions>;
  /** update multiples rows of table: "in_queue_subscriptions" */
  update_in_queue_subscriptions_many?: Maybe<Array<Maybe<In_Queue_Subscriptions_Mutation_Response>>>;
  /** update data of the table: "line_capacity_costs" */
  update_line_capacity_costs?: Maybe<Line_Capacity_Costs_Mutation_Response>;
  /** update single row of the table: "line_capacity_costs" */
  update_line_capacity_costs_by_pk?: Maybe<Line_Capacity_Costs>;
  /** update multiples rows of table: "line_capacity_costs" */
  update_line_capacity_costs_many?: Maybe<Array<Maybe<Line_Capacity_Costs_Mutation_Response>>>;
  /** update data of the table: "line_constraint_stacks" */
  update_line_constraint_stacks?: Maybe<Line_Constraint_Stacks_Mutation_Response>;
  /** update single row of the table: "line_constraint_stacks" */
  update_line_constraint_stacks_by_pk?: Maybe<Line_Constraint_Stacks>;
  /** update multiples rows of table: "line_constraint_stacks" */
  update_line_constraint_stacks_many?: Maybe<Array<Maybe<Line_Constraint_Stacks_Mutation_Response>>>;
  /** update data of the table: "model_lines" */
  update_model_lines?: Maybe<Model_Lines_Mutation_Response>;
  /** update single row of the table: "model_lines" */
  update_model_lines_by_pk?: Maybe<Model_Lines>;
  /** update multiples rows of table: "model_lines" */
  update_model_lines_many?: Maybe<Array<Maybe<Model_Lines_Mutation_Response>>>;
  /** update data of the table: "model_lines_to_missing_buses" */
  update_model_lines_to_missing_buses?: Maybe<Model_Lines_To_Missing_Buses_Mutation_Response>;
  /** update single row of the table: "model_lines_to_missing_buses" */
  update_model_lines_to_missing_buses_by_pk?: Maybe<Model_Lines_To_Missing_Buses>;
  /** update multiples rows of table: "model_lines_to_missing_buses" */
  update_model_lines_to_missing_buses_many?: Maybe<Array<Maybe<Model_Lines_To_Missing_Buses_Mutation_Response>>>;
  /** update data of the table: "projects" */
  update_projects?: Maybe<Projects_Mutation_Response>;
  /** update single row of the table: "projects" */
  update_projects_by_pk?: Maybe<Projects>;
  /** update multiples rows of table: "projects" */
  update_projects_many?: Maybe<Array<Maybe<Projects_Mutation_Response>>>;
  /** update data of the table: "scenario_allocated_upgrades" */
  update_scenario_allocated_upgrades?: Maybe<Scenario_Allocated_Upgrades_Mutation_Response>;
  /** update single row of the table: "scenario_allocated_upgrades" */
  update_scenario_allocated_upgrades_by_pk?: Maybe<Scenario_Allocated_Upgrades>;
  /** update multiples rows of table: "scenario_allocated_upgrades" */
  update_scenario_allocated_upgrades_many?: Maybe<Array<Maybe<Scenario_Allocated_Upgrades_Mutation_Response>>>;
  /** update data of the table: "scenario_constraints" */
  update_scenario_constraints?: Maybe<Scenario_Constraints_Mutation_Response>;
  /** update single row of the table: "scenario_constraints" */
  update_scenario_constraints_by_pk?: Maybe<Scenario_Constraints>;
  /** update multiples rows of table: "scenario_constraints" */
  update_scenario_constraints_many?: Maybe<Array<Maybe<Scenario_Constraints_Mutation_Response>>>;
  /** update data of the table: "scenario_projects" */
  update_scenario_projects?: Maybe<Scenario_Projects_Mutation_Response>;
  /** update single row of the table: "scenario_projects" */
  update_scenario_projects_by_pk?: Maybe<Scenario_Projects>;
  /** update multiples rows of table: "scenario_projects" */
  update_scenario_projects_many?: Maybe<Array<Maybe<Scenario_Projects_Mutation_Response>>>;
  /** update data of the table: "scenario_statuses" */
  update_scenario_statuses?: Maybe<Scenario_Statuses_Mutation_Response>;
  /** update single row of the table: "scenario_statuses" */
  update_scenario_statuses_by_pk?: Maybe<Scenario_Statuses>;
  /** update multiples rows of table: "scenario_statuses" */
  update_scenario_statuses_many?: Maybe<Array<Maybe<Scenario_Statuses_Mutation_Response>>>;
  /** update data of the table: "scenarios" */
  update_scenarios?: Maybe<Scenarios_Mutation_Response>;
  /** update single row of the table: "scenarios" */
  update_scenarios_by_pk?: Maybe<Scenarios>;
  /** update multiples rows of table: "scenarios" */
  update_scenarios_many?: Maybe<Array<Maybe<Scenarios_Mutation_Response>>>;
  /** update data of the table: "screening_views" */
  update_screening_views?: Maybe<Screening_Views_Mutation_Response>;
  /** update single row of the table: "screening_views" */
  update_screening_views_by_pk?: Maybe<Screening_Views>;
  /** update multiples rows of table: "screening_views" */
  update_screening_views_many?: Maybe<Array<Maybe<Screening_Views_Mutation_Response>>>;
  /** update data of the table: "subscriptions" */
  update_subscriptions?: Maybe<Subscriptions_Mutation_Response>;
  /** update single row of the table: "subscriptions" */
  update_subscriptions_by_pk?: Maybe<Subscriptions>;
  /** update multiples rows of table: "subscriptions" */
  update_subscriptions_many?: Maybe<Array<Maybe<Subscriptions_Mutation_Response>>>;
  /** update data of the table: "substations" */
  update_substations?: Maybe<Substations_Mutation_Response>;
  /** update single row of the table: "substations" */
  update_substations_by_pk?: Maybe<Substations>;
  /** update multiples rows of table: "substations" */
  update_substations_many?: Maybe<Array<Maybe<Substations_Mutation_Response>>>;
  /** update data of the table: "teams" */
  update_teams?: Maybe<Teams_Mutation_Response>;
  /** update single row of the table: "teams" */
  update_teams_by_pk?: Maybe<Teams>;
  /** update multiples rows of table: "teams" */
  update_teams_many?: Maybe<Array<Maybe<Teams_Mutation_Response>>>;
  /** update data of the table: "tracked_projects" */
  update_tracked_projects?: Maybe<Tracked_Projects_Mutation_Response>;
  /** update single row of the table: "tracked_projects" */
  update_tracked_projects_by_pk?: Maybe<Tracked_Projects>;
  /** update multiples rows of table: "tracked_projects" */
  update_tracked_projects_many?: Maybe<Array<Maybe<Tracked_Projects_Mutation_Response>>>;
  /** update data of the table: "unlocked_buses" */
  update_unlocked_buses?: Maybe<Unlocked_Buses_Mutation_Response>;
  /** update single row of the table: "unlocked_buses" */
  update_unlocked_buses_by_pk?: Maybe<Unlocked_Buses>;
  /** update multiples rows of table: "unlocked_buses" */
  update_unlocked_buses_many?: Maybe<Array<Maybe<Unlocked_Buses_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_All_ProjectsArgs = {
  where: All_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_All_Projects_By_PkArgs = {
  project_id: Scalars['String']['input'];
  region: Scalars['String']['input'];
  study_phase: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_BranchesArgs = {
  where: Branches_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Branches_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_BusesArgs = {
  where: Buses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Buses_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Capacity_CostsArgs = {
  where: Capacity_Costs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Capacity_Costs_By_PkArgs = {
  bus_id: Scalars['String']['input'];
  energy_size: Scalars['numeric']['input'];
  includes_pre_existing: Scalars['Boolean']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Constraint_StacksArgs = {
  where: Constraint_Stacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Constraint_Stacks_By_PkArgs = {
  bus_id: Scalars['String']['input'];
  monitored_facility_id: Scalars['String']['input'];
  resource_type: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Constraint_TypesArgs = {
  where: Constraint_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Constraint_Types_By_PkArgs = {
  constraint_type: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_In_Queue_SubscriptionsArgs = {
  where: In_Queue_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_In_Queue_Subscriptions_By_PkArgs = {
  subscription_id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Line_Capacity_CostsArgs = {
  where: Line_Capacity_Costs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Line_Capacity_Costs_By_PkArgs = {
  energy_size: Scalars['numeric']['input'];
  includes_pre_existing: Scalars['Boolean']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
  tap_facility_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Line_Constraint_StacksArgs = {
  where: Line_Constraint_Stacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Line_Constraint_Stacks_By_PkArgs = {
  monitored_facility_id: Scalars['String']['input'];
  resource_type: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
  tap_facility_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Model_LinesArgs = {
  where: Model_Lines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Model_Lines_By_PkArgs = {
  facility_id: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Model_Lines_To_Missing_BusesArgs = {
  where: Model_Lines_To_Missing_Buses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Model_Lines_To_Missing_Buses_By_PkArgs = {
  facility_id: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ProjectsArgs = {
  where: Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Projects_By_PkArgs = {
  project_number: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Scenario_Allocated_UpgradesArgs = {
  where: Scenario_Allocated_Upgrades_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Scenario_Allocated_Upgrades_By_PkArgs = {
  constraint_type: Constraint_Types_Enum;
  network_upgrade_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
  scenario_id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Scenario_ConstraintsArgs = {
  where: Scenario_Constraints_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Scenario_Constraints_By_PkArgs = {
  constraint_type: Constraint_Types_Enum;
  facility_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
  scenario_id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Scenario_ProjectsArgs = {
  where: Scenario_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Scenario_Projects_By_PkArgs = {
  project_id: Scalars['String']['input'];
  scenario_id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Scenario_StatusesArgs = {
  where: Scenario_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Scenario_Statuses_By_PkArgs = {
  status: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ScenariosArgs = {
  where: Scenarios_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Scenarios_By_PkArgs = {
  scenario_id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Screening_ViewsArgs = {
  where: Screening_Views_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Screening_Views_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SubscriptionsArgs = {
  where: Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subscriptions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SubstationsArgs = {
  where: Substations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Substations_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TeamsArgs = {
  where: Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teams_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tracked_ProjectsArgs = {
  where: Tracked_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tracked_Projects_By_PkArgs = {
  project_id: Scalars['String']['input'];
  subscription_id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Unlocked_BusesArgs = {
  where: Unlocked_Buses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Unlocked_Buses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  auth0_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootInsert_All_ProjectsArgs = {
  objects: Array<All_Projects_Insert_Input>;
  on_conflict?: InputMaybe<All_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_All_Projects_OneArgs = {
  object: All_Projects_Insert_Input;
  on_conflict?: InputMaybe<All_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BranchesArgs = {
  objects: Array<Branches_Insert_Input>;
  on_conflict?: InputMaybe<Branches_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Branches_OneArgs = {
  object: Branches_Insert_Input;
  on_conflict?: InputMaybe<Branches_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BusesArgs = {
  objects: Array<Buses_Insert_Input>;
  on_conflict?: InputMaybe<Buses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Buses_OneArgs = {
  object: Buses_Insert_Input;
  on_conflict?: InputMaybe<Buses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Capacity_CostsArgs = {
  objects: Array<Capacity_Costs_Insert_Input>;
  on_conflict?: InputMaybe<Capacity_Costs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Capacity_Costs_OneArgs = {
  object: Capacity_Costs_Insert_Input;
  on_conflict?: InputMaybe<Capacity_Costs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Constraint_StacksArgs = {
  objects: Array<Constraint_Stacks_Insert_Input>;
  on_conflict?: InputMaybe<Constraint_Stacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Constraint_Stacks_OneArgs = {
  object: Constraint_Stacks_Insert_Input;
  on_conflict?: InputMaybe<Constraint_Stacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Constraint_TypesArgs = {
  objects: Array<Constraint_Types_Insert_Input>;
  on_conflict?: InputMaybe<Constraint_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Constraint_Types_OneArgs = {
  object: Constraint_Types_Insert_Input;
  on_conflict?: InputMaybe<Constraint_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_In_Queue_SubscriptionsArgs = {
  objects: Array<In_Queue_Subscriptions_Insert_Input>;
  on_conflict?: InputMaybe<In_Queue_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_In_Queue_Subscriptions_OneArgs = {
  object: In_Queue_Subscriptions_Insert_Input;
  on_conflict?: InputMaybe<In_Queue_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Line_Capacity_CostsArgs = {
  objects: Array<Line_Capacity_Costs_Insert_Input>;
  on_conflict?: InputMaybe<Line_Capacity_Costs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Line_Capacity_Costs_OneArgs = {
  object: Line_Capacity_Costs_Insert_Input;
  on_conflict?: InputMaybe<Line_Capacity_Costs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Line_Constraint_StacksArgs = {
  objects: Array<Line_Constraint_Stacks_Insert_Input>;
  on_conflict?: InputMaybe<Line_Constraint_Stacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Line_Constraint_Stacks_OneArgs = {
  object: Line_Constraint_Stacks_Insert_Input;
  on_conflict?: InputMaybe<Line_Constraint_Stacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Model_LinesArgs = {
  objects: Array<Model_Lines_Insert_Input>;
  on_conflict?: InputMaybe<Model_Lines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Model_Lines_OneArgs = {
  object: Model_Lines_Insert_Input;
  on_conflict?: InputMaybe<Model_Lines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Model_Lines_To_Missing_BusesArgs = {
  objects: Array<Model_Lines_To_Missing_Buses_Insert_Input>;
  on_conflict?: InputMaybe<Model_Lines_To_Missing_Buses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Model_Lines_To_Missing_Buses_OneArgs = {
  object: Model_Lines_To_Missing_Buses_Insert_Input;
  on_conflict?: InputMaybe<Model_Lines_To_Missing_Buses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectsArgs = {
  objects: Array<Projects_Insert_Input>;
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_OneArgs = {
  object: Projects_Insert_Input;
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scenario_Allocated_UpgradesArgs = {
  objects: Array<Scenario_Allocated_Upgrades_Insert_Input>;
  on_conflict?: InputMaybe<Scenario_Allocated_Upgrades_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scenario_Allocated_Upgrades_OneArgs = {
  object: Scenario_Allocated_Upgrades_Insert_Input;
  on_conflict?: InputMaybe<Scenario_Allocated_Upgrades_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scenario_ConstraintsArgs = {
  objects: Array<Scenario_Constraints_Insert_Input>;
  on_conflict?: InputMaybe<Scenario_Constraints_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scenario_Constraints_OneArgs = {
  object: Scenario_Constraints_Insert_Input;
  on_conflict?: InputMaybe<Scenario_Constraints_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scenario_ProjectsArgs = {
  objects: Array<Scenario_Projects_Insert_Input>;
  on_conflict?: InputMaybe<Scenario_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scenario_Projects_OneArgs = {
  object: Scenario_Projects_Insert_Input;
  on_conflict?: InputMaybe<Scenario_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scenario_StatusesArgs = {
  objects: Array<Scenario_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Scenario_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scenario_Statuses_OneArgs = {
  object: Scenario_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Scenario_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ScenariosArgs = {
  objects: Array<Scenarios_Insert_Input>;
  on_conflict?: InputMaybe<Scenarios_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scenarios_OneArgs = {
  object: Scenarios_Insert_Input;
  on_conflict?: InputMaybe<Scenarios_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Screening_ViewsArgs = {
  objects: Array<Screening_Views_Insert_Input>;
  on_conflict?: InputMaybe<Screening_Views_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Screening_Views_OneArgs = {
  object: Screening_Views_Insert_Input;
  on_conflict?: InputMaybe<Screening_Views_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubscriptionsArgs = {
  objects: Array<Subscriptions_Insert_Input>;
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscriptions_OneArgs = {
  object: Subscriptions_Insert_Input;
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubstationsArgs = {
  objects: Array<Substations_Insert_Input>;
  on_conflict?: InputMaybe<Substations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Substations_OneArgs = {
  object: Substations_Insert_Input;
  on_conflict?: InputMaybe<Substations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeamsArgs = {
  objects: Array<Teams_Insert_Input>;
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_OneArgs = {
  object: Teams_Insert_Input;
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tracked_ProjectsArgs = {
  objects: Array<Tracked_Projects_Insert_Input>;
  on_conflict?: InputMaybe<Tracked_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tracked_Projects_OneArgs = {
  object: Tracked_Projects_Insert_Input;
  on_conflict?: InputMaybe<Tracked_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unlocked_BusesArgs = {
  objects: Array<Unlocked_Buses_Insert_Input>;
  on_conflict?: InputMaybe<Unlocked_Buses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unlocked_Buses_OneArgs = {
  object: Unlocked_Buses_Insert_Input;
  on_conflict?: InputMaybe<Unlocked_Buses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUnlock_BusArgs = {
  args: Unlock_Bus_Args;
  distinct_on?: InputMaybe<Array<Unlocked_Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Unlocked_Buses_Order_By>>;
  where?: InputMaybe<Unlocked_Buses_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootUpdate_All_ProjectsArgs = {
  _inc?: InputMaybe<All_Projects_Inc_Input>;
  _set?: InputMaybe<All_Projects_Set_Input>;
  where: All_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_All_Projects_By_PkArgs = {
  _inc?: InputMaybe<All_Projects_Inc_Input>;
  _set?: InputMaybe<All_Projects_Set_Input>;
  pk_columns: All_Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_All_Projects_ManyArgs = {
  updates: Array<All_Projects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BranchesArgs = {
  _append?: InputMaybe<Branches_Append_Input>;
  _delete_at_path?: InputMaybe<Branches_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Branches_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Branches_Delete_Key_Input>;
  _inc?: InputMaybe<Branches_Inc_Input>;
  _prepend?: InputMaybe<Branches_Prepend_Input>;
  _set?: InputMaybe<Branches_Set_Input>;
  where: Branches_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Branches_By_PkArgs = {
  _append?: InputMaybe<Branches_Append_Input>;
  _delete_at_path?: InputMaybe<Branches_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Branches_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Branches_Delete_Key_Input>;
  _inc?: InputMaybe<Branches_Inc_Input>;
  _prepend?: InputMaybe<Branches_Prepend_Input>;
  _set?: InputMaybe<Branches_Set_Input>;
  pk_columns: Branches_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Branches_ManyArgs = {
  updates: Array<Branches_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BusesArgs = {
  _append?: InputMaybe<Buses_Append_Input>;
  _delete_at_path?: InputMaybe<Buses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Buses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Buses_Delete_Key_Input>;
  _inc?: InputMaybe<Buses_Inc_Input>;
  _prepend?: InputMaybe<Buses_Prepend_Input>;
  _set?: InputMaybe<Buses_Set_Input>;
  where: Buses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Buses_By_PkArgs = {
  _append?: InputMaybe<Buses_Append_Input>;
  _delete_at_path?: InputMaybe<Buses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Buses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Buses_Delete_Key_Input>;
  _inc?: InputMaybe<Buses_Inc_Input>;
  _prepend?: InputMaybe<Buses_Prepend_Input>;
  _set?: InputMaybe<Buses_Set_Input>;
  pk_columns: Buses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Buses_ManyArgs = {
  updates: Array<Buses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Capacity_CostsArgs = {
  _inc?: InputMaybe<Capacity_Costs_Inc_Input>;
  _set?: InputMaybe<Capacity_Costs_Set_Input>;
  where: Capacity_Costs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Capacity_Costs_By_PkArgs = {
  _inc?: InputMaybe<Capacity_Costs_Inc_Input>;
  _set?: InputMaybe<Capacity_Costs_Set_Input>;
  pk_columns: Capacity_Costs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Capacity_Costs_ManyArgs = {
  updates: Array<Capacity_Costs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Constraint_StacksArgs = {
  _inc?: InputMaybe<Constraint_Stacks_Inc_Input>;
  _set?: InputMaybe<Constraint_Stacks_Set_Input>;
  where: Constraint_Stacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Constraint_Stacks_By_PkArgs = {
  _inc?: InputMaybe<Constraint_Stacks_Inc_Input>;
  _set?: InputMaybe<Constraint_Stacks_Set_Input>;
  pk_columns: Constraint_Stacks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Constraint_Stacks_ManyArgs = {
  updates: Array<Constraint_Stacks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Constraint_TypesArgs = {
  _set?: InputMaybe<Constraint_Types_Set_Input>;
  where: Constraint_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Constraint_Types_By_PkArgs = {
  _set?: InputMaybe<Constraint_Types_Set_Input>;
  pk_columns: Constraint_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Constraint_Types_ManyArgs = {
  updates: Array<Constraint_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_In_Queue_SubscriptionsArgs = {
  _inc?: InputMaybe<In_Queue_Subscriptions_Inc_Input>;
  _set?: InputMaybe<In_Queue_Subscriptions_Set_Input>;
  where: In_Queue_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_In_Queue_Subscriptions_By_PkArgs = {
  _inc?: InputMaybe<In_Queue_Subscriptions_Inc_Input>;
  _set?: InputMaybe<In_Queue_Subscriptions_Set_Input>;
  pk_columns: In_Queue_Subscriptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_In_Queue_Subscriptions_ManyArgs = {
  updates: Array<In_Queue_Subscriptions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Line_Capacity_CostsArgs = {
  _inc?: InputMaybe<Line_Capacity_Costs_Inc_Input>;
  _set?: InputMaybe<Line_Capacity_Costs_Set_Input>;
  where: Line_Capacity_Costs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Line_Capacity_Costs_By_PkArgs = {
  _inc?: InputMaybe<Line_Capacity_Costs_Inc_Input>;
  _set?: InputMaybe<Line_Capacity_Costs_Set_Input>;
  pk_columns: Line_Capacity_Costs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Line_Capacity_Costs_ManyArgs = {
  updates: Array<Line_Capacity_Costs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Line_Constraint_StacksArgs = {
  _inc?: InputMaybe<Line_Constraint_Stacks_Inc_Input>;
  _set?: InputMaybe<Line_Constraint_Stacks_Set_Input>;
  where: Line_Constraint_Stacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Line_Constraint_Stacks_By_PkArgs = {
  _inc?: InputMaybe<Line_Constraint_Stacks_Inc_Input>;
  _set?: InputMaybe<Line_Constraint_Stacks_Set_Input>;
  pk_columns: Line_Constraint_Stacks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Line_Constraint_Stacks_ManyArgs = {
  updates: Array<Line_Constraint_Stacks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Model_LinesArgs = {
  _inc?: InputMaybe<Model_Lines_Inc_Input>;
  _set?: InputMaybe<Model_Lines_Set_Input>;
  where: Model_Lines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Model_Lines_By_PkArgs = {
  _inc?: InputMaybe<Model_Lines_Inc_Input>;
  _set?: InputMaybe<Model_Lines_Set_Input>;
  pk_columns: Model_Lines_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Model_Lines_ManyArgs = {
  updates: Array<Model_Lines_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Model_Lines_To_Missing_BusesArgs = {
  _inc?: InputMaybe<Model_Lines_To_Missing_Buses_Inc_Input>;
  _set?: InputMaybe<Model_Lines_To_Missing_Buses_Set_Input>;
  where: Model_Lines_To_Missing_Buses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Model_Lines_To_Missing_Buses_By_PkArgs = {
  _inc?: InputMaybe<Model_Lines_To_Missing_Buses_Inc_Input>;
  _set?: InputMaybe<Model_Lines_To_Missing_Buses_Set_Input>;
  pk_columns: Model_Lines_To_Missing_Buses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Model_Lines_To_Missing_Buses_ManyArgs = {
  updates: Array<Model_Lines_To_Missing_Buses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectsArgs = {
  _inc?: InputMaybe<Projects_Inc_Input>;
  _set?: InputMaybe<Projects_Set_Input>;
  where: Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_By_PkArgs = {
  _inc?: InputMaybe<Projects_Inc_Input>;
  _set?: InputMaybe<Projects_Set_Input>;
  pk_columns: Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_ManyArgs = {
  updates: Array<Projects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Scenario_Allocated_UpgradesArgs = {
  _append?: InputMaybe<Scenario_Allocated_Upgrades_Append_Input>;
  _delete_at_path?: InputMaybe<Scenario_Allocated_Upgrades_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Scenario_Allocated_Upgrades_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Scenario_Allocated_Upgrades_Delete_Key_Input>;
  _inc?: InputMaybe<Scenario_Allocated_Upgrades_Inc_Input>;
  _prepend?: InputMaybe<Scenario_Allocated_Upgrades_Prepend_Input>;
  _set?: InputMaybe<Scenario_Allocated_Upgrades_Set_Input>;
  where: Scenario_Allocated_Upgrades_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Scenario_Allocated_Upgrades_By_PkArgs = {
  _append?: InputMaybe<Scenario_Allocated_Upgrades_Append_Input>;
  _delete_at_path?: InputMaybe<Scenario_Allocated_Upgrades_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Scenario_Allocated_Upgrades_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Scenario_Allocated_Upgrades_Delete_Key_Input>;
  _inc?: InputMaybe<Scenario_Allocated_Upgrades_Inc_Input>;
  _prepend?: InputMaybe<Scenario_Allocated_Upgrades_Prepend_Input>;
  _set?: InputMaybe<Scenario_Allocated_Upgrades_Set_Input>;
  pk_columns: Scenario_Allocated_Upgrades_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Scenario_Allocated_Upgrades_ManyArgs = {
  updates: Array<Scenario_Allocated_Upgrades_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Scenario_ConstraintsArgs = {
  _append?: InputMaybe<Scenario_Constraints_Append_Input>;
  _delete_at_path?: InputMaybe<Scenario_Constraints_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Scenario_Constraints_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Scenario_Constraints_Delete_Key_Input>;
  _inc?: InputMaybe<Scenario_Constraints_Inc_Input>;
  _prepend?: InputMaybe<Scenario_Constraints_Prepend_Input>;
  _set?: InputMaybe<Scenario_Constraints_Set_Input>;
  where: Scenario_Constraints_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Scenario_Constraints_By_PkArgs = {
  _append?: InputMaybe<Scenario_Constraints_Append_Input>;
  _delete_at_path?: InputMaybe<Scenario_Constraints_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Scenario_Constraints_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Scenario_Constraints_Delete_Key_Input>;
  _inc?: InputMaybe<Scenario_Constraints_Inc_Input>;
  _prepend?: InputMaybe<Scenario_Constraints_Prepend_Input>;
  _set?: InputMaybe<Scenario_Constraints_Set_Input>;
  pk_columns: Scenario_Constraints_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Scenario_Constraints_ManyArgs = {
  updates: Array<Scenario_Constraints_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Scenario_ProjectsArgs = {
  _append?: InputMaybe<Scenario_Projects_Append_Input>;
  _delete_at_path?: InputMaybe<Scenario_Projects_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Scenario_Projects_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Scenario_Projects_Delete_Key_Input>;
  _inc?: InputMaybe<Scenario_Projects_Inc_Input>;
  _prepend?: InputMaybe<Scenario_Projects_Prepend_Input>;
  _set?: InputMaybe<Scenario_Projects_Set_Input>;
  where: Scenario_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Scenario_Projects_By_PkArgs = {
  _append?: InputMaybe<Scenario_Projects_Append_Input>;
  _delete_at_path?: InputMaybe<Scenario_Projects_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Scenario_Projects_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Scenario_Projects_Delete_Key_Input>;
  _inc?: InputMaybe<Scenario_Projects_Inc_Input>;
  _prepend?: InputMaybe<Scenario_Projects_Prepend_Input>;
  _set?: InputMaybe<Scenario_Projects_Set_Input>;
  pk_columns: Scenario_Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Scenario_Projects_ManyArgs = {
  updates: Array<Scenario_Projects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Scenario_StatusesArgs = {
  _set?: InputMaybe<Scenario_Statuses_Set_Input>;
  where: Scenario_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Scenario_Statuses_By_PkArgs = {
  _set?: InputMaybe<Scenario_Statuses_Set_Input>;
  pk_columns: Scenario_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Scenario_Statuses_ManyArgs = {
  updates: Array<Scenario_Statuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ScenariosArgs = {
  _append?: InputMaybe<Scenarios_Append_Input>;
  _delete_at_path?: InputMaybe<Scenarios_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Scenarios_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Scenarios_Delete_Key_Input>;
  _inc?: InputMaybe<Scenarios_Inc_Input>;
  _prepend?: InputMaybe<Scenarios_Prepend_Input>;
  _set?: InputMaybe<Scenarios_Set_Input>;
  where: Scenarios_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Scenarios_By_PkArgs = {
  _append?: InputMaybe<Scenarios_Append_Input>;
  _delete_at_path?: InputMaybe<Scenarios_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Scenarios_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Scenarios_Delete_Key_Input>;
  _inc?: InputMaybe<Scenarios_Inc_Input>;
  _prepend?: InputMaybe<Scenarios_Prepend_Input>;
  _set?: InputMaybe<Scenarios_Set_Input>;
  pk_columns: Scenarios_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Scenarios_ManyArgs = {
  updates: Array<Scenarios_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Screening_ViewsArgs = {
  _append?: InputMaybe<Screening_Views_Append_Input>;
  _delete_at_path?: InputMaybe<Screening_Views_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Screening_Views_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Screening_Views_Delete_Key_Input>;
  _inc?: InputMaybe<Screening_Views_Inc_Input>;
  _prepend?: InputMaybe<Screening_Views_Prepend_Input>;
  _set?: InputMaybe<Screening_Views_Set_Input>;
  where: Screening_Views_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Screening_Views_By_PkArgs = {
  _append?: InputMaybe<Screening_Views_Append_Input>;
  _delete_at_path?: InputMaybe<Screening_Views_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Screening_Views_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Screening_Views_Delete_Key_Input>;
  _inc?: InputMaybe<Screening_Views_Inc_Input>;
  _prepend?: InputMaybe<Screening_Views_Prepend_Input>;
  _set?: InputMaybe<Screening_Views_Set_Input>;
  pk_columns: Screening_Views_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Screening_Views_ManyArgs = {
  updates: Array<Screening_Views_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SubscriptionsArgs = {
  _inc?: InputMaybe<Subscriptions_Inc_Input>;
  _set?: InputMaybe<Subscriptions_Set_Input>;
  where: Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscriptions_By_PkArgs = {
  _inc?: InputMaybe<Subscriptions_Inc_Input>;
  _set?: InputMaybe<Subscriptions_Set_Input>;
  pk_columns: Subscriptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subscriptions_ManyArgs = {
  updates: Array<Subscriptions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SubstationsArgs = {
  _set?: InputMaybe<Substations_Set_Input>;
  where: Substations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Substations_By_PkArgs = {
  _set?: InputMaybe<Substations_Set_Input>;
  pk_columns: Substations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Substations_ManyArgs = {
  updates: Array<Substations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TeamsArgs = {
  _append?: InputMaybe<Teams_Append_Input>;
  _delete_at_path?: InputMaybe<Teams_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Teams_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Teams_Delete_Key_Input>;
  _inc?: InputMaybe<Teams_Inc_Input>;
  _prepend?: InputMaybe<Teams_Prepend_Input>;
  _set?: InputMaybe<Teams_Set_Input>;
  where: Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_By_PkArgs = {
  _append?: InputMaybe<Teams_Append_Input>;
  _delete_at_path?: InputMaybe<Teams_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Teams_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Teams_Delete_Key_Input>;
  _inc?: InputMaybe<Teams_Inc_Input>;
  _prepend?: InputMaybe<Teams_Prepend_Input>;
  _set?: InputMaybe<Teams_Set_Input>;
  pk_columns: Teams_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_ManyArgs = {
  updates: Array<Teams_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tracked_ProjectsArgs = {
  _set?: InputMaybe<Tracked_Projects_Set_Input>;
  where: Tracked_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tracked_Projects_By_PkArgs = {
  _set?: InputMaybe<Tracked_Projects_Set_Input>;
  pk_columns: Tracked_Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tracked_Projects_ManyArgs = {
  updates: Array<Tracked_Projects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Unlocked_BusesArgs = {
  _inc?: InputMaybe<Unlocked_Buses_Inc_Input>;
  _set?: InputMaybe<Unlocked_Buses_Set_Input>;
  where: Unlocked_Buses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Unlocked_Buses_By_PkArgs = {
  _inc?: InputMaybe<Unlocked_Buses_Inc_Input>;
  _set?: InputMaybe<Unlocked_Buses_Set_Input>;
  pk_columns: Unlocked_Buses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Unlocked_Buses_ManyArgs = {
  updates: Array<Unlocked_Buses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Inc_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Inc_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** Boolean expression to compare columns of type "path". All fields are combined with logical 'AND'. */
export type Path_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['path']['input']>;
  _gt?: InputMaybe<Scalars['path']['input']>;
  _gte?: InputMaybe<Scalars['path']['input']>;
  _in?: InputMaybe<Array<Scalars['path']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['path']['input']>;
  _lte?: InputMaybe<Scalars['path']['input']>;
  _neq?: InputMaybe<Scalars['path']['input']>;
  _nin?: InputMaybe<Array<Scalars['path']['input']>>;
};

/** columns and relationships of "projects" */
export type Projects = {
  __typename?: 'projects';
  eris_capacity_mw: Scalars['numeric']['output'];
  fuel_type: Scalars['String']['output'];
  included: Scalars['Boolean']['output'];
  nris_capacity_mw: Scalars['numeric']['output'];
  project_number: Scalars['String']['output'];
  region: Scalars['String']['output'];
  /** An array relationship */
  region_subscriptions: Array<Subscriptions>;
  /** An aggregate relationship */
  region_subscriptions_aggregate: Subscriptions_Aggregate;
  scope: Scalars['String']['output'];
  study_cycle: Scalars['String']['output'];
  study_group: Scalars['String']['output'];
};


/** columns and relationships of "projects" */
export type ProjectsRegion_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsRegion_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

/** aggregated selection of "projects" */
export type Projects_Aggregate = {
  __typename?: 'projects_aggregate';
  aggregate?: Maybe<Projects_Aggregate_Fields>;
  nodes: Array<Projects>;
};

/** aggregate fields of "projects" */
export type Projects_Aggregate_Fields = {
  __typename?: 'projects_aggregate_fields';
  avg?: Maybe<Projects_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Projects_Max_Fields>;
  min?: Maybe<Projects_Min_Fields>;
  stddev?: Maybe<Projects_Stddev_Fields>;
  stddev_pop?: Maybe<Projects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Projects_Stddev_Samp_Fields>;
  sum?: Maybe<Projects_Sum_Fields>;
  var_pop?: Maybe<Projects_Var_Pop_Fields>;
  var_samp?: Maybe<Projects_Var_Samp_Fields>;
  variance?: Maybe<Projects_Variance_Fields>;
};


/** aggregate fields of "projects" */
export type Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Projects_Avg_Fields = {
  __typename?: 'projects_avg_fields';
  eris_capacity_mw?: Maybe<Scalars['Float']['output']>;
  nris_capacity_mw?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "projects". All fields are combined with a logical 'AND'. */
export type Projects_Bool_Exp = {
  _and?: InputMaybe<Array<Projects_Bool_Exp>>;
  _not?: InputMaybe<Projects_Bool_Exp>;
  _or?: InputMaybe<Array<Projects_Bool_Exp>>;
  eris_capacity_mw?: InputMaybe<Numeric_Comparison_Exp>;
  fuel_type?: InputMaybe<String_Comparison_Exp>;
  included?: InputMaybe<Boolean_Comparison_Exp>;
  nris_capacity_mw?: InputMaybe<Numeric_Comparison_Exp>;
  project_number?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  region_subscriptions?: InputMaybe<Subscriptions_Bool_Exp>;
  region_subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Bool_Exp>;
  scope?: InputMaybe<String_Comparison_Exp>;
  study_cycle?: InputMaybe<String_Comparison_Exp>;
  study_group?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "projects" */
export enum Projects_Constraint {
  /** unique or primary key constraint on columns "project_number" */
  ProjectsPkey = 'projects_pkey'
}

/** input type for incrementing numeric columns in table "projects" */
export type Projects_Inc_Input = {
  eris_capacity_mw?: InputMaybe<Scalars['numeric']['input']>;
  nris_capacity_mw?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "projects" */
export type Projects_Insert_Input = {
  eris_capacity_mw?: InputMaybe<Scalars['numeric']['input']>;
  fuel_type?: InputMaybe<Scalars['String']['input']>;
  included?: InputMaybe<Scalars['Boolean']['input']>;
  nris_capacity_mw?: InputMaybe<Scalars['numeric']['input']>;
  project_number?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  region_subscriptions?: InputMaybe<Subscriptions_Arr_Rel_Insert_Input>;
  scope?: InputMaybe<Scalars['String']['input']>;
  study_cycle?: InputMaybe<Scalars['String']['input']>;
  study_group?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Projects_Max_Fields = {
  __typename?: 'projects_max_fields';
  eris_capacity_mw?: Maybe<Scalars['numeric']['output']>;
  fuel_type?: Maybe<Scalars['String']['output']>;
  nris_capacity_mw?: Maybe<Scalars['numeric']['output']>;
  project_number?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  study_cycle?: Maybe<Scalars['String']['output']>;
  study_group?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Projects_Min_Fields = {
  __typename?: 'projects_min_fields';
  eris_capacity_mw?: Maybe<Scalars['numeric']['output']>;
  fuel_type?: Maybe<Scalars['String']['output']>;
  nris_capacity_mw?: Maybe<Scalars['numeric']['output']>;
  project_number?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  study_cycle?: Maybe<Scalars['String']['output']>;
  study_group?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "projects" */
export type Projects_Mutation_Response = {
  __typename?: 'projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Projects>;
};

/** on_conflict condition type for table "projects" */
export type Projects_On_Conflict = {
  constraint: Projects_Constraint;
  update_columns?: Array<Projects_Update_Column>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "projects". */
export type Projects_Order_By = {
  eris_capacity_mw?: InputMaybe<Order_By>;
  fuel_type?: InputMaybe<Order_By>;
  included?: InputMaybe<Order_By>;
  nris_capacity_mw?: InputMaybe<Order_By>;
  project_number?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  region_subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Order_By>;
  scope?: InputMaybe<Order_By>;
  study_cycle?: InputMaybe<Order_By>;
  study_group?: InputMaybe<Order_By>;
};

/** primary key columns input for table: projects */
export type Projects_Pk_Columns_Input = {
  project_number: Scalars['String']['input'];
};

/** select columns of table "projects" */
export enum Projects_Select_Column {
  /** column name */
  ErisCapacityMw = 'eris_capacity_mw',
  /** column name */
  FuelType = 'fuel_type',
  /** column name */
  Included = 'included',
  /** column name */
  NrisCapacityMw = 'nris_capacity_mw',
  /** column name */
  ProjectNumber = 'project_number',
  /** column name */
  Region = 'region',
  /** column name */
  Scope = 'scope',
  /** column name */
  StudyCycle = 'study_cycle',
  /** column name */
  StudyGroup = 'study_group'
}

/** input type for updating data in table "projects" */
export type Projects_Set_Input = {
  eris_capacity_mw?: InputMaybe<Scalars['numeric']['input']>;
  fuel_type?: InputMaybe<Scalars['String']['input']>;
  included?: InputMaybe<Scalars['Boolean']['input']>;
  nris_capacity_mw?: InputMaybe<Scalars['numeric']['input']>;
  project_number?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  study_cycle?: InputMaybe<Scalars['String']['input']>;
  study_group?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Projects_Stddev_Fields = {
  __typename?: 'projects_stddev_fields';
  eris_capacity_mw?: Maybe<Scalars['Float']['output']>;
  nris_capacity_mw?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Projects_Stddev_Pop_Fields = {
  __typename?: 'projects_stddev_pop_fields';
  eris_capacity_mw?: Maybe<Scalars['Float']['output']>;
  nris_capacity_mw?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Projects_Stddev_Samp_Fields = {
  __typename?: 'projects_stddev_samp_fields';
  eris_capacity_mw?: Maybe<Scalars['Float']['output']>;
  nris_capacity_mw?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "projects" */
export type Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Stream_Cursor_Value_Input = {
  eris_capacity_mw?: InputMaybe<Scalars['numeric']['input']>;
  fuel_type?: InputMaybe<Scalars['String']['input']>;
  included?: InputMaybe<Scalars['Boolean']['input']>;
  nris_capacity_mw?: InputMaybe<Scalars['numeric']['input']>;
  project_number?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  study_cycle?: InputMaybe<Scalars['String']['input']>;
  study_group?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Projects_Sum_Fields = {
  __typename?: 'projects_sum_fields';
  eris_capacity_mw?: Maybe<Scalars['numeric']['output']>;
  nris_capacity_mw?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "projects" */
export enum Projects_Update_Column {
  /** column name */
  ErisCapacityMw = 'eris_capacity_mw',
  /** column name */
  FuelType = 'fuel_type',
  /** column name */
  Included = 'included',
  /** column name */
  NrisCapacityMw = 'nris_capacity_mw',
  /** column name */
  ProjectNumber = 'project_number',
  /** column name */
  Region = 'region',
  /** column name */
  Scope = 'scope',
  /** column name */
  StudyCycle = 'study_cycle',
  /** column name */
  StudyGroup = 'study_group'
}

export type Projects_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Projects_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Projects_Set_Input>;
  /** filter the rows which have to be updated */
  where: Projects_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Projects_Var_Pop_Fields = {
  __typename?: 'projects_var_pop_fields';
  eris_capacity_mw?: Maybe<Scalars['Float']['output']>;
  nris_capacity_mw?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Projects_Var_Samp_Fields = {
  __typename?: 'projects_var_samp_fields';
  eris_capacity_mw?: Maybe<Scalars['Float']['output']>;
  nris_capacity_mw?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Projects_Variance_Fields = {
  __typename?: 'projects_variance_fields';
  eris_capacity_mw?: Maybe<Scalars['Float']['output']>;
  nris_capacity_mw?: Maybe<Scalars['Float']['output']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "all_projects" */
  all_projects: Array<All_Projects>;
  /** fetch aggregated fields from the table: "all_projects" */
  all_projects_aggregate: All_Projects_Aggregate;
  /** fetch data from the table: "all_projects" using primary key columns */
  all_projects_by_pk?: Maybe<All_Projects>;
  /** fetch data from the table: "branches" */
  branches: Array<Branches>;
  /** fetch aggregated fields from the table: "branches" */
  branches_aggregate: Branches_Aggregate;
  /** fetch data from the table: "branches" using primary key columns */
  branches_by_pk?: Maybe<Branches>;
  /** An array relationship */
  buses: Array<Buses>;
  /** An aggregate relationship */
  buses_aggregate: Buses_Aggregate;
  /** fetch data from the table: "buses" using primary key columns */
  buses_by_pk?: Maybe<Buses>;
  /** An array relationship */
  capacity_costs: Array<Capacity_Costs>;
  /** An aggregate relationship */
  capacity_costs_aggregate: Capacity_Costs_Aggregate;
  /** fetch data from the table: "capacity_costs" using primary key columns */
  capacity_costs_by_pk?: Maybe<Capacity_Costs>;
  /** An array relationship */
  constraint_stacks: Array<Constraint_Stacks>;
  /** An aggregate relationship */
  constraint_stacks_aggregate: Constraint_Stacks_Aggregate;
  /** fetch data from the table: "constraint_stacks" using primary key columns */
  constraint_stacks_by_pk?: Maybe<Constraint_Stacks>;
  /** fetch data from the table: "constraint_types" */
  constraint_types: Array<Constraint_Types>;
  /** fetch aggregated fields from the table: "constraint_types" */
  constraint_types_aggregate: Constraint_Types_Aggregate;
  /** fetch data from the table: "constraint_types" using primary key columns */
  constraint_types_by_pk?: Maybe<Constraint_Types>;
  /** fetch data from the table: "in_queue_subscriptions" */
  in_queue_subscriptions: Array<In_Queue_Subscriptions>;
  /** fetch aggregated fields from the table: "in_queue_subscriptions" */
  in_queue_subscriptions_aggregate: In_Queue_Subscriptions_Aggregate;
  /** fetch data from the table: "in_queue_subscriptions" using primary key columns */
  in_queue_subscriptions_by_pk?: Maybe<In_Queue_Subscriptions>;
  /** An array relationship */
  line_capacity_costs: Array<Line_Capacity_Costs>;
  /** An aggregate relationship */
  line_capacity_costs_aggregate: Line_Capacity_Costs_Aggregate;
  /** fetch data from the table: "line_capacity_costs" using primary key columns */
  line_capacity_costs_by_pk?: Maybe<Line_Capacity_Costs>;
  /** An array relationship */
  line_constraint_stacks: Array<Line_Constraint_Stacks>;
  /** An aggregate relationship */
  line_constraint_stacks_aggregate: Line_Constraint_Stacks_Aggregate;
  /** fetch data from the table: "line_constraint_stacks" using primary key columns */
  line_constraint_stacks_by_pk?: Maybe<Line_Constraint_Stacks>;
  /** fetch data from the table: "model_lines" */
  model_lines: Array<Model_Lines>;
  /** fetch aggregated fields from the table: "model_lines" */
  model_lines_aggregate: Model_Lines_Aggregate;
  /** fetch data from the table: "model_lines" using primary key columns */
  model_lines_by_pk?: Maybe<Model_Lines>;
  /** fetch data from the table: "model_lines_to_missing_buses" */
  model_lines_to_missing_buses: Array<Model_Lines_To_Missing_Buses>;
  /** fetch aggregated fields from the table: "model_lines_to_missing_buses" */
  model_lines_to_missing_buses_aggregate: Model_Lines_To_Missing_Buses_Aggregate;
  /** fetch data from the table: "model_lines_to_missing_buses" using primary key columns */
  model_lines_to_missing_buses_by_pk?: Maybe<Model_Lines_To_Missing_Buses>;
  /** fetch data from the table: "projects" */
  projects: Array<Projects>;
  /** fetch aggregated fields from the table: "projects" */
  projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** An array relationship */
  scenario_allocated_upgrades: Array<Scenario_Allocated_Upgrades>;
  /** An aggregate relationship */
  scenario_allocated_upgrades_aggregate: Scenario_Allocated_Upgrades_Aggregate;
  /** fetch data from the table: "scenario_allocated_upgrades" using primary key columns */
  scenario_allocated_upgrades_by_pk?: Maybe<Scenario_Allocated_Upgrades>;
  /** An array relationship */
  scenario_constraints: Array<Scenario_Constraints>;
  /** An aggregate relationship */
  scenario_constraints_aggregate: Scenario_Constraints_Aggregate;
  /** fetch data from the table: "scenario_constraints" using primary key columns */
  scenario_constraints_by_pk?: Maybe<Scenario_Constraints>;
  /** An array relationship */
  scenario_projects: Array<Scenario_Projects>;
  /** An aggregate relationship */
  scenario_projects_aggregate: Scenario_Projects_Aggregate;
  /** fetch data from the table: "scenario_projects" using primary key columns */
  scenario_projects_by_pk?: Maybe<Scenario_Projects>;
  /** fetch data from the table: "scenario_statuses" */
  scenario_statuses: Array<Scenario_Statuses>;
  /** fetch aggregated fields from the table: "scenario_statuses" */
  scenario_statuses_aggregate: Scenario_Statuses_Aggregate;
  /** fetch data from the table: "scenario_statuses" using primary key columns */
  scenario_statuses_by_pk?: Maybe<Scenario_Statuses>;
  /** An array relationship */
  scenarios: Array<Scenarios>;
  /** An aggregate relationship */
  scenarios_aggregate: Scenarios_Aggregate;
  /** fetch data from the table: "scenarios" using primary key columns */
  scenarios_by_pk?: Maybe<Scenarios>;
  /** An array relationship */
  screening_views: Array<Screening_Views>;
  /** An aggregate relationship */
  screening_views_aggregate: Screening_Views_Aggregate;
  /** fetch data from the table: "screening_views" using primary key columns */
  screening_views_by_pk?: Maybe<Screening_Views>;
  /** An array relationship */
  subscriptions: Array<Subscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate;
  /** fetch data from the table: "subscriptions" using primary key columns */
  subscriptions_by_pk?: Maybe<Subscriptions>;
  /** fetch data from the table: "substations" */
  substations: Array<Substations>;
  /** fetch aggregated fields from the table: "substations" */
  substations_aggregate: Substations_Aggregate;
  /** fetch data from the table: "substations" using primary key columns */
  substations_by_pk?: Maybe<Substations>;
  /** fetch data from the table: "teams" */
  teams: Array<Teams>;
  /** fetch aggregated fields from the table: "teams" */
  teams_aggregate: Teams_Aggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teams_by_pk?: Maybe<Teams>;
  /** An array relationship */
  tracked_projects: Array<Tracked_Projects>;
  /** An aggregate relationship */
  tracked_projects_aggregate: Tracked_Projects_Aggregate;
  /** fetch data from the table: "tracked_projects" using primary key columns */
  tracked_projects_by_pk?: Maybe<Tracked_Projects>;
  /** An array relationship */
  unlocked_buses: Array<Unlocked_Buses>;
  /** An aggregate relationship */
  unlocked_buses_aggregate: Unlocked_Buses_Aggregate;
  /** fetch data from the table: "unlocked_buses" using primary key columns */
  unlocked_buses_by_pk?: Maybe<Unlocked_Buses>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Query_RootAll_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<All_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<All_Projects_Order_By>>;
  where?: InputMaybe<All_Projects_Bool_Exp>;
};


export type Query_RootAll_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<All_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<All_Projects_Order_By>>;
  where?: InputMaybe<All_Projects_Bool_Exp>;
};


export type Query_RootAll_Projects_By_PkArgs = {
  project_id: Scalars['String']['input'];
  region: Scalars['String']['input'];
  study_phase: Scalars['String']['input'];
};


export type Query_RootBranchesArgs = {
  distinct_on?: InputMaybe<Array<Branches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Branches_Order_By>>;
  where?: InputMaybe<Branches_Bool_Exp>;
};


export type Query_RootBranches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Branches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Branches_Order_By>>;
  where?: InputMaybe<Branches_Bool_Exp>;
};


export type Query_RootBranches_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootBusesArgs = {
  distinct_on?: InputMaybe<Array<Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buses_Order_By>>;
  where?: InputMaybe<Buses_Bool_Exp>;
};


export type Query_RootBuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buses_Order_By>>;
  where?: InputMaybe<Buses_Bool_Exp>;
};


export type Query_RootBuses_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootCapacity_CostsArgs = {
  distinct_on?: InputMaybe<Array<Capacity_Costs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Capacity_Costs_Order_By>>;
  where?: InputMaybe<Capacity_Costs_Bool_Exp>;
};


export type Query_RootCapacity_Costs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Capacity_Costs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Capacity_Costs_Order_By>>;
  where?: InputMaybe<Capacity_Costs_Bool_Exp>;
};


export type Query_RootCapacity_Costs_By_PkArgs = {
  bus_id: Scalars['String']['input'];
  energy_size: Scalars['numeric']['input'];
  includes_pre_existing: Scalars['Boolean']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
};


export type Query_RootConstraint_StacksArgs = {
  distinct_on?: InputMaybe<Array<Constraint_Stacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Constraint_Stacks_Order_By>>;
  where?: InputMaybe<Constraint_Stacks_Bool_Exp>;
};


export type Query_RootConstraint_Stacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Constraint_Stacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Constraint_Stacks_Order_By>>;
  where?: InputMaybe<Constraint_Stacks_Bool_Exp>;
};


export type Query_RootConstraint_Stacks_By_PkArgs = {
  bus_id: Scalars['String']['input'];
  monitored_facility_id: Scalars['String']['input'];
  resource_type: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
};


export type Query_RootConstraint_TypesArgs = {
  distinct_on?: InputMaybe<Array<Constraint_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Constraint_Types_Order_By>>;
  where?: InputMaybe<Constraint_Types_Bool_Exp>;
};


export type Query_RootConstraint_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Constraint_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Constraint_Types_Order_By>>;
  where?: InputMaybe<Constraint_Types_Bool_Exp>;
};


export type Query_RootConstraint_Types_By_PkArgs = {
  constraint_type: Scalars['String']['input'];
};


export type Query_RootIn_Queue_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<In_Queue_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_Queue_Subscriptions_Order_By>>;
  where?: InputMaybe<In_Queue_Subscriptions_Bool_Exp>;
};


export type Query_RootIn_Queue_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<In_Queue_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_Queue_Subscriptions_Order_By>>;
  where?: InputMaybe<In_Queue_Subscriptions_Bool_Exp>;
};


export type Query_RootIn_Queue_Subscriptions_By_PkArgs = {
  subscription_id: Scalars['uuid']['input'];
};


export type Query_RootLine_Capacity_CostsArgs = {
  distinct_on?: InputMaybe<Array<Line_Capacity_Costs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Line_Capacity_Costs_Order_By>>;
  where?: InputMaybe<Line_Capacity_Costs_Bool_Exp>;
};


export type Query_RootLine_Capacity_Costs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Line_Capacity_Costs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Line_Capacity_Costs_Order_By>>;
  where?: InputMaybe<Line_Capacity_Costs_Bool_Exp>;
};


export type Query_RootLine_Capacity_Costs_By_PkArgs = {
  energy_size: Scalars['numeric']['input'];
  includes_pre_existing: Scalars['Boolean']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
  tap_facility_id: Scalars['String']['input'];
};


export type Query_RootLine_Constraint_StacksArgs = {
  distinct_on?: InputMaybe<Array<Line_Constraint_Stacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Line_Constraint_Stacks_Order_By>>;
  where?: InputMaybe<Line_Constraint_Stacks_Bool_Exp>;
};


export type Query_RootLine_Constraint_Stacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Line_Constraint_Stacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Line_Constraint_Stacks_Order_By>>;
  where?: InputMaybe<Line_Constraint_Stacks_Bool_Exp>;
};


export type Query_RootLine_Constraint_Stacks_By_PkArgs = {
  monitored_facility_id: Scalars['String']['input'];
  resource_type: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
  tap_facility_id: Scalars['String']['input'];
};


export type Query_RootModel_LinesArgs = {
  distinct_on?: InputMaybe<Array<Model_Lines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Lines_Order_By>>;
  where?: InputMaybe<Model_Lines_Bool_Exp>;
};


export type Query_RootModel_Lines_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Model_Lines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Lines_Order_By>>;
  where?: InputMaybe<Model_Lines_Bool_Exp>;
};


export type Query_RootModel_Lines_By_PkArgs = {
  facility_id: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
};


export type Query_RootModel_Lines_To_Missing_BusesArgs = {
  distinct_on?: InputMaybe<Array<Model_Lines_To_Missing_Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Lines_To_Missing_Buses_Order_By>>;
  where?: InputMaybe<Model_Lines_To_Missing_Buses_Bool_Exp>;
};


export type Query_RootModel_Lines_To_Missing_Buses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Model_Lines_To_Missing_Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Lines_To_Missing_Buses_Order_By>>;
  where?: InputMaybe<Model_Lines_To_Missing_Buses_Bool_Exp>;
};


export type Query_RootModel_Lines_To_Missing_Buses_By_PkArgs = {
  facility_id: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
};


export type Query_RootProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Query_RootProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Query_RootProjects_By_PkArgs = {
  project_number: Scalars['String']['input'];
};


export type Query_RootScenario_Allocated_UpgradesArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Allocated_Upgrades_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Allocated_Upgrades_Order_By>>;
  where?: InputMaybe<Scenario_Allocated_Upgrades_Bool_Exp>;
};


export type Query_RootScenario_Allocated_Upgrades_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Allocated_Upgrades_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Allocated_Upgrades_Order_By>>;
  where?: InputMaybe<Scenario_Allocated_Upgrades_Bool_Exp>;
};


export type Query_RootScenario_Allocated_Upgrades_By_PkArgs = {
  constraint_type: Constraint_Types_Enum;
  network_upgrade_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
  scenario_id: Scalars['uuid']['input'];
};


export type Query_RootScenario_ConstraintsArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Constraints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Constraints_Order_By>>;
  where?: InputMaybe<Scenario_Constraints_Bool_Exp>;
};


export type Query_RootScenario_Constraints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Constraints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Constraints_Order_By>>;
  where?: InputMaybe<Scenario_Constraints_Bool_Exp>;
};


export type Query_RootScenario_Constraints_By_PkArgs = {
  constraint_type: Constraint_Types_Enum;
  facility_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
  scenario_id: Scalars['uuid']['input'];
};


export type Query_RootScenario_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Projects_Order_By>>;
  where?: InputMaybe<Scenario_Projects_Bool_Exp>;
};


export type Query_RootScenario_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Projects_Order_By>>;
  where?: InputMaybe<Scenario_Projects_Bool_Exp>;
};


export type Query_RootScenario_Projects_By_PkArgs = {
  project_id: Scalars['String']['input'];
  scenario_id: Scalars['uuid']['input'];
};


export type Query_RootScenario_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Statuses_Order_By>>;
  where?: InputMaybe<Scenario_Statuses_Bool_Exp>;
};


export type Query_RootScenario_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Statuses_Order_By>>;
  where?: InputMaybe<Scenario_Statuses_Bool_Exp>;
};


export type Query_RootScenario_Statuses_By_PkArgs = {
  status: Scalars['String']['input'];
};


export type Query_RootScenariosArgs = {
  distinct_on?: InputMaybe<Array<Scenarios_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenarios_Order_By>>;
  where?: InputMaybe<Scenarios_Bool_Exp>;
};


export type Query_RootScenarios_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scenarios_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenarios_Order_By>>;
  where?: InputMaybe<Scenarios_Bool_Exp>;
};


export type Query_RootScenarios_By_PkArgs = {
  scenario_id: Scalars['uuid']['input'];
};


export type Query_RootScreening_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Screening_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Screening_Views_Order_By>>;
  where?: InputMaybe<Screening_Views_Bool_Exp>;
};


export type Query_RootScreening_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screening_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Screening_Views_Order_By>>;
  where?: InputMaybe<Screening_Views_Bool_Exp>;
};


export type Query_RootScreening_Views_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Query_RootSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Query_RootSubscriptions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootSubstationsArgs = {
  distinct_on?: InputMaybe<Array<Substations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Substations_Order_By>>;
  where?: InputMaybe<Substations_Bool_Exp>;
};


export type Query_RootSubstations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Substations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Substations_Order_By>>;
  where?: InputMaybe<Substations_Bool_Exp>;
};


export type Query_RootSubstations_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Query_RootTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Query_RootTeams_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootTracked_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tracked_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tracked_Projects_Order_By>>;
  where?: InputMaybe<Tracked_Projects_Bool_Exp>;
};


export type Query_RootTracked_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracked_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tracked_Projects_Order_By>>;
  where?: InputMaybe<Tracked_Projects_Bool_Exp>;
};


export type Query_RootTracked_Projects_By_PkArgs = {
  project_id: Scalars['String']['input'];
  subscription_id: Scalars['uuid']['input'];
};


export type Query_RootUnlocked_BusesArgs = {
  distinct_on?: InputMaybe<Array<Unlocked_Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Unlocked_Buses_Order_By>>;
  where?: InputMaybe<Unlocked_Buses_Bool_Exp>;
};


export type Query_RootUnlocked_Buses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unlocked_Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Unlocked_Buses_Order_By>>;
  where?: InputMaybe<Unlocked_Buses_Bool_Exp>;
};


export type Query_RootUnlocked_Buses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  auth0_id: Scalars['String']['input'];
};

/** columns and relationships of "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades = {
  __typename?: 'scenario_allocated_upgrades';
  allocated_cost: Scalars['numeric']['output'];
  constraint_type: Constraint_Types_Enum;
  max_dfax: Scalars['numeric']['output'];
  max_post_study_load?: Maybe<Scalars['numeric']['output']>;
  monitored_facility_names: Scalars['jsonb']['output'];
  network_upgrade_id: Scalars['String']['output'];
  network_upgrade_name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  pmax: Scalars['numeric']['output'];
  project_id: Scalars['String']['output'];
  /** An object relationship */
  scenario: Scenarios;
  scenario_id: Scalars['uuid']['output'];
  sum_mw_impact: Scalars['numeric']['output'];
  total_cost: Scalars['numeric']['output'];
};


/** columns and relationships of "scenario_allocated_upgrades" */
export type Scenario_Allocated_UpgradesMonitored_Facility_NamesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Aggregate = {
  __typename?: 'scenario_allocated_upgrades_aggregate';
  aggregate?: Maybe<Scenario_Allocated_Upgrades_Aggregate_Fields>;
  nodes: Array<Scenario_Allocated_Upgrades>;
};

export type Scenario_Allocated_Upgrades_Aggregate_Bool_Exp = {
  count?: InputMaybe<Scenario_Allocated_Upgrades_Aggregate_Bool_Exp_Count>;
};

export type Scenario_Allocated_Upgrades_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Scenario_Allocated_Upgrades_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scenario_Allocated_Upgrades_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Aggregate_Fields = {
  __typename?: 'scenario_allocated_upgrades_aggregate_fields';
  avg?: Maybe<Scenario_Allocated_Upgrades_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Scenario_Allocated_Upgrades_Max_Fields>;
  min?: Maybe<Scenario_Allocated_Upgrades_Min_Fields>;
  stddev?: Maybe<Scenario_Allocated_Upgrades_Stddev_Fields>;
  stddev_pop?: Maybe<Scenario_Allocated_Upgrades_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Scenario_Allocated_Upgrades_Stddev_Samp_Fields>;
  sum?: Maybe<Scenario_Allocated_Upgrades_Sum_Fields>;
  var_pop?: Maybe<Scenario_Allocated_Upgrades_Var_Pop_Fields>;
  var_samp?: Maybe<Scenario_Allocated_Upgrades_Var_Samp_Fields>;
  variance?: Maybe<Scenario_Allocated_Upgrades_Variance_Fields>;
};


/** aggregate fields of "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Scenario_Allocated_Upgrades_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Aggregate_Order_By = {
  avg?: InputMaybe<Scenario_Allocated_Upgrades_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Scenario_Allocated_Upgrades_Max_Order_By>;
  min?: InputMaybe<Scenario_Allocated_Upgrades_Min_Order_By>;
  stddev?: InputMaybe<Scenario_Allocated_Upgrades_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Scenario_Allocated_Upgrades_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Scenario_Allocated_Upgrades_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Scenario_Allocated_Upgrades_Sum_Order_By>;
  var_pop?: InputMaybe<Scenario_Allocated_Upgrades_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Scenario_Allocated_Upgrades_Var_Samp_Order_By>;
  variance?: InputMaybe<Scenario_Allocated_Upgrades_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Scenario_Allocated_Upgrades_Append_Input = {
  monitored_facility_names?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Arr_Rel_Insert_Input = {
  data: Array<Scenario_Allocated_Upgrades_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Scenario_Allocated_Upgrades_On_Conflict>;
};

/** aggregate avg on columns */
export type Scenario_Allocated_Upgrades_Avg_Fields = {
  __typename?: 'scenario_allocated_upgrades_avg_fields';
  allocated_cost?: Maybe<Scalars['Float']['output']>;
  max_dfax?: Maybe<Scalars['Float']['output']>;
  max_post_study_load?: Maybe<Scalars['Float']['output']>;
  pmax?: Maybe<Scalars['Float']['output']>;
  sum_mw_impact?: Maybe<Scalars['Float']['output']>;
  total_cost?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Avg_Order_By = {
  allocated_cost?: InputMaybe<Order_By>;
  max_dfax?: InputMaybe<Order_By>;
  max_post_study_load?: InputMaybe<Order_By>;
  pmax?: InputMaybe<Order_By>;
  sum_mw_impact?: InputMaybe<Order_By>;
  total_cost?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "scenario_allocated_upgrades". All fields are combined with a logical 'AND'. */
export type Scenario_Allocated_Upgrades_Bool_Exp = {
  _and?: InputMaybe<Array<Scenario_Allocated_Upgrades_Bool_Exp>>;
  _not?: InputMaybe<Scenario_Allocated_Upgrades_Bool_Exp>;
  _or?: InputMaybe<Array<Scenario_Allocated_Upgrades_Bool_Exp>>;
  allocated_cost?: InputMaybe<Numeric_Comparison_Exp>;
  constraint_type?: InputMaybe<Constraint_Types_Enum_Comparison_Exp>;
  max_dfax?: InputMaybe<Numeric_Comparison_Exp>;
  max_post_study_load?: InputMaybe<Numeric_Comparison_Exp>;
  monitored_facility_names?: InputMaybe<Jsonb_Comparison_Exp>;
  network_upgrade_id?: InputMaybe<String_Comparison_Exp>;
  network_upgrade_name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  pmax?: InputMaybe<Numeric_Comparison_Exp>;
  project_id?: InputMaybe<String_Comparison_Exp>;
  scenario?: InputMaybe<Scenarios_Bool_Exp>;
  scenario_id?: InputMaybe<Uuid_Comparison_Exp>;
  sum_mw_impact?: InputMaybe<Numeric_Comparison_Exp>;
  total_cost?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "scenario_allocated_upgrades" */
export enum Scenario_Allocated_Upgrades_Constraint {
  /** unique or primary key constraint on columns "constraint_type", "project_id", "network_upgrade_id", "scenario_id" */
  ScenarioAllocatedUpgradesPkey = 'scenario_allocated_upgrades_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Scenario_Allocated_Upgrades_Delete_At_Path_Input = {
  monitored_facility_names?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Scenario_Allocated_Upgrades_Delete_Elem_Input = {
  monitored_facility_names?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Scenario_Allocated_Upgrades_Delete_Key_Input = {
  monitored_facility_names?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Inc_Input = {
  allocated_cost?: InputMaybe<Scalars['numeric']['input']>;
  max_dfax?: InputMaybe<Scalars['numeric']['input']>;
  max_post_study_load?: InputMaybe<Scalars['numeric']['input']>;
  pmax?: InputMaybe<Scalars['numeric']['input']>;
  sum_mw_impact?: InputMaybe<Scalars['numeric']['input']>;
  total_cost?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Insert_Input = {
  allocated_cost?: InputMaybe<Scalars['numeric']['input']>;
  constraint_type?: InputMaybe<Constraint_Types_Enum>;
  max_dfax?: InputMaybe<Scalars['numeric']['input']>;
  max_post_study_load?: InputMaybe<Scalars['numeric']['input']>;
  monitored_facility_names?: InputMaybe<Scalars['jsonb']['input']>;
  network_upgrade_id?: InputMaybe<Scalars['String']['input']>;
  network_upgrade_name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  pmax?: InputMaybe<Scalars['numeric']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  scenario?: InputMaybe<Scenarios_Obj_Rel_Insert_Input>;
  scenario_id?: InputMaybe<Scalars['uuid']['input']>;
  sum_mw_impact?: InputMaybe<Scalars['numeric']['input']>;
  total_cost?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Scenario_Allocated_Upgrades_Max_Fields = {
  __typename?: 'scenario_allocated_upgrades_max_fields';
  allocated_cost?: Maybe<Scalars['numeric']['output']>;
  max_dfax?: Maybe<Scalars['numeric']['output']>;
  max_post_study_load?: Maybe<Scalars['numeric']['output']>;
  network_upgrade_id?: Maybe<Scalars['String']['output']>;
  network_upgrade_name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  pmax?: Maybe<Scalars['numeric']['output']>;
  project_id?: Maybe<Scalars['String']['output']>;
  scenario_id?: Maybe<Scalars['uuid']['output']>;
  sum_mw_impact?: Maybe<Scalars['numeric']['output']>;
  total_cost?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Max_Order_By = {
  allocated_cost?: InputMaybe<Order_By>;
  max_dfax?: InputMaybe<Order_By>;
  max_post_study_load?: InputMaybe<Order_By>;
  network_upgrade_id?: InputMaybe<Order_By>;
  network_upgrade_name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  pmax?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  scenario_id?: InputMaybe<Order_By>;
  sum_mw_impact?: InputMaybe<Order_By>;
  total_cost?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Scenario_Allocated_Upgrades_Min_Fields = {
  __typename?: 'scenario_allocated_upgrades_min_fields';
  allocated_cost?: Maybe<Scalars['numeric']['output']>;
  max_dfax?: Maybe<Scalars['numeric']['output']>;
  max_post_study_load?: Maybe<Scalars['numeric']['output']>;
  network_upgrade_id?: Maybe<Scalars['String']['output']>;
  network_upgrade_name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  pmax?: Maybe<Scalars['numeric']['output']>;
  project_id?: Maybe<Scalars['String']['output']>;
  scenario_id?: Maybe<Scalars['uuid']['output']>;
  sum_mw_impact?: Maybe<Scalars['numeric']['output']>;
  total_cost?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Min_Order_By = {
  allocated_cost?: InputMaybe<Order_By>;
  max_dfax?: InputMaybe<Order_By>;
  max_post_study_load?: InputMaybe<Order_By>;
  network_upgrade_id?: InputMaybe<Order_By>;
  network_upgrade_name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  pmax?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  scenario_id?: InputMaybe<Order_By>;
  sum_mw_impact?: InputMaybe<Order_By>;
  total_cost?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Mutation_Response = {
  __typename?: 'scenario_allocated_upgrades_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Scenario_Allocated_Upgrades>;
};

/** on_conflict condition type for table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_On_Conflict = {
  constraint: Scenario_Allocated_Upgrades_Constraint;
  update_columns?: Array<Scenario_Allocated_Upgrades_Update_Column>;
  where?: InputMaybe<Scenario_Allocated_Upgrades_Bool_Exp>;
};

/** Ordering options when selecting data from "scenario_allocated_upgrades". */
export type Scenario_Allocated_Upgrades_Order_By = {
  allocated_cost?: InputMaybe<Order_By>;
  constraint_type?: InputMaybe<Order_By>;
  max_dfax?: InputMaybe<Order_By>;
  max_post_study_load?: InputMaybe<Order_By>;
  monitored_facility_names?: InputMaybe<Order_By>;
  network_upgrade_id?: InputMaybe<Order_By>;
  network_upgrade_name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  pmax?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  scenario?: InputMaybe<Scenarios_Order_By>;
  scenario_id?: InputMaybe<Order_By>;
  sum_mw_impact?: InputMaybe<Order_By>;
  total_cost?: InputMaybe<Order_By>;
};

/** primary key columns input for table: scenario_allocated_upgrades */
export type Scenario_Allocated_Upgrades_Pk_Columns_Input = {
  constraint_type: Constraint_Types_Enum;
  network_upgrade_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
  scenario_id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Scenario_Allocated_Upgrades_Prepend_Input = {
  monitored_facility_names?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "scenario_allocated_upgrades" */
export enum Scenario_Allocated_Upgrades_Select_Column {
  /** column name */
  AllocatedCost = 'allocated_cost',
  /** column name */
  ConstraintType = 'constraint_type',
  /** column name */
  MaxDfax = 'max_dfax',
  /** column name */
  MaxPostStudyLoad = 'max_post_study_load',
  /** column name */
  MonitoredFacilityNames = 'monitored_facility_names',
  /** column name */
  NetworkUpgradeId = 'network_upgrade_id',
  /** column name */
  NetworkUpgradeName = 'network_upgrade_name',
  /** column name */
  Notes = 'notes',
  /** column name */
  Pmax = 'pmax',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ScenarioId = 'scenario_id',
  /** column name */
  SumMwImpact = 'sum_mw_impact',
  /** column name */
  TotalCost = 'total_cost'
}

/** input type for updating data in table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Set_Input = {
  allocated_cost?: InputMaybe<Scalars['numeric']['input']>;
  constraint_type?: InputMaybe<Constraint_Types_Enum>;
  max_dfax?: InputMaybe<Scalars['numeric']['input']>;
  max_post_study_load?: InputMaybe<Scalars['numeric']['input']>;
  monitored_facility_names?: InputMaybe<Scalars['jsonb']['input']>;
  network_upgrade_id?: InputMaybe<Scalars['String']['input']>;
  network_upgrade_name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  pmax?: InputMaybe<Scalars['numeric']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  scenario_id?: InputMaybe<Scalars['uuid']['input']>;
  sum_mw_impact?: InputMaybe<Scalars['numeric']['input']>;
  total_cost?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Scenario_Allocated_Upgrades_Stddev_Fields = {
  __typename?: 'scenario_allocated_upgrades_stddev_fields';
  allocated_cost?: Maybe<Scalars['Float']['output']>;
  max_dfax?: Maybe<Scalars['Float']['output']>;
  max_post_study_load?: Maybe<Scalars['Float']['output']>;
  pmax?: Maybe<Scalars['Float']['output']>;
  sum_mw_impact?: Maybe<Scalars['Float']['output']>;
  total_cost?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Stddev_Order_By = {
  allocated_cost?: InputMaybe<Order_By>;
  max_dfax?: InputMaybe<Order_By>;
  max_post_study_load?: InputMaybe<Order_By>;
  pmax?: InputMaybe<Order_By>;
  sum_mw_impact?: InputMaybe<Order_By>;
  total_cost?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Scenario_Allocated_Upgrades_Stddev_Pop_Fields = {
  __typename?: 'scenario_allocated_upgrades_stddev_pop_fields';
  allocated_cost?: Maybe<Scalars['Float']['output']>;
  max_dfax?: Maybe<Scalars['Float']['output']>;
  max_post_study_load?: Maybe<Scalars['Float']['output']>;
  pmax?: Maybe<Scalars['Float']['output']>;
  sum_mw_impact?: Maybe<Scalars['Float']['output']>;
  total_cost?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Stddev_Pop_Order_By = {
  allocated_cost?: InputMaybe<Order_By>;
  max_dfax?: InputMaybe<Order_By>;
  max_post_study_load?: InputMaybe<Order_By>;
  pmax?: InputMaybe<Order_By>;
  sum_mw_impact?: InputMaybe<Order_By>;
  total_cost?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Scenario_Allocated_Upgrades_Stddev_Samp_Fields = {
  __typename?: 'scenario_allocated_upgrades_stddev_samp_fields';
  allocated_cost?: Maybe<Scalars['Float']['output']>;
  max_dfax?: Maybe<Scalars['Float']['output']>;
  max_post_study_load?: Maybe<Scalars['Float']['output']>;
  pmax?: Maybe<Scalars['Float']['output']>;
  sum_mw_impact?: Maybe<Scalars['Float']['output']>;
  total_cost?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Stddev_Samp_Order_By = {
  allocated_cost?: InputMaybe<Order_By>;
  max_dfax?: InputMaybe<Order_By>;
  max_post_study_load?: InputMaybe<Order_By>;
  pmax?: InputMaybe<Order_By>;
  sum_mw_impact?: InputMaybe<Order_By>;
  total_cost?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Scenario_Allocated_Upgrades_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Scenario_Allocated_Upgrades_Stream_Cursor_Value_Input = {
  allocated_cost?: InputMaybe<Scalars['numeric']['input']>;
  constraint_type?: InputMaybe<Constraint_Types_Enum>;
  max_dfax?: InputMaybe<Scalars['numeric']['input']>;
  max_post_study_load?: InputMaybe<Scalars['numeric']['input']>;
  monitored_facility_names?: InputMaybe<Scalars['jsonb']['input']>;
  network_upgrade_id?: InputMaybe<Scalars['String']['input']>;
  network_upgrade_name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  pmax?: InputMaybe<Scalars['numeric']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  scenario_id?: InputMaybe<Scalars['uuid']['input']>;
  sum_mw_impact?: InputMaybe<Scalars['numeric']['input']>;
  total_cost?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Scenario_Allocated_Upgrades_Sum_Fields = {
  __typename?: 'scenario_allocated_upgrades_sum_fields';
  allocated_cost?: Maybe<Scalars['numeric']['output']>;
  max_dfax?: Maybe<Scalars['numeric']['output']>;
  max_post_study_load?: Maybe<Scalars['numeric']['output']>;
  pmax?: Maybe<Scalars['numeric']['output']>;
  sum_mw_impact?: Maybe<Scalars['numeric']['output']>;
  total_cost?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Sum_Order_By = {
  allocated_cost?: InputMaybe<Order_By>;
  max_dfax?: InputMaybe<Order_By>;
  max_post_study_load?: InputMaybe<Order_By>;
  pmax?: InputMaybe<Order_By>;
  sum_mw_impact?: InputMaybe<Order_By>;
  total_cost?: InputMaybe<Order_By>;
};

/** update columns of table "scenario_allocated_upgrades" */
export enum Scenario_Allocated_Upgrades_Update_Column {
  /** column name */
  AllocatedCost = 'allocated_cost',
  /** column name */
  ConstraintType = 'constraint_type',
  /** column name */
  MaxDfax = 'max_dfax',
  /** column name */
  MaxPostStudyLoad = 'max_post_study_load',
  /** column name */
  MonitoredFacilityNames = 'monitored_facility_names',
  /** column name */
  NetworkUpgradeId = 'network_upgrade_id',
  /** column name */
  NetworkUpgradeName = 'network_upgrade_name',
  /** column name */
  Notes = 'notes',
  /** column name */
  Pmax = 'pmax',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ScenarioId = 'scenario_id',
  /** column name */
  SumMwImpact = 'sum_mw_impact',
  /** column name */
  TotalCost = 'total_cost'
}

export type Scenario_Allocated_Upgrades_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Scenario_Allocated_Upgrades_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Scenario_Allocated_Upgrades_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Scenario_Allocated_Upgrades_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Scenario_Allocated_Upgrades_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Scenario_Allocated_Upgrades_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Scenario_Allocated_Upgrades_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Scenario_Allocated_Upgrades_Set_Input>;
  /** filter the rows which have to be updated */
  where: Scenario_Allocated_Upgrades_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Scenario_Allocated_Upgrades_Var_Pop_Fields = {
  __typename?: 'scenario_allocated_upgrades_var_pop_fields';
  allocated_cost?: Maybe<Scalars['Float']['output']>;
  max_dfax?: Maybe<Scalars['Float']['output']>;
  max_post_study_load?: Maybe<Scalars['Float']['output']>;
  pmax?: Maybe<Scalars['Float']['output']>;
  sum_mw_impact?: Maybe<Scalars['Float']['output']>;
  total_cost?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Var_Pop_Order_By = {
  allocated_cost?: InputMaybe<Order_By>;
  max_dfax?: InputMaybe<Order_By>;
  max_post_study_load?: InputMaybe<Order_By>;
  pmax?: InputMaybe<Order_By>;
  sum_mw_impact?: InputMaybe<Order_By>;
  total_cost?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Scenario_Allocated_Upgrades_Var_Samp_Fields = {
  __typename?: 'scenario_allocated_upgrades_var_samp_fields';
  allocated_cost?: Maybe<Scalars['Float']['output']>;
  max_dfax?: Maybe<Scalars['Float']['output']>;
  max_post_study_load?: Maybe<Scalars['Float']['output']>;
  pmax?: Maybe<Scalars['Float']['output']>;
  sum_mw_impact?: Maybe<Scalars['Float']['output']>;
  total_cost?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Var_Samp_Order_By = {
  allocated_cost?: InputMaybe<Order_By>;
  max_dfax?: InputMaybe<Order_By>;
  max_post_study_load?: InputMaybe<Order_By>;
  pmax?: InputMaybe<Order_By>;
  sum_mw_impact?: InputMaybe<Order_By>;
  total_cost?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Scenario_Allocated_Upgrades_Variance_Fields = {
  __typename?: 'scenario_allocated_upgrades_variance_fields';
  allocated_cost?: Maybe<Scalars['Float']['output']>;
  max_dfax?: Maybe<Scalars['Float']['output']>;
  max_post_study_load?: Maybe<Scalars['Float']['output']>;
  pmax?: Maybe<Scalars['Float']['output']>;
  sum_mw_impact?: Maybe<Scalars['Float']['output']>;
  total_cost?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "scenario_allocated_upgrades" */
export type Scenario_Allocated_Upgrades_Variance_Order_By = {
  allocated_cost?: InputMaybe<Order_By>;
  max_dfax?: InputMaybe<Order_By>;
  max_post_study_load?: InputMaybe<Order_By>;
  pmax?: InputMaybe<Order_By>;
  sum_mw_impact?: InputMaybe<Order_By>;
  total_cost?: InputMaybe<Order_By>;
};

/** columns and relationships of "scenario_constraints" */
export type Scenario_Constraints = {
  __typename?: 'scenario_constraints';
  constraint_type: Constraint_Types_Enum;
  dfax: Scalars['numeric']['output'];
  element_type?: Maybe<Scalars['String']['output']>;
  facility_id: Scalars['String']['output'];
  line_length?: Maybe<Scalars['numeric']['output']>;
  monitored_facility_name: Scalars['String']['output'];
  mw_impact: Scalars['numeric']['output'];
  network_upgrade_names: Scalars['jsonb']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  post_study_flow?: Maybe<Scalars['String']['output']>;
  post_study_load?: Maybe<Scalars['numeric']['output']>;
  post_study_load_ac?: Maybe<Scalars['String']['output']>;
  post_study_load_dc?: Maybe<Scalars['numeric']['output']>;
  post_study_rating?: Maybe<Scalars['numeric']['output']>;
  pre_study_flow?: Maybe<Scalars['numeric']['output']>;
  pre_study_load?: Maybe<Scalars['numeric']['output']>;
  pre_study_rating: Scalars['numeric']['output'];
  project_id: Scalars['String']['output'];
  /** An object relationship */
  scenario: Scenarios;
  scenario_id: Scalars['uuid']['output'];
  voltage?: Maybe<Scalars['String']['output']>;
  worst_contingency_name?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "scenario_constraints" */
export type Scenario_ConstraintsNetwork_Upgrade_NamesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "scenario_constraints" */
export type Scenario_Constraints_Aggregate = {
  __typename?: 'scenario_constraints_aggregate';
  aggregate?: Maybe<Scenario_Constraints_Aggregate_Fields>;
  nodes: Array<Scenario_Constraints>;
};

export type Scenario_Constraints_Aggregate_Bool_Exp = {
  count?: InputMaybe<Scenario_Constraints_Aggregate_Bool_Exp_Count>;
};

export type Scenario_Constraints_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Scenario_Constraints_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scenario_Constraints_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "scenario_constraints" */
export type Scenario_Constraints_Aggregate_Fields = {
  __typename?: 'scenario_constraints_aggregate_fields';
  avg?: Maybe<Scenario_Constraints_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Scenario_Constraints_Max_Fields>;
  min?: Maybe<Scenario_Constraints_Min_Fields>;
  stddev?: Maybe<Scenario_Constraints_Stddev_Fields>;
  stddev_pop?: Maybe<Scenario_Constraints_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Scenario_Constraints_Stddev_Samp_Fields>;
  sum?: Maybe<Scenario_Constraints_Sum_Fields>;
  var_pop?: Maybe<Scenario_Constraints_Var_Pop_Fields>;
  var_samp?: Maybe<Scenario_Constraints_Var_Samp_Fields>;
  variance?: Maybe<Scenario_Constraints_Variance_Fields>;
};


/** aggregate fields of "scenario_constraints" */
export type Scenario_Constraints_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Scenario_Constraints_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "scenario_constraints" */
export type Scenario_Constraints_Aggregate_Order_By = {
  avg?: InputMaybe<Scenario_Constraints_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Scenario_Constraints_Max_Order_By>;
  min?: InputMaybe<Scenario_Constraints_Min_Order_By>;
  stddev?: InputMaybe<Scenario_Constraints_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Scenario_Constraints_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Scenario_Constraints_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Scenario_Constraints_Sum_Order_By>;
  var_pop?: InputMaybe<Scenario_Constraints_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Scenario_Constraints_Var_Samp_Order_By>;
  variance?: InputMaybe<Scenario_Constraints_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Scenario_Constraints_Append_Input = {
  network_upgrade_names?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "scenario_constraints" */
export type Scenario_Constraints_Arr_Rel_Insert_Input = {
  data: Array<Scenario_Constraints_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Scenario_Constraints_On_Conflict>;
};

/** aggregate avg on columns */
export type Scenario_Constraints_Avg_Fields = {
  __typename?: 'scenario_constraints_avg_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  mw_impact?: Maybe<Scalars['Float']['output']>;
  post_study_load?: Maybe<Scalars['Float']['output']>;
  post_study_load_dc?: Maybe<Scalars['Float']['output']>;
  post_study_rating?: Maybe<Scalars['Float']['output']>;
  pre_study_flow?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  pre_study_rating?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "scenario_constraints" */
export type Scenario_Constraints_Avg_Order_By = {
  dfax?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  mw_impact?: InputMaybe<Order_By>;
  post_study_load?: InputMaybe<Order_By>;
  post_study_load_dc?: InputMaybe<Order_By>;
  post_study_rating?: InputMaybe<Order_By>;
  pre_study_flow?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  pre_study_rating?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "scenario_constraints". All fields are combined with a logical 'AND'. */
export type Scenario_Constraints_Bool_Exp = {
  _and?: InputMaybe<Array<Scenario_Constraints_Bool_Exp>>;
  _not?: InputMaybe<Scenario_Constraints_Bool_Exp>;
  _or?: InputMaybe<Array<Scenario_Constraints_Bool_Exp>>;
  constraint_type?: InputMaybe<Constraint_Types_Enum_Comparison_Exp>;
  dfax?: InputMaybe<Numeric_Comparison_Exp>;
  element_type?: InputMaybe<String_Comparison_Exp>;
  facility_id?: InputMaybe<String_Comparison_Exp>;
  line_length?: InputMaybe<Numeric_Comparison_Exp>;
  monitored_facility_name?: InputMaybe<String_Comparison_Exp>;
  mw_impact?: InputMaybe<Numeric_Comparison_Exp>;
  network_upgrade_names?: InputMaybe<Jsonb_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  post_study_flow?: InputMaybe<String_Comparison_Exp>;
  post_study_load?: InputMaybe<Numeric_Comparison_Exp>;
  post_study_load_ac?: InputMaybe<String_Comparison_Exp>;
  post_study_load_dc?: InputMaybe<Numeric_Comparison_Exp>;
  post_study_rating?: InputMaybe<Numeric_Comparison_Exp>;
  pre_study_flow?: InputMaybe<Numeric_Comparison_Exp>;
  pre_study_load?: InputMaybe<Numeric_Comparison_Exp>;
  pre_study_rating?: InputMaybe<Numeric_Comparison_Exp>;
  project_id?: InputMaybe<String_Comparison_Exp>;
  scenario?: InputMaybe<Scenarios_Bool_Exp>;
  scenario_id?: InputMaybe<Uuid_Comparison_Exp>;
  voltage?: InputMaybe<String_Comparison_Exp>;
  worst_contingency_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "scenario_constraints" */
export enum Scenario_Constraints_Constraint {
  /** unique or primary key constraint on columns "facility_id", "constraint_type", "project_id", "scenario_id" */
  ScenarioConstraintsPkey = 'scenario_constraints_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Scenario_Constraints_Delete_At_Path_Input = {
  network_upgrade_names?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Scenario_Constraints_Delete_Elem_Input = {
  network_upgrade_names?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Scenario_Constraints_Delete_Key_Input = {
  network_upgrade_names?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "scenario_constraints" */
export type Scenario_Constraints_Inc_Input = {
  dfax?: InputMaybe<Scalars['numeric']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  mw_impact?: InputMaybe<Scalars['numeric']['input']>;
  post_study_load?: InputMaybe<Scalars['numeric']['input']>;
  post_study_load_dc?: InputMaybe<Scalars['numeric']['input']>;
  post_study_rating?: InputMaybe<Scalars['numeric']['input']>;
  pre_study_flow?: InputMaybe<Scalars['numeric']['input']>;
  pre_study_load?: InputMaybe<Scalars['numeric']['input']>;
  pre_study_rating?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "scenario_constraints" */
export type Scenario_Constraints_Insert_Input = {
  constraint_type?: InputMaybe<Constraint_Types_Enum>;
  dfax?: InputMaybe<Scalars['numeric']['input']>;
  element_type?: InputMaybe<Scalars['String']['input']>;
  facility_id?: InputMaybe<Scalars['String']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  monitored_facility_name?: InputMaybe<Scalars['String']['input']>;
  mw_impact?: InputMaybe<Scalars['numeric']['input']>;
  network_upgrade_names?: InputMaybe<Scalars['jsonb']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  post_study_flow?: InputMaybe<Scalars['String']['input']>;
  post_study_load?: InputMaybe<Scalars['numeric']['input']>;
  post_study_load_ac?: InputMaybe<Scalars['String']['input']>;
  post_study_load_dc?: InputMaybe<Scalars['numeric']['input']>;
  post_study_rating?: InputMaybe<Scalars['numeric']['input']>;
  pre_study_flow?: InputMaybe<Scalars['numeric']['input']>;
  pre_study_load?: InputMaybe<Scalars['numeric']['input']>;
  pre_study_rating?: InputMaybe<Scalars['numeric']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  scenario?: InputMaybe<Scenarios_Obj_Rel_Insert_Input>;
  scenario_id?: InputMaybe<Scalars['uuid']['input']>;
  voltage?: InputMaybe<Scalars['String']['input']>;
  worst_contingency_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Scenario_Constraints_Max_Fields = {
  __typename?: 'scenario_constraints_max_fields';
  dfax?: Maybe<Scalars['numeric']['output']>;
  element_type?: Maybe<Scalars['String']['output']>;
  facility_id?: Maybe<Scalars['String']['output']>;
  line_length?: Maybe<Scalars['numeric']['output']>;
  monitored_facility_name?: Maybe<Scalars['String']['output']>;
  mw_impact?: Maybe<Scalars['numeric']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  post_study_flow?: Maybe<Scalars['String']['output']>;
  post_study_load?: Maybe<Scalars['numeric']['output']>;
  post_study_load_ac?: Maybe<Scalars['String']['output']>;
  post_study_load_dc?: Maybe<Scalars['numeric']['output']>;
  post_study_rating?: Maybe<Scalars['numeric']['output']>;
  pre_study_flow?: Maybe<Scalars['numeric']['output']>;
  pre_study_load?: Maybe<Scalars['numeric']['output']>;
  pre_study_rating?: Maybe<Scalars['numeric']['output']>;
  project_id?: Maybe<Scalars['String']['output']>;
  scenario_id?: Maybe<Scalars['uuid']['output']>;
  voltage?: Maybe<Scalars['String']['output']>;
  worst_contingency_name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "scenario_constraints" */
export type Scenario_Constraints_Max_Order_By = {
  dfax?: InputMaybe<Order_By>;
  element_type?: InputMaybe<Order_By>;
  facility_id?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  monitored_facility_name?: InputMaybe<Order_By>;
  mw_impact?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  post_study_flow?: InputMaybe<Order_By>;
  post_study_load?: InputMaybe<Order_By>;
  post_study_load_ac?: InputMaybe<Order_By>;
  post_study_load_dc?: InputMaybe<Order_By>;
  post_study_rating?: InputMaybe<Order_By>;
  pre_study_flow?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  pre_study_rating?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  scenario_id?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
  worst_contingency_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Scenario_Constraints_Min_Fields = {
  __typename?: 'scenario_constraints_min_fields';
  dfax?: Maybe<Scalars['numeric']['output']>;
  element_type?: Maybe<Scalars['String']['output']>;
  facility_id?: Maybe<Scalars['String']['output']>;
  line_length?: Maybe<Scalars['numeric']['output']>;
  monitored_facility_name?: Maybe<Scalars['String']['output']>;
  mw_impact?: Maybe<Scalars['numeric']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  post_study_flow?: Maybe<Scalars['String']['output']>;
  post_study_load?: Maybe<Scalars['numeric']['output']>;
  post_study_load_ac?: Maybe<Scalars['String']['output']>;
  post_study_load_dc?: Maybe<Scalars['numeric']['output']>;
  post_study_rating?: Maybe<Scalars['numeric']['output']>;
  pre_study_flow?: Maybe<Scalars['numeric']['output']>;
  pre_study_load?: Maybe<Scalars['numeric']['output']>;
  pre_study_rating?: Maybe<Scalars['numeric']['output']>;
  project_id?: Maybe<Scalars['String']['output']>;
  scenario_id?: Maybe<Scalars['uuid']['output']>;
  voltage?: Maybe<Scalars['String']['output']>;
  worst_contingency_name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "scenario_constraints" */
export type Scenario_Constraints_Min_Order_By = {
  dfax?: InputMaybe<Order_By>;
  element_type?: InputMaybe<Order_By>;
  facility_id?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  monitored_facility_name?: InputMaybe<Order_By>;
  mw_impact?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  post_study_flow?: InputMaybe<Order_By>;
  post_study_load?: InputMaybe<Order_By>;
  post_study_load_ac?: InputMaybe<Order_By>;
  post_study_load_dc?: InputMaybe<Order_By>;
  post_study_rating?: InputMaybe<Order_By>;
  pre_study_flow?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  pre_study_rating?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  scenario_id?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
  worst_contingency_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "scenario_constraints" */
export type Scenario_Constraints_Mutation_Response = {
  __typename?: 'scenario_constraints_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Scenario_Constraints>;
};

/** on_conflict condition type for table "scenario_constraints" */
export type Scenario_Constraints_On_Conflict = {
  constraint: Scenario_Constraints_Constraint;
  update_columns?: Array<Scenario_Constraints_Update_Column>;
  where?: InputMaybe<Scenario_Constraints_Bool_Exp>;
};

/** Ordering options when selecting data from "scenario_constraints". */
export type Scenario_Constraints_Order_By = {
  constraint_type?: InputMaybe<Order_By>;
  dfax?: InputMaybe<Order_By>;
  element_type?: InputMaybe<Order_By>;
  facility_id?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  monitored_facility_name?: InputMaybe<Order_By>;
  mw_impact?: InputMaybe<Order_By>;
  network_upgrade_names?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  post_study_flow?: InputMaybe<Order_By>;
  post_study_load?: InputMaybe<Order_By>;
  post_study_load_ac?: InputMaybe<Order_By>;
  post_study_load_dc?: InputMaybe<Order_By>;
  post_study_rating?: InputMaybe<Order_By>;
  pre_study_flow?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  pre_study_rating?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  scenario?: InputMaybe<Scenarios_Order_By>;
  scenario_id?: InputMaybe<Order_By>;
  voltage?: InputMaybe<Order_By>;
  worst_contingency_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: scenario_constraints */
export type Scenario_Constraints_Pk_Columns_Input = {
  constraint_type: Constraint_Types_Enum;
  facility_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
  scenario_id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Scenario_Constraints_Prepend_Input = {
  network_upgrade_names?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "scenario_constraints" */
export enum Scenario_Constraints_Select_Column {
  /** column name */
  ConstraintType = 'constraint_type',
  /** column name */
  Dfax = 'dfax',
  /** column name */
  ElementType = 'element_type',
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  LineLength = 'line_length',
  /** column name */
  MonitoredFacilityName = 'monitored_facility_name',
  /** column name */
  MwImpact = 'mw_impact',
  /** column name */
  NetworkUpgradeNames = 'network_upgrade_names',
  /** column name */
  Notes = 'notes',
  /** column name */
  PostStudyFlow = 'post_study_flow',
  /** column name */
  PostStudyLoad = 'post_study_load',
  /** column name */
  PostStudyLoadAc = 'post_study_load_ac',
  /** column name */
  PostStudyLoadDc = 'post_study_load_dc',
  /** column name */
  PostStudyRating = 'post_study_rating',
  /** column name */
  PreStudyFlow = 'pre_study_flow',
  /** column name */
  PreStudyLoad = 'pre_study_load',
  /** column name */
  PreStudyRating = 'pre_study_rating',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ScenarioId = 'scenario_id',
  /** column name */
  Voltage = 'voltage',
  /** column name */
  WorstContingencyName = 'worst_contingency_name'
}

/** input type for updating data in table "scenario_constraints" */
export type Scenario_Constraints_Set_Input = {
  constraint_type?: InputMaybe<Constraint_Types_Enum>;
  dfax?: InputMaybe<Scalars['numeric']['input']>;
  element_type?: InputMaybe<Scalars['String']['input']>;
  facility_id?: InputMaybe<Scalars['String']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  monitored_facility_name?: InputMaybe<Scalars['String']['input']>;
  mw_impact?: InputMaybe<Scalars['numeric']['input']>;
  network_upgrade_names?: InputMaybe<Scalars['jsonb']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  post_study_flow?: InputMaybe<Scalars['String']['input']>;
  post_study_load?: InputMaybe<Scalars['numeric']['input']>;
  post_study_load_ac?: InputMaybe<Scalars['String']['input']>;
  post_study_load_dc?: InputMaybe<Scalars['numeric']['input']>;
  post_study_rating?: InputMaybe<Scalars['numeric']['input']>;
  pre_study_flow?: InputMaybe<Scalars['numeric']['input']>;
  pre_study_load?: InputMaybe<Scalars['numeric']['input']>;
  pre_study_rating?: InputMaybe<Scalars['numeric']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  scenario_id?: InputMaybe<Scalars['uuid']['input']>;
  voltage?: InputMaybe<Scalars['String']['input']>;
  worst_contingency_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Scenario_Constraints_Stddev_Fields = {
  __typename?: 'scenario_constraints_stddev_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  mw_impact?: Maybe<Scalars['Float']['output']>;
  post_study_load?: Maybe<Scalars['Float']['output']>;
  post_study_load_dc?: Maybe<Scalars['Float']['output']>;
  post_study_rating?: Maybe<Scalars['Float']['output']>;
  pre_study_flow?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  pre_study_rating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "scenario_constraints" */
export type Scenario_Constraints_Stddev_Order_By = {
  dfax?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  mw_impact?: InputMaybe<Order_By>;
  post_study_load?: InputMaybe<Order_By>;
  post_study_load_dc?: InputMaybe<Order_By>;
  post_study_rating?: InputMaybe<Order_By>;
  pre_study_flow?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  pre_study_rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Scenario_Constraints_Stddev_Pop_Fields = {
  __typename?: 'scenario_constraints_stddev_pop_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  mw_impact?: Maybe<Scalars['Float']['output']>;
  post_study_load?: Maybe<Scalars['Float']['output']>;
  post_study_load_dc?: Maybe<Scalars['Float']['output']>;
  post_study_rating?: Maybe<Scalars['Float']['output']>;
  pre_study_flow?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  pre_study_rating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "scenario_constraints" */
export type Scenario_Constraints_Stddev_Pop_Order_By = {
  dfax?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  mw_impact?: InputMaybe<Order_By>;
  post_study_load?: InputMaybe<Order_By>;
  post_study_load_dc?: InputMaybe<Order_By>;
  post_study_rating?: InputMaybe<Order_By>;
  pre_study_flow?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  pre_study_rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Scenario_Constraints_Stddev_Samp_Fields = {
  __typename?: 'scenario_constraints_stddev_samp_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  mw_impact?: Maybe<Scalars['Float']['output']>;
  post_study_load?: Maybe<Scalars['Float']['output']>;
  post_study_load_dc?: Maybe<Scalars['Float']['output']>;
  post_study_rating?: Maybe<Scalars['Float']['output']>;
  pre_study_flow?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  pre_study_rating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "scenario_constraints" */
export type Scenario_Constraints_Stddev_Samp_Order_By = {
  dfax?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  mw_impact?: InputMaybe<Order_By>;
  post_study_load?: InputMaybe<Order_By>;
  post_study_load_dc?: InputMaybe<Order_By>;
  post_study_rating?: InputMaybe<Order_By>;
  pre_study_flow?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  pre_study_rating?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "scenario_constraints" */
export type Scenario_Constraints_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Scenario_Constraints_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Scenario_Constraints_Stream_Cursor_Value_Input = {
  constraint_type?: InputMaybe<Constraint_Types_Enum>;
  dfax?: InputMaybe<Scalars['numeric']['input']>;
  element_type?: InputMaybe<Scalars['String']['input']>;
  facility_id?: InputMaybe<Scalars['String']['input']>;
  line_length?: InputMaybe<Scalars['numeric']['input']>;
  monitored_facility_name?: InputMaybe<Scalars['String']['input']>;
  mw_impact?: InputMaybe<Scalars['numeric']['input']>;
  network_upgrade_names?: InputMaybe<Scalars['jsonb']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  post_study_flow?: InputMaybe<Scalars['String']['input']>;
  post_study_load?: InputMaybe<Scalars['numeric']['input']>;
  post_study_load_ac?: InputMaybe<Scalars['String']['input']>;
  post_study_load_dc?: InputMaybe<Scalars['numeric']['input']>;
  post_study_rating?: InputMaybe<Scalars['numeric']['input']>;
  pre_study_flow?: InputMaybe<Scalars['numeric']['input']>;
  pre_study_load?: InputMaybe<Scalars['numeric']['input']>;
  pre_study_rating?: InputMaybe<Scalars['numeric']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  scenario_id?: InputMaybe<Scalars['uuid']['input']>;
  voltage?: InputMaybe<Scalars['String']['input']>;
  worst_contingency_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Scenario_Constraints_Sum_Fields = {
  __typename?: 'scenario_constraints_sum_fields';
  dfax?: Maybe<Scalars['numeric']['output']>;
  line_length?: Maybe<Scalars['numeric']['output']>;
  mw_impact?: Maybe<Scalars['numeric']['output']>;
  post_study_load?: Maybe<Scalars['numeric']['output']>;
  post_study_load_dc?: Maybe<Scalars['numeric']['output']>;
  post_study_rating?: Maybe<Scalars['numeric']['output']>;
  pre_study_flow?: Maybe<Scalars['numeric']['output']>;
  pre_study_load?: Maybe<Scalars['numeric']['output']>;
  pre_study_rating?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "scenario_constraints" */
export type Scenario_Constraints_Sum_Order_By = {
  dfax?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  mw_impact?: InputMaybe<Order_By>;
  post_study_load?: InputMaybe<Order_By>;
  post_study_load_dc?: InputMaybe<Order_By>;
  post_study_rating?: InputMaybe<Order_By>;
  pre_study_flow?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  pre_study_rating?: InputMaybe<Order_By>;
};

/** update columns of table "scenario_constraints" */
export enum Scenario_Constraints_Update_Column {
  /** column name */
  ConstraintType = 'constraint_type',
  /** column name */
  Dfax = 'dfax',
  /** column name */
  ElementType = 'element_type',
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  LineLength = 'line_length',
  /** column name */
  MonitoredFacilityName = 'monitored_facility_name',
  /** column name */
  MwImpact = 'mw_impact',
  /** column name */
  NetworkUpgradeNames = 'network_upgrade_names',
  /** column name */
  Notes = 'notes',
  /** column name */
  PostStudyFlow = 'post_study_flow',
  /** column name */
  PostStudyLoad = 'post_study_load',
  /** column name */
  PostStudyLoadAc = 'post_study_load_ac',
  /** column name */
  PostStudyLoadDc = 'post_study_load_dc',
  /** column name */
  PostStudyRating = 'post_study_rating',
  /** column name */
  PreStudyFlow = 'pre_study_flow',
  /** column name */
  PreStudyLoad = 'pre_study_load',
  /** column name */
  PreStudyRating = 'pre_study_rating',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ScenarioId = 'scenario_id',
  /** column name */
  Voltage = 'voltage',
  /** column name */
  WorstContingencyName = 'worst_contingency_name'
}

export type Scenario_Constraints_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Scenario_Constraints_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Scenario_Constraints_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Scenario_Constraints_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Scenario_Constraints_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Scenario_Constraints_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Scenario_Constraints_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Scenario_Constraints_Set_Input>;
  /** filter the rows which have to be updated */
  where: Scenario_Constraints_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Scenario_Constraints_Var_Pop_Fields = {
  __typename?: 'scenario_constraints_var_pop_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  mw_impact?: Maybe<Scalars['Float']['output']>;
  post_study_load?: Maybe<Scalars['Float']['output']>;
  post_study_load_dc?: Maybe<Scalars['Float']['output']>;
  post_study_rating?: Maybe<Scalars['Float']['output']>;
  pre_study_flow?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  pre_study_rating?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "scenario_constraints" */
export type Scenario_Constraints_Var_Pop_Order_By = {
  dfax?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  mw_impact?: InputMaybe<Order_By>;
  post_study_load?: InputMaybe<Order_By>;
  post_study_load_dc?: InputMaybe<Order_By>;
  post_study_rating?: InputMaybe<Order_By>;
  pre_study_flow?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  pre_study_rating?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Scenario_Constraints_Var_Samp_Fields = {
  __typename?: 'scenario_constraints_var_samp_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  mw_impact?: Maybe<Scalars['Float']['output']>;
  post_study_load?: Maybe<Scalars['Float']['output']>;
  post_study_load_dc?: Maybe<Scalars['Float']['output']>;
  post_study_rating?: Maybe<Scalars['Float']['output']>;
  pre_study_flow?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  pre_study_rating?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "scenario_constraints" */
export type Scenario_Constraints_Var_Samp_Order_By = {
  dfax?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  mw_impact?: InputMaybe<Order_By>;
  post_study_load?: InputMaybe<Order_By>;
  post_study_load_dc?: InputMaybe<Order_By>;
  post_study_rating?: InputMaybe<Order_By>;
  pre_study_flow?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  pre_study_rating?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Scenario_Constraints_Variance_Fields = {
  __typename?: 'scenario_constraints_variance_fields';
  dfax?: Maybe<Scalars['Float']['output']>;
  line_length?: Maybe<Scalars['Float']['output']>;
  mw_impact?: Maybe<Scalars['Float']['output']>;
  post_study_load?: Maybe<Scalars['Float']['output']>;
  post_study_load_dc?: Maybe<Scalars['Float']['output']>;
  post_study_rating?: Maybe<Scalars['Float']['output']>;
  pre_study_flow?: Maybe<Scalars['Float']['output']>;
  pre_study_load?: Maybe<Scalars['Float']['output']>;
  pre_study_rating?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "scenario_constraints" */
export type Scenario_Constraints_Variance_Order_By = {
  dfax?: InputMaybe<Order_By>;
  line_length?: InputMaybe<Order_By>;
  mw_impact?: InputMaybe<Order_By>;
  post_study_load?: InputMaybe<Order_By>;
  post_study_load_dc?: InputMaybe<Order_By>;
  post_study_rating?: InputMaybe<Order_By>;
  pre_study_flow?: InputMaybe<Order_By>;
  pre_study_load?: InputMaybe<Order_By>;
  pre_study_rating?: InputMaybe<Order_By>;
};

/** columns and relationships of "scenario_projects" */
export type Scenario_Projects = {
  __typename?: 'scenario_projects';
  assigned_upgrades: Scalars['jsonb']['output'];
  cost_per_mw: Scalars['numeric']['output'];
  eris_size_mw: Scalars['numeric']['output'];
  nris_size_mw: Scalars['numeric']['output'];
  overloaded_facilities: Scalars['jsonb']['output'];
  project_id: Scalars['String']['output'];
  /** An object relationship */
  scenario: Scenarios;
  scenario_id: Scalars['uuid']['output'];
  sum_allocated_cost: Scalars['numeric']['output'];
  sum_allocated_cost_by_constraint_type?: Maybe<Scalars['jsonb']['output']>;
};


/** columns and relationships of "scenario_projects" */
export type Scenario_ProjectsAssigned_UpgradesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "scenario_projects" */
export type Scenario_ProjectsOverloaded_FacilitiesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "scenario_projects" */
export type Scenario_ProjectsSum_Allocated_Cost_By_Constraint_TypeArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "scenario_projects" */
export type Scenario_Projects_Aggregate = {
  __typename?: 'scenario_projects_aggregate';
  aggregate?: Maybe<Scenario_Projects_Aggregate_Fields>;
  nodes: Array<Scenario_Projects>;
};

export type Scenario_Projects_Aggregate_Bool_Exp = {
  count?: InputMaybe<Scenario_Projects_Aggregate_Bool_Exp_Count>;
};

export type Scenario_Projects_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Scenario_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scenario_Projects_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "scenario_projects" */
export type Scenario_Projects_Aggregate_Fields = {
  __typename?: 'scenario_projects_aggregate_fields';
  avg?: Maybe<Scenario_Projects_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Scenario_Projects_Max_Fields>;
  min?: Maybe<Scenario_Projects_Min_Fields>;
  stddev?: Maybe<Scenario_Projects_Stddev_Fields>;
  stddev_pop?: Maybe<Scenario_Projects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Scenario_Projects_Stddev_Samp_Fields>;
  sum?: Maybe<Scenario_Projects_Sum_Fields>;
  var_pop?: Maybe<Scenario_Projects_Var_Pop_Fields>;
  var_samp?: Maybe<Scenario_Projects_Var_Samp_Fields>;
  variance?: Maybe<Scenario_Projects_Variance_Fields>;
};


/** aggregate fields of "scenario_projects" */
export type Scenario_Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Scenario_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "scenario_projects" */
export type Scenario_Projects_Aggregate_Order_By = {
  avg?: InputMaybe<Scenario_Projects_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Scenario_Projects_Max_Order_By>;
  min?: InputMaybe<Scenario_Projects_Min_Order_By>;
  stddev?: InputMaybe<Scenario_Projects_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Scenario_Projects_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Scenario_Projects_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Scenario_Projects_Sum_Order_By>;
  var_pop?: InputMaybe<Scenario_Projects_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Scenario_Projects_Var_Samp_Order_By>;
  variance?: InputMaybe<Scenario_Projects_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Scenario_Projects_Append_Input = {
  assigned_upgrades?: InputMaybe<Scalars['jsonb']['input']>;
  overloaded_facilities?: InputMaybe<Scalars['jsonb']['input']>;
  sum_allocated_cost_by_constraint_type?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "scenario_projects" */
export type Scenario_Projects_Arr_Rel_Insert_Input = {
  data: Array<Scenario_Projects_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Scenario_Projects_On_Conflict>;
};

/** aggregate avg on columns */
export type Scenario_Projects_Avg_Fields = {
  __typename?: 'scenario_projects_avg_fields';
  cost_per_mw?: Maybe<Scalars['Float']['output']>;
  eris_size_mw?: Maybe<Scalars['Float']['output']>;
  nris_size_mw?: Maybe<Scalars['Float']['output']>;
  sum_allocated_cost?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "scenario_projects" */
export type Scenario_Projects_Avg_Order_By = {
  cost_per_mw?: InputMaybe<Order_By>;
  eris_size_mw?: InputMaybe<Order_By>;
  nris_size_mw?: InputMaybe<Order_By>;
  sum_allocated_cost?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "scenario_projects". All fields are combined with a logical 'AND'. */
export type Scenario_Projects_Bool_Exp = {
  _and?: InputMaybe<Array<Scenario_Projects_Bool_Exp>>;
  _not?: InputMaybe<Scenario_Projects_Bool_Exp>;
  _or?: InputMaybe<Array<Scenario_Projects_Bool_Exp>>;
  assigned_upgrades?: InputMaybe<Jsonb_Comparison_Exp>;
  cost_per_mw?: InputMaybe<Numeric_Comparison_Exp>;
  eris_size_mw?: InputMaybe<Numeric_Comparison_Exp>;
  nris_size_mw?: InputMaybe<Numeric_Comparison_Exp>;
  overloaded_facilities?: InputMaybe<Jsonb_Comparison_Exp>;
  project_id?: InputMaybe<String_Comparison_Exp>;
  scenario?: InputMaybe<Scenarios_Bool_Exp>;
  scenario_id?: InputMaybe<Uuid_Comparison_Exp>;
  sum_allocated_cost?: InputMaybe<Numeric_Comparison_Exp>;
  sum_allocated_cost_by_constraint_type?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "scenario_projects" */
export enum Scenario_Projects_Constraint {
  /** unique or primary key constraint on columns "project_id", "scenario_id" */
  ScenarioProjectsPkey = 'scenario_projects_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Scenario_Projects_Delete_At_Path_Input = {
  assigned_upgrades?: InputMaybe<Array<Scalars['String']['input']>>;
  overloaded_facilities?: InputMaybe<Array<Scalars['String']['input']>>;
  sum_allocated_cost_by_constraint_type?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Scenario_Projects_Delete_Elem_Input = {
  assigned_upgrades?: InputMaybe<Scalars['Int']['input']>;
  overloaded_facilities?: InputMaybe<Scalars['Int']['input']>;
  sum_allocated_cost_by_constraint_type?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Scenario_Projects_Delete_Key_Input = {
  assigned_upgrades?: InputMaybe<Scalars['String']['input']>;
  overloaded_facilities?: InputMaybe<Scalars['String']['input']>;
  sum_allocated_cost_by_constraint_type?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "scenario_projects" */
export type Scenario_Projects_Inc_Input = {
  cost_per_mw?: InputMaybe<Scalars['numeric']['input']>;
  eris_size_mw?: InputMaybe<Scalars['numeric']['input']>;
  nris_size_mw?: InputMaybe<Scalars['numeric']['input']>;
  sum_allocated_cost?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "scenario_projects" */
export type Scenario_Projects_Insert_Input = {
  assigned_upgrades?: InputMaybe<Scalars['jsonb']['input']>;
  cost_per_mw?: InputMaybe<Scalars['numeric']['input']>;
  eris_size_mw?: InputMaybe<Scalars['numeric']['input']>;
  nris_size_mw?: InputMaybe<Scalars['numeric']['input']>;
  overloaded_facilities?: InputMaybe<Scalars['jsonb']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  scenario?: InputMaybe<Scenarios_Obj_Rel_Insert_Input>;
  scenario_id?: InputMaybe<Scalars['uuid']['input']>;
  sum_allocated_cost?: InputMaybe<Scalars['numeric']['input']>;
  sum_allocated_cost_by_constraint_type?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type Scenario_Projects_Max_Fields = {
  __typename?: 'scenario_projects_max_fields';
  cost_per_mw?: Maybe<Scalars['numeric']['output']>;
  eris_size_mw?: Maybe<Scalars['numeric']['output']>;
  nris_size_mw?: Maybe<Scalars['numeric']['output']>;
  project_id?: Maybe<Scalars['String']['output']>;
  scenario_id?: Maybe<Scalars['uuid']['output']>;
  sum_allocated_cost?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "scenario_projects" */
export type Scenario_Projects_Max_Order_By = {
  cost_per_mw?: InputMaybe<Order_By>;
  eris_size_mw?: InputMaybe<Order_By>;
  nris_size_mw?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  scenario_id?: InputMaybe<Order_By>;
  sum_allocated_cost?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Scenario_Projects_Min_Fields = {
  __typename?: 'scenario_projects_min_fields';
  cost_per_mw?: Maybe<Scalars['numeric']['output']>;
  eris_size_mw?: Maybe<Scalars['numeric']['output']>;
  nris_size_mw?: Maybe<Scalars['numeric']['output']>;
  project_id?: Maybe<Scalars['String']['output']>;
  scenario_id?: Maybe<Scalars['uuid']['output']>;
  sum_allocated_cost?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "scenario_projects" */
export type Scenario_Projects_Min_Order_By = {
  cost_per_mw?: InputMaybe<Order_By>;
  eris_size_mw?: InputMaybe<Order_By>;
  nris_size_mw?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  scenario_id?: InputMaybe<Order_By>;
  sum_allocated_cost?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "scenario_projects" */
export type Scenario_Projects_Mutation_Response = {
  __typename?: 'scenario_projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Scenario_Projects>;
};

/** on_conflict condition type for table "scenario_projects" */
export type Scenario_Projects_On_Conflict = {
  constraint: Scenario_Projects_Constraint;
  update_columns?: Array<Scenario_Projects_Update_Column>;
  where?: InputMaybe<Scenario_Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "scenario_projects". */
export type Scenario_Projects_Order_By = {
  assigned_upgrades?: InputMaybe<Order_By>;
  cost_per_mw?: InputMaybe<Order_By>;
  eris_size_mw?: InputMaybe<Order_By>;
  nris_size_mw?: InputMaybe<Order_By>;
  overloaded_facilities?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  scenario?: InputMaybe<Scenarios_Order_By>;
  scenario_id?: InputMaybe<Order_By>;
  sum_allocated_cost?: InputMaybe<Order_By>;
  sum_allocated_cost_by_constraint_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: scenario_projects */
export type Scenario_Projects_Pk_Columns_Input = {
  project_id: Scalars['String']['input'];
  scenario_id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Scenario_Projects_Prepend_Input = {
  assigned_upgrades?: InputMaybe<Scalars['jsonb']['input']>;
  overloaded_facilities?: InputMaybe<Scalars['jsonb']['input']>;
  sum_allocated_cost_by_constraint_type?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "scenario_projects" */
export enum Scenario_Projects_Select_Column {
  /** column name */
  AssignedUpgrades = 'assigned_upgrades',
  /** column name */
  CostPerMw = 'cost_per_mw',
  /** column name */
  ErisSizeMw = 'eris_size_mw',
  /** column name */
  NrisSizeMw = 'nris_size_mw',
  /** column name */
  OverloadedFacilities = 'overloaded_facilities',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ScenarioId = 'scenario_id',
  /** column name */
  SumAllocatedCost = 'sum_allocated_cost',
  /** column name */
  SumAllocatedCostByConstraintType = 'sum_allocated_cost_by_constraint_type'
}

/** input type for updating data in table "scenario_projects" */
export type Scenario_Projects_Set_Input = {
  assigned_upgrades?: InputMaybe<Scalars['jsonb']['input']>;
  cost_per_mw?: InputMaybe<Scalars['numeric']['input']>;
  eris_size_mw?: InputMaybe<Scalars['numeric']['input']>;
  nris_size_mw?: InputMaybe<Scalars['numeric']['input']>;
  overloaded_facilities?: InputMaybe<Scalars['jsonb']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  scenario_id?: InputMaybe<Scalars['uuid']['input']>;
  sum_allocated_cost?: InputMaybe<Scalars['numeric']['input']>;
  sum_allocated_cost_by_constraint_type?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type Scenario_Projects_Stddev_Fields = {
  __typename?: 'scenario_projects_stddev_fields';
  cost_per_mw?: Maybe<Scalars['Float']['output']>;
  eris_size_mw?: Maybe<Scalars['Float']['output']>;
  nris_size_mw?: Maybe<Scalars['Float']['output']>;
  sum_allocated_cost?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "scenario_projects" */
export type Scenario_Projects_Stddev_Order_By = {
  cost_per_mw?: InputMaybe<Order_By>;
  eris_size_mw?: InputMaybe<Order_By>;
  nris_size_mw?: InputMaybe<Order_By>;
  sum_allocated_cost?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Scenario_Projects_Stddev_Pop_Fields = {
  __typename?: 'scenario_projects_stddev_pop_fields';
  cost_per_mw?: Maybe<Scalars['Float']['output']>;
  eris_size_mw?: Maybe<Scalars['Float']['output']>;
  nris_size_mw?: Maybe<Scalars['Float']['output']>;
  sum_allocated_cost?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "scenario_projects" */
export type Scenario_Projects_Stddev_Pop_Order_By = {
  cost_per_mw?: InputMaybe<Order_By>;
  eris_size_mw?: InputMaybe<Order_By>;
  nris_size_mw?: InputMaybe<Order_By>;
  sum_allocated_cost?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Scenario_Projects_Stddev_Samp_Fields = {
  __typename?: 'scenario_projects_stddev_samp_fields';
  cost_per_mw?: Maybe<Scalars['Float']['output']>;
  eris_size_mw?: Maybe<Scalars['Float']['output']>;
  nris_size_mw?: Maybe<Scalars['Float']['output']>;
  sum_allocated_cost?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "scenario_projects" */
export type Scenario_Projects_Stddev_Samp_Order_By = {
  cost_per_mw?: InputMaybe<Order_By>;
  eris_size_mw?: InputMaybe<Order_By>;
  nris_size_mw?: InputMaybe<Order_By>;
  sum_allocated_cost?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "scenario_projects" */
export type Scenario_Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Scenario_Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Scenario_Projects_Stream_Cursor_Value_Input = {
  assigned_upgrades?: InputMaybe<Scalars['jsonb']['input']>;
  cost_per_mw?: InputMaybe<Scalars['numeric']['input']>;
  eris_size_mw?: InputMaybe<Scalars['numeric']['input']>;
  nris_size_mw?: InputMaybe<Scalars['numeric']['input']>;
  overloaded_facilities?: InputMaybe<Scalars['jsonb']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  scenario_id?: InputMaybe<Scalars['uuid']['input']>;
  sum_allocated_cost?: InputMaybe<Scalars['numeric']['input']>;
  sum_allocated_cost_by_constraint_type?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type Scenario_Projects_Sum_Fields = {
  __typename?: 'scenario_projects_sum_fields';
  cost_per_mw?: Maybe<Scalars['numeric']['output']>;
  eris_size_mw?: Maybe<Scalars['numeric']['output']>;
  nris_size_mw?: Maybe<Scalars['numeric']['output']>;
  sum_allocated_cost?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "scenario_projects" */
export type Scenario_Projects_Sum_Order_By = {
  cost_per_mw?: InputMaybe<Order_By>;
  eris_size_mw?: InputMaybe<Order_By>;
  nris_size_mw?: InputMaybe<Order_By>;
  sum_allocated_cost?: InputMaybe<Order_By>;
};

/** update columns of table "scenario_projects" */
export enum Scenario_Projects_Update_Column {
  /** column name */
  AssignedUpgrades = 'assigned_upgrades',
  /** column name */
  CostPerMw = 'cost_per_mw',
  /** column name */
  ErisSizeMw = 'eris_size_mw',
  /** column name */
  NrisSizeMw = 'nris_size_mw',
  /** column name */
  OverloadedFacilities = 'overloaded_facilities',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ScenarioId = 'scenario_id',
  /** column name */
  SumAllocatedCost = 'sum_allocated_cost',
  /** column name */
  SumAllocatedCostByConstraintType = 'sum_allocated_cost_by_constraint_type'
}

export type Scenario_Projects_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Scenario_Projects_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Scenario_Projects_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Scenario_Projects_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Scenario_Projects_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Scenario_Projects_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Scenario_Projects_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Scenario_Projects_Set_Input>;
  /** filter the rows which have to be updated */
  where: Scenario_Projects_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Scenario_Projects_Var_Pop_Fields = {
  __typename?: 'scenario_projects_var_pop_fields';
  cost_per_mw?: Maybe<Scalars['Float']['output']>;
  eris_size_mw?: Maybe<Scalars['Float']['output']>;
  nris_size_mw?: Maybe<Scalars['Float']['output']>;
  sum_allocated_cost?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "scenario_projects" */
export type Scenario_Projects_Var_Pop_Order_By = {
  cost_per_mw?: InputMaybe<Order_By>;
  eris_size_mw?: InputMaybe<Order_By>;
  nris_size_mw?: InputMaybe<Order_By>;
  sum_allocated_cost?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Scenario_Projects_Var_Samp_Fields = {
  __typename?: 'scenario_projects_var_samp_fields';
  cost_per_mw?: Maybe<Scalars['Float']['output']>;
  eris_size_mw?: Maybe<Scalars['Float']['output']>;
  nris_size_mw?: Maybe<Scalars['Float']['output']>;
  sum_allocated_cost?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "scenario_projects" */
export type Scenario_Projects_Var_Samp_Order_By = {
  cost_per_mw?: InputMaybe<Order_By>;
  eris_size_mw?: InputMaybe<Order_By>;
  nris_size_mw?: InputMaybe<Order_By>;
  sum_allocated_cost?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Scenario_Projects_Variance_Fields = {
  __typename?: 'scenario_projects_variance_fields';
  cost_per_mw?: Maybe<Scalars['Float']['output']>;
  eris_size_mw?: Maybe<Scalars['Float']['output']>;
  nris_size_mw?: Maybe<Scalars['Float']['output']>;
  sum_allocated_cost?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "scenario_projects" */
export type Scenario_Projects_Variance_Order_By = {
  cost_per_mw?: InputMaybe<Order_By>;
  eris_size_mw?: InputMaybe<Order_By>;
  nris_size_mw?: InputMaybe<Order_By>;
  sum_allocated_cost?: InputMaybe<Order_By>;
};

/** All valid statuses on a scenario */
export type Scenario_Statuses = {
  __typename?: 'scenario_statuses';
  description: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

/** aggregated selection of "scenario_statuses" */
export type Scenario_Statuses_Aggregate = {
  __typename?: 'scenario_statuses_aggregate';
  aggregate?: Maybe<Scenario_Statuses_Aggregate_Fields>;
  nodes: Array<Scenario_Statuses>;
};

/** aggregate fields of "scenario_statuses" */
export type Scenario_Statuses_Aggregate_Fields = {
  __typename?: 'scenario_statuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Scenario_Statuses_Max_Fields>;
  min?: Maybe<Scenario_Statuses_Min_Fields>;
};


/** aggregate fields of "scenario_statuses" */
export type Scenario_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Scenario_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "scenario_statuses". All fields are combined with a logical 'AND'. */
export type Scenario_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Scenario_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Scenario_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Scenario_Statuses_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "scenario_statuses" */
export enum Scenario_Statuses_Constraint {
  /** unique or primary key constraint on columns "status" */
  ScenarioStatusesPkey = 'scenario_statuses_pkey'
}

export enum Scenario_Statuses_Enum {
  /** The scenario was successfully completed; however, it has since been archived and is no longer accessible. */
  Archived = 'ARCHIVED',
  /** Scenario has been created in a draft state */
  Draft = 'DRAFT',
  /** The first step of the pipeline has been run */
  Running = 'RUNNING',
  /** The run completed with a failure */
  RunFailed = 'RUN_FAILED',
  /** The user has requested a run of this scenario; it is no longer editable */
  RunRequested = 'RUN_REQUESTED',
  /** The pipeline sensor has submitted a run for the scenario */
  RunSubmitted = 'RUN_SUBMITTED',
  /** The run completed and successfully wrote results to the database */
  RunSucceeded = 'RUN_SUCCEEDED'
}

/** Boolean expression to compare columns of type "scenario_statuses_enum". All fields are combined with logical 'AND'. */
export type Scenario_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scenario_Statuses_Enum>;
  _in?: InputMaybe<Array<Scenario_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scenario_Statuses_Enum>;
  _nin?: InputMaybe<Array<Scenario_Statuses_Enum>>;
};

/** input type for inserting data into table "scenario_statuses" */
export type Scenario_Statuses_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Scenario_Statuses_Max_Fields = {
  __typename?: 'scenario_statuses_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Scenario_Statuses_Min_Fields = {
  __typename?: 'scenario_statuses_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "scenario_statuses" */
export type Scenario_Statuses_Mutation_Response = {
  __typename?: 'scenario_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Scenario_Statuses>;
};

/** on_conflict condition type for table "scenario_statuses" */
export type Scenario_Statuses_On_Conflict = {
  constraint: Scenario_Statuses_Constraint;
  update_columns?: Array<Scenario_Statuses_Update_Column>;
  where?: InputMaybe<Scenario_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "scenario_statuses". */
export type Scenario_Statuses_Order_By = {
  description?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: scenario_statuses */
export type Scenario_Statuses_Pk_Columns_Input = {
  status: Scalars['String']['input'];
};

/** select columns of table "scenario_statuses" */
export enum Scenario_Statuses_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "scenario_statuses" */
export type Scenario_Statuses_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "scenario_statuses" */
export type Scenario_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Scenario_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Scenario_Statuses_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "scenario_statuses" */
export enum Scenario_Statuses_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Status = 'status'
}

export type Scenario_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Scenario_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Scenario_Statuses_Bool_Exp;
};

/** columns and relationships of "scenarios" */
export type Scenarios = {
  __typename?: 'scenarios';
  cost_assumptions: Scalars['jsonb']['output'];
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  dagster_run_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  in_queue_subscription?: Maybe<In_Queue_Subscriptions>;
  input_data_used?: Maybe<Scalars['jsonb']['output']>;
  parent_project_id?: Maybe<Scalars['String']['output']>;
  project_size_assumptions: Scalars['jsonb']['output'];
  region: Scalars['String']['output'];
  /** An object relationship */
  relevant_subscriptions?: Maybe<In_Queue_Subscriptions>;
  run_completed_at?: Maybe<Scalars['timestamptz']['output']>;
  run_started_at?: Maybe<Scalars['timestamptz']['output']>;
  run_submitted_at?: Maybe<Scalars['timestamptz']['output']>;
  run_submitted_by?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  scenario_allocated_upgrades: Array<Scenario_Allocated_Upgrades>;
  /** An aggregate relationship */
  scenario_allocated_upgrades_aggregate: Scenario_Allocated_Upgrades_Aggregate;
  /** An array relationship */
  scenario_constraints: Array<Scenario_Constraints>;
  /** An aggregate relationship */
  scenario_constraints_aggregate: Scenario_Constraints_Aggregate;
  scenario_id: Scalars['uuid']['output'];
  /** An array relationship */
  scenario_projects: Array<Scenario_Projects>;
  /** An aggregate relationship */
  scenario_projects_aggregate: Scenario_Projects_Aggregate;
  scenario_stage: Scalars['String']['output'];
  scenario_type: Scalars['String']['output'];
  status: Scenario_Statuses_Enum;
  study_cycle: Scalars['String']['output'];
  study_group: Scalars['String']['output'];
  study_phase: Scalars['String']['output'];
  subscription_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  team: Teams;
  team_id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "scenarios" */
export type ScenariosCost_AssumptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "scenarios" */
export type ScenariosInput_Data_UsedArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "scenarios" */
export type ScenariosProject_Size_AssumptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "scenarios" */
export type ScenariosScenario_Allocated_UpgradesArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Allocated_Upgrades_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Allocated_Upgrades_Order_By>>;
  where?: InputMaybe<Scenario_Allocated_Upgrades_Bool_Exp>;
};


/** columns and relationships of "scenarios" */
export type ScenariosScenario_Allocated_Upgrades_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Allocated_Upgrades_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Allocated_Upgrades_Order_By>>;
  where?: InputMaybe<Scenario_Allocated_Upgrades_Bool_Exp>;
};


/** columns and relationships of "scenarios" */
export type ScenariosScenario_ConstraintsArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Constraints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Constraints_Order_By>>;
  where?: InputMaybe<Scenario_Constraints_Bool_Exp>;
};


/** columns and relationships of "scenarios" */
export type ScenariosScenario_Constraints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Constraints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Constraints_Order_By>>;
  where?: InputMaybe<Scenario_Constraints_Bool_Exp>;
};


/** columns and relationships of "scenarios" */
export type ScenariosScenario_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Projects_Order_By>>;
  where?: InputMaybe<Scenario_Projects_Bool_Exp>;
};


/** columns and relationships of "scenarios" */
export type ScenariosScenario_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Projects_Order_By>>;
  where?: InputMaybe<Scenario_Projects_Bool_Exp>;
};

/** aggregated selection of "scenarios" */
export type Scenarios_Aggregate = {
  __typename?: 'scenarios_aggregate';
  aggregate?: Maybe<Scenarios_Aggregate_Fields>;
  nodes: Array<Scenarios>;
};

export type Scenarios_Aggregate_Bool_Exp = {
  count?: InputMaybe<Scenarios_Aggregate_Bool_Exp_Count>;
};

export type Scenarios_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Scenarios_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scenarios_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "scenarios" */
export type Scenarios_Aggregate_Fields = {
  __typename?: 'scenarios_aggregate_fields';
  avg?: Maybe<Scenarios_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Scenarios_Max_Fields>;
  min?: Maybe<Scenarios_Min_Fields>;
  stddev?: Maybe<Scenarios_Stddev_Fields>;
  stddev_pop?: Maybe<Scenarios_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Scenarios_Stddev_Samp_Fields>;
  sum?: Maybe<Scenarios_Sum_Fields>;
  var_pop?: Maybe<Scenarios_Var_Pop_Fields>;
  var_samp?: Maybe<Scenarios_Var_Samp_Fields>;
  variance?: Maybe<Scenarios_Variance_Fields>;
};


/** aggregate fields of "scenarios" */
export type Scenarios_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Scenarios_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "scenarios" */
export type Scenarios_Aggregate_Order_By = {
  avg?: InputMaybe<Scenarios_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Scenarios_Max_Order_By>;
  min?: InputMaybe<Scenarios_Min_Order_By>;
  stddev?: InputMaybe<Scenarios_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Scenarios_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Scenarios_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Scenarios_Sum_Order_By>;
  var_pop?: InputMaybe<Scenarios_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Scenarios_Var_Samp_Order_By>;
  variance?: InputMaybe<Scenarios_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Scenarios_Append_Input = {
  cost_assumptions?: InputMaybe<Scalars['jsonb']['input']>;
  input_data_used?: InputMaybe<Scalars['jsonb']['input']>;
  project_size_assumptions?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "scenarios" */
export type Scenarios_Arr_Rel_Insert_Input = {
  data: Array<Scenarios_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Scenarios_On_Conflict>;
};

/** aggregate avg on columns */
export type Scenarios_Avg_Fields = {
  __typename?: 'scenarios_avg_fields';
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "scenarios" */
export type Scenarios_Avg_Order_By = {
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "scenarios". All fields are combined with a logical 'AND'. */
export type Scenarios_Bool_Exp = {
  _and?: InputMaybe<Array<Scenarios_Bool_Exp>>;
  _not?: InputMaybe<Scenarios_Bool_Exp>;
  _or?: InputMaybe<Array<Scenarios_Bool_Exp>>;
  cost_assumptions?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  dagster_run_id?: InputMaybe<String_Comparison_Exp>;
  in_queue_subscription?: InputMaybe<In_Queue_Subscriptions_Bool_Exp>;
  input_data_used?: InputMaybe<Jsonb_Comparison_Exp>;
  parent_project_id?: InputMaybe<String_Comparison_Exp>;
  project_size_assumptions?: InputMaybe<Jsonb_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  relevant_subscriptions?: InputMaybe<In_Queue_Subscriptions_Bool_Exp>;
  run_completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  run_started_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  run_submitted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  run_submitted_by?: InputMaybe<String_Comparison_Exp>;
  scenario_allocated_upgrades?: InputMaybe<Scenario_Allocated_Upgrades_Bool_Exp>;
  scenario_allocated_upgrades_aggregate?: InputMaybe<Scenario_Allocated_Upgrades_Aggregate_Bool_Exp>;
  scenario_constraints?: InputMaybe<Scenario_Constraints_Bool_Exp>;
  scenario_constraints_aggregate?: InputMaybe<Scenario_Constraints_Aggregate_Bool_Exp>;
  scenario_id?: InputMaybe<Uuid_Comparison_Exp>;
  scenario_projects?: InputMaybe<Scenario_Projects_Bool_Exp>;
  scenario_projects_aggregate?: InputMaybe<Scenario_Projects_Aggregate_Bool_Exp>;
  scenario_stage?: InputMaybe<String_Comparison_Exp>;
  scenario_type?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Scenario_Statuses_Enum_Comparison_Exp>;
  study_cycle?: InputMaybe<String_Comparison_Exp>;
  study_group?: InputMaybe<String_Comparison_Exp>;
  study_phase?: InputMaybe<String_Comparison_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "scenarios" */
export enum Scenarios_Constraint {
  /** unique or primary key constraint on columns "scenario_id" */
  ScenariosPkey = 'scenarios_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Scenarios_Delete_At_Path_Input = {
  cost_assumptions?: InputMaybe<Array<Scalars['String']['input']>>;
  input_data_used?: InputMaybe<Array<Scalars['String']['input']>>;
  project_size_assumptions?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Scenarios_Delete_Elem_Input = {
  cost_assumptions?: InputMaybe<Scalars['Int']['input']>;
  input_data_used?: InputMaybe<Scalars['Int']['input']>;
  project_size_assumptions?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Scenarios_Delete_Key_Input = {
  cost_assumptions?: InputMaybe<Scalars['String']['input']>;
  input_data_used?: InputMaybe<Scalars['String']['input']>;
  project_size_assumptions?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "scenarios" */
export type Scenarios_Inc_Input = {
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "scenarios" */
export type Scenarios_Insert_Input = {
  cost_assumptions?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  dagster_run_id?: InputMaybe<Scalars['String']['input']>;
  in_queue_subscription?: InputMaybe<In_Queue_Subscriptions_Obj_Rel_Insert_Input>;
  input_data_used?: InputMaybe<Scalars['jsonb']['input']>;
  parent_project_id?: InputMaybe<Scalars['String']['input']>;
  project_size_assumptions?: InputMaybe<Scalars['jsonb']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  relevant_subscriptions?: InputMaybe<In_Queue_Subscriptions_Obj_Rel_Insert_Input>;
  run_completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  run_started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  run_submitted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  run_submitted_by?: InputMaybe<Scalars['String']['input']>;
  scenario_allocated_upgrades?: InputMaybe<Scenario_Allocated_Upgrades_Arr_Rel_Insert_Input>;
  scenario_constraints?: InputMaybe<Scenario_Constraints_Arr_Rel_Insert_Input>;
  scenario_id?: InputMaybe<Scalars['uuid']['input']>;
  scenario_projects?: InputMaybe<Scenario_Projects_Arr_Rel_Insert_Input>;
  scenario_stage?: InputMaybe<Scalars['String']['input']>;
  scenario_type?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scenario_Statuses_Enum>;
  study_cycle?: InputMaybe<Scalars['String']['input']>;
  study_group?: InputMaybe<Scalars['String']['input']>;
  study_phase?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['uuid']['input']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Scenarios_Max_Fields = {
  __typename?: 'scenarios_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  dagster_run_id?: Maybe<Scalars['String']['output']>;
  parent_project_id?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  run_completed_at?: Maybe<Scalars['timestamptz']['output']>;
  run_started_at?: Maybe<Scalars['timestamptz']['output']>;
  run_submitted_at?: Maybe<Scalars['timestamptz']['output']>;
  run_submitted_by?: Maybe<Scalars['String']['output']>;
  scenario_id?: Maybe<Scalars['uuid']['output']>;
  scenario_stage?: Maybe<Scalars['String']['output']>;
  scenario_type?: Maybe<Scalars['String']['output']>;
  study_cycle?: Maybe<Scalars['String']['output']>;
  study_group?: Maybe<Scalars['String']['output']>;
  study_phase?: Maybe<Scalars['String']['output']>;
  subscription_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "scenarios" */
export type Scenarios_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  dagster_run_id?: InputMaybe<Order_By>;
  parent_project_id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  run_completed_at?: InputMaybe<Order_By>;
  run_started_at?: InputMaybe<Order_By>;
  run_submitted_at?: InputMaybe<Order_By>;
  run_submitted_by?: InputMaybe<Order_By>;
  scenario_id?: InputMaybe<Order_By>;
  scenario_stage?: InputMaybe<Order_By>;
  scenario_type?: InputMaybe<Order_By>;
  study_cycle?: InputMaybe<Order_By>;
  study_group?: InputMaybe<Order_By>;
  study_phase?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Scenarios_Min_Fields = {
  __typename?: 'scenarios_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  dagster_run_id?: Maybe<Scalars['String']['output']>;
  parent_project_id?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  run_completed_at?: Maybe<Scalars['timestamptz']['output']>;
  run_started_at?: Maybe<Scalars['timestamptz']['output']>;
  run_submitted_at?: Maybe<Scalars['timestamptz']['output']>;
  run_submitted_by?: Maybe<Scalars['String']['output']>;
  scenario_id?: Maybe<Scalars['uuid']['output']>;
  scenario_stage?: Maybe<Scalars['String']['output']>;
  scenario_type?: Maybe<Scalars['String']['output']>;
  study_cycle?: Maybe<Scalars['String']['output']>;
  study_group?: Maybe<Scalars['String']['output']>;
  study_phase?: Maybe<Scalars['String']['output']>;
  subscription_id?: Maybe<Scalars['uuid']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "scenarios" */
export type Scenarios_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  dagster_run_id?: InputMaybe<Order_By>;
  parent_project_id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  run_completed_at?: InputMaybe<Order_By>;
  run_started_at?: InputMaybe<Order_By>;
  run_submitted_at?: InputMaybe<Order_By>;
  run_submitted_by?: InputMaybe<Order_By>;
  scenario_id?: InputMaybe<Order_By>;
  scenario_stage?: InputMaybe<Order_By>;
  scenario_type?: InputMaybe<Order_By>;
  study_cycle?: InputMaybe<Order_By>;
  study_group?: InputMaybe<Order_By>;
  study_phase?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "scenarios" */
export type Scenarios_Mutation_Response = {
  __typename?: 'scenarios_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Scenarios>;
};

/** input type for inserting object relation for remote table "scenarios" */
export type Scenarios_Obj_Rel_Insert_Input = {
  data: Scenarios_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Scenarios_On_Conflict>;
};

/** on_conflict condition type for table "scenarios" */
export type Scenarios_On_Conflict = {
  constraint: Scenarios_Constraint;
  update_columns?: Array<Scenarios_Update_Column>;
  where?: InputMaybe<Scenarios_Bool_Exp>;
};

/** Ordering options when selecting data from "scenarios". */
export type Scenarios_Order_By = {
  cost_assumptions?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  dagster_run_id?: InputMaybe<Order_By>;
  in_queue_subscription?: InputMaybe<In_Queue_Subscriptions_Order_By>;
  input_data_used?: InputMaybe<Order_By>;
  parent_project_id?: InputMaybe<Order_By>;
  project_size_assumptions?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  relevant_subscriptions?: InputMaybe<In_Queue_Subscriptions_Order_By>;
  run_completed_at?: InputMaybe<Order_By>;
  run_started_at?: InputMaybe<Order_By>;
  run_submitted_at?: InputMaybe<Order_By>;
  run_submitted_by?: InputMaybe<Order_By>;
  scenario_allocated_upgrades_aggregate?: InputMaybe<Scenario_Allocated_Upgrades_Aggregate_Order_By>;
  scenario_constraints_aggregate?: InputMaybe<Scenario_Constraints_Aggregate_Order_By>;
  scenario_id?: InputMaybe<Order_By>;
  scenario_projects_aggregate?: InputMaybe<Scenario_Projects_Aggregate_Order_By>;
  scenario_stage?: InputMaybe<Order_By>;
  scenario_type?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  study_cycle?: InputMaybe<Order_By>;
  study_group?: InputMaybe<Order_By>;
  study_phase?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: scenarios */
export type Scenarios_Pk_Columns_Input = {
  scenario_id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Scenarios_Prepend_Input = {
  cost_assumptions?: InputMaybe<Scalars['jsonb']['input']>;
  input_data_used?: InputMaybe<Scalars['jsonb']['input']>;
  project_size_assumptions?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "scenarios" */
export enum Scenarios_Select_Column {
  /** column name */
  CostAssumptions = 'cost_assumptions',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DagsterRunId = 'dagster_run_id',
  /** column name */
  InputDataUsed = 'input_data_used',
  /** column name */
  ParentProjectId = 'parent_project_id',
  /** column name */
  ProjectSizeAssumptions = 'project_size_assumptions',
  /** column name */
  Region = 'region',
  /** column name */
  RunCompletedAt = 'run_completed_at',
  /** column name */
  RunStartedAt = 'run_started_at',
  /** column name */
  RunSubmittedAt = 'run_submitted_at',
  /** column name */
  RunSubmittedBy = 'run_submitted_by',
  /** column name */
  ScenarioId = 'scenario_id',
  /** column name */
  ScenarioStage = 'scenario_stage',
  /** column name */
  ScenarioType = 'scenario_type',
  /** column name */
  Status = 'status',
  /** column name */
  StudyCycle = 'study_cycle',
  /** column name */
  StudyGroup = 'study_group',
  /** column name */
  StudyPhase = 'study_phase',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "scenarios" */
export type Scenarios_Set_Input = {
  cost_assumptions?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  dagster_run_id?: InputMaybe<Scalars['String']['input']>;
  input_data_used?: InputMaybe<Scalars['jsonb']['input']>;
  parent_project_id?: InputMaybe<Scalars['String']['input']>;
  project_size_assumptions?: InputMaybe<Scalars['jsonb']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  run_completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  run_started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  run_submitted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  run_submitted_by?: InputMaybe<Scalars['String']['input']>;
  scenario_id?: InputMaybe<Scalars['uuid']['input']>;
  scenario_stage?: InputMaybe<Scalars['String']['input']>;
  scenario_type?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scenario_Statuses_Enum>;
  study_cycle?: InputMaybe<Scalars['String']['input']>;
  study_group?: InputMaybe<Scalars['String']['input']>;
  study_phase?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['uuid']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Scenarios_Stddev_Fields = {
  __typename?: 'scenarios_stddev_fields';
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "scenarios" */
export type Scenarios_Stddev_Order_By = {
  team_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Scenarios_Stddev_Pop_Fields = {
  __typename?: 'scenarios_stddev_pop_fields';
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "scenarios" */
export type Scenarios_Stddev_Pop_Order_By = {
  team_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Scenarios_Stddev_Samp_Fields = {
  __typename?: 'scenarios_stddev_samp_fields';
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "scenarios" */
export type Scenarios_Stddev_Samp_Order_By = {
  team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "scenarios" */
export type Scenarios_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Scenarios_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Scenarios_Stream_Cursor_Value_Input = {
  cost_assumptions?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  dagster_run_id?: InputMaybe<Scalars['String']['input']>;
  input_data_used?: InputMaybe<Scalars['jsonb']['input']>;
  parent_project_id?: InputMaybe<Scalars['String']['input']>;
  project_size_assumptions?: InputMaybe<Scalars['jsonb']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  run_completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  run_started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  run_submitted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  run_submitted_by?: InputMaybe<Scalars['String']['input']>;
  scenario_id?: InputMaybe<Scalars['uuid']['input']>;
  scenario_stage?: InputMaybe<Scalars['String']['input']>;
  scenario_type?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scenario_Statuses_Enum>;
  study_cycle?: InputMaybe<Scalars['String']['input']>;
  study_group?: InputMaybe<Scalars['String']['input']>;
  study_phase?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['uuid']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Scenarios_Sum_Fields = {
  __typename?: 'scenarios_sum_fields';
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "scenarios" */
export type Scenarios_Sum_Order_By = {
  team_id?: InputMaybe<Order_By>;
};

/** update columns of table "scenarios" */
export enum Scenarios_Update_Column {
  /** column name */
  CostAssumptions = 'cost_assumptions',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DagsterRunId = 'dagster_run_id',
  /** column name */
  InputDataUsed = 'input_data_used',
  /** column name */
  ParentProjectId = 'parent_project_id',
  /** column name */
  ProjectSizeAssumptions = 'project_size_assumptions',
  /** column name */
  Region = 'region',
  /** column name */
  RunCompletedAt = 'run_completed_at',
  /** column name */
  RunStartedAt = 'run_started_at',
  /** column name */
  RunSubmittedAt = 'run_submitted_at',
  /** column name */
  RunSubmittedBy = 'run_submitted_by',
  /** column name */
  ScenarioId = 'scenario_id',
  /** column name */
  ScenarioStage = 'scenario_stage',
  /** column name */
  ScenarioType = 'scenario_type',
  /** column name */
  Status = 'status',
  /** column name */
  StudyCycle = 'study_cycle',
  /** column name */
  StudyGroup = 'study_group',
  /** column name */
  StudyPhase = 'study_phase',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type Scenarios_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Scenarios_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Scenarios_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Scenarios_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Scenarios_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Scenarios_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Scenarios_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Scenarios_Set_Input>;
  /** filter the rows which have to be updated */
  where: Scenarios_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Scenarios_Var_Pop_Fields = {
  __typename?: 'scenarios_var_pop_fields';
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "scenarios" */
export type Scenarios_Var_Pop_Order_By = {
  team_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Scenarios_Var_Samp_Fields = {
  __typename?: 'scenarios_var_samp_fields';
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "scenarios" */
export type Scenarios_Var_Samp_Order_By = {
  team_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Scenarios_Variance_Fields = {
  __typename?: 'scenarios_variance_fields';
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "scenarios" */
export type Scenarios_Variance_Order_By = {
  team_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "screening_views" */
export type Screening_Views = {
  __typename?: 'screening_views';
  created_at: Scalars['timestamptz']['output'];
  created_by: Scalars['String']['output'];
  description: Scalars['String']['output'];
  generator_type: Scalars['String']['output'];
  hide_locked_buses: Scalars['Boolean']['output'];
  hide_low_confidence_buses: Scalars['Boolean']['output'];
  high_threshold: Scalars['numeric']['output'];
  id: Scalars['String']['output'];
  includes_pre_existing: Scalars['Boolean']['output'];
  is_archived: Scalars['Boolean']['output'];
  low_threshold: Scalars['numeric']['output'];
  max_allocated_costs?: Maybe<Scalars['numeric']['output']>;
  max_total_costs?: Maybe<Scalars['numeric']['output']>;
  medium_threshold: Scalars['numeric']['output'];
  region: Scalars['String']['output'];
  scope: Scalars['String']['output'];
  states: Scalars['jsonb']['output'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  /** An object relationship */
  user: Users;
  voltages: Scalars['jsonb']['output'];
};


/** columns and relationships of "screening_views" */
export type Screening_ViewsStatesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "screening_views" */
export type Screening_ViewsVoltagesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "screening_views" */
export type Screening_Views_Aggregate = {
  __typename?: 'screening_views_aggregate';
  aggregate?: Maybe<Screening_Views_Aggregate_Fields>;
  nodes: Array<Screening_Views>;
};

export type Screening_Views_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Screening_Views_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Screening_Views_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Screening_Views_Aggregate_Bool_Exp_Count>;
};

export type Screening_Views_Aggregate_Bool_Exp_Bool_And = {
  arguments: Screening_Views_Select_Column_Screening_Views_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Screening_Views_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Screening_Views_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Screening_Views_Select_Column_Screening_Views_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Screening_Views_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Screening_Views_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Screening_Views_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Screening_Views_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "screening_views" */
export type Screening_Views_Aggregate_Fields = {
  __typename?: 'screening_views_aggregate_fields';
  avg?: Maybe<Screening_Views_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Screening_Views_Max_Fields>;
  min?: Maybe<Screening_Views_Min_Fields>;
  stddev?: Maybe<Screening_Views_Stddev_Fields>;
  stddev_pop?: Maybe<Screening_Views_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Screening_Views_Stddev_Samp_Fields>;
  sum?: Maybe<Screening_Views_Sum_Fields>;
  var_pop?: Maybe<Screening_Views_Var_Pop_Fields>;
  var_samp?: Maybe<Screening_Views_Var_Samp_Fields>;
  variance?: Maybe<Screening_Views_Variance_Fields>;
};


/** aggregate fields of "screening_views" */
export type Screening_Views_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Screening_Views_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "screening_views" */
export type Screening_Views_Aggregate_Order_By = {
  avg?: InputMaybe<Screening_Views_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Screening_Views_Max_Order_By>;
  min?: InputMaybe<Screening_Views_Min_Order_By>;
  stddev?: InputMaybe<Screening_Views_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Screening_Views_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Screening_Views_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Screening_Views_Sum_Order_By>;
  var_pop?: InputMaybe<Screening_Views_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Screening_Views_Var_Samp_Order_By>;
  variance?: InputMaybe<Screening_Views_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Screening_Views_Append_Input = {
  states?: InputMaybe<Scalars['jsonb']['input']>;
  voltages?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "screening_views" */
export type Screening_Views_Arr_Rel_Insert_Input = {
  data: Array<Screening_Views_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Screening_Views_On_Conflict>;
};

/** aggregate avg on columns */
export type Screening_Views_Avg_Fields = {
  __typename?: 'screening_views_avg_fields';
  high_threshold?: Maybe<Scalars['Float']['output']>;
  low_threshold?: Maybe<Scalars['Float']['output']>;
  max_allocated_costs?: Maybe<Scalars['Float']['output']>;
  max_total_costs?: Maybe<Scalars['Float']['output']>;
  medium_threshold?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "screening_views" */
export type Screening_Views_Avg_Order_By = {
  high_threshold?: InputMaybe<Order_By>;
  low_threshold?: InputMaybe<Order_By>;
  max_allocated_costs?: InputMaybe<Order_By>;
  max_total_costs?: InputMaybe<Order_By>;
  medium_threshold?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "screening_views". All fields are combined with a logical 'AND'. */
export type Screening_Views_Bool_Exp = {
  _and?: InputMaybe<Array<Screening_Views_Bool_Exp>>;
  _not?: InputMaybe<Screening_Views_Bool_Exp>;
  _or?: InputMaybe<Array<Screening_Views_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  generator_type?: InputMaybe<String_Comparison_Exp>;
  hide_locked_buses?: InputMaybe<Boolean_Comparison_Exp>;
  hide_low_confidence_buses?: InputMaybe<Boolean_Comparison_Exp>;
  high_threshold?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  includes_pre_existing?: InputMaybe<Boolean_Comparison_Exp>;
  is_archived?: InputMaybe<Boolean_Comparison_Exp>;
  low_threshold?: InputMaybe<Numeric_Comparison_Exp>;
  max_allocated_costs?: InputMaybe<Numeric_Comparison_Exp>;
  max_total_costs?: InputMaybe<Numeric_Comparison_Exp>;
  medium_threshold?: InputMaybe<Numeric_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  scope?: InputMaybe<String_Comparison_Exp>;
  states?: InputMaybe<Jsonb_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  voltages?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "screening_views" */
export enum Screening_Views_Constraint {
  /** unique or primary key constraint on columns "id" */
  ScreeningViewsPkey = 'screening_views_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Screening_Views_Delete_At_Path_Input = {
  states?: InputMaybe<Array<Scalars['String']['input']>>;
  voltages?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Screening_Views_Delete_Elem_Input = {
  states?: InputMaybe<Scalars['Int']['input']>;
  voltages?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Screening_Views_Delete_Key_Input = {
  states?: InputMaybe<Scalars['String']['input']>;
  voltages?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "screening_views" */
export type Screening_Views_Inc_Input = {
  high_threshold?: InputMaybe<Scalars['numeric']['input']>;
  low_threshold?: InputMaybe<Scalars['numeric']['input']>;
  max_allocated_costs?: InputMaybe<Scalars['numeric']['input']>;
  max_total_costs?: InputMaybe<Scalars['numeric']['input']>;
  medium_threshold?: InputMaybe<Scalars['numeric']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "screening_views" */
export type Screening_Views_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  generator_type?: InputMaybe<Scalars['String']['input']>;
  hide_locked_buses?: InputMaybe<Scalars['Boolean']['input']>;
  hide_low_confidence_buses?: InputMaybe<Scalars['Boolean']['input']>;
  high_threshold?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  includes_pre_existing?: InputMaybe<Scalars['Boolean']['input']>;
  is_archived?: InputMaybe<Scalars['Boolean']['input']>;
  low_threshold?: InputMaybe<Scalars['numeric']['input']>;
  max_allocated_costs?: InputMaybe<Scalars['numeric']['input']>;
  max_total_costs?: InputMaybe<Scalars['numeric']['input']>;
  medium_threshold?: InputMaybe<Scalars['numeric']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<Scalars['jsonb']['input']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  voltages?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type Screening_Views_Max_Fields = {
  __typename?: 'screening_views_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  generator_type?: Maybe<Scalars['String']['output']>;
  high_threshold?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  low_threshold?: Maybe<Scalars['numeric']['output']>;
  max_allocated_costs?: Maybe<Scalars['numeric']['output']>;
  max_total_costs?: Maybe<Scalars['numeric']['output']>;
  medium_threshold?: Maybe<Scalars['numeric']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "screening_views" */
export type Screening_Views_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  generator_type?: InputMaybe<Order_By>;
  high_threshold?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  low_threshold?: InputMaybe<Order_By>;
  max_allocated_costs?: InputMaybe<Order_By>;
  max_total_costs?: InputMaybe<Order_By>;
  medium_threshold?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Screening_Views_Min_Fields = {
  __typename?: 'screening_views_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  generator_type?: Maybe<Scalars['String']['output']>;
  high_threshold?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  low_threshold?: Maybe<Scalars['numeric']['output']>;
  max_allocated_costs?: Maybe<Scalars['numeric']['output']>;
  max_total_costs?: Maybe<Scalars['numeric']['output']>;
  medium_threshold?: Maybe<Scalars['numeric']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "screening_views" */
export type Screening_Views_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  generator_type?: InputMaybe<Order_By>;
  high_threshold?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  low_threshold?: InputMaybe<Order_By>;
  max_allocated_costs?: InputMaybe<Order_By>;
  max_total_costs?: InputMaybe<Order_By>;
  medium_threshold?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "screening_views" */
export type Screening_Views_Mutation_Response = {
  __typename?: 'screening_views_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Screening_Views>;
};

/** on_conflict condition type for table "screening_views" */
export type Screening_Views_On_Conflict = {
  constraint: Screening_Views_Constraint;
  update_columns?: Array<Screening_Views_Update_Column>;
  where?: InputMaybe<Screening_Views_Bool_Exp>;
};

/** Ordering options when selecting data from "screening_views". */
export type Screening_Views_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  generator_type?: InputMaybe<Order_By>;
  hide_locked_buses?: InputMaybe<Order_By>;
  hide_low_confidence_buses?: InputMaybe<Order_By>;
  high_threshold?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  includes_pre_existing?: InputMaybe<Order_By>;
  is_archived?: InputMaybe<Order_By>;
  low_threshold?: InputMaybe<Order_By>;
  max_allocated_costs?: InputMaybe<Order_By>;
  max_total_costs?: InputMaybe<Order_By>;
  medium_threshold?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  states?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  voltages?: InputMaybe<Order_By>;
};

/** primary key columns input for table: screening_views */
export type Screening_Views_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Screening_Views_Prepend_Input = {
  states?: InputMaybe<Scalars['jsonb']['input']>;
  voltages?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "screening_views" */
export enum Screening_Views_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  GeneratorType = 'generator_type',
  /** column name */
  HideLockedBuses = 'hide_locked_buses',
  /** column name */
  HideLowConfidenceBuses = 'hide_low_confidence_buses',
  /** column name */
  HighThreshold = 'high_threshold',
  /** column name */
  Id = 'id',
  /** column name */
  IncludesPreExisting = 'includes_pre_existing',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  LowThreshold = 'low_threshold',
  /** column name */
  MaxAllocatedCosts = 'max_allocated_costs',
  /** column name */
  MaxTotalCosts = 'max_total_costs',
  /** column name */
  MediumThreshold = 'medium_threshold',
  /** column name */
  Region = 'region',
  /** column name */
  Scope = 'scope',
  /** column name */
  States = 'states',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Title = 'title',
  /** column name */
  Voltages = 'voltages'
}

/** select "screening_views_aggregate_bool_exp_bool_and_arguments_columns" columns of table "screening_views" */
export enum Screening_Views_Select_Column_Screening_Views_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HideLockedBuses = 'hide_locked_buses',
  /** column name */
  HideLowConfidenceBuses = 'hide_low_confidence_buses',
  /** column name */
  IncludesPreExisting = 'includes_pre_existing',
  /** column name */
  IsArchived = 'is_archived'
}

/** select "screening_views_aggregate_bool_exp_bool_or_arguments_columns" columns of table "screening_views" */
export enum Screening_Views_Select_Column_Screening_Views_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HideLockedBuses = 'hide_locked_buses',
  /** column name */
  HideLowConfidenceBuses = 'hide_low_confidence_buses',
  /** column name */
  IncludesPreExisting = 'includes_pre_existing',
  /** column name */
  IsArchived = 'is_archived'
}

/** input type for updating data in table "screening_views" */
export type Screening_Views_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  generator_type?: InputMaybe<Scalars['String']['input']>;
  hide_locked_buses?: InputMaybe<Scalars['Boolean']['input']>;
  hide_low_confidence_buses?: InputMaybe<Scalars['Boolean']['input']>;
  high_threshold?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  includes_pre_existing?: InputMaybe<Scalars['Boolean']['input']>;
  is_archived?: InputMaybe<Scalars['Boolean']['input']>;
  low_threshold?: InputMaybe<Scalars['numeric']['input']>;
  max_allocated_costs?: InputMaybe<Scalars['numeric']['input']>;
  max_total_costs?: InputMaybe<Scalars['numeric']['input']>;
  medium_threshold?: InputMaybe<Scalars['numeric']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<Scalars['jsonb']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  voltages?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type Screening_Views_Stddev_Fields = {
  __typename?: 'screening_views_stddev_fields';
  high_threshold?: Maybe<Scalars['Float']['output']>;
  low_threshold?: Maybe<Scalars['Float']['output']>;
  max_allocated_costs?: Maybe<Scalars['Float']['output']>;
  max_total_costs?: Maybe<Scalars['Float']['output']>;
  medium_threshold?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "screening_views" */
export type Screening_Views_Stddev_Order_By = {
  high_threshold?: InputMaybe<Order_By>;
  low_threshold?: InputMaybe<Order_By>;
  max_allocated_costs?: InputMaybe<Order_By>;
  max_total_costs?: InputMaybe<Order_By>;
  medium_threshold?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Screening_Views_Stddev_Pop_Fields = {
  __typename?: 'screening_views_stddev_pop_fields';
  high_threshold?: Maybe<Scalars['Float']['output']>;
  low_threshold?: Maybe<Scalars['Float']['output']>;
  max_allocated_costs?: Maybe<Scalars['Float']['output']>;
  max_total_costs?: Maybe<Scalars['Float']['output']>;
  medium_threshold?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "screening_views" */
export type Screening_Views_Stddev_Pop_Order_By = {
  high_threshold?: InputMaybe<Order_By>;
  low_threshold?: InputMaybe<Order_By>;
  max_allocated_costs?: InputMaybe<Order_By>;
  max_total_costs?: InputMaybe<Order_By>;
  medium_threshold?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Screening_Views_Stddev_Samp_Fields = {
  __typename?: 'screening_views_stddev_samp_fields';
  high_threshold?: Maybe<Scalars['Float']['output']>;
  low_threshold?: Maybe<Scalars['Float']['output']>;
  max_allocated_costs?: Maybe<Scalars['Float']['output']>;
  max_total_costs?: Maybe<Scalars['Float']['output']>;
  medium_threshold?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "screening_views" */
export type Screening_Views_Stddev_Samp_Order_By = {
  high_threshold?: InputMaybe<Order_By>;
  low_threshold?: InputMaybe<Order_By>;
  max_allocated_costs?: InputMaybe<Order_By>;
  max_total_costs?: InputMaybe<Order_By>;
  medium_threshold?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "screening_views" */
export type Screening_Views_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Screening_Views_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Screening_Views_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  generator_type?: InputMaybe<Scalars['String']['input']>;
  hide_locked_buses?: InputMaybe<Scalars['Boolean']['input']>;
  hide_low_confidence_buses?: InputMaybe<Scalars['Boolean']['input']>;
  high_threshold?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  includes_pre_existing?: InputMaybe<Scalars['Boolean']['input']>;
  is_archived?: InputMaybe<Scalars['Boolean']['input']>;
  low_threshold?: InputMaybe<Scalars['numeric']['input']>;
  max_allocated_costs?: InputMaybe<Scalars['numeric']['input']>;
  max_total_costs?: InputMaybe<Scalars['numeric']['input']>;
  medium_threshold?: InputMaybe<Scalars['numeric']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<Scalars['jsonb']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  voltages?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type Screening_Views_Sum_Fields = {
  __typename?: 'screening_views_sum_fields';
  high_threshold?: Maybe<Scalars['numeric']['output']>;
  low_threshold?: Maybe<Scalars['numeric']['output']>;
  max_allocated_costs?: Maybe<Scalars['numeric']['output']>;
  max_total_costs?: Maybe<Scalars['numeric']['output']>;
  medium_threshold?: Maybe<Scalars['numeric']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "screening_views" */
export type Screening_Views_Sum_Order_By = {
  high_threshold?: InputMaybe<Order_By>;
  low_threshold?: InputMaybe<Order_By>;
  max_allocated_costs?: InputMaybe<Order_By>;
  max_total_costs?: InputMaybe<Order_By>;
  medium_threshold?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** update columns of table "screening_views" */
export enum Screening_Views_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  GeneratorType = 'generator_type',
  /** column name */
  HideLockedBuses = 'hide_locked_buses',
  /** column name */
  HideLowConfidenceBuses = 'hide_low_confidence_buses',
  /** column name */
  HighThreshold = 'high_threshold',
  /** column name */
  Id = 'id',
  /** column name */
  IncludesPreExisting = 'includes_pre_existing',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  LowThreshold = 'low_threshold',
  /** column name */
  MaxAllocatedCosts = 'max_allocated_costs',
  /** column name */
  MaxTotalCosts = 'max_total_costs',
  /** column name */
  MediumThreshold = 'medium_threshold',
  /** column name */
  Region = 'region',
  /** column name */
  Scope = 'scope',
  /** column name */
  States = 'states',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Title = 'title',
  /** column name */
  Voltages = 'voltages'
}

export type Screening_Views_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Screening_Views_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Screening_Views_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Screening_Views_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Screening_Views_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Screening_Views_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Screening_Views_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Screening_Views_Set_Input>;
  /** filter the rows which have to be updated */
  where: Screening_Views_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Screening_Views_Var_Pop_Fields = {
  __typename?: 'screening_views_var_pop_fields';
  high_threshold?: Maybe<Scalars['Float']['output']>;
  low_threshold?: Maybe<Scalars['Float']['output']>;
  max_allocated_costs?: Maybe<Scalars['Float']['output']>;
  max_total_costs?: Maybe<Scalars['Float']['output']>;
  medium_threshold?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "screening_views" */
export type Screening_Views_Var_Pop_Order_By = {
  high_threshold?: InputMaybe<Order_By>;
  low_threshold?: InputMaybe<Order_By>;
  max_allocated_costs?: InputMaybe<Order_By>;
  max_total_costs?: InputMaybe<Order_By>;
  medium_threshold?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Screening_Views_Var_Samp_Fields = {
  __typename?: 'screening_views_var_samp_fields';
  high_threshold?: Maybe<Scalars['Float']['output']>;
  low_threshold?: Maybe<Scalars['Float']['output']>;
  max_allocated_costs?: Maybe<Scalars['Float']['output']>;
  max_total_costs?: Maybe<Scalars['Float']['output']>;
  medium_threshold?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "screening_views" */
export type Screening_Views_Var_Samp_Order_By = {
  high_threshold?: InputMaybe<Order_By>;
  low_threshold?: InputMaybe<Order_By>;
  max_allocated_costs?: InputMaybe<Order_By>;
  max_total_costs?: InputMaybe<Order_By>;
  medium_threshold?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Screening_Views_Variance_Fields = {
  __typename?: 'screening_views_variance_fields';
  high_threshold?: Maybe<Scalars['Float']['output']>;
  low_threshold?: Maybe<Scalars['Float']['output']>;
  max_allocated_costs?: Maybe<Scalars['Float']['output']>;
  max_total_costs?: Maybe<Scalars['Float']['output']>;
  medium_threshold?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "screening_views" */
export type Screening_Views_Variance_Order_By = {
  high_threshold?: InputMaybe<Order_By>;
  low_threshold?: InputMaybe<Order_By>;
  max_allocated_costs?: InputMaybe<Order_By>;
  max_total_costs?: InputMaybe<Order_By>;
  medium_threshold?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "all_projects" */
  all_projects: Array<All_Projects>;
  /** fetch aggregated fields from the table: "all_projects" */
  all_projects_aggregate: All_Projects_Aggregate;
  /** fetch data from the table: "all_projects" using primary key columns */
  all_projects_by_pk?: Maybe<All_Projects>;
  /** fetch data from the table in a streaming manner: "all_projects" */
  all_projects_stream: Array<All_Projects>;
  /** fetch data from the table: "branches" */
  branches: Array<Branches>;
  /** fetch aggregated fields from the table: "branches" */
  branches_aggregate: Branches_Aggregate;
  /** fetch data from the table: "branches" using primary key columns */
  branches_by_pk?: Maybe<Branches>;
  /** fetch data from the table in a streaming manner: "branches" */
  branches_stream: Array<Branches>;
  /** An array relationship */
  buses: Array<Buses>;
  /** An aggregate relationship */
  buses_aggregate: Buses_Aggregate;
  /** fetch data from the table: "buses" using primary key columns */
  buses_by_pk?: Maybe<Buses>;
  /** fetch data from the table in a streaming manner: "buses" */
  buses_stream: Array<Buses>;
  /** An array relationship */
  capacity_costs: Array<Capacity_Costs>;
  /** An aggregate relationship */
  capacity_costs_aggregate: Capacity_Costs_Aggregate;
  /** fetch data from the table: "capacity_costs" using primary key columns */
  capacity_costs_by_pk?: Maybe<Capacity_Costs>;
  /** fetch data from the table in a streaming manner: "capacity_costs" */
  capacity_costs_stream: Array<Capacity_Costs>;
  /** An array relationship */
  constraint_stacks: Array<Constraint_Stacks>;
  /** An aggregate relationship */
  constraint_stacks_aggregate: Constraint_Stacks_Aggregate;
  /** fetch data from the table: "constraint_stacks" using primary key columns */
  constraint_stacks_by_pk?: Maybe<Constraint_Stacks>;
  /** fetch data from the table in a streaming manner: "constraint_stacks" */
  constraint_stacks_stream: Array<Constraint_Stacks>;
  /** fetch data from the table: "constraint_types" */
  constraint_types: Array<Constraint_Types>;
  /** fetch aggregated fields from the table: "constraint_types" */
  constraint_types_aggregate: Constraint_Types_Aggregate;
  /** fetch data from the table: "constraint_types" using primary key columns */
  constraint_types_by_pk?: Maybe<Constraint_Types>;
  /** fetch data from the table in a streaming manner: "constraint_types" */
  constraint_types_stream: Array<Constraint_Types>;
  /** fetch data from the table: "in_queue_subscriptions" */
  in_queue_subscriptions: Array<In_Queue_Subscriptions>;
  /** fetch aggregated fields from the table: "in_queue_subscriptions" */
  in_queue_subscriptions_aggregate: In_Queue_Subscriptions_Aggregate;
  /** fetch data from the table: "in_queue_subscriptions" using primary key columns */
  in_queue_subscriptions_by_pk?: Maybe<In_Queue_Subscriptions>;
  /** fetch data from the table in a streaming manner: "in_queue_subscriptions" */
  in_queue_subscriptions_stream: Array<In_Queue_Subscriptions>;
  /** An array relationship */
  line_capacity_costs: Array<Line_Capacity_Costs>;
  /** An aggregate relationship */
  line_capacity_costs_aggregate: Line_Capacity_Costs_Aggregate;
  /** fetch data from the table: "line_capacity_costs" using primary key columns */
  line_capacity_costs_by_pk?: Maybe<Line_Capacity_Costs>;
  /** fetch data from the table in a streaming manner: "line_capacity_costs" */
  line_capacity_costs_stream: Array<Line_Capacity_Costs>;
  /** An array relationship */
  line_constraint_stacks: Array<Line_Constraint_Stacks>;
  /** An aggregate relationship */
  line_constraint_stacks_aggregate: Line_Constraint_Stacks_Aggregate;
  /** fetch data from the table: "line_constraint_stacks" using primary key columns */
  line_constraint_stacks_by_pk?: Maybe<Line_Constraint_Stacks>;
  /** fetch data from the table in a streaming manner: "line_constraint_stacks" */
  line_constraint_stacks_stream: Array<Line_Constraint_Stacks>;
  /** fetch data from the table: "model_lines" */
  model_lines: Array<Model_Lines>;
  /** fetch aggregated fields from the table: "model_lines" */
  model_lines_aggregate: Model_Lines_Aggregate;
  /** fetch data from the table: "model_lines" using primary key columns */
  model_lines_by_pk?: Maybe<Model_Lines>;
  /** fetch data from the table in a streaming manner: "model_lines" */
  model_lines_stream: Array<Model_Lines>;
  /** fetch data from the table: "model_lines_to_missing_buses" */
  model_lines_to_missing_buses: Array<Model_Lines_To_Missing_Buses>;
  /** fetch aggregated fields from the table: "model_lines_to_missing_buses" */
  model_lines_to_missing_buses_aggregate: Model_Lines_To_Missing_Buses_Aggregate;
  /** fetch data from the table: "model_lines_to_missing_buses" using primary key columns */
  model_lines_to_missing_buses_by_pk?: Maybe<Model_Lines_To_Missing_Buses>;
  /** fetch data from the table in a streaming manner: "model_lines_to_missing_buses" */
  model_lines_to_missing_buses_stream: Array<Model_Lines_To_Missing_Buses>;
  /** fetch data from the table: "projects" */
  projects: Array<Projects>;
  /** fetch aggregated fields from the table: "projects" */
  projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table in a streaming manner: "projects" */
  projects_stream: Array<Projects>;
  /** An array relationship */
  scenario_allocated_upgrades: Array<Scenario_Allocated_Upgrades>;
  /** An aggregate relationship */
  scenario_allocated_upgrades_aggregate: Scenario_Allocated_Upgrades_Aggregate;
  /** fetch data from the table: "scenario_allocated_upgrades" using primary key columns */
  scenario_allocated_upgrades_by_pk?: Maybe<Scenario_Allocated_Upgrades>;
  /** fetch data from the table in a streaming manner: "scenario_allocated_upgrades" */
  scenario_allocated_upgrades_stream: Array<Scenario_Allocated_Upgrades>;
  /** An array relationship */
  scenario_constraints: Array<Scenario_Constraints>;
  /** An aggregate relationship */
  scenario_constraints_aggregate: Scenario_Constraints_Aggregate;
  /** fetch data from the table: "scenario_constraints" using primary key columns */
  scenario_constraints_by_pk?: Maybe<Scenario_Constraints>;
  /** fetch data from the table in a streaming manner: "scenario_constraints" */
  scenario_constraints_stream: Array<Scenario_Constraints>;
  /** An array relationship */
  scenario_projects: Array<Scenario_Projects>;
  /** An aggregate relationship */
  scenario_projects_aggregate: Scenario_Projects_Aggregate;
  /** fetch data from the table: "scenario_projects" using primary key columns */
  scenario_projects_by_pk?: Maybe<Scenario_Projects>;
  /** fetch data from the table in a streaming manner: "scenario_projects" */
  scenario_projects_stream: Array<Scenario_Projects>;
  /** fetch data from the table: "scenario_statuses" */
  scenario_statuses: Array<Scenario_Statuses>;
  /** fetch aggregated fields from the table: "scenario_statuses" */
  scenario_statuses_aggregate: Scenario_Statuses_Aggregate;
  /** fetch data from the table: "scenario_statuses" using primary key columns */
  scenario_statuses_by_pk?: Maybe<Scenario_Statuses>;
  /** fetch data from the table in a streaming manner: "scenario_statuses" */
  scenario_statuses_stream: Array<Scenario_Statuses>;
  /** An array relationship */
  scenarios: Array<Scenarios>;
  /** An aggregate relationship */
  scenarios_aggregate: Scenarios_Aggregate;
  /** fetch data from the table: "scenarios" using primary key columns */
  scenarios_by_pk?: Maybe<Scenarios>;
  /** fetch data from the table in a streaming manner: "scenarios" */
  scenarios_stream: Array<Scenarios>;
  /** An array relationship */
  screening_views: Array<Screening_Views>;
  /** An aggregate relationship */
  screening_views_aggregate: Screening_Views_Aggregate;
  /** fetch data from the table: "screening_views" using primary key columns */
  screening_views_by_pk?: Maybe<Screening_Views>;
  /** fetch data from the table in a streaming manner: "screening_views" */
  screening_views_stream: Array<Screening_Views>;
  /** An array relationship */
  subscriptions: Array<Subscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate;
  /** fetch data from the table: "subscriptions" using primary key columns */
  subscriptions_by_pk?: Maybe<Subscriptions>;
  /** fetch data from the table in a streaming manner: "subscriptions" */
  subscriptions_stream: Array<Subscriptions>;
  /** fetch data from the table: "substations" */
  substations: Array<Substations>;
  /** fetch aggregated fields from the table: "substations" */
  substations_aggregate: Substations_Aggregate;
  /** fetch data from the table: "substations" using primary key columns */
  substations_by_pk?: Maybe<Substations>;
  /** fetch data from the table in a streaming manner: "substations" */
  substations_stream: Array<Substations>;
  /** fetch data from the table: "teams" */
  teams: Array<Teams>;
  /** fetch aggregated fields from the table: "teams" */
  teams_aggregate: Teams_Aggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teams_by_pk?: Maybe<Teams>;
  /** fetch data from the table in a streaming manner: "teams" */
  teams_stream: Array<Teams>;
  /** An array relationship */
  tracked_projects: Array<Tracked_Projects>;
  /** An aggregate relationship */
  tracked_projects_aggregate: Tracked_Projects_Aggregate;
  /** fetch data from the table: "tracked_projects" using primary key columns */
  tracked_projects_by_pk?: Maybe<Tracked_Projects>;
  /** fetch data from the table in a streaming manner: "tracked_projects" */
  tracked_projects_stream: Array<Tracked_Projects>;
  /** An array relationship */
  unlocked_buses: Array<Unlocked_Buses>;
  /** An aggregate relationship */
  unlocked_buses_aggregate: Unlocked_Buses_Aggregate;
  /** fetch data from the table: "unlocked_buses" using primary key columns */
  unlocked_buses_by_pk?: Maybe<Unlocked_Buses>;
  /** fetch data from the table in a streaming manner: "unlocked_buses" */
  unlocked_buses_stream: Array<Unlocked_Buses>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
};


export type Subscription_RootAll_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<All_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<All_Projects_Order_By>>;
  where?: InputMaybe<All_Projects_Bool_Exp>;
};


export type Subscription_RootAll_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<All_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<All_Projects_Order_By>>;
  where?: InputMaybe<All_Projects_Bool_Exp>;
};


export type Subscription_RootAll_Projects_By_PkArgs = {
  project_id: Scalars['String']['input'];
  region: Scalars['String']['input'];
  study_phase: Scalars['String']['input'];
};


export type Subscription_RootAll_Projects_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<All_Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<All_Projects_Bool_Exp>;
};


export type Subscription_RootBranchesArgs = {
  distinct_on?: InputMaybe<Array<Branches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Branches_Order_By>>;
  where?: InputMaybe<Branches_Bool_Exp>;
};


export type Subscription_RootBranches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Branches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Branches_Order_By>>;
  where?: InputMaybe<Branches_Bool_Exp>;
};


export type Subscription_RootBranches_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootBranches_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Branches_Stream_Cursor_Input>>;
  where?: InputMaybe<Branches_Bool_Exp>;
};


export type Subscription_RootBusesArgs = {
  distinct_on?: InputMaybe<Array<Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buses_Order_By>>;
  where?: InputMaybe<Buses_Bool_Exp>;
};


export type Subscription_RootBuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buses_Order_By>>;
  where?: InputMaybe<Buses_Bool_Exp>;
};


export type Subscription_RootBuses_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootBuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Buses_Stream_Cursor_Input>>;
  where?: InputMaybe<Buses_Bool_Exp>;
};


export type Subscription_RootCapacity_CostsArgs = {
  distinct_on?: InputMaybe<Array<Capacity_Costs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Capacity_Costs_Order_By>>;
  where?: InputMaybe<Capacity_Costs_Bool_Exp>;
};


export type Subscription_RootCapacity_Costs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Capacity_Costs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Capacity_Costs_Order_By>>;
  where?: InputMaybe<Capacity_Costs_Bool_Exp>;
};


export type Subscription_RootCapacity_Costs_By_PkArgs = {
  bus_id: Scalars['String']['input'];
  energy_size: Scalars['numeric']['input'];
  includes_pre_existing: Scalars['Boolean']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
};


export type Subscription_RootCapacity_Costs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Capacity_Costs_Stream_Cursor_Input>>;
  where?: InputMaybe<Capacity_Costs_Bool_Exp>;
};


export type Subscription_RootConstraint_StacksArgs = {
  distinct_on?: InputMaybe<Array<Constraint_Stacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Constraint_Stacks_Order_By>>;
  where?: InputMaybe<Constraint_Stacks_Bool_Exp>;
};


export type Subscription_RootConstraint_Stacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Constraint_Stacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Constraint_Stacks_Order_By>>;
  where?: InputMaybe<Constraint_Stacks_Bool_Exp>;
};


export type Subscription_RootConstraint_Stacks_By_PkArgs = {
  bus_id: Scalars['String']['input'];
  monitored_facility_id: Scalars['String']['input'];
  resource_type: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
};


export type Subscription_RootConstraint_Stacks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Constraint_Stacks_Stream_Cursor_Input>>;
  where?: InputMaybe<Constraint_Stacks_Bool_Exp>;
};


export type Subscription_RootConstraint_TypesArgs = {
  distinct_on?: InputMaybe<Array<Constraint_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Constraint_Types_Order_By>>;
  where?: InputMaybe<Constraint_Types_Bool_Exp>;
};


export type Subscription_RootConstraint_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Constraint_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Constraint_Types_Order_By>>;
  where?: InputMaybe<Constraint_Types_Bool_Exp>;
};


export type Subscription_RootConstraint_Types_By_PkArgs = {
  constraint_type: Scalars['String']['input'];
};


export type Subscription_RootConstraint_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Constraint_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Constraint_Types_Bool_Exp>;
};


export type Subscription_RootIn_Queue_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<In_Queue_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_Queue_Subscriptions_Order_By>>;
  where?: InputMaybe<In_Queue_Subscriptions_Bool_Exp>;
};


export type Subscription_RootIn_Queue_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<In_Queue_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<In_Queue_Subscriptions_Order_By>>;
  where?: InputMaybe<In_Queue_Subscriptions_Bool_Exp>;
};


export type Subscription_RootIn_Queue_Subscriptions_By_PkArgs = {
  subscription_id: Scalars['uuid']['input'];
};


export type Subscription_RootIn_Queue_Subscriptions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<In_Queue_Subscriptions_Stream_Cursor_Input>>;
  where?: InputMaybe<In_Queue_Subscriptions_Bool_Exp>;
};


export type Subscription_RootLine_Capacity_CostsArgs = {
  distinct_on?: InputMaybe<Array<Line_Capacity_Costs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Line_Capacity_Costs_Order_By>>;
  where?: InputMaybe<Line_Capacity_Costs_Bool_Exp>;
};


export type Subscription_RootLine_Capacity_Costs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Line_Capacity_Costs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Line_Capacity_Costs_Order_By>>;
  where?: InputMaybe<Line_Capacity_Costs_Bool_Exp>;
};


export type Subscription_RootLine_Capacity_Costs_By_PkArgs = {
  energy_size: Scalars['numeric']['input'];
  includes_pre_existing: Scalars['Boolean']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
  tap_facility_id: Scalars['String']['input'];
};


export type Subscription_RootLine_Capacity_Costs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Line_Capacity_Costs_Stream_Cursor_Input>>;
  where?: InputMaybe<Line_Capacity_Costs_Bool_Exp>;
};


export type Subscription_RootLine_Constraint_StacksArgs = {
  distinct_on?: InputMaybe<Array<Line_Constraint_Stacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Line_Constraint_Stacks_Order_By>>;
  where?: InputMaybe<Line_Constraint_Stacks_Bool_Exp>;
};


export type Subscription_RootLine_Constraint_Stacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Line_Constraint_Stacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Line_Constraint_Stacks_Order_By>>;
  where?: InputMaybe<Line_Constraint_Stacks_Bool_Exp>;
};


export type Subscription_RootLine_Constraint_Stacks_By_PkArgs = {
  monitored_facility_id: Scalars['String']['input'];
  resource_type: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
  tap_facility_id: Scalars['String']['input'];
};


export type Subscription_RootLine_Constraint_Stacks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Line_Constraint_Stacks_Stream_Cursor_Input>>;
  where?: InputMaybe<Line_Constraint_Stacks_Bool_Exp>;
};


export type Subscription_RootModel_LinesArgs = {
  distinct_on?: InputMaybe<Array<Model_Lines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Lines_Order_By>>;
  where?: InputMaybe<Model_Lines_Bool_Exp>;
};


export type Subscription_RootModel_Lines_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Model_Lines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Lines_Order_By>>;
  where?: InputMaybe<Model_Lines_Bool_Exp>;
};


export type Subscription_RootModel_Lines_By_PkArgs = {
  facility_id: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
};


export type Subscription_RootModel_Lines_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Model_Lines_Stream_Cursor_Input>>;
  where?: InputMaybe<Model_Lines_Bool_Exp>;
};


export type Subscription_RootModel_Lines_To_Missing_BusesArgs = {
  distinct_on?: InputMaybe<Array<Model_Lines_To_Missing_Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Lines_To_Missing_Buses_Order_By>>;
  where?: InputMaybe<Model_Lines_To_Missing_Buses_Bool_Exp>;
};


export type Subscription_RootModel_Lines_To_Missing_Buses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Model_Lines_To_Missing_Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Lines_To_Missing_Buses_Order_By>>;
  where?: InputMaybe<Model_Lines_To_Missing_Buses_Bool_Exp>;
};


export type Subscription_RootModel_Lines_To_Missing_Buses_By_PkArgs = {
  facility_id: Scalars['String']['input'];
  scope: Scalars['String']['input'];
  scope_view: Scalars['String']['input'];
};


export type Subscription_RootModel_Lines_To_Missing_Buses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Model_Lines_To_Missing_Buses_Stream_Cursor_Input>>;
  where?: InputMaybe<Model_Lines_To_Missing_Buses_Bool_Exp>;
};


export type Subscription_RootProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjects_By_PkArgs = {
  project_number: Scalars['String']['input'];
};


export type Subscription_RootProjects_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootScenario_Allocated_UpgradesArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Allocated_Upgrades_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Allocated_Upgrades_Order_By>>;
  where?: InputMaybe<Scenario_Allocated_Upgrades_Bool_Exp>;
};


export type Subscription_RootScenario_Allocated_Upgrades_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Allocated_Upgrades_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Allocated_Upgrades_Order_By>>;
  where?: InputMaybe<Scenario_Allocated_Upgrades_Bool_Exp>;
};


export type Subscription_RootScenario_Allocated_Upgrades_By_PkArgs = {
  constraint_type: Constraint_Types_Enum;
  network_upgrade_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
  scenario_id: Scalars['uuid']['input'];
};


export type Subscription_RootScenario_Allocated_Upgrades_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Scenario_Allocated_Upgrades_Stream_Cursor_Input>>;
  where?: InputMaybe<Scenario_Allocated_Upgrades_Bool_Exp>;
};


export type Subscription_RootScenario_ConstraintsArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Constraints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Constraints_Order_By>>;
  where?: InputMaybe<Scenario_Constraints_Bool_Exp>;
};


export type Subscription_RootScenario_Constraints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Constraints_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Constraints_Order_By>>;
  where?: InputMaybe<Scenario_Constraints_Bool_Exp>;
};


export type Subscription_RootScenario_Constraints_By_PkArgs = {
  constraint_type: Constraint_Types_Enum;
  facility_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
  scenario_id: Scalars['uuid']['input'];
};


export type Subscription_RootScenario_Constraints_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Scenario_Constraints_Stream_Cursor_Input>>;
  where?: InputMaybe<Scenario_Constraints_Bool_Exp>;
};


export type Subscription_RootScenario_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Projects_Order_By>>;
  where?: InputMaybe<Scenario_Projects_Bool_Exp>;
};


export type Subscription_RootScenario_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Projects_Order_By>>;
  where?: InputMaybe<Scenario_Projects_Bool_Exp>;
};


export type Subscription_RootScenario_Projects_By_PkArgs = {
  project_id: Scalars['String']['input'];
  scenario_id: Scalars['uuid']['input'];
};


export type Subscription_RootScenario_Projects_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Scenario_Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Scenario_Projects_Bool_Exp>;
};


export type Subscription_RootScenario_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Statuses_Order_By>>;
  where?: InputMaybe<Scenario_Statuses_Bool_Exp>;
};


export type Subscription_RootScenario_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scenario_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenario_Statuses_Order_By>>;
  where?: InputMaybe<Scenario_Statuses_Bool_Exp>;
};


export type Subscription_RootScenario_Statuses_By_PkArgs = {
  status: Scalars['String']['input'];
};


export type Subscription_RootScenario_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Scenario_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Scenario_Statuses_Bool_Exp>;
};


export type Subscription_RootScenariosArgs = {
  distinct_on?: InputMaybe<Array<Scenarios_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenarios_Order_By>>;
  where?: InputMaybe<Scenarios_Bool_Exp>;
};


export type Subscription_RootScenarios_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scenarios_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Scenarios_Order_By>>;
  where?: InputMaybe<Scenarios_Bool_Exp>;
};


export type Subscription_RootScenarios_By_PkArgs = {
  scenario_id: Scalars['uuid']['input'];
};


export type Subscription_RootScenarios_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Scenarios_Stream_Cursor_Input>>;
  where?: InputMaybe<Scenarios_Bool_Exp>;
};


export type Subscription_RootScreening_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Screening_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Screening_Views_Order_By>>;
  where?: InputMaybe<Screening_Views_Bool_Exp>;
};


export type Subscription_RootScreening_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screening_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Screening_Views_Order_By>>;
  where?: InputMaybe<Screening_Views_Bool_Exp>;
};


export type Subscription_RootScreening_Views_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootScreening_Views_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Screening_Views_Stream_Cursor_Input>>;
  where?: InputMaybe<Screening_Views_Bool_Exp>;
};


export type Subscription_RootSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Subscription_RootSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Subscription_RootSubscriptions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootSubscriptions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Subscriptions_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


export type Subscription_RootSubstationsArgs = {
  distinct_on?: InputMaybe<Array<Substations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Substations_Order_By>>;
  where?: InputMaybe<Substations_Bool_Exp>;
};


export type Subscription_RootSubstations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Substations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Substations_Order_By>>;
  where?: InputMaybe<Substations_Bool_Exp>;
};


export type Subscription_RootSubstations_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootSubstations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Substations_Stream_Cursor_Input>>;
  where?: InputMaybe<Substations_Bool_Exp>;
};


export type Subscription_RootTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Subscription_RootTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Subscription_RootTeams_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootTeams_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Teams_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Subscription_RootTracked_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Tracked_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tracked_Projects_Order_By>>;
  where?: InputMaybe<Tracked_Projects_Bool_Exp>;
};


export type Subscription_RootTracked_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracked_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tracked_Projects_Order_By>>;
  where?: InputMaybe<Tracked_Projects_Bool_Exp>;
};


export type Subscription_RootTracked_Projects_By_PkArgs = {
  project_id: Scalars['String']['input'];
  subscription_id: Scalars['uuid']['input'];
};


export type Subscription_RootTracked_Projects_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tracked_Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Tracked_Projects_Bool_Exp>;
};


export type Subscription_RootUnlocked_BusesArgs = {
  distinct_on?: InputMaybe<Array<Unlocked_Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Unlocked_Buses_Order_By>>;
  where?: InputMaybe<Unlocked_Buses_Bool_Exp>;
};


export type Subscription_RootUnlocked_Buses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unlocked_Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Unlocked_Buses_Order_By>>;
  where?: InputMaybe<Unlocked_Buses_Bool_Exp>;
};


export type Subscription_RootUnlocked_Buses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootUnlocked_Buses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Unlocked_Buses_Stream_Cursor_Input>>;
  where?: InputMaybe<Unlocked_Buses_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  auth0_id: Scalars['String']['input'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** represents a team subscription to each individual region  */
export type Subscriptions = {
  __typename?: 'subscriptions';
  created_at: Scalars['timestamptz']['output'];
  end_date: Scalars['date']['output'];
  has_arcgis_access: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  num_buses: Scalars['Int']['output'];
  region: Scalars['String']['output'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['Int']['output'];
  unlimited: Scalars['Boolean']['output'];
  /** An array relationship */
  unlocked_buses: Array<Unlocked_Buses>;
  /** An aggregate relationship */
  unlocked_buses_aggregate: Unlocked_Buses_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
};


/** represents a team subscription to each individual region  */
export type SubscriptionsUnlocked_BusesArgs = {
  distinct_on?: InputMaybe<Array<Unlocked_Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Unlocked_Buses_Order_By>>;
  where?: InputMaybe<Unlocked_Buses_Bool_Exp>;
};


/** represents a team subscription to each individual region  */
export type SubscriptionsUnlocked_Buses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unlocked_Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Unlocked_Buses_Order_By>>;
  where?: InputMaybe<Unlocked_Buses_Bool_Exp>;
};

/** aggregated selection of "subscriptions" */
export type Subscriptions_Aggregate = {
  __typename?: 'subscriptions_aggregate';
  aggregate?: Maybe<Subscriptions_Aggregate_Fields>;
  nodes: Array<Subscriptions>;
};

export type Subscriptions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Subscriptions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Subscriptions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Subscriptions_Aggregate_Bool_Exp_Count>;
};

export type Subscriptions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Subscriptions_Select_Column_Subscriptions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Subscriptions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Subscriptions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Subscriptions_Select_Column_Subscriptions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Subscriptions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Subscriptions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Subscriptions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscriptions" */
export type Subscriptions_Aggregate_Fields = {
  __typename?: 'subscriptions_aggregate_fields';
  avg?: Maybe<Subscriptions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Subscriptions_Max_Fields>;
  min?: Maybe<Subscriptions_Min_Fields>;
  stddev?: Maybe<Subscriptions_Stddev_Fields>;
  stddev_pop?: Maybe<Subscriptions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subscriptions_Stddev_Samp_Fields>;
  sum?: Maybe<Subscriptions_Sum_Fields>;
  var_pop?: Maybe<Subscriptions_Var_Pop_Fields>;
  var_samp?: Maybe<Subscriptions_Var_Samp_Fields>;
  variance?: Maybe<Subscriptions_Variance_Fields>;
};


/** aggregate fields of "subscriptions" */
export type Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriptions" */
export type Subscriptions_Aggregate_Order_By = {
  avg?: InputMaybe<Subscriptions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscriptions_Max_Order_By>;
  min?: InputMaybe<Subscriptions_Min_Order_By>;
  stddev?: InputMaybe<Subscriptions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Subscriptions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Subscriptions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Subscriptions_Sum_Order_By>;
  var_pop?: InputMaybe<Subscriptions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Subscriptions_Var_Samp_Order_By>;
  variance?: InputMaybe<Subscriptions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "subscriptions" */
export type Subscriptions_Arr_Rel_Insert_Input = {
  data: Array<Subscriptions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};

/** aggregate avg on columns */
export type Subscriptions_Avg_Fields = {
  __typename?: 'subscriptions_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  num_buses?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriptions" */
export type Subscriptions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  num_buses?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "subscriptions". All fields are combined with a logical 'AND'. */
export type Subscriptions_Bool_Exp = {
  _and?: InputMaybe<Array<Subscriptions_Bool_Exp>>;
  _not?: InputMaybe<Subscriptions_Bool_Exp>;
  _or?: InputMaybe<Array<Subscriptions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  has_arcgis_access?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  num_buses?: InputMaybe<Int_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  unlimited?: InputMaybe<Boolean_Comparison_Exp>;
  unlocked_buses?: InputMaybe<Unlocked_Buses_Bool_Exp>;
  unlocked_buses_aggregate?: InputMaybe<Unlocked_Buses_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscriptions" */
export enum Subscriptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionsPkey = 'subscriptions_pkey',
  /** unique or primary key constraint on columns "region", "team_id" */
  SubscriptionsTeamIdRegionIdx = 'subscriptions_team_id_region_idx'
}

/** input type for incrementing numeric columns in table "subscriptions" */
export type Subscriptions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  num_buses?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriptions" */
export type Subscriptions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  has_arcgis_access?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  num_buses?: InputMaybe<Scalars['Int']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  unlimited?: InputMaybe<Scalars['Boolean']['input']>;
  unlocked_buses?: InputMaybe<Unlocked_Buses_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Subscriptions_Max_Fields = {
  __typename?: 'subscriptions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  end_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  num_buses?: Maybe<Scalars['Int']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "subscriptions" */
export type Subscriptions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  num_buses?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscriptions_Min_Fields = {
  __typename?: 'subscriptions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  end_date?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  num_buses?: Maybe<Scalars['Int']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "subscriptions" */
export type Subscriptions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  num_buses?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscriptions" */
export type Subscriptions_Mutation_Response = {
  __typename?: 'subscriptions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscriptions>;
};

/** input type for inserting object relation for remote table "subscriptions" */
export type Subscriptions_Obj_Rel_Insert_Input = {
  data: Subscriptions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>;
};

/** on_conflict condition type for table "subscriptions" */
export type Subscriptions_On_Conflict = {
  constraint: Subscriptions_Constraint;
  update_columns?: Array<Subscriptions_Update_Column>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};

/** Ordering options when selecting data from "subscriptions". */
export type Subscriptions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  has_arcgis_access?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  num_buses?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  unlimited?: InputMaybe<Order_By>;
  unlocked_buses_aggregate?: InputMaybe<Unlocked_Buses_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscriptions */
export type Subscriptions_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "subscriptions" */
export enum Subscriptions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  HasArcgisAccess = 'has_arcgis_access',
  /** column name */
  Id = 'id',
  /** column name */
  NumBuses = 'num_buses',
  /** column name */
  Region = 'region',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Unlimited = 'unlimited',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "subscriptions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "subscriptions" */
export enum Subscriptions_Select_Column_Subscriptions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasArcgisAccess = 'has_arcgis_access',
  /** column name */
  Unlimited = 'unlimited'
}

/** select "subscriptions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "subscriptions" */
export enum Subscriptions_Select_Column_Subscriptions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasArcgisAccess = 'has_arcgis_access',
  /** column name */
  Unlimited = 'unlimited'
}

/** input type for updating data in table "subscriptions" */
export type Subscriptions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  has_arcgis_access?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  num_buses?: InputMaybe<Scalars['Int']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  unlimited?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Subscriptions_Stddev_Fields = {
  __typename?: 'subscriptions_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  num_buses?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriptions" */
export type Subscriptions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  num_buses?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Subscriptions_Stddev_Pop_Fields = {
  __typename?: 'subscriptions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  num_buses?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriptions" */
export type Subscriptions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  num_buses?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Subscriptions_Stddev_Samp_Fields = {
  __typename?: 'subscriptions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  num_buses?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriptions" */
export type Subscriptions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  num_buses?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "subscriptions" */
export type Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscriptions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  has_arcgis_access?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  num_buses?: InputMaybe<Scalars['Int']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  unlimited?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Subscriptions_Sum_Fields = {
  __typename?: 'subscriptions_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  num_buses?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriptions" */
export type Subscriptions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  num_buses?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** update columns of table "subscriptions" */
export enum Subscriptions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  HasArcgisAccess = 'has_arcgis_access',
  /** column name */
  Id = 'id',
  /** column name */
  NumBuses = 'num_buses',
  /** column name */
  Region = 'region',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Unlimited = 'unlimited',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Subscriptions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Subscriptions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscriptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscriptions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Subscriptions_Var_Pop_Fields = {
  __typename?: 'subscriptions_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  num_buses?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriptions" */
export type Subscriptions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  num_buses?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Subscriptions_Var_Samp_Fields = {
  __typename?: 'subscriptions_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  num_buses?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriptions" */
export type Subscriptions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  num_buses?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Subscriptions_Variance_Fields = {
  __typename?: 'subscriptions_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  num_buses?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriptions" */
export type Subscriptions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  num_buses?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "substations" */
export type Substations = {
  __typename?: 'substations';
  /** An array relationship */
  buses: Array<Buses>;
  /** An aggregate relationship */
  buses_aggregate: Buses_Aggregate;
  id: Scalars['String']['output'];
  substation_display_name: Scalars['String']['output'];
  substation_owner: Scalars['String']['output'];
};


/** columns and relationships of "substations" */
export type SubstationsBusesArgs = {
  distinct_on?: InputMaybe<Array<Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buses_Order_By>>;
  where?: InputMaybe<Buses_Bool_Exp>;
};


/** columns and relationships of "substations" */
export type SubstationsBuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Buses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buses_Order_By>>;
  where?: InputMaybe<Buses_Bool_Exp>;
};

/** aggregated selection of "substations" */
export type Substations_Aggregate = {
  __typename?: 'substations_aggregate';
  aggregate?: Maybe<Substations_Aggregate_Fields>;
  nodes: Array<Substations>;
};

/** aggregate fields of "substations" */
export type Substations_Aggregate_Fields = {
  __typename?: 'substations_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Substations_Max_Fields>;
  min?: Maybe<Substations_Min_Fields>;
};


/** aggregate fields of "substations" */
export type Substations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Substations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "substations". All fields are combined with a logical 'AND'. */
export type Substations_Bool_Exp = {
  _and?: InputMaybe<Array<Substations_Bool_Exp>>;
  _not?: InputMaybe<Substations_Bool_Exp>;
  _or?: InputMaybe<Array<Substations_Bool_Exp>>;
  buses?: InputMaybe<Buses_Bool_Exp>;
  buses_aggregate?: InputMaybe<Buses_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  substation_display_name?: InputMaybe<String_Comparison_Exp>;
  substation_owner?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "substations" */
export enum Substations_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubstationsPkey = 'substations_pkey'
}

/** input type for inserting data into table "substations" */
export type Substations_Insert_Input = {
  buses?: InputMaybe<Buses_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']['input']>;
  substation_display_name?: InputMaybe<Scalars['String']['input']>;
  substation_owner?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Substations_Max_Fields = {
  __typename?: 'substations_max_fields';
  id?: Maybe<Scalars['String']['output']>;
  substation_display_name?: Maybe<Scalars['String']['output']>;
  substation_owner?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Substations_Min_Fields = {
  __typename?: 'substations_min_fields';
  id?: Maybe<Scalars['String']['output']>;
  substation_display_name?: Maybe<Scalars['String']['output']>;
  substation_owner?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "substations" */
export type Substations_Mutation_Response = {
  __typename?: 'substations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Substations>;
};

/** input type for inserting object relation for remote table "substations" */
export type Substations_Obj_Rel_Insert_Input = {
  data: Substations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Substations_On_Conflict>;
};

/** on_conflict condition type for table "substations" */
export type Substations_On_Conflict = {
  constraint: Substations_Constraint;
  update_columns?: Array<Substations_Update_Column>;
  where?: InputMaybe<Substations_Bool_Exp>;
};

/** Ordering options when selecting data from "substations". */
export type Substations_Order_By = {
  buses_aggregate?: InputMaybe<Buses_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  substation_display_name?: InputMaybe<Order_By>;
  substation_owner?: InputMaybe<Order_By>;
};

/** primary key columns input for table: substations */
export type Substations_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "substations" */
export enum Substations_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SubstationDisplayName = 'substation_display_name',
  /** column name */
  SubstationOwner = 'substation_owner'
}

/** input type for updating data in table "substations" */
export type Substations_Set_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  substation_display_name?: InputMaybe<Scalars['String']['input']>;
  substation_owner?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "substations" */
export type Substations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Substations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Substations_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  substation_display_name?: InputMaybe<Scalars['String']['input']>;
  substation_owner?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "substations" */
export enum Substations_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SubstationDisplayName = 'substation_display_name',
  /** column name */
  SubstationOwner = 'substation_owner'
}

export type Substations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Substations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Substations_Bool_Exp;
};

/** represents teams that have a Nira subscription */
export type Teams = {
  __typename?: 'teams';
  arcgis_group_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  feature_flags: Scalars['jsonb']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  screening_views: Array<Screening_Views>;
  /** An aggregate relationship */
  screening_views_aggregate: Screening_Views_Aggregate;
  stripe_customer_id?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  subscriptions: Array<Subscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
};


/** represents teams that have a Nira subscription */
export type TeamsFeature_FlagsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** represents teams that have a Nira subscription */
export type TeamsScreening_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Screening_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Screening_Views_Order_By>>;
  where?: InputMaybe<Screening_Views_Bool_Exp>;
};


/** represents teams that have a Nira subscription */
export type TeamsScreening_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screening_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Screening_Views_Order_By>>;
  where?: InputMaybe<Screening_Views_Bool_Exp>;
};


/** represents teams that have a Nira subscription */
export type TeamsSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


/** represents teams that have a Nira subscription */
export type TeamsSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>;
  where?: InputMaybe<Subscriptions_Bool_Exp>;
};


/** represents teams that have a Nira subscription */
export type TeamsUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** represents teams that have a Nira subscription */
export type TeamsUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "teams" */
export type Teams_Aggregate = {
  __typename?: 'teams_aggregate';
  aggregate?: Maybe<Teams_Aggregate_Fields>;
  nodes: Array<Teams>;
};

/** aggregate fields of "teams" */
export type Teams_Aggregate_Fields = {
  __typename?: 'teams_aggregate_fields';
  avg?: Maybe<Teams_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Teams_Max_Fields>;
  min?: Maybe<Teams_Min_Fields>;
  stddev?: Maybe<Teams_Stddev_Fields>;
  stddev_pop?: Maybe<Teams_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Teams_Stddev_Samp_Fields>;
  sum?: Maybe<Teams_Sum_Fields>;
  var_pop?: Maybe<Teams_Var_Pop_Fields>;
  var_samp?: Maybe<Teams_Var_Samp_Fields>;
  variance?: Maybe<Teams_Variance_Fields>;
};


/** aggregate fields of "teams" */
export type Teams_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Teams_Append_Input = {
  feature_flags?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Teams_Avg_Fields = {
  __typename?: 'teams_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "teams". All fields are combined with a logical 'AND'. */
export type Teams_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Bool_Exp>>;
  _not?: InputMaybe<Teams_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Bool_Exp>>;
  arcgis_group_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feature_flags?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  screening_views?: InputMaybe<Screening_Views_Bool_Exp>;
  screening_views_aggregate?: InputMaybe<Screening_Views_Aggregate_Bool_Exp>;
  stripe_customer_id?: InputMaybe<String_Comparison_Exp>;
  subscriptions?: InputMaybe<Subscriptions_Bool_Exp>;
  subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
  users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "teams" */
export enum Teams_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamsPkey = 'teams_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Teams_Delete_At_Path_Input = {
  feature_flags?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Teams_Delete_Elem_Input = {
  feature_flags?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Teams_Delete_Key_Input = {
  feature_flags?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "teams" */
export type Teams_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "teams" */
export type Teams_Insert_Input = {
  arcgis_group_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  feature_flags?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  screening_views?: InputMaybe<Screening_Views_Arr_Rel_Insert_Input>;
  stripe_customer_id?: InputMaybe<Scalars['String']['input']>;
  subscriptions?: InputMaybe<Subscriptions_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Teams_Max_Fields = {
  __typename?: 'teams_max_fields';
  arcgis_group_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stripe_customer_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Teams_Min_Fields = {
  __typename?: 'teams_min_fields';
  arcgis_group_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stripe_customer_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "teams" */
export type Teams_Mutation_Response = {
  __typename?: 'teams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Teams>;
};

/** input type for inserting object relation for remote table "teams" */
export type Teams_Obj_Rel_Insert_Input = {
  data: Teams_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};

/** on_conflict condition type for table "teams" */
export type Teams_On_Conflict = {
  constraint: Teams_Constraint;
  update_columns?: Array<Teams_Update_Column>;
  where?: InputMaybe<Teams_Bool_Exp>;
};

/** Ordering options when selecting data from "teams". */
export type Teams_Order_By = {
  arcgis_group_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feature_flags?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  screening_views_aggregate?: InputMaybe<Screening_Views_Aggregate_Order_By>;
  stripe_customer_id?: InputMaybe<Order_By>;
  subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: teams */
export type Teams_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Teams_Prepend_Input = {
  feature_flags?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "teams" */
export enum Teams_Select_Column {
  /** column name */
  ArcgisGroupId = 'arcgis_group_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeatureFlags = 'feature_flags',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "teams" */
export type Teams_Set_Input = {
  arcgis_group_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  feature_flags?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stripe_customer_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Teams_Stddev_Fields = {
  __typename?: 'teams_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Teams_Stddev_Pop_Fields = {
  __typename?: 'teams_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Teams_Stddev_Samp_Fields = {
  __typename?: 'teams_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "teams" */
export type Teams_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Stream_Cursor_Value_Input = {
  arcgis_group_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  feature_flags?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stripe_customer_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Teams_Sum_Fields = {
  __typename?: 'teams_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "teams" */
export enum Teams_Update_Column {
  /** column name */
  ArcgisGroupId = 'arcgis_group_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeatureFlags = 'feature_flags',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Teams_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Teams_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Teams_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Teams_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Teams_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Teams_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Teams_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Teams_Set_Input>;
  /** filter the rows which have to be updated */
  where: Teams_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Teams_Var_Pop_Fields = {
  __typename?: 'teams_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Teams_Var_Samp_Fields = {
  __typename?: 'teams_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Teams_Variance_Fields = {
  __typename?: 'teams_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "tracked_projects" */
export type Tracked_Projects = {
  __typename?: 'tracked_projects';
  /** An object relationship */
  in_queue_subscription: In_Queue_Subscriptions;
  project_id: Scalars['String']['output'];
  subscription_id: Scalars['uuid']['output'];
};

/** aggregated selection of "tracked_projects" */
export type Tracked_Projects_Aggregate = {
  __typename?: 'tracked_projects_aggregate';
  aggregate?: Maybe<Tracked_Projects_Aggregate_Fields>;
  nodes: Array<Tracked_Projects>;
};

export type Tracked_Projects_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tracked_Projects_Aggregate_Bool_Exp_Count>;
};

export type Tracked_Projects_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tracked_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tracked_Projects_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tracked_projects" */
export type Tracked_Projects_Aggregate_Fields = {
  __typename?: 'tracked_projects_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tracked_Projects_Max_Fields>;
  min?: Maybe<Tracked_Projects_Min_Fields>;
};


/** aggregate fields of "tracked_projects" */
export type Tracked_Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tracked_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tracked_projects" */
export type Tracked_Projects_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tracked_Projects_Max_Order_By>;
  min?: InputMaybe<Tracked_Projects_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tracked_projects" */
export type Tracked_Projects_Arr_Rel_Insert_Input = {
  data: Array<Tracked_Projects_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tracked_Projects_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tracked_projects". All fields are combined with a logical 'AND'. */
export type Tracked_Projects_Bool_Exp = {
  _and?: InputMaybe<Array<Tracked_Projects_Bool_Exp>>;
  _not?: InputMaybe<Tracked_Projects_Bool_Exp>;
  _or?: InputMaybe<Array<Tracked_Projects_Bool_Exp>>;
  in_queue_subscription?: InputMaybe<In_Queue_Subscriptions_Bool_Exp>;
  project_id?: InputMaybe<String_Comparison_Exp>;
  subscription_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "tracked_projects" */
export enum Tracked_Projects_Constraint {
  /** unique or primary key constraint on columns "subscription_id", "project_id" */
  TrackedProjectsPkey = 'tracked_projects_pkey'
}

/** input type for inserting data into table "tracked_projects" */
export type Tracked_Projects_Insert_Input = {
  in_queue_subscription?: InputMaybe<In_Queue_Subscriptions_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Tracked_Projects_Max_Fields = {
  __typename?: 'tracked_projects_max_fields';
  project_id?: Maybe<Scalars['String']['output']>;
  subscription_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "tracked_projects" */
export type Tracked_Projects_Max_Order_By = {
  project_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tracked_Projects_Min_Fields = {
  __typename?: 'tracked_projects_min_fields';
  project_id?: Maybe<Scalars['String']['output']>;
  subscription_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "tracked_projects" */
export type Tracked_Projects_Min_Order_By = {
  project_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tracked_projects" */
export type Tracked_Projects_Mutation_Response = {
  __typename?: 'tracked_projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tracked_Projects>;
};

/** on_conflict condition type for table "tracked_projects" */
export type Tracked_Projects_On_Conflict = {
  constraint: Tracked_Projects_Constraint;
  update_columns?: Array<Tracked_Projects_Update_Column>;
  where?: InputMaybe<Tracked_Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "tracked_projects". */
export type Tracked_Projects_Order_By = {
  in_queue_subscription?: InputMaybe<In_Queue_Subscriptions_Order_By>;
  project_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tracked_projects */
export type Tracked_Projects_Pk_Columns_Input = {
  project_id: Scalars['String']['input'];
  subscription_id: Scalars['uuid']['input'];
};

/** select columns of table "tracked_projects" */
export enum Tracked_Projects_Select_Column {
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SubscriptionId = 'subscription_id'
}

/** input type for updating data in table "tracked_projects" */
export type Tracked_Projects_Set_Input = {
  project_id?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "tracked_projects" */
export type Tracked_Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tracked_Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tracked_Projects_Stream_Cursor_Value_Input = {
  project_id?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "tracked_projects" */
export enum Tracked_Projects_Update_Column {
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SubscriptionId = 'subscription_id'
}

export type Tracked_Projects_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tracked_Projects_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tracked_Projects_Bool_Exp;
};

export type Unlock_Bus_Args = {
  bus_id?: InputMaybe<Scalars['String']['input']>;
};

/** describes buses that have been unlocked as part of each subscription */
export type Unlocked_Buses = {
  __typename?: 'unlocked_buses';
  /** An object relationship */
  bus?: Maybe<Buses>;
  bus_id: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  subscription: Subscriptions;
  subscription_id: Scalars['Int']['output'];
  unlocked: Scalars['Boolean']['output'];
  unlocked_by_user_auth0_id: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "unlocked_buses" */
export type Unlocked_Buses_Aggregate = {
  __typename?: 'unlocked_buses_aggregate';
  aggregate?: Maybe<Unlocked_Buses_Aggregate_Fields>;
  nodes: Array<Unlocked_Buses>;
};

export type Unlocked_Buses_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Unlocked_Buses_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Unlocked_Buses_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Unlocked_Buses_Aggregate_Bool_Exp_Count>;
};

export type Unlocked_Buses_Aggregate_Bool_Exp_Bool_And = {
  arguments: Unlocked_Buses_Select_Column_Unlocked_Buses_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Unlocked_Buses_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Unlocked_Buses_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Unlocked_Buses_Select_Column_Unlocked_Buses_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Unlocked_Buses_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Unlocked_Buses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Unlocked_Buses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Unlocked_Buses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "unlocked_buses" */
export type Unlocked_Buses_Aggregate_Fields = {
  __typename?: 'unlocked_buses_aggregate_fields';
  avg?: Maybe<Unlocked_Buses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Unlocked_Buses_Max_Fields>;
  min?: Maybe<Unlocked_Buses_Min_Fields>;
  stddev?: Maybe<Unlocked_Buses_Stddev_Fields>;
  stddev_pop?: Maybe<Unlocked_Buses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Unlocked_Buses_Stddev_Samp_Fields>;
  sum?: Maybe<Unlocked_Buses_Sum_Fields>;
  var_pop?: Maybe<Unlocked_Buses_Var_Pop_Fields>;
  var_samp?: Maybe<Unlocked_Buses_Var_Samp_Fields>;
  variance?: Maybe<Unlocked_Buses_Variance_Fields>;
};


/** aggregate fields of "unlocked_buses" */
export type Unlocked_Buses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Unlocked_Buses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "unlocked_buses" */
export type Unlocked_Buses_Aggregate_Order_By = {
  avg?: InputMaybe<Unlocked_Buses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Unlocked_Buses_Max_Order_By>;
  min?: InputMaybe<Unlocked_Buses_Min_Order_By>;
  stddev?: InputMaybe<Unlocked_Buses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Unlocked_Buses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Unlocked_Buses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Unlocked_Buses_Sum_Order_By>;
  var_pop?: InputMaybe<Unlocked_Buses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Unlocked_Buses_Var_Samp_Order_By>;
  variance?: InputMaybe<Unlocked_Buses_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "unlocked_buses" */
export type Unlocked_Buses_Arr_Rel_Insert_Input = {
  data: Array<Unlocked_Buses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Unlocked_Buses_On_Conflict>;
};

/** aggregate avg on columns */
export type Unlocked_Buses_Avg_Fields = {
  __typename?: 'unlocked_buses_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "unlocked_buses" */
export type Unlocked_Buses_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "unlocked_buses". All fields are combined with a logical 'AND'. */
export type Unlocked_Buses_Bool_Exp = {
  _and?: InputMaybe<Array<Unlocked_Buses_Bool_Exp>>;
  _not?: InputMaybe<Unlocked_Buses_Bool_Exp>;
  _or?: InputMaybe<Array<Unlocked_Buses_Bool_Exp>>;
  bus?: InputMaybe<Buses_Bool_Exp>;
  bus_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  subscription?: InputMaybe<Subscriptions_Bool_Exp>;
  subscription_id?: InputMaybe<Int_Comparison_Exp>;
  unlocked?: InputMaybe<Boolean_Comparison_Exp>;
  unlocked_by_user_auth0_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "unlocked_buses" */
export enum Unlocked_Buses_Constraint {
  /** unique or primary key constraint on columns "id" */
  UnlockedBusesPkey = 'unlocked_buses_pkey',
  /** unique or primary key constraint on columns "subscription_id", "bus_id" */
  UnlockedBusesSubscriptionIdBusIdKey = 'unlocked_buses_subscription_id_bus_id_key'
}

/** input type for incrementing numeric columns in table "unlocked_buses" */
export type Unlocked_Buses_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "unlocked_buses" */
export type Unlocked_Buses_Insert_Input = {
  bus?: InputMaybe<Buses_Obj_Rel_Insert_Input>;
  bus_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  subscription?: InputMaybe<Subscriptions_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  unlocked?: InputMaybe<Scalars['Boolean']['input']>;
  unlocked_by_user_auth0_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Unlocked_Buses_Max_Fields = {
  __typename?: 'unlocked_buses_max_fields';
  bus_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  unlocked_by_user_auth0_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "unlocked_buses" */
export type Unlocked_Buses_Max_Order_By = {
  bus_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  unlocked_by_user_auth0_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Unlocked_Buses_Min_Fields = {
  __typename?: 'unlocked_buses_min_fields';
  bus_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  unlocked_by_user_auth0_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "unlocked_buses" */
export type Unlocked_Buses_Min_Order_By = {
  bus_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  unlocked_by_user_auth0_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "unlocked_buses" */
export type Unlocked_Buses_Mutation_Response = {
  __typename?: 'unlocked_buses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Unlocked_Buses>;
};

/** on_conflict condition type for table "unlocked_buses" */
export type Unlocked_Buses_On_Conflict = {
  constraint: Unlocked_Buses_Constraint;
  update_columns?: Array<Unlocked_Buses_Update_Column>;
  where?: InputMaybe<Unlocked_Buses_Bool_Exp>;
};

/** Ordering options when selecting data from "unlocked_buses". */
export type Unlocked_Buses_Order_By = {
  bus?: InputMaybe<Buses_Order_By>;
  bus_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscriptions_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  unlocked?: InputMaybe<Order_By>;
  unlocked_by_user_auth0_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: unlocked_buses */
export type Unlocked_Buses_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "unlocked_buses" */
export enum Unlocked_Buses_Select_Column {
  /** column name */
  BusId = 'bus_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  Unlocked = 'unlocked',
  /** column name */
  UnlockedByUserAuth0Id = 'unlocked_by_user_auth0_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "unlocked_buses_aggregate_bool_exp_bool_and_arguments_columns" columns of table "unlocked_buses" */
export enum Unlocked_Buses_Select_Column_Unlocked_Buses_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Unlocked = 'unlocked'
}

/** select "unlocked_buses_aggregate_bool_exp_bool_or_arguments_columns" columns of table "unlocked_buses" */
export enum Unlocked_Buses_Select_Column_Unlocked_Buses_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Unlocked = 'unlocked'
}

/** input type for updating data in table "unlocked_buses" */
export type Unlocked_Buses_Set_Input = {
  bus_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  unlocked?: InputMaybe<Scalars['Boolean']['input']>;
  unlocked_by_user_auth0_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Unlocked_Buses_Stddev_Fields = {
  __typename?: 'unlocked_buses_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "unlocked_buses" */
export type Unlocked_Buses_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Unlocked_Buses_Stddev_Pop_Fields = {
  __typename?: 'unlocked_buses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "unlocked_buses" */
export type Unlocked_Buses_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Unlocked_Buses_Stddev_Samp_Fields = {
  __typename?: 'unlocked_buses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "unlocked_buses" */
export type Unlocked_Buses_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "unlocked_buses" */
export type Unlocked_Buses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Unlocked_Buses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Unlocked_Buses_Stream_Cursor_Value_Input = {
  bus_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  unlocked?: InputMaybe<Scalars['Boolean']['input']>;
  unlocked_by_user_auth0_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Unlocked_Buses_Sum_Fields = {
  __typename?: 'unlocked_buses_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "unlocked_buses" */
export type Unlocked_Buses_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** update columns of table "unlocked_buses" */
export enum Unlocked_Buses_Update_Column {
  /** column name */
  BusId = 'bus_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  Unlocked = 'unlocked',
  /** column name */
  UnlockedByUserAuth0Id = 'unlocked_by_user_auth0_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Unlocked_Buses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Unlocked_Buses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Unlocked_Buses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Unlocked_Buses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Unlocked_Buses_Var_Pop_Fields = {
  __typename?: 'unlocked_buses_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "unlocked_buses" */
export type Unlocked_Buses_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Unlocked_Buses_Var_Samp_Fields = {
  __typename?: 'unlocked_buses_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "unlocked_buses" */
export type Unlocked_Buses_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Unlocked_Buses_Variance_Fields = {
  __typename?: 'unlocked_buses_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "unlocked_buses" */
export type Unlocked_Buses_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  auth0_id: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  feature_flags: Scalars['jsonb']['output'];
  id: Scalars['Int']['output'];
  last_seen: Scalars['timestamptz']['output'];
  name: Scalars['String']['output'];
  role: Scalars['String']['output'];
  /** An array relationship */
  screening_views: Array<Screening_Views>;
  /** An aggregate relationship */
  screening_views_aggregate: Screening_Views_Aggregate;
  /** An object relationship */
  team: Teams;
  team_id: Scalars['Int']['output'];
};


/** columns and relationships of "users" */
export type UsersFeature_FlagsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "users" */
export type UsersScreening_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Screening_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Screening_Views_Order_By>>;
  where?: InputMaybe<Screening_Views_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersScreening_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screening_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Screening_Views_Order_By>>;
  where?: InputMaybe<Screening_Views_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
  stddev?: InputMaybe<Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  feature_flags?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  auth0_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  feature_flags?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_seen?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  screening_views?: InputMaybe<Screening_Views_Bool_Exp>;
  screening_views_aggregate?: InputMaybe<Screening_Views_Aggregate_Bool_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "auth0_id" */
  UsersPkey = 'users_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  feature_flags?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  feature_flags?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  feature_flags?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  auth0_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  feature_flags?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  screening_views?: InputMaybe<Screening_Views_Arr_Rel_Insert_Input>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  auth0_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_seen?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  auth0_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  auth0_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_seen?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  auth0_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  auth0_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  feature_flags?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  screening_views_aggregate?: InputMaybe<Screening_Views_Aggregate_Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  auth0_id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  feature_flags?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Auth0Id = 'auth0_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FeatureFlags = 'feature_flags',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Name = 'name',
  /** column name */
  Role = 'role',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  auth0_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  feature_flags?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  auth0_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  feature_flags?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Auth0Id = 'auth0_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FeatureFlags = 'feature_flags',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Name = 'name',
  /** column name */
  Role = 'role',
  /** column name */
  TeamId = 'team_id'
}

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

export type UnlockedBusFragment = { __typename?: 'buses', unlocked: Array<{ __typename?: 'unlocked_buses', unlocked: boolean }> };

export type ArchiveScreeningViewMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ArchiveScreeningViewMutation = { __typename?: 'mutation_root', update_screening_views_by_pk?: { __typename?: 'screening_views', id: string } | null };

export type CreateScreeningViewMutationVariables = Exact<{
  team_id: Scalars['Int']['input'];
  voltages: Scalars['jsonb']['input'];
  includes_pre_existing: Scalars['Boolean']['input'];
  max_allocated_costs?: InputMaybe<Scalars['numeric']['input']>;
  max_total_costs?: InputMaybe<Scalars['numeric']['input']>;
  scope: Scalars['String']['input'];
  hide_locked_buses: Scalars['Boolean']['input'];
  hide_low_confidence_buses: Scalars['Boolean']['input'];
  low_threshold: Scalars['numeric']['input'];
  medium_threshold: Scalars['numeric']['input'];
  high_threshold: Scalars['numeric']['input'];
  region: Scalars['String']['input'];
  generator_type: Scalars['String']['input'];
  title: Scalars['String']['input'];
  description: Scalars['String']['input'];
  states: Scalars['jsonb']['input'];
}>;


export type CreateScreeningViewMutation = { __typename?: 'mutation_root', insert_screening_views_one?: { __typename?: 'screening_views', id: string } | null };

export type GetBranchesQueryVariables = Exact<{
  voltages: Array<Scalars['numeric']['input']> | Scalars['numeric']['input'];
  region: Scalars['jsonb']['input'];
  filter_states_where_clause: Branches_Bool_Exp;
}>;


export type GetBranchesQuery = { __typename?: 'query_root', branches: Array<{ __typename?: 'branches', branch_path: string, id: string, voltage: number }> };

export type GetBusesByNameOrIdQueryVariables = Exact<{
  region: Scalars['String']['input'];
  query: Scalars['String']['input'];
  voltages: Array<Scalars['numeric']['input']> | Scalars['numeric']['input'];
  scope: Scalars['jsonb']['input'];
  locationCodes: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  filter_locked_buses_where_clause: Buses_Bool_Exp;
  filter_states_where_clause: Buses_Bool_Exp;
}>;


export type GetBusesByNameOrIdQuery = { __typename?: 'query_root', buses: Array<{ __typename?: 'buses', bus_display_name: string, id: string, latitude: number, longitude: number }> };

export type GetBusesWithCapacityCostQueryVariables = Exact<{
  minLat: Scalars['numeric']['input'];
  minLong: Scalars['numeric']['input'];
  maxLat: Scalars['numeric']['input'];
  maxLong: Scalars['numeric']['input'];
  voltages: Array<Scalars['numeric']['input']> | Scalars['numeric']['input'];
  capacity_costs_where_clause: Capacity_Costs_Bool_Exp;
  region: Scalars['String']['input'];
  scope: Scalars['jsonb']['input'];
  locationCodes: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  filter_locked_buses_where_clause: Buses_Bool_Exp;
  filter_states_where_clause: Buses_Bool_Exp;
}>;


export type GetBusesWithCapacityCostQuery = { __typename?: 'query_root', buses: Array<{ __typename?: 'buses', id: string, voltage: number, latitude: number, longitude: number, region: string, bus_display_name: string, location_code: number, capacity_costs: Array<{ __typename?: 'capacity_costs', capacity_size: number, energy_size: number, charging_size: number, upgrade_costs: number, total_upgrade_costs: number }>, substation: { __typename?: 'substations', id: string, substation_display_name: string, substation_owner: string } }> };

export type GetConnectedLinesWithCapacityCostsQueryVariables = Exact<{
  scope: Scalars['String']['input'];
  scopeView: Scalars['String']['input'];
  busId: Scalars['String']['input'];
  lineCapacityCostsWhereClause: Line_Capacity_Costs_Bool_Exp;
}>;


export type GetConnectedLinesWithCapacityCostsQuery = { __typename?: 'query_root', model_lines: Array<{ __typename?: 'model_lines', facility_id: string, line_length: number, from_bus: { __typename?: 'buses', id: string, bus_display_name: string, latitude: number, longitude: number, substation: { __typename?: 'substations', substation_owner: string } }, to_bus: { __typename?: 'buses', id: string, bus_display_name: string, latitude: number, longitude: number, substation: { __typename?: 'substations', substation_owner: string } }, line_capacity_costs: Array<{ __typename?: 'line_capacity_costs', energy_size: number, capacity_size: number, charging_size: number, upgrade_costs: number, total_upgrade_costs: number }> }>, model_lines_to_missing_buses: Array<{ __typename?: 'model_lines_to_missing_buses', facility_id: string, line_length: number, rating: number, voltage: number, missing_bus_id: string, queued_projects_at_missing_bus?: string | null }> };

export type GetConstraintsQueryVariables = Exact<{
  constraintsWhereClause: Constraint_Stacks_Bool_Exp;
}>;


export type GetConstraintsQuery = { __typename?: 'query_root', constraint_stacks: Array<{ __typename?: 'constraint_stacks', bus_id: string, injection_threshold: number, monitored_facility_name: string, monitored_facility_id: string, resource_type: string, rating: number, pre_study_load: number, element_type: string, line_length?: number | null, dfax: number, estimated_cost?: number | null, scope: string, scope_view: string, contingency: string }> };

export type GetLineTapConstraintsQueryVariables = Exact<{
  constraintsWhereClause: Line_Constraint_Stacks_Bool_Exp;
}>;


export type GetLineTapConstraintsQuery = { __typename?: 'query_root', line_constraint_stacks: Array<{ __typename?: 'line_constraint_stacks', tap_facility_id: string, injection_threshold: number, monitored_facility_name: string, monitored_facility_id: string, resource_type: string, rating: number, pre_study_load: number, element_type: string, line_length?: number | null, dfax: number, estimated_cost?: number | null, scope: string, scope_view: string, contingency: string }> };

export type GetLineTapDataWithCapacityCostsQueryVariables = Exact<{
  scope: Scalars['String']['input'];
  scopeView: Scalars['String']['input'];
  facilityId: Scalars['String']['input'];
  capacityCostsWhereClause: Capacity_Costs_Bool_Exp;
  lineCapacityCostsWhereClause: Line_Capacity_Costs_Bool_Exp;
}>;


export type GetLineTapDataWithCapacityCostsQuery = { __typename?: 'query_root', model_lines: Array<{ __typename?: 'model_lines', facility_id: string, rating: number, line_length: number, from_bus: { __typename?: 'buses', id: string, bus_display_name: string, capacity_costs: Array<{ __typename?: 'capacity_costs', energy_size: number, capacity_size: number, charging_size: number }>, unlocked: Array<{ __typename?: 'unlocked_buses', unlocked: boolean }> }, to_bus: { __typename?: 'buses', id: string, bus_display_name: string, capacity_costs: Array<{ __typename?: 'capacity_costs', energy_size: number, capacity_size: number, charging_size: number }>, unlocked: Array<{ __typename?: 'unlocked_buses', unlocked: boolean }> }, line_capacity_costs: Array<{ __typename?: 'line_capacity_costs', energy_size: number, capacity_size: number, charging_size: number, upgrade_costs: number, total_upgrade_costs: number }> }> };

export type GetProjectsQueryVariables = Exact<{
  scope: Scalars['String']['input'];
}>;


export type GetProjectsQuery = { __typename?: 'query_root', projects: Array<{ __typename?: 'projects', eris_capacity_mw: number, fuel_type: string, included: boolean, nris_capacity_mw: number, project_number: string, study_group: string, study_cycle: string }> };

export type GetScreeningViewsQueryVariables = Exact<{
  maybe_id_filter: Screening_Views_Bool_Exp;
}>;


export type GetScreeningViewsQuery = { __typename?: 'query_root', screening_views: Array<{ __typename?: 'screening_views', id: string, created_at: any, description: string, generator_type: string, hide_locked_buses: boolean, hide_low_confidence_buses: boolean, high_threshold: number, includes_pre_existing: boolean, low_threshold: number, max_allocated_costs?: number | null, max_total_costs?: number | null, medium_threshold: number, region: string, scope: string, states: any, title: string, voltages: any, user: { __typename?: 'users', email: string } }> };

export type GetSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionsQuery = { __typename?: 'query_root', subscriptions: Array<{ __typename?: 'subscriptions', region: string, unlimited: boolean, num_buses: number, end_date: string, unlocked_buses_aggregate: { __typename?: 'unlocked_buses_aggregate', aggregate?: { __typename?: 'unlocked_buses_aggregate_fields', count: number } | null } }> };

export type GetUserDataQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type GetUserDataQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: number, auth0_id: string, team_id: number, role: string, team: { __typename?: 'teams', name: string, feature_flags: any, subscriptions: Array<{ __typename?: 'subscriptions', region: string, unlimited: boolean, num_buses: number, end_date: string, unlocked_buses_aggregate: { __typename?: 'unlocked_buses_aggregate', aggregate?: { __typename?: 'unlocked_buses_aggregate_fields', count: number } | null } }> } }> };

export type AddTrackedProjectMutationVariables = Exact<{
  projectId: Scalars['String']['input'];
  subscriptionId: Scalars['uuid']['input'];
}>;


export type AddTrackedProjectMutation = { __typename?: 'mutation_root', insert_tracked_projects_one?: { __typename?: 'tracked_projects', project_id: string, subscription_id: any } | null };

export type CreateScenarioMutationVariables = Exact<{
  subscriptionId: Scalars['uuid']['input'];
  scenarioStage: Scalars['String']['input'];
  region: Scalars['String']['input'];
  teamId: Scalars['Int']['input'];
  authId: Scalars['String']['input'];
  studyGroup: Scalars['String']['input'];
  studyCycle: Scalars['String']['input'];
  studyPhase: Scalars['String']['input'];
  title: Scalars['String']['input'];
  status: Scenario_Statuses_Enum;
  projectSizeAssumptions: Scalars['jsonb']['input'];
  costAssumptions: Scalars['jsonb']['input'];
  runSubmittedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  runSubmittedBy?: InputMaybe<Scalars['String']['input']>;
  parentProjectId?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateScenarioMutation = { __typename?: 'mutation_root', insert_scenarios?: { __typename?: 'scenarios_mutation_response', returning: Array<{ __typename?: 'scenarios', scenario_id: any, region: string, team_id: number, created_by?: string | null, updated_by?: string | null, study_group: string, study_cycle: string, study_phase: string, title: string, project_size_assumptions: any, cost_assumptions: any, status: Scenario_Statuses_Enum }> } | null };

export type DeleteScenarioMutationVariables = Exact<{
  scenarioId: Scalars['uuid']['input'];
}>;


export type DeleteScenarioMutation = { __typename?: 'mutation_root', delete_scenarios?: { __typename?: 'scenarios_mutation_response', affected_rows: number } | null };

export type GetAllProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllProjectsQuery = { __typename?: 'query_root', all_projects: Array<{ __typename?: 'all_projects', eris_capacity_mw: number, nris_capacity_mw: number, fuel_type: string, project_id: string, region: string, status: string, study_cycle: string, study_group: string, study_phase: string }> };

export type GetInQueueSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInQueueSubscriptionsQuery = { __typename?: 'query_root', in_queue_subscriptions: Array<{ __typename?: 'in_queue_subscriptions', subscription_id: any, region: string, study_cycle: string, study_group: string, phase: string, scenario_limit: number }> };

export type GetScenarioMetricsQueryVariables = Exact<{
  projectId: Scalars['String']['input'];
  scenarioIds?: InputMaybe<Array<Scalars['uuid']['input']> | Scalars['uuid']['input']>;
}>;


export type GetScenarioMetricsQuery = { __typename?: 'query_root', scenarios: Array<{ __typename?: 'scenarios', scenario_id: any, scenario_projects_aggregate: { __typename?: 'scenario_projects_aggregate', aggregate?: { __typename?: 'scenario_projects_aggregate_fields', max?: { __typename?: 'scenario_projects_max_fields', sum_allocated_cost?: number | null, cost_per_mw?: number | null } | null } | null } }> };

export type GetScenarioResultsQueryVariables = Exact<{
  scenarioId: Scalars['uuid']['input'];
}>;


export type GetScenarioResultsQuery = { __typename?: 'query_root', scenarios: Array<{ __typename?: 'scenarios', scenario_allocated_upgrades: Array<{ __typename?: 'scenario_allocated_upgrades', allocated_cost: number, constraint_type: Constraint_Types_Enum, max_dfax: number, max_post_study_load?: number | null, monitored_facility_names: any, sum_mw_impact: number, network_upgrade_id: string, network_upgrade_name: string, pmax: number, project_id: string, total_cost: number, notes?: string | null }>, scenario_constraints: Array<{ __typename?: 'scenario_constraints', constraint_type: Constraint_Types_Enum, monitored_facility_name: string, element_type?: string | null, line_length?: number | null, voltage?: string | null, post_study_flow?: string | null, post_study_load?: number | null, post_study_load_ac?: string | null, post_study_load_dc?: number | null, post_study_rating?: number | null, pre_study_flow?: number | null, pre_study_load?: number | null, pre_study_rating: number, worst_contingency_name?: string | null, network_upgrade_names: any, project_id: string, dfax: number, mw_impact: number, notes?: string | null }>, scenario_projects: Array<{ __typename?: 'scenario_projects', assigned_upgrades: any, cost_per_mw: number, eris_size_mw: number, nris_size_mw: number, overloaded_facilities: any, project_id: string, sum_allocated_cost: number, sum_allocated_cost_by_constraint_type?: any | null }> }> };

export type RemoveTrackedProjectMutationVariables = Exact<{
  projectId: Scalars['String']['input'];
  subscriptionId: Scalars['uuid']['input'];
}>;


export type RemoveTrackedProjectMutation = { __typename?: 'mutation_root', delete_tracked_projects_by_pk?: { __typename?: 'tracked_projects', project_id: string, subscription_id: any } | null };

export type SubmitScenarioMutationVariables = Exact<{
  scenarioId: Scalars['uuid']['input'];
  authId: Scalars['String']['input'];
  currentTime: Scalars['timestamptz']['input'];
}>;


export type SubmitScenarioMutation = { __typename?: 'mutation_root', update_scenarios?: { __typename?: 'scenarios_mutation_response', returning: Array<{ __typename?: 'scenarios', scenario_id: any, region: string, team_id: number, created_by?: string | null, updated_by?: string | null, study_group: string, study_cycle: string, study_phase: string, title: string, project_size_assumptions: any, status: Scenario_Statuses_Enum }> } | null };

export type SubscribeToInQueueScenariosSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeToInQueueScenariosSubscription = { __typename?: 'subscription_root', scenarios: Array<{ __typename?: 'scenarios', scenario_id: any, scenario_type: string, scenario_stage: string, title: string, project_size_assumptions: any, cost_assumptions: any, region: string, study_cycle: string, study_group: string, study_phase: string, status: Scenario_Statuses_Enum, input_data_used?: any | null, created_at: any, created_by?: string | null, updated_at: any, updated_by?: string | null, dagster_run_id?: string | null, run_completed_at?: any | null, run_started_at?: any | null, run_submitted_at?: any | null, run_submitted_by?: string | null, parent_project_id?: string | null, in_queue_subscription?: { __typename?: 'in_queue_subscriptions', tracked_projects: Array<{ __typename?: 'tracked_projects', project_id: string }> } | null }> };

export type SubscribeToTrackedProjectsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeToTrackedProjectsSubscription = { __typename?: 'subscription_root', tracked_projects: Array<{ __typename?: 'tracked_projects', subscription_id: any, project_id: string }> };

export type UpdateScenarioMutationVariables = Exact<{
  scenarioId: Scalars['uuid']['input'];
  authId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  status: Scenario_Statuses_Enum;
  projectSizeAssumptions: Scalars['jsonb']['input'];
  parentProjectId?: InputMaybe<Scalars['String']['input']>;
  costAssumptions: Scalars['jsonb']['input'];
}>;


export type UpdateScenarioMutation = { __typename?: 'mutation_root', update_scenarios?: { __typename?: 'scenarios_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'scenarios', scenario_id: any, region: string, team_id: number, created_by?: string | null, updated_by?: string | null, study_group: string, study_cycle: string, study_phase: string, title: string, project_size_assumptions: any, status: Scenario_Statuses_Enum, cost_assumptions: any }> } | null };

export type UpdateScenarioParentProjectMutationVariables = Exact<{
  scenarioId: Scalars['uuid']['input'];
  authId: Scalars['String']['input'];
  parentProjectId?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateScenarioParentProjectMutation = { __typename?: 'mutation_root', update_scenarios?: { __typename?: 'scenarios_mutation_response', affected_rows: number } | null };

export type UpdateScenarioStatusMutationVariables = Exact<{
  scenarioId: Scalars['uuid']['input'];
  status: Scenario_Statuses_Enum;
}>;


export type UpdateScenarioStatusMutation = { __typename?: 'mutation_root', update_scenarios?: { __typename?: 'scenarios_mutation_response', affected_rows: number } | null };

export type UpdateScenarioTitleMutationVariables = Exact<{
  scenarioId: Scalars['uuid']['input'];
  authId: Scalars['String']['input'];
  title: Scalars['String']['input'];
}>;


export type UpdateScenarioTitleMutation = { __typename?: 'mutation_root', update_scenarios?: { __typename?: 'scenarios_mutation_response', affected_rows: number } | null };

export type UnlockBusMutationVariables = Exact<{
  busId: Scalars['String']['input'];
}>;


export type UnlockBusMutation = { __typename?: 'mutation_root', unlock_bus?: { __typename?: 'unlocked_buses', bus_id: string } | null };

export const UnlockedBusFragmentDoc = gql`
    fragment UnlockedBus on buses {
  unlocked {
    unlocked
  }
}
    `;
export const ArchiveScreeningViewDocument = gql`
    mutation ArchiveScreeningView($id: String!) {
  update_screening_views_by_pk(pk_columns: {id: $id}, _set: {is_archived: true}) {
    id
  }
}
    `;
export type ArchiveScreeningViewMutationFn = Apollo.MutationFunction<ArchiveScreeningViewMutation, ArchiveScreeningViewMutationVariables>;

/**
 * __useArchiveScreeningViewMutation__
 *
 * To run a mutation, you first call `useArchiveScreeningViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveScreeningViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveScreeningViewMutation, { data, loading, error }] = useArchiveScreeningViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveScreeningViewMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveScreeningViewMutation, ArchiveScreeningViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveScreeningViewMutation, ArchiveScreeningViewMutationVariables>(ArchiveScreeningViewDocument, options);
      }
export type ArchiveScreeningViewMutationHookResult = ReturnType<typeof useArchiveScreeningViewMutation>;
export type ArchiveScreeningViewMutationResult = Apollo.MutationResult<ArchiveScreeningViewMutation>;
export type ArchiveScreeningViewMutationOptions = Apollo.BaseMutationOptions<ArchiveScreeningViewMutation, ArchiveScreeningViewMutationVariables>;
export const CreateScreeningViewDocument = gql`
    mutation CreateScreeningView($team_id: Int!, $voltages: jsonb!, $includes_pre_existing: Boolean!, $max_allocated_costs: numeric, $max_total_costs: numeric, $scope: String!, $hide_locked_buses: Boolean!, $hide_low_confidence_buses: Boolean!, $low_threshold: numeric!, $medium_threshold: numeric!, $high_threshold: numeric!, $region: String!, $generator_type: String!, $title: String!, $description: String!, $states: jsonb!) {
  insert_screening_views_one(
    object: {team_id: $team_id, voltages: $voltages, includes_pre_existing: $includes_pre_existing, max_allocated_costs: $max_allocated_costs, max_total_costs: $max_total_costs, scope: $scope, hide_locked_buses: $hide_locked_buses, hide_low_confidence_buses: $hide_low_confidence_buses, low_threshold: $low_threshold, medium_threshold: $medium_threshold, high_threshold: $high_threshold, region: $region, generator_type: $generator_type, title: $title, description: $description, states: $states}
  ) {
    id
  }
}
    `;
export type CreateScreeningViewMutationFn = Apollo.MutationFunction<CreateScreeningViewMutation, CreateScreeningViewMutationVariables>;

/**
 * __useCreateScreeningViewMutation__
 *
 * To run a mutation, you first call `useCreateScreeningViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScreeningViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScreeningViewMutation, { data, loading, error }] = useCreateScreeningViewMutation({
 *   variables: {
 *      team_id: // value for 'team_id'
 *      voltages: // value for 'voltages'
 *      includes_pre_existing: // value for 'includes_pre_existing'
 *      max_allocated_costs: // value for 'max_allocated_costs'
 *      max_total_costs: // value for 'max_total_costs'
 *      scope: // value for 'scope'
 *      hide_locked_buses: // value for 'hide_locked_buses'
 *      hide_low_confidence_buses: // value for 'hide_low_confidence_buses'
 *      low_threshold: // value for 'low_threshold'
 *      medium_threshold: // value for 'medium_threshold'
 *      high_threshold: // value for 'high_threshold'
 *      region: // value for 'region'
 *      generator_type: // value for 'generator_type'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      states: // value for 'states'
 *   },
 * });
 */
export function useCreateScreeningViewMutation(baseOptions?: Apollo.MutationHookOptions<CreateScreeningViewMutation, CreateScreeningViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScreeningViewMutation, CreateScreeningViewMutationVariables>(CreateScreeningViewDocument, options);
      }
export type CreateScreeningViewMutationHookResult = ReturnType<typeof useCreateScreeningViewMutation>;
export type CreateScreeningViewMutationResult = Apollo.MutationResult<CreateScreeningViewMutation>;
export type CreateScreeningViewMutationOptions = Apollo.BaseMutationOptions<CreateScreeningViewMutation, CreateScreeningViewMutationVariables>;
export const GetBranchesDocument = gql`
    query getBranches($voltages: [numeric!]!, $region: jsonb!, $filter_states_where_clause: branches_bool_exp!) {
  branches(
    where: {_and: [{voltage: {_in: $voltages}}, {regions: {_contains: $region}}, $filter_states_where_clause]}
  ) {
    branch_path
    id
    voltage
  }
}
    `;

/**
 * __useGetBranchesQuery__
 *
 * To run a query within a React component, call `useGetBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchesQuery({
 *   variables: {
 *      voltages: // value for 'voltages'
 *      region: // value for 'region'
 *      filter_states_where_clause: // value for 'filter_states_where_clause'
 *   },
 * });
 */
export function useGetBranchesQuery(baseOptions: Apollo.QueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchesQuery, GetBranchesQueryVariables>(GetBranchesDocument, options);
      }
export function useGetBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchesQuery, GetBranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchesQuery, GetBranchesQueryVariables>(GetBranchesDocument, options);
        }
export type GetBranchesQueryHookResult = ReturnType<typeof useGetBranchesQuery>;
export type GetBranchesLazyQueryHookResult = ReturnType<typeof useGetBranchesLazyQuery>;
export type GetBranchesQueryResult = Apollo.QueryResult<GetBranchesQuery, GetBranchesQueryVariables>;
export const GetBusesByNameOrIdDocument = gql`
    query getBusesByNameOrId($region: String!, $query: String!, $voltages: [numeric!]!, $scope: jsonb!, $locationCodes: [Int!]!, $filter_locked_buses_where_clause: buses_bool_exp!, $filter_states_where_clause: buses_bool_exp!) {
  buses(
    where: {_and: [{_or: [{bus_display_name: {_ilike: $query}}, {id: {_ilike: $query}}]}, {region: {_eq: $region}}, {voltage: {_in: $voltages}}, {scopes: {_contains: $scope}}, {location_code: {_in: $locationCodes}}, $filter_locked_buses_where_clause, $filter_states_where_clause]}
  ) {
    bus_display_name
    id
    latitude
    longitude
  }
}
    `;

/**
 * __useGetBusesByNameOrIdQuery__
 *
 * To run a query within a React component, call `useGetBusesByNameOrIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusesByNameOrIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusesByNameOrIdQuery({
 *   variables: {
 *      region: // value for 'region'
 *      query: // value for 'query'
 *      voltages: // value for 'voltages'
 *      scope: // value for 'scope'
 *      locationCodes: // value for 'locationCodes'
 *      filter_locked_buses_where_clause: // value for 'filter_locked_buses_where_clause'
 *      filter_states_where_clause: // value for 'filter_states_where_clause'
 *   },
 * });
 */
export function useGetBusesByNameOrIdQuery(baseOptions: Apollo.QueryHookOptions<GetBusesByNameOrIdQuery, GetBusesByNameOrIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusesByNameOrIdQuery, GetBusesByNameOrIdQueryVariables>(GetBusesByNameOrIdDocument, options);
      }
export function useGetBusesByNameOrIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusesByNameOrIdQuery, GetBusesByNameOrIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusesByNameOrIdQuery, GetBusesByNameOrIdQueryVariables>(GetBusesByNameOrIdDocument, options);
        }
export type GetBusesByNameOrIdQueryHookResult = ReturnType<typeof useGetBusesByNameOrIdQuery>;
export type GetBusesByNameOrIdLazyQueryHookResult = ReturnType<typeof useGetBusesByNameOrIdLazyQuery>;
export type GetBusesByNameOrIdQueryResult = Apollo.QueryResult<GetBusesByNameOrIdQuery, GetBusesByNameOrIdQueryVariables>;
export const GetBusesWithCapacityCostDocument = gql`
    query getBusesWithCapacityCost($minLat: numeric!, $minLong: numeric!, $maxLat: numeric!, $maxLong: numeric!, $voltages: [numeric!]!, $capacity_costs_where_clause: capacity_costs_bool_exp!, $region: String!, $scope: jsonb!, $locationCodes: [Int!]!, $filter_locked_buses_where_clause: buses_bool_exp!, $filter_states_where_clause: buses_bool_exp!) {
  buses(
    where: {_and: [{latitude: {_gte: $minLat}}, {latitude: {_lte: $maxLat}}, {longitude: {_lte: $maxLong}}, {longitude: {_gte: $minLong}}, {voltage: {_in: $voltages}}, {region: {_eq: $region}}, {scopes: {_contains: $scope}}, {location_code: {_in: $locationCodes}}, $filter_locked_buses_where_clause, $filter_states_where_clause]}
  ) {
    id
    voltage
    latitude
    longitude
    region
    bus_display_name
    location_code
    capacity_costs(
      where: $capacity_costs_where_clause
      order_by: {energy_size: desc}
      limit: 1
    ) {
      capacity_size
      energy_size
      charging_size
      upgrade_costs
      total_upgrade_costs
    }
    substation {
      id
      substation_display_name
      substation_owner
    }
  }
}
    `;

/**
 * __useGetBusesWithCapacityCostQuery__
 *
 * To run a query within a React component, call `useGetBusesWithCapacityCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusesWithCapacityCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusesWithCapacityCostQuery({
 *   variables: {
 *      minLat: // value for 'minLat'
 *      minLong: // value for 'minLong'
 *      maxLat: // value for 'maxLat'
 *      maxLong: // value for 'maxLong'
 *      voltages: // value for 'voltages'
 *      capacity_costs_where_clause: // value for 'capacity_costs_where_clause'
 *      region: // value for 'region'
 *      scope: // value for 'scope'
 *      locationCodes: // value for 'locationCodes'
 *      filter_locked_buses_where_clause: // value for 'filter_locked_buses_where_clause'
 *      filter_states_where_clause: // value for 'filter_states_where_clause'
 *   },
 * });
 */
export function useGetBusesWithCapacityCostQuery(baseOptions: Apollo.QueryHookOptions<GetBusesWithCapacityCostQuery, GetBusesWithCapacityCostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusesWithCapacityCostQuery, GetBusesWithCapacityCostQueryVariables>(GetBusesWithCapacityCostDocument, options);
      }
export function useGetBusesWithCapacityCostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusesWithCapacityCostQuery, GetBusesWithCapacityCostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusesWithCapacityCostQuery, GetBusesWithCapacityCostQueryVariables>(GetBusesWithCapacityCostDocument, options);
        }
export type GetBusesWithCapacityCostQueryHookResult = ReturnType<typeof useGetBusesWithCapacityCostQuery>;
export type GetBusesWithCapacityCostLazyQueryHookResult = ReturnType<typeof useGetBusesWithCapacityCostLazyQuery>;
export type GetBusesWithCapacityCostQueryResult = Apollo.QueryResult<GetBusesWithCapacityCostQuery, GetBusesWithCapacityCostQueryVariables>;
export const GetConnectedLinesWithCapacityCostsDocument = gql`
    query getConnectedLinesWithCapacityCosts($scope: String!, $scopeView: String!, $busId: String!, $lineCapacityCostsWhereClause: line_capacity_costs_bool_exp!) {
  model_lines(
    where: {_and: [{_or: [{from_bus_id: {_eq: $busId}}, {to_bus_id: {_eq: $busId}}]}, {scope: {_eq: $scope}}, {scope_view: {_eq: $scopeView}}]}
  ) {
    facility_id
    line_length
    from_bus {
      id
      bus_display_name
      latitude
      longitude
      substation {
        substation_owner
      }
    }
    to_bus {
      id
      bus_display_name
      latitude
      longitude
      substation {
        substation_owner
      }
    }
    line_capacity_costs(
      where: $lineCapacityCostsWhereClause
      order_by: {energy_size: desc}
      limit: 1
    ) {
      energy_size
      capacity_size
      charging_size
      upgrade_costs
      total_upgrade_costs
    }
  }
  model_lines_to_missing_buses(
    where: {_and: [{from_bus_id: {_eq: $busId}}, {scope: {_eq: $scope}}, {scope_view: {_eq: $scopeView}}]}
  ) {
    facility_id
    line_length
    rating
    voltage
    missing_bus_id
    queued_projects_at_missing_bus
  }
}
    `;

/**
 * __useGetConnectedLinesWithCapacityCostsQuery__
 *
 * To run a query within a React component, call `useGetConnectedLinesWithCapacityCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConnectedLinesWithCapacityCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConnectedLinesWithCapacityCostsQuery({
 *   variables: {
 *      scope: // value for 'scope'
 *      scopeView: // value for 'scopeView'
 *      busId: // value for 'busId'
 *      lineCapacityCostsWhereClause: // value for 'lineCapacityCostsWhereClause'
 *   },
 * });
 */
export function useGetConnectedLinesWithCapacityCostsQuery(baseOptions: Apollo.QueryHookOptions<GetConnectedLinesWithCapacityCostsQuery, GetConnectedLinesWithCapacityCostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConnectedLinesWithCapacityCostsQuery, GetConnectedLinesWithCapacityCostsQueryVariables>(GetConnectedLinesWithCapacityCostsDocument, options);
      }
export function useGetConnectedLinesWithCapacityCostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConnectedLinesWithCapacityCostsQuery, GetConnectedLinesWithCapacityCostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConnectedLinesWithCapacityCostsQuery, GetConnectedLinesWithCapacityCostsQueryVariables>(GetConnectedLinesWithCapacityCostsDocument, options);
        }
export type GetConnectedLinesWithCapacityCostsQueryHookResult = ReturnType<typeof useGetConnectedLinesWithCapacityCostsQuery>;
export type GetConnectedLinesWithCapacityCostsLazyQueryHookResult = ReturnType<typeof useGetConnectedLinesWithCapacityCostsLazyQuery>;
export type GetConnectedLinesWithCapacityCostsQueryResult = Apollo.QueryResult<GetConnectedLinesWithCapacityCostsQuery, GetConnectedLinesWithCapacityCostsQueryVariables>;
export const GetConstraintsDocument = gql`
    query getConstraints($constraintsWhereClause: constraint_stacks_bool_exp!) {
  constraint_stacks(where: $constraintsWhereClause) {
    bus_id
    injection_threshold
    monitored_facility_name
    monitored_facility_id
    resource_type
    rating
    pre_study_load
    element_type
    line_length
    dfax
    estimated_cost
    scope
    scope_view
    contingency
  }
}
    `;

/**
 * __useGetConstraintsQuery__
 *
 * To run a query within a React component, call `useGetConstraintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConstraintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConstraintsQuery({
 *   variables: {
 *      constraintsWhereClause: // value for 'constraintsWhereClause'
 *   },
 * });
 */
export function useGetConstraintsQuery(baseOptions: Apollo.QueryHookOptions<GetConstraintsQuery, GetConstraintsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConstraintsQuery, GetConstraintsQueryVariables>(GetConstraintsDocument, options);
      }
export function useGetConstraintsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConstraintsQuery, GetConstraintsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConstraintsQuery, GetConstraintsQueryVariables>(GetConstraintsDocument, options);
        }
export type GetConstraintsQueryHookResult = ReturnType<typeof useGetConstraintsQuery>;
export type GetConstraintsLazyQueryHookResult = ReturnType<typeof useGetConstraintsLazyQuery>;
export type GetConstraintsQueryResult = Apollo.QueryResult<GetConstraintsQuery, GetConstraintsQueryVariables>;
export const GetLineTapConstraintsDocument = gql`
    query getLineTapConstraints($constraintsWhereClause: line_constraint_stacks_bool_exp!) {
  line_constraint_stacks(where: $constraintsWhereClause) {
    tap_facility_id
    injection_threshold
    monitored_facility_name
    monitored_facility_id
    resource_type
    rating
    pre_study_load
    element_type
    line_length
    dfax
    estimated_cost
    scope
    scope_view
    contingency
  }
}
    `;

/**
 * __useGetLineTapConstraintsQuery__
 *
 * To run a query within a React component, call `useGetLineTapConstraintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLineTapConstraintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLineTapConstraintsQuery({
 *   variables: {
 *      constraintsWhereClause: // value for 'constraintsWhereClause'
 *   },
 * });
 */
export function useGetLineTapConstraintsQuery(baseOptions: Apollo.QueryHookOptions<GetLineTapConstraintsQuery, GetLineTapConstraintsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLineTapConstraintsQuery, GetLineTapConstraintsQueryVariables>(GetLineTapConstraintsDocument, options);
      }
export function useGetLineTapConstraintsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLineTapConstraintsQuery, GetLineTapConstraintsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLineTapConstraintsQuery, GetLineTapConstraintsQueryVariables>(GetLineTapConstraintsDocument, options);
        }
export type GetLineTapConstraintsQueryHookResult = ReturnType<typeof useGetLineTapConstraintsQuery>;
export type GetLineTapConstraintsLazyQueryHookResult = ReturnType<typeof useGetLineTapConstraintsLazyQuery>;
export type GetLineTapConstraintsQueryResult = Apollo.QueryResult<GetLineTapConstraintsQuery, GetLineTapConstraintsQueryVariables>;
export const GetLineTapDataWithCapacityCostsDocument = gql`
    query getLineTapDataWithCapacityCosts($scope: String!, $scopeView: String!, $facilityId: String!, $capacityCostsWhereClause: capacity_costs_bool_exp!, $lineCapacityCostsWhereClause: line_capacity_costs_bool_exp!) {
  model_lines(
    where: {_and: [{scope: {_eq: $scope}}, {scope_view: {_eq: $scopeView}}, {facility_id: {_eq: $facilityId}}]}
  ) {
    facility_id
    rating
    line_length
    from_bus {
      id
      bus_display_name
      capacity_costs(
        where: $capacityCostsWhereClause
        order_by: {energy_size: desc}
        limit: 1
      ) {
        energy_size
        capacity_size
        charging_size
      }
      unlocked {
        unlocked
      }
    }
    to_bus {
      id
      bus_display_name
      capacity_costs(
        where: $capacityCostsWhereClause
        order_by: {energy_size: desc}
        limit: 1
      ) {
        energy_size
        capacity_size
        charging_size
      }
      unlocked {
        unlocked
      }
    }
    line_capacity_costs(
      where: $lineCapacityCostsWhereClause
      order_by: {energy_size: desc}
      limit: 1
    ) {
      energy_size
      capacity_size
      charging_size
      upgrade_costs
      total_upgrade_costs
    }
  }
}
    `;

/**
 * __useGetLineTapDataWithCapacityCostsQuery__
 *
 * To run a query within a React component, call `useGetLineTapDataWithCapacityCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLineTapDataWithCapacityCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLineTapDataWithCapacityCostsQuery({
 *   variables: {
 *      scope: // value for 'scope'
 *      scopeView: // value for 'scopeView'
 *      facilityId: // value for 'facilityId'
 *      capacityCostsWhereClause: // value for 'capacityCostsWhereClause'
 *      lineCapacityCostsWhereClause: // value for 'lineCapacityCostsWhereClause'
 *   },
 * });
 */
export function useGetLineTapDataWithCapacityCostsQuery(baseOptions: Apollo.QueryHookOptions<GetLineTapDataWithCapacityCostsQuery, GetLineTapDataWithCapacityCostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLineTapDataWithCapacityCostsQuery, GetLineTapDataWithCapacityCostsQueryVariables>(GetLineTapDataWithCapacityCostsDocument, options);
      }
export function useGetLineTapDataWithCapacityCostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLineTapDataWithCapacityCostsQuery, GetLineTapDataWithCapacityCostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLineTapDataWithCapacityCostsQuery, GetLineTapDataWithCapacityCostsQueryVariables>(GetLineTapDataWithCapacityCostsDocument, options);
        }
export type GetLineTapDataWithCapacityCostsQueryHookResult = ReturnType<typeof useGetLineTapDataWithCapacityCostsQuery>;
export type GetLineTapDataWithCapacityCostsLazyQueryHookResult = ReturnType<typeof useGetLineTapDataWithCapacityCostsLazyQuery>;
export type GetLineTapDataWithCapacityCostsQueryResult = Apollo.QueryResult<GetLineTapDataWithCapacityCostsQuery, GetLineTapDataWithCapacityCostsQueryVariables>;
export const GetProjectsDocument = gql`
    query getProjects($scope: String!) {
  projects(where: {scope: {_eq: $scope}}) {
    eris_capacity_mw
    fuel_type
    included
    nris_capacity_mw
    project_number
    study_group
    study_cycle
  }
}
    `;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetProjectsQuery(baseOptions: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
      }
export function useGetProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
        }
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const GetScreeningViewsDocument = gql`
    query getScreeningViews($maybe_id_filter: screening_views_bool_exp!) {
  screening_views(where: {_and: [{is_archived: {_eq: false}}, $maybe_id_filter]}) {
    id
    created_at
    description
    generator_type
    hide_locked_buses
    hide_low_confidence_buses
    high_threshold
    includes_pre_existing
    low_threshold
    max_allocated_costs
    max_total_costs
    medium_threshold
    region
    scope
    states
    title
    voltages
    user {
      email
    }
  }
}
    `;

/**
 * __useGetScreeningViewsQuery__
 *
 * To run a query within a React component, call `useGetScreeningViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScreeningViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScreeningViewsQuery({
 *   variables: {
 *      maybe_id_filter: // value for 'maybe_id_filter'
 *   },
 * });
 */
export function useGetScreeningViewsQuery(baseOptions: Apollo.QueryHookOptions<GetScreeningViewsQuery, GetScreeningViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScreeningViewsQuery, GetScreeningViewsQueryVariables>(GetScreeningViewsDocument, options);
      }
export function useGetScreeningViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScreeningViewsQuery, GetScreeningViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScreeningViewsQuery, GetScreeningViewsQueryVariables>(GetScreeningViewsDocument, options);
        }
export type GetScreeningViewsQueryHookResult = ReturnType<typeof useGetScreeningViewsQuery>;
export type GetScreeningViewsLazyQueryHookResult = ReturnType<typeof useGetScreeningViewsLazyQuery>;
export type GetScreeningViewsQueryResult = Apollo.QueryResult<GetScreeningViewsQuery, GetScreeningViewsQueryVariables>;
export const GetSubscriptionsDocument = gql`
    query getSubscriptions {
  subscriptions {
    region
    unlimited
    num_buses
    end_date
    unlocked_buses_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
      }
export function useGetSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
        }
export type GetSubscriptionsQueryHookResult = ReturnType<typeof useGetSubscriptionsQuery>;
export type GetSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsLazyQuery>;
export type GetSubscriptionsQueryResult = Apollo.QueryResult<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>;
export const GetUserDataDocument = gql`
    query getUserData($userId: String!) {
  users(where: {auth0_id: {_eq: $userId}}) {
    id
    auth0_id
    team_id
    role
    team {
      name
      subscriptions {
        region
        unlimited
        num_buses
        end_date
        unlocked_buses_aggregate {
          aggregate {
            count
          }
        }
      }
      feature_flags
    }
  }
}
    `;

/**
 * __useGetUserDataQuery__
 *
 * To run a query within a React component, call `useGetUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserDataQuery(baseOptions: Apollo.QueryHookOptions<GetUserDataQuery, GetUserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDataQuery, GetUserDataQueryVariables>(GetUserDataDocument, options);
      }
export function useGetUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDataQuery, GetUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDataQuery, GetUserDataQueryVariables>(GetUserDataDocument, options);
        }
export type GetUserDataQueryHookResult = ReturnType<typeof useGetUserDataQuery>;
export type GetUserDataLazyQueryHookResult = ReturnType<typeof useGetUserDataLazyQuery>;
export type GetUserDataQueryResult = Apollo.QueryResult<GetUserDataQuery, GetUserDataQueryVariables>;
export const AddTrackedProjectDocument = gql`
    mutation AddTrackedProject($projectId: String!, $subscriptionId: uuid!) {
  insert_tracked_projects_one(
    object: {project_id: $projectId, subscription_id: $subscriptionId}
  ) {
    project_id
    subscription_id
  }
}
    `;
export type AddTrackedProjectMutationFn = Apollo.MutationFunction<AddTrackedProjectMutation, AddTrackedProjectMutationVariables>;

/**
 * __useAddTrackedProjectMutation__
 *
 * To run a mutation, you first call `useAddTrackedProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrackedProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrackedProjectMutation, { data, loading, error }] = useAddTrackedProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useAddTrackedProjectMutation(baseOptions?: Apollo.MutationHookOptions<AddTrackedProjectMutation, AddTrackedProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTrackedProjectMutation, AddTrackedProjectMutationVariables>(AddTrackedProjectDocument, options);
      }
export type AddTrackedProjectMutationHookResult = ReturnType<typeof useAddTrackedProjectMutation>;
export type AddTrackedProjectMutationResult = Apollo.MutationResult<AddTrackedProjectMutation>;
export type AddTrackedProjectMutationOptions = Apollo.BaseMutationOptions<AddTrackedProjectMutation, AddTrackedProjectMutationVariables>;
export const CreateScenarioDocument = gql`
    mutation CreateScenario($subscriptionId: uuid!, $scenarioStage: String!, $region: String!, $teamId: Int!, $authId: String!, $studyGroup: String!, $studyCycle: String!, $studyPhase: String!, $title: String!, $status: scenario_statuses_enum!, $projectSizeAssumptions: jsonb!, $costAssumptions: jsonb!, $runSubmittedAt: timestamptz, $runSubmittedBy: String, $parentProjectId: String) {
  insert_scenarios(
    objects: {scenario_type: "CUSTOM", scenario_stage: $scenarioStage, region: $region, team_id: $teamId, created_by: $authId, updated_by: $authId, study_group: $studyGroup, study_cycle: $studyCycle, study_phase: $studyPhase, title: $title, project_size_assumptions: $projectSizeAssumptions, cost_assumptions: $costAssumptions, run_submitted_at: $runSubmittedAt, run_submitted_by: $runSubmittedBy, status: $status, subscription_id: $subscriptionId, parent_project_id: $parentProjectId}
  ) {
    returning {
      scenario_id
      region
      team_id
      created_by
      updated_by
      study_group
      study_cycle
      study_phase
      title
      project_size_assumptions
      cost_assumptions
      status
    }
  }
}
    `;
export type CreateScenarioMutationFn = Apollo.MutationFunction<CreateScenarioMutation, CreateScenarioMutationVariables>;

/**
 * __useCreateScenarioMutation__
 *
 * To run a mutation, you first call `useCreateScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScenarioMutation, { data, loading, error }] = useCreateScenarioMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      scenarioStage: // value for 'scenarioStage'
 *      region: // value for 'region'
 *      teamId: // value for 'teamId'
 *      authId: // value for 'authId'
 *      studyGroup: // value for 'studyGroup'
 *      studyCycle: // value for 'studyCycle'
 *      studyPhase: // value for 'studyPhase'
 *      title: // value for 'title'
 *      status: // value for 'status'
 *      projectSizeAssumptions: // value for 'projectSizeAssumptions'
 *      costAssumptions: // value for 'costAssumptions'
 *      runSubmittedAt: // value for 'runSubmittedAt'
 *      runSubmittedBy: // value for 'runSubmittedBy'
 *      parentProjectId: // value for 'parentProjectId'
 *   },
 * });
 */
export function useCreateScenarioMutation(baseOptions?: Apollo.MutationHookOptions<CreateScenarioMutation, CreateScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScenarioMutation, CreateScenarioMutationVariables>(CreateScenarioDocument, options);
      }
export type CreateScenarioMutationHookResult = ReturnType<typeof useCreateScenarioMutation>;
export type CreateScenarioMutationResult = Apollo.MutationResult<CreateScenarioMutation>;
export type CreateScenarioMutationOptions = Apollo.BaseMutationOptions<CreateScenarioMutation, CreateScenarioMutationVariables>;
export const DeleteScenarioDocument = gql`
    mutation DeleteScenario($scenarioId: uuid!) {
  delete_scenarios(where: {scenario_id: {_eq: $scenarioId}}) {
    affected_rows
  }
}
    `;
export type DeleteScenarioMutationFn = Apollo.MutationFunction<DeleteScenarioMutation, DeleteScenarioMutationVariables>;

/**
 * __useDeleteScenarioMutation__
 *
 * To run a mutation, you first call `useDeleteScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScenarioMutation, { data, loading, error }] = useDeleteScenarioMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useDeleteScenarioMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScenarioMutation, DeleteScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScenarioMutation, DeleteScenarioMutationVariables>(DeleteScenarioDocument, options);
      }
export type DeleteScenarioMutationHookResult = ReturnType<typeof useDeleteScenarioMutation>;
export type DeleteScenarioMutationResult = Apollo.MutationResult<DeleteScenarioMutation>;
export type DeleteScenarioMutationOptions = Apollo.BaseMutationOptions<DeleteScenarioMutation, DeleteScenarioMutationVariables>;
export const GetAllProjectsDocument = gql`
    query getAllProjects {
  all_projects {
    eris_capacity_mw
    nris_capacity_mw
    fuel_type
    project_id
    region
    status
    study_cycle
    study_group
    study_phase
  }
}
    `;

/**
 * __useGetAllProjectsQuery__
 *
 * To run a query within a React component, call `useGetAllProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllProjectsQuery, GetAllProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllProjectsQuery, GetAllProjectsQueryVariables>(GetAllProjectsDocument, options);
      }
export function useGetAllProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllProjectsQuery, GetAllProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllProjectsQuery, GetAllProjectsQueryVariables>(GetAllProjectsDocument, options);
        }
export type GetAllProjectsQueryHookResult = ReturnType<typeof useGetAllProjectsQuery>;
export type GetAllProjectsLazyQueryHookResult = ReturnType<typeof useGetAllProjectsLazyQuery>;
export type GetAllProjectsQueryResult = Apollo.QueryResult<GetAllProjectsQuery, GetAllProjectsQueryVariables>;
export const GetInQueueSubscriptionsDocument = gql`
    query getInQueueSubscriptions {
  in_queue_subscriptions {
    subscription_id
    region
    study_cycle
    study_group
    phase
    scenario_limit
  }
}
    `;

/**
 * __useGetInQueueSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetInQueueSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInQueueSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInQueueSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInQueueSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetInQueueSubscriptionsQuery, GetInQueueSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInQueueSubscriptionsQuery, GetInQueueSubscriptionsQueryVariables>(GetInQueueSubscriptionsDocument, options);
      }
export function useGetInQueueSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInQueueSubscriptionsQuery, GetInQueueSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInQueueSubscriptionsQuery, GetInQueueSubscriptionsQueryVariables>(GetInQueueSubscriptionsDocument, options);
        }
export type GetInQueueSubscriptionsQueryHookResult = ReturnType<typeof useGetInQueueSubscriptionsQuery>;
export type GetInQueueSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetInQueueSubscriptionsLazyQuery>;
export type GetInQueueSubscriptionsQueryResult = Apollo.QueryResult<GetInQueueSubscriptionsQuery, GetInQueueSubscriptionsQueryVariables>;
export const GetScenarioMetricsDocument = gql`
    query getScenarioMetrics($projectId: String!, $scenarioIds: [uuid!]) {
  scenarios(where: {scenario_id: {_in: $scenarioIds}}) {
    scenario_id
    scenario_projects_aggregate(where: {project_id: {_eq: $projectId}}) {
      aggregate {
        max {
          sum_allocated_cost
          cost_per_mw
        }
      }
    }
  }
}
    `;

/**
 * __useGetScenarioMetricsQuery__
 *
 * To run a query within a React component, call `useGetScenarioMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioMetricsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      scenarioIds: // value for 'scenarioIds'
 *   },
 * });
 */
export function useGetScenarioMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetScenarioMetricsQuery, GetScenarioMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScenarioMetricsQuery, GetScenarioMetricsQueryVariables>(GetScenarioMetricsDocument, options);
      }
export function useGetScenarioMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScenarioMetricsQuery, GetScenarioMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScenarioMetricsQuery, GetScenarioMetricsQueryVariables>(GetScenarioMetricsDocument, options);
        }
export type GetScenarioMetricsQueryHookResult = ReturnType<typeof useGetScenarioMetricsQuery>;
export type GetScenarioMetricsLazyQueryHookResult = ReturnType<typeof useGetScenarioMetricsLazyQuery>;
export type GetScenarioMetricsQueryResult = Apollo.QueryResult<GetScenarioMetricsQuery, GetScenarioMetricsQueryVariables>;
export const GetScenarioResultsDocument = gql`
    query getScenarioResults($scenarioId: uuid!) {
  scenarios(where: {scenario_id: {_eq: $scenarioId}}) {
    scenario_allocated_upgrades {
      allocated_cost
      constraint_type
      max_dfax
      max_post_study_load
      monitored_facility_names
      sum_mw_impact
      network_upgrade_id
      network_upgrade_name
      pmax
      project_id
      total_cost
      notes
    }
    scenario_constraints {
      constraint_type
      monitored_facility_name
      element_type
      line_length
      voltage
      post_study_flow
      post_study_load
      post_study_load_ac
      post_study_load_dc
      post_study_rating
      pre_study_flow
      pre_study_load
      pre_study_rating
      worst_contingency_name
      network_upgrade_names
      project_id
      dfax
      mw_impact
      notes
    }
    scenario_projects {
      assigned_upgrades
      cost_per_mw
      eris_size_mw
      nris_size_mw
      overloaded_facilities
      project_id
      sum_allocated_cost
      sum_allocated_cost_by_constraint_type
    }
  }
}
    `;

/**
 * __useGetScenarioResultsQuery__
 *
 * To run a query within a React component, call `useGetScenarioResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioResultsQuery({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useGetScenarioResultsQuery(baseOptions: Apollo.QueryHookOptions<GetScenarioResultsQuery, GetScenarioResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScenarioResultsQuery, GetScenarioResultsQueryVariables>(GetScenarioResultsDocument, options);
      }
export function useGetScenarioResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScenarioResultsQuery, GetScenarioResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScenarioResultsQuery, GetScenarioResultsQueryVariables>(GetScenarioResultsDocument, options);
        }
export type GetScenarioResultsQueryHookResult = ReturnType<typeof useGetScenarioResultsQuery>;
export type GetScenarioResultsLazyQueryHookResult = ReturnType<typeof useGetScenarioResultsLazyQuery>;
export type GetScenarioResultsQueryResult = Apollo.QueryResult<GetScenarioResultsQuery, GetScenarioResultsQueryVariables>;
export const RemoveTrackedProjectDocument = gql`
    mutation RemoveTrackedProject($projectId: String!, $subscriptionId: uuid!) {
  delete_tracked_projects_by_pk(
    project_id: $projectId
    subscription_id: $subscriptionId
  ) {
    project_id
    subscription_id
  }
}
    `;
export type RemoveTrackedProjectMutationFn = Apollo.MutationFunction<RemoveTrackedProjectMutation, RemoveTrackedProjectMutationVariables>;

/**
 * __useRemoveTrackedProjectMutation__
 *
 * To run a mutation, you first call `useRemoveTrackedProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTrackedProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTrackedProjectMutation, { data, loading, error }] = useRemoveTrackedProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useRemoveTrackedProjectMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTrackedProjectMutation, RemoveTrackedProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTrackedProjectMutation, RemoveTrackedProjectMutationVariables>(RemoveTrackedProjectDocument, options);
      }
export type RemoveTrackedProjectMutationHookResult = ReturnType<typeof useRemoveTrackedProjectMutation>;
export type RemoveTrackedProjectMutationResult = Apollo.MutationResult<RemoveTrackedProjectMutation>;
export type RemoveTrackedProjectMutationOptions = Apollo.BaseMutationOptions<RemoveTrackedProjectMutation, RemoveTrackedProjectMutationVariables>;
export const SubmitScenarioDocument = gql`
    mutation SubmitScenario($scenarioId: uuid!, $authId: String!, $currentTime: timestamptz!) {
  update_scenarios(
    where: {scenario_id: {_eq: $scenarioId}}
    _set: {status: RUN_REQUESTED, updated_by: $authId, run_submitted_by: $authId, run_submitted_at: $currentTime}
  ) {
    returning {
      scenario_id
      region
      team_id
      created_by
      updated_by
      study_group
      study_cycle
      study_phase
      title
      project_size_assumptions
      status
    }
  }
}
    `;
export type SubmitScenarioMutationFn = Apollo.MutationFunction<SubmitScenarioMutation, SubmitScenarioMutationVariables>;

/**
 * __useSubmitScenarioMutation__
 *
 * To run a mutation, you first call `useSubmitScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitScenarioMutation, { data, loading, error }] = useSubmitScenarioMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *      authId: // value for 'authId'
 *      currentTime: // value for 'currentTime'
 *   },
 * });
 */
export function useSubmitScenarioMutation(baseOptions?: Apollo.MutationHookOptions<SubmitScenarioMutation, SubmitScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitScenarioMutation, SubmitScenarioMutationVariables>(SubmitScenarioDocument, options);
      }
export type SubmitScenarioMutationHookResult = ReturnType<typeof useSubmitScenarioMutation>;
export type SubmitScenarioMutationResult = Apollo.MutationResult<SubmitScenarioMutation>;
export type SubmitScenarioMutationOptions = Apollo.BaseMutationOptions<SubmitScenarioMutation, SubmitScenarioMutationVariables>;
export const SubscribeToInQueueScenariosDocument = gql`
    subscription subscribeToInQueueScenarios {
  scenarios {
    scenario_id
    scenario_type
    scenario_stage
    title
    project_size_assumptions
    cost_assumptions
    region
    study_cycle
    study_group
    study_phase
    status
    input_data_used
    created_at
    created_by
    updated_at
    updated_by
    dagster_run_id
    run_completed_at
    run_started_at
    run_submitted_at
    run_submitted_by
    parent_project_id
    in_queue_subscription {
      tracked_projects {
        project_id
      }
    }
  }
}
    `;

/**
 * __useSubscribeToInQueueScenariosSubscription__
 *
 * To run a query within a React component, call `useSubscribeToInQueueScenariosSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToInQueueScenariosSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToInQueueScenariosSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeToInQueueScenariosSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribeToInQueueScenariosSubscription, SubscribeToInQueueScenariosSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToInQueueScenariosSubscription, SubscribeToInQueueScenariosSubscriptionVariables>(SubscribeToInQueueScenariosDocument, options);
      }
export type SubscribeToInQueueScenariosSubscriptionHookResult = ReturnType<typeof useSubscribeToInQueueScenariosSubscription>;
export type SubscribeToInQueueScenariosSubscriptionResult = Apollo.SubscriptionResult<SubscribeToInQueueScenariosSubscription>;
export const SubscribeToTrackedProjectsDocument = gql`
    subscription subscribeToTrackedProjects {
  tracked_projects {
    subscription_id
    project_id
  }
}
    `;

/**
 * __useSubscribeToTrackedProjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToTrackedProjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToTrackedProjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToTrackedProjectsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeToTrackedProjectsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribeToTrackedProjectsSubscription, SubscribeToTrackedProjectsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToTrackedProjectsSubscription, SubscribeToTrackedProjectsSubscriptionVariables>(SubscribeToTrackedProjectsDocument, options);
      }
export type SubscribeToTrackedProjectsSubscriptionHookResult = ReturnType<typeof useSubscribeToTrackedProjectsSubscription>;
export type SubscribeToTrackedProjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToTrackedProjectsSubscription>;
export const UpdateScenarioDocument = gql`
    mutation UpdateScenario($scenarioId: uuid!, $authId: String!, $title: String!, $status: scenario_statuses_enum!, $projectSizeAssumptions: jsonb!, $parentProjectId: String, $costAssumptions: jsonb!) {
  update_scenarios(
    where: {scenario_id: {_eq: $scenarioId}}
    _set: {title: $title, status: $status, project_size_assumptions: $projectSizeAssumptions, updated_by: $authId, parent_project_id: $parentProjectId, cost_assumptions: $costAssumptions}
  ) {
    affected_rows
    returning {
      scenario_id
      region
      team_id
      created_by
      updated_by
      study_group
      study_cycle
      study_phase
      title
      project_size_assumptions
      status
      cost_assumptions
    }
  }
}
    `;
export type UpdateScenarioMutationFn = Apollo.MutationFunction<UpdateScenarioMutation, UpdateScenarioMutationVariables>;

/**
 * __useUpdateScenarioMutation__
 *
 * To run a mutation, you first call `useUpdateScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenarioMutation, { data, loading, error }] = useUpdateScenarioMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *      authId: // value for 'authId'
 *      title: // value for 'title'
 *      status: // value for 'status'
 *      projectSizeAssumptions: // value for 'projectSizeAssumptions'
 *      parentProjectId: // value for 'parentProjectId'
 *      costAssumptions: // value for 'costAssumptions'
 *   },
 * });
 */
export function useUpdateScenarioMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScenarioMutation, UpdateScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScenarioMutation, UpdateScenarioMutationVariables>(UpdateScenarioDocument, options);
      }
export type UpdateScenarioMutationHookResult = ReturnType<typeof useUpdateScenarioMutation>;
export type UpdateScenarioMutationResult = Apollo.MutationResult<UpdateScenarioMutation>;
export type UpdateScenarioMutationOptions = Apollo.BaseMutationOptions<UpdateScenarioMutation, UpdateScenarioMutationVariables>;
export const UpdateScenarioParentProjectDocument = gql`
    mutation UpdateScenarioParentProject($scenarioId: uuid!, $authId: String!, $parentProjectId: String) {
  update_scenarios(
    where: {scenario_id: {_eq: $scenarioId}}
    _set: {parent_project_id: $parentProjectId, updated_by: $authId}
  ) {
    affected_rows
  }
}
    `;
export type UpdateScenarioParentProjectMutationFn = Apollo.MutationFunction<UpdateScenarioParentProjectMutation, UpdateScenarioParentProjectMutationVariables>;

/**
 * __useUpdateScenarioParentProjectMutation__
 *
 * To run a mutation, you first call `useUpdateScenarioParentProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenarioParentProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenarioParentProjectMutation, { data, loading, error }] = useUpdateScenarioParentProjectMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *      authId: // value for 'authId'
 *      parentProjectId: // value for 'parentProjectId'
 *   },
 * });
 */
export function useUpdateScenarioParentProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScenarioParentProjectMutation, UpdateScenarioParentProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScenarioParentProjectMutation, UpdateScenarioParentProjectMutationVariables>(UpdateScenarioParentProjectDocument, options);
      }
export type UpdateScenarioParentProjectMutationHookResult = ReturnType<typeof useUpdateScenarioParentProjectMutation>;
export type UpdateScenarioParentProjectMutationResult = Apollo.MutationResult<UpdateScenarioParentProjectMutation>;
export type UpdateScenarioParentProjectMutationOptions = Apollo.BaseMutationOptions<UpdateScenarioParentProjectMutation, UpdateScenarioParentProjectMutationVariables>;
export const UpdateScenarioStatusDocument = gql`
    mutation UpdateScenarioStatus($scenarioId: uuid!, $status: scenario_statuses_enum!) {
  update_scenarios(
    where: {scenario_id: {_eq: $scenarioId}}
    _set: {status: $status}
  ) {
    affected_rows
  }
}
    `;
export type UpdateScenarioStatusMutationFn = Apollo.MutationFunction<UpdateScenarioStatusMutation, UpdateScenarioStatusMutationVariables>;

/**
 * __useUpdateScenarioStatusMutation__
 *
 * To run a mutation, you first call `useUpdateScenarioStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenarioStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenarioStatusMutation, { data, loading, error }] = useUpdateScenarioStatusMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateScenarioStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScenarioStatusMutation, UpdateScenarioStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScenarioStatusMutation, UpdateScenarioStatusMutationVariables>(UpdateScenarioStatusDocument, options);
      }
export type UpdateScenarioStatusMutationHookResult = ReturnType<typeof useUpdateScenarioStatusMutation>;
export type UpdateScenarioStatusMutationResult = Apollo.MutationResult<UpdateScenarioStatusMutation>;
export type UpdateScenarioStatusMutationOptions = Apollo.BaseMutationOptions<UpdateScenarioStatusMutation, UpdateScenarioStatusMutationVariables>;
export const UpdateScenarioTitleDocument = gql`
    mutation UpdateScenarioTitle($scenarioId: uuid!, $authId: String!, $title: String!) {
  update_scenarios(
    where: {scenario_id: {_eq: $scenarioId}}
    _set: {title: $title, updated_by: $authId}
  ) {
    affected_rows
  }
}
    `;
export type UpdateScenarioTitleMutationFn = Apollo.MutationFunction<UpdateScenarioTitleMutation, UpdateScenarioTitleMutationVariables>;

/**
 * __useUpdateScenarioTitleMutation__
 *
 * To run a mutation, you first call `useUpdateScenarioTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenarioTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenarioTitleMutation, { data, loading, error }] = useUpdateScenarioTitleMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *      authId: // value for 'authId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateScenarioTitleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScenarioTitleMutation, UpdateScenarioTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScenarioTitleMutation, UpdateScenarioTitleMutationVariables>(UpdateScenarioTitleDocument, options);
      }
export type UpdateScenarioTitleMutationHookResult = ReturnType<typeof useUpdateScenarioTitleMutation>;
export type UpdateScenarioTitleMutationResult = Apollo.MutationResult<UpdateScenarioTitleMutation>;
export type UpdateScenarioTitleMutationOptions = Apollo.BaseMutationOptions<UpdateScenarioTitleMutation, UpdateScenarioTitleMutationVariables>;
export const UnlockBusDocument = gql`
    mutation unlockBus($busId: String!) {
  unlock_bus(args: {bus_id: $busId}) {
    bus_id
  }
}
    `;
export type UnlockBusMutationFn = Apollo.MutationFunction<UnlockBusMutation, UnlockBusMutationVariables>;

/**
 * __useUnlockBusMutation__
 *
 * To run a mutation, you first call `useUnlockBusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockBusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockBusMutation, { data, loading, error }] = useUnlockBusMutation({
 *   variables: {
 *      busId: // value for 'busId'
 *   },
 * });
 */
export function useUnlockBusMutation(baseOptions?: Apollo.MutationHookOptions<UnlockBusMutation, UnlockBusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlockBusMutation, UnlockBusMutationVariables>(UnlockBusDocument, options);
      }
export type UnlockBusMutationHookResult = ReturnType<typeof useUnlockBusMutation>;
export type UnlockBusMutationResult = Apollo.MutationResult<UnlockBusMutation>;
export type UnlockBusMutationOptions = Apollo.BaseMutationOptions<UnlockBusMutation, UnlockBusMutationVariables>;
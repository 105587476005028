import css from "./TableActionRibbon.module.scss";

export interface TableActionRibbonProps {
    leftActions?: React.ReactElement[];
    rightActions?: React.ReactElement[];
}

export const TableActionRibbon: React.FC<TableActionRibbonProps> = ({
    leftActions,
    rightActions
}) => {
    return (
        <div className={css["ribbon"]}>
            <div className={css["actions"]}>{leftActions}</div>
            <div className={css["actions"]}>{rightActions}</div>
        </div>
    );
};

import { useUserDataContext } from "contexts/UserDataContext/UserDataContext";
import { useEffect } from "react";
import { segmentTrackOpenHomePage } from "./analytics";
import { AddTrackedProjectButton } from "./components/AddTrackedProjectButton";
import { ClusterPicker } from "./components/cluster/ClusterPicker";
import { SectionHeader } from "./components/common/SectionHeader";
import { ClusterProjectsTable } from "./components/tables/ClusterProjectsTable";
import { useCluster, useClusterId } from "./contexts/ClusterContext";
import { useTrackedProjectsInCluster } from "./contexts/ProjectDataContext";
import { useSetLastSelectedClusterId } from "./hooks/useLastClusterStorage";
import { getClusterLabel } from "./types/clusterType";

export const ClusterPage: React.FC = () => {
    const clusterId = useClusterId();
    const cluster = useCluster();

    useSetLastSelectedClusterId(clusterId);

    const trackedProjects = useTrackedProjectsInCluster();
    const { teamName } = useUserDataContext();

    useEffect(() => {
        segmentTrackOpenHomePage();
    }, []);

    return (
        <>
            <div>
                <SectionHeader
                    level="h1"
                    title={`Portfolio: ${teamName}`}
                    actions={<ClusterPicker currentCluster={cluster} />}
                />
                <SectionHeader
                    level="h2"
                    title={getClusterLabel(cluster)}
                    actions={<AddTrackedProjectButton />}
                />
            </div>
            <div>
                <ClusterProjectsTable
                    cluster={cluster}
                    projects={trackedProjects}
                    height="auto"
                />
            </div>
        </>
    );
};

import { Alignment, Button, MenuItem } from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";
import { segmentTrackScopeChanged } from "analytics/analyticTrackEvents";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import React from "react";
import {
    regionToScopeConfigurationType,
    ScopeConfigurationType
} from "types/scopeConfigurationType";
import { ScopeType } from "types/scopeType";
import FilterWrapper from "./FilterWrapper";

type ScopeSelectFilterProps = {
    readonly inSidebar: boolean;
};

const ScopeSelectFilter: React.FC<ScopeSelectFilterProps> = (props) => {
    const {
        busFiltersConfiguration: { busFilters, updateScope, filtersDisabled }
    } = useMapViewConfiguration();
    const { scope } = busFilters;
    const { region, generator } = useMapViewRoutingMetadata();

    const scopeConfigs = [...regionToScopeConfigurationType[region][generator]];

    const selectedScopeConfig = getScopeConfigForScope(scope, scopeConfigs);

    return (
        <FilterWrapper
            label="Scope"
            limitInputMaxWidth={props.inSidebar}
            limitLabelMaxWidth={props.inSidebar}
        >
            <Select
                items={scopeConfigs}
                itemRenderer={getSelectOptionItemForConfig}
                onItemSelect={(newScope: ScopeConfigurationType) => {
                    updateScope(newScope.scope);
                    segmentTrackScopeChanged(newScope.scope);
                }}
                activeItem={selectedScopeConfig}
                filterable={false}
                popoverProps={{ minimal: true }}
                fill={true}
            >
                <Button
                    // selectedScopeConfig should never be undefined. If it is, we've set something up wrong.
                    text={selectedScopeConfig?.label ?? "Unknown scope"}
                    rightIcon="caret-down"
                    small={true}
                    fill={true}
                    alignText={Alignment.LEFT}
                    ellipsizeText={true}
                    disabled={filtersDisabled}
                />
            </Select>
        </FilterWrapper>
    );
};

const getScopeConfigForScope = (
    scope: ScopeType,
    scopeConfigs: ScopeConfigurationType[]
): ScopeConfigurationType | undefined => {
    return scopeConfigs.find((scopeConfig) => scopeConfig.scope === scope);
};

const getSelectOptionItemForConfig: ItemRenderer<ScopeConfigurationType> = (
    scopeConfig: ScopeConfigurationType,
    { handleClick, modifiers }
) => (
    <MenuItem
        active={modifiers.active}
        key={scopeConfig.scope}
        text={scopeConfig.label}
        disabled={scopeConfig.disabled}
        onClick={handleClick}
    />
);

export default ScopeSelectFilter;

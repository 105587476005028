import { Dialog, DialogBody } from "@blueprintjs/core";
import { useScreeningViewContext } from "contexts/ScreeningViewContext/ScreeningViewContext";
import ScreeningViewMetadataSection from "./ScreeningViewMetadataSection";

type AboutScreeningViewDialogProps = {
    readonly isOpen: boolean;
    readonly closeDialog: () => void;
};

const AboutScreeningViewDialog: React.FC<AboutScreeningViewDialogProps> = (
    props
) => {
    const { isOpen, closeDialog } = props;
    const maybeScreeningViewData = useScreeningViewContext();

    return (
        <Dialog
            isOpen={isOpen}
            title={"About screening view"}
            onClose={closeDialog}
            icon="info-sign"
        >
            <DialogBody>
                {
                    // MaybeScreeningViewData should always have a value if we're loading this dialog
                    maybeScreeningViewData && (
                        <ScreeningViewMetadataSection
                            screeningView={maybeScreeningViewData}
                            showTitle={true}
                        />
                    )
                }
            </DialogBody>
        </Dialog>
    );
};

export default AboutScreeningViewDialog;

import {
    MapViewConfigurationContext,
    MapViewConfigurationContextProps
} from "contexts/MapViewConfigurationContext/MapViewConfigurationContext";
import { useContext } from "react";

const useMapViewConfiguration = (): MapViewConfigurationContextProps => {
    const context = useContext(MapViewConfigurationContext);
    if (!context) {
        throw new Error(
            "useMapViewConfiguration must be used within a MapViewConfigurationProvider"
        );
    }

    return context;
};

export default useMapViewConfiguration;

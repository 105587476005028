import { Loading } from "types/loadingType";

import { ICellRendererParams } from "ag-grid-community";
import { InQueueCluster } from "in_queue/types/clusterType";
import { REGIONS_TO_COMPONENT_CONFIG } from "in_queue/types/configPerRegion";
import { TrackedProject } from "in_queue/types/projectType";
import { Link, useNavigate } from "react-router-dom";
import { RegionType } from "types/regionType";
import { AgGridColumnDefs } from "./agGrid/agGridHelpers";
import { BaseAgGrid } from "./base/BaseAgGrid";
import css from "./TableStyles.module.scss";

// Haven't been able to find any good typings for cell renderers
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProjectCellRenderer: any = (
    params: ICellRendererParams<TrackedProject>
) => {
    const projectId = params.data?.projectId;
    return (
        <Link to={projectId ?? ""} className={css["link"]}>
            {projectId}
        </Link>
    );
};

const getColumnDefs = (
    region: RegionType
): AgGridColumnDefs<TrackedProject, never> => {
    const config = REGIONS_TO_COMPONENT_CONFIG[region];
    return [
        {
            field: "projectId",
            headerName: "Project",
            pinned: true,
            width: 200,
            sort: "asc",
            cellRenderer: ProjectCellRenderer
        },
        {
            field: "fuelType",
            width: 200
        },
        {
            headerName: "Size (MW)",
            children: [
                {
                    field: "size.erisMw",
                    headerName: config.sizeLabel.energyLabel,
                    width: 120
                },
                {
                    field: "size.nrisMw",
                    headerName: config.sizeLabel.capacityLabel,
                    flex: 1
                }
            ]
        }
    ];
};

export const ClusterProjectsTable: React.FC<{
    cluster: InQueueCluster;
    projects: Loading<TrackedProject[]>;
    height?: "auto" | number;
}> = ({ cluster, projects, height }) => {
    const navigate = useNavigate();
    const columnDefs = getColumnDefs(cluster.region);

    return (
        <BaseAgGrid<TrackedProject>
            loadingData={projects}
            columnDefs={columnDefs}
            height={height}
            rowHeight={30}
            onRowClicked={(event) => navigate(event.data?.projectId ?? "")}
            selectableRows
        />
    );
};

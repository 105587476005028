import { Project, ProjectSize } from "./projectType";
import { MisoPercentageReduction, ScenarioMetadata } from "./scenarioType";

export const getCurrentProjectSize = (
    project: Project,
    scenario: ScenarioMetadata
): ProjectSize => {
    const maybeProjectAssumptions = scenario.assumptions[project.projectId];
    return maybeProjectAssumptions === undefined
        ? project.size
        : reduceProjectSizeByPercentage(project.size, maybeProjectAssumptions);
};

export const reduceProjectSizeByPercentage = (
    projectSize: ProjectSize,
    percentageReduction: MisoPercentageReduction
): ProjectSize => {
    return {
        erisMw: reduceSizeByPercentage(
            projectSize.erisMw,
            percentageReduction.eris
        ),
        nrisMw: reduceSizeByPercentage(
            projectSize.nrisMw,
            percentageReduction.nris
        )
    };
};

const reduceSizeByPercentage = (
    size: number,
    percentageReduction: number
): number => {
    return parseFloat((size - (percentageReduction / 100) * size).toFixed(2));
};

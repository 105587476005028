import {
    Button,
    Menu,
    MenuDivider,
    MenuItem,
    Popover,
    Tooltip
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { IN_QUEUE_PATH } from "infrastructure/RouterApp";
import { useSubscriptions } from "in_queue/contexts/InQueueSubscriptionsContext";
import {
    ClusterId,
    clusterToClusterId,
    getCycleAndGroupLabel,
    getPhaseLabel,
    InQueueCluster,
    StudyCycle,
    StudyGroup,
    StudyPhase
} from "in_queue/types/clusterType";
import {
    IN_QUEUE_REGIONS,
    REGIONS_TO_COMPONENT_CONFIG
} from "in_queue/types/configPerRegion";
import { InQueueSubscription } from "in_queue/types/projectType";
import { isEqual } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "types/loadingType";

export const ClusterMenuItem: React.FC<{
    cluster: InQueueCluster;
    currentCluster: InQueueCluster;
    subscriptions: Loading<InQueueSubscription[]>;
}> = ({ cluster, currentCluster, subscriptions }) => {
    const navigate = useNavigate();
    const navigateToCluster = (clusterId: ClusterId) => {
        navigate(`/${IN_QUEUE_PATH}/${clusterId}`);
    };
    const hasClusterSubscription =
        subscriptions !== "loading" &&
        subscriptions.some((sub) => isEqual(sub.cluster, cluster));
    const isCurrentCluster = isEqual(cluster, currentCluster);

    return (
        <Tooltip
            content="You do not have an active subscription for this decision point."
            position="bottom"
            disabled={hasClusterSubscription}
        >
            <MenuItem
                text={getPhaseLabel(cluster.studyPhase)}
                onClick={() => navigateToCluster(clusterToClusterId(cluster))}
                disabled={!hasClusterSubscription}
                active={isCurrentCluster}
            />
        </Tooltip>
    );
};

export const ClusterPicker: React.FC<{ currentCluster: InQueueCluster }> = ({
    currentCluster
}) => {
    const subscriptions = useSubscriptions();

    const menuItems: JSX.Element[] = [];

    for (const region of IN_QUEUE_REGIONS) {
        menuItems.push(<MenuDivider title={region} />);
        const cycles = REGIONS_TO_COMPONENT_CONFIG[region].availableClusters;
        if (!cycles) {
            continue;
        }
        for (const cycle of Object.keys(cycles) as StudyCycle[]) {
            const groups = cycles[cycle];
            if (!groups) {
                continue;
            }
            for (const group of Object.keys(groups) as StudyGroup[]) {
                const phasesDict = groups[group];
                if (!phasesDict) {
                    continue;
                }
                const phases = Object.keys(phasesDict) as StudyPhase[];
                const label = getCycleAndGroupLabel(region, cycle, group);
                const menuItem = (
                    <MenuItem key={label} text={label}>
                        {phases.map((phase) => {
                            return (
                                <ClusterMenuItem
                                    cluster={{
                                        region: region,
                                        studyCycle: cycle,
                                        studyGroup: group,
                                        studyPhase: phase
                                    }}
                                    currentCluster={currentCluster}
                                    subscriptions={subscriptions}
                                />
                            );
                        })}
                    </MenuItem>
                );
                menuItems.push(menuItem);
            }
        }
    }

    return (
        <Popover content={<Menu>{menuItems}</Menu>} placement="bottom">
            <Button
                icon={IconNames.PROJECTS}
                rightIcon={IconNames.CARET_DOWN}
                text="Select cluster"
            />
        </Popover>
    );
};

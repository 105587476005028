import { ClusterId, isValidClusterId } from "in_queue/types/clusterType";
import { useEffect } from "react";

const LAST_CLUSTER_ID = "lastClusterId";

export const useSetLastSelectedClusterId = (
    clusterId: string | undefined
): void => {
    useEffect(() => {
        if (clusterId) {
            localStorage.setItem(LAST_CLUSTER_ID, clusterId);
        }
    }, [clusterId]);
};

export const useLastSelectedClusterId = (): ClusterId | undefined => {
    const maybeClusterId = localStorage.getItem(LAST_CLUSTER_ID) as ClusterId;
    if (isValidClusterId(maybeClusterId)) {
        return maybeClusterId;
    }
};

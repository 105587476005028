export type Project = {
    readonly projectNumber: string;
    readonly studyGroup: string;
    readonly studyCycle: string;
    readonly erisCapacityMw: number;
    readonly nrisCapacityMw: number;
    readonly fuelType: string;
    readonly included: boolean;
};

type HasuraProject = {
    readonly project_number: string;
    readonly study_group: string;
    readonly study_cycle: string;
    readonly eris_capacity_mw: number;
    readonly nris_capacity_mw: number;
    readonly fuel_type: string;
    readonly included: boolean;
};

export const hasuraProjectsToNiraProjects = (
    hasuraProjects: HasuraProject[]
): ReadonlyArray<Project> => {
    return hasuraProjects.map(hasuraProjectToNiraProject);
};

const hasuraProjectToNiraProject = (hasuraProject: HasuraProject): Project => {
    return {
        projectNumber: hasuraProject.project_number,
        studyGroup: hasuraProject.study_group,
        studyCycle: hasuraProject.study_cycle,
        erisCapacityMw: hasuraProject.eris_capacity_mw,
        nrisCapacityMw: hasuraProject.nris_capacity_mw,
        fuelType: hasuraProject.fuel_type,
        included: hasuraProject.included
    };
};

import "./SearchResultItem.scss";

import React from "react";

export type SearchResult = {
    readonly text: string;
    readonly subText?: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly maybeBoundingBox?: [number, number, number, number];
    readonly id: string;
    readonly isBus?: boolean;
};

type SearchResultItemProps = {
    readonly onSearchResultItemSelected: (searchResult: SearchResult) => void;
    readonly searchResult: SearchResult;
    readonly isLastItem: boolean;
};

const SearchResultItem: React.FC<SearchResultItemProps> = (props) => {
    const { searchResult, onSearchResultItemSelected, isLastItem } = props;
    return (
        <div
            className={`SearchResultItem-wrapper ${
                !isLastItem ? "SearchResultItem-wrapper-not-last" : ""
            }`}
            onMouseDown={() => {
                onSearchResultItemSelected(searchResult);
            }}
        >
            <div className={`SearchResultItem-text`}>
                <b>{searchResult.text}</b>
            </div>
            {searchResult.subText && (
                <div className={`SearchResultItem-sub-text bp5-text-muted`}>
                    {searchResult.subText}
                </div>
            )}
        </div>
    );
};

export default SearchResultItem;

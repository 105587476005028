import "./SearchBar.scss";

import { useLazyQuery } from "@apollo/client";
import { InputGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import mbxGeocoding, {
    GeocodeFeature
} from "@mapbox/mapbox-sdk/services/geocoding";
import { segmentTrackFlyToLocationFromSearch } from "analytics/analyticTrackEvents";
import getEnvVariables from "config/envVariables";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { useScreeningViewContext } from "contexts/ScreeningViewContext/ScreeningViewContext";
import useSelectedBuses from "contexts/SelectedBusesContext/useSelectedBuses";
import {
    GetBusesByNameOrIdQuery,
    GetBusesByNameOrIdQueryVariables
} from "generated/graphql";
import { loader } from "graphql.macro";
import {
    maybeHideLockedBusesFilter,
    maybeScreeningViewStatesFilter
} from "graphql/helpers/busHelpers";
import React, { useEffect, useMemo, useState } from "react";
import {
    ALL_LOCATION_CODES,
    HIGH_CONFIDENCE_LOCATION_CODES
} from "types/busType";
import {
    getFormattedCoordinates,
    maybeGetCoordinates
} from "types/coordinatesAndZoom";
import SearchResultItem, { SearchResult } from "./SearchResultItem";

const MIN_QUERY_LENGTH = 3;
const MAX_RESULTS_TO_SHOW = 8;

const GET_BUSES_BY_NAME_OR_ID = loader(
    "src/graphql/getBusesByNameOrId.graphql"
);

type SearchBarProps = {
    readonly flyToLocation: (searchResult: SearchResult) => void;
};

const SearchBar: React.FC<SearchBarProps> = (props) => {
    const { flyToLocation } = props;
    const { selectSingleBus } = useSelectedBuses();
    const { region: selectedRegion } = useMapViewRoutingMetadata();
    const {
        busFiltersConfiguration: {
            busFilters: {
                voltages,
                scope,
                hideLowConfidenceBuses,
                hideLockedBuses
            }
        }
    } = useMapViewConfiguration();
    const maybeScreeningViewData = useScreeningViewContext();

    const [text, setText] = useState<string>("");
    const [searchResults, setSearchResults] = useState<
        ReadonlyArray<SearchResult>
    >([]);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const geocodingService = useMemo(
        () =>
            mbxGeocoding({
                accessToken: getEnvVariables().mapboxToken
            }),
        []
    );

    const [getBusesByNameOrId] = useLazyQuery<
        GetBusesByNameOrIdQuery,
        GetBusesByNameOrIdQueryVariables
    >(GET_BUSES_BY_NAME_OR_ID);

    useEffect(() => {
        const fetchSearchResults = async () => {
            const coordinates = maybeGetCoordinates(text);
            if (coordinates) {
                setSearchResults([
                    {
                        id: "lat-long",
                        text: getFormattedCoordinates(coordinates),
                        latitude: coordinates.latitude,
                        longitude: coordinates.longitude,
                        maybeBoundingBox: undefined
                    }
                ]);
            } else if (text.length >= MIN_QUERY_LENGTH) {
                // Use Mapbox to do some forward geocoding
                const geocodeResponse = await geocodingService
                    .forwardGeocode({
                        query: text,
                        countries: ["us"],
                        types: [
                            "region",
                            "postcode",
                            "district",
                            "place",
                            "locality",
                            "neighborhood",
                            "address"
                        ]
                    })
                    .send();

                // Also call Hasura to see if query matches any bus names or bus IDs
                const hasuraResponse = await getBusesByNameOrId({
                    variables: {
                        region: selectedRegion,
                        voltages: [...voltages],
                        scope: scope,
                        query: `%${text}%`,
                        locationCodes: hideLowConfidenceBuses
                            ? HIGH_CONFIDENCE_LOCATION_CODES
                            : ALL_LOCATION_CODES,
                        filter_locked_buses_where_clause:
                            maybeHideLockedBusesFilter(hideLockedBuses),
                        filter_states_where_clause:
                            maybeScreeningViewStatesFilter(
                                maybeScreeningViewData
                            )
                    }
                });

                setSearchResults(
                    formatSearchResults({
                        geocodeSearchResults: geocodeResponse.body.features,
                        busSearchResults: hasuraResponse.data
                    })
                );
            }
        };
        fetchSearchResults();
        // This useEffect depends on filter options that change the search results.
        // We want to invalidate the search query if the user toggles any of these
        // options on, so we list them in the dependencies here.
    }, [text, geocodingService, voltages, scope, hideLowConfidenceBuses]);

    return (
        <div className="SearchBar-wrapper">
            <InputGroup
                leftIcon={IconNames.SEARCH}
                placeholder={"Search"}
                className="SearchBar-input"
                type="search"
                value={text}
                onChange={(evt) => setText(evt.currentTarget.value)}
                onFocusCapture={() => setIsFocused(true)}
                onBlurCapture={() => setIsFocused(false)}
            />
            {text.length >= MIN_QUERY_LENGTH &&
                searchResults.length > 0 &&
                isFocused && (
                    <div className="SearchBar-results-wrapper">
                        {searchResults.map((searchResult, index) => (
                            <SearchResultItem
                                searchResult={searchResult}
                                onSearchResultItemSelected={(searchResult) => {
                                    flyToLocation(searchResult);
                                    segmentTrackFlyToLocationFromSearch(
                                        searchResult.id,
                                        searchResult.subText ||
                                            searchResult.text
                                    );

                                    if (searchResult.isBus) {
                                        selectSingleBus(searchResult.id);
                                    }
                                }}
                                key={searchResult.id}
                                isLastItem={index === searchResults.length - 1}
                            />
                        ))}
                    </div>
                )}
        </div>
    );
};

const formatSearchResults = ({
    geocodeSearchResults,
    busSearchResults
}: {
    geocodeSearchResults: ReadonlyArray<GeocodeFeature>;
    busSearchResults?: GetBusesByNameOrIdQuery;
}): ReadonlyArray<SearchResult> => {
    const geocodeSearchResultItems = geocodeSearchResults.map(
        (geocodeFeature) => {
            const maybeBoundingBox = geocodeFeature.bbox;
            let maybeBoundingBoxToReturn = undefined;
            if (maybeBoundingBox !== undefined) {
                if (maybeBoundingBox.length === 4) {
                    maybeBoundingBoxToReturn = maybeBoundingBox as [
                        number,
                        number,
                        number,
                        number
                    ];
                }
            }
            return {
                id: geocodeFeature.id,
                text: geocodeFeature.text,
                subText: geocodeFeature.place_name,
                latitude: geocodeFeature.center[1],
                longitude: geocodeFeature.center[0],
                maybeBoundingBox: maybeBoundingBoxToReturn
            };
        }
    );
    const busSearchResultItems = busSearchResults
        ? busSearchResults.buses.map((bus) => {
              return {
                  id: bus.id,
                  isBus: true,
                  text: bus.bus_display_name,
                  subText: `Bus ID ${bus.id}`,
                  latitude: bus.latitude,
                  longitude: bus.longitude
              };
          })
        : [];

    let results = [...busSearchResultItems, ...geocodeSearchResultItems];
    if (results.length > MAX_RESULTS_TO_SHOW) {
        results = results.slice(0, MAX_RESULTS_TO_SHOW);
    }

    return results;
};

export default SearchBar;

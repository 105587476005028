import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { To, useNavigate } from "react-router-dom";
import css from "./BackButton.module.scss";

export interface BackButtonProps {
    text: string;
    to: To;
}

export const BackButton: React.FC<BackButtonProps> = ({ text, to }) => {
    const navigate = useNavigate();
    const onClick = () => {
        navigate(to);
    };
    return (
        <div className={css["wrapper"]}>
            <Button
                className={css["button"]}
                text={text}
                icon={IconNames.ARROW_LEFT}
                minimal
                onClick={onClick}
            />
        </div>
    );
};

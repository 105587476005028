import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { segmentTrackBusSelectionFromToggle } from "analytics/analyticTrackEvents";
import useSelectedBuses from "contexts/SelectedBusesContext/useSelectedBuses";
import { BusId } from "types/busType";
import "./OtherBusesToggle.scss";

const OtherBusesToggle: React.FC = () => {
    const { selectedBusesAtLocation, updateSelectedBusId } = useSelectedBuses();

    if (
        !selectedBusesAtLocation ||
        selectedBusesAtLocation.busesAtLocation.length <= 1
    ) {
        return null;
    }

    const { busesAtLocation, selectedBusId } = selectedBusesAtLocation;

    const index = busesAtLocation.indexOf(selectedBusId);
    const setSelectedBusWithTracking = (busId: BusId) => {
        segmentTrackBusSelectionFromToggle(busId);
        updateSelectedBusId(busId);
    };
    return (
        <div className="OtherBusesToggle-wrapper">
            <Button
                icon={IconNames.ChevronLeft}
                minimal={true}
                onClick={() =>
                    setSelectedBusWithTracking(busesAtLocation[index - 1])
                }
                disabled={index <= 0}
            />
            <div className="OtherBusesToggle-text">{`${index + 1} of ${
                busesAtLocation.length
            }`}</div>
            <Button
                icon={IconNames.ChevronRight}
                minimal={true}
                onClick={() =>
                    setSelectedBusWithTracking(busesAtLocation[index + 1])
                }
                disabled={index >= busesAtLocation.length - 1}
            />
        </div>
    );
};

export default OtherBusesToggle;

import { useBranchData } from "contexts/MapViewDataContext/hooks/useBranchData";
import { useBusData } from "contexts/MapViewDataContext/hooks/useBusData";
import { MapViewDataContextProps } from "../MapViewDataContext";

const useCreateMapViewData = (): MapViewDataContextProps => {
    const { scopedBuses, isLoading: isLoadingBuses } = useBusData();
    const { branches, isLoading: isLoadingBranches } = useBranchData();

    return {
        scopedBuses,
        isLoadingBuses,
        branches,
        isLoadingBranches
    };
};

export { useCreateMapViewData };

import TextLogo from "logo/textLogo";
import React, { useState } from "react";
import FilterWrapper from "./filters/FilterWrapper";
import "./Sidebar.scss";
import VoltageSelectWrapper from "./VoltageSelectWrapper";

import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { hasMultipleScopeConfigurations } from "types/scopeConfigurationType";
import AllFiltersDialog from "./filters/AllFiltersDialog";
import ScopeSelectFilter from "./filters/ScopeSelectFilter";
import SidebarMenu from "./SidebarMenu";
import SidebarResultsWrapper from "./SidebarResultsWrapper";

const Sidebar: React.FC = () => {
    const { region, generator } = useMapViewRoutingMetadata();

    const [allFiltersDialogOpen, setAllFiltersDialogOpen] = useState(false);

    return (
        <div className="Sidebar-wrapper">
            <div className="Sidebar-logo-wrapper">
                <TextLogo />
                <SidebarMenu />
            </div>
            <div className="Sidebar-filter-wrapper">
                <div className="Sidebar-filter-wrapper-header">
                    <h6 className="bp5-heading">Filters</h6>
                    <div
                        className="Sidebar-show-all-filters bp5-text-small"
                        onClick={() => setAllFiltersDialogOpen(true)}
                    >
                        Show all filters
                    </div>
                </div>
                {hasMultipleScopeConfigurations(region, generator) && (
                    <ScopeSelectFilter inSidebar={true} />
                )}
                <FilterWrapper label="Voltage" limitLabelMaxWidth={true}>
                    <VoltageSelectWrapper />
                </FilterWrapper>
            </div>
            <SidebarResultsWrapper />
            <AllFiltersDialog
                isOpen={allFiltersDialogOpen}
                closeDialog={() => setAllFiltersDialogOpen(false)}
            />
        </div>
    );
};

export default Sidebar;

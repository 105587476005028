import { Navigate } from "react-router-dom";
import { mapLoadingState } from "types/loadingType";
import { useSubscriptions } from "./contexts/InQueueSubscriptionsContext";
import { useLastSelectedClusterId } from "./hooks/useLastClusterStorage";
import { clusterToClusterId } from "./types/clusterType";

export const RedirectToCluster: React.FC = () => {
    const maybeLastSelectedClusterId = useLastSelectedClusterId();
    const subscriptions = useSubscriptions();
    const firstClusterId = mapLoadingState(subscriptions, (subs) =>
        clusterToClusterId(subs[0]?.cluster)
    );

    // Redirect to the last cluster the user had open, or else redirect to the first cluster in the user's subscriptions
    return <Navigate to={maybeLastSelectedClusterId ?? firstClusterId} />;
};

import {
    GetLineTapConstraintsQuery,
    Line_Constraint_Stacks
} from "generated/graphql";
import { convertMaybeToUndefined } from "graphql/helpers/queryHelpers";
import { convertToResourceType, ResourceType } from "./resourceType";
import { convertToScopeType, ScopeType } from "./scopeType";
import { convertToScopeViewType, ScopeViewType } from "./scopeViewType";

type HasuraLineConstraint = Readonly<
    Pick<
        Line_Constraint_Stacks,
        | "tap_facility_id"
        | "injection_threshold"
        | "monitored_facility_name"
        | "resource_type"
        | "rating"
        | "pre_study_load"
        | "element_type"
        | "line_length"
        | "dfax"
        | "monitored_facility_id"
        | "estimated_cost"
        | "scope"
        | "scope_view"
        | "contingency"
    >
>;

export type LineTapConstraint = {
    readonly tapFacilityId: string;
    readonly injectionThreshold: number;
    readonly monitoredFacilityId: string;
    readonly monitoredFacilityName: string;
    readonly resourceType: ResourceType;
    readonly rating: number;
    readonly preStudyLoad: number;
    readonly elementType: string;
    readonly lineLength: number | undefined;
    readonly dfax: number;
    readonly estimatedCost: number | undefined;
    readonly scope: ScopeType;
    readonly scopeView: ScopeViewType;
    readonly contingency: string;
};

export const convertHasuraLineConstraintsToLineTapConstraints = (
    hasuraData: GetLineTapConstraintsQuery
): ReadonlyArray<LineTapConstraint> => {
    return hasuraData.line_constraint_stacks.map(
        convertHasuraLineConstraintToLineTapConstraint
    );
};

const convertHasuraLineConstraintToLineTapConstraint = (
    hasuraLineConstraint: HasuraLineConstraint
): LineTapConstraint => {
    return {
        tapFacilityId: hasuraLineConstraint.tap_facility_id,
        injectionThreshold: hasuraLineConstraint.injection_threshold,
        monitoredFacilityId: hasuraLineConstraint.monitored_facility_id,
        monitoredFacilityName: hasuraLineConstraint.monitored_facility_name,
        resourceType: convertToResourceType(hasuraLineConstraint.resource_type),
        rating: hasuraLineConstraint.rating,
        preStudyLoad: hasuraLineConstraint.pre_study_load,
        elementType: hasuraLineConstraint.element_type,
        lineLength: convertMaybeToUndefined(hasuraLineConstraint.line_length),
        dfax: hasuraLineConstraint.dfax,
        estimatedCost: convertMaybeToUndefined(
            hasuraLineConstraint.estimated_cost
        ),
        scope: convertToScopeType(hasuraLineConstraint.scope),
        scopeView: convertToScopeViewType(hasuraLineConstraint.scope_view),
        contingency: hasuraLineConstraint.contingency
    };
};

import { GetScenarioMetricsQueryVariables } from "generated/graphql";
import { loader } from "graphql.macro";
import { getHasuraDataAndConvertToNiraType } from "graphql/helpers/queryHelpers";
import { Loading } from "types/loadingType";
import { TrackedProject } from "../types/projectType";
import {
    hasuraScenarioMetricsToScenarioMetricsMapping,
    ScenarioMetricsMapping
} from "../types/scenarioMetricsType";
import { ScenarioMetadata } from "../types/scenarioType";

const GET_SCENARIO_METRICS = loader(
    "src/graphql/in_queue/getScenarioMetrics.graphql"
);
export const useScenarioMetricsMapping = (
    project: Loading<TrackedProject | undefined>,
    scenarios: Loading<ScenarioMetadata[]>
): Loading<ScenarioMetricsMapping> => {
    const variables: GetScenarioMetricsQueryVariables = {
        projectId: project !== "loading" ? project?.projectId ?? "" : "",
        scenarioIds:
            scenarios !== "loading"
                ? scenarios.map((scenario) => scenario.scenarioId)
                : []
    };
    return getHasuraDataAndConvertToNiraType(
        GET_SCENARIO_METRICS,
        hasuraScenarioMetricsToScenarioMetricsMapping,
        variables,
        project === "loading" ||
            project === undefined ||
            scenarios === "loading"
    );
};

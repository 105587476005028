import "./MapComponent.scss";

import React, { useEffect, useRef, useState } from "react";
import Map, { MapRef, Popup, ScaleControl } from "react-map-gl";
import getEnvVariables from "../../config/envVariables";
// This is all really gross, follow this Github issue for a fix: https://github.com/visgl/react-map-gl/issues/1266
import {
    segmentTrackBusSelectionFromMap,
    segmentTrackEndedDragging,
    segmentTrackEndedZooming,
    segmentTrackSelectionCanceledFromMap,
    segmentTrackStartedDragging,
    segmentTrackStartedZooming
} from "analytics/analyticTrackEvents";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import useMapViewData from "contexts/MapViewDataContext/hooks/useMapViewData";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { useScreeningViewContext } from "contexts/ScreeningViewContext/ScreeningViewContext";
import useSelectedBuses from "contexts/SelectedBusesContext/useSelectedBuses";
import mapboxgl, { MapLayerMouseEvent } from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
import { useSearchParams } from "react-router-dom";
import { Bus } from "types/busType";
import { getDefaultCoordinatesAndZoomOverrideWithQueryParams } from "types/coordinatesAndZoom";
import { layerStyles } from "types/layerStyleTypes";
import { Voltage } from "types/voltageTypes";
import BranchSource, { BRANCH_LAYER_ID } from "./BranchSource";
import BusSource, { BUS_LAYER_ID } from "./BusSource";
import SearchBar from "./searchBar/SearchBar";
import { SearchResult } from "./searchBar/SearchResultItem";

export const MAP_SPEED = 4.5;
export const MAP_CURVE = 0.9;
export const MAP_ZOOM = 14;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
mapboxgl.workerClass =
    // eslint-disable-next-line import/no-webpack-loader-syntax, @typescript-eslint/no-var-requires
    require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const CURSOR_GRAB = "grab";
const CURSOR_GRABBING = "grabbing";
const CURSOR_POINTER = "pointer";

interface BranchProperties {
    voltage: Voltage;
}

interface HoverInfo {
    voltage: Voltage;
    longitude: number;
    latitude: number;
}

const MapComponent: React.FC = () => {
    const { scopedBuses } = useMapViewData();
    const {
        layerStyleConfiguration: { layerStyleId },
        boundingBoxConfiguration: { updateBoundingBox, updateMapRef }
    } = useMapViewConfiguration();
    const { selectMultipleBuses, clearSelectedBuses } = useSelectedBuses();
    const { region } = useMapViewRoutingMetadata();
    const [queryParams] = useSearchParams();

    const mapRef = useRef<MapRef>(null);
    const currentMapRef = mapRef.current;
    useEffect(() => {
        updateMapRef(currentMapRef);
    }, [currentMapRef]);

    const [cursor, setCursor] = useState<string>(CURSOR_GRAB);
    const [hoverInfo, setHoverInfo] = useState<HoverInfo | null>(null);

    const defaultCoordinatesAndZoom =
        getDefaultCoordinatesAndZoomOverrideWithQueryParams(
            region,
            useScreeningViewContext(),
            queryParams
        );

    const onClick = (event: MapLayerMouseEvent) => {
        const busFeatures = event.features?.filter(
            (feature) => feature.layer.id === BUS_LAYER_ID
        );
        if (busFeatures === undefined || busFeatures.length === 0) {
            clearSelectedBuses();
            segmentTrackSelectionCanceledFromMap();
        } else {
            const busIds = busFeatures
                .map((feature) => feature.properties as Bus)
                .map((bus) => bus.id);
            const firstBusId = busIds[0];
            selectMultipleBuses({
                selectedBusId: firstBusId,
                busIds
            });

            const foundScopedBus = scopedBuses.find(
                (scopedBus) => scopedBus.bus.id === firstBusId
            );
            if (foundScopedBus !== undefined) {
                segmentTrackBusSelectionFromMap(foundScopedBus);
            }
        }
    };

    const onHover = (event: MapLayerMouseEvent) => {
        const branchFeatures = event.features?.filter(
            (feature) => feature.layer.id === BRANCH_LAYER_ID
        );
        const busFeatures = event.features?.filter(
            (feature) => feature.layer.id === BUS_LAYER_ID
        );
        // If we're hovering over a bus or *not* hovering over any branches, clear the hover state
        if (
            branchFeatures === undefined ||
            branchFeatures.length === 0 ||
            (busFeatures !== undefined && busFeatures.length > 0)
        ) {
            setHoverInfo(null);
        } else {
            // Otherwise, get the current lat/long and branch voltage and set it in the state
            const branches = branchFeatures.map(
                (feature) => feature.properties as BranchProperties
            );
            const firstBranch = branches[0];
            setHoverInfo({
                voltage: firstBranch.voltage,
                longitude: event.lngLat.lng,
                latitude: event.lngLat.lat
            });
        }
    };

    return (
        <div className="MapComponent-wrapper">
            <Map
                ref={mapRef}
                initialViewState={defaultCoordinatesAndZoom}
                // Width must come after {...viewport} to overwrite the width in the viewport
                style={{ width: "100%", height: "100%" }}
                mapboxAccessToken={getEnvVariables().mapboxToken}
                mapStyle={layerStyles[layerStyleId].mapboxSource}
                onMoveStart={(e) => {
                    segmentTrackStartedDragging(
                        e.viewState.zoom,
                        e.viewState.latitude,
                        e.viewState.longitude
                    );
                }}
                onMoveEnd={(e) => {
                    updateBoundingBox();
                    segmentTrackEndedDragging(
                        e.viewState.zoom,
                        e.viewState.latitude,
                        e.viewState.longitude
                    );
                }}
                onZoomStart={(e) => {
                    segmentTrackStartedZooming(
                        e.viewState.zoom,
                        e.viewState.latitude,
                        e.viewState.longitude
                    );
                }}
                onZoomEnd={(e) => {
                    segmentTrackEndedZooming(
                        e.viewState.zoom,
                        e.viewState.latitude,
                        e.viewState.longitude
                    );
                }}
                onDragStart={() => setCursor(CURSOR_GRABBING)}
                onDragEnd={() => setCursor(CURSOR_GRAB)}
                interactiveLayerIds={[BUS_LAYER_ID, BRANCH_LAYER_ID]}
                onClick={onClick}
                minZoom={5.5}
                onMouseMove={onHover}
                onMouseEnter={() => setCursor(CURSOR_POINTER)}
                onMouseLeave={() => setCursor(CURSOR_GRAB)}
                cursor={cursor}
                attributionControl={false}
            >
                <BranchSource />
                <BusSource />
                <ScaleControl
                    maxWidth={250}
                    unit="imperial"
                    position="bottom-right"
                    style={{ marginRight: 87 }}
                />
                {hoverInfo && (
                    <Popup
                        longitude={hoverInfo.longitude}
                        latitude={hoverInfo.latitude}
                        offset={[0, -10]}
                        closeButton={false}
                    >
                        Voltage: <b>{hoverInfo.voltage} kV</b>
                    </Popup>
                )}
            </Map>
            {/* We'd prefer if SearchBar could be a component at the MapView level but unfortunately, we need access to currentMapRefs */}
            <div className="MapComponent-searchbar-wrapper">
                <SearchBar
                    flyToLocation={(searchResult: SearchResult) => {
                        const currentMapRef = mapRef.current;
                        if (currentMapRef !== null) {
                            if (searchResult.maybeBoundingBox !== undefined) {
                                currentMapRef.fitBounds(
                                    searchResult.maybeBoundingBox,
                                    {
                                        speed: MAP_SPEED,
                                        curve: MAP_CURVE,
                                        maxZoom: MAP_ZOOM
                                    }
                                );
                            } else {
                                currentMapRef.flyTo({
                                    center: [
                                        searchResult.longitude,
                                        searchResult.latitude
                                    ],
                                    zoom: MAP_ZOOM,
                                    curve: MAP_CURVE,
                                    speed: MAP_SPEED
                                });
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default MapComponent;

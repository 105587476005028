import { segmentTrackMaxAllocatedCostsDropdownChange } from "analytics/analyticTrackEvents";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { MaybeMaxCosts } from "types/busFilterTypes";
import CostsInput from "./CostsInput";
import FilterWrapper from "./FilterWrapper";

const AllocatedCostFilter: React.FC = () => {
    const {
        busFiltersConfiguration: {
            busFilters: { maybeMaxAllocatedCosts },
            updateMaybeMaxAllocatedCosts
        }
    } = useMapViewConfiguration();

    return (
        <FilterWrapper
            label={"Allocated cost (millions)"}
            helperText={
                <div>
                    <b>
                        Nira's cost allocations should only be used as a rough
                        guideline.
                    </b>
                    <br />
                    <br />
                    These estimates were built by assessing the allocations that
                    projects from prior clusters received and the correlation
                    between their allocation and impact on a constraint. Your
                    actual allocation can vary significantly and will be highly
                    dependent on what other projects enter the current cluster.
                </div>
            }
        >
            <CostsInput
                maxCost={maybeMaxAllocatedCosts}
                setMaxCost={(newMaybeMaxAllocatedCosts: MaybeMaxCosts) => {
                    updateMaybeMaxAllocatedCosts(newMaybeMaxAllocatedCosts);
                    segmentTrackMaxAllocatedCostsDropdownChange(
                        newMaybeMaxAllocatedCosts,
                        maybeMaxAllocatedCosts
                    );
                }}
            />
        </FilterWrapper>
    );
};

export default AllocatedCostFilter;

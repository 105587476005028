import {
    useCreateScenarioMutation,
    useUpdateScenarioMutation
} from "generated/graphql";
import { ProjectSize, TrackedProject } from "in_queue/types/projectType";
import {
    CostAssumption,
    CostAssumptionType,
    MisoPercentageReduction,
    ProjectSizeAdjustmentType,
    ScenarioMetadata,
    ScenarioStage,
    ScenarioStatus
} from "in_queue/types/scenarioType";
import { reduceProjectSizeByPercentage } from "in_queue/types/scenarioUtils";
import { Loading } from "types/loadingType";
import { User } from "types/userType";

export const getProjectSize = (
    project: Loading<TrackedProject | undefined>,
    projectSizeAssumption: MisoPercentageReduction | undefined
): ProjectSize | undefined => {
    if (project === undefined || project === "loading") {
        return undefined;
    }
    const defaultSize = project.size;
    const assumption = projectSizeAssumption || {
        type: ProjectSizeAdjustmentType.PERCENTAGE_REDUCTION,
        eris: 0,
        nris: 0
    };
    if (assumption.eris !== 0 || assumption.nris !== 0) {
        return reduceProjectSizeByPercentage(defaultSize, assumption);
    }
    return defaultSize;
};

export const getScenarioDialogConfigFromExistingScenario = ({
    projectId,
    scenario,
    action,
    scenarioStage
}: {
    projectId?: string;
    scenario?: ScenarioMetadata;
    action: "create" | "update";
    scenarioStage: ScenarioStage;
}): {
    title: string;
    scenarioStage: ScenarioStage;
    costAssumptions: CostAssumption;
    projectSizeAssumptions: Record<string, MisoPercentageReduction>;
} => {
    const useExistingScenario = projectId && scenario;

    if (!projectId || !useExistingScenario) {
        return {
            title: "",
            projectSizeAssumptions: {},
            scenarioStage,
            costAssumptions: {
                type: CostAssumptionType.CONSERVATIVE
            }
        };
    }

    const projectSizeAssumptions = { ...scenario.assumptions };
    const title =
        action === "create" ? `${scenario.title} copy` : `${scenario.title}`;

    return {
        title,
        projectSizeAssumptions,
        scenarioStage: scenario.stage,
        costAssumptions: scenario.costAssumptions
    };
};

interface CreateOrUpdateScenarioResponse {
    scenario_id: string;
    title: string;
}

interface UpdateScenarioProps {
    scenarioId: string;
    auth0Id: string;
    title: string;
    runOnSubmit: boolean;
    projectSizeAssumptions: Record<string, MisoPercentageReduction>;
    parentProjectId?: string;
    costAssumptions: CostAssumption;
}

export const useUpdateScenario: () => (
    props: UpdateScenarioProps
) => Promise<CreateOrUpdateScenarioResponse | undefined> = () => {
    const [updateScenarioMutation] = useUpdateScenarioMutation();

    const updateScenario = async ({
        scenarioId,
        auth0Id,
        title,
        runOnSubmit,
        projectSizeAssumptions,
        parentProjectId,
        costAssumptions
    }: UpdateScenarioProps) => {
        const response = await updateScenarioMutation({
            variables: {
                scenarioId: scenarioId,
                authId: auth0Id,
                title,
                status: runOnSubmit
                    ? ScenarioStatus.RunRequested
                    : ScenarioStatus.Draft,
                projectSizeAssumptions,
                parentProjectId,
                costAssumptions
            }
        });
        if (
            response.data &&
            response.data.update_scenarios &&
            response.data.update_scenarios.affected_rows === 0
        ) {
            throw new Error("Unable to update scenario");
        }
        return response.data?.update_scenarios?.returning[0];
    };

    return updateScenario;
};

interface CreateScenarioProps {
    project: TrackedProject;
    userData: User;
    title: string;
    runOnSubmit: boolean;
    projectSizeAssumptions: Record<string, MisoPercentageReduction>;
    scenarioStage: ScenarioStage;
    parentProjectId?: string;
    costAssumptions: CostAssumption;
}

export const useCreateScenario: () => (
    props: CreateScenarioProps
) => Promise<CreateOrUpdateScenarioResponse | undefined> = () => {
    const [createScenarioMutation] = useCreateScenarioMutation();

    const createScenario = async ({
        project,
        userData,
        title,
        runOnSubmit,
        projectSizeAssumptions,
        scenarioStage,
        parentProjectId,
        costAssumptions
    }: CreateScenarioProps) => {
        const response = await createScenarioMutation({
            variables: {
                subscriptionId: project.subscriptionId,
                teamId: userData.teamId,
                authId: userData.auth0Id,
                region: project.cluster.region,
                studyGroup: project.cluster.studyGroup,
                studyCycle: project.cluster.studyCycle,
                studyPhase: project.cluster.studyPhase,
                scenarioStage,
                title: title || "New scenario",
                projectSizeAssumptions,
                status: runOnSubmit
                    ? ScenarioStatus.RunRequested
                    : ScenarioStatus.Draft,
                parentProjectId,
                costAssumptions
            }
        });
        return response.data?.insert_scenarios?.returning[0];
    };

    return createScenario;
};

export type Loading<T> = T | "loading";

export const mapLoadingState = <T, R>(
    item: Loading<T>,
    mapper: (item: T) => R
): Loading<R> => {
    if (item === "loading") {
        return "loading";
    }
    return mapper(item);
};

export const mapLoadingStateWithFallback = <T, R>(
    item: Loading<T>,
    mapper: (item: T) => R,
    fallback: R
): R => {
    const result = mapLoadingState(item, mapper);
    if (result === "loading") {
        return fallback;
    }
    return result;
};

export const zipLoadingStates = <A, B, C>(
    item1: Loading<A>,
    item2: Loading<B>,
    mapper: (item1: A, item2: B) => C
): Loading<C> => {
    if (item1 === "loading" || item2 === "loading") {
        return "loading";
    }
    return mapper(item1, item2);
};

import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { useUserDataContext } from "contexts/UserDataContext/UserDataContext";

export const useIsUnlimitedSubscription = (): boolean => {
    const { region } = useMapViewRoutingMetadata();
    const user = useUserDataContext();

    const regionSubscriptions = user.subscriptions.filter(
        (subscription) => subscription.region == region
    );

    // we should *always* have exactly 1 item in regionSubscriptions... we just add a length
    // check here to ensure we don't screw up
    return (
        (regionSubscriptions.length > 0 &&
            regionSubscriptions.at(0)?.unlimited) ??
        false
    );
};

import { Intent, Position, Toaster } from "@blueprintjs/core";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import useSelectedBuses from "contexts/SelectedBusesContext/useSelectedBuses";
import { SelectedScopedBusProvider } from "contexts/SelectedScopedBusContext/SelectedScopedBusContext";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { queryParamsHaveUnknownOrMismatchedScopeAndRegion } from "types/queryParams";
import PermissionedBusDetailPane from "./detailPane/BusDetailPane/PermissionedBusDetailPane";
import LineTapPane from "./detailPane/LineTapPane/LineTapPane";
import LayerStyleSelector from "./layerStyleSelector/LayerStyleSelector";
import MapComponent from "./map/MapComponent";
import "./MapView.scss";
import Sidebar from "./sidebar/Sidebar";

const MapView: React.FC = () => {
    const { selectedBusesAtLocation, selectedFacility } = useSelectedBuses();

    let pane = null;
    if (selectedBusesAtLocation) {
        pane = (
            <SelectedScopedBusProvider>
                <PermissionedBusDetailPane />
            </SelectedScopedBusProvider>
        );
    } else if (selectedFacility) {
        pane = <LineTapPane />;
    }

    useShowMismatchedScopeToast();

    return (
        <div className="MapView">
            <div className="MapView-sidebar-wrapper">
                <Sidebar />
            </div>
            <div className="MapView-map-wrapper">
                <MapComponent />
                <div className="MapView-detail-pane-wrapper">{pane}</div>
                <div className="MapView-layer-selector-wrapper">
                    <LayerStyleSelector />
                </div>
            </div>
        </div>
    );
};

const MismatchedScopeToaster = Toaster.create({
    position: Position.TOP
});

const useShowMismatchedScopeToast = () => {
    const [queryParams] = useSearchParams();
    const { region: selectedRegion } = useMapViewRoutingMetadata();

    useEffect(() => {
        if (
            queryParamsHaveUnknownOrMismatchedScopeAndRegion(
                selectedRegion,
                queryParams
            )
        ) {
            MismatchedScopeToaster.show({
                intent: Intent.DANGER,
                message:
                    "Found unknown scope in URL. It's likely you are using an outdated URL. Loading results with default scope",
                timeout: -1
            });
        }
    }, [queryParams, selectedRegion]);
};

export default MapView;

import AvailableTag from "components/common/AvailableTag";
import { LabelStyleType } from "components/common/labels";
import React from "react";
import { ScopedBus } from "types/busType";
import { ENERGY_RESOURCE_TYPE } from "types/resourceType";

interface FirstTagProps {
    scopedBus: ScopedBus;
    isLoading: boolean;
    labelStyle: LabelStyleType;
}

const FirstTag: React.FC<FirstTagProps> = ({
    scopedBus,
    isLoading,
    labelStyle
}: FirstTagProps) => {
    return (
        <div className={`BusItem-tag ${isLoading ? "bp5-skeleton" : ""}`}>
            <AvailableTag
                size={scopedBus.scopedCapacityEnergyCost.energySize}
                resourceType={ENERGY_RESOURCE_TYPE}
                labelStyle={labelStyle}
            />
        </div>
    );
};

export default FirstTag;

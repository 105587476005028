import React from "react";
import "./FiltersDialogSection.scss";

type FiltersDialogSectionProps = {
    readonly header: string;
};

const FiltersDialogSection: React.FC<FiltersDialogSectionProps> = (props) => {
    const { header, children } = props;
    return (
        <div className={`FiltersDialogSection-wrapper`}>
            <h6 className="bp5-heading FiltersDialogSection-heading">
                {header}
            </h6>
            <div>{children}</div>
        </div>
    );
};

export default FiltersDialogSection;

import {
    useScenariosInCluster,
    useSubscriptionForCluster
} from "in_queue/contexts/ProjectDataContext";

// This hook must be rendered within the ProjectDataContext
export const useIsAtScenarioLimit = (): boolean => {
    const subscription = useSubscriptionForCluster();
    const scenariosInCluster = useScenariosInCluster();

    if (!subscription || subscription === "loading") {
        return false;
    }

    return scenariosInCluster.length >= subscription.scenarioLimit;
};

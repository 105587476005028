import {
    ColGroupDef,
    ICellRendererParams,
    ValueFormatterFunc,
    ValueGetterFunc
} from "ag-grid-community";
import { useRegionConfig } from "in_queue/contexts/ClusterContext";
import { InQueueRegionConfig } from "in_queue/types/configPerRegion";
import { TrackedProject } from "in_queue/types/projectType";
import {
    ScenarioMetrics,
    ScenarioMetricsMapping
} from "in_queue/types/scenarioMetricsType";
import { ScenarioMetadata, ScenarioStatus } from "in_queue/types/scenarioType";
import { reduceProjectSizeByPercentage } from "in_queue/types/scenarioUtils";
import { formatRelativeDate } from "in_queue/utils/formatting";
import { isEmpty } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { Loading } from "types/loadingType";
import {
    AgGridColumnDefs,
    currencyFormatter,
    hideUnwantedColumns,
    NiraColDef,
    roundingFormatter,
    ScenariosTableColumnId,
    stringFormatter,
    titleCaseStringFormatter
} from "./agGrid/agGridHelpers";
import { BaseAgGrid } from "./base/BaseAgGrid";
import css from "./TableStyles.module.scss";

// Haven't been able to find any good typings for cell renderers
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ScenarioCellRenderer: any = (
    params: ICellRendererParams<ScenarioMetadata>
) => {
    const scenarioId = params.data?.scenarioId;
    return (
        <Link to={`scenario/${scenarioId}` ?? ""} className={css["link"]}>
            {params.data?.title}
        </Link>
    );
};

const NoneCell = () => <span className={css["muted-cell"]}>None</span>;

// Haven't been able to find any good typings for cell renderers
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SensitivitiesRenderer: any = (
    params: ICellRendererParams<ScenarioMetadata>
) => {
    const scenario = params.data;
    if (!scenario) {
        return <NoneCell />;
    }

    const sensitivityCount = Object.keys(params.value).length;
    if (sensitivityCount === 0) {
        return <NoneCell />;
    }

    return <span>{sensitivityCount}</span>;
};

const statusFormatter: ValueFormatterFunc<ScenarioMetadata, string> = (
    params
) => {
    const scenario = params.data;
    if (!scenario) {
        return "-";
    }

    if (scenario.status === ScenarioStatus.Draft) {
        return "Draft";
    } else if (
        scenario.status === ScenarioStatus.RunRequested ||
        scenario.status === ScenarioStatus.RunSubmitted ||
        scenario.status === ScenarioStatus.Running
    ) {
        return "Running";
    } else if (scenario.status === ScenarioStatus.RunSucceeded) {
        return "Done";
    } else if (scenario.status === ScenarioStatus.RunFailed) {
        return "Failed";
    } else if (scenario.status === ScenarioStatus.Archived) {
        return "Archived";
    }

    return "-";
};

const timingFormatter: ValueFormatterFunc<ScenarioMetadata, string> = (
    params
) => {
    const scenario = params.data;
    if (!scenario) {
        return "-";
    }

    const timing = scenario.timeMetadata;

    if (scenario.status === ScenarioStatus.Draft && timing.createdAt) {
        return `Created ${formatRelativeDate(timing.createdAt)}`;
    } else if (
        scenario.status === ScenarioStatus.RunFailed &&
        timing.runStartedAt
    ) {
        return `Started ${formatRelativeDate(timing.runStartedAt)}`;
    } else if (
        (scenario.status === ScenarioStatus.RunSucceeded ||
            scenario.status === ScenarioStatus.Archived) &&
        timing.runCompletedAt
    ) {
        return `Ran ${formatRelativeDate(timing.runCompletedAt)}`;
    } else if (
        scenario.status === ScenarioStatus.RunFailed &&
        timing.runCompletedAt
    ) {
        return `Failed ${formatRelativeDate(timing.runCompletedAt)}`;
    }

    return "-";
};

const getColumnDefs = (
    project: Loading<TrackedProject | undefined>,
    scenarioMetrics: Loading<ScenarioMetricsMapping>,
    config: InQueueRegionConfig
): AgGridColumnDefs<ScenarioMetadata, ScenariosTableColumnId> => {
    const columnDefs: AgGridColumnDefs<
        ScenarioMetadata,
        ScenariosTableColumnId
    > = [
        {
            colId: "title",
            field: "title",
            headerName: "Scenario",
            width: 240,
            cellRenderer: ScenarioCellRenderer
        },
        getMetricsColumns(scenarioMetrics),
        {
            headerName: "Assumptions",
            children: [
                ...getProjectSizeColumns(project, config),
                {
                    colId: "sensitivities",
                    field: "assumptions",
                    headerName: "Sensitivities",
                    width: 80,
                    valueFormatter: (params) =>
                        isEmpty(params.value) ? "None" : params.value,
                    cellRenderer: SensitivitiesRenderer
                }
            ]
        },
        {
            headerName: "Scenario run",
            children: [
                {
                    colId: "runStatus",
                    field: "status",
                    headerName: "Status",
                    width: 75,
                    valueFormatter: statusFormatter,
                    enableCellChangeFlash: true
                },
                {
                    colId: "timing",
                    field: "timeMetadata.latestDate",
                    headerName: "Timing",
                    valueFormatter: timingFormatter,
                    minWidth: 120,
                    flex: 1
                }
            ]
        },
        {
            colId: "inputData",
            headerName: "Input data",
            field: "inputDataUsed.concise",
            width: 160,
            valueFormatter: stringFormatter
        },
        {
            colId: "costAssumptions",
            headerName: "Cost assumptions",
            field: "costAssumptions.type",
            valueFormatter: titleCaseStringFormatter,
            width: 140
        }
    ];

    hideUnwantedColumns(columnDefs, config.scenariosTable.columnsToRemove);

    return columnDefs;
};

const getProjectSizeColumns = (
    project: Loading<TrackedProject | undefined>,
    config: InQueueRegionConfig
): NiraColDef<ScenarioMetadata, ScenariosTableColumnId>[] => {
    const getModifiedSize = (data: ScenarioMetadata | undefined) => {
        if (project === "loading" || project === undefined || !data) {
            return undefined;
        }
        if (!(project.projectId in data.assumptions)) {
            return project.size;
        }
        return reduceProjectSizeByPercentage(
            project.size,
            data.assumptions[project.projectId]
        );
    };
    return [
        {
            colId: "energyMw",
            headerName: config.sizeLabel.energyLabel,
            width: 80,
            valueGetter: ({ data }) => getModifiedSize(data)?.erisMw ?? "-",
            valueFormatter: roundingFormatter
        },
        {
            colId: "capacityMw",
            headerName: config.sizeLabel.capacityLabel,
            width: 80,
            valueGetter: ({ data }) => getModifiedSize(data)?.nrisMw ?? "-",
            valueFormatter: roundingFormatter
        }
    ];
};

const getMetricsColumns = (
    scenarioMetrics: Loading<ScenarioMetricsMapping>
): ColGroupDef<ScenarioMetadata> => {
    const createMetricsValueGetter = (
        field: keyof ScenarioMetrics
    ): ValueGetterFunc<ScenarioMetadata> => {
        return ({ data }) => {
            if (scenarioMetrics === "loading" || !data?.scenarioId) {
                return undefined;
            }
            return scenarioMetrics[data.scenarioId]?.[field];
        };
    };
    return {
        headerName: "Results",
        children: [
            {
                colId: "cost",
                headerName: "Cost",
                width: 90,
                valueFormatter: currencyFormatter,
                valueGetter: createMetricsValueGetter("sumAllocatedCost")
            },
            {
                colId: "costPerMw",
                headerName: "$ / MW",
                width: 90,
                valueFormatter: currencyFormatter,
                valueGetter: createMetricsValueGetter("costPerMw")
            }
        ]
    };
};

export const ScenariosTable: React.FC<{
    project: Loading<TrackedProject | undefined>;
    scenarios: Loading<ScenarioMetadata[]>;
    scenarioMetrics: Loading<ScenarioMetricsMapping>;
    height?: "auto" | number;
}> = ({ project, scenarios, scenarioMetrics, height }) => {
    const navigate = useNavigate();
    const config = useRegionConfig();

    return (
        <BaseAgGrid<ScenarioMetadata>
            loadingData={scenarios}
            columnDefs={getColumnDefs(project, scenarioMetrics, config)}
            height={height}
            onRowClicked={(event) => {
                const scenarioId = event.data?.scenarioId;
                if (scenarioId) {
                    navigate(`scenario/${scenarioId}`);
                }
            }}
            rowHeight={30}
            selectableRows
        />
    );
};

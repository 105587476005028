import { useCurrentProjectId } from "in_queue/contexts/ScenarioPageContext";
import { useScenarioResults } from "in_queue/contexts/ScenarioResultsContext";
import { formatCosts } from "in_queue/utils/formatting";
import { useMemo } from "react";
import { Loading, mapLoadingState } from "types/loadingType";
import { MetricCard, MetricCardRow } from "../common/MetricCard";

export const ScenarioMetrics: React.FC = () => {
    const projectId = useCurrentProjectId();
    const metrics = useScenarioMetrics(projectId);

    return (
        <MetricCardRow>
            <MetricCard
                label="Sum of allocated costs"
                value={mapLoadingState(metrics, (m) => m.sumAllocatedCosts)}
            />
            <MetricCard
                label="Num. upgrades assigned"
                value={mapLoadingState(metrics, (m) => m.numUpgrades)}
            />
            <MetricCard
                label="$ / MW"
                value={mapLoadingState(metrics, (m) => m.dollarsPerMw)}
            />
        </MetricCardRow>
    );
};

interface ScenarioMetrics {
    sumAllocatedCosts: string;
    numUpgrades: string;
    dollarsPerMw: string;
}
const useScenarioMetrics = (
    currentProjectId: string
): Loading<ScenarioMetrics> => {
    const scenarioResults = useScenarioResults();

    return useMemo(() => {
        return mapLoadingState(scenarioResults, (details) => {
            const currentProjectResults = details.projects.find(
                (project) => project.projectId === currentProjectId
            );
            if (currentProjectResults === undefined) {
                // TODO handle this better
                return {
                    sumAllocatedCosts: "-",
                    numUpgrades: "-",
                    dollarsPerMw: "-"
                };
            }
            return {
                sumAllocatedCosts: formatCosts(
                    currentProjectResults.sumAllocatedCost
                ),
                numUpgrades:
                    currentProjectResults.assignedUpgrades.length.toFixed(0),
                dollarsPerMw: formatCosts(currentProjectResults.costPerMw)
            };
        });
    }, [scenarioResults, currentProjectId]);
};

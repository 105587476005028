import { gql, MutationHookOptions, useMutation } from "@apollo/client";
import { segmentTrackUnlockBusAttempt } from "analytics/analyticTrackEvents";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import useSelectedScopedBus from "contexts/SelectedScopedBusContext/useSelectedScopedBus";
import {
    UnlockBusMutation,
    UnlockBusMutationVariables
} from "generated/graphql";
import { loader } from "graphql.macro";
import { useEffect, useRef } from "react";
import { BusId } from "types/busType";
import {
    isCurtailmentConfig,
    isPricedConstraintConfig
} from "types/componentConfigPerRegion";
import ConstraintsBasedBusDetailPane from "./ConstraintsBasedBusDetailPane";
import { default as CurtailmentBusDetailPane } from "./CurtailmentBusDetailPane";
import UnauthorizedDetailPane from "./UnauthorizedBusDetailPane";

const UNLOCK_BUS = loader("src/graphql/unlockBus.graphql");
const UNLOCKED_FIELDS_FRAGMENT = gql`
    fragment UnlockedBus on buses {
        unlocked {
            unlocked
        }
    }
`;
const UNLOCKED_DATA = {
    unlocked: [
        {
            unlocked: true
        }
    ]
};

const PermissionedBusDetailPane: React.FC = () => {
    const { componentConfig } = useMapViewRoutingMetadata();
    const scopedBus = useSelectedScopedBus();

    const lastBusIdAttempedToUnlock = useRef<BusId | null>(null);

    const unlockBusOptions: MutationHookOptions<
        UnlockBusMutation,
        UnlockBusMutationVariables
    > = {
        // whenever the unlockBus mutation is called, manually update the unlocked field of the locally cached bus
        // without this, we would need to refetch the bus from the network every time the line tap pane is loaded to get the updated unlocked field
        update: (cache, { data }) => {
            if (data?.unlock_bus == null) {
                return;
            }

            const busCacheId = `buses:${data.unlock_bus.bus_id}`;
            cache.writeFragment({
                id: busCacheId,
                fragment: UNLOCKED_FIELDS_FRAGMENT,
                data: UNLOCKED_DATA
            });
        }
    };
    const [unlockBusMutation, { data, loading }] = useMutation<
        UnlockBusMutation,
        UnlockBusMutationVariables
    >(UNLOCK_BUS, unlockBusOptions);

    useEffect(() => {
        const busId = scopedBus.bus.id;
        lastBusIdAttempedToUnlock.current = busId;
        unlockBusMutation({
            variables: { busId },
            onCompleted: (unlockBusMutation) => {
                segmentTrackUnlockBusAttempt(
                    busId,
                    busUnlocked(unlockBusMutation)
                );
            }
        });
    }, [scopedBus.bus.id, unlockBusMutation]);

    let unlockLoading = false;

    if (
        loading ||
        data === undefined ||
        data === null ||
        // If the bus_id the last time we unlocked a bus is different from the bus id we are trying
        // to render, then the mutation for the new bus hasn't been called yet.
        // In that case, we just render this as "loading" since we are sure that the
        // mutation will re-execute due to the useEffect above.
        lastBusIdAttempedToUnlock.current !== scopedBus.bus.id
    ) {
        // unlockBusMutation is loading, so don't render anything quite yet
        unlockLoading = true;
    } else if (busUnlocked(data)) {
        return <UnauthorizedDetailPane scopedBus={scopedBus} />;
    }

    if (isPricedConstraintConfig(componentConfig)) {
        return (
            <ConstraintsBasedBusDetailPane unlockBusLoading={unlockLoading} />
        );
    } else if (isCurtailmentConfig(componentConfig)) {
        return <CurtailmentBusDetailPane unlockBusLoading={unlockLoading} />;
    } else {
        return null;
    }
};

const busUnlocked = (unlockBusResponse: UnlockBusMutation): boolean => {
    return unlockBusResponse.unlock_bus == null;
};

export default PermissionedBusDetailPane;

import { Button, Dialog, DialogBody } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { segmentTrackMoreDetailsConstraintsDialogClosed } from "analytics/analyticTrackEvents";
import React from "react";
import { BusId } from "types/busType";
import { FacilityId } from "types/selectedFacility";
import "./ConstraintDetailsDialog.scss";

type ConstraintDetailsDialogProps = {
    readonly isOpen: boolean;
    readonly closeDialog: () => void;
    readonly exportConstraints: () => void;
    readonly title: string;
    readonly selectedId: BusId | FacilityId;
};

const ConstraintDetailsDialog: React.FC<ConstraintDetailsDialogProps> = (
    props
) => {
    const { isOpen, closeDialog, exportConstraints, title, selectedId } = props;

    const titleElement = (
        <div className="ConstraintDetailsDialog-details-dialog-title">
            {title}
            <Button
                small
                minimal
                icon={IconNames.DOWNLOAD}
                onClick={exportConstraints}
            >
                Download CSV
            </Button>
        </div>
    );

    return (
        <Dialog
            isOpen={isOpen}
            title={titleElement}
            onClose={() => {
                segmentTrackMoreDetailsConstraintsDialogClosed(selectedId);
                closeDialog();
            }}
            icon="build"
            className="ConstraintDetailsDialog-details-dialog"
        >
            <DialogBody className="ConstraintDetailsDialog-body">
                {props.children}
            </DialogBody>
        </Dialog>
    );
};

export default ConstraintDetailsDialog;

import { Tooltip } from "@blueprintjs/core";
import React from "react";
import "./Tooltip.scss";

export const ScenarioLimitTooltip: React.FC<{ isAtScenarioLimit: boolean }> = ({
    isAtScenarioLimit,
    children
}) => {
    return (
        <Tooltip
            content="You have reached the scenario limit for this subscription."
            position="bottom"
            className="tooltip"
            disabled={!isAtScenarioLimit}
        >
            {children}
        </Tooltip>
    );
};

import { Intent, MenuItem, Position, Toaster } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemRenderer, MultiSelect } from "@blueprintjs/select";
import { segmentTrackVoltageDropdownChange } from "analytics/analyticTrackEvents";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { useScreeningViewContext } from "contexts/ScreeningViewContext/ScreeningViewContext";
import React from "react";
import { KV_69, REGIONS_TO_VOLTAGES, Voltage } from "types/voltageTypes";
import "./VoltageSelectWrapper.scss";

const BranchesNotShownToaster = Toaster.create({
    position: Position.TOP
});

const VoltageSelectWrapper: React.FC = () => {
    const {
        busFiltersConfiguration: {
            busFilters,
            selectVoltage,
            deselectVoltage,
            clearVoltages
        }
    } = useMapViewConfiguration();
    const { voltages } = busFilters;
    const { region } = useMapViewRoutingMetadata();
    const maybeScreeningViewData = useScreeningViewContext();

    const voltageRenderer: ItemRenderer<Voltage> = (
        voltage,
        { handleClick, modifiers }
    ) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                key={voltage}
                text={getVoltageLabel(voltage)}
                onClick={handleClick}
                role="listoption"
                icon={
                    voltages.includes(voltage)
                        ? IconNames.SMALL_TICK
                        : IconNames.BLANK
                }
                disabled={
                    maybeScreeningViewData &&
                    !maybeScreeningViewData.voltages.includes(voltage)
                }
            />
        );
    };

    return (
        <MultiSelect
            className="VoltageSelectWrapper-select"
            popoverProps={{ minimal: true }}
            items={[...REGIONS_TO_VOLTAGES[region]]}
            selectedItems={[...voltages]}
            fill={true}
            onClear={clearVoltages}
            tagRenderer={(voltage) => {
                return `${voltage} kV`;
            }}
            itemRenderer={voltageRenderer}
            onItemSelect={(voltage: Voltage) => {
                if (voltages.includes(voltage)) {
                    deselectVoltage(voltage);
                } else {
                    if (voltage === KV_69) {
                        BranchesNotShownToaster.show({
                            message: `${voltage} kV transmission line data not available`,
                            intent: Intent.WARNING
                        });
                    }
                    selectVoltage(voltage);
                    segmentTrackVoltageDropdownChange(
                        Array.from(new Set([...voltages, voltage])),
                        busFilters
                    );
                }
            }}
            tagInputProps={{
                tagProps: { minimal: true, intent: Intent.PRIMARY }
            }}
            onRemove={deselectVoltage}
        />
    );
};

const getVoltageLabel = (voltage: Voltage): string => {
    return `${voltage} kV`;
};

export default VoltageSelectWrapper;

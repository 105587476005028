import {
    DocumentNode,
    OperationVariables,
    QueryResult,
    useQuery,
    WatchQueryFetchPolicy
} from "@apollo/client";
import { Maybe } from "generated/graphql";
import { Loading } from "types/loadingType";

export const getHasuraDataAndConvertToNiraType = <
    TQuery,
    TQueryVariables extends OperationVariables,
    TNiraType
>(
    query: DocumentNode,
    conversionFunction: (hasuraType: TQuery) => TNiraType,
    variables?: TQueryVariables,
    skip?: boolean,
    fetchPolicy?: WatchQueryFetchPolicy
): Loading<TNiraType> => {
    const activeDataQuery: QueryResult<TQuery, TQueryVariables> = useQuery<
        TQuery,
        TQueryVariables
    >(query, {
        variables,
        skip,
        fetchPolicy
    });

    let maybeData: Loading<TNiraType>;
    if (activeDataQuery.loading || activeDataQuery.data === undefined) {
        maybeData = "loading";
    } else {
        maybeData = conversionFunction(activeDataQuery.data);
    }
    return maybeData;
};

export const convertMaybeToUndefined = <T,>(
    maybeValue: Maybe<T> | undefined
): T | undefined => {
    if (maybeValue === null || maybeValue === undefined) {
        return undefined;
    } else {
        return maybeValue;
    }
};

export const isStringArray = (value: unknown): value is string[] => {
    return (
        Array.isArray(value) &&
        value.every((element) => typeof element === "string")
    );
};

export const isNumberArray = (value: unknown): value is number[] => {
    return (
        Array.isArray(value) &&
        value.every((element) => typeof element === "number")
    );
};

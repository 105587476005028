import { useLazyQuery } from "@apollo/client";
import { Button, Intent, Position, Toaster, Tooltip } from "@blueprintjs/core";
import saveAs from "file-saver";
import { GetProjectsQuery, GetProjectsQueryVariables } from "generated/graphql";
import { loader } from "graphql.macro";
import { json2csvAsync } from "json-2-csv";
import { hasuraProjectsToNiraProjects, Project } from "types/projectsType";
import { ScopeType } from "types/scopeType";

const AppToaster = Toaster.create({
    position: Position.TOP
});
const GET_PROJECTS = loader("src/graphql/getProjects.graphql");
const DownloadProjectsCSVComponent: React.FC<{ scope: ScopeType }> = ({
    scope
}) => {
    const [getProjects] = useLazyQuery<
        GetProjectsQuery,
        GetProjectsQueryVariables
    >(GET_PROJECTS);

    const handleDownloadCSV = async () => {
        const data = (
            await getProjects({
                variables: { scope }
            })
        ).data;

        if (data !== undefined) {
            const projects = hasuraProjectsToNiraProjects(data.projects);

            const columnOrder: (keyof Project)[] = [
                "projectNumber",
                "studyGroup",
                "studyCycle",
                "erisCapacityMw",
                "nrisCapacityMw",
                "fuelType",
                "included"
            ];

            // The order of insert into the object is the same order the columns will be in on export
            const projectsWithCorrectColumnOrder = projects.map((project) => {
                const reorderedProject = Object.fromEntries(
                    columnOrder.map((column) => [column, project[column]])
                );
                return reorderedProject;
            });

            const csvString = await json2csvAsync(
                [...projectsWithCorrectColumnOrder],
                {
                    checkSchemaDifferences: true,
                    emptyFieldValue: ""
                }
            );

            // Create a Blob and generate a URL for the CSV data
            const blob = new Blob([csvString], { type: "text/csv" });

            saveAs(blob, "projects.csv");
        } else {
            // Display a warning toast
            AppToaster.show({
                message: `Error fetching project data.`,
                intent: Intent.WARNING,
                icon: "warning-sign",
                timeout: 5000
            });
        }
    };

    return (
        <Tooltip
            content={
                <div>
                    Click to download a CSV of which projects <br /> were
                    included in the MISO Realistic Scope.
                </div>
            }
            position={Position.TOP_RIGHT}
        >
            <Button text="Download Projects" onClick={handleDownloadCSV} />
        </Tooltip>
    );
};

export default DownloadProjectsCSVComponent;

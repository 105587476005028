import { Button, MenuItem } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Select } from "@blueprintjs/select";
import { useTrackedProjectsInCluster } from "in_queue/contexts/ProjectDataContext";

interface AllProjectsItem {
    type: "all-projects";
}
interface TrackedProjectItem {
    type: "project";
    projectId?: string;
}
type VisibilityMenuItem = AllProjectsItem | TrackedProjectItem;

export const VisibilityInput: React.FC<{
    parentProjectId: string | undefined;
    setParentProjectId: (projectId: string | undefined) => void;
}> = ({ parentProjectId, setParentProjectId }) => {
    const trackedProjects = useTrackedProjectsInCluster();

    let trackedProjectItems: VisibilityMenuItem[] = [{ type: "all-projects" }];
    if (trackedProjects !== "loading") {
        trackedProjectItems = trackedProjectItems.concat(
            trackedProjects.map((project) => ({
                type: "project",
                projectId: project.projectId
            }))
        );
    }

    return (
        <Select
            items={trackedProjectItems}
            itemRenderer={(item, { handleClick }) => (
                <MenuItem
                    active={
                        (item.type === "all-projects" && !parentProjectId) ||
                        (item.type === "project" &&
                            item.projectId === parentProjectId)
                    }
                    text={
                        item.type === "all-projects"
                            ? "All projects"
                            : item.projectId
                    }
                    key={
                        item.type === "all-projects"
                            ? "All projects"
                            : item.projectId
                    }
                    onClick={handleClick}
                />
            )}
            filterable={false}
            popoverProps={{
                minimal: true
            }}
            onItemSelect={(item) => {
                setParentProjectId(
                    item.type === "project" ? item.projectId : undefined
                );
            }}
        >
            <Button
                rightIcon={IconNames.CARET_DOWN}
                text={parentProjectId ? parentProjectId : "All projects"}
                small
            />
        </Select>
    );
};

import {
    Breadcrumb,
    BreadcrumbProps,
    Breadcrumbs,
    Classes
} from "@blueprintjs/core";
import { IN_QUEUE_PATH } from "infrastructure/RouterApp";
import { useScenarioMetadata } from "in_queue/contexts/ProjectDataContext";
import {
    clusterIdToCluster,
    getClusterLabel
} from "in_queue/types/clusterType";
import { Link, useMatch } from "react-router-dom";

export const InQueueBreadcrumbs: React.FC = () => {
    const clusterMatch = useMatch(`/${IN_QUEUE_PATH}/:clusterId`);
    const projectMatch = useMatch(`/${IN_QUEUE_PATH}/:clusterId/:projectId`);
    const scenarioMatch = useMatch(
        `/${IN_QUEUE_PATH}/:clusterId/:projectId/scenario/:scenarioId`
    );

    const scenario = useScenarioMetadata(
        scenarioMatch?.params.projectId,
        scenarioMatch?.params.scenarioId
    );

    const breadcrumbs: BreadcrumbProps[] = [];
    if (clusterMatch != null) {
        const clusterId = clusterMatch.params.clusterId;
        const clusterLabel = getClusterLabel(clusterIdToCluster(clusterId));
        breadcrumbs.push({
            icon: "folder-close",
            href: `/${IN_QUEUE_PATH}/${clusterId}`,
            text: clusterLabel,
            current: true
        });
    }
    if (projectMatch != null) {
        const clusterId = projectMatch.params.clusterId;
        const clusterLabel = getClusterLabel(clusterIdToCluster(clusterId));
        breadcrumbs.push({
            icon: "folder-close",
            href: `/${IN_QUEUE_PATH}/${clusterId}`,
            text: clusterLabel
        });
        const projectId = projectMatch.params.projectId;
        breadcrumbs.push({
            href: `/${IN_QUEUE_PATH}/${clusterId}/${projectId}`,
            text: `Project ${projectId}`,
            current: true
        });
    }
    if (scenarioMatch != null) {
        const clusterId = scenarioMatch.params.clusterId;
        const clusterLabel = getClusterLabel(clusterIdToCluster(clusterId));
        breadcrumbs.push({
            icon: "folder-close",
            href: `/${IN_QUEUE_PATH}/${clusterId}`,
            text: clusterLabel
        });

        const projectId = scenarioMatch.params.projectId;
        breadcrumbs.push({
            href: `/${IN_QUEUE_PATH}/${clusterId}/${projectId}`,
            text: `Project ${projectId}`
        });

        let scenarioText;
        if (scenario === "loading" || scenario === undefined) {
            scenarioText = (
                <>
                    Scenario:&nbsp;{" "}
                    <span className={Classes.SKELETON}>scenario title</span>
                </>
            );
        } else {
            scenarioText = `Scenario: ${scenario.title}`;
        }
        const scenarioId = scenarioMatch.params.scenarioId;
        breadcrumbs.push({
            href: `/${IN_QUEUE_PATH}/${clusterId}/${projectId}/scenario/${scenarioId}`,
            text: scenarioText,
            current: true
        });
    }

    return (
        <Breadcrumbs
            items={breadcrumbs}
            breadcrumbRenderer={(props: BreadcrumbProps) => (
                <ReactRouterBreadcrumb {...props} />
            )}
        />
    );
};
const ReactRouterBreadcrumb: React.FC<BreadcrumbProps> = (props) => {
    return (
        <Link to={props.href ?? `/${IN_QUEUE_PATH}`}>
            <Breadcrumb {...props} />
        </Link>
    );
};

import { loader } from "graphql.macro";
import { getHasuraDataAndConvertToNiraType } from "graphql/helpers/queryHelpers";
import {
    convertHasuraSubscriptionsToSubscriptions,
    InQueueSubscription
} from "in_queue/types/projectType";
import { createContext, PropsWithChildren, useContext } from "react";
import { Loading } from "types/loadingType";

interface InQueueSubscriptionsContextValue {
    subscriptions: Loading<InQueueSubscription[]>;
}

export const InQueueSubscriptionsContext =
    createContext<InQueueSubscriptionsContextValue>({
        subscriptions: "loading"
    });

const GET_IN_QUEUE_SUBSCRIPTIONS = loader(
    "src/graphql/in_queue/getInQueueSubscriptions.graphql"
);

export const InQueueSubscriptionsProvider: React.FC<
    PropsWithChildren<unknown>
> = ({ children }: PropsWithChildren<unknown>) => {
    const subscriptions = getHasuraDataAndConvertToNiraType(
        GET_IN_QUEUE_SUBSCRIPTIONS,
        convertHasuraSubscriptionsToSubscriptions
    );

    const value = {
        subscriptions
    };
    return (
        <InQueueSubscriptionsContext.Provider value={value}>
            {children}
        </InQueueSubscriptionsContext.Provider>
    );
};

export const useSubscriptions = (): Loading<InQueueSubscription[]> => {
    const context = useContext(InQueueSubscriptionsContext);
    return context.subscriptions;
};

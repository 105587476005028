// eslint-disable-next-line import/no-unresolved
import { FeatureCollection } from "geojson";

export const EMPTY_GEOJSON: Readonly<FeatureCollection> = {
    type: "FeatureCollection",
    features: [
        {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: []
            },
            properties: {}
        }
    ]
};

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const LoggedOutApp: React.FC = () => {
    const { loginWithRedirect } = useAuth0();
    // It's weird that appState is not typed here, but this key has to be returnTo.
    loginWithRedirect({
        appState: {
            returnTo: window.location.pathname + window.location.search
        }
    });
    return <div></div>;
};

export default LoggedOutApp;

import { BusFiltersFromQueryParams } from "./busFilterTypes";
import { RegionType } from "./regionType";
import {
    convertToScopeType,
    doesScopeMatchRegion,
    isKnownScopeType
} from "./scopeType";
import { convertToVoltage, isKnownVoltageType } from "./voltageTypes";

export const QUERY_PARAMS_FOR_MAP_VIEW: { readonly [key: string]: string } = {
    voltage: "voltage",
    scope: "scope",
    maxAllocatedCostsEnabled: "maxUpgradeCostsEnabled",
    maxAllocatedCosts: "maxUpgradeCosts",
    maxTotalCostsEnabled: "maxTotalCostsEnabled",
    maxTotalCosts: "maxTotalCosts",
    encryptedSelectedBusId: "encryptedSelectedBusId",
    selectedBusId: "selectedBusId",
    latitude: "latitude",
    longitude: "longitude",
    zoom: "zoom",
    includePreExistingConstraints: "includePreExistingConstraints"
};

export const queryParamsHaveUnknownOrMismatchedScopeAndRegion = (
    selectedRegion: RegionType,
    params: URLSearchParams
): boolean => {
    const maybeScopeParam = params.get(QUERY_PARAMS_FOR_MAP_VIEW.scope);

    if (maybeScopeParam !== null) {
        if (isKnownScopeType(maybeScopeParam)) {
            const scope = convertToScopeType(maybeScopeParam);
            return !doesScopeMatchRegion(scope, selectedRegion);
        } else {
            return true;
        }
    }

    return false;
};

export const getBusFiltersFromQueryParams = (
    selectedRegion: RegionType,
    params: URLSearchParams
): BusFiltersFromQueryParams => {
    let busFilters: BusFiltersFromQueryParams = {};

    const maybeVoltageParam = params.get(QUERY_PARAMS_FOR_MAP_VIEW.voltage);
    const maybeScopeParam = params.get(QUERY_PARAMS_FOR_MAP_VIEW.scope);
    const maybeMaxAllocatedCostsEnabledParam = params.get(
        QUERY_PARAMS_FOR_MAP_VIEW.maxAllocatedCostsEnabled
    );
    const maybeMaxAllocatedCostsParam = params.get(
        QUERY_PARAMS_FOR_MAP_VIEW.maxAllocatedCosts
    );
    const maybeMaxTotalCostsEnabledParam = params.get(
        QUERY_PARAMS_FOR_MAP_VIEW.maxTotalCostsEnabled
    );
    const maybeMaxTotalCosts = params.get(
        QUERY_PARAMS_FOR_MAP_VIEW.maxTotalCosts
    );
    const maybeIncludePreExistingConstraints = params.get(
        QUERY_PARAMS_FOR_MAP_VIEW.includePreExistingConstraints
    );

    if (maybeVoltageParam !== null) {
        const voltageParamAsInt = parseInt(maybeVoltageParam);
        if (isKnownVoltageType(voltageParamAsInt)) {
            busFilters = {
                ...busFilters,
                voltage: convertToVoltage(voltageParamAsInt)
            };
        }
    }
    if (maybeScopeParam !== null) {
        if (isKnownScopeType(maybeScopeParam)) {
            const scope = convertToScopeType(maybeScopeParam);
            if (doesScopeMatchRegion(scope, selectedRegion)) {
                busFilters = {
                    ...busFilters,
                    scope
                };
            }
        }
    }

    if (maybeMaxAllocatedCostsEnabledParam !== null) {
        busFilters = {
            ...busFilters,
            maxAllocatedCostsEnabled: parseBooleanFromString(
                maybeMaxAllocatedCostsEnabledParam
            )
        };
    }

    if (maybeMaxAllocatedCostsParam !== null) {
        const maxAllocatedCostsAsInt = parseInt(maybeMaxAllocatedCostsParam);
        if (!isNaN(maxAllocatedCostsAsInt)) {
            busFilters = {
                ...busFilters,
                maxAllocatedCosts: maxAllocatedCostsAsInt
            };
        }
    }

    if (maybeMaxTotalCostsEnabledParam !== null) {
        busFilters = {
            ...busFilters,
            maxTotalCostsEnabled: parseBooleanFromString(
                maybeMaxTotalCostsEnabledParam
            )
        };
    }

    if (maybeMaxTotalCosts !== null) {
        const maybeMaxTotalCostsAsInt = parseInt(maybeMaxTotalCosts);
        if (!isNaN(maybeMaxTotalCostsAsInt)) {
            busFilters = {
                ...busFilters,
                maxTotalCosts: maybeMaxTotalCostsAsInt
            };
        }
    }

    if (maybeIncludePreExistingConstraints !== null) {
        busFilters = {
            ...busFilters,
            includePreExistingConstraints: parseBooleanFromString(
                maybeIncludePreExistingConstraints
            )
        };
    }

    return busFilters;
};

const parseBooleanFromString = (
    maybeStringBool: string
): boolean | undefined => {
    if (maybeStringBool.toLocaleUpperCase() === "TRUE") {
        return true;
    } else if (maybeStringBool.toLocaleUpperCase() === "FALSE") {
        return false;
    } else {
        return undefined;
    }
};

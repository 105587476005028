import { Callout, Intent } from "@blueprintjs/core";
import { segmentTrackSelectionCanceledFromBusDetailPaneCross } from "analytics/analyticTrackEvents";
import BusCapacityDot from "components/common/BusCapacityDot";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import useSelectedBuses from "contexts/SelectedBusesContext/useSelectedBuses";
import useSelectedScopedBus from "contexts/SelectedScopedBusContext/useSelectedScopedBus";
import React from "react";
import { getCapacityColor } from "sharedStyles/capacityColorStyles";
import { isLowConfidenceLocationBus } from "types/busType";
import DetailPaneHeader from "../shared/DetailPaneHeader";
import "./BusDetailPane.scss";
import OtherBusesToggle from "./OtherBusesToggle";

type BusDetailPaneProps = {
    readonly energyTag: React.ReactNode;
    readonly chargingTag?: React.ReactNode;
    readonly capacityTag?: React.ReactNode;
    constraintDetailsSections: ReadonlyArray<React.ReactNode>;
    connectedLinesSection: React.ReactNode;
};

export type PowerAmounts = {
    readonly energySize: number;
    readonly capacitySize: number;
    readonly chargingSize: number;
};

const BusDetailPane: React.FC<BusDetailPaneProps> = (
    props: BusDetailPaneProps
) => {
    const {
        energyTag,
        chargingTag,
        capacityTag,
        constraintDetailsSections,
        connectedLinesSection
    } = props;
    const { clearSelectedBuses } = useSelectedBuses();
    const {
        generator,
        componentConfig: { showLineTaps }
    } = useMapViewRoutingMetadata();
    const scopedBus = useSelectedScopedBus();
    const {
        busFiltersConfiguration: { busFilters }
    } = useMapViewConfiguration();
    const { capacityThresholds } = busFilters;

    const capacityDotColor = getCapacityColor(
        scopedBus.scopedCapacityEnergyCost,
        capacityThresholds,
        generator
    );

    const onCancel = () => {
        segmentTrackSelectionCanceledFromBusDetailPaneCross(scopedBus.bus.id);
        clearSelectedBuses();
    };

    return (
        <div className="BusDetailPane-wrapper">
            <div className="BusDetailPane-main-content-wrapper">
                <DetailPaneHeader
                    title={scopedBus.bus.busDisplayName}
                    icon={<BusCapacityDot color={capacityDotColor} />}
                    onCancel={onCancel}
                    energyTag={energyTag}
                    chargingTag={chargingTag}
                    capacityTag={capacityTag}
                />
                {constraintDetailsSections.map(
                    (constraintDetailSection, index) => (
                        <div
                            key={index}
                            className="BusDetailPane-details-wrapper"
                        >
                            {constraintDetailSection}
                        </div>
                    )
                )}
                <div>
                    <h6 className="bp5-heading">Bus details</h6>
                    {getBusDetailPaneRow("Bus ID", `${scopedBus.bus.id}`)}
                    {getBusDetailPaneRow("Voltage", `${scopedBus.bus.voltage}`)}
                    {getBusDetailPaneRow(
                        "Owner",
                        scopedBus.bus.substation.substationOwner
                    )}
                    {getBusDetailPaneRow(
                        "Coordinates",
                        `(${scopedBus.bus.latitude.toFixed(
                            3
                        )}, ${scopedBus.bus.longitude.toFixed(3)})`
                    )}
                    {isLowConfidenceLocationBus(scopedBus) && (
                        <Callout
                            className="BusDetailPane-low-confidence-location-callout"
                            intent={Intent.WARNING}
                            title="Coordinates may be inaccurate"
                        >
                            Nira has low confidence on the coordinates for this
                            bus. Please double-check the location in other data
                            sources.
                        </Callout>
                    )}
                </div>
                {showLineTaps && connectedLinesSection}
            </div>
            <OtherBusesToggle />
        </div>
    );
};

const getBusDetailPaneRow = (label: string, value: string): JSX.Element => {
    return (
        <div className="BusDetailPane-row">
            <div className="BusDetailPane-row-label  bp5-text-disabled">
                {label}
            </div>
            <div className="BusDetailPane-row-value">{value}</div>
        </div>
    );
};

export default BusDetailPane;

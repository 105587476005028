import { MAP_CURVE, MAP_SPEED } from "components/map/MapComponent";
import { useState } from "react";
import { MapRef } from "react-map-gl";

type BoundingBox = {
    readonly maxLong: number;
    readonly maxLat: number;
    readonly minLong: number;
    readonly minLat: number;
};

const INIT_BOUNDING_BOX: BoundingBox = {
    maxLong: 0,
    maxLat: 0,
    minLong: 0,
    minLat: 0
};

// includes Hawaii and Alaska
export const USA_BOUNDING_BOX: BoundingBox = {
    maxLong: -65.6957,
    maxLat: 71.5388,
    minLong: -178.2175,
    minLat: 18.9108
};

const PADDING = 0.05;
const PANE_PADDING_MULTIPLIER = 1.5;
// panes are actually 450px in width but making it 500 to add buffer
const PANE_WIDTH = 500;

interface BoundingBoxConfiguration {
    boundingBox: BoundingBox;
    currentMapRef: MapRef | null;
    updateMapRef: (mapRef: MapRef | null) => void;
    updateBoundingBox: () => void;
    adjustBoundingBoxToShowLine: (newBoundingBox: BoundingBox) => void;
}

const useCreateBoundingBoxConfiguration = (): BoundingBoxConfiguration => {
    const [boundingBox, setBoundingBox] =
        useState<BoundingBox>(INIT_BOUNDING_BOX);
    const [currentMapRef, setCurrentMapRef] = useState<MapRef | null>(null);

    const updateBoundingBox = () => {
        if (currentMapRef === null) {
            return;
        }

        setBoundingBox(getBoundingBox(currentMapRef));
    };

    const updateMapRef = (mapRef: MapRef | null) => {
        setCurrentMapRef(mapRef);
        mapRef !== null && setBoundingBox(getBoundingBox(mapRef));
    };

    const fitBoundsOptions = {
        speed: MAP_SPEED,
        curve: MAP_CURVE
    };
    const adjustBoundingBoxToShowLine = (newBoundingBox: BoundingBox) => {
        if (currentMapRef === null) {
            return;
        }

        const containerWidth = currentMapRef
            .getMap()
            .getContainer().clientWidth;
        const percentPane = PANE_WIDTH / containerWidth;

        if (percentPane > 1) {
            return;
        }

        const alreadyInView = insideCurrentBoundingBox(
            boundingBox,
            newBoundingBox,
            percentPane
        );
        if (alreadyInView) {
            return;
        }

        const maxLongWithPadding = newBoundingBox.maxLong + PADDING;
        const minLongWithPadding = newBoundingBox.minLong - PADDING;
        const paddingForPane =
            (maxLongWithPadding - minLongWithPadding) *
            percentPane *
            PANE_PADDING_MULTIPLIER;

        currentMapRef.fitBounds(
            [
                minLongWithPadding,
                newBoundingBox.minLat - PADDING,
                maxLongWithPadding + paddingForPane,
                newBoundingBox.maxLat + PADDING
            ],
            fitBoundsOptions
        );
    };

    return {
        boundingBox,
        currentMapRef,
        updateMapRef,
        updateBoundingBox,
        adjustBoundingBoxToShowLine
    };
};

const getBoundingBox = (mapRef: MapRef): BoundingBox => {
    const bounds = mapRef.getMap().getBounds();
    const minLong = bounds.getWest();
    const minLat = bounds.getSouth();
    const maxLong = bounds.getEast();
    const maxLat = bounds.getNorth();
    return {
        maxLong,
        maxLat,
        minLong,
        minLat
    };
};

const insideCurrentBoundingBox = (
    currentBoundingBox: BoundingBox,
    newBoundingBox: BoundingBox,
    percentPane: number
): boolean => {
    const paneLong =
        (currentBoundingBox.maxLong - currentBoundingBox.minLong) * percentPane;
    const maxLongMinusPane = currentBoundingBox.maxLong - paneLong;
    return (
        newBoundingBox.minLat > currentBoundingBox.minLat &&
        newBoundingBox.maxLat < currentBoundingBox.maxLat &&
        newBoundingBox.minLong > currentBoundingBox.minLong &&
        newBoundingBox.maxLong < maxLongMinusPane
    );
};

export { useCreateBoundingBoxConfiguration };
export type { BoundingBox, BoundingBoxConfiguration };

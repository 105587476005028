import { Spinner } from "@blueprintjs/core";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { forwardRef, Ref } from "react";
import { Loading } from "types/loadingType";

export interface BaseAgGridProps<TData> extends AgGridReactProps<TData> {
    loadingData: Loading<TData[]>;
    height?: "auto" | number;
    /**
     * Whether each row should show up as selectable. Makes cursor show as pointer
     * for each row, and disables ability to focus cells. Should be used in conjunction
     * with an onRowClicked event to navigate to another page.
     */
    selectableRows?: boolean;
}

const BaseAgGridInner = <TData,>(
    props: BaseAgGridProps<TData>,
    ref?: Ref<AgGridReact<TData>>
): React.ReactElement => {
    const divHeight =
        props.height === undefined
            ? 500
            : props.height === "auto"
            ? undefined
            : props.height;
    const maybeRowStyle = props.selectableRows
        ? { cursor: "pointer" }
        : undefined;
    return (
        <div className="ag-theme-balham" style={{ height: divHeight }}>
            <AgGridReact<TData>
                ref={ref}
                rowData={
                    props.loadingData === "loading"
                        ? undefined
                        : props.loadingData
                }
                loadingOverlayComponent={Spinner}
                animateRows={false}
                enableCellTextSelection={true}
                tooltipShowDelay={500}
                defaultColDef={{ useValueFormatterForExport: false }}
                domLayout={props.height === "auto" ? "autoHeight" : "normal"}
                rowStyle={maybeRowStyle}
                suppressCellFocus={props.selectableRows}
                {...props}
            />
        </div>
    );
};

// These type shenanigans are necessary to support having a generic type TData
// *and* a forwarded ref. See https://fettblog.eu/typescript-react-generic-forward-refs/.
export const BaseAgGrid = forwardRef(BaseAgGridInner) as <TData>(
    props: BaseAgGridProps<TData> & {
        ref?: React.ForwardedRef<AgGridReact<TData>>;
    }
) => ReturnType<typeof BaseAgGridInner>;

import { Icon, IconSize } from "@blueprintjs/core";
import "./DetailPaneHeader.scss";

interface DetailPaneHeaderProps {
    title: string;
    icon: React.ReactNode;
    onCancel: () => void;
    readonly energyTag: React.ReactNode;
    readonly chargingTag?: React.ReactNode;
    readonly capacityTag?: React.ReactNode;
}

const DetailPaneHeader: React.FC<DetailPaneHeaderProps> = (props) => {
    const { title, icon, onCancel, energyTag, chargingTag, capacityTag } =
        props;

    return (
        <div className="DetailPaneHeader-wrapper">
            {icon}
            <h5 className="bp5-heading">{title}</h5>
            <Icon
                className={"DetailPaneHeader-cross bp5-text-muted"}
                icon="cross"
                size={IconSize.STANDARD}
                onClick={onCancel}
            />

            <div className="DetailPaneHeader-capacity-energy-tags-wrapper">
                <div className="DetailPaneHeader-energy-tag-wrapper">
                    {energyTag}
                </div>
                {chargingTag && (
                    <div className="DetailPaneHeader-charging-capacity-tag-wrapper">
                        {chargingTag}
                    </div>
                )}
                {capacityTag && (
                    <div className="DetailPaneHeader-charging-capacity-tag-wrapper">
                        {capacityTag}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DetailPaneHeader;

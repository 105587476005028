import { Alert, Intent, MenuItem, Position, Toaster } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useUserDataContext } from "contexts/UserDataContext/UserDataContext";
import { useDeleteScenarioMutation } from "generated/graphql";
import { IN_QUEUE_PATH } from "infrastructure/RouterApp";
import { segmentTrackDeleteScenario } from "in_queue/analytics";
import {
    useCurrentScenarioMetadata,
    useCurrentTrackedProject
} from "in_queue/contexts/ScenarioPageContext";
import { clusterToClusterId } from "in_queue/types/clusterType";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ADMIN_TYPE } from "types/roleType";

interface DeleteScenarioActionProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const DeleteScenarioAlert: React.FC<DeleteScenarioActionProps> = ({
    isOpen,
    setIsOpen
}: DeleteScenarioActionProps) => {
    const trackedProject = useCurrentTrackedProject();
    const scenario = useCurrentScenarioMetadata();
    const navigate = useNavigate();
    const [deleteScenarioMutation] = useDeleteScenarioMutation();

    const [isDeleteAlertLoading, setIsDeleteAlertLoading] = useState(false);

    const { projectId, cluster } = trackedProject;

    const deleteScenario = async () => {
        setIsDeleteAlertLoading(true);

        const hasuraResponse = await deleteScenarioMutation({
            variables: {
                scenarioId: scenario.scenarioId
            }
        });

        if (
            hasuraResponse.data &&
            hasuraResponse.data.delete_scenarios &&
            hasuraResponse.data.delete_scenarios.affected_rows > 0
        ) {
            segmentTrackDeleteScenario({ scenarioId: scenario.scenarioId });
            setIsDeleteAlertLoading(false);
            setIsOpen(false);

            DeleteScenarioToaster.show({
                intent: Intent.SUCCESS,
                message: `Successfully deleted scenario "${scenario.title}"`
            });

            const clusterId = clusterToClusterId(cluster);
            navigate(`/${IN_QUEUE_PATH}/${clusterId}/${projectId}`);
        } else {
            setIsDeleteAlertLoading(false);
            setIsOpen(false);

            DeleteScenarioToaster.show({
                intent: Intent.DANGER,
                message: `Unable to delete scenario.`
            });
        }
    };

    return (
        <>
            <Alert
                cancelButtonText="Cancel"
                confirmButtonText="Delete scenario"
                icon="trash"
                intent={Intent.DANGER}
                isOpen={isOpen}
                loading={isDeleteAlertLoading}
                onCancel={() => setIsOpen(false)}
                onConfirm={deleteScenario}
            >
                <p>Are you sure you want to delete this scenario?</p>
            </Alert>
        </>
    );
};

interface DeleteScenarioMenuItemProps {
    setIsOpen: (isOpen: boolean) => void;
}

const DeleteScenarioMenuItem: React.FC<DeleteScenarioMenuItemProps> = ({
    setIsOpen
}: DeleteScenarioMenuItemProps) => {
    const { role } = useUserDataContext();
    const isNotAdmin = role !== ADMIN_TYPE;

    return (
        <MenuItem
            icon={IconNames.DELETE}
            text="Delete"
            onClick={() => setIsOpen(true)}
            disabled={isNotAdmin}
        />
    );
};

const DeleteScenarioToaster = Toaster.create({
    position: Position.TOP
});

export { DeleteScenarioAlert, DeleteScenarioMenuItem };

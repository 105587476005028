import { useContext } from "react";
import {
    MapViewDataContext,
    MapViewDataContextProps
} from "../MapViewDataContext";

const useMapViewData = (): MapViewDataContextProps => {
    const context = useContext(MapViewDataContext);
    if (!context) {
        throw new Error(
            "useMapViewData must be used within a MapViewDataProvider"
        );
    }

    return context;
};

export default useMapViewData;

import { segmentTrackMaxTotalCostsDropdownChange } from "analytics/analyticTrackEvents";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { MaybeMaxCosts } from "types/busFilterTypes";
import CostsInput from "./CostsInput";
import FilterWrapper from "./FilterWrapper";

const TotalCostFilter: React.FC = () => {
    const {
        busFiltersConfiguration: {
            busFilters: { maybeMaxTotalCosts },
            updateMaybeMaxTotalCosts
        }
    } = useMapViewConfiguration();

    return (
        <FilterWrapper label={"Total cost (millions)"}>
            <CostsInput
                maxCost={maybeMaxTotalCosts}
                setMaxCost={(newMaybeMaxTotalCosts: MaybeMaxCosts) => {
                    updateMaybeMaxTotalCosts(newMaybeMaxTotalCosts);
                    segmentTrackMaxTotalCostsDropdownChange(
                        newMaybeMaxTotalCosts,
                        maybeMaxTotalCosts
                    );
                }}
            />
        </FilterWrapper>
    );
};

export default TotalCostFilter;

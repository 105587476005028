import { getLabelStyle } from "components/common/labels";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { useState } from "react";
import { convertToCurtailmentConfig } from "types/componentConfigPerRegion";
import { Constraint } from "types/constraintType";
import { LineTapConstraint } from "types/lineConstraintType";
import { Loading } from "types/loadingType";
import { PowerAmounts } from "../BusDetailPane";
import ConstraintDetailsDialog from "./ConstraintDetailsDialog";
import { ConstraintTableConfig } from "./ConstraintsDetailsDialogTable";
import { getExportConstraintsToCSV } from "./constraintsTableHelpers";
import CurtailmentConstraintDetailsDialogTable from "./CurtailmentConstraintDetailsDialogTable";
import {
    ActivatedScopeViewsType,
    convertToCurtailmentRegion,
    filterDownConstraints,
    getActivatedScopeViews,
    getErcotModelNameFromConstraint
} from "./curtailmentConstraintsHelpers";

type CurtailmentConstraintDetailsDialogProps = {
    readonly dialogTitle: string;
    readonly detailsDialogOpen: boolean;
    readonly setDetailsDialogOpen: (value: boolean) => void;
    readonly maybeConstraints: Loading<
        ReadonlyArray<Constraint | LineTapConstraint>
    >;
    readonly powerAmounts: PowerAmounts;
    readonly selectedId: string;
    readonly exportDisplayName: string;
};

export const CurtailmentConstraintDetailsDialog: React.FC<
    CurtailmentConstraintDetailsDialogProps
> = ({
    dialogTitle,
    detailsDialogOpen,
    setDetailsDialogOpen,
    maybeConstraints,
    powerAmounts,
    selectedId,
    exportDisplayName
}) => {
    const {
        generator: generatorType,
        componentConfig,
        scopeView,
        region
    } = useMapViewRoutingMetadata();

    const curtailmentRegion = convertToCurtailmentRegion(region);

    const [activatedScopeViews, setActivatedScopeViews] =
        useState<ActivatedScopeViewsType>(
            getActivatedScopeViews(curtailmentRegion, scopeView, generatorType)
        );

    const maybeFilteredDownConstraints = filterDownConstraints(
        maybeConstraints,
        generatorType,
        activatedScopeViews,
        curtailmentRegion
    );

    const curtailmentConfig = convertToCurtailmentConfig(componentConfig);

    const constraintTableConfig: ConstraintTableConfig = {
        hideAllocatedCosts: true,
        hideTotalCosts: true,
        showPostStudyLoad: false,
        maybeGetModelName: curtailmentConfig.showModelColumnConstraintTable
            ? getErcotModelNameFromConstraint
            : undefined,
        showContingency: curtailmentConfig.showContingency,
        generatorType
    };
    const exportConstraints = getExportConstraintsToCSV(
        exportDisplayName,
        maybeFilteredDownConstraints,
        {
            constraintTableConfig,
            labelStyle: getLabelStyle(componentConfig, generatorType),
            powerAmounts: powerAmounts
        }
    );

    return (
        <ConstraintDetailsDialog
            isOpen={detailsDialogOpen}
            closeDialog={() => {
                setDetailsDialogOpen(false);
            }}
            exportConstraints={exportConstraints}
            title={dialogTitle}
            selectedId={selectedId}
        >
            <CurtailmentConstraintDetailsDialogTable
                maybeFilteredDownConstraints={maybeFilteredDownConstraints}
                constraintTableConfig={constraintTableConfig}
                activatedScopeViews={activatedScopeViews}
                setActivatedScopeViews={setActivatedScopeViews}
                powerAmounts={powerAmounts}
            />
        </ConstraintDetailsDialog>
    );
};

import { Callout, Intent } from "@blueprintjs/core";
import { useRegionConfig } from "in_queue/contexts/ClusterContext";
import { useAllProjectsInCluster } from "in_queue/contexts/ProjectDataContext";
import { MisoPercentageReduction } from "in_queue/types/scenarioType";
import { formatPower } from "in_queue/utils/formatting";
import { keyBy, mapValues } from "lodash";

const PERCENT_DROP_WARNING_THRESHOLD = 85;

interface ScenarioPreviewCalloutProps {
    projectSizeAssumptions: Record<string, MisoPercentageReduction>;
}
export const ScenarioPreviewCallout: React.FC<ScenarioPreviewCalloutProps> = ({
    projectSizeAssumptions
}) => {
    const config = useRegionConfig();
    const clusterProjects = useAllProjectsInCluster();

    if (!config.showScenarioPreview || clusterProjects === "loading") {
        return null;
    }

    const projectIdToSize = mapValues(
        keyBy(clusterProjects, "projectId"),
        (project) => project.size
    );

    let droppedProjectCount = 0;
    let mwRemoved = 0;
    for (const [assumptionProjectId, assumption] of Object.entries(
        projectSizeAssumptions
    )) {
        if (!assumption) {
            continue;
        }
        if (assumption.eris === 100 && assumption.nris === 100) {
            droppedProjectCount += 1;
        }
        mwRemoved +=
            projectIdToSize[assumptionProjectId].erisMw *
            (assumption.eris / 100);
    }

    if (droppedProjectCount === 0 && mwRemoved < 100) {
        return null;
    }

    const percentDropped = (droppedProjectCount / clusterProjects.length) * 100;
    const shouldShowSubmitWarning =
        percentDropped > PERCENT_DROP_WARNING_THRESHOLD;

    return (
        <Callout
            title={shouldShowSubmitWarning ? "Warning" : "Info"}
            intent={shouldShowSubmitWarning ? Intent.WARNING : Intent.PRIMARY}
            icon={shouldShowSubmitWarning ? "warning-sign" : "info-sign"}
        >
            This scenario drops <b>{droppedProjectCount}</b> projects (
            <b>{percentDropped.toFixed(1)}%</b> of the queue) and removes{" "}
            <b>{formatPower(mwRemoved)}</b> of generation from the model.
            {shouldShowSubmitWarning && (
                <span>
                    &nbsp; Scenarios that drop more than{" "}
                    {PERCENT_DROP_WARNING_THRESHOLD}% of projects will likely
                    fail due to issues with redispatching large amounts of
                    generation.
                </span>
            )}
        </Callout>
    );
};

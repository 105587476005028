import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { LayerStyle, LayerStyleId } from "types/layerStyleTypes";
import "./LayerPreview.scss";

interface LayerPreviewProps {
    style: LayerStyle;
    layerStyleHovered: LayerStyleId | undefined;
    setLayerStyleHovered: (styleId: LayerStyleId | undefined) => void;
}

const LayerPreview: React.FC<LayerPreviewProps> = ({
    style,
    layerStyleHovered,
    setLayerStyleHovered
}: LayerPreviewProps) => {
    const {
        layerStyleConfiguration: { layerStyleId, setLayerStyleId }
    } = useMapViewConfiguration();
    const selectedOrHovered =
        layerStyleId === style.id || layerStyleHovered === style.id;
    return (
        <div
            className="LayerPreview-wrapper"
            onMouseOver={() => setLayerStyleHovered(style.id)}
            onMouseLeave={() => setLayerStyleHovered(undefined)}
            onClick={() => setLayerStyleId(style.id)}
        >
            <img
                className={`LayerPreview-img ${
                    selectedOrHovered ? "LayerPreview-img-focused" : ""
                }`}
                src={style.previewSource}
                alt={style.name}
            />
            <div
                className={`LayerPreview-text  ${
                    selectedOrHovered ? "LayerPreview-text-focused" : ""
                }`}
            >
                {style.name}
            </div>
        </div>
    );
};

export default LayerPreview;

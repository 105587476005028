import { Switch } from "@blueprintjs/core";
import { segmentTrackHideLowConfidenceBusesChanged } from "analytics/analyticTrackEvents";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import FilterWrapper from "./FilterWrapper";

const LowConfidenceBusFilter: React.FC = () => {
    const {
        busFiltersConfiguration: {
            busFilters: { hideLowConfidenceBuses },
            toggleHideLowConfidenceBuses,
            filtersDisabled
        }
    } = useMapViewConfiguration();

    return (
        <FilterWrapper
            label={"Hide low-confidence location buses"}
            helperText='Toggle to "Yes" to remove buses with low-confidence coordinates.'
            wideLabel={true}
        >
            <Switch
                checked={hideLowConfidenceBuses}
                onChange={(evt) => {
                    toggleHideLowConfidenceBuses();
                    segmentTrackHideLowConfidenceBusesChanged(
                        evt.target.checked
                    );
                }}
                innerLabel="No"
                innerLabelChecked="Yes"
                style={{ marginBottom: 0 }}
                alignIndicator="right"
                large={true}
                disabled={filtersDisabled}
            />
        </FilterWrapper>
    );
};

export default LowConfidenceBusFilter;

import { Icon, Intent, Tag } from "@blueprintjs/core";
import "./UnauthorizedTag.scss";

import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { ENERGY_RESOURCE_TYPE, ResourceType } from "types/resourceType";

type UnauthorizedTagProps = {
    readonly resourceType: ResourceType;
};

const UnauthorizedTag: React.FC<UnauthorizedTagProps> = (props) => {
    const { resourceType } = props;

    return (
        <Tag
            round={true}
            intent={
                resourceType === ENERGY_RESOURCE_TYPE
                    ? Intent.PRIMARY
                    : Intent.NONE
            }
        >
            <div className="UnauthorizedTag-icon-wrapper">
                <Icon
                    icon={IconNames.LOCK}
                    size={15} // This is to match the height of the other pills
                />
            </div>
        </Tag>
    );
};

export default UnauthorizedTag;

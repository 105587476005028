import { segmentTrackMoreDetailsConstraintsDialogOpened } from "analytics/analyticTrackEvents";
import AvailableTag from "components/common/AvailableTag";
import { getLabelStyle } from "components/common/labels";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import useSelectedScopedBus from "contexts/SelectedScopedBusContext/useSelectedScopedBus";
import { GetConstraintsQueryVariables } from "generated/graphql";
import { loader } from "graphql.macro";
import { getHasuraDataAndConvertToNiraType } from "graphql/helpers/queryHelpers";
import React, { useState } from "react";
import { BusId } from "types/busType";
import {
    Constraint,
    convertHasuraConstraintsToConstraints
} from "types/constraintType";
import { GeneratorType, STORAGE_TYPE } from "types/generatorType";
import { Loading, mapLoadingState } from "types/loadingType";
import {
    CHARGING_RESOURCE_TYPE,
    ENERGY_RESOURCE_TYPE
} from "types/resourceType";
import BusDetailPane from "./BusDetailPane";
import ConnectedLinesSection from "./ConnectedLinesSection";
import { CurtailmentConstraintDetailsDialog } from "./ConstraintDetailsDialog/CurtailmentConstraintDetailsDialog";
import ConstraintsDetailsSection from "./ConstraintsDetailsSection";
import { getCurtailmentDetailRows } from "./constraintsHelpers";

type CurtailmentBusDetailPaneProps = {
    readonly unlockBusLoading: boolean;
};

const GET_CONSTRAINTS = loader("src/graphql/getConstraints.graphql");

const CurtailmentBusDetailPane: React.FC<CurtailmentBusDetailPaneProps> = (
    props: CurtailmentBusDetailPaneProps
) => {
    const { unlockBusLoading } = props;
    const { generator, scopeView, componentConfig } =
        useMapViewRoutingMetadata();
    const {
        busFiltersConfiguration: {
            busFilters: { scope }
        }
    } = useMapViewConfiguration();
    const scopedBus = useSelectedScopedBus();

    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

    const getConstraintsQueryVariables: GetConstraintsQueryVariables = {
        constraintsWhereClause: {
            bus_id: { _eq: scopedBus.bus.id },
            scope: { _eq: scope }
        }
    };

    const maybeAllConstraints = getHasuraDataAndConvertToNiraType(
        GET_CONSTRAINTS,
        convertHasuraConstraintsToConstraints,
        getConstraintsQueryVariables,
        unlockBusLoading
    );

    const scopeViewConstraints = mapLoadingState(
        maybeAllConstraints,
        (constraints) =>
            constraints.filter(
                (constraint) => constraint.scopeView === scopeView
            )
    );

    const labelStyle = getLabelStyle(componentConfig, generator);

    return (
        <>
            <BusDetailPane
                energyTag={
                    <AvailableTag
                        size={scopedBus.scopedCapacityEnergyCost.energySize}
                        resourceType={ENERGY_RESOURCE_TYPE}
                        labelStyle={labelStyle}
                    />
                }
                capacityTag={
                    generator === STORAGE_TYPE && (
                        <AvailableTag
                            size={
                                scopedBus.scopedCapacityEnergyCost.chargingSize
                            }
                            resourceType={CHARGING_RESOURCE_TYPE}
                            labelStyle={labelStyle}
                        />
                    )
                }
                constraintDetailsSections={[
                    getDetailsSection(
                        scopeViewConstraints,
                        scopedBus.bus.id,
                        setDetailsDialogOpen,
                        generator
                    )
                ]}
                connectedLinesSection={
                    <ConnectedLinesSection
                        isUnlockBusLoading={unlockBusLoading}
                    />
                }
            />
            <CurtailmentConstraintDetailsDialog
                dialogTitle="Constraint details"
                detailsDialogOpen={detailsDialogOpen}
                setDetailsDialogOpen={setDetailsDialogOpen}
                maybeConstraints={maybeAllConstraints}
                powerAmounts={scopedBus.scopedCapacityEnergyCost}
                selectedId={scopedBus.bus.id}
                exportDisplayName={scopedBus.bus.busDisplayName}
            />
        </>
    );
};

const getDetailsSection = (
    constraints: Loading<ReadonlyArray<Constraint>>,
    busId: BusId,
    setDetailsDialogOpen: (isOpen: boolean) => void,
    generatorType: GeneratorType
) => {
    return (
        <ConstraintsDetailsSection
            sectionLabel="Constraint details"
            onHeaderDetailsClicked={() => {
                segmentTrackMoreDetailsConstraintsDialogOpened(busId);
                setDetailsDialogOpen(true);
            }}
            constraintDetailsRows={getCurtailmentDetailRows(
                constraints,
                generatorType
            )}
        />
    );
};

export default CurtailmentBusDetailPane;

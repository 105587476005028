import { pickBy } from "lodash";
import { GITBOOK_NAMESPACE, NiraAuth0UserType } from "./auth0UserType";
import {
    convertToFeatureFlagTypeList,
    FeatureFlagType
} from "./featureFlagType";
import { isKnownRegionType, RegionType } from "./regionType";
import { convertToRoleType, RoleType } from "./roleType";
import {
    HasuraSubscription,
    hasuraSubscriptionsToNiraSubscriptions,
    Subscription
} from "./subscriptionType";

export type UserId = number;
export type TeamId = number;
export type Auth0Id = string;

export type User = {
    readonly id: UserId;
    readonly auth0Id: Auth0Id;
    readonly teamId: TeamId;
    readonly teamName: string;
    readonly featureFlags: ReadonlyArray<FeatureFlagType>;
    readonly subscriptions: ReadonlyArray<Subscription>;
    readonly gitbookTokens?: {
        readonly commonDocsGitbookToken: string;
        readonly regionGitbookTokens: { [K in RegionType]?: string };
    };
    readonly role: RoleType;
};

type HasuraTeam = {
    readonly name: string;
    readonly feature_flags: unknown;
    readonly subscriptions: HasuraSubscription[];
};

export type HasuraUser = {
    readonly id: number;
    readonly auth0_id: string;
    readonly team_id: number;
    readonly team: HasuraTeam;
    readonly role: string;
};

export const hasuraUserToNiraUser = (
    hasuraUser: HasuraUser,
    auth0User: NiraAuth0UserType
): User => {
    const subscriptions = hasuraSubscriptionsToNiraSubscriptions(
        hasuraUser.team.subscriptions
    );

    const maybeGitbookTokens = auth0User[GITBOOK_NAMESPACE];

    return {
        id: hasuraUser.id,
        auth0Id: hasuraUser.auth0_id,
        teamId: hasuraUser.team_id,
        teamName: hasuraUser.team.name,
        featureFlags: convertToFeatureFlagTypeList(
            hasuraUser.team.feature_flags
        ),
        subscriptions: subscriptions,
        gitbookTokens: maybeGitbookTokens && {
            commonDocsGitbookToken: maybeGitbookTokens.commonDocs,
            regionGitbookTokens: convertRegionGitbookTokens(
                maybeGitbookTokens.regionDocs
            )
        },
        role: convertToRoleType(hasuraUser.role)
    };
};

const convertRegionGitbookTokens = (rawGitbookTokens: {
    [K in string]: string;
}): { [K in RegionType]?: string } => {
    return pickBy(rawGitbookTokens, (_, maybeRegion) =>
        isKnownRegionType(maybeRegion)
    );
};

export const ENERGY_RESOURCE_TYPE = "energy";
export const CAPACITY_RESOURCE_TYPE = "capacity";
export const CHARGING_RESOURCE_TYPE = "charging";

export const RESOURCE_TYPES_IN_ORDER = [
    CAPACITY_RESOURCE_TYPE,
    ENERGY_RESOURCE_TYPE,
    CHARGING_RESOURCE_TYPE
] as const;

export type ResourceType = (typeof RESOURCE_TYPES_IN_ORDER)[number];

export const isKnownResourceType = (type: string): type is ResourceType => {
    return RESOURCE_TYPES_IN_ORDER.includes(type as ResourceType);
};

export const convertToResourceType = (stringType: string): ResourceType => {
    if (!isKnownResourceType(stringType)) {
        throw new Error("Unknown resource type");
    }
    return stringType;
};

export const convertToResourceTypes = (
    stringList: ReadonlyArray<string>
): ReadonlyArray<ResourceType> => {
    return stringList.filter(isKnownResourceType);
};

import shpwrite from "@mapbox/shp-write";
import { USA_BOUNDING_BOX } from "contexts/MapViewConfigurationContext/hooks/useBoundingBox";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import {
    convertQueryResultAndSplitIntoScopedAndUnauthorizedBuses,
    useBusesWithCapacityCostsVariables
} from "contexts/MapViewDataContext/hooks/useBusData";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { useScreeningViewContext } from "contexts/ScreeningViewContext/ScreeningViewContext";
import saveAs from "file-saver";
import { useGetBusesWithCapacityCostLazyQuery } from "generated/graphql";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import {
    ExportColorType,
    getCapacityColorString,
    GREEN_EXPORT_COLOR,
    RED_EXPORT_COLOR,
    YELLOW_EXPORT_COLOR
} from "sharedStyles/capacityColorStyles";
import { BusFilters } from "types/busFilterTypes";
import { encryptBusId, ScopedBus } from "types/busType";
import {
    ComponentConfig,
    isPricedConstraintConfig
} from "types/componentConfigPerRegion";
import { GeneratorType, STORAGE_TYPE } from "types/generatorType";
import {
    getUrlForScreeningViewWithBus,
    ScreeningView
} from "types/screeningViewType";
import greenDot from "./NiraGreenDot.png";
import redDot from "./NiraRedDot.png";
import yellowDot from "./NiraYellowDot.png";

const KML_HEADER = `<?xml version="1.0" encoding="UTF-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2">
    <Document>
`;

const KML_FOOTER = `    </Document>
</kml>
`;

const NIRA_RED_DOT = "NiraRedDot";
const NIRA_YELLOW_DOT = "NiraYellowDot";
const NIRA_GREEN_DOT = "NiraGreenDot";
const ALL_DOT_STYLE_IDS = [
    NIRA_RED_DOT,
    NIRA_YELLOW_DOT,
    NIRA_GREEN_DOT
] as const;
type DotStyleId = (typeof ALL_DOT_STYLE_IDS)[number];

const getPngName = (styleId: DotStyleId) => `${styleId}.png`;

const convertDotStyleIdToDotStyleXml = (
    styleId: DotStyleId
) => `       <Style id="${styleId}">
            <IconStyle>
                <scale>0.5</scale>
                <Icon>
                    <href>${getPngName(styleId)}</href>
                </Icon>
            </IconStyle>
        </Style>
`;

const NIRA_DOT_STYLES = ALL_DOT_STYLE_IDS.map(
    convertDotStyleIdToDotStyleXml
).join("");

const capacityColorToStyleId = (capacityColor: ExportColorType): DotStyleId => {
    switch (capacityColor) {
        case RED_EXPORT_COLOR:
            return NIRA_RED_DOT;
        case YELLOW_EXPORT_COLOR:
            return NIRA_YELLOW_DOT;
        case GREEN_EXPORT_COLOR:
            return NIRA_GREEN_DOT;
    }
};

const styleIdToDotImage = (styleId: DotStyleId) => {
    switch (styleId) {
        case NIRA_RED_DOT:
            return redDot;
        case NIRA_YELLOW_DOT:
            return yellowDot;
        case NIRA_GREEN_DOT:
            return greenDot;
    }
};

const zipDotImage = async (zip: JSZip, styleId: DotStyleId) => {
    const dotImageData = await JSZipUtils.getBinaryContent(
        styleIdToDotImage(styleId)
    );
    zip.file(getPngName(styleId), dotImageData, { binary: true });
};

const saveBlob = (
    blob: Blob,
    screeningViewData: ScreeningView,
    extension: "kmz" | "zip"
) => {
    const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "short",
        year: "numeric"
    };
    const currentDate = new Date().toLocaleDateString("en-US", options);
    const fileName = `${screeningViewData.title} - ${currentDate}.${extension}`;
    saveAs(blob, fileName);
};

const ONE_MILLION = 1_000_000;

enum ExportKeys {
    BUS_NAME = "Bus Name",
    MW_ENERGY = "Energy",
    MW_CAPACITY = "Capacity",
    MW_CHARGING = "Charging",
    ALLOCATED_COST = "Allocated Cost",
    TOTAL_COST = "Total Cost",
    VOLTAGE = "Voltage",
    OWNER = "Owner",
    COORDINATES = "Coordinates",
    URL = "url",
    COLOR = "color"
}

type ExportBusColumn = {
    key: ExportKeys;
    keyForShapefile: string; // necessary due to the 10 char limit for keys in Shapefiles
    renderValue: (scopedBus: ScopedBus) => string;
    hideColumn?: (
        componentConfig: ComponentConfig,
        generator: GeneratorType
    ) => boolean;
};

const EXPORT_BUS_SHARED_COLUMNS: ExportBusColumn[] = [
    {
        key: ExportKeys.BUS_NAME,
        keyForShapefile: "bus_name",
        renderValue: (scopedBus) => `${scopedBus.bus.busDisplayName}`
    },
    {
        key: ExportKeys.MW_ENERGY,
        keyForShapefile: "energy",
        renderValue: (scopedBus) =>
            `${scopedBus.scopedCapacityEnergyCost.energySize}MW`
    },
    {
        key: ExportKeys.MW_CAPACITY,
        keyForShapefile: "capacity",
        renderValue: (scopedBus) =>
            `${scopedBus.scopedCapacityEnergyCost.capacitySize}MW`,
        hideColumn: (componentConfig) =>
            !isPricedConstraintConfig(componentConfig) ||
            !componentConfig.showCapacity
    },
    {
        key: ExportKeys.MW_CHARGING,
        keyForShapefile: "charging",
        renderValue: (scopedBus) =>
            `${scopedBus.scopedCapacityEnergyCost.chargingSize}MW`,
        hideColumn: (_, generator) => generator !== STORAGE_TYPE
    },
    {
        key: ExportKeys.ALLOCATED_COST,
        keyForShapefile: "alloc_cost",
        renderValue: (scopedBus) =>
            `$${(
                scopedBus.scopedCapacityEnergyCost.allocatedCosts / ONE_MILLION
            ).toFixed(1)}M`,
        hideColumn: (componentConfig) =>
            !isPricedConstraintConfig(componentConfig) ||
            !componentConfig.hasAllocatedCost
    },
    {
        key: ExportKeys.TOTAL_COST,
        keyForShapefile: "total_cost",
        renderValue: (scopedBus) =>
            `$${(
                scopedBus.scopedCapacityEnergyCost.totalCosts / ONE_MILLION
            ).toFixed(1)}M`,
        hideColumn: (componentConfig) =>
            !isPricedConstraintConfig(componentConfig)
    },
    {
        key: ExportKeys.VOLTAGE,
        keyForShapefile: "voltage",
        renderValue: (scopedBus) => `${scopedBus.bus.voltage}kV`
    },
    {
        key: ExportKeys.OWNER,
        keyForShapefile: "owner",
        renderValue: (scopedBus) =>
            `${scopedBus.bus.substation.substationOwner}`
    },
    {
        key: ExportKeys.COORDINATES,
        keyForShapefile: "coords",
        renderValue: (scopedBus) =>
            `(${scopedBus.bus.latitude.toFixed(
                3
            )}, ${scopedBus.bus.longitude.toFixed(3)})`
    }
];

const renderNiraLink = (
    scopedBus: ScopedBus,
    screeningViewData: ScreeningView
) => {
    return `<a href="${getUrlForScreeningViewWithBus(
        screeningViewData,
        encryptBusId(scopedBus.bus.id),
        scopedBus.bus.latitude,
        scopedBus.bus.longitude,
        window.location.origin
    )}">Nira Link</a>`;
};

const renderColumn = (key: string, value: string) => {
    return `<div><b>${key}:</b> ${value}</div>`;
};

const exportBusesToKmz = async (
    scopedBuses: readonly ScopedBus[],
    screeningViewData: ScreeningView,
    busFilters: BusFilters,
    generator: GeneratorType,
    componentConfig: ComponentConfig
): Promise<void> => {
    const placemarks = scopedBuses
        .map((scopedBus) => {
            const {
                bus: { latitude, longitude },
                scopedCapacityEnergyCost: {
                    energySize,
                    capacitySize,
                    chargingSize
                }
            } = scopedBus;
            const powerAmounts = { energySize, capacitySize, chargingSize };
            const capacityColor = getCapacityColorString(
                powerAmounts,
                busFilters.capacityThresholds,
                generator
            );

            const columnDivs = EXPORT_BUS_SHARED_COLUMNS.map((column) => {
                const hideColumn =
                    column.hideColumn &&
                    column.hideColumn(componentConfig, generator);

                return hideColumn
                    ? ""
                    : renderColumn(column.key, column.renderValue(scopedBus));
            });
            columnDivs.push(
                `<div>${renderNiraLink(scopedBus, screeningViewData)}</div>`
            );

            const description = `<![CDATA[<html><body bgcolor="white">${columnDivs.join(
                ""
            )}</body></html>]]>`;
            const placemark = `        <Placemark>
            <description>${description}</description>
            <styleUrl>${capacityColorToStyleId(capacityColor)}</styleUrl>
            <Point>
                <coordinates>${longitude},${latitude}</coordinates>
            </Point>
        </Placemark>
`;

            return placemark;
        })
        .join("");
    const kml = KML_HEADER + NIRA_DOT_STYLES + placemarks + KML_FOOTER;

    const zip = new JSZip();
    zip.file(`${screeningViewData.title}.kml`, kml);

    for (const styleId of ALL_DOT_STYLE_IDS) {
        await zipDotImage(zip, styleId);
    }

    const fileContent = await zip.generateAsync({
        type: "blob",
        mimeType: "application/vnd.google-earth.kmz"
    });

    saveBlob(fileContent, screeningViewData, "kmz");
};

const exportBusesToShapefile = async (
    scopedBuses: readonly ScopedBus[],
    screeningViewData: ScreeningView,
    busFilters: BusFilters,
    generator: GeneratorType,
    componentConfig: ComponentConfig
): Promise<void> => {
    const features = scopedBuses.map((scopedBus) => {
        const {
            bus: { latitude, longitude },
            scopedCapacityEnergyCost: { energySize, capacitySize, chargingSize }
        } = scopedBus;
        const powerAmounts = { energySize, capacitySize, chargingSize };
        const capacityColor = getCapacityColorString(
            powerAmounts,
            busFilters.capacityThresholds,
            generator
        );

        const properties = EXPORT_BUS_SHARED_COLUMNS.reduce(
            (propertiesAcc: { [key: string]: string }, column) => {
                if (
                    !column.hideColumn ||
                    !column.hideColumn(componentConfig, generator)
                ) {
                    propertiesAcc[column.keyForShapefile] =
                        column.renderValue(scopedBus);
                }
                return propertiesAcc;
            },
            {}
        );
        properties[ExportKeys.COLOR] = capacityColor;
        properties[ExportKeys.URL] = renderNiraLink(
            scopedBus,
            screeningViewData
        );

        const feature: GeoJSON.Feature = {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [longitude, latitude]
            },
            properties: properties
        };

        return feature;
    });
    const featureCollection: GeoJSON.FeatureCollection = {
        type: "FeatureCollection",
        features: features
    };

    const fileContent = (await shpwrite.zip(featureCollection, {
        outputType: "blob",
        compression: "STORE"
    })) as Blob;

    saveBlob(fileContent, screeningViewData, "zip");
};

export const useExportScreeningView: (
    format: "KMZ" | "SHAPEFILE"
) => () => Promise<void> = (format) => {
    const [getBusDataForScreeningViewExport] =
        useGetBusesWithCapacityCostLazyQuery();
    const maybeScreeningViewData = useScreeningViewContext();
    const exportQueryVariables = useBusesWithCapacityCostsVariables(
        USA_BOUNDING_BOX,
        maybeScreeningViewData?.voltages
    );
    const { generator, componentConfig } = useMapViewRoutingMetadata();
    const {
        busFiltersConfiguration: { busFilters }
    } = useMapViewConfiguration();

    const exportFunc =
        format === "KMZ" ? exportBusesToKmz : exportBusesToShapefile;

    const exportScreeningView = async () => {
        if (!maybeScreeningViewData) {
            throw Error("No screening view defined");
        }

        const queryResult = await getBusDataForScreeningViewExport({
            variables: exportQueryVariables,
            fetchPolicy: "no-cache"
        });

        const [scopedBuses] =
            convertQueryResultAndSplitIntoScopedAndUnauthorizedBuses(
                queryResult
            );

        await exportFunc(
            scopedBuses,
            maybeScreeningViewData,
            busFilters,
            generator,
            componentConfig
        );
    };

    return exportScreeningView;
};

import {
    SubscribeToInQueueScenariosSubscription,
    SubscribeToTrackedProjectsSubscription,
    useSubscribeToInQueueScenariosSubscription,
    useSubscribeToTrackedProjectsSubscription
} from "generated/graphql";
import { loader } from "graphql.macro";
import { getHasuraDataAndConvertToNiraType } from "graphql/helpers/queryHelpers";
import { StudyPhase } from "in_queue/types/clusterType";
import {
    convertHasuraProjectsToProjects,
    getTrackedProjects,
    InQueueSubscription,
    Project,
    TrackedProject
} from "in_queue/types/projectType";
import {
    convertHasuraInQueueScenariosToProjectScenarioMapping,
    ProjectScenarioMapping,
    ScenarioMetadata
} from "in_queue/types/scenarioType";
import { flatten, isEqual } from "lodash";
import { createContext, PropsWithChildren, useContext } from "react";
import { Loading, mapLoadingState, zipLoadingStates } from "types/loadingType";
import { useCluster } from "./ClusterContext";
import { useSubscriptions } from "./InQueueSubscriptionsContext";

interface ProjectDataContextValue {
    allProjects: Loading<Project[]>;
    trackedProjects: Loading<TrackedProject[]>;
    scenarioMapping: Loading<ProjectScenarioMapping>;
}

export const ProjectDataContext = createContext<ProjectDataContextValue>({
    allProjects: "loading",
    trackedProjects: "loading",
    scenarioMapping: "loading"
});

const GET_ALL_PROJECTS = loader("src/graphql/in_queue/getAllProjects.graphql");

export const ProjectDataProvider: React.FC<PropsWithChildren<unknown>> = ({
    children
}: PropsWithChildren<unknown>) => {
    const allProjects = getHasuraDataAndConvertToNiraType(
        GET_ALL_PROJECTS,
        convertHasuraProjectsToProjects
    );

    const hasuraScenarios = useHasuraInQueueScenarios();

    const hasuraTrackedProjects = useHasuraTrackedProjects();
    const subscriptions = useSubscriptions();
    const trackedProjects = getTrackedProjects(
        hasuraTrackedProjects,
        allProjects,
        subscriptions
    );

    const scenarioMapping = mapLoadingState(hasuraScenarios, (scenarios) =>
        convertHasuraInQueueScenariosToProjectScenarioMapping(
            scenarios,
            trackedProjects
        )
    );

    const value = {
        allProjects,
        trackedProjects,
        scenarioMapping
    };
    return (
        <ProjectDataContext.Provider value={value}>
            {children}
        </ProjectDataContext.Provider>
    );
};

const useHasuraInQueueScenarios =
    (): Loading<SubscribeToInQueueScenariosSubscription> => {
        // Note: Here, "Subscription" refers to Hasura's notion of a subscription, not ours
        const hasuraScenariosSubscription =
            useSubscribeToInQueueScenariosSubscription();
        if (
            hasuraScenariosSubscription.loading ||
            !hasuraScenariosSubscription.data
        ) {
            return "loading";
        }
        return hasuraScenariosSubscription.data;
    };

const useHasuraTrackedProjects =
    (): Loading<SubscribeToTrackedProjectsSubscription> => {
        // Note: Here, "Subscription" refers to Hasura's notion of a subscription, not ours
        const hasuraTrackedProjectsSubscription =
            useSubscribeToTrackedProjectsSubscription();
        if (
            hasuraTrackedProjectsSubscription.loading ||
            !hasuraTrackedProjectsSubscription.data
        ) {
            return "loading";
        }
        return hasuraTrackedProjectsSubscription.data;
    };

export const useAllProjectsInCluster = (): Loading<Project[]> => {
    const allProjects = useContext(ProjectDataContext).allProjects;
    const cluster = useCluster();

    if (allProjects === "loading") {
        return "loading";
    }

    return allProjects.filter((p: Project) => isEqual(p.cluster, cluster));
};

export const useOtherProjectsInCluster = (): Loading<Project[]> => {
    const allProjects = useAllProjectsInCluster();
    const trackedProjects = useTrackedProjectsInCluster();

    return zipLoadingStates(
        allProjects,
        trackedProjects,
        (projects, tracked) => {
            const trackedProjectIds = new Set(
                tracked.map((project) => project.projectId)
            );
            return projects.filter(
                (project) => !trackedProjectIds.has(project.projectId)
            );
        }
    );
};

export const useSubscriptionForCluster = (): Loading<
    InQueueSubscription | undefined
> => {
    const subscriptions = useSubscriptions();
    const cluster = useCluster();

    return mapLoadingState(subscriptions, (subscriptions) =>
        subscriptions.find((sub) => isEqual(sub.cluster, cluster))
    );
};

export const useProject = ({
    projectId
}: {
    projectId: string | undefined;
}): Loading<Project | undefined> => {
    const allProjects = useAllProjectsInCluster();
    return mapLoadingState(allProjects, (projects) =>
        projects.find((project) => project.projectId === projectId)
    );
};

export const useTrackedProjects = (): Loading<TrackedProject[]> => {
    const context = useContext(ProjectDataContext);
    return context.trackedProjects;
};

export const useTrackedProjectsInCluster = (): Loading<TrackedProject[]> => {
    const trackedProjects = useTrackedProjects();
    const cluster = useCluster();

    return mapLoadingState(trackedProjects, (projects) => {
        return projects.filter((project) => isEqual(project.cluster, cluster));
    });
};

export const useTrackedProject = ({
    projectId,
    phase
}: {
    projectId: string | undefined;
    phase: StudyPhase;
}): Loading<TrackedProject | undefined> => {
    const trackedProjects = useTrackedProjects();
    return mapLoadingState(trackedProjects, (projects) =>
        projects.find(
            (project) =>
                project.projectId === projectId &&
                project.cluster.studyPhase === phase
        )
    );
};

export const useProjectScenarioMapping =
    (): Loading<ProjectScenarioMapping> => {
        const context = useContext(ProjectDataContext);
        return context.scenarioMapping;
    };

export const useProjectScenarios = ({
    projectId,
    phase
}: {
    projectId: string | undefined;
    phase: StudyPhase;
}): Loading<ScenarioMetadata[]> => {
    const scenarioMapping = useProjectScenarioMapping();
    return mapLoadingState(scenarioMapping, (mapping) =>
        projectId && mapping[projectId] && mapping[projectId][phase]
            ? mapping[projectId][phase]
            : []
    );
};

export const useScenarioMetadata = (
    projectId: string | undefined,
    scenarioId: string | undefined
): Loading<ScenarioMetadata | undefined> => {
    const projectScenarioMapping = useProjectScenarioMapping();

    if (projectId === undefined || scenarioId === undefined) {
        return undefined;
    }
    return mapLoadingState(projectScenarioMapping, (projectScenarioMapping) => {
        const phaseToScenarioMapping = projectScenarioMapping[projectId];
        const allProjectScenarios = flatten(
            Object.values(phaseToScenarioMapping)
        );

        return allProjectScenarios.find(
            (s: ScenarioMetadata) => s.scenarioId === scenarioId
        );
    });
};

export const useScenariosInCluster = (): Loading<ScenarioMetadata[]> => {
    const scenarioMapping = useProjectScenarioMapping();
    const trackedProjects = useTrackedProjectsInCluster();
    const cluster = useCluster();

    return zipLoadingStates(
        scenarioMapping,
        trackedProjects,
        (
            scenarioMapping: ProjectScenarioMapping,
            trackedProjects: TrackedProject[]
        ) => {
            const scenarios: { [scenarioId: string]: ScenarioMetadata } = {};

            trackedProjects.forEach((tp: TrackedProject) => {
                if (
                    scenarioMapping[tp.projectId] &&
                    scenarioMapping[tp.projectId][cluster.studyPhase]
                ) {
                    scenarioMapping[tp.projectId][cluster.studyPhase].forEach(
                        (scenario: ScenarioMetadata) => {
                            scenarios[scenario.scenarioId] = scenario;
                        }
                    );
                }
            });

            return Object.values(scenarios);
        }
    );
};

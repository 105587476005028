import useMapViewData from "contexts/MapViewDataContext/hooks/useMapViewData";
import React from "react";
import BusItemList from "./BusItemList";
import "./SidebarResultsWrapper.scss";

const SidebarResultsWrapper: React.FC = () => {
    const { scopedBuses } = useMapViewData();

    return (
        <div className="SidebarResultsWrapper-wrapper">
            <h6 className="bp5-heading SidebarResultsWrapper-heading">
                {scopedBuses.length} bus
                {scopedBuses.length !== 1 ? "es" : ""} in map area
            </h6>
            <BusItemList />
        </div>
    );
};

export default SidebarResultsWrapper;

import React from "react";
import "./BusCapacityDot.scss";

type BusCapacityDotProps = {
    readonly color: string;
    readonly small?: boolean;
};

const BusCapacityDot: React.FC<BusCapacityDotProps> = (props) => {
    const { color, small } = props;

    return (
        <div
            className={`BusCapacityDot-icon ${
                small ? "BusCapacityDot-small" : ""
            }`}
            style={{ borderColor: color }}
        />
    );
};

export default BusCapacityDot;

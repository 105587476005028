import { useContext } from "react";
import {
    SelectedBusesContext,
    SelectedBusesContextProps
} from "./SelectedBusesContext";

const useSelectedBuses = (): SelectedBusesContextProps => {
    const context = useContext(SelectedBusesContext);
    if (!context) {
        throw new Error(
            "useSelectedBuses must be used within a SelectedBusesProvider"
        );
    }

    return context;
};

export default useSelectedBuses;

import { getResourceTypeLabel, LabelStyleType } from "components/common/labels";
import saveAs from "file-saver";
import { json2csvAsync } from "json-2-csv";
import { Constraint } from "types/constraintType";
import { LOAD_TYPE } from "types/generatorType";
import { LineTapConstraint } from "types/lineConstraintType";
import { Loading } from "types/loadingType";
import {
    CHARGING_RESOURCE_TYPE,
    ENERGY_RESOURCE_TYPE
} from "types/resourceType";
import { PowerAmounts } from "../BusDetailPane";
import {
    getAllocatedCostForConstraint,
    isConstraintWithinSize
} from "../constraintsHelpers";
import { ConstraintTableConfig } from "./ConstraintsDetailsDialogTable";
/**
 * All the information that can be used to render a cell value in the constraint table.
 */

export interface RenderConstraintTableValueParams
    extends RenderConstraintTableValueConfig {
    constraint: Constraint | LineTapConstraint;
    withinSize: boolean;
}
export interface RenderConstraintTableValueConfig {
    powerAmounts: PowerAmounts;
    labelStyle: LabelStyleType;
    constraintTableConfig: ConstraintTableConfig;
}
/**
 * Represents a single column in the constraint table, including its header label,
 * how it should be rendered, and whether it should be hidden based on the config.
 */
interface ConstraintTableColumn {
    getHeader: (config: ConstraintTableConfig) => string;
    renderValue: (params: RenderConstraintTableValueParams) => string;
    hideColumn?: (config: ConstraintTableConfig) => boolean;
    ellipsizedColumn: boolean;
}
interface ConfiguredConstraintTableColumn extends ConstraintTableColumn {
    header: string;
}
export const CONSTRAINT_TABLE_COLUMNS: ConstraintTableColumn[] = [
    {
        getHeader: () => "Monitored facility",
        renderValue: ({ constraint }) => constraint.monitoredFacilityName,
        ellipsizedColumn: false
    },
    {
        getHeader: () => "Allocated cost",
        renderValue: ({ constraint, powerAmounts, withinSize }) =>
            withinSize
                ? formatCostString(
                      getAllocatedCostForConstraint(constraint, powerAmounts)
                  )
                : "-",
        hideColumn: (config) => config.hideAllocatedCosts,
        ellipsizedColumn: false
    },
    {
        getHeader: () => "Estimated cost",
        renderValue: ({ constraint }) =>
            formatCostString(constraint.estimatedCost),
        hideColumn: (config) => config.hideTotalCosts,
        ellipsizedColumn: false
    },
    {
        getHeader: () => "Model",
        renderValue: ({ constraint, constraintTableConfig }) =>
            constraintTableConfig.maybeGetModelName !== undefined
                ? constraintTableConfig.maybeGetModelName(constraint)
                : "",
        hideColumn: (config) => config.maybeGetModelName === undefined,
        ellipsizedColumn: false
    },
    {
        getHeader: (config) =>
            config.generatorType === LOAD_TYPE
                ? "Threshold"
                : "Injection threshold",
        renderValue: ({ constraint }) =>
            `${constraint.injectionThreshold.toFixed(1)} MW`,
        ellipsizedColumn: false
    },
    {
        getHeader: () => "Resource type",
        renderValue: ({ constraint, labelStyle }) =>
            getResourceTypeLabel(constraint.resourceType, labelStyle),
        ellipsizedColumn: false,
        hideColumn: (config) => config.generatorType === LOAD_TYPE
    },
    {
        getHeader: () => "Existing load",
        renderValue: ({ constraint }) =>
            formatPercentString(constraint.preStudyLoad),
        ellipsizedColumn: false
    },
    {
        getHeader: () => "New load",
        renderValue: ({ constraint, powerAmounts }) =>
            formatPercentString(getPostStudyLoad(constraint, powerAmounts)),
        hideColumn: (config) => !config.showPostStudyLoad,
        ellipsizedColumn: false
    },
    {
        getHeader: () => "Element type",
        renderValue: ({ constraint }) => constraint.elementType,
        ellipsizedColumn: false
    },
    {
        getHeader: () => "Line length",
        renderValue: ({ constraint }) =>
            constraint.lineLength === undefined
                ? "-"
                : `${constraint.lineLength.toFixed(1)} miles`,
        ellipsizedColumn: false
    },
    {
        getHeader: () => "Dfax",
        renderValue: ({ constraint }) =>
            `${(constraint.dfax * 100).toFixed(2)}%`,
        ellipsizedColumn: false
    },
    {
        getHeader: () => "Rating (MVA)",
        renderValue: ({ constraint }) => constraint.rating.toFixed(0),
        ellipsizedColumn: false
    },
    {
        getHeader: () => "Contingency",
        renderValue: ({ constraint }) => constraint.contingency,
        hideColumn: (config) => !config.showContingency,
        ellipsizedColumn: true
    }
];
/**
 * Given a ConstraintTableConfig and list of columns, filters down the list of columns
 * to only those that should be shown based on the config.
 */
export const getConfiguredColumns = (
    columns: ConstraintTableColumn[],
    config: ConstraintTableConfig
): ConfiguredConstraintTableColumn[] => {
    return columns
        .filter(
            (column) =>
                column.hideColumn === undefined || !column.hideColumn(config)
        )
        .map((column) => {
            return {
                ...column,
                header: column.getHeader(config)
            };
        });
};

const formatCostString = (cost: number | undefined): string => {
    if (cost === undefined) {
        return "N/A";
    }
    return `${`$${(cost / 1000000).toFixed(1)}M`}`;
};

const formatPercentString = (decimal: number): string => {
    return `${(decimal * 100).toFixed(0)}%`;
};

const getPostStudyLoad = (
    constraint: Constraint | LineTapConstraint,
    powerAmounts: PowerAmounts
) => {
    const injection_to_use =
        constraint.resourceType === ENERGY_RESOURCE_TYPE
            ? powerAmounts.energySize
            : constraint.resourceType === CHARGING_RESOURCE_TYPE
            ? powerAmounts.chargingSize
            : powerAmounts.capacitySize;

    return (
        (injection_to_use * constraint.dfax) / constraint.rating +
        constraint.preStudyLoad
    );
};

/**
 * Given a ConstraintTableConfig, returns a list of header labels that should be shown for the table.
 */
export const getConstraintsTableHeader = (
    config: ConstraintTableConfig
): string[] => {
    return getConfiguredColumns(CONSTRAINT_TABLE_COLUMNS, config).map(
        (column) => column.header
    );
};

export const getExportConstraintsToCSV = (
    displayName: string,
    maybeConstraints: Loading<ReadonlyArray<Constraint | LineTapConstraint>>,
    config: RenderConstraintTableValueConfig
): (() => Promise<void>) => {
    if (maybeConstraints === "loading") {
        return () => Promise.resolve();
    }
    const sortedConstraints = [...maybeConstraints].sort(
        (a, b) => a.injectionThreshold - b.injectionThreshold
    );
    const exportObjects = constraintToExportObject(sortedConstraints, config);

    return async () => {
        const csvString = await json2csvAsync(exportObjects, {
            checkSchemaDifferences: true,
            emptyFieldValue: ""
        });

        // Create a Blob and generate a URL for the CSV data
        const blob = new Blob([csvString], { type: "text/csv" });

        saveAs(blob, `Nira Constraints - ${displayName}.csv`);
    };
};

const constraintToExportObject = (
    constraints: (Constraint | LineTapConstraint)[],
    config: RenderConstraintTableValueConfig
): { [header: string]: string }[] => {
    const configuredColumns = getConfiguredColumns(
        CONSTRAINT_TABLE_COLUMNS,
        config.constraintTableConfig
    );
    return constraints.map((constraint) => {
        const result: { [header: string]: string } = {};
        const withinSize = isConstraintWithinSize(
            constraint,
            config.powerAmounts
        );
        configuredColumns.forEach((column) => {
            result[column.header] = column.renderValue({
                ...config,
                constraint,
                withinSize
            });
        });
        return result;
    });
};

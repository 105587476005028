import { datadogRum } from "@datadog/browser-rum";
import {
    isInCreateReactAppProductionMode,
    onGitProductionBranch,
    onGitStagingBranch
} from "config/envVariables";
import { useEffect } from "react";

/**
 * Initializes Datadog logging.
 */
export const DataDogProvider: React.FC = ({ children }) => {
    // Set up telemetry
    useSetupDatadog();

    return <>{children}</>;
};

const DATADOG_PRODUCTION_ENV = "production";
const DATADOG_STAGING_ENV = "staging";

const useSetupDatadog = (): void => {
    useEffect(() => {
        if (isInCreateReactAppProductionMode()) {
            let env;
            if (onGitProductionBranch()) {
                env = DATADOG_PRODUCTION_ENV;
            } else if (onGitStagingBranch()) {
                env = DATADOG_STAGING_ENV;
            }

            datadogRum.init({
                applicationId: "5f1440b1-d7ab-4716-a323-59b27cbdfea5",
                clientToken: "pub9d13a9ee4906f432f908e363d5b6730e",
                site: "datadoghq.com",
                service: "nira-app",
                env,
                // version: '1.0.0',
                sampleRate: 100,
                trackInteractions: true
            });
        }
    }, []);
};

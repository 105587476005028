import { Tag } from "@blueprintjs/core";
import { segmentTrackCustomInjectionDialogOpened } from "analytics/analyticTrackEvents";
import React from "react";
import { BusId } from "types/busType";
import { ResourceType } from "types/resourceType";
import { FacilityId } from "types/selectedFacility";
import { getPillIntent, getSizeLabel, LabelStyleType } from "./labels";

import "./AvailableTag.scss";

type AvailableTagProps = {
    readonly size: number;
    readonly editedSize?: number;
    readonly onClick?: () => void;
    readonly selectedId?: BusId | FacilityId;
    readonly resourceType: ResourceType;
    readonly labelStyle: LabelStyleType;
    readonly minimal?: boolean;
};

const AvailableTag: React.FC<AvailableTagProps> = (props) => {
    const {
        size,
        editedSize,
        onClick,
        selectedId,
        resourceType,
        labelStyle,
        minimal
    } = props;

    let pillOnClick;
    if (onClick && selectedId) {
        pillOnClick = () => {
            segmentTrackCustomInjectionDialogOpened(selectedId);
            onClick();
        };
    }
    return (
        <Tag
            round={true}
            minimal={minimal}
            intent={getPillIntent(resourceType, labelStyle)}
            interactive={pillOnClick !== undefined}
            onClick={pillOnClick}
        >
            <span
                className={
                    size !== editedSize && editedSize !== undefined
                        ? "AvailableTag-default-crossed"
                        : ""
                }
            >
                {size.toFixed(0)}
            </span>
            {size !== editedSize && editedSize !== undefined && (
                <span>{` → ${editedSize.toFixed(0)}`}</span>
            )}{" "}
            {getSizeLabel(resourceType, labelStyle)}
        </Tag>
    );
};

export default AvailableTag;

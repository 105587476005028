import "./CurtailmentConstraintDetailsDialogTable.scss";

import { Checkbox } from "@blueprintjs/core";
import { getLabelStyle } from "components/common/labels";
import { useMapViewRoutingMetadata } from "contexts/RoutingMetadataContext";
import { convertToCurtailmentConfig } from "types/componentConfigPerRegion";
import { Constraint } from "types/constraintType";
import { STORAGE_TYPE } from "types/generatorType";
import { LineTapConstraint } from "types/lineConstraintType";
import { Loading } from "types/loadingType";
import {
    CHARGING_RESOURCE_TYPE,
    ENERGY_RESOURCE_TYPE
} from "types/resourceType";
import { PowerAmounts } from "../BusDetailPane";
import ConstraintDetailsDialogTable, {
    ConstraintTableConfig
} from "./ConstraintsDetailsDialogTable";
import {
    ActivatedScopeViewsType,
    EnergyOrChargingResourceType,
    LOW_LOAD,
    ModelType,
    MODEL_TYPE_TO_MODEL_LABELS,
    SUMMER_PEAK
} from "./curtailmentConstraintsHelpers";

type CurtailmentConstraintDetailsDialogTableProps = {
    readonly constraintTableConfig: ConstraintTableConfig;
    readonly maybeFilteredDownConstraints: Loading<
        ReadonlyArray<Constraint | LineTapConstraint>
    >;
    readonly activatedScopeViews: ActivatedScopeViewsType;
    readonly setActivatedScopeViews: (
        newScopeViews: ActivatedScopeViewsType
    ) => void;
    readonly powerAmounts: PowerAmounts;
};

const CurtailmentConstraintDetailsDialogTable: React.FC<
    CurtailmentConstraintDetailsDialogTableProps
> = (props: CurtailmentConstraintDetailsDialogTableProps) => {
    const {
        constraintTableConfig,
        maybeFilteredDownConstraints,
        activatedScopeViews,
        setActivatedScopeViews,
        powerAmounts
    } = props;
    const { generator: generatorType, componentConfig } =
        useMapViewRoutingMetadata();

    const curtailmentConfig = convertToCurtailmentConfig(componentConfig);

    return (
        <div>
            {curtailmentConfig.showModelSwitcherConstraintTable && (
                <div className="CurtailmentConstraintDetailsDialogTable-checkbox-section">
                    {getCheckboxRowForResourceType(
                        "Discharging:",
                        ENERGY_RESOURCE_TYPE,
                        activatedScopeViews,
                        setActivatedScopeViews
                    )}
                    {generatorType === STORAGE_TYPE &&
                        getCheckboxRowForResourceType(
                            "Charging:",
                            CHARGING_RESOURCE_TYPE,
                            activatedScopeViews,
                            setActivatedScopeViews
                        )}
                </div>
            )}
            <ConstraintDetailsDialogTable
                constraints={maybeFilteredDownConstraints}
                powerAmounts={powerAmounts}
                showAllConstraints={true}
                showCapacityLabel={false}
                showChargingLabel={false}
                labelStyle={getLabelStyle(componentConfig, generatorType)}
                constraintTableConfig={constraintTableConfig}
            />
        </div>
    );
};

const getCheckboxRowForResourceType = (
    rowLabel: string,
    resourceType: EnergyOrChargingResourceType,
    activatedScopeViews: ActivatedScopeViewsType,
    setActivatedScopeViews: (
        newActivatedScopeViews: ActivatedScopeViewsType
    ) => void
) => {
    return (
        <div className="CurtailmentConstraintDetailsDialogTable-checkbox-row">
            <h6 className="bp5-heading CurtailmentConstraintDetailsDialogTable-checkbox-row-header">
                {rowLabel}
            </h6>
            {getCheckboxForResourceTypeAndModelType(
                resourceType,
                activatedScopeViews,
                setActivatedScopeViews,
                SUMMER_PEAK
            )}
            {getCheckboxForResourceTypeAndModelType(
                resourceType,
                activatedScopeViews,
                setActivatedScopeViews,
                LOW_LOAD
            )}
        </div>
    );
};

const getCheckboxForResourceTypeAndModelType = (
    resourceType: EnergyOrChargingResourceType,
    activatedScopeViews: ActivatedScopeViewsType,
    setActivatedScopeViews: (
        newActivatedScopeViews: ActivatedScopeViewsType
    ) => void,
    modelType: ModelType
) => {
    return (
        <Checkbox
            label={MODEL_TYPE_TO_MODEL_LABELS[modelType]}
            inline={true}
            checked={activatedScopeViews[resourceType][modelType]}
            onChange={(onChangeEvent) => {
                if (onChangeEvent.currentTarget.checked) {
                    setActivatedScopeViews({
                        ...activatedScopeViews,
                        [resourceType]: {
                            ...activatedScopeViews[resourceType],
                            [modelType]: true
                        }
                    });
                } else {
                    setActivatedScopeViews({
                        ...activatedScopeViews,
                        [resourceType]: {
                            ...activatedScopeViews[resourceType],
                            [modelType]: false
                        }
                    });
                }
            }}
        />
    );
};

export default CurtailmentConstraintDetailsDialogTable;

import { InputGroup } from "@blueprintjs/core";
import FilterWrapper from "../filters/FilterWrapper";

type ScreeningViewTitleProps = {
    readonly title: string;
    readonly setTitle: (newTitle: string) => void;
};

const ScreeningViewTitle: React.FC<ScreeningViewTitleProps> = (props) => {
    const { title, setTitle } = props;

    return (
        <FilterWrapper label={"Title"}>
            <InputGroup
                value={title}
                fill={true}
                placeholder="Enter a title..."
                onValueChange={(newTitle) => setTitle(newTitle)}
            />
        </FilterWrapper>
    );
};

export default ScreeningViewTitle;

import { Switch } from "@blueprintjs/core";
import { segmentTrackHideLockedBusesChanged } from "analytics/analyticTrackEvents";
import useMapViewConfiguration from "contexts/MapViewConfigurationContext/hooks/useMapViewConfiguration";
import FilterWrapper from "./FilterWrapper";

const UnlockedBusesFilter: React.FC = () => {
    const {
        busFiltersConfiguration: {
            busFilters: { hideLockedBuses },
            toggleHideLockedBuses,
            filtersDisabled
        }
    } = useMapViewConfiguration();

    return (
        <FilterWrapper
            label={"Hide locked buses"}
            helperText={
                'Toggle to "Yes" to remove buses that are locked. ' +
                "Use this if you want to avoid using up buses in your subscription."
            }
        >
            <Switch
                checked={hideLockedBuses}
                onChange={(evt) => {
                    toggleHideLockedBuses();
                    segmentTrackHideLockedBusesChanged(evt.target.checked);
                }}
                innerLabel="No"
                innerLabelChecked="Yes"
                style={{ marginBottom: 0 }}
                alignIndicator="right"
                large={true}
                disabled={filtersDisabled}
            />
        </FilterWrapper>
    );
};

export default UnlockedBusesFilter;

import {
    AnchorButton,
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    Divider,
    Position,
    Tab,
    Tabs,
    Tooltip
} from "@blueprintjs/core";
import { IN_QUEUE_PATH } from "infrastructure/RouterApp";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GeneratorType } from "types/generatorType";
import { RegionType } from "types/regionType";

import { getUrlForScreeningView, ScreeningView } from "types/screeningViewType";
import { REGION_TO_FUEL_TYPE_TO_URL_DICT } from "types/urlDirectoryType";
import "./RegionSelectorDialog.scss";
import RegionSelectorTab from "./RegionSelectorTab";
import ScreeningViewSelectorTab from "./ScreeningViewSelectorTab";

type RegionAndGeneratorType = {
    readonly region: RegionType;
    readonly generatorType: GeneratorType;
};

type RegionSelectorDialogProps = {
    readonly isOpen: boolean;
    readonly closeDialog?: () => void;
    readonly currentRegionAndGeneratorType?: RegionAndGeneratorType;
    readonly currentScreeningView?: ScreeningView;
};

const REGIONS_TAB_ID = "regions-tab";
const SCREENING_VIEWS_TAB_ID = "screening-views-tab";

const TAB_TYPES = [REGIONS_TAB_ID, SCREENING_VIEWS_TAB_ID] as const;
type TabType = (typeof TAB_TYPES)[number];

const RegionSelectorDialog: React.FC<RegionSelectorDialogProps> = (props) => {
    const {
        isOpen,
        closeDialog,
        currentRegionAndGeneratorType,
        currentScreeningView
    } = props;
    const navigate = useNavigate();

    const [selectedTab, setSelectedTab] = useState<TabType>(
        getDefaultTab(currentRegionAndGeneratorType, currentScreeningView)
    );

    const [stagingRegionType, setStagingRegionType] = useState<
        RegionType | undefined
    >(currentRegionAndGeneratorType?.region);

    const [stagingGeneratorType, setStagingGeneratorType] = useState<
        GeneratorType | undefined
    >(currentRegionAndGeneratorType?.generatorType);

    const [stagingScreeningView, setStagingScreeningView] = useState<
        ScreeningView | undefined
    >(currentScreeningView);

    const onHomePage = currentRegionAndGeneratorType === undefined;
    const canSubmit =
        (selectedTab === REGIONS_TAB_ID &&
            stagingGeneratorType !== undefined &&
            stagingRegionType !== undefined) ||
        (selectedTab === SCREENING_VIEWS_TAB_ID &&
            stagingScreeningView !== undefined);

    const dialogTitle = (
        <div className="RegionSelectorDialog-title">
            <Tabs
                selectedTabId={selectedTab}
                onChange={(tabId: TabType) => setSelectedTab(tabId)}
            >
                <Tab title={"Screening views"} id={SCREENING_VIEWS_TAB_ID} />
                <Tab title={"Regions"} id={REGIONS_TAB_ID} />
            </Tabs>
            <Link to={`/${IN_QUEUE_PATH}`}>
                <Button
                    minimal
                    small
                    icon="folder-shared-open"
                    text="In-queue"
                />
            </Link>
        </div>
    );

    return (
        <Dialog
            className={"RegionSelectorDialog-wrapper"}
            isOpen={isOpen}
            title={dialogTitle}
            onClose={() => {
                tryClose({
                    closeDialog
                });
            }}
            icon="globe"
            isCloseButtonShown={!onHomePage}
            canOutsideClickClose={!onHomePage}
            canEscapeKeyClose={!onHomePage}
        >
            <DialogBody className="RegionSelectorDialog-body">
                {selectedTab === SCREENING_VIEWS_TAB_ID ? (
                    <ScreeningViewSelectorTab
                        screeningView={stagingScreeningView}
                        setScreeningView={setStagingScreeningView}
                    />
                ) : (
                    <RegionSelectorTab
                        stagingRegionType={stagingRegionType}
                        stagingGeneratorType={stagingGeneratorType}
                        setStagingRegionType={setStagingRegionType}
                        setStagingGeneratorType={setStagingGeneratorType}
                    />
                )}
            </DialogBody>
            <DialogFooter
                actions={
                    <div>
                        {!onHomePage && (
                            <AnchorButton
                                onClick={() => {
                                    tryClose({
                                        closeDialog
                                    });
                                }}
                            >
                                Cancel
                            </AnchorButton>
                        )}
                        <Tooltip
                            content={
                                selectedTab === REGIONS_TAB_ID
                                    ? "Select a region and fuel type."
                                    : "Select a screening view."
                            }
                            position={Position.TOP_RIGHT}
                            disabled={canSubmit}
                        >
                            <AnchorButton
                                intent="primary"
                                onClick={() => {
                                    const maybeUrl = maybeGetUrl(
                                        selectedTab,
                                        stagingRegionType,
                                        stagingGeneratorType,
                                        stagingScreeningView
                                    );
                                    if (maybeUrl) {
                                        navigate(maybeUrl.toLowerCase());
                                    }
                                }}
                                disabled={!canSubmit}
                            >
                                Confirm
                            </AnchorButton>
                        </Tooltip>
                    </div>
                }
            >
                {
                    <Tooltip
                        content={
                            <div className="RegionSelectorDialog-learn-more-wrapper bp5-dark">
                                <strong>
                                    Select "Screening views" if you're a
                                    developer doing early-stage screening for
                                    prospecting.
                                </strong>
                                <br />
                                <br />
                                Screening views are simplified views of Nira,
                                created by transmission engineers at your
                                company. Check with your transmission team on
                                which views to use.
                                <br />
                                <br />
                                <Divider />
                                <br />
                                <strong>
                                    Select "Regions" if you're a transmission
                                    expert and are doing detailed research on
                                    specific POIs.
                                </strong>
                                <br />
                                <br />
                                These views contain the full functionality of
                                Nira's prospecting tools. You're able to view
                                constraint stacks, edit filters, tweak project
                                sizes, etc.
                            </div>
                        }
                        position="top-left"
                    >
                        <a>Learn more</a>
                    </Tooltip>
                }
            </DialogFooter>
        </Dialog>
    );
};

const maybeGetUrl = (
    selectedTab: TabType,
    stagingRegionType: RegionType | undefined,
    stagingGeneratorType: GeneratorType | undefined,
    stagingScreeningView: ScreeningView | undefined
): string | undefined => {
    let maybeUrl: string | undefined;
    if (selectedTab === REGIONS_TAB_ID) {
        if (
            stagingRegionType !== undefined &&
            stagingGeneratorType !== undefined
        ) {
            const urlOrNull =
                REGION_TO_FUEL_TYPE_TO_URL_DICT[stagingRegionType][
                    stagingGeneratorType
                ];
            if (urlOrNull) {
                maybeUrl = `/${urlOrNull}`;
            }
        }
    } else if (selectedTab === SCREENING_VIEWS_TAB_ID) {
        if (stagingScreeningView !== undefined) {
            maybeUrl = getUrlForScreeningView(stagingScreeningView);
        }
    }

    return maybeUrl;
};

const tryClose = ({ closeDialog }: { closeDialog?: () => void }) => {
    if (closeDialog) {
        closeDialog();
    }
};

const getDefaultTab = (
    currentRegionAndGeneratorType: RegionAndGeneratorType | undefined,
    currentScreeningView: ScreeningView | undefined
): TabType => {
    if (
        currentRegionAndGeneratorType === undefined &&
        currentScreeningView === undefined
    ) {
        // If on home page, default to screening views. We could potentially change this depending on the users roles.
        return SCREENING_VIEWS_TAB_ID;
    } else if (currentScreeningView !== undefined) {
        return SCREENING_VIEWS_TAB_ID;
    } else {
        return REGIONS_TAB_ID;
    }
};

export default RegionSelectorDialog;

import {
    isInCreateReactAppProductionMode,
    onGitProductionBranch,
    onGitStagingBranch
} from "config/envVariables";

import { NiraAuth0UserType } from "types/auth0UserType";

export const page = (): void => {
    if (shouldSendMetrics()) {
        window.analytics.page();
    }
};

export const track = (
    name: string,
    properties: Record<
        string,
        number | boolean | string | ReadonlyArray<number>
    >
): void => {
    if (shouldSendMetrics()) {
        window.analytics.track(name, properties);
    }
};

export const identify = (
    user: NiraAuth0UserType,
    callback: () => void
): void => {
    if (shouldSendMetrics()) {
        if (user.sub !== undefined && user.email !== undefined) {
            const segmentId = `${user.email}_${user.sub}`;
            window.analytics.identify(
                segmentId,
                {
                    email: user.email,
                    weekUserCreated: getMondayOfWeekCreated(user)
                },
                callback
            );
        }
    }
};

const shouldSendMetrics = (): boolean => {
    return (
        isInCreateReactAppProductionMode() &&
        (onGitProductionBranch() || onGitStagingBranch())
    );
};

const getMondayOfWeekCreated = (user: NiraAuth0UserType): string => {
    const mondayOfWeekCreated = new Date(user["https://nira"].created_at);
    const dayOfWeek = mondayOfWeekCreated.getDay() || 7; // Get current day number, converting Sun. to 7
    if (dayOfWeek !== 1) {
        // Only manipulate the date if it isn't Mon.
        mondayOfWeekCreated.setHours(-24 * (dayOfWeek - 1)); // Set the hours to day number minus 1 multiplied by negative 24
    }
    return mondayOfWeekCreated.toLocaleDateString("en-ZA", {
        day: "numeric",
        month: "numeric",
        year: "numeric"
    });
};

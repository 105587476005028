import { PowerAmounts } from "components/detailPane/BusDetailPane/BusDetailPane";
import { CapacityThresholds } from "types/busFilterTypes";
import {
    GeneratorType,
    LOAD_TYPE,
    SOLAR_TYPE,
    STORAGE_TYPE,
    WIND_TYPE
} from "types/generatorType";

// These colors come from Blueprint 3
export const HIGH_AVAILABLE_CAPACITY_COLOR = "#3DCC91";
export const MED_AVAILABLE_CAPACITY_COLOR = "#FFB366";
export const LOW_AVAILABLE_CAPACITY_COLOR = "#FF7373";
export const GREY_COLOR = "#8A9BA8";

const CAPACITY_COLOR_TYPE = [
    HIGH_AVAILABLE_CAPACITY_COLOR,
    MED_AVAILABLE_CAPACITY_COLOR,
    LOW_AVAILABLE_CAPACITY_COLOR
] as const;
type CapacityColorType = (typeof CAPACITY_COLOR_TYPE)[number];

export const RED_EXPORT_COLOR = "RED";
export const YELLOW_EXPORT_COLOR = "YELLOW";
export const GREEN_EXPORT_COLOR = "GREEN";
const EXPORT_COLOR_TYPE = [
    RED_EXPORT_COLOR,
    YELLOW_EXPORT_COLOR,
    GREEN_EXPORT_COLOR
] as const;
export type ExportColorType = (typeof EXPORT_COLOR_TYPE)[number];

type ColorHexAndString = {
    colorHex: CapacityColorType;
    colorString: ExportColorType;
};

const RED_COLOR_HEX_AND_STRING: ColorHexAndString = {
    colorHex: LOW_AVAILABLE_CAPACITY_COLOR,
    colorString: RED_EXPORT_COLOR
};
const YELLOW_COLOR_HEX_AND_STRING: ColorHexAndString = {
    colorHex: MED_AVAILABLE_CAPACITY_COLOR,
    colorString: YELLOW_EXPORT_COLOR
};
const GREEN_COLOR_HEX_AND_STRING: ColorHexAndString = {
    colorHex: HIGH_AVAILABLE_CAPACITY_COLOR,
    colorString: GREEN_EXPORT_COLOR
};

type SizeCalculator = (powerAmounts: PowerAmounts) => number;

const DEFAULT_SIZE_CALCULATOR = (powerAmounts: PowerAmounts): number => {
    return powerAmounts.energySize;
};

const STORAGE_SIZE_CALCULATOR = (powerAmounts: PowerAmounts): number => {
    return Math.min(powerAmounts.energySize, powerAmounts.chargingSize);
};

export const GENERATOR_TYPE_TO_AVAILABILITY_SIZE_TO_USE: {
    [key in GeneratorType]: SizeCalculator;
} = {
    [SOLAR_TYPE]: DEFAULT_SIZE_CALCULATOR,
    [WIND_TYPE]: DEFAULT_SIZE_CALCULATOR,
    [STORAGE_TYPE]: STORAGE_SIZE_CALCULATOR,
    [LOAD_TYPE]: DEFAULT_SIZE_CALCULATOR
};

export const getCapacityColor = (
    powerAmounts: PowerAmounts,
    capacityThresholds: CapacityThresholds,
    generatorType: GeneratorType
): CapacityColorType => {
    const colorHexAndString = getCapacityHexAndString(
        powerAmounts,
        capacityThresholds,
        generatorType
    );
    return colorHexAndString.colorHex;
};

export const getCapacityColorString = (
    powerAmounts: PowerAmounts,
    capacityThresholds: CapacityThresholds,
    generatorType: GeneratorType
): ExportColorType => {
    const colorHexAndString = getCapacityHexAndString(
        powerAmounts,
        capacityThresholds,
        generatorType
    );
    return colorHexAndString.colorString;
};

// This function must stay in sync with "getMapboxColorSteps" above.
const getCapacityHexAndString = (
    powerAmounts: PowerAmounts,
    capacityThresholds: CapacityThresholds,
    generatorType: GeneratorType
): ColorHexAndString => {
    const sizeCalculatorFunction =
        GENERATOR_TYPE_TO_AVAILABILITY_SIZE_TO_USE[generatorType];

    const size = sizeCalculatorFunction(powerAmounts);

    if (size < capacityThresholds.mediumThreshold) {
        return RED_COLOR_HEX_AND_STRING;
    } else if (size < capacityThresholds.highThreshold) {
        return YELLOW_COLOR_HEX_AND_STRING;
    } else {
        return GREEN_COLOR_HEX_AND_STRING;
    }
};

// This function must stay in sync with "getCapacityHexAndString" above.
// Mapbox doesnt allow for "ties" in their steps. So this logic is a little weird.
// If the threshold for "green" is == the medium threshold (it should be impossible for it to be <)
// then that means there should be no orange section, so we remove that step.
export const getMapboxColorSteps = (
    capacityThresholds: CapacityThresholds
): (string | number)[] => {
    const steps: (string | number)[] = [
        capacityThresholds.highThreshold,
        HIGH_AVAILABLE_CAPACITY_COLOR
    ];

    if (capacityThresholds.highThreshold > capacityThresholds.mediumThreshold) {
        steps.unshift(
            capacityThresholds.mediumThreshold,
            MED_AVAILABLE_CAPACITY_COLOR
        );
    }

    steps.unshift(LOW_AVAILABLE_CAPACITY_COLOR);

    return steps;
};

import logo from "./nira_text_logo.svg";
import "./textLogo.scss";

export interface LogoProps {
    width?: number;
}
const TextLogo: React.FC<LogoProps> = ({ width }) => {
    return (
        <img
            className="TextLogo-logo"
            src={logo}
            alt="Nira Logo"
            style={width ? { width } : undefined}
        />
    );
};

export default TextLogo;

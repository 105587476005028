export const NYISO = "NYISO";
export const PJM = "PJM";
export const MISO = "MISO";
export const CAISO = "CAISO";
export const SPP = "SPP";
export const ERCOT = "ERCOT";
export const WECC = "WECC";
export const TVA = "TVA";
export const ISONE = "ISONE";
export const SOCO = "SOCO";
export const DUKE = "DUKE";

export const REGION_TYPES = [
    NYISO,
    PJM,
    MISO,
    CAISO,
    SPP,
    ERCOT,
    WECC,
    TVA,
    ISONE,
    SOCO,
    DUKE
] as const;
export type RegionType = (typeof REGION_TYPES)[number];

export const isKnownRegionType = (type: string): type is RegionType => {
    return REGION_TYPES.includes(type as RegionType);
};

export const convertToRegionType = (string: string): RegionType => {
    if (isKnownRegionType(string)) {
        return string;
    }

    throw new Error("Unexpected region type");
};

export const convertToRegionTypeList = (
    stringList: ReadonlyArray<string>
): ReadonlyArray<RegionType> => {
    return stringList.filter(isKnownRegionType);
};

import { Alert, Intent, Position, Toaster } from "@blueprintjs/core";
import { useUpdateScenarioStatusMutation } from "generated/graphql";
import { segmentTrackArchiveScenario } from "in_queue/analytics";
import { useCurrentScenarioMetadata } from "in_queue/contexts/ScenarioPageContext";
import { ScenarioStatus } from "in_queue/types/scenarioType";
import { useState } from "react";

interface ArchiveScenarioAlertProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    action: "archive" | "unarchive";
}

const ArchiveScenarioAlert: React.FC<ArchiveScenarioAlertProps> = ({
    isOpen,
    setIsOpen,
    action
}: ArchiveScenarioAlertProps) => {
    const [updateScenarioStatus] = useUpdateScenarioStatusMutation();
    const scenario = useCurrentScenarioMetadata();
    const [isAlertLoading, setIsAlertLoading] = useState(false);

    const archiveScenario = async ({
        action
    }: {
        action: "archive" | "unarchive";
    }) => {
        setIsAlertLoading(true);

        const hasuraResponse = await updateScenarioStatus({
            variables: {
                scenarioId: scenario.scenarioId,
                status:
                    action === "archive"
                        ? ScenarioStatus.Archived
                        : ScenarioStatus.RunSucceeded
            }
        });

        if (
            hasuraResponse.data &&
            hasuraResponse.data.update_scenarios &&
            hasuraResponse.data.update_scenarios.affected_rows > 0
        ) {
            segmentTrackArchiveScenario({
                scenarioId: scenario.scenarioId,
                action
            });
            setIsAlertLoading(false);
            setIsOpen(false);

            ArchiveScenarioToaster.show({
                intent: Intent.SUCCESS,
                message: `Successfully ${
                    action === "archive" ? "archived" : "unarchived"
                } scenario "${scenario.title}"`
            });
        }
    };

    return (
        <Alert
            cancelButtonText="Cancel"
            confirmButtonText={
                action === "archive" ? "Archive scenario" : "Unarchive scenario"
            }
            icon={action === "archive" ? "archive" : "unarchive"}
            intent={Intent.NONE}
            isOpen={isOpen}
            loading={isAlertLoading}
            onCancel={() => setIsOpen(false)}
            onConfirm={() => archiveScenario({ action })}
        >
            <p>
                {action === "archive"
                    ? "Are you sure you want to archive this scenario? Once the scenario is archived, it will be hidden by default."
                    : "Unarchiving this scenario means that it will be shown by default."}
            </p>
        </Alert>
    );
};

const ArchiveScenarioToaster = Toaster.create({
    position: Position.TOP
});

export default ArchiveScenarioAlert;

import { User } from "@auth0/auth0-react";

// This is defined in Auth0, inside the "created-at" rule
const AUTH_NAMESPACE = "https://nira";
// This is defined in Auth0, inside the "get-gitbook-links" action
export const GITBOOK_NAMESPACE = "https://nira-gitbook";

export interface NiraAuth0UserType extends Readonly<User> {
    readonly [AUTH_NAMESPACE]: {
        readonly created_at: string;
    };
    // This shouldnt ever be undefined, but just to be safe, I make it optional
    readonly [GITBOOK_NAMESPACE]?: {
        readonly commonDocs: string;
        // The keys here are regions but we'll convert them in userType.tsx
        readonly regionDocs: { [K in string]: string };
    };
}
